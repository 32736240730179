import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import Fornecedores from './pages/fornecedores/Fornecedores';
import Itens from './pages/itens/Itens';
import Pessoas from './pages/pessoas/Pessoas';
import Login from './pages/login/Login';
import Usuarios from './pages/usuarios/Usuarios';
import Vendas from './pages/vendas/Vendas';
import CadastrarVendasPage from './pages/vendas/Cadastrar';
import Leads from './pages/leads/Leads';
import CadastrarLeadsPage from './pages/leads/cadastrar';
import AtendimentoPage from "./pages/atendimento/Atendimento";
import CadastrarAtendimentoPage from "./pages/atendimento/Cadastrar";
import UsuarioCadastroPage from "./pages/usuarios/Cadastro";
import FornecedorCadastroPage from './pages/fornecedores/cadastrar';
import PessoasCadastrarPage from './pages/pessoas/Cadastrar';
import ItemCadastrarPage from './pages/itens/Cadastrar';
import ModeloPage from './pages/modelos/Modelos';
import ModeloCadastrarPage from './pages/modelos/Cadastrar';
import DashboardPage from './pages/dashboard/dashboard';
import PDFPage from './pages/pdf/Pdf.jsx';
import StatusPage from './pages/status/Status';
import StatusCadastrarPage from './pages/status/Cadastrar';
import ItemEditarPage from "./pages/itens/Editar";
import EditarLeadsPage from "./pages/leads/Editar";
import StatusEditarPage from './pages/status/Editar';
import ModeloEditarPage from './pages/modelos/Editar';
import EditarAtendimentoPage from './pages/atendimento/Editar';
import EditarVendasPage from './pages/vendas/Editar';
import PessoasEditarPage from './pages/pessoas/Editar';
import FornecedorEditarPage from './pages/fornecedores/Editar';
import UsuarioEditarPage from './pages/usuarios/Editar';
import PDFVendaPage from './pages/pdf/Venda';
import PDFCompraPage from './pages/pdf/Compra';
import RelatorioLeadsPage from './pages/leads/relatorio';
import FinanceiroPage from './pages/financeiro/Financeiro';
import ContasPage from './pages/contas/Contas';
import MovimentacaoPage from './pages/movimentacao/movimentacao';
import PropriedadesPage from './pages/propriedades/propriedade';
import ChecklistPage from './pages/checklist/checklist';
import ChecklistDocPage from './pages/checklistDoc/checklistDoc';
import MotivoPage from './pages/vendas/motivo';
import QuestionarioPage from './pages/vendas/questionario';
import AndamentoPage from './pages/compra/andamento';
import Empresas from './pages/empresas/Empresas';
import EmpresaCadastroPage from "./pages/empresas/Cadastro";
import EmpresaEditarPage from './pages/empresas/Editar';
import VendaFasePage from "./pages/vendas/vendaFase";
import Estoque from './pages/estoque/Estoque';
import EstoqueCadastroPage from './pages/estoque/Cadastro';
import VendaRetornoPage from './pages/vendas/vendaRetorno';
import TrocaPage from './pages/estoque/Troca';
import PDFImpressaoPage from './pages/pdf/impressao';
import RelatorioPage from './pages/relatorios/relatorio';
import ComprasPage from './pages/compra/compras';
import ContasBancariasPage from './pages/contas/Bancarias';
import RestricaoPage from './pages/usuarios/Restricao';
import RelatorioVendasPage from './pages/relatorios/relatorioVendas';
import RelatorioVendasDetPage from './pages/relatorios/relatorioVendaDet';
import RelatorioCompraDetPage from './pages/relatorios/relatorioCompraDet';
import RelatorioComprasPage from './pages/relatorios/relatorioCompras';
import TipoPagPage from './pages/financeiro/TipoPagamento';
import DespesaPage from './pages/despesas/Despesas';
import DespesaMotoPage from './pages/despesas/DespesaMoto';
import NotificacaoPage from './pages/dashboard/Notificacao';
import EditarMov from './pages/vendas/EditarMov';
import ConsorcioPage from './pages/consorcio/Consorcio';
import VendasConsorcio from './pages/vendas/vendaConsorcio';
import GestaoPage from './pages/gestao/Gestao';
import ComissaoPage from './pages/gestao/RelatorioComissaoConsorcio';

import ProdutosPage from './pages/produtos/Produtos';
import ProdutoCadastroPage from './pages/produtos/cadastrar';
import ProdutoEditarPage from './pages/produtos/Editar';
import NotasPage from './pages/notas/Notas';
import NotasCadastrarPage from './pages/notas/cadastrar';
import NotasDetalhesPage from './pages/notas/detalhes';
import ProdutosEstoquePage from './pages/produtos/estoque';
import OficinaProdutosPage from './pages/oficina/produtos';
import OficinaPage from './pages/oficina/dashboard';
import OficinaAddProdutos from './pages/oficina/adicionar';
import OficinaAddServico from './pages/oficina/servicos';
import Prestadores from './pages/prestadores/Prestadores';
import PrestadorCadastroPage from './pages/prestadores/cadastrar';
import PrestadoresEditarPage from './pages/prestadores/editar';
import CategoriasPrestadoresPage from './pages/prestadores/categorias';
import Externos from './pages/oficina/externos';
import EditarExterno from './pages/oficina/editarServico';

import RelatorioFinanceiro from './pages/financeiro/relatorio';
import Especies from './pages/especies/Especies';
import AddEspecies from './pages/especies/adicionar';

import PDFExternoPage from './pages/pdf/servico';

import MapaVendas from './pages/vendas/Mapa';

function App() {
  return (
    <Router>
      <div className="App">

        <Switch>
          <Route path='/' exact  render={props => <Login {...props} />} />
          <Route path="/leads/relatorio" render={props => <RelatorioLeadsPage {...props} />} />
          <Route path='/pessoas' render={props => <Pessoas {...props} />} />
          <Route path='/itens' render={props => <Itens {...props} />} />
          <Route path='/login' render={props => <Login {...props} />} />
          <Route path='/usuarios' render={props => <Usuarios {...props} />} />
          <Route path='/empresas' render={props => <Empresas {...props} />} />
          <Route path='/vendas/relatorio' render={props => <RelatorioVendasPage {...props} />} />
          <Route path='/vendas/detalhe/:id' render={props => <RelatorioVendasDetPage {...props} />} />
          <Route path='/vendas/:id' render={props => <Vendas {...props} />} />
          <Route path='/vendas' render={props => <Vendas {...props} />} />
          <Route path='/motivo' render={props => <MotivoPage {...props} />} />
          <Route path='/leads' render={props => <Leads {...props} />} />
          <Route path="/status" render={props => <StatusPage {...props} />} />
          <Route path="/financeiro" render={props => <FinanceiroPage {...props} />} />
          <Route path="/movimentacao/:id" render={props => <MovimentacaoPage {...props} />} />
          <Route path="/movimentacao/" render={props => <MovimentacaoPage {...props} />} />
          <Route path="/contas" render={props => <ContasPage {...props} />} />
          <Route path="/dashboardcomercial" render={props => <DashboardPage {...props} />} />
          <Route path="/cadastrarmodelos" render={props => <ModeloCadastrarPage {...props} />} />
          <Route path="/modelos" render={props => <ModeloPage {...props} />} />
          <Route path="/pdfvenda/:iten/:modelo" exact render={props => <PDFVendaPage {...props} />} />
          <Route path="/pdf/:iten/:modelo" exact render={props => <PDFPage {...props} />} />
          <Route path="/pdfcompra/:iten/:modelo" exact render={props => <PDFCompraPage {...props} />} />
          <Route path="/pdfImpressao/:iten/:modelo" exact render={props => <PDFImpressaoPage {...props} />} />

          <Route path="/pdfOficina/:item/:tipo/:modelo" exact render={props => <PDFExternoPage {...props} />} />

          <Route path='/cadastrarpessoa/:id' render={props => <PessoasCadastrarPage {...props} />} />
          <Route path='/questionario/:id' render={props => <QuestionarioPage {...props} />} />
          <Route path='/questionario' render={props => <QuestionarioPage {...props} />} />
          <Route path='/andamento' render={props => <AndamentoPage {...props} />} />
          <Route path='/vendaFase' render={props => <VendaFasePage {...props} />} />
          <Route path='/vendaR/:id' render={props => <VendaRetornoPage {...props} />} />
          <Route path='/estoque' render={props => <Estoque {...props} />} />
          <Route path='/trocaEstoque' render={props => <TrocaPage {...props} />} />
          {/* <Route path='/relatorio' render={props => <RelatorioPage {...props} />} /> */}
          <Route path='/compras/relatorio' render={props => <RelatorioComprasPage {...props} />} />
          <Route path='/compras/detalhe/:id' render={props => <RelatorioCompraDetPage {...props} />} />
          <Route path='/compras' render={props => <ComprasPage {...props} />} />
          <Route path='/contasBancarias' render={props => <ContasBancariasPage {...props} />} />
          <Route path='/restricao/:id' render={props => <RestricaoPage {...props} />} />
          <Route path='/tipoPagamento' render={props => <TipoPagPage {...props} />} />
          <Route path='/despesas' render={props => <DespesaPage {...props} />} />
          <Route path='/despesasItem/:id' render={props => <DespesaMotoPage {...props} />} />
          <Route path='/notificacao' render={props => <NotificacaoPage {...props} />} />
          <Route path='/editarMov/:id' render={props => <EditarMov {...props} />} />
          <Route path='/gestao' render={props => <GestaoPage {...props} />} />

          <Route path='/consorcio' render={props => <ConsorcioPage {...props} />} />
          <Route path='/vendaconsorcio' render={props => <VendasConsorcio {...props} />} />
          <Route path='/comissao' render={props => <ComissaoPage {...props} />} />
          
          <Route path='/propriedades' render={props => <PropriedadesPage {...props} />} />
          <Route path='/checklist' render={props => <ChecklistPage {...props} />} />
          <Route path='/checklistDoc' render={props => <ChecklistDocPage {...props} />} />
          <Route path='/cadastrarvendas' render={props => <CadastrarVendasPage {...props} />} />
          <Route path='/cadastrarleads' render={props => <CadastrarLeadsPage {...props} />} />
          <Route path='/atendimento' render={props => <AtendimentoPage {...props} />} />
          <Route path='/cadastraratendimento' render={props => <CadastrarAtendimentoPage {...props} />} />
          <Route path='/cadastrarusuario' render={props => <UsuarioCadastroPage {...props} />} />
          <Route path='/cadastrarempresa' render={props => <EmpresaCadastroPage {...props} />} />
          <Route path='/cadastrarestoqueminimo' render={props => <EstoqueCadastroPage {...props} />} />
          <Route path='/cadastrarpessoa' render={props => <PessoasCadastrarPage {...props} />} />
          <Route path='/cadastraritens' render={props => <ItemCadastrarPage {...props} />} />
          <Route path='/cadastrarstatus' render={props => <StatusCadastrarPage {...props} />} />
       
          <Route path='/fornecedores' render={props => <Fornecedores {...props} />} />
          <Route path='/cadastrarfornecedor' render={props => <FornecedorCadastroPage {...props} />} />
          <Route path='/editarfornecedor/:id' render={props => <FornecedorEditarPage {...props} />} /> 
          
          
          <Route path="/produtos" render={props => <ProdutosPage {...props} />} />
          <Route path='/cadastrarproduto' render={props => <ProdutoCadastroPage {...props} />} />
          <Route path='/editarproduto/:id' render={props => <ProdutoEditarPage {...props} />} />
          <Route path='/notas/produto/:produto' render={props => <NotasPage {...props} />} />
          <Route path='/notas' render={props => <NotasPage {...props} />} />
          <Route path='/cadastrarnotas' render={props => <NotasCadastrarPage {...props} />} />
          <Route path='/detalhesnotas/:id' render={props => <NotasDetalhesPage {...props} />} />
          <Route path="/produtosestoque" render={props => <ProdutosEstoquePage {...props} />} />
          <Route path="/adicionaroficina/:id/tipo/:tipo" render={props => <OficinaAddProdutos {...props} />} />
          <Route path="/adicionarservico/:id/tipo/:tipo" render={props => <OficinaAddServico {...props} />} />
          <Route path="/oficina/item/:id/tipo/:tipo" render={props => <OficinaProdutosPage {...props} />} />
          <Route path="/oficina/externos/:id/tipo/:tipo" render={props => <Externos {...props} />} />
          <Route path="/oficina/editarexterno/:id/tipo/:tipo" render={props => <EditarExterno {...props} />} />
          <Route path="/oficina" render={props => <OficinaPage {...props} />} />
          

          <Route path="/prestadores/categorias" render={props => <CategoriasPrestadoresPage {...props} />} />
          <Route path="/prestadores" render={props => <Prestadores {...props} />} />
          <Route path="/editarprestador/:id" render={props => <PrestadoresEditarPage {...props} />} />
          <Route path="/adicionarprestador" render={props => <PrestadorCadastroPage {...props} />} />
            
          <Route path='/editarusuario/:id' render={props => <UsuarioEditarPage {...props} />} /> 
          <Route path='/editarempresa/:id' render={props => <EmpresaEditarPage {...props} />} /> 
          <Route path='/editarvenda/:id' render={props => <EditarVendasPage {...props} />} /> 
          <Route path='/editarpessoa/:id' render={props => <PessoasEditarPage {...props} />} /> 
          <Route path='/editaritens/:id' render={props => <ItemEditarPage {...props} />} /> 
          <Route path='/editaratendimento/:id' render={props => <EditarAtendimentoPage {...props} />} /> 
          <Route path='/editarmodelo/:id' render={props => <ModeloEditarPage {...props} />} /> 
          <Route path='/editarleads/:id' render={props => <EditarLeadsPage {...props} />} /> 
          <Route path="/editarstatus/:id" render={props => <StatusEditarPage {...props} />} />

          <Route path="/relatorioFinanceiro" render={props => <RelatorioFinanceiro {...props} />} />
          <Route path="/especie" render={props => <Especies {...props} />} />
          <Route path="/criarespecie" render={props => <AddEspecies {...props} />} />
          <Route path="/alterespecies/:id" render={props => <AddEspecies {...props} />} />

          <Route path="/mapavendas" render={props => <MapaVendas {...props} />} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
