import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

class GestaoPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			tags: [],
			dados: [],
			data: [],
			keys: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			]
		};

		this.verificarPermissao();
	}

	async componentDidMount() {
		await this.apiCategorias();
		const chart = Highcharts.chart('chart', {
			title: {
				text: "Movimentações de "+moment().format("YYYY")
			},
			xAxis: {
				categories: this.state.keys,
			},
			yAxis: {
				title: {
					text: 'Valor (R$)'
				},
				plotLines: [{
					value: 0,
					width: 1,
					color: '#808080'
				}]
			},
			tooltip: {
				valuePrefix: 'R$'
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'middle',
				borderWidth: 0
			},
			series: this.state.data,
		});
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/relatorioFinanceiro")
			if (res.length == 0) {
				this.props.history.push('/dashboardcomercial')
			}
		} else {
			this.props.history.push('/dashboardcomercial')
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] clientesUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	getRandomColor = () => {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	apiCategorias = async event => {
		let res = await api_topmotos.get('/categorias/relatorio');

		let tags = [];
		let groups = res.data.filter(r => {
			tags.push(r.categoria);
			return !r.cat_id;
		})
		groups.forEach(g => {
			g.options = res.data.filter(r => {
				return r.cat_id == g.id;
			})
		})

		let data = [];
		res.data.forEach(r => {
			let tags = [
				r.mJan,
				r.mFev,
				r.mMai,
				r.mAbr,
				r.mMai,
				r.mJun,
				r.mJul,
				r.mAgo,
				r.mSet,
				r.mOut,
				r.mNov,
				r.mDez,
			];
			data.push({
				name: r.categoria,
				data: tags,
			})
		});
		console.log(data, this.state.keys);

		this.setState({ dados: groups, data, tags })
	}

	formatValue = (valor) => {
		return valor ? parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
	}

	openPrint = () => {
		window.print();
	}

	render() {

		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Relatorio Plano de Contas</p>
							<div style={{ display: 'flex' }}>
								<div className="td-acoes" style={{ width: '70px', float: 'left' }}>
									<i className="fas fa-print" onClick={() => this.openPrint()}></i>
								</div>
							</div>
						</div>
						<div className="table">
							<table className="print-table planosConta">
								<p style={{ textAlign: "center", fontSize: "15px", margin: "10px", display: "none" }}>
									Relatório Plano de Contas
								</p>
								<thead>
									<tr>
										<th>Plano de Conta</th>
										{this.state.keys.map(k => (
											<th>{k}</th>
										))}
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									{this.state.dados.map(a => (
										<>
											<tr>
												<td style={{ borderLeft: `8px solid ${a.tipo === "D" ? "#ff0707" : "#17AD1D"}` }}>
													{a.categoria}
												</td>
												<td>{this.formatValue(a.mJan)}</td>
												<td>{this.formatValue(a.mFev)}</td>
												<td>{this.formatValue(a.mMar)}</td>
												<td>{this.formatValue(a.mAbr)}</td>
												<td>{this.formatValue(a.mMai)}</td>
												<td>{this.formatValue(a.mJun)}</td>
												<td>{this.formatValue(a.mJul)}</td>
												<td>{this.formatValue(a.mAgo)}</td>
												<td>{this.formatValue(a.mSet)}</td>
												<td>{this.formatValue(a.mOut)}</td>
												<td>{this.formatValue(a.mNov)}</td>
												<td>{this.formatValue(a.mDez)}</td>
												<td>{this.formatValue(a.total)}</td>
											</tr>
											{a.options.map((op) => (
												<tr style={{ backgroundColor: '#ffffff' }}>
													<td>
														<i className="fas fa-level-up-alt fa-flip-horizontal" style={{ margin: '0 20px' }}></i>
														{op.categoria}
													</td>
													<td>{this.formatValue(op.mJan)}</td>
													<td>{this.formatValue(op.mFev)}</td>
													<td>{this.formatValue(op.mMar)}</td>
													<td>{this.formatValue(op.mAbr)}</td>
													<td>{this.formatValue(op.mMai)}</td>
													<td>{this.formatValue(op.mJun)}</td>
													<td>{this.formatValue(op.mJul)}</td>
													<td>{this.formatValue(op.mAgo)}</td>
													<td>{this.formatValue(op.mSet)}</td>
													<td>{this.formatValue(op.mOut)}</td>
													<td>{this.formatValue(op.mNov)}</td>
													<td>{this.formatValue(op.mDez)}</td>
													<td>{this.formatValue(op.total)}</td>
												</tr>
											))}
										</>
									))}
								</tbody>
							</table>
						</div>
						<div className="form-footer" style={{ height: "600px", padding: "40px 10px 20px 10px" }}>
							<div id="chart"></div>
						</div>
						<Versionamento />
					</div>
				</div>


			</section >

		)
	}
}

export default GestaoPage;
