import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';
import moment from 'moment';


import {
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Thumbnail } from 'react-bootstrap';
import { parseTwoDigitYear } from 'moment';

class NotaCadastroPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			indexperg: 0,
			titles: ['Selecionar Fornecedor', 'Cadastrar Produtos'],
			sum: 0,
			valorPagamento: 0,
			movimentacoesTabela: [],

			addFornecedor: null,
			valorTParcela: '',
			valorParcela: '',

			tipoPagamento: [],
			tipoParcelamento: '',
			contas: [],
			categorias: [],
			conta: '',
			categoria: '',
			tipo:'',

			contasBancarias: [],
			contaB: '',
			dataCompetencia: '',

			openmodalCadastrar: false,
			openmodal: false,

			produtosF: [],
			produtos: [],
			options: [],

			modalRef: '',
			modalQuantidade: '',
			modalValorUnit: '',

			modalCadastrarItens: [],
			fornecedores: [],
			tipos: [],

			modalCadastrarNome: '',
			modalCadastrarTipo: '',
			modalCadastrarDescricao: '',
			modalCadastrarRefInterna: '',
			modalCadastrarRefExterna: '',

			modalDetalhesOpen: false,
			modalDetalhesId: '',
			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesTipo: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefInterna: '',
			modalDetalhesRefExterna: '',

			fornecedor_nome: '',
			selectFornecedor: '',
			selectFornecedorId: '',
			numeroNota: '',
			dataEmissao: '',

			produtoSE: '',
			meses: '',
			modalCadastrarP: false,

			lettersInfo: null,
			clientFocus: '',
		};
	}

	saveMovimentacao = async () =>{
		var inputId = document.getElementById("parcelaM");
		var inputIcone = document.getElementById("lblParcelaM");
		if (this.state.meses == '' && this.state.repetirLancamento == 2) {
				inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
				inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
				return;
		} else {
				inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
		}
		await this.repetirLancamento();

		let parcela_id;

		if(this.state.repetirLancamento == 2){
				await api_topmotos.post('/parcelamento/create', {
						admissaodata: this.getTimestamp()
				}).then(res => {
						this.setState({parcela_id: res.data.returnId})
				})
		}

		const {
				valor,
				tipo,
				conta, 
				categoria,
				dataVencimento,
				banco,
				agencia, 
				nConta,
				tipoConta,
				tipoPag
		} = this.state

		var inputs = [
				[valor, 'valorM', 'iconValorM', 'lblValorM'],
				[tipo, 'tipoPagamento', 'iconTipoPagamento', 'lblTipoPagamento'],
				[conta, 'conta', 'iconConta', 'lblConta'],
				[categoria, 'categoria', 'iconCategoria', 'lblCategoria'],
				[dataVencimento, 'vencimento', 'iconVencimento', 'lblVencimento'],
		]

		var inputsTransf = [
				[banco, 'banco', 'iconBanco', 'lblBanco'],
				[agencia, 'agencia', 'iconAgencia', 'lblAgencia'],
				[nConta, 'nconta', 'iconNConta', 'lblNConta'],
				[tipoConta, 'tipoConta', 'iconTipoConta', 'lblTipoConta']
		]

		if(valor){
				var real = this.state.valor.replace('.', '');
				real = real.replace(',', '.');
		}

		let transferencia = false;

		if(tipoPag){
				/*if(!nConta || 
						!tipoConta ){

						for(var i = 0; i < inputsTransf.length; i++){
								if(!inputsTransf[i][0] || inputsTransf[i][0] == 'NULL' ){
										var inputId = document.getElementById(inputsTransf[i][1]);
										var inputIcone = document.getElementById(inputsTransf[i][2]);
										var inputNome = document.getElementById(inputsTransf[i][3]);
										inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
										inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
										inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
								} else{
										var inputId = document.getElementById(inputsTransf[i][1]);
										var inputIcone = document.getElementById(inputsTransf[i][2]);
										var inputNome = document.getElementById(inputsTransf[i][3]);
										inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
										inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
										inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
								}
						}
						transferencia = true
				}*/
		} else{
				transferencia = false
		}
			console.log(transferencia)
			if(!tipo ||
					!conta || 
					!categoria ||
					!dataVencimento || 
					!valor || transferencia){
					
					this.setState({ error: "preencha todos os dados para cadastrar!" });
					this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
					this.log(this.state.error);
					
					for(var i = 0; i < inputs.length; i++){
			if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
				var inputId = document.getElementById(inputs[i][1]);
				var inputIcone = document.getElementById(inputs[i][2]);
				var inputNome = document.getElementById(inputs[i][3]);
				inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
				inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
				inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
			} else{
				var inputId = document.getElementById(inputs[i][1]);
				var inputIcone = document.getElementById(inputs[i][2]);
				var inputNome = document.getElementById(inputs[i][3]);
				inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
		}
			} else{
					for(var i = 0; i < inputs.length; i++){
							var inputId = document.getElementById(inputs[i][1]);
							var inputIcone = document.getElementById(inputs[i][2]);
							var inputNome = document.getElementById(inputs[i][3]);
							inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
							inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
							inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					}
					
					if(this.state.notaId){
							try{
									this.toggleLoading();
									if(this.state.repetirLancamento){
											for(let i in this.state.dataRepeticao){
													await api_topmotos.post('/financeiro/create', {
															descricao: this.state.notaId + ' - Nota - ' +  this.state.numeroNota + ' - ' + this.state.fornecedor_nome,
															dataVencimento: this.state.dataRepeticao[i],
															valor: this.state.repetirLancamento == 2 ? this.state.valorParcela : real,
															tipoPagamento: this.state.tipo,
															status: 'AP',
															admissaodata: this.getTimestamp(),
															cat_id: this.state.categoria,
															conta_id: this.state.conta,
															compraId: this.state.notaId,
															dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
															banco: this.state.contaB ? this.state.contaB : null,
															parcela_id: this.state.parcela_id ? this.state.parcela_id : null,
															bancoFinanciado: this.state.tFinanciado
													}).then(res => {
															this.setState({ movId: res.data.returnId, valorPagamento: this.state.valorPagamento - this.state.valorParcela})
															if(this.state.tipoPag && this.state.dataRepeticao.length == (parseInt(i)+1)){
																	this.apiCadastrarDadosBanc(res.data.returnId)
															}
															if(this.state.tipoParcelamento && this.state.dataRepeticao.length == (parseInt(i)+1)){
																	this.apiCadastrarTipoParcelamento(res.data.returnId)
															}
													});
											}
									} else{
											await api_topmotos.post('/financeiro/create', {
													descricao: this.state.notaId + ' - Nota - ' +  this.state.numeroNota + ' - ' + this.state.fornecedor_nome,
													dataVencimento: this.state.dataVencimento,
													valor: real,
													tipoPagamento: this.state.tipo,
													status: 'AP',
													admissaodata: this.getTimestamp(),
													cat_id: this.state.categoria,
													conta_id: this.state.conta,
													nota_id: this.state.notaId,
													dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
													banco: this.state.contaB ? this.state.contaB : null,
													parcela_id: null,
													bancoFinanciado: this.state.tFinanciado
											}).then(res => {
													this.setState({ movId: res.data.returnId, valorPagamento: this.state.valorPagamento - real})
													if(this.state.tipoPag){
															this.apiCadastrarDadosBanc(res.data.returnId)
													}

													if(this.state.tipoParcelamento){
															this.apiCadastrarTipoParcelamento(res.data.returnId)
													}
											});
									}
			
									this.atualizarMovimentacao();
									this.toggleModalCadastrar();
									this.limparState();
									this.setState({valor: ''})
									this.toggleLoading();
							} catch (err) {
									this.setState({ error: "Ocorreu um erro ao conectar com a API! (/financeiro/create)" });
									this.log(this.state.error);
							}
					} else{
							console.log('Ocorreu um erro')
					}

			}
	}

	limparState = () =>{
		this.setState({
				meses: '',
				repetirLancamento: '',
				dataRepeticao: [],
				parcela_id: '',
				dataCompetencia: '',
				categoria: '',
				conta: '',
				valor: '',
				tipo: '',
				dataVencimento: '',
				valorTParcela: ''
		})
	}

	toggleLoading = () =>{
		this.setState({ displayLoading: !this.state.displayLoading });
	}

	apiCadastrarTipoParcelamento = async (id) =>{
		try{
				var real = this.state.valorTParcela.replace('.', '');
				real = real.replace(',', '.');

				await api_topmotos.post('/tParcelamento/create', {
						valor: real,
						parcelas: this.state.tParcela,
						mov: id
				})

				this.setState({displayTParcela: 'none'})
		} catch (err) {
				this.setState({ error: "Ocorreu um erro ao conectar com a API! (/tParcelamento/create)" });
				this.log(this.state.error);
		}
	}

	apiCadastrarDadosBanc = async (id) =>{
		try{

				await api_topmotos.post('/dadosBanc/create', {
						banco: this.state.banco,
						agencia: this.state.agencia,
						conta: this.state.nConta,
						tipo: this.state.tipoConta,
						mov: id
				})

				this.setState({display: 'none'})
		} catch (err) {
				this.setState({ error: "Ocorreu um erro ao conectar com a API! (/dadosBanc/create)" });
				this.log(this.state.error);
		}
	}

	// Off/On modal cadastro
	toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
  }
  

	// Listar tipo pagamentos
	apiListarPagamento = async () =>{
		let res = await api_topmotos.get('/tipopag/');
		res = res.data.filter( t => t.compra == 1);
		this.setState({ tipoPagamento: res });
	}

	// Listar Contas 
	apiListarContas = async () =>{
		let res = await api_topmotos.get('/contas/');
		this.setState({ contas: res.data });
	}

	// Listar Contas Bancárias
  listarContasBancarias = async () =>{
    let res = await api_topmotos.get('/contasBancarias/');
    this.setState({ contasBancarias: res.data });
  }

	// Listar Categorias 
	apiListarCategorias = async () => {
		let res = await api_topmotos.get('/categoriasAll/');
		
		let groups = res.data.filter(r => {
				return !r.cat_id && r.tipo == "D";
		})
		groups.forEach(g => {
				g.options = res.data.filter(r => {
						return r.cat_id == g.id;
				})
		})

		this.setState({ categorias: groups });
	}

	apiListarFornecedores = async () => {
		let res = await api_topmotos.get('/fornecedores/');
		this.setState({ fornecedores: res.data });
	}

	apiListarTipos = async () => {
		let res = await api_topmotos.get('/produtos/tipos/');
		this.setState({ tipos: res.data });
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/notas")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] NotasUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarProdutos()
			this.apiListarFornecedores()
			this.apiListarTipos()
			this.apiListarPagamento()
			this.apiListarContas()
			this.listarContasBancarias()
			this.apiListarCategorias()
		}
	}

	apiListarProdutos = async () => {
		let res = await api_topmotos.get('/produtos');
		this.setState({ produtos: res.data });
	}

	apiCadastrarProduto = async (e) => {
		const {
			modalCadastrarNome,
			modalCadastrarTipo,
			modalCadastrarDescricao,
			modalCadastrarRefInterna,
			modalCadastrarRefExterna
		} = this.state;

		if (!modalCadastrarNome ||
			!modalCadastrarTipo ||
			!modalCadastrarDescricao ||
			!modalCadastrarRefExterna ||
			!modalCadastrarRefInterna) {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
			this.log(this.state.error);

		} else {
			this.setState({ errorMessage: '' });

			try {
				await api_topmotos.post('/produtos/create', {
					nome: modalCadastrarNome,
					descricao: modalCadastrarDescricao,
					tipo: modalCadastrarTipo,
					fornecedor: this.state.selectFornecedorId,
					refInterna: modalCadastrarRefInterna,
					refExterna: modalCadastrarRefExterna,
				})

			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/produtos/create)" });
				this.log(this.state.error);
			}

			this.toggleModalCadastrarP();
			await this.apiListarProdutos();
			const produtos = this.state.produtos.filter((p) => {
				return p.produto_fornecedor === this.state.selectFornecedorId
			})
			this.setState({ produtosF: produtos })

			this.editarItem(modalCadastrarRefInterna)
		}
	}

	repetirLancamento = async () =>{
		var date = new Date();

		var mes;
		var meses = [];
		var anos = 0;
		var dia = moment(this.state.dataVencimento).format('DD');
		var ano = parseInt(moment(this.state.dataVencimento).format('YYYY'));

		for (let i = 0; i < this.state.meses; i++){
				mes = parseInt(moment(this.state.dataVencimento).format('MM')) + i;

				if(mes%12 == 0){
						anos++;
						mes = `${ano + anos - 1}-12`
				} else{
						if(mes > 12){
								mes = mes - (12 * anos)
								mes = `${ano + anos}-${mes}`
						} else{
								mes = `${ano + anos}-${mes}`
						}
				}

				meses.push(mes + '-' + dia)

		}

		this.setState({dataRepeticao: meses})

	}

	// Deletar conta
	deletar = async e =>{

		e.preventDefault();

		try {
						await api_topmotos.delete('/financeiro/delete/id/' + this.state.modalDeletarId);
						this.setState({valorPagamento: this.state.valorPagamento + this.state.valorDeletado})
						this.atualizarMovimentacaoS();
						this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeito/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Contas deletado com sucesso!' });
	}

	togglemodalCadastrar = () => this.setState({ openmodalCadastrar: !this.state.openmodalCadastrar, clientFocus: true })
	togglemodalDetalhes = () => this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen })
	toggleModalCadastrarP = () => this.setState({ modalCadastrarP: !this.state.modalCadastrarP });

	// Off/On modal deletar
	toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  // Off/On modal aviso
  toggleModalAviso = () => {
    this.setState({ modalAviso: !this.state.modalAviso });
  }

	togglemodal = () => this.setState({ openmodal: !this.state.openmodal })

	additem = () => {
		this.apiListarProdutos();
		this.togglemodal();
	}

	removeItem = async (index) => {
		let newOptions = this.state.options;
		newOptions.splice(index, 1);

		this.setState({options: newOptions, sum: this.getSum()});
	}

	apiCadastrarNota = async () => {
		let verif = false, sum = this.getSum();
		this.state.options.forEach((op, index) => {
			if (!op.produto_id || !op.quantidade || !op.valorUnit) {
				verif = true;
			}
		});

		if (verif) return;

		try {
			await api_topmotos.post('/notas/create', {
				fornecedor: this.state.selectFornecedorId,
				code: this.state.numeroNota,
				data: this.state.dataEmissao,
				produtos: this.state.options
			}).then(res => {
				this.setState({notaId: res.data.insertId});
				this.slide(+1)
			});
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/notas/create)" });
			this.log(this.state.error);
		}

		this.setState({sum: sum, valorPagamento: sum})
	}

	verificarTransferencia = (e) =>{
		let transferencia = this.state.tipoPagamento.filter(y => y.id == e)
		if (transferencia[0].id == 12) {
				this.setState({
						displayTFinanciamento: 'block',
				})
		} 
		if(transferencia[0].dados_bancarios == 1){
				this.setState({
						display: 'block',
						tipoPag: true,
						displayTParcela: 'none',
						tipoParcelamento: false
				})

		} else if(transferencia[0].parcelamento == 1){
				this.setState({
						displayTParcela: 'block',
						tipoParcelamento: true,
						tipoPag: false,
						display: 'none',
				})
		} else{
				this.setState({
						tipoPag: false,
						display: 'none',
						nConta: '',
						banco: '',
						agencia: '',
						tipoConta: '',
						displayTParcela: 'none',
						tipoParcelamento: false,
						tParcela: '',
						valorTParcela: ''
				}) 
		}
	}

	slide = (cont) => {
		var questionario = document.getElementsByClassName("perguntas");
		var aberta;

		for (var i = 0; i < questionario.length; i++) {
			if (questionario[i].style.display == 'flex') {
				aberta = i;
			}
			questionario[i].style.display = 'none';
		}

		questionario[aberta + cont].style.display = 'flex'
		this.setState({ indexperg: aberta + cont })
	}

	maskDate = (e) => {
		let mask = []

		let regexs = {
			"0": [/^(\d{2})(\d{2})?/, "$1-$2"],
			"1": [/^(\d{2})(\d{2})?(\d{4})?/, "$1-$2-$3"],
		}

		if (e.length < 4) {
			mask = regexs[0]
		}
		else {
			mask = regexs[1]
		}

		e = e.replace(/\D/g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(mask[0], mask[1])

		this.setState({ dataEmissao: e })
	}

	buttonAddFornecedor = () => {
		this.setState({ addFornecedor: (
			<>
				<label>&nbsp;&nbsp;</label>
				<Button onClick={a => this.props.history.push("/cadastrarfornecedor")} className="btnora">
					Adicionar Fornecedor
				</Button>
			</>
		)})
	}

	cpfInvalido = () =>{
		var inputId, inputIcone, inputNome;
		inputId = document.getElementById("selectFornecedor");
		inputIcone = document.getElementById("iconSelectFornecedor");
		inputNome = document.getElementById("lblSelectFornecedor");
		inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
		inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
		inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
    this.aviso("CPF/CNPJ Invalido");
	}

	validarCPF = async (e) => {
		var cpf = e.target.value;

		if(cpf.length == 14){
				if (typeof cpf !== "string"){
						await this.cpfInvalido();
				} else{
						cpf = cpf.replace(/[\s.-]*/igm, '')

						if (!cpf ||
								cpf.length != 11 ||
								cpf == "00000000000" ||
								cpf == "11111111111" ||
								cpf == "22222222222" ||
								cpf == "33333333333" ||
								cpf == "44444444444" ||
								cpf == "55555555555" ||
								cpf == "66666666666" ||
								cpf == "77777777777" ||
								cpf == "88888888888" ||
								cpf == "99999999999" 
						) {
								this.cpfInvalido();
						} else{
								var soma = 0
								var resto

								for (var i = 1; i <= 9; i++) 
										soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
		
								resto = (soma * 10) % 11

								if ((resto == 10) || (resto == 11))  resto = 0

								if (resto != parseInt(cpf.substring(9, 10)) ){
										this.cpfInvalido();
								} else{
										soma = 0

										for (var i = 1; i <= 10; i++) 
												soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
										resto = (soma * 10) % 11

										if ((resto == 10) || (resto == 11))  resto = 0

										if (resto != parseInt(cpf.substring(10, 11) ) ){
												this.cpfInvalido();
										} else{
												this.aviso(null)
										}
								}
						}
				}
		} else if(cpf.length == 18){
				let cnpj = cpf.replace(/[^\d]+/g, '')

				if ( !cnpj || cnpj.length != 14
						|| cnpj == "00000000000000" 
						|| cnpj == "11111111111111" 
						|| cnpj == "22222222222222" 
						|| cnpj == "33333333333333" 
						|| cnpj == "44444444444444" 
						|| cnpj == "55555555555555" 
						|| cnpj == "66666666666666" 
						|| cnpj == "77777777777777" 
						|| cnpj == "88888888888888" 
						|| cnpj == "99999999999999"){
						await this.cpfInvalido(1);
				} else{
						var tamanho = cnpj.length - 2
						var numeros = cnpj.substring(0,tamanho)
						var digitos = cnpj.substring(tamanho)
						var soma = 0
						var pos = tamanho - 7

						for (var i = tamanho; i >= 1; i--) {
							soma += numeros.charAt(tamanho - i) * pos--
							if (pos < 2) pos = 9
						}

						var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

						if (resultado != digitos.charAt(0)){
								this.cpfInvalido(1);
						} else{
								tamanho = tamanho + 1
								numeros = cnpj.substring(0,tamanho)
								soma = 0
								pos = tamanho - 7

								for (var i = tamanho; i >= 1; i--) {
									soma += numeros.charAt(tamanho - i) * pos--
									if (pos < 2) pos = 9;
								}

								resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

								if (resultado != digitos.charAt(1)){
										this.cpfInvalido(1);
								} else {
									this.aviso(null)
								}
						}
				}
		}
	}

	maskCnpjCpf = (e) => {
		let mask = []

		let regexs = {
			"0": [/^(\d{3})(\d{3})?/, "$1.$2"],
			"1": [/^(\d{3})(\d{3})?(\d{3})?/, "$1.$2.$3"],
			"2": [/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4"],
			"3": [/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5"],
		}

		if (e.length < 5) {
			mask = regexs[0]
		}
		else if (e.length < 10) {
			mask = regexs[1]
		}
		else if (e.length < 15) {
			mask = regexs[2]
		}
		else {
			mask = regexs[3]
		}

		e = e.replace(/\D/g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(mask[0], mask[1])

		this.setState({ selectFornecedor: e })
	}

	fornecedor = () => {
		this.setState({ options: [], sum: 0, modalCadastrarItens: [] })

		var inputId, inputIcone, inputNome;
		if (this.state.numeroNota && this.state.dataEmissao && this.state.selectFornecedor){
			const fornecedor = this.state.fornecedores.filter((f) => {
				return f.fornecedor_cnpj === this.state.selectFornecedor
			})

			if (fornecedor.length === 0) {
				inputId = document.getElementById("selectFornecedor");
				inputIcone = document.getElementById("iconSelectFornecedor");
				inputNome = document.getElementById("lblSelectFornecedor");
				inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
				inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
				inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				this.buttonAddFornecedor();
				this.aviso("Fornecedor Invalido");
			} else {
				const produtos = this.state.produtos.filter((p) => {
					return p.produto_fornecedor === fornecedor[0].fornecedor_id
				})

				this.setState({ produtosF: produtos, fornecedor_nome: fornecedor[0].fornecedor_nome, selectFornecedorId: fornecedor[0].fornecedor_id })

				this.slide(+1)

				this.aviso(null)
				this.setState({addFornecedor: null})
			}
		} else {
			var inputs = [
				[this.state.numeroNota, 'numeroNota', 'iconNumeroNota', 'lblNumeroNota'],
				[this.state.dataEmissao, 'dataEmissao', 'iconDataEmissao', 'lblDataEmissao'],
				[this.state.selectFornecedor, 'selectFornecedor', 'iconSelectFornecedor', 'lblSelectFornecedor'],
			]

			for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
						inputId = document.getElementById(inputs[i][1]);
						inputIcone = document.getElementById(inputs[i][2]);
						inputNome = document.getElementById(inputs[i][3]);
						inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
						inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
						inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
						inputId = document.getElementById(inputs[i][1]);
						inputIcone = document.getElementById(inputs[i][2]);
						inputNome = document.getElementById(inputs[i][3]);
						inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
						inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
						inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}
		}
	}

	aviso = (msg) => {
		const aviso = document.getElementById("aviso")
		aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
		this.setState({ errorMessage: msg })
	}

	modalDetalhes = async (idProduto) => {
		var produto = this.state.produtosF.filter((p) => {
			return p.produto_id === idProduto;
		});
		if (produto.length > 0) {
			produto = produto[0];

			this.setState({
				modalDetalhesOpen: true,
				modalDetalhesId: produto.produto_id,
				modalDetalhesNome: produto.produto_nome,
				modalDetalhesDescricao: produto.produto_descricao,
				modalDetalhesTipo: produto.tipo_nome,
				modalDetalhesFornecedor: produto.fornecedor_nome,
				modalDetalhesRefInterna: produto.refInterna,
				modalDetalhesRefExterna: produto.refExterna,
			});
		}
	}

	OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/financeiro/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
  }

	getSum = () => {
		let sum = 0;
		this.state.options.forEach((op) => {
			sum += op.valorTotal;
		})
		return sum;
	}

	modalQuantidadeEditar = (quantidade, index) => {
		let newOptions = this.state.options;
		newOptions[index].quantidade = quantidade;

		let valorUnit = this.state.options[index].valorUnit || "0";
		var real = valorUnit.replace('.', '');
   	real = real.replace(',', '.');
		real = parseFloat(real);

		newOptions[index].valorTotal = quantidade * real;
		this.setState({ options: newOptions, sum: this.getSum() })
	}

	modalValorUnitEditar = (valorUnit, index) => {
		let newOptions = this.state.options;

		var real = valorUnit.replace('.', '');
   	real = real.replace(',', '.');
		real = parseFloat(real);

		newOptions[index].valorUnit = valorUnit;
		newOptions[index].valorTotal = real * this.state.options[index].quantidade;

		this.setState({ options: newOptions, sum: this.getSum() })
	}

	editarItem = (refInterna) => {
		let newOptions = this.state.options;
		if (refInterna !== 'null') {
			let produto = this.state.produtosF.filter((p) => p.refInterna === refInterna);
			if (produto.length > 0) {
				produto = produto[0];

				newOptions.push({
					produto_id: produto.produto_id,
					refInterna: produto.refInterna,
					refExterna: produto.refExterna,
					nome: produto.produto_nome,
					descricao: produto.produto_descricao,
					unidade: produto.tipo_nome,
				})
				this.setState({ 
					options: newOptions,
					produtoSE: '',
				})

				this.togglemodal();
			} else {
				this.createProduto()
			}
		} else {
			this.createProduto()
		}
	}

	mascaraReal = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
						real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return real;
	}

	createProduto = () => {
		this.setState({
			modalCadastrarNome: this.state.produtoSE,
			modalCadastrarTipo: '',
			modalCadastrarDescricao: '',
			modalCadastrarRefExterna: '',
			modalCadastrarRefInterna: '',
		})

		this.toggleModalCadastrarP();
	}

	corStatus = (status) =>{

		var status_repla = status.toLowerCase(); 

		return (
				<div className="d-flex w-100">
						<div class={"tag-status tag-" + status_repla}>
						</div>
				</div>
		)
		
	}

	listarMovimentacao = async () =>{
    let res;
    res = await api_topmotos.get('/financeiro/');
    this.setState({ movimentacoes: res.data });
  }

	atualizarMovimentacaoS = async (f) =>{
		await this.listarMovimentacao();

		var mov = await this.state.movimentacoes.filter((mov) => {
				return mov.nota_id == this.state.notaId
		})

		this.setState({movimentacoesTabela: mov})
	}

	atualizarMovimentacao = async (f) =>{
		await this.listarMovimentacao();

		var mov = await this.state.movimentacoes.filter((mov) => {
				return mov.nota_id == this.state.notaId
		})

		let valor = 0;

		if(f){
				for(let i of mov){
						valor += i.valor;
				}
				this.setState({valorPagamento: this.state.valorPagamento - valor})
		}

		this.setState({movimentacoesTabela: mov})
	}

	render() {
		return (
			<>
					<div className="overlay" style={{display: this.state.displayLoading ? 'flex' : 'none'}}>
						<div className="modal__loading">
								<img src={require("../../imgs/loading.gif")} alt=""/>
								<p>Loading</p>
						</div>
					</div>
				<section>
					<div className="content-all d-flex">
						<NavMenu props={this.props} />
						<div className="content-session">
							<NavUsuario />
							<div className="form-head">
								<p><strong>Inserir Nota</strong></p>
							</div>
							<div className="questionario">
								<p className="head">{this.state.titles[this.state.indexperg]}</p>
								<div className="perguntas no-min-height" id="primeira" style={{ display: "flex" }}>
									<div className="row">
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="selectFornecedor" id="lblSelectFornecedor">Fornecedor CNPJ *</label>
												<div className="grupo-campo-input input-cad" id="selectFornecedor">
													<i className="fas fa-user" id="iconSelectFornecedor"></i>
													<input
														type="text"
														placeholder="&nbsp;Informe o CNPJ do fornecedor"
														autoComplete="on"
														maxLength={18}
														value={this.state.selectFornecedor}
														onChange={a => {this.maskCnpjCpf(a.target.value); this.validarCPF(a)}}
														required
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												{this.state.addFornecedor}&nbsp;&nbsp;
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="numeroNota" id="lblNumeroNota">Número da Nota *</label>
												<div className="grupo-campo-input input-cad" id="numeroNota">
													<i className="fa fa-barcode" id="iconNumeroNota"></i>
													<input type="text" onChange={a => this.setState({ numeroNota: a.target.value })} />
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="dataEmissao" id="lblDataEmissao">Data Emissão *</label>
												<div className="grupo-campo-input input-cad" id="dataEmissao">
													<i className="fa fa-calendar" id="iconDataEmissao"></i>
													<input
														value={this.state.dataEmissao}
														type="date"
														onChange={event => { this.setState({ dataEmissao: event.target.value }) }}
														required
													/>
												</div>
											</div>
										</div>
									</div>

									<div className="aviso-formulario">
										<p className="aviso" id="aviso">{this.state.errorMessage}</p>
									</div>
									<div className="btn-prox-volt">
										<button onClick={() => this.fornecedor()}>Próximo<i class="fas fa-arrow-right"></i></button>
									</div>
								</div>
								<div className="perguntas no-min-height">
									<div>
										<div className="row">
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<label htmlFor="rg">Fornecedor: {this.state.fornecedor_nome} (CNPJ: {this.state.selectFornecedor})</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<label htmlFor="rg">Número da Nota: {this.state.numeroNota}</label>
												</div>
											</div>
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<label htmlFor="rg">Data de Emissão: {this.state.dataEmissao}</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<Button onClick={() => this.additem()} className="btnora">Adicionar</Button>&nbsp;&nbsp;
												</div>
											</div>
										</div>
										<div className="row">
											<div className="table">
												<table>
													<thead>
														<tr>
															<th>
																<div className="d-flex align-center justify-between">
																	Referencia Externa
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Nome
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Unidade
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Quantidade
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Valor Unitario
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Valor Total
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Ações
																</div>
															</th>
														</tr>		
														{
															this.state.options.map((produto, index) => (
																<tr key={produto.produto_ref} id={index}>
																	<th>
																		<div className="grupo-input input-style-cad" id="refExterna">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					value={produto.refExterna}
																					placeholder="Referencia Externa"
																					disabled={true}
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="nome">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.nome}
																					placeholder="Nome"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="unidade">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.unidade}
																					placeholder="Tipo Unidade"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="quantidade">
																			<div className="d-flex align-center justify-between">
																				<input type="number"
																					value={produto.quantidade}
																					onChange={(e) => {this.modalQuantidadeEditar(e.target.value, index)}}
																					placeholder="Quantidade"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="valorUnit">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					value={produto.valorUnit ? this.mascaraReal(produto.valorUnit.toString()) : null}
																					onChange={(e) => {this.modalValorUnitEditar(this.mascaraReal(e.target.value), index)}}
																					placeholder="Valor Unitario"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="valorTotal">
																			{parseFloat(produto.valorTotal)
																				.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
																		</div>
																	</th>
																	<td className="td-acoes">										
																		<div className="d-flex align-center justify-between">
																			<i className="fas fa-eye" onClick={() => this.modalDetalhes(produto.produto_id)}></i>&nbsp;
																			<i className="fas fa-trash" onClick={() => this.removeItem(index)}></i>
																		</div>
																	</td>
																</tr>
															))
														}											
													</thead>
													<tbody>
													</tbody>
												</table>
												<div className="form-footer">
													<div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														Valor Total: {parseFloat(this.state.sum)
															.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="aviso-formulario aviso-formulario-err ">
										<p className="aviso text-right">{this.state.errorMessage}</p>
									</div>
									<div className="btn-prox-volt justify-content-between">
										<button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
										<button onClick={() => {
											this.apiCadastrarNota()
										}}>Finalizar<i class="fas fa-arrow-right"></i></button>
									</div>
								</div>
							<div className="perguntas">
								<div>
									<div className="d-flex justify-content-between my-2">
										<div className="d-flex align-center line-separa">
												<div className="indicadores m-3 d-flex">
														<i class="fas fa-dollar-sign" id="receber"></i>
														<div>
																<p>Total</p>
																<p>{parseFloat(this.state.sum).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
														</div>
												</div>
												<div className="indicadores m-3 d-flex">
														<i class="fas fa-dollar-sign" id="recebido"></i>
														<div>
																<p>Restante</p>
																<p>{parseFloat(this.state.valorPagamento).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
														</div>
												</div>
										</div>

										<button className="btn-add p-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar movimentação<i class="fas fa-plus"></i></button>
									</div>
										<div className="table mt-3">
											<table>
													<thead>
															<tr>
																	<th>
																			<div className="d-flex align-center justify-between">
																					Operação
																					<i className="fas fa-sort"></i>
																			</div>
																	</th>
																	<th>
																			<div className="d-flex align-center justify-between">
																					Descrição
																					<i className="fas fa-sort"></i>
																			</div>
																	</th>
																	<th>
																			<div className="d-flex align-center justify-between">
																					Data de Vencimento
																					<i className="fas fa-sort"></i>
																			</div>
																	</th>
																	<th>
																			<div className="d-flex align-center justify-between">
																					Valor
																					<i className="fas fa-sort"></i>
																			</div>
																	</th>
																	<th>
																			<div className="d-flex align-center justify-between">
																					Ações
																			</div>
																	</th>
															</tr>
													</thead>
													<tbody>
															{
																	this.state.movimentacoesTabela.map((mov) => (
																			<tr key={mov.id}>
																					<td>{this.corStatus(mov.conta_status)}</td>
																					<td>{mov.descricao}</td>
																					<td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
																					<td>{mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
																					<td className="td-acoes">
																							<i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(mov.id); this.setState({valorDeletado: mov.valor})}}></i>
																					</td>
																			</tr>
																	))
															}
													</tbody>
											</table>
										</div>
								</div>
								<div className="btn-prox-volt justify-content-between">
										<button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
										<button onClick={() => {this.props.history.push('/notas')}}>Salvar</button>
								</div>
							</div>
					</div>
						</div>
					</div>
					<Versionamento />
				</section>

				{/* Escolha Produto */}
				<Modal isOpen={this.state.openmodal} toggle={this.togglemodal} backdrop={true} keyboard={true} size="md">
					<ModalHeader toggle={this.togglemodal}>
							Selecionar Produto
					</ModalHeader>
					<ModalBody>
							<div className="grupo-input input-style-cad">
									<label htmlFor="cliente">Produto </label>
									<div className="grupo-campo-input input-cad" id="cliente">
											<i className="fas fa-user-tag" id="iconCliente"></i>
											<input type="text"
													value={this.state.produtoSE}
													onChange={a => this.setState({ produtoSE: a.target.value })}
													onFocus={a => this.setState({ clientFocus: true })}
													onBlur={a => this.setState({ clientFocus: false })}
													placeholder="Insira o Produto"
											/>
									</div>
									<ul className={this.state.clientFocus ? "listC activate" : "listC"}>
										{this.state.produtosF.filter(y => { 
											let inUse = this.state.options.filter(o => o.refExterna === y.refExterna);
											return (y.refExterna.includes(this.state.produtoSE) || y.refInterna.includes(this.state.produtoSE) || y.produto_descricao.toLowerCase().includes(this.state.produtoSE.toLowerCase()) || y.produto_nome.toLowerCase().includes(this.state.produtoSE.toLowerCase())) && inUse.length === 0
										}).map(e => (
											<li onClick={a => {this.setState({ produtoSE: e.refInterna });}}>
													{e.produto_nome + ' - ' + e.produto_descricao}
													<br />
													{'(Interna: ' + e.refInterna + ', Externa: ' + e.refExterna + ' )'}
											</li>
										))}
									</ul>
							</div>
					</ModalBody>
					{
							this.state.produtoSE ? (
									<ModalFooter>
											<Button className="btnora" onClick={(e) => this.editarItem(this.state.produtoSE)}>Adicionar</Button>
									</ModalFooter>
							) : (null)
					}
			</Modal>

				{/*Modal Deletar*/}
				<Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
						<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
						<ModalBody>
								Deseja realmente deletar a conta<br></br>(ID: {this.state.modalDeletarId})?
						</ModalBody>
						<ModalFooter>
								<Button onClick={this.deletar} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
						</ModalFooter>
				</Modal>
		
			{/* MODAL AVISO */}
			<Modal
				isOpen={this.state.modalAviso}
				toggle={this.toggleModalAviso.bind(this)}
				backdrop={true}
				keyboard={true}
				size="sm"
				fade={false} >
				<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
				<ModalBody>
						{this.state.modalAvisoText}
				</ModalBody>
				<ModalFooter>
						<Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
				</ModalFooter>
		</Modal>

				{/* Modal de Cadastro */}
				<Modal
					isOpen={this.state.modalCadastrar}
					toggle={this.toggleModalCadastrar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Conta</ModalHeader>
						<ModalBody>
							<div className="content-formulario">
									<div className="row">
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label id="lblValorM">Valor *</label>
															<div className="grupo-campo-input input-cad" id="valorM">
															<i class="fas fa-dollar-sign" id="iconValorM"></i>
																	<input
																			type="text"
																			placeholder="&nbsp;Informe o valor"
																			autoComplete="on"
																			value={this.state.valor}
																			onChange={event => this.setState({valor: this.mascaraReal(event.target.value)})}
																			required
																	/>
															</div>
													</div>
											</div>
									</div>
									
									<div className="row">
											<div className="col-md-12">
													<div className="grupo-input input-style-cad d-flex justify-content-start">
															<label htmlFor="" id="lblRepetirLancamento">Repetir Lançamento</label>
															<div className="obrigatorio">
																	<input type="radio" name="repetirLancamento" value="2" id="parcela" onChange={event => {this.setState({repetirLancamento: event.target.value, meses: '', valorParcela: ''})}}/>
																	<label htmlFor="parcela">Parcelamento</label>
															</div>
													</div>
											</div>
									</div>

									<div className="row" style={{display: this.state.repetirLancamento ? 'block' : 'none'}}>
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label id="lblParcelaM">Quantos meses? *</label>
															<div className="grupo-campo-input input-cad" id="parcelaM">
																	<input
																			type="number"
																			autoComplete="on"
																			value={this.state.meses}
																			onChange={event => {this.setState({ meses: event.target.value }); this.valorParcela(event.target.value)}}
																			required
																	/>
															</div>
													</div>
											</div>
											<div className="col-md-6">
													<p id="valor-mensal" style={{display: this.state.valorParcela ? 'block' : 'none'}}>
															<i class="fas fa-info-circle"></i>
																{this.state.meses} vezes de {(this.state.valorParcela).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
													</p>
											</div>
									</div>

									<div className="row">
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label id="lblTipoPagamento">Tipo de pagamento *</label>
															<div className="grupo-campo-input input-cad" id="tipoPagamento" >
																	<i class="fas fa-dollar-sign" id="iconTipoPagamento"></i>
																	<select onChange={event => {this.setState({ tipo: event.target.value}); this.verificarTransferencia(event.target.value)}}>
																			<option value="">Selecione uma opção</option>
																			{this.state.tipoPagamento.map((tipo) => (
																					<option value={tipo.id}>{tipo.tipo_pagamento}</option>
																			))}
																	</select>
															</div>
													</div>
											</div>
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label id="lblConta">Centro de Custos *</label>
															<div className="grupo-campo-input input-cad" id="conta">
																	<i class="fas fa-dollar-sign" id="iconConta"></i>
																	<select onChange={event => this.setState({ conta: event.target.value })}>
																			<option value="">Selecione uma opção</option>
																			{this.state.contas.map((contas) => (
																					<option value={contas.id}>{contas.descricao}</option>
																			))}
																	</select>
															</div>
													</div>
											</div>
									</div>

									<div className="row">
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label id="lblVencimento">Data de vencimento *</label>
															<div className="grupo-campo-input input-cad" id="vencimento">
																	<i class="far fa-calendar-alt" id="iconVencimento"></i>
																	<input
																			type="date"
																			autoComplete="on"
																			onChange={event => this.setState({ dataVencimento: event.target.value })}
																			required
																	/>
															</div>
													</div>
											</div>
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label id="lblCategoria">Plano de Contas *</label>
															<div className="grupo-campo-input input-cad" id="categoria" >
																	<i class="fas fa-dollar-sign" id="iconCategoria"></i>
																	<select onChange={event => this.setState({ categoria: event.target.value })}>
																			<option value="">Selecione uma opção</option>
																			
																			{this.state.categorias.map((cat) => (
																					<>
																							<option className="select__group" value={cat.id}>{cat.categoria}</option>
																							{cat.options.map((op) => (
																									<option value={op.id}>
																											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																											{op.categoria}
																									</option>
																							))}
																					</>
																			))}
																			
																	</select>
															</div>
													</div>
											</div>
									</div>

									<div className="row">
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label>Conta Bancária</label>
															<div className="grupo-campo-input input-cad" >
																	<i class="fas fa-dollar-sign"></i>
																	<select onChange={event => this.setState({ contaB: event.target.value })}>
																			<option value="">Selecione uma opção</option>
																			{this.state.contasBancarias.map((contas) => (
																					<option value={contas.id}>{contas.descricao}</option>
																			))}
																	</select>
															</div>
													</div>
											</div>
											<div className="col-md-6">
													<div className="grupo-input input-style-cad">
															<label>Data de competência</label>
															<div className="grupo-campo-input input-cad">
																	<i class="far fa-calendar-alt"></i>
																	<input
																			type="date"
																			autoComplete="on"
																			onChange={event => this.setState({ dataCompetencia: event.target.value })}
																			required
																	/>
															</div>
													</div>
											</div>
									</div>

									<div style={{display: this.state.display}}>
											<p class="subtitle-form">Dados bancários</p>
											<div className="row">
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblBanco">Banco *</label>
																	<div className="grupo-campo-input input-cad" id="banco" >
																			<i class="fas fa-dollar-sign" id="iconBanco"></i>
																			<input 
																					onChange={event => this.setState({banco: event.target.value})} 
																					placeholder="&nbsp;Informe o Banco" 
																					type="text"
																			/>
																	</div>
															</div>
													</div>
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblAgencia">Agência *</label>
																	<div className="grupo-campo-input input-cad" id="agencia" >
																			<i class="fas fa-dollar-sign" id="iconAgencia"></i>
																			<input 
																					onChange={event => this.setState({agencia: event.target.value})} 
																					placeholder="&nbsp;Informe a Agência" 
																					type="text"
																			/>
																	</div>
															</div>
													</div>
											</div>

											<div className="row">
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblNConta">Conta *</label>
																	<div className="grupo-campo-input input-cad" id="nconta" >
																			<i class="fas fa-dollar-sign" id="iconNConta"></i>
																			<input 
																					onChange={event => this.setState({nConta: event.target.value})} 
																					placeholder="&nbsp;Informe a Conta" 
																					type="text"
																			/>
																	</div>
															</div>
													</div>
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblTipoConta">Tipo de conta *</label>
																	<div className="grupo-campo-input input-cad" id="tipoConta" >
																			<i class="fas fa-dollar-sign" id="iconTipoConta"></i>
																			<select onChange={ e => this.setState({tipoConta: e.target.value})}>
																					<option value="">Selecione o tipo da conta</option>
																					<option value="Conta Corrente">Conta Corrente</option>
																					<option value="Conta Poupança">Conta Poupança</option>
																			</select>
																	</div>
															</div>
													</div>
											</div>
									</div>
							
									<div style={{display: this.state.displayTParcela}}>
											<p class="subtitle-form">Parcelamento</p>
											<div className="row">
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblTParcela">Quantas parcelas? *</label>
																	<div className="grupo-campo-input input-cad" id="tParcela" >
																			<i class="fas fa-dollar-sign" id="iconTParcela"></i>
																			<input 
																					onChange={event => this.setState({tParcela: event.target.value})} 
																					placeholder="&nbsp;Informe a quantidade de parcelas" 
																					min="0"
																					type="number"
																			/>
																	</div>
															</div>
													</div>
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblValorTParcela">Valor da Parcela*</label>
																	<div className="grupo-campo-input input-cad" id="valorTParcela" >
																			<i class="fas fa-dollar-sign" id="iconValorTParcela"></i>
																			<input 
																					onChange={event => this.mascaraParcelamento(event.target.value)} 
																					value={this.state.valorTParcela}
																					// placeholder="&nbsp;Informe a Agência" 
																					type="text"
																			/>
																	</div>
															</div>
													</div>
											</div>
									</div>

									<div style={{display: this.state.displayTFinanciamento}}>
											<p class="subtitle-form">Financiamento</p>
											<div className="row">
													<div className="col-md-6">
															<div className="grupo-input input-style-cad">
																	<label id="lblTParcela">Banco Financiado *</label>
																	<div className="grupo-campo-input input-cad" id="tParcela" >
																			<i class="fas fa-dollar-sign" id="iconTParcela"></i>
																			<input 
																					onChange={event => this.setState({tFinanciado: event.target.value})} 
																					placeholder="&nbsp;Informe a qual o financiador" 
																					type="text"
																			/>
																	</div>
															</div>
													</div>
											</div>
									</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={this.toggleModalCadastrar.bind(this)}>Fechar</Button>
              <Button className="bg-laranja" onClick={this.saveMovimentacao}>Salvar</Button>
						</ModalFooter>
					</Modal>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.togglemodalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.togglemodalDetalhes.bind(this)}>{this.state.modalDetalhesNome} (ID: {this.state.modalDetalhesId})</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Nome</p>
								<p>{this.state.modalDetalhesNome}</p>
							</div>
							<div className="conteudo-info">
								<p>Descrição</p>
								<p>{this.state.modalDetalhesDescricao}</p>
							</div>
							<div className="conteudo-info">
								<p>Fornecedor</p>
								<p>{this.state.modalDetalhesFornecedor}</p>
							</div>
							<div className="conteudo-info">
								<p>Tipo</p>
								<p>{this.state.modalDetalhesTipo}</p>
							</div>
							<div className="conteudo-info">
								<p>Referencia Externa</p>
								<p>{this.state.modalDetalhesRefExterna}</p>
							</div>
							<div className="conteudo-info">
								<p>Referencia Interna</p>
								<p>{this.state.modalDetalhesRefInterna}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.togglemodalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* Cadastrar Produto */}
				<Modal isOpen={this.state.modalCadastrarP} toggle={this.toggleModalCadastrarP} backdrop={true} keyboard={true} fade={false} size="lg">
					<ModalHeader toggle={this.toggleModalCadastrarP}>
						Cadastrar Produtos
          </ModalHeader>
					<div className="content-formulario">
						<div>
							<div className="row">
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="nome">Nome *</label>
										<div className="grupo-campo-input input-cad" id="nome">
											<i className="fas fa-user" id="iconNome"></i>
											<input
												type="text"
												placeholder="&nbsp;Informe o Nome do Produto"
												autoComplete="on"
												value={this.state.modalCadastrarNome}
												onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
												required
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="descricao">Descrição</label>
										<div className="grupo-campo-input input-cad" id="descricao">
											<i className="fa fa-pen" id="iconDescricao"></i>
											<input
												type="text"
												placeholder="&nbsp;Informe a Descrição do Produto"
												autoComplete="on"
												value={this.state.modalCadastrarDescricao}
												onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
												required
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="refExterna">Referencia Externa *</label>
										<div className="grupo-campo-input input-cad" id="refExterna">
											<i className="fas fa-asterisk" id="iconRefExterna"></i>
											<input type="text" value={this.state.modalCadastrarRefExterna} onChange={event => this.setState({ modalCadastrarRefExterna: event.target.value })} />
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="refInterna">Referencia Interna *</label>
										<div className="grupo-campo-input input-cad" id="refInterna">
											<i className="fas fa-asterisk" id="iconRefInterna"></i>
											<input type="text" value={this.state.modalCadastrarRefInterna} onChange={event => this.setState({ modalCadastrarRefInterna: event.target.value })} />
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="fornecedor">Fornecedor  *</label>
										<div className="grupo-campo-input input-cad" id="fornecedor">
											<i className="fas fa-warehouse" id="iconFornecedor"></i>
											<input
												disabled={true}
												name="fornecedor"
												placeholder="&nbsp;Selecione o Fornecedor"
												autoComplete="on"
												required
												value={this.state.fornecedor_nome}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="tipo">Unidade *</label>
										<div className="grupo-campo-input input-cad" id="tipo">
											<i className="fas fa-balance-scale" id="iconTipo"></i>
											<select
												name="tipo"
												placeholder="&nbsp;Selecione a Unidade de Medida"
												autoComplete="on"
												required
												value={this.state.modalCadastrarTipo}
												onChange={a => this.setState({ modalCadastrarTipo: a.target.value })}
											>
												<option value="NULL">Selecione a Unidade de Medida</option>
												{
													this.state.tipos.map((e, id) => (
														<option key={e.tipo_id} value={e.tipo_id}>{e.tipo_nome}</option>
													))
												}
											</select>
										</div>
									</div>
								</div>
							</div>
							<Button onClick={(e) => this.apiCadastrarProduto(e)} className="cadbtn mt-1">Cadastrar Produto</Button>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

export default NotaCadastroPage
