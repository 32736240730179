import React from 'react';
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

class NotificacaoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            estoque: [],
            itens: [],
            itensD: [],
            mov: [],
            movf: [],
            dia: moment().format('YYYY-MM-DD'),
            btn: 'd',
            btnItem: 45,
            leadsitens: [],
            leadsitensInEstoque: [],
            allNotificacao: false,
            readNotificacao: [],
            permissaoF: false,
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            let pN = res.data.filter(a => a.rota == "/notificacao")

            let pF = res.data.filter(a => a.rota == "/movimentacao/")
            let pEI = res.data.filter(a => a.rota == "/estoque")

            let resP = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
            let permissaoP = resP.data.filter(a => a.restricao_id == 7);

            this.setState({
                permissaoF: pF.length == 0 ? false : true,
                permissaoEI: pEI.length == 0 ? false : true, 
                permissaoP: permissaoP.length == 0 ? false : true, 
            })
            if(pN.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            await this.apiListarNotificacaoLidas();
            this.apiListarMov();
            await this.apiListItens();
            this.apiListarLeadsItens();
            this.apiListEstoqueMin();
        }
    }

    filtrarNotificacaoLidas = (filter) => {
        if (!filter === this.state.allNotificacao) return;
        this.setState({allNotificacao: !filter})

        let mov = this.state.mov
        let estoque = this.state.estoqueNotRead
        let itens = this.state.itensNotRead
        let leadsitensInEstoque = this.state.leadsitensInEstoqueNotRead
        let leadsitens = this.state.leadsitensNotRead
        if (!filter)  {
            mov = this.state.movAll
            estoque = this.state.estoqueAll
            itens = this.state.itensAll
            leadsitensInEstoque = this.state.leadsitensInEstoqueAll
            leadsitens = this.state.leadsitensAll
        }

        this.setState({estoque, itens, leadsitensInEstoque, leadsitens})
        this.filtrar(this.state.btn, mov)
        this.filtraItens(this.state.btnItem, itens);
    }

    apiListarNotificacaoLidas = async () => {
        let res = await api_topmotos.get('/notificacao/id/' + localStorage.getItem('usuario_id'));
        this.setState({readNotificacao: res.data});
    }

    reloadByType = async (type) => {
        switch (type) {
            case 'financeiro':
                await this.apiListarMov()
                break;
            case 'modelosano':
                await this.apiListEstoqueMin()
                break;
            case 'itens':
                await this.apiListItens()
                break;
            case 'leadsitensInEstoque':
            case 'leadsitens':
                await this.apiListarLeadsItens()
               break;
            default:
        }
        this.filtrarNotificacaoLidas(!this.state.allNotificacao);
    }

    removeReadNotificacao = async (type, id) => {
        await api_topmotos.post('/notificacao/delete/id/' + localStorage.getItem('usuario_id'), {
            notificacao_type: type,
            reference_id: id,
        });
        await this.apiListarNotificacaoLidas();
        this.reloadByType(type);
    }

    saveReadNotificacao = async (type, id) => {
        await api_topmotos.post('/notificacao/id/' + localStorage.getItem('usuario_id'), {
            notificacao_type: type,
            reference_id: id,
        });
        await this.apiListarNotificacaoLidas();
        this.reloadByType(type);
    }

    apiListarMov = async () => {
        let res = await api_topmotos.get('/financeiro/');

        res = res.data.filter(a => (a.conta_status == 'AP' || a.conta_status == 'AR') && a.mov_cancelada != 1)

        let resNotRead = res.filter(mov => {
            let status = this.state.readNotificacao.every(notificacao => {
                return !(notificacao.notificacao_type == "financeiro" && notificacao.reference_id == mov.id);
            });
            if (!status) mov.read = true
            return status;
        });

       this.setState({mov: resNotRead, movAll: res})
       this.filtrar(this.state.btn, resNotRead);
    }

    apiListItens = async () => {
        let res = await api_topmotos.get('/itens/');

        let resNotRead = res.data.filter(mol => {
            return this.state.readNotificacao.every(notificacao => {
                let status = !(notificacao.notificacao_type == "itens" && notificacao.reference_id == mol.item_id);
                if (!status) mol.read = true
                return status;
            });
        });

        let i = resNotRead.filter(y => y.compra == 1)
        this.setState({ itens: i, itensNotRead: i, itensAll: res.data.filter(y => y.compra == 1)});

        this.filtraItens(this.state.btnItem);
    }

    apiListEstoqueMin = async () => {
        let res = await api_topmotos.get('/modelosano/');

        let resNotRead = res.data.filter(mol => {
            return this.state.readNotificacao.every(notificacao => {
                let status = !(notificacao.notificacao_type == "modelosano" && notificacao.reference_id == mol.id_cor_ano);
                if (!status) mol.read = true
                return status;
            });
        });

        let e = resNotRead.filter(y => y.quantidade < y.estoque_minimo)
        this.setState({ estoque: e, estoqueNotRead: e, estoqueAll: res.data.filter(y => y.quantidade < y.estoque_minimo)});
    }

    filtraItens = async (btnItem, itens = this.state.itens) => {
        var filter = itens.filter(y => {
            let dias = 90 - Math.floor(moment.duration(moment().diff(moment(y.venda_data))).asDays());
            return dias === btnItem && y.terceira_pessoa;
        });

        this.setState({btnItem: btnItem})
        this.setState({itensD: filter});
    }

    filtrar = (btn, mov = null) =>{
        if (mov === null) {
            if (this.state.allNotificacao) mov = this.state.movAll
            else mov = this.state.mov
        }

        if(btn == 'd'){
            mov = mov.filter(a => moment(a.dataVencimento).format('YYYY-MM-DD') == this.state.dia)
        } else if(btn == 's'){
            let data = new Date();
            data.setDate(data.getDate() + 7);

            mov = mov.filter(a => moment(a.dataVencimento).format('YYYY-MM-DD') >= this.state.dia && moment(a.dataVencimento).format('YYYY-MM-DD') <= moment(data).format('YYYY-MM-DD'))

        } else if(btn == 'm'){
            let data = new Date();
            data.setMonth(data.getMonth() + 1);

            mov = mov.filter(a => moment(a.dataVencimento).format('YYYY-MM-DD') >= this.state.dia && moment(a.dataVencimento).format('YYYY-MM-DD') <= moment(data).format('YYYY-MM-DD'))

        } else{
            mov = mov.filter(a => moment(a.dataVencimento).format('YYYY-MM-DD') <= this.state.dia)
        }

        this.setState({movf: mov, btn: btn})
    }

    apiListarLeadsItens = async () => {
        let res = await api_topmotos.get('/leadsitens/');
        
        let filter = res.data.filter(a => {
            return Math.floor(moment.duration(moment().diff(moment(a.leaditens_admissaodata))).asDays()) < 30;
        })

        let modelos = this.state.itens.map(a => {
            return a.item_modelo && a.status !== 3;
        })
        let filterInEstoque = filter.filter((e) => {
            return modelos.includes(e.nome);
        })

        let resInEstoqueNotRead = filterInEstoque.filter(lead => {
            return this.state.readNotificacao.every(notificacao => {
                let status = !(notificacao.notificacao_type == "leadsitensInEstoque" && notificacao.reference_id == lead.id);
                if (!status) lead.read = true
                return status;
            });
        });

        this.setState({ leadsitensInEstoque: resInEstoqueNotRead, leadsitensInEstoqueNotRead: resInEstoqueNotRead, leadsitensInEstoqueAll: filterInEstoque })

        filter = filter.filter((e) => {
            return !modelos.includes(e.nome) || e.status === 3;
        })
        let resNotRead = filter.filter(lead => {
            return this.state.readNotificacao.every(notificacao => {
                let status = !(notificacao.notificacao_type == "leadsitens" && notificacao.reference_id == lead.id);
                if (!status) lead.read = true
                return status;
            });
        });
        this.setState({ leadsitens: resNotRead, leadsitensNotRead: resNotRead, leadsitensAll: filter })
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />

                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Notificações</p>
                                </div>
                                <div className="notificacao__buttons">
                                    <button className={`notificacao__btn ${!this.state.allNotificacao ? "notificacao__btn--active" : ''}`} onClick={() => this.filtrarNotificacaoLidas(true)}> Notificações não Lidas </button>
                                    <button className={`notificacao__btn ${this.state.allNotificacao ? "notificacao__btn--active" : ''}`} onClick={() => this.filtrarNotificacaoLidas(false)}> Todas as Notificações </button>
                                </div>
                            </div>

                            <div className="row">
                                {this.state.permissaoF ? (<div className="col-md-6">
                                    <div className="notificacao">
                                        <div className="notificacao__header">
                                            <div className="notificacao__icone">
                                                <i className="fas fa-dollar-sign"></i>
                                            </div>
                                            <p>Movimentações</p>
                                        </div>
                                        <div className="notificacao__buttons">
                                            <button className={`notificacao__btn ${this.state.btn == "d" ? "notificacao__btn--active" : ''}`} onClick={() => this.filtrar('d')}> Dia </button>
                                            <button className={`notificacao__btn ${this.state.btn == "s" ? "notificacao__btn--active" : ''}`} onClick={() => this.filtrar('s')}> Semana </button>
                                            <button className={`notificacao__btn ${this.state.btn == "m" ? "notificacao__btn--active" : ''}`} onClick={() => this.filtrar('m')}> Mês </button>
                                            <button className={`notificacao__btn ${this.state.btn == "a" ? "notificacao__btn--active" : ''}`} onClick={() => this.filtrar('a')}> Atrasados </button>
                                        </div>
                                        <div className="notificacao__body">
                                           {this.state.movf.length == 0 ? <p>Não há movimentações</p> : this.state.movf.map((mov) => (
                                                <div className="notificacao__und">
                                                    <div className="notificacao__data">
                                                        <p>{moment(mov.dataVencimento).format('DD')}</p>
                                                        <p>{(new Date(mov.dataVencimento).toLocaleString('pt-BR', {month: 'long', year: 'numeric'})).slice(0, 3)}<br />{moment(mov.dataVencimento).format('YYYY') != moment(this.state.dia).format('YYYY') ? (new Date(mov.dataVencimento).toLocaleString('pt-BR', {year: 'numeric'})) : ''}</p>
                                                    </div>
                                                    <div className="notificacao__info">
                                                        <div className="notificacao__descricao">
                                                            <p>{mov.descricao}</p>
                                                            <p className={mov.conta_status == 'AP' ? "notificacao__descricao--pagar" : "notificacao__descricao--receber" }>{mov.conta_status == 'AP' ? "Pagar" : "Receber" }</p>
                                                        </div>
                                                        <div className="notificacao__valor">
                                                            <p>{parseFloat(mov.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                    {mov.read  ?
                                                    (<button className="notificacao__lida" onClick={() => {this.removeReadNotificacao('financeiro', mov.id)}}>
                                                        <i class="fas fa-eye"></i>
                                                    </button>) :
                                                    (<button className="notificacao__lida" onClick={() => {this.saveReadNotificacao('financeiro', mov.id)}}>
                                                        <i class="fas fa-eye-slash"></i>
                                                    </button>)}
                                                </div>
                                           ))} 
                                        </div>
                                    </div>
                                </div>): null}
                                { this.state.permissaoP ? <div className="col-md-6">
                                    <div className="notificacao">
                                        <div className="notificacao__header">
                                            <div className="notificacao__icone">
                                                <i class="fas fa-hourglass-half"></i>
                                            </div>
                                            <p>Procurações</p>
                                        </div>
                                        <div className="notificacao__buttons">
                                            <button className={`notificacao__btn ${this.state.btnItem == 45 ? "notificacao__btn--active" : ''}`} onClick={() => this.filtraItens(45)}> 45 dias </button>
                                            <button className={`notificacao__btn ${this.state.btnItem == 80 ? "notificacao__btn--active" : ''}`} onClick={() => this.filtraItens(80)}> 80 dias </button>
                                        </div>
                                        <div className="notificacao__body">
                                            {this.state.itensD.length == 0 ? <p>Não há Procurações</p> :  this.state.itensD.map((e) => (
                                                <div className="notificacao__und">
                                                    <div className="notificacao__data">
                                                        <p>{moment(e.venda_data).format('DD')}</p>
                                                        <p>{(new Date(e.venda_data).toLocaleString('pt-BR', {month: 'long', year: 'numeric'})).slice(0, 3)}<br />{moment(e.venda_data).format('YYYY') != moment(this.state.dia).format('YYYY') ? (new Date(e.venda_data).toLocaleString('pt-BR', {year: 'numeric'})) : ''}</p>
                                                    </div>
                                                    <div className="notificacao__info">
                                                        <div className="notificacao__descricao">
                                                            <p>{e.item_marca} {e.item_modelo}</p>
                                                            <p>{e.item_id} - {e.placa}</p>
                                                        </div>
                                                        <div className="notificacao__valor">
                                                            <p>{90 - Math.floor(moment.duration(moment().diff(moment(e.venda_data))).asDays())} dias restantes</p>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                    {e.read  ?
                                                    (<button className="notificacao__lida" onClick={() => {this.removeReadNotificacao('itens', e.item_id)}}>
                                                        <i class="fas fa-eye"></i>
                                                    </button>) :
                                                    <button className="notificacao__lida" onClick={() => {this.saveReadNotificacao('itens', e.item_id)}}>
                                                        <i class="fas fa-eye-slash"></i>
                                                    </button>}
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>: null }
                                <div className="col-md-6">
                                    <div className="notificacao">
                                        <div className="notificacao__header">
                                            <div className="notificacao__icone">
                                                <i class="fa fa-road"></i>
                                            </div>
                                            <p>Leads em espera (ultimos 30 dias)</p>
                                        </div>
                                        <div className="notificacao__buttons">
                                        </div>
                                        <div className="notificacao__body">
                                            {this.state.leadsitens.length == 0 ? <p>Não há leads em espera</p> : this.state.leadsitens.map((e) => (
                                                <div className="notificacao__und">
                                                    <div className="notificacao__data">
                                                        <p>{moment(e.leaditens_admissaodata).format('DD')}</p>
                                                        <p>{(new Date(e.leaditens_admissaodata).toLocaleString('pt-BR', {month: 'long', year: 'numeric'})).slice(0, 3)}<br />{moment(e.leaditens_admissaodata).format('YYYY') != moment(this.state.dia).format('YYYY') ? (new Date(e.leaditens_admissaodata).toLocaleString('pt-BR', {year: 'numeric'})) : ''}</p>
                                                    </div>
                                                    <div className="notificacao__info">
                                                        <div className="notificacao__descricao">
                                                            <p>{e.lead_nome}</p>
                                                            <p>{e.nome}</p>
                                                        </div>
                                                        <div className="notificacao__valor">
                                                            <p style={{fontSize: "10px"}}>{e.lead_telefone}</p>
                                                            <p style={{fontSize: "15px", textAlign: "center", color: e.status_retorno === "Não" ? "#ff7115" : "#77ADFF"}}>{e.status_retorno === "Sim" ? "Retorno" : "Sem Retorno"}</p>
                                                        </div>
                                                    </div>
                                                    {e.read  ?
                                                    (<button className="notificacao__lida" onClick={() => {this.removeReadNotificacao('leadsitens', e.id)}}>
                                                        <i class="fas fa-eye"></i>
                                                    </button>) :
                                                    <button className="notificacao__lida" onClick={() => {this.saveReadNotificacao('leadsitens', e.id)}}>
                                                        <i class="fas fa-eye-slash"></i>
                                                    </button>}
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="notificacao">
                                        <div className="notificacao__header">
                                            <div className="notificacao__icone">
                                                <i class="fa fa-road"></i>
                                            </div>
                                            <p>Leads com estoque (ultimos 30 dias)</p>
                                        </div>
                                        <div className="notificacao__buttons">
                                        </div>
                                        <div className="notificacao__body">
                                            {this.state.leadsitensInEstoque.length == 0 ? <p>Não há leads com estoque</p> : this.state.leadsitensInEstoque.map((e, index) => (
                                                <div className="notificacao__und">
                                                    <div className="notificacao__data">
                                                        <p>{moment(e.leaditens_admissaodata).format('DD')}</p>
                                                        <p>{(new Date(e.leaditens_admissaodata).toLocaleString('pt-BR', {month: 'long', year: 'numeric'})).slice(0, 3)}<br />{moment(e.leaditens_admissaodata).format('YYYY') != moment(this.state.dia).format('YYYY') ? (new Date(e.leaditens_admissaodata).toLocaleString('pt-BR', {year: 'numeric'})) : ''}</p>
                                                    </div>
                                                    <div className="notificacao__info">
                                                        <div className="notificacao__descricao">
                                                            <p>{e.lead_nome}</p>
                                                            <p>{e.nome}</p>
                                                        </div>
                                                        <div className="notificacao__valor">
                                                            <p style={{fontSize: "10px"}}>{e.lead_telefone}</p>
                                                            <p style={{fontSize: "15px", textAlign: "center", color: e.status_retorno === "Não" ? "#ff7115" : "#77ADFF"}}>{e.status_retorno === "Sim" ? "Retorno" : "Sem Retorno"}</p>
                                                        </div>
                                                    </div>
                                                    {e.read  ?
                                                    (<button className="notificacao__lida" onClick={() => {this.removeReadNotificacao('leadsitensInEstoque', e.id)}}>
                                                        <i class="fas fa-eye"></i>
                                                    </button>) :
                                                    <button className="notificacao__lida" onClick={() => {this.saveReadNotificacao('leadsitensInEstoque', e.id)}}>
                                                        <i class="fas fa-eye-slash"></i>
                                                    </button>}
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>
                                {this.state.permissaoEI ? <div className="col-md-6">
                                    <div className="notificacao">
                                        <div className="notificacao__header">
                                            <div className="notificacao__icone">
                                                <i class="fas fa-motorcycle"></i>
                                            </div>
                                            <p>Itens sem Estoque Ideal</p>
                                        </div>
                                        <div className="notificacao__buttons">
                                        </div>
                                        <div className="notificacao__body">
                                        {this.state.estoque.length == 0 ? <p>Não há Itens sem Estoque Ideal</p> :  this.state.estoque.map((e) => (
                                                <div className="notificacao__und">
                                                    <div className="notificacao__info">
                                                        <div className="notificacao__descricao">
                                                            <p>{e.marcaName} {e.nome}</p>
                                                            <p>{e.cor} - {e.ano}</p>
                                                        </div>
                                                        <div className="notificacao__valor">
                                                            <p>Ideal: {e.estoque_minimo}</p>
                                                            <p>Atual: {e.quantidade}</p>
                                                        </div>
                                                    </div>
                                                    {e.read  ?
                                                    (<button className="notificacao__lida" onClick={() => {this.removeReadNotificacao('modelosano', e.id_cor_ano)}}>
                                                        <i class="fas fa-eye"></i>
                                                    </button>) :
                                                    <button className="notificacao__lida" onClick={() => {this.saveReadNotificacao('modelosano', e.id_cor_ano)}}>
                                                        <i class="fas fa-eye-slash"></i>
                                                    </button>}
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                </div>: null}
                            </div>
                            
                            <Versionamento/>
                        </div>    
                    </div>
                </section>
            </>
        )
    }
}

export default NotificacaoPage
