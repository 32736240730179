import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';
import { api_viacep } from '../../services/api';
import InputMask from 'react-input-mask';
import moment from 'moment';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nomeVendedor: localStorage.getItem('usuario_nome'),

            nome: '',
            email: '',
            telefone: '',
            itens: [],
            marcas: [],
            cores: [],
            errorMessage: '',
            modelos: [],
            
            marcaSE: "NULL",
            modeleSE: '',
            options: [],
            conhecimentoLoja: '',
            data_retorno: '',
            retorno: '',

            pessoas: [],
            cpf: '',

            modalCadastrarNome: '',
            modalCadastrarTelefone: '',
            modalCadastrarCelular: '',
            modalCadastrarEmail: '',
            modalCadastrarRg: '',
            modalCadastrarEnderecoId: '',
            modalCadastrarCep: '',
            modalCadastrarEstado: '',
            modalCadastrarCidade: '',
            modalCadastrarBairro: '',
            modalCadastrarLogradouro: '',
            modalCadastrarNumero: '',
            modalCadastrarComplemento: '',

            clientFocus: false,
            idIten: 0,

            yesp: false,
            modelSE: 'NULL',
            tipo_i: '',

            marcaSE: 'NULL',
            clientFocus2: false,
            modelosvei: [],
            modeloSE: '',

            cores: [],
            coresSE: 'NULL',

            combustiveis: [],
            combustiveisSE: 'NULL',

            estilos: [],
            estilosSE: 'NULL',

            propriedades: [],
            propriedadesSE: 'NULL',
            renavam: '',
            chassi: '',
            valorMoto: '',
            valorCompra: '',

            anos: [],
            anosSE: '',
            modeloId: '',
            pessoaId: '',

            idmoto: '',

            placa: '',
            is_pessoa: false,

            indexperg: 0,
            titles: ['Cadastrar Lead', 'Verificar Cliente', 'Selecionar Caminho', 'Termo de Venda', 'Pagamento'],
            motivo: [],
            motivoSE: '',
            cpfLead: '',
            statusVenda: [],
            status: '',
            modalCadastrarItens: [],
            modalCadastrarItensKm: [],
            openmodal: false,
            openmodalConsorcio: false,
            modeleSE: '',
            timing: null,
            lettersInfo: null,
            
            modalCadastrar: false,
            tipoPagamento: [],
            tipo: '',

            contas: [],
            categorias:[],
            usuarios: [],
            empresas: [],
            vendedor: '',
            empresaId: '',
            vendaId: '',

            descricao: '',
            dataVencimento: '',
            dataPagamento: '',
            valor: '',
            valorPago: '',
            tipo: '',
            statusA: '',
            categoria:'',
            conta: '',
            
            optionsLead: [],
            movimentacoes: [],
            movimentacoesTabela: [],

            itensSE: [],
            leadId: '',
            statusMov: '',
            aviso: '',
            avisoLead: '',
            cpfValido: false,

            valorVenda: '',
            rg:'',

            troca: '',
            modeloTroca: '',
            idVenda: '',
            modeloVenda: '',
            itemTroca: '',
            itemId: '',
            compra_id: '',
            valorAr: '',
            descricaoTroca: '',
            categoriaTroca: '',
            compraId: '',
            trocaV: false,

            contasBancarias: [],
            contaB: '',
            dataCompetencia: '',

            repetirLancamento: '',
            meses: '',
            dataRepeticao: [],
            parcela_id: '',
            valorParcela: '',

            display: 'none',
            nConta: '',
            banco: '',
            agencia: '',
            tipoConta: '',
            tipoPag: '',
            movId: '',

            empresas: [],
            empresa: '',

            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',
            valorDeletado: '',
            venda_id: '',
            mov: '',
            valorMov: '',
            permitir: '',
            
            displayTParcela: 'none',
            displayTFinanciamento: "none",
            tipoParcelamento: '',
            valorTParcela: '',
            tParcela: '',
            
            displayLoading: false,

            trocaS: false,
            movC: '',
            statusCompra: '',
            valorTroca: '',

            consorcios: [],
            consorcio: '',
            observacao: '',
            grupo: '',
            cota: '',
            lettersInfoConsorcio: null,

            nomeConsorcio: '',
            valorConsorcio: '',
            comissaoConsorcio: '',
            dataRepeticaoConsorcio: '',
            parcela_id_consorcio: [],
            venda_consorcio_id: '',
            
            tFinanciado: '',
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            await this.apiListarPessoas();
            await this.apiListarModelos();
            await this.apiListarMarcas();
            await this.apiListarMotivo();
            await this.apiListarStatus();
            await this.apiListarItens();
            await this.apiListarUsuarios();
            await this.apiListarEmpresas();
            await this.apiListarConsorcios();
            await this.listarCategorias();
            await this.listarContas();
            await this.listarContasBancarias();
            await this.listarPagamento();
            await this.listarMovimentacao();
            this.apiConsultarItem();
            this.apiListarPermissao();

            if (this.props.match.params.id){
                this.apiConsultarItem()
            }
        }
    }

    // Listar todas as contas
    listarMovimentacao = async () =>{
        let res;
        
        res = await api_topmotos.get('/financeiro/');

        this.setState({ movimentacoes: res.data });
    }

    apiConsultarItem = async () => {
        let {data} = await api_topmotos.post('/questitem', {id: this.props.match.params.id})

        if (data == 'Invalid'){
            return
        }

        let {respostasPreenchidasVei, respostasPreenchidasDoc} = this.state

        for (let i of data.ckvei){
            respostasPreenchidasVei[`1-${i.id_opcao_checklist}`] = i.resposta;
        }

        for (let i of data.ckdoc){
            respostasPreenchidasDoc[`2-${i.cod_check_list}`] = {'opt': i.checklist_item, 'txt': i.respostas_checklist_documento}
        }

        this.setState({
            rg: data.item[0].pessoa_rg, 
            cpf: data.item[0].pessoa_cpf_cnpj, 
            modalCadastrarTipo: data.item[0].item_tipo,
            marcaSE: this.state.marcas.filter(y => y.nome == data.item[0].item_marca)[0].id,
            estilosSE: data.item[0].estilo_id,
            modalCadastrarEstilo: data.item[0].estilo_id,
            modalCadastrarModelo: data.item[0].item_modelo,
            modalCadastrarKm: data.item[0].item_km,
            modalCadastrarAno: data.item[0].item_ano,
            placa: data.item[0].placa,
            coresSE: data.item[0].cor_id,
            modalCadastrarCor: data.item[0].cor_id,
            combustiveisSE: data.item[0].combustivel_id,
            modalCadastrarCombustivel: data.item[0].combustivel_id,
            modalCadastrarDescricao: data.item[0].item_descricao,
            idmoto: data.item[0].item_id,
            modalCadastrarMarca: data.item[0].item_marca,
            respostasPreenchidasVei: respostasPreenchidasVei,
            
            modeloSE: data.item[0].item_modelo,
            modeloId: this.state.modelosvei.filter(y => y.nome == data.item[0].item_modelo)[0].id,
        })

        try {
            
        } catch (error) {
            
        }


        try {
            this.setState({
                obsDoc: data.obss[1].obs,
                obsVei: data.obss[0].obs,
            })
        } catch (error) {
            
        }

        this.abrirEstilo(data.item[0].item_tipo)
        this.checkPessoa({target: {value: data.item[0].pessoa_cpf_cnpj}})
    }

    // Pessoas
    apiListarPessoas = async () => {
        let res = await api_topmotos.get('/pessoas/');
        this.setState({ pessoas: res.data });
    }

    // Marcas
    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    // Modelos
    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    // Motivo de não venda
    apiListarMotivo = async event => {
        let res = await api_topmotos.get('/motivonVenda/');
        this.setState({ motivo: res.data });
    }
    
    // Listar Status de venda
    apiListarStatus = async () =>{
        let res = await api_topmotos.get('/vendaStatus/');
        this.setState({ statusVenda: res.data });
    }

    // Listar Contas Bancárias
    listarContasBancarias = async () =>{
        let res = await api_topmotos.get('/contasBancarias/');
        this.setState({ contasBancarias: res.data });
    }

    // Empresas
    apiListarEmpresa = async () => {
        let res = await api_topmotos.get('/empresas/');
        this.setState({ empresas: res.data });
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiCadastrarLeads = async () => {
        const {
            nome, 
            telefone, 
            email,
            itensSE
        } = this.state

        var inputs = [
            [nome, 'nome', 'iconNome', 'lblNome'],
            [telefone, 'telefone', 'iconTelefone', 'lblTelefone']
        ] 

        if(!nome ||
            !telefone ||
            !itensSE){
                this.setState({ error: "preencha todos os dados para cadastrar!" });
                this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
                this.log(this.state.error);

                for(var i = 0; i < inputs.length; i++){
                    if(!inputs[i][0] || inputs[i][0] == 'NULL' || inputs[i][0] == [] ){
                        var inputId = document.getElementById(inputs[i][1]);
                        var inputIcone = document.getElementById(inputs[i][2]);
                        var inputNome = document.getElementById(inputs[i][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    } else{
                        var inputId = document.getElementById(inputs[i][1]);
                        var inputIcone = document.getElementById(inputs[i][2]);
                        var inputNome = document.getElementById(inputs[i][3]);
                        inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    }
                }

        } else{
            try{
                this.toggleLoading();

                for(var i = 0; i < inputs.length; i++){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }

                await api_topmotos.post('/leads/create', {
                    nome: this.state.nome,
                    telefone: this.state.telefone,
                    data: this.getTimestamp(),
                    email: this.state.email,
                    itens: this.state.itensSE,
                    loja: this.state.conhecimentoLoja ? this.state.conhecimentoLoja : null,
                    retorno: this.state.retorno ? this.state.retorno : null, 
                    data_retorno: this.state.data_retorno ? this.state.data_retorno : null,
                    motivo: this.state.motivoSE ? this.state.motivoSE : null ,
                    cpf: this.state.cpfLead ?  this.state.cpfLead : null
                }).then(res => {
                    this.setState({leadId: res.data.returnId})
                    this.toggleLoading();
                });
            } catch(err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/leads/create)" });
                this.log(this.state.error);

            }
        }
    }

    apiCadastrarStatus = async () =>{
        var inputs = [
            [this.state.status, 'status_venda', 'iconStatus_venda']
        ]

        if(this.state.status){
            try{

                await api_topmotos.post('/vendas/create', {
                    status: this.state.status ? this.state.status : null,
                    venda_admissaodata: this.getTimestamp(),
                    venda_vendedor: localStorage.getItem('usuario_id'),
                    venda_cliente: this.state.pessoaId,
                    empresa: localStorage.getItem('usuario_empresa')
                }).then(res => {
                    this.setState({vendaId: res.data.returnId})
                });
                
                this.props.history.push('/vendas')  
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }
        } else{
            for(var i = 0; i < inputs.length; i++){
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
            }
        }

    }

    apiCadastrarVenda = async () =>{
        const {
            modalCadastrarValor,
            empresa
        } = this.state

        var inputs = [
            [modalCadastrarValor, 'valor', 'iconValor', 'lblValor']
        ]

        if(this.state.vendaId){
            this.slide(+1);
        } else{
            if(this.state.modalCadastrarValor){
                
                this.toggleLoading();

                for(var i = 0; i < inputs.length; i++){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
    
                await api_topmotos.post('/vendas/create', {
                    status: this.state.status ? this.state.status : null,
                    venda_cliente: this.state.pessoaId,
                    venda_vendedor: localStorage.getItem('usuario_id'),
                    venda_admissaodata: this.getTimestamp(),
                    empresa: this.state.empresa,
                    venda_valor: this.state.modalCadastrarValor
                }).then(res => {
                    this.setState({vendaId: res.data.returnId})
    
                    var item = this.state.itens.filter((it) => {
                        return it.item_id == this.state.modalCadastrarItens[0]
                    })
    
                    this.setState({descricao: res.data.returnId + ' - Venda - ' + item[0].item_modelo, valorVenda: modalCadastrarValor, idVenda: res.data.returnId, modeloVenda: item[0].item_modelo})
                    this.toggleLoading();
                    this.slide(+1)
                });
        
                let max = this.state.modalCadastrarItens.length;
                for (let i = 0; i < max; i++) {
                    try {
                        await api_topmotos.post('/vendaitens/create', {
                            vendaitens_venda: this.state.vendaId,
                            vendaitens_item: this.state.modalCadastrarItens[i],
                            vendaitens_admissaodata: this.getTimestamp(),
                            vendaitens_km: this.state.modalCadastrarItensKm[i],
                        })
    
                        await api_topmotos.put('/itens/update/status/id/'+this.state.modalCadastrarItens[i])
                    } catch (err) {
                        this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendaitens/create)" })
                        this.log(this.state.error);
                    }
                }
                
            } else{
                for(var i = 0; i < inputs.length; i++){
                    if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                        var inputId = document.getElementById(inputs[i][1]);
                        var inputIcone = document.getElementById(inputs[i][2]);
                        var inputNome = document.getElementById(inputs[i][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                        
                    } else{
                        var inputId = document.getElementById(inputs[i][1]);
                        var inputIcone = document.getElementById(inputs[i][2]);
                        var inputNome = document.getElementById(inputs[i][3]);
                        inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    }
                }
            }
        }
    }

    apiCadastrarPessoa = async e => {

        const {
            modalCadastrarNome,
            modalCadastrarTelefone,
            modalCadastrarCelular,
            modalCadastrarEmail,
            cpf,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            modalCadastrarComplemento,
            cpfValido,
            rg
        } = this.state;

        var inputs = [
            [cpf,'cpfCliente','iconCpfCliente','lblCpfCliente'],
            [cpfValido,'cpfCliente','iconCpfCliente','lblCpfCliente'],
            [modalCadastrarNome,'nomeCliente','iconNomeCliente','lblNomeCliente'],
            [modalCadastrarTelefone,'celularCliente','iconCelularCliente','lblCelularCliente'],
            [modalCadastrarCep,'cep','iconCep','lblCep'],
            [modalCadastrarEstado,'estado','iconEstado','lblEstado'],
            [modalCadastrarCidade,'cidade','iconCidade','lblCidade'],
            [modalCadastrarLogradouro,'logradouro','iconLogradouro','lblLogradouro'],
            [modalCadastrarNumero,'numero','iconNumero','lblNumero'],
            [modalCadastrarBairro,'bairro','iconBairro','lblBairro']
        ]

        if (!modalCadastrarNome ||
            !modalCadastrarTelefone ||
            !cpf ||
            !modalCadastrarCep ||
            !modalCadastrarEstado ||
            !modalCadastrarCidade ||
            !modalCadastrarBairro ||
            !modalCadastrarLogradouro ||
            !modalCadastrarNumero ||
            modalCadastrarEstado === 'NULL' ||
            modalCadastrarTelefone === '(__) _____-____' ||
            modalCadastrarCep === '_____-___') {
            

            if (this.state.pessoaId){
                this.slide(+1);
            }
            else{
                for(var i = 0; i < inputs.length; i++){
                    if(this.state.pessoaId === '' || this.state.pessoaId == null && !cpfValido){
                        var inputId = document.getElementById(inputs[0][1]);
                        var inputIcone = document.getElementById(inputs[0][2]);
                        var inputNome = document.getElementById(inputs[0][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    } else{
                        for(var i = 0; i < inputs.length; i++){
                            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                                var inputId = document.getElementById(inputs[i][1]);
                                var inputIcone = document.getElementById(inputs[i][2]);
                                var inputNome = document.getElementById(inputs[i][3]);
                                inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                                inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                                inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                            } else{
                                var inputId = document.getElementById(inputs[i][1]);
                                var inputIcone = document.getElementById(inputs[i][2]);
                                var inputNome = document.getElementById(inputs[i][3]);
                                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                            }
                        }
                    }
                }
            }

            this.setState({ error: "preencha todos os dados para se cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para se cadastrar!" });
            this.log(this.state.error);

        } else {
            
            this.toggleLoading();
            this.setState({ errorMessage: '' });
            
            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }

            try {
                await api_topmotos.post('/enderecos/create', {
                    endereco_cep: modalCadastrarCep,
                    endereco_estado: modalCadastrarEstado,
                    endereco_cidade: modalCadastrarCidade,
                    endereco_bairro: modalCadastrarBairro,
                    endereco_logradouro: modalCadastrarLogradouro,
                    endereco_numero: modalCadastrarNumero,
                    endereco_complemento: modalCadastrarComplemento,
                    endereco_admissaodata: this.getTimestamp(),

                }).then(res => {
                    this.setState({ modalCadastrarEnderecoId: res.data.returnId });
                    this.slide(+1);
                });
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }

            try {
                await api_topmotos.post('/pessoas/create', {
                    pessoa_nome: modalCadastrarNome,
                    pessoa_telefone: modalCadastrarTelefone,
                    pessoa_celular: modalCadastrarCelular,
                    pessoa_email: modalCadastrarEmail,
                    pessoa_cpf: cpf,
                    pessoa_endereco: this.state.modalCadastrarEnderecoId,
                    pessoa_admissaodata: this.getTimestamp(),
                    lead: this.state.leadId ? this.state.leadId : null ,
                    idpessoa: this.state.pessoaId,
                    pessoa_rg: rg
                }).then(res => {
                    this.setState({ pessoaId: res.data.returnId });
                    this.toggleLoading();
                });
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/pessoas/create)" });
                this.log(this.state.error);
            }

        }
    }

    slide = (cont) => {
        var questionario = document.getElementsByClassName("perguntas");
        var aberta;

        for(var i = 0; i < questionario.length; i++){
            if(questionario[i].style.display == 'flex'){
                aberta = i;
            }
            questionario[i].style.display = 'none';
        }

        questionario[aberta + cont].style.display = 'flex'
        this.setState({indexperg: aberta + cont})
    }

    checkPessoa = (e) => {

        if (e && (e.length == 14 || e.length == 18)) {

            var pessoaBox = document.getElementById('pessoa')
            var aviso = document.getElementById('aviso')

            this.setState({aviso: '', errorMessage: ''})

            var pessoa = this.state.pessoas.filter((pes) => {
                return pes.pessoa_cpf_cnpj == e
            })

            if (pessoa.length == 0) {
                aviso.style.display = "none"
                this.setState({
                    pessoaId: null, 
                    is_pessoa: true,
                    modalCadastrarNome: '',
                    modalCadastrarEmail: '',
                    modalCadastrarTelefone: '',
                    modalCadastrarCelular: '',
                    modalCadastrarCep: '',
                    modalCadastrarEstado: '',
                    modalCadastrarCidade: '',
                    modalCadastrarBairro: '',
                    modalCadastrarLogradouro: '',
                    modalCadastrarNumero: '',
                    modalCadastrarComplemento: '',
                    rg: ''
                })
            } else {
                this.setState({aviso: 'Cliente Encontrado!'})
                aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
        
                this.setState({
                    pessoaId: pessoa[0]['pessoa_id'], 
                    is_pessoa: false,
                    rg: pessoa[0].pessoa_rg,
                    modalCadastrarNome: pessoa[0].pessoa_nome,
                    modalCadastrarEmail: pessoa[0].pessoa_email,
                    modalCadastrarTelefone: pessoa[0].pessoa_telefone,
                    modalCadastrarCelular: pessoa[0].pessoa_celular,
                    modalCadastrarCep: pessoa[0].endereco_cep,
                    modalCadastrarEstado: pessoa[0].endereco_estado,
                    modalCadastrarCidade: pessoa[0].endereco_cidade,
                    modalCadastrarBairro: pessoa[0].endereco_bairro,
                    modalCadastrarLogradouro: pessoa[0].endereco_logradouro,
                    modalCadastrarNumero: pessoa[0].endereco_numero,
                    modalCadastrarComplemento: pessoa[0].endereco_complemento,
                })
            }
        }
        else{
            this.setState({
                is_pessoa: false,
            })

            if(this.state.pessoaId){
                this.setState({
                    pessoaId: null, 
                    is_pessoa: false,
                    modalCadastrarNome: '',
                    modalCadastrarEmail: '',
                    modalCadastrarTelefone: '',
                    modalCadastrarCep: '',
                    modalCadastrarEstado: '',
                    modalCadastrarCidade: '',
                    modalCadastrarBairro: '',
                    modalCadastrarLogradouro: '',
                    modalCadastrarNumero: '',
                    modalCadastrarComplemento: '',
                    rg: ''
                })
            }
        }
    }

    getCEP = async (event, modal) => {
        let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');

        this.setState({ modalCadastrarCep: event.target.value });

        if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstado: res['data'].uf,
                modalCadastrarCidade: res['data'].localidade,
                modalCadastrarBairro: res['data'].bairro,
                modalCadastrarLogradouro: res['data'].logradouro,
            })
        }
    }

    configModelo = async (e) => {
        this.setState({ modeloSE: e });

        if (e.length == 0) {
            this.setState({ modalCadastrarModelo: null })
            return
        }

        let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarModelo: c[0].nome })
        }
        else {
            this.setState({ modalCadastrarModelo: e })
        }
    }

    changeModalCadastrarTipo = (event) => {
        this.setState({ modalCadastrarTipo: event.target.value });
        this.abrirEstilo(event.target.value);
    }

    abrirEstilo = (tipo) => {
        var estilo = document.getElementById('estilo');

        if (tipo != 'Moto') {
            estilo.style.display = "none";
            this.setState({ modalCadastrarEstilo: null });
        } else {
            estilo.style.display = "block";
        }

    }

    maskCnpjCpf = (e, tipo) => {

        if(e.length <= 14){
            e = e.replace(/\D/g,"")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        } else{
            e = e.replace(/\D/g,"")
            e = e.replace(/^(\d{2})(\d)/,"$1.$2")
            e = e.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            e = e.replace(/\.(\d{3})(\d)/,".$1/$2")
            e = e.replace(/(\d{4})(\d)/,"$1-$2")
        }

        if(tipo == 'l'){
            this.setState({ cpfLead: e })
        } else{
            this.setState({ cpf: e })
        }
    }

    handleItens = (valores, modulo) => {
        this.state.itensSE = valores.map((item) => (
            item.value
        ))
    }

    removeitem = async (index) => {
        let s = this.state.optionsLead;
        let i = this.state.itensSE;

        s.splice(index, 1);
        i.splice(index, 1);

        this.setState({optionsLead: s, itensSE: i})
    }

    additem = async () => {
        let a = this.state.modalCadastrarItens.concat(this.state.lettersInfo.item_id);
        let b = this.state.modalCadastrarItensKm.concat(this.state.kmSE);

        this.setValor(a)
        this.setState({modalCadastrarItensKm: b, modalCadastrarItens: a, lettersInfo: null, modeleSE: '', kmSE: '', options: this.state.options.concat(this.state.lettersInfo)})
        
    }

    reviewModal = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_id == e && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })

        this.setState({
            lettersInfo: data[0]
        })
    }

    reviewModalConsorcio = async (e, nome) => {
        let data = this.state.consorcios.filter(y => y.id == e)[0]
        
        this.setState({
            lettersInfoConsorcio: data,
            valorConsorcio: data.valor,
            nomeConsorcio: data.plano,
            comissaoConsorcio: data.comissao,
        })
    }

    rebuildModal = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(e.target.value.toLowerCase()) && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        this.setState({
            modeleSE: e.target.value,
        })

        if (item == undefined || e.target.value.length == 0) {
            this.setState({
                lettersInfo: null
            })
            return
        }

        this.setState({
            timing: setTimeout(async () => {
                let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
                this.setState({
                    lettersInfo: data[0]
                })
            }, 450)
        })
    }

    rebuildModalTroca = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(e.target.value.toLowerCase()) && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        this.setState({
            modeloTroca: e.target.value,
        })

        if (item == undefined || e.target.value.length == 0) {
            this.setState({
                lettersInfo: null
            })
            return
        }

        this.setState({
            timing: setTimeout(async () => {
                let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
                this.setState({
                    lettersInfo: data[0]
                })
            }, 450)
        })
    }

    setValor = async (itens) => {
        var somaValor = 0;
        if (itens) {
            let max = itens.length;
            for (let i = 0; i < max; i++) {
                let idItem = itens[i];
                let res = await api_topmotos.get('/itens/id/' + idItem);
                somaValor += res.data.item[0].item_preco;
            }
            this.setState({ modalCadastrarValor: somaValor, valorMov: somaValor});
        }
    }

    togglemodal = () => this.setState({ openmodal: !this.state.openmodal })

    togglemodalConsorcio = () => this.setState({ openmodalConsorcio: !this.state.openmodalConsorcio })

    removeItem = async (index) => {
        let i = this.state.modalCadastrarItens;
        let j = this.state.modalCadastrarItensKm;
        let op = this.state.options;

        console.log(j)

        i.splice(index, 1)
        j.splice(index, 1)
        op.splice(index, 1)

        this.setValor(i)

        this.setState({modalCadastrarItens: i, options: op});
    }

    apiListarItens = async event => {
        let res = await api_topmotos.get('/itens/');
        this.setState({ itens: res.data.filter(y => y.status == 1 || y.status == 5) });
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Listar tipo pagamentos
    listarPagamento = async () =>{
        let res = await api_topmotos.get('/tipopag/');
        res = res.data.filter( t => t.venda == 1);
        this.setState({ tipoPagamento: res});
    }

    // Listar Contas 
    listarContas = async () =>{
        let res = await api_topmotos.get('/contas/');
        this.setState({ contas: res.data });
    }

    // Listar Categorias 
    listarCategorias = async () =>{
        let res = await api_topmotos.get('/categoriasAll/');

        let groups = res.data.filter(r => {
            return !r.cat_id && r.tipo == "R";
        })
        groups.forEach(g => {
            g.options = res.data.filter(r => {
                return r.cat_id == g.id;
            })
        })

        this.setState({ categorias: groups });
    }

    apiListarUsuarios = async event => {
        let res = await api_topmotos.get('/usuarios/');
        this.setState({ usuarios: res.data });
    }

    apiListarEmpresas = async event => {
        let res = await api_topmotos.get('/empresas/');
        this.setState({ empresas: res.data });
    }

    apiListarConsorcios = async event => {
        let res = await api_topmotos.get('/consorcio/');
        this.setState({ consorcios: res.data });
    }

    // Inserir conta
    salvar = async () => {
        var inputId = document.getElementById("parcelaM");
        var inputIcone = document.getElementById("lblParcelaM");
        if (this.state.meses == '' && this.state.repetirLancamento == 2) {
            inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
            inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
            return;
        } else {
            inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
            inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
        }
        await this.repetirLancamento();

        var inputs = [
            [this.state.dataVencimento, 'vencimento', 'iconVencimento', 'lblVencimento'],
            [this.state.valor, 'valorM', 'iconValorM', 'lblValorM'],
            [this.state.categoria, 'categoria', 'iconCategoria', 'lblCategoria'],
            [this.state.conta, 'conta', 'iconConta', 'lblConta'],
            [this.state.tipo, 'pagamento', 'iconPagamento', 'lblPagamento']
        ]

        var inputsTransf = [
            [this.state.banco, 'banco', 'iconBanco', 'lblBanco'],
            [this.state.agencia, 'agencia', 'iconAgencia', 'lblAgencia'],
            [this.state.nConta, 'nconta', 'iconNConta', 'lblNConta'],
            [this.state.tipoConta, 'tipoConta', 'iconTipoConta', 'lblTipoConta']
        ]

        let responder = 0;

        let transferencia = false;

        for(let i = 0; i < inputs.length; i++){
            if(inputs[i][0])
                responder++
        }

        if(this.state.tipoPag){
            /*if(!this.state.nConta || 
                !this.state.tipoConta){

                for(var i = 0; i < inputsTransf.length; i++){
                    if(!inputsTransf[i][0] || inputsTransf[i][0] == 'NULL' ){
                        var inputId = document.getElementById(inputsTransf[i][1]);
                        var inputIcone = document.getElementById(inputsTransf[i][2]);
                        var inputNome = document.getElementById(inputsTransf[i][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    } else{
                        var inputId = document.getElementById(inputsTransf[i][1]);
                        var inputIcone = document.getElementById(inputsTransf[i][2]);
                        var inputNome = document.getElementById(inputsTransf[i][3]);
                        inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    }
                }
                transferencia = true
            }*/
        } else{
            transferencia = false
        }

        if(responder == inputs.length && !transferencia){
            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }

            var real = this.state.valor.replace('.', '')
            real = real.replace(',', '.');

            var real2 = '';

            var trocaV = false;
            if(this.state.troca){
                if (!this.state.modeloTroca) {
                    var inputId = document.getElementById('cliente');
                    var inputIcone = document.getElementById('iconCliente');
                    var inputNome = document.getElementById('lblCliente');
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    return;
                } else 
                if((this.state.valorVenda - real) < 0){
                    this.setState({
                        valorAr: this.state.valorVenda - real,
                        valorMov: this.state.valorVenda - real
                    })

                    real2 = this.state.valorVenda
                    
                    this.ApiAlterarValorTroca(this.state.itemId);
                } else{
                    this.ApiAlterarValorMenorTroca(this.state.itemId, real);
                }
            } else{
                if((this.state.valorVenda - real) < 0){
                    trocaV = true;
                }
            }

            let parcela_id;

            if(this.state.repetirLancamento == 2){
                await api_topmotos.post('/parcelamento/create', {
                    admissaodata: this.getTimestamp()
                }).then(res => {
                    this.setState({parcela_id: res.data.returnId})
                })
            }

            var status;

            if(this.state.troca){
                status = 'R'
            } else if((this.state.valorVenda - real) < 0 && this.state.trocaS){
                status = 'AP'
            } else{
                status = 'AR'
            }

            if(this.state.statusCompra){
                status = this.state.statusCompra
            }

            try {
                
                this.toggleLoading();

                console.log(this.state.compraId)

                if(this.state.repetirLancamento){
                    for(let i in this.state.dataRepeticao){
                        await api_topmotos.post('/financeiro/create', {
                            descricao: this.state.trocaS ? trocaV ? this.state.descricaoTroca : this.state.descricao : this.state.descricao,
                            dataVencimento: this.state.dataRepeticao[i],
                            valor: this.state.repetirLancamento == 2 ? this.state.valorParcela : real,
                            tipoPagamento: this.state.tipo,
                            status: status,
                            admissaodata: this.getTimestamp(),
                            cat_id: this.state.categoria,
                            conta_id: this.state.conta,
                            venda_id: this.state.trocaS ? trocaV ? null : this.state.vendaId : this.state.vendaId,
                            compraId: this.state.trocaS ? trocaV ? this.state.compraId ? this.state.compraId : null : null : this.state.compraId ? this.state.compraId : null,
                            dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                            banco: this.state.contaB ? this.state.contaB : null,
                            parcela_id: this.state.parcela_id ? this.state.parcela_id : null,
                            bancoFinanciado: this.state.tFinanciado
                        }).then(res => {
                            // if(this.state.tipoPag && this.state.dataRepeticao.length == (parseInt(i)+1)){
                            //     this.apiCadastrarDadosBanc(res.data.returnId)
                            // }
                            if(this.state.tipoParcelamento && this.state.dataRepeticao.length == (parseInt(i)+1)){
                                this.apiCadastrarTipoParcelamento(res.data.returnId)
                            }
                        });
                    }
                } else{
                    await api_topmotos.post('/financeiro/create', {
                        descricao: this.state.trocaS ? trocaV ? this.state.descricaoTroca : this.state.descricao : this.state.descricao,
                        dataVencimento: this.state.dataVencimento,
                        valor: real2 ? real2 : real,
                        tipoPagamento: this.state.tipo,
                        status: status,
                        admissaodata: this.getTimestamp(),
                        cat_id: this.state.categoria,
                        conta_id: this.state.conta,
                        venda_id: this.state.trocaS ? trocaV ? null : this.state.vendaId : this.state.vendaId,
                        compraId: this.state.trocaS ? trocaV ? this.state.compraId ? this.state.compraId : null : null : this.state.compraId ? this.state.compraId : null,
                        dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                        banco: this.state.contaB ? this.state.contaB : null,
                        parcela_id: null,
                        bancoFinanciado: this.state.tFinanciado
                    }).then(res => {
                        // if(this.state.tipoPag){
                        //     this.apiCadastrarDadosBanc(res.data.returnId)
                        // }
                        if(this.state.tipoParcelamento){
                            this.apiCadastrarTipoParcelamento(res.data.returnId)
                        }
                    });
                }
                
                
                await this.atualizarMovimentacao();
                this.setState({troca: false})
                this.setState({
                    // valorVenda: trocaV ? parseFloat(real) + this.state.valorVenda : this.state.valorVenda - real, 
                    descricao: this.state.idVenda + ' - Venda - ' + this.state.modeloVenda})
                this.toggleModalCadastrar();
                this.setState({valor: ''});
                await this.atualizarValorRestante();
                this.limparState();
                this.toggleLoading();

                if ((this.state.valorVenda >= 0) && this.state.trocaS){
                        this.setState({trocaS: false})
                } 

            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
                this.log(this.state.error);
            }

        } else{
            for(i = 0; i < inputs.length; i++){
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
            }
        }
    }

    limparState = () =>{
        this.setState({
            meses: '',
            repetirLancamento: '',
            dataRepeticao: [],
            parcela_id: '',
            banco: '',
            agencia: '',
            nConta: '', 
            tipoConta: '',
            dataCompetencia: '',
            categoria: '',
            conta: '',
            tipo: '',
            dataVencimento: '',
            valor: '',
            valorTParcela: ''
        })
    }

    apiCadastrarMovimentacaoAP = async () =>{
        var real = this.state.valorAr * (-1);

        try {
            await api_topmotos.post('/financeiro/create', {
                descricao: this.state.descricaoTroca,
                dataVencimento: this.state.dataVencimento,
                valor: real,
                tipoPagamento: this.state.tipo,
                status: 'AP',
                admissaodata: this.getTimestamp(),
                cat_id: this.state.categoriaTroca,
                conta_id: this.state.conta,
                compraId: this.state.compraId
            })

            this.setState({valorVenda: this.state.valorVenda - (real * (-1)), descricao: this.state.idVenda + ' - Venda - ' + this.state.modeloVenda})
            this.atualizarMovimentacao();
        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
            this.log(this.state.error);
        }
    }

    apiCadastrarTroca = async (id) =>{
        try{
            await api_topmotos.post('/troca/create', {
                item: this.state.itemId,
                venda: id
            })
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/troca/create)" });
            this.log(this.state.error);
        }
    }

    ApiAlterarValorTroca = async (id) =>{
        let res = await api_topmotos.get('/financeiro/compra/id/' + id);

        console.log(res.data[0].compra_id)

        this.setState({
            descricaoTroca: res.data[0].descricao, 
            categoriaTroca: res.data[0].categorias_id,
            compraId: res.data[0].compra_id
        })

        try{
            for(let i in res.data){

                await api_topmotos.put('/financeiro/compra/update/id/' + res.data[i].id,{
                    valor: (this.state.modalCadastrarValor / res.data.length).toFixed(2)
                })
            }

           await this.apiCadastrarTroca(this.state.vendaId);
           await this.atualizarMovimentacao();
           await this.atualizarValorRestante();
        } catch(err){
        }
    }

    ApiAlterarValorMenorTroca = async (id, valor) =>{
        let res = await api_topmotos.get('/financeiro/compra/id/' + id);
        this.setState({compraId: res.data[0].compra_id})

        var real = this.state.valor.replace('.', '')
        real = real.replace(',', '.');

        try{
            for(let i in res.data){
                await api_topmotos.put('/financeiro/compra/update/menor/id/' + res.data[i].id,{
                    valor: (real / res.data.length).toFixed(2) 
                })
            }
            
            await this.apiCadastrarTroca(this.state.vendaId);
            await this.atualizarMovimentacao();
        } catch(err){
        }
    }

    atualizarMovimentacao = async () =>{
        await this.listarMovimentacao();

        var mov = await this.state.movimentacoes.filter((mov) => {
            return mov.venda_id == this.state.vendaId && this.state.compraId != null
        })

        this.setState({movimentacoesTabela: mov})
    }

    atualizarValorRestante = async (valor) =>{

        let soma = 0, somaCompra = 0; 

        for(let i of this.state.movimentacoesTabela){
            console.log(i)
            if(i.compra_id){
                somaCompra += i.valor
            } else{
                soma += i.valor
            }
        }

        if (this.state.valorTroca) {
            (somaCompra - this.state.valorTroca == 0) ? this.setState({trocaS: false}) : this.setState({trocaS: true}) 
        } 

        if(Math.round(somaCompra + soma) == this.state.valorMov) {
            this.setState({statusCompra: '', movC: true}) 
        } else{
            this.setState({movC: false}) 
        }
        console.log(this.state.movC)

        somaCompra =  somaCompra + soma;

        this.setState({valorVenda: this.state.valorMov - somaCompra})
    }

    // Open input-grupo caso tenha (A) Receber/Pagar
    status = (e, input) =>{
        this.setState({status: e})

        if(input == 1){
            var dataPag = document.getElementById('dataPag');
            var pago = document.getElementById('grupo-pago');
    
            if( e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
    
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: null})
                this.setState({valorPago: null})
            }
        } else{
            var dataPag = document.getElementById('dataPag2');
            var pago = document.getElementById('grupo-pago2');

            if(e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: '', valorPago:''})
            }
        }

    }

    // Mascára real R$00,00
    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valor: real})
    }

    // Mascára real R$00,00
    mascaraRealPago = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorPago: real})
    }

    // Mascára parcelamento R$00,00
    mascaraParcelamento = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorTParcela: real})
    }

    // Off/On modal alterar
    corStatus = (status) =>{

        var status_repla = status.toLowerCase(); 

        return (
            <div className="d-flex w-100">
                <div class={"tag-status tag-" + status_repla}>
                </div>
            </div>
        )
        
    }

    openPdf = () =>{
        window.open('/pdf/'+this.state.vendaId+'/6', '_blank')
        window.open('/pdfImpressao/'+this.state.vendaId+'/8', '_blank')
        this.props.history.push('/vendas')
    }

    leadCliente = () =>{
        if(this.state.cpfLead){
            if(this.state.cpfValido){
                this.checkPessoa(this.state.cpfLead)
    
                this.setState({
                    cpf: this.state.cpfLead,
                    // modalCadastrarNome: this.state.nome,
                    // modalCadastrarEmail: this.state.email,
                    // modalCadastrarTelefone: this.state.telefone,
                    // errorMessage: '',
                })
    
                this.apiCadastrarLeads();
                
                this.slide(+1);
            }
        } else if(this.state.nome){
            this.setState({
                is_pessoa: true,
                modalCadastrarNome: this.state.nome,
                modalCadastrarEmail: this.state.email,
                modalCadastrarTelefone: this.state.telefone,
                errorMessage: '',
            })
            
            this.apiCadastrarLeads();
            
            this.slide(+1);
        } else{
            this.setState({errorMessage: ''})
            this.slide(+1)
        }

    }

    cpfInvalido = (tipo) =>{
        var aviso;
        if(tipo == 1){
            this.setState({avisoLead: 'CPF inválido', cpfValido: false})
            aviso = document.getElementById('avisoLead')
            aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
        } else{
            this.setState({avisoLead: 'CNPJ inválido'})
            aviso = document.getElementById('avisoLead')
            aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
            this.setState({aviso: tipo == 2 ? 'CNPJ inválido' : 'CPF inválido', cpfValido: false})
            aviso = document.getElementById('aviso')
            aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
    
            this.setState({
                pessoaId: null, 
                is_pessoa: false,
                modalCadastrarNome: '',
                modalCadastrarEmail: '',
                modalCadastrarTelefone: '',
                modalCadastrarCep: '',
                modalCadastrarEstado: '',
                modalCadastrarCidade: '',
                modalCadastrarBairro: '',
                modalCadastrarLogradouro: '',
                modalCadastrarNumero: '',
                modalCadastrarComplemento: '',
            })
        }
    }
    
    validarCPF = async (e) =>{
        var cpf = e;

        if(cpf.length == 14){
            if (typeof cpf !== "string"){
                await this.cpfInvalido();
                
            } else{
                cpf = cpf.replace(/[\s.-]*/igm, '')

                if (!cpf ||
                    cpf.length != 11 ||
                    cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999" 
                ) {
                    await this.cpfInvalido();
                } else{
                    var soma = 0
                    var resto

                    for (var i = 1; i <= 9; i++) 
                        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        
                    resto = (soma * 10) % 11

                    if ((resto == 10) || (resto == 11))  resto = 0

                    if (resto != parseInt(cpf.substring(9, 10)) ){
                        await this.setState({aviso: 'CPF inválido'})
                        await this.cpfInvalido();
                    } else{
                        soma = 0

                        for (var i = 1; i <= 10; i++) 
                            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
                        resto = (soma * 10) % 11

                        if ((resto == 10) || (resto == 11))  resto = 0

                        if (resto != parseInt(cpf.substring(10, 11) ) ){
                            await this.cpfInvalido();
                        } else{
                            this.setState({cpfValido: true})
                            this.checkPessoa(e)
                        }
                    }
                }
            }
        } else if(cpf.length == 18){
            let cnpj = cpf.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                await this.cpfInvalido(2);
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    await this.cpfInvalido(2);
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        await this.cpfInvalido(2);
                    } else{
                        this.setState({cpfValido: true})
                        this.checkPessoa(e)
                    }
                }
            }
        } else{
            this.checkPessoa(e)
            this.setState({aviso: '', cpfValido: false})
        }
    }

    validarCPFLead = async (e) =>{
        var cpf = e;

        if(cpf.length == 14){
            if (typeof cpf !== "string"){
                await this.cpfInvalido(1);
                
            } else{
                cpf = cpf.replace(/[\s.-]*/igm, '')

                if (!cpf ||
                    cpf.length != 11 ||
                    cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999" 
                ) {
                    await this.cpfInvalido(1);
                } else{
                    var soma = 0
                    var resto

                    for (var i = 1; i <= 9; i++) 
                        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        
                    resto = (soma * 10) % 11

                    if ((resto == 10) || (resto == 11))  resto = 0

                    if (resto != parseInt(cpf.substring(9, 10)) ){
                        await this.cpfInvalido(1);
                    } else{
                        soma = 0

                        for (var i = 1; i <= 10; i++) 
                            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
                        resto = (soma * 10) % 11

                        if ((resto == 10) || (resto == 11))  resto = 0

                        if (resto != parseInt(cpf.substring(10, 11) ) ){
                            await this.cpfInvalido(1);
                        } else{
                            this.setState({cpfValido: true, avisoLead: ''})
                        }
                    }
                }
            }
        } else if(cpf.length == 18){
            let cnpj = cpf.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                await this.cpfInvalido(2);
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    await this.cpfInvalido(2);
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        await this.cpfInvalido(2);
                    } else{
                        this.setState({cpfValido: true})
                        this.checkPessoa(e)
                    }
                }
            }
        }else{
            this.checkPessoa(e)
            this.setState({avisoLead: '', cpfValido: false})
        }
    }

    trocaMovel = async (id) =>{
        let res = await api_topmotos.get('/itens/id/' + id);
        let res2 = await api_topmotos.get('/compras/itens/' + id);

        let valor = parseFloat(res2.data[0].valor_compra).toLocaleString('pt-BR');

        this.setState({
            descricao: this.state.idVenda + ' - Venda - ' + this.state.modeloVenda + ` (Troca por: ${res.data.item[0].item_modelo} ${res.data.item[0].placa} )`, 
            valor: valor,
            valorTroca: res2.data[0].valor_compra,
            itemTroca: id
        })
    }

    verificarTipo = async (e) =>{
        if(e){
            var tipo = this.state.tipoPagamento.filter(y => y.id == e)
            var verificarI = tipo[0].tipo_pagamento.toUpperCase() == 'TROCA' ? true : false

            this.apiListarItens();
            
            this.setState({tipo: e, troca: verificarI ? true : false, trocaS: this.state.trocaS ? true : verificarI ? true : false});

            if(tipo[0].parcelamento == 1){
                this.setState({
                    displayTParcela: 'block',
                    tipoParcelamento: true,
                    tipoPag: false,
                    display: 'none',
                })
            } else{
                this.setState({
                    displayTParcela: 'none',
                    tipoParcelamento: false,
                    tParcela: '',
                    valorTParcela: ''
                }) 
            }

        } else{
            this.setState({
                tipo: e, 
                troca: false,
                displayTParcela: 'none',
                tipoParcelamento: false,
                tParcela: '',
                valorTParcela: ''
            });
        }
    }

    // apiCadastrarDadosBanc = async (id) =>{
    //     try{
    //         await api_topmotos.post('/dadosBanc/create', {
    //             banco: this.state.banco,
    //             agencia: this.state.agencia,
    //             conta: this.state.nConta,
    //             tipo: this.state.tipoConta,
    //             mov: id
    //         })
    //     } catch (err) {
    //         this.setState({ error: "Ocorreu um erro ao conectar com a API! (/dadosBanc/create)" });
    //         this.log(this.state.error);
    //     }
    // }

    verificarTransferencia = (e) =>{
        let transferencia = this.state.tipoPagamento.filter(y => y.id == e)
        if (transferencia[0].id == 12) {
            this.setState({
                displayTFinanciamento: 'block',
            })
        } 
        if(transferencia[0].dados_bancarios == 1){
            this.setState({
                display: 'block',
                tipoPag: true
            })
        } else{
            this.setState({
                tipoPag: false,
                display: 'none',
                nConta: '',
                banco: '',
                agencia: '',
                tipoConta: ''
            }) 
        }
    }

    apiCadastrarTipoParcelamento = async (id) =>{
        try{
            var real = this.state.valorTParcela.replace('.', '');
            real = real.replace(',', '.');

            await api_topmotos.post('/tParcelamento/create', {
                valor: real,
                parcelas: this.state.tParcela,
                mov: id
            })

            this.setState({displayTParcela: 'none'})
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/tParcelamento/create)" });
            this.log(this.state.error);
        }
    }

    repetirLancamento = async () =>{

        var data = moment().format(this.state.dataVencimento), datas = [];

        for(let i = 0; i < this.state.meses; i++){
            datas.push(moment(data).add(i, 'months').format('YYYY-MM-DD'))
        }

        this.setState({dataRepeticao: datas})

    }

    valorParcela = async (meses) =>{
        const {valor, repetirLancamento} = this.state

        if(repetirLancamento == 2){
            if(meses && valor){
                var real = valor.replace('.', '');
                real = real.replace(',', '.');
    
                this.setState({valorParcela: real / meses})
            }
        } else{
            this.setState({valorParcela: ''})
        }
    }

    ano = (ano, fab) => {
        
        ano = ano == 3200 ? '0km' : ano.toString().slice(2, 4) ;
        fab = fab ? fab.toString().slice(2, 4) : '';
        
        let anos = fab + "/" + ano;
        return(anos)
    }

    atualizarMovimentacaoS = async (compra) =>{
        await this.listarMovimentacao();

        var mov = await this.state.movimentacoes.filter((mov) => {
            return mov.venda_id == this.state.vendaId
        })

        this.setState({movimentacoesTabela: mov})
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    OpenModalDeletar = async (id, compra, venda) => {
		let res = await api_topmotos.get('/financeiro/id/' + id);
        res = res.data[0];

		this.setState({
            modalDeletar: true,
            modalDeletarId: res.id,
            mov: compra ? compra : '',
            trocaS: compra ? true : false,
            statusCompra: res.conta_status == 'R' || res.conta_status == 'P' ? res.conta_status : '',
            descricaoTroca: compra ?  `${compra} - Compra - ${this.state.modeloTroca}` : this.state.descricaoTroca,
            compraId: compra ? compra : this.state.compraId
		});
    }
    
    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.delete('/financeiro/delete/id/' + this.state.modalDeletarId);
            this.setState({valorVenda: this.state.valorVenda < 0 ? this.state.mov ? this.state.valorVenda - this.state.valorDeletado : this.state.valorVenda + this.state.valorDeletado : this.state.mov ? this.state.valorVenda - this.state.valorDeletado : this.state.valorVenda + this.state.valorDeletado})
            if(this.state.valorVenda === this.state.valorMov) {
                this.setState({statusCompra: '', movC: true}) 
            } else{
                this.setState({movC: false}) 
            }
            this.atualizarMovimentacaoS(this.state.mov);
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeito/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Contas deletado com sucesso!' });
    }

    salvarObs = async () =>{
        if(this.state.obsPag){
            await api_topmotos.put('/venda/obs/' + this.state.vendaId, {
                obs: this.state.obsPag
            });
        } 
        
        if(this.state.valorVenda == 0 || this.state.permitir){
            await this.openPdf();
        } else{
            api_topmotos.put('/venda/pendencia/' + this.state.vendaId, {status: 1})

            let max = this.state.modalCadastrarItens.length;
            for (let i = 0; i < max; i++) {
                try {
                    await api_topmotos.put('/itens/status/update/'+this.state.modalCadastrarItens[i], {status: 2})
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendaitens/create)" })
                    this.log(this.state.error);
                }
            }
            
            this.props.history.push('/vendas')
        }
    }

    apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
        let venda = res.data.filter(a => a.restricao_id == 4);

        this.setState({permitir: venda.length > 0 ? true : false})
    }

    toggleLoading = () =>{
        this.setState({ displayLoading: !this.state.displayLoading });
    }

    repetirLancamentoConsorcio = async () =>{
        var date = new Date();

        var data = moment().format('YYYY-MM-DD'), datas = [];

        for(let i = 1; i <= 4; i++){
            datas.push(moment(data).add(i, 'months').format('YYYY-MM-DD'))
        }

        this.setState({dataRepeticaoConsorcio: datas})
    }

    cadastrarConsorcio = async () =>{
        

        var inputs = [
            [this.state.consorcio, 'consorcio', 'lblConsorcio'],
            [this.state.grupo, 'grupo', 'lblGrupo'],
            [this.state.cota, 'cota', 'lblCota'],
            [this.state.observacao, 'observacao', 'lblObservacao']
        ]

        let cont = inputs.length;

        for(let i = 0; i < inputs.length; i++){
            if(inputs[i][0]){
                cont--;
            }

        }

        if(cont == 0){
            
            this.toggleLoading();

            try{

                await this.repetirLancamentoConsorcio();

                let parcela_id = [], venda_consorcio_id;

                for(let i = 0; i < 2; i++){
                    await api_topmotos.post('/parcelamento/create', {
                        admissaodata: this.getTimestamp()
                    }).then(res => {
                        parcela_id.push(res.data.returnId)
                        // this.setState({parcela_id_consorcio: parcela_id_consorcio.concat(res.data.returnId)})
                    })
                }

                await api_topmotos.post('/vendaconsorcio/create', {
                    data: this.getTimestamp(),
                    usuario: localStorage.getItem('usuario_id'),
                    cliente: this.state.pessoaId,
                    empresa: localStorage.getItem('usuario_empresa'),
                    consorcio: this.state.consorcio,
                    grupo: this.state.grupo,
                    cota: this.state.cota,
                    observacao: this.state.observacao
                }).then(res => {
                    venda_consorcio_id = res.data.returnId
                })
                
                //Comissão Topmotos
                for(let i = 0; i < 4; i++){
                    await api_topmotos.post('/financeiro/consorcio/create', {
                        descricao: 'Consórcio - ' + this.state.nomeConsorcio,
                        dataVencimento: this.state.dataRepeticaoConsorcio[i],
                        valor: (this.state.valorConsorcio / 100 * this.state.comissaoConsorcio)/4,
                        tipoPagamento: 11,
                        status: 'AR',
                        admissaodata: this.getTimestamp(),
                        cat_id: 10,
                        conta_id: 1,
                        parcela_id: parcela_id ? parcela_id[0] : null,
                        venda_consorcio: venda_consorcio_id ? venda_consorcio_id : null,
                        index: i+1
                    })
                }

                let res = await api_topmotos.get('/usuarios/id/' + localStorage.getItem('usuario_id'));
                let comissaoConsorcioVendedor = res.data[0].usuario_comissao_consorcio

                // Comissão Vendedor
                for(let i = 0; i < 4; i++){
                    await api_topmotos.post('/financeiro/consorcio/create', {
                        descricao: `Comissão Consórcio - ${this.state.nomeConsorcio} - ${this.state.nomeVendedor}`,
                        dataVencimento: this.state.dataRepeticaoConsorcio[i],
                        valor: (this.state.valorConsorcio / 100 * comissaoConsorcioVendedor)/4,
                        tipoPagamento: 11,
                        status: 'AP',
                        admissaodata: this.getTimestamp(),
                        cat_id: 18,
                        conta_id: 1,
                        parcela_id: parcela_id ? parcela_id[1] : null,
                        venda_consorcio: venda_consorcio_id ? venda_consorcio_id : null,
                        index: i+1
                    })
                }

                this.toggleLoading();

                this.props.history.push('/vendaconsorcio') 
                

            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }
        } else{
            for(var i = 0; i < inputs.length; i++){
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
            }
        }
    }

    openTermoVenda = () =>{
        this.apiListarItens();
        this.togglemodal();
    }

    render() {
        return (
            <>
                <div className="overlay" style={{display: this.state.displayLoading ? 'flex' : 'none'}}>
                    <div className="modal__loading">
                        <img src={require("../../imgs/loading.gif")} alt=""/>
                        <p>Loading</p>
                    </div>
                </div>

                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />

                            <div className="questionario">
                                <p className="head">{this.state.titles[this.state.indexperg]}</p>
                                <div className="perguntas" id="primeira" style={{display:"flex"}}>
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="nome" id="lblNome">Nome*</label>
                                                    <div className="grupo-campo-input input-cad" id="nome">
                                                        <i className="fas fa-sign" id="iconNome"></i>
                                                        <input placeholder="Insira o nome" type="text" onChange={(e) => this.setState({ nome: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cpf" id="lblCpf">CPF Lead</label>
                                                    <div className="grupo-campo-input input-cad" id="cpf">
                                                        <i class="fas fa-id-card" id="iconCpf"></i>
                                                        <InputMask
                                                            maxLength="18"
                                                            type="text"
                                                            placeholder="&nbsp;Informe o CPF"
                                                            autoComplete="on"
                                                            value={this.state.cpfLead}
                                                            onChange={a => {this.maskCnpjCpf(a.target.value, 'l'); this.validarCPFLead(a.target.value)}}
                                                            required
                                                        />
                                                    </div>
                                                    <p className="valorFipe pt-2" id="avisoLead">{this.state.avisoLead}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="iema" id="lblEmail">Email</label>
                                                    <div className="grupo-campo-input input-cad" id="email">
                                                        <i className="fas fa-envelope" id="iconEmail"></i>
                                                        <input placeholder="Insira o email" type="text" onChange={(e) => this.setState({ email: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="itel" id="lblTelefone">Telefone*</label>
                                                    <div className="grupo-campo-input input-cad" id="telefone">
                                                        <i className="fas fa-phone" id="iconTelefone"></i>
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            maskChar="_"
                                                            alwaysShowMask="true"
                                                            type="tel"
                                                            placeholder="Insira o telefone"
                                                            autoComplete="on"
                                                            onChange={(e) => this.setState({ telefone: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="tipo">Como conheceu a loja?</label>
                                                    <div className="grupo-campo-input input-cad" id="tipo">
                                                        <i class="fas fa-store"></i>
                                                        <select
                                                            name="nivel"
                                                            placeholder="&nbsp;Selecione o Tipo do item"
                                                            autoComplete="on"
                                                            onChange={a => this.setState({conhecimentoLoja: a.target.value})}
                                                            required
                                                        >
                                                            <option value="NULL">Selecione como soube</option>
                                                            <option value="OLX">OLX</option>
                                                            <option value="Facebook">Facebook</option>
                                                            <option value="Site">Site</option>
                                                            <option value="Loja">Loja</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="retorno">Motivo da Não venda</label>
                                                    <div className="grupo-campo-input input-cad" id="retorno">
                                                        <i class="fas fa-minus-circle"></i>
                                                        <select
                                                            name="nivel"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({motivoSE: event.target.value})}
                                                            required
                                                        >
                                                            <option value="">Selecione uma opção</option>
                                                            {
                                                                this.state.motivo.map((e, index) => (
                                                                    <option value={e.id}>{e.motivo}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="retorno">O lead marcou retorno?</label>
                                                    <div className="grupo-campo-input input-cad" id="retorno">
                                                    <i class="fas fa-undo-alt"></i>
                                                        <select
                                                            name="nivel"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({retorno: event.target.value})}
                                                            required
                                                        >
                                                            <option value="NULL">Selecione uma opção</option>
                                                            <option value="Sim">Sim</option>
                                                            <option value="Não">Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="imark" id="lblMarca">Marca*</label>
                                                    <div className="grupo-campo-input input-cad" id="marca">
                                                    <i class="fas fa-motorcycle" id="iconMarca"></i>
                                                        <select name="" id="imark" onChange={e => this.setState({ marcaSE: e.target.value })}>
                                                            <option value="NULL">Selecione uma marca</option>
                                                            {
                                                                this.state.marcas.map(e => (
                                                                    <option value={e.id}>{e.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="imol" id="lblModelo">Modelo</label>
                                                    <div className="grupo-campo-input input-cad" id="modelo">
                                                        <i class="fas fa-motorcycle" id="iconModelo"></i>
                                                        <input 
                                                            placeholder="Insira o modelo" 
                                                            value={this.state.modeleSE} 
                                                            onChange={a => this.setState({modeleSE: a.target.value})} 
                                                            disabled={this.state.marcaSE == "NULL"} 
                                                            type="text" 
                                                            onFocus={a => this.setState({ clientFocus: true })} 
                                                            onBlur={a => this.setState({ clientFocus: false })} />
                                                    </div>
                                                    <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                        {
                                                            this.state.marcaSE != "NULL" ?
                                                                (this.state.modelosvei.filter(y => !this.state.itens.includes(y.id) && y.id_marca == this.state.marcaSE && y.nome.toLowerCase().includes(this.state.modeleSE.toLowerCase())).map(e => (
                                                                    <li onClick={a => this.setState({optionsLead: this.state.optionsLead.concat(e), itensSE: this.state.itensSE.concat(e.id) })}>{e.nome}</li>
                                                                )))
                                                                : (null)
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>                       

                                        <div className="itensmodsall">
                                            {
                                                this.state.optionsLead.map((e, index) => (
                                                    <div className="itemMods">
                                                        {e.marcaName} - {e.nome}
                                                        <i className="fas fa-times" onClick={a => this.removeitem(index)}></i>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        
                                        <div className="aviso-formulario aviso-formulario-err ">
                                            <p className="aviso text-right">{this.state.errorMessage}</p>
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt">
                                        <button className="mr-2" onClick={() => {this.apiCadastrarLeads()}}>
                                            Enviar
                                        </button>
                                        <button onClick={() => {this.leadCliente()}}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cpf" id="lblCpfCliente">CPF Cliente *</label>
                                                    <div className="grupo-campo-input input-cad" id="cpfCliente">
                                                        <i class="fas fa-id-card" id="iconCpfCliente"></i>
                                                        <InputMask
                                                            maxLength="18"
                                                            type="text"
                                                            placeholder="&nbsp;Informe o CPF ou CNPJ"
                                                            autoComplete="on"
                                                            value={this.state.cpf}
                                                            onChange={a => {this.maskCnpjCpf(a.target.value); this.validarCPF(a.target.value)}}
                                                            // value={this.state.cpf}
                                                            required
                                                        />
                                                    </div>
                                                    <p className="valorFipe pt-2" id="aviso">{this.state.aviso}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <p id="aviso">Cliente encontrado!</p>

                                        <div id="pessoa d-block">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="nome" id="lblNomeCliente">Nome *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="nomeCliente">
                                                            <i className="fas fa-user" id="iconNomeCliente"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Nome"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarNome}
                                                                onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="email" id="lblEmailCliente">E-mail</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="emailCliente">
                                                            <i className="far fa-envelope" id="iconEmailCliente"></i>
                                                            <input
                                                                type="email"
                                                                placeholder="&nbsp;Informe o E-mail"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarEmail}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.setState({ modalCadastrarEmail: event.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row">
                                            <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="celularCliente" id="lblCelularCliente">Celular *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="celularCliente">
                                                            <i className="fas fa-phone" id="iconCelularCliente"></i>
                                                            <InputMask
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Celular"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarTelefone}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.setState({ modalCadastrarTelefone: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="telefone" id="lblTelefoneCliente">Telefone </label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="telefoneCliente">
                                                            <i className="fas fa-phone" id="iconTelefoneCliente"></i>
                                                            <InputMask
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Telefone"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarCelular}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.setState({ modalCadastrarCelular: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="rg" id="lblrg">RG</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="rg">
                                                            <i class="fas fa-id-card" id="iconRg"></i>
                                                            <InputMask
                                                                maxLength="15"
                                                                type="text"
                                                                placeholder="&nbsp;Informe o RG"
                                                                autoComplete="on"
                                                                value={this.state.rg}
                                                                onChange={a => {this.setState({rg: a.target.value})}}
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cep" id="lblCep">CEP *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cep">
                                                            <i className="fas fa-envelope" id="iconCep"></i>
                                                            <InputMask
                                                                mask="99999-999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarCep}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.getCEP(event, "cadastrar")}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>       
                                                    
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="estado" id="lblEstado">Estado *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="estado">
                                                            <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                            <select
                                                                name="estado"
                                                                value={this.state.modalCadastrarEstado}
                                                                placeholder="&nbsp;Selecione o Estado de residência"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarEstado: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            >
                                                                <option value="NULL">Selecione o Estado de residência</option>
                                                                <option value='AC'>Acre</option>
                                                                <option value='AL'>Alagoas</option>
                                                                <option value='AP'>Amapá</option>
                                                                <option value='AM'>Amazonas</option>
                                                                <option value='BA'>Bahia</option>
                                                                <option value='CE'>Ceará</option>
                                                                <option value='DF'>Distrito Federal</option>
                                                                <option value='ES'>Espírito Santo</option>
                                                                <option value='GO'>Goiás</option>
                                                                <option value='MA'>Maranhão</option>
                                                                <option value='MT'>Mato Grosso</option>
                                                                <option value='MS'>Mato Grosso do Sul</option>
                                                                <option value='MG'>Minas Gerais</option>
                                                                <option value='PA'>Pará</option>
                                                                <option value='PB'>Paraíba</option>
                                                                <option value='PR'>Paraná</option>
                                                                <option value='PE'>Pernambuco</option>
                                                                <option value='PI'>Piauí</option>
                                                                <option value='RJ'>Rio de Janeiro</option>
                                                                <option value='RN'>Rio Grande do Norte</option>
                                                                <option value='RS'>Rio Grande do Sul</option>
                                                                <option value='RO'>Rondônia</option>
                                                                <option value='RR'>Roraima</option>
                                                                <option value='SC'>Santa Catarina</option>
                                                                <option value='SP'>São Paulo</option>
                                                                <option value='SE'>Sergipe</option>
                                                                <option value='TO'>Tocantins</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cidade" id="lblCidade">Cidade *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cidade">
                                                            <i className="fas fa-city" id="iconCidade"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarCidade}
                                                                placeholder="&nbsp;Informe a Cidade de residência"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarCidade: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>    
                                            </div>       

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="logradouro" id="lblLogradouro">Logradouro *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="logradouro">
                                                            <i className="fas fa-road" id="iconLogradouro"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarLogradouro}
                                                                placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarLogradouro: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="numero" id="lblNumero">Número *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="numero">
                                                            <i className="fas fa-home" id="iconNumero"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Número de Residência"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarNumero: event.target.value })}
                                                                required
                                                                value={this.state.modalCadastrarNumero}
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="bairro" id="lblBairro">Bairro *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="bairro">
                                                            <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarBairro}
                                                                placeholder="&nbsp;Informe o Bairro"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarBairro: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="complemento">Complemento</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="complemento">
                                                            <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarComplemento}
                                                                placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                                autoComplete="on"
                                                                disabled={!this.state.is_pessoa}
                                                                
                                                                onChange={event => this.setState({ modalCadastrarComplemento: event.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            
                                        <div className="aviso-formulario aviso-formulario-err ">
                                            <p className="aviso text-right">{this.state.errorMessage}</p>
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => {
                                            this.apiCadastrarPessoa()
                                        }}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="row justify-content-center py-4">
                                            <div className="col-md-4">
                                                <div className="card-venda">
                                                    <div className="titulo">
                                                        Mudar Status
                                                    </div>
                                                    <img src={require("../../imgs/desenho_venda.png")} alt=""/>
                                                    <p className="texto">Selecione o status da venda</p>
                                                    <div className="grupo-input input-style-cad">
                                                        <div className="grupo-campo-input input-cad" id="status_venda">
                                                            <i className="fas fa-sign" id="iconStatus_venda"></i>
                                                            <select onClick={event => {this.setState({status: event.target.value})}}>
                                                                <option value="">Selecione status</option>
                                                                {
                                                                    this.state.statusVenda.map((e, index) => (
                                                                        <option value={e.id}>{e.descricao}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div> 
                                                    </div>
                                                    <div className="btn-prox-volt justify-content-center">
                                                        <button onClick={() => {this.apiCadastrarStatus()
                                                        }}>Enviar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card-venda">
                                                    <div className="titulo">
                                                        Termo de venda
                                                    </div>
                                                    <img src={require("../../imgs/desenho_termo.png")} alt=""/>
                                                    <p className="texto">Finalize sua venda</p>
                                                    <div className="btn-prox-volt justify-content-center py-4">
                                                        <button onClick={() => {this.slide(+1)}}>Termo de Venda</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card-venda">
                                                    <div className="titulo">
                                                        Consórcio
                                                    </div>
                                                    <img src={require("../../imgs/moto.png")} alt=""/>
                                                    <p className="texto">Selecione o consórcio </p>
                                                    <div className="btn-prox-volt justify-content-center  py-4">
                                                        <button onClick={() => this.togglemodalConsorcio()}>Visualizar consórcios</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="valor">Itens </label>
                                            
                                            <Button onClick={this.openTermoVenda} className="btnora">Adicionar</Button>
                                            
                                            <div className="cardsItens">
                                                {
                                                    this.state.options.map((e, index) => (
                                                        <div className="itensx">
                                                            <div className="cropper">
                                                                <div className="killmoto" onClick={a => this.removeItem(index)}><i className="fas fa-times"></i></div>
                                                                <img src={e.itemfotos_foto} alt=""/>
                                                            </div>
                                                            <p>
                                                                {e.item_modelo}
                                                            </p>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="valor" id="lblValor">Valor </label>
                                                    <div className="grupo-campo-input input-cad" id="valor">
                                                        <i className="fas fa-tag" id="iconValor"></i>
                                                        <input
                                                            type="text"
                                                            value={this.state.modalCadastrarValor ? parseFloat(this.state.modalCadastrarValor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }): ''}
                                                            placeholder="&nbsp;Informe o Valor da venda"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ modalCadastrarValor: event.target.value, valorMov: event.target.value})}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => {
                                            this.apiCadastrarVenda();
                                        }}>Proximo <i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="d-flex justify-content-between my-2">
                                            <div className="d-flex align-center line-separa">
                                                <div className="indicadores m-3 d-flex">
                                                    <i class="fas fa-dollar-sign" id="receber"></i>
                                                    <div>
                                                        <p>Total</p>
                                                        <p>{parseFloat(this.state.modalCadastrarValor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                <div className="indicadores m-3 d-flex">
                                                    <i class="fas fa-dollar-sign" id="recebido"></i>
                                                    <div>
                                                        <p>Restante</p>
                                                        <p>{parseFloat(this.state.valorVenda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <button className="btn-add p-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar movimentação<i class="fas fa-plus"></i></button>
                                        </div>
                                        <div className="table mt-3">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Operação
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Descrição
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Data de Vencimento
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Valor
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Ações
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.movimentacoesTabela.map((mov) => (
                                                            <tr key={mov.id}>
                                                                <td>{this.corStatus(mov.conta_status)}</td>
                                                                <td>{mov.descricao}</td>
                                                                <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                                <td>{mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                <td className="td-acoes">
                                                                    <i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(mov.id, mov.compra_id, mov.venda_id); this.setState({valorDeletado: mov.valor})}}></i>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="grupo-input input-style-cad mt-4">
                                            <label htmlFor="obsPag" id="lblObsPag">Observação Forma de Pagamento</label>
                                            <textarea value={this.state.obsPag} onChange={a => this.setState({obsPag: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => {this.salvarObs()}}>Salvar</button>
                                    </div>
                                </div>
                           </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

                {/* Modal de Cadastro */}
                <Modal
					isOpen={this.state.modalCadastrar}
					toggle={this.toggleModalCadastrar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Conta</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblValorM">Valor *</label>
                                            <div className="grupo-campo-input input-cad" id="valorM">
                                            <i class="fas fa-dollar-sign" id="iconValorM"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valor}
                                                    onChange={event => this.mascaraReal(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad d-flex justify-content-start">
                                            <label htmlFor="" id="lblRepetirLancamento">Repetir Lançamento</label>
                                            <div className="obrigatorio">
                                                <input type="radio" name="repetirLancamento" value="2" id="parcela" onChange={event => {this.setState({repetirLancamento: event.target.value, meses: '', valorParcela: ''})}}/>
                                                <label htmlFor="parcela">Parcelamento</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{display: this.state.repetirLancamento ? 'block' : 'none'}}>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblParcelaM">Quantos meses? *</label>
                                            <div className="grupo-campo-input input-cad" id="parcelaM">
                                                <input
                                                    type="number"
                                                    autoComplete="on"
                                                    value={this.state.meses}
                                                    onChange={event => {this.setState({ meses: event.target.value }); this.valorParcela(event.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p id="valor-mensal" style={{display: this.state.valorParcela ? 'block' : 'none'}}>
                                            <i class="fas fa-info-circle"></i>
                                             {this.state.meses} vezes de {(this.state.valorParcela).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblPagamento">Tipo de pagamento *</label>
                                            <div className="grupo-campo-input input-cad" id="pagamento">
                                                <i class="fas fa-dollar-sign" id="iconPagamento"></i>
                                                <select onChange={event => {this.verificarTipo(event.target.value); this.verificarTransferencia(event.target.value)}}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.tipoPagamento.map((tipo) => (
                                                        <option value={tipo.id}>{tipo.tipo_pagamento}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblConta">Centro de Custos *</label>
                                            <div className="grupo-campo-input input-cad" id="conta">
                                                <i class="fas fa-dollar-sign" id="iconConta"></i>
                                                <select onChange={event => this.setState({ conta: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contas.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblVencimento">Data de vencimento *</label>
                                            <div className="grupo-campo-input input-cad" id="vencimento">
                                                <i class="far fa-calendar-alt" id="iconVencimento"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataVencimento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblCategoria">Plano de Contas *</label>
                                            <div className="grupo-campo-input input-cad" id="categoria">
                                                <i class="fas fa-dollar-sign" id="iconCategoria"></i>
                                                <select onChange={event => this.setState({ categoria: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.categorias.map((cat) => (
                                                        <>
                                                            <option className="select__group" value={cat.id}>{cat.categoria}</option>
                                                            {cat.options.map((op) => (
                                                                <option value={op.id}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {op.categoria}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Conta Bancária</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select onChange={event => this.setState({ contaB: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contasBancarias.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Data de competência</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataCompetencia: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: this.state.troca ? 'block' : 'none'}}>
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cliente" id="lblCliente">Modelo *</label>
                                            <div className="grupo-campo-input input-cad" id="cliente">
                                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                                <input type="text"
                                                    value={this.state.modeloTroca}
                                                    onChange={this.rebuildModalTroca}
                                                    onFocus={a => this.setState({ clientFocus: true })}
                                                    onBlur={a => this.setState({ clientFocus: false })}
                                                    placeholder="Insira o modelo"
                                                />
                                            </div>
                                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                {
                                                    this.state.itens.filter(y => y.compra == 1 && y.item_modelo != this.state.modeleSE && y.empresa_id == localStorage.getItem('usuario_empresa') && (y.item_modelo.toLowerCase().includes(this.state.modeloTroca.toLowerCase()) || y.placa.toLowerCase().includes(this.state.modeloTroca.toLowerCase())) && !this.state.modalCadastrarItens.includes(y.item_id)).map(e => (
                                                        <li onClick={a => {this.setState({ modeloTroca: e.item_modelo, itemId: e.item_id}); this.trocaMovel(e.item_id)}}>
                                                            {e.item_modelo +  " - " + e.placa}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            
                                <div style={{display: this.state.display}}>
                                    <p class="subtitle-form">Dados bancários</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblBanco">Banco *</label>
                                                <div className="grupo-campo-input input-cad" id="banco" >
                                                    <i class="fas fa-dollar-sign" id="iconBanco"></i>
                                                    <input 
                                                        onChange={event => this.setState({banco: event.target.value})} 
                                                        placeholder="&nbsp;Informe o Banco" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblAgencia">Agência *</label>
                                                <div className="grupo-campo-input input-cad" id="agencia" >
                                                    <i class="fas fa-dollar-sign" id="iconAgencia"></i>
                                                    <input 
                                                        onChange={event => this.setState({agencia: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblNConta">Conta *</label>
                                                <div className="grupo-campo-input input-cad" id="nconta" >
                                                    <i class="fas fa-dollar-sign" id="iconNConta"></i>
                                                    <input 
                                                        onChange={event => this.setState({nConta: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Conta" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTipoConta">Tipo de conta *</label>
                                                <div className="grupo-campo-input input-cad" id="tipoConta" >
                                                    <i class="fas fa-dollar-sign" id="iconTipoConta"></i>
                                                    <select onChange={ e => this.setState({tipoConta: e.target.value})}>
                                                        <option value="">Selecione o tipo da conta</option>
                                                        <option value="Conta Corrente">Conta Corrente</option>
                                                        <option value="Conta Poupança">Conta Poupança</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div style={{display: this.state.displayTParcela}}>
                                    <p class="subtitle-form">Parcelamento</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTParcela">Quantas parcelas? *</label>
                                                <div className="grupo-campo-input input-cad" id="tParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconTParcela"></i>
                                                    <input 
                                                        onChange={event => this.setState({tParcela: event.target.value})} 
                                                        placeholder="&nbsp;Informe a quantidade de parcelas" 
                                                        min="0"
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblValorTParcela">Valor da Parcela*</label>
                                                <div className="grupo-campo-input input-cad" id="valorTParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconValorTParcela"></i>
                                                    <input 
                                                        onChange={event => this.mascaraParcelamento(event.target.value)} 
                                                        value={this.state.valorTParcela}
                                                        // placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: this.state.displayTFinanciamento}}>
                                    <p class="subtitle-form">Financiamento</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTParcela">Banco Financiado *</label>
                                                <div className="grupo-campo-input input-cad" id="tParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconTParcela"></i>
                                                    <input 
                                                        onChange={event => this.setState({tFinanciado: event.target.value})} 
                                                        placeholder="&nbsp;Informe a qual o financiador" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={this.toggleModalCadastrar.bind(this)}>Fechar</Button>
                            <Button className="bg-laranja" onClick={this.salvar}>Salvar</Button>
						</ModalFooter>
					</Modal>

                <Modal isOpen={this.state.openmodal} toggle={this.togglemodal} backdrop={true} keyboard={true} size="md">
                    <ModalHeader toggle={this.togglemodal}>
                        Selecionar itens
                    </ModalHeader>
                    <ModalBody>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="empresa" id="lblEmpresa">Empresa *</label>
                            <div className="grupo-campo-input input-cad" id="empresa">
                                <i className="fas fa-building" id="iconEmpresa"></i>
                                <select name="" value={this.state.empresa} onChange={e => this.setState({ empresa: e.target.value})}>
                                    <option value="NULL">Selecione uma empresa</option>
                                    {
                                        this.state.empresas.map(e => (
                                            <option value={e.empresa_id}>{e.empresa_nomefantasia}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad">
                            <label htmlFor="cliente">Modelo </label>
                            <div className="grupo-campo-input input-cad" id="cliente">
                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                <input type="text"
                                    value={this.state.modeleSE}
                                    onChange={this.rebuildModal}
                                    onFocus={a => this.setState({ clientFocus: true })}
                                    onBlur={a => this.setState({ clientFocus: false })}
                                    placeholder="Insira o modelo"
                                />
                            </div>
                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                {
                                    this.state.itens.filter(y => y.compra == 1 && y.empresa_id == this.state.empresa && (y.item_modelo.toLowerCase().includes(this.state.modeleSE.toLowerCase()) || y.placa.toLowerCase().includes(this.state.modeleSE.toLowerCase())) && !this.state.modalCadastrarItens.includes(y.item_id)).map(e => (
                                        <li onClick={a => {this.setState({ modeleSE: e.item_modelo }); this.reviewModal(e.item_id)}}>
                                            {e.item_modelo + ' - ' + e.placa}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="grupo-input input-style-cad">
                            <label htmlFor="cliente">Km de Venda </label>
                            <div className="grupo-campo-input input-cad" id="km">
                                <i className="fas fa-tachometer-alt" id="iconKm"></i>
                                <input type="number"
                                    value={this.state.kmSE}
                                    onChange={e => this.setState({ kmSE: e.target.value })}
                                    placeholder="Insira o Km de Venda"
                                />
                            </div>
                        </div>
                        {
                            this.state.lettersInfo ? (
                                <div className="infamus">
                                    <div className="imgcropper">
                                        <div className="img-veiculo" style={{backgroundImage: `url("${this.state.lettersInfo.itemfotos_foto ? this.state.lettersInfo.itemfotos_foto : require('../../imgs/sFoto.png')}")`}}></div>
                                        {/* <img src={this.state.lettersInfo.itemfotos_foto} alt="" /> */}
                                    </div>
                                    <br />
                                    <div className="gridinfoview">
                                        <span className="orange lgm bol">
                                            {this.state.lettersInfo.item_modelo}
                                        </span>
                                        <span className=" lgm gr bol">
                                            {parseFloat(this.state.lettersInfo.item_preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                        <span className="tl lgm gr bol">
                                            {this.ano(this.state.lettersInfo.ano_mod, this.state.lettersInfo.item_ano_fab)}
                                        </span>
                                        <span className=" gr lgm bol">
                                            {this.state.lettersInfo.combustivel}
                                        </span>
                                        <span className="tl lgm gr bol">
                                            {this.state.lettersInfo.placa}
                                        </span>
                                        <span className=" gr lgm bol">
                                            {this.state.lettersInfo.cor}
                                        </span>
                                        <span className="alb lgm">
                                            {this.state.lettersInfo.item_descricao}
                                        </span>
                                    </div>
                                </div>
                            ) : (null)
                        }
                    </ModalBody>
                    {
                        this.state.lettersInfo ? (
                            <ModalFooter>
                                <Button className="btnora" onClick={this.additem}>Adicionar</Button>
                            </ModalFooter>
                        ) : (null)
                    }
                </Modal>

                <Modal 
                    isOpen={this.state.openmodalConsorcio} 
                    toggle={this.togglemodalConsorcio} 
                    backdrop={true} 
                    keyboard={true} 
                    size="md">

                    <ModalHeader toggle={this.togglemodalConsorcio}>
                        Selecionar Consórcios
                    </ModalHeader>
                    <ModalBody>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="consorcio" id="lblConsorcio">Consorcio *</label>
                            <div className="grupo-campo-input input-cad" id="consorcio">
                                <select name="" value={this.state.consorcio} onChange={e => {this.setState({ consorcio: e.target.value});  this.reviewModalConsorcio(e.target.value)}}>
                                    <option value="NULL">Selecione um consorcio</option>
                                    {
                                        this.state.consorcios.map(e => (
                                            <option value={e.id}>{e.plano}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            this.state.lettersInfoConsorcio ? (
                                <div className="infamus">
                                    <div className="gridinfoview">
                                        <span className="texto">
                                            <p>Plano</p>
                                            <p>{this.state.lettersInfoConsorcio.plano}</p>
                                        </span>
                                        <span className="texto">
                                            <p>Valor</p>
                                            <p>{parseFloat(this.state.lettersInfoConsorcio.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                        </span>
                                        <span className="texto">
                                            <p>Empresa</p>
                                            <p>{this.state.lettersInfoConsorcio.empresa}</p>
                                        </span>
                                        <span className="texto">
                                            <p>Meses</p>
                                            <p>{this.state.lettersInfoConsorcio.meses}</p>
                                        </span>
                                    </div>
                                </div>
                            ) : (null)
                        }
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="grupo" id="lblGrupo">Grupo *</label>
                            <div className="grupo-campo-input input-cad" id="grupo">
                                <input placeholder="Insira o grupo" type="text" onChange={(e) => this.setState({ grupo: e.target.value })} />
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="cota" id="lblCota">Cota *</label>
                            <div className="grupo-campo-input input-cad" id="cota">
                                <input placeholder="Insira a cota" type="text" onChange={(e) => this.setState({ cota: e.target.value })} />
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="observacao" id="lblObservacao">Observação *</label>
                            <div className="grupo-campo-input input-cad" id="observacao">
                                <textarea onChange={(e) => this.setState({ observacao: e.target.value })}>

                                </textarea>
                            </div>
                        </div>
                        {/* <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="empresa" id="lblEmpresa">Empresa *</label>
                            <div className="grupo-campo-input input-cad" id="empresa">
                                <i className="fas fa-building" id="iconEmpresa"></i>
                                <select name="" value={this.state.empresa} onChange={e => this.setState({ empresa: e.target.value})}>
                                    <option value="NULL">Selecione uma empresa</option>
                                    {
                                        this.state.empresas.map(e => (
                                            <option value={e.empresa_id}>{e.empresa_nomefantasia}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad">
                            <label htmlFor="cliente">Modelo </label>
                            <div className="grupo-campo-input input-cad" id="cliente">
                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                <input type="text"
                                    value={this.state.modeleSE}
                                    onChange={this.rebuildModal}
                                    onFocus={a => this.setState({ clientFocus: true })}
                                    onBlur={a => this.setState({ clientFocus: false })}
                                    placeholder="Insira o modelo"
                                />
                            </div>
                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                {
                                    this.state.itens.filter(y => y.compra == 1 && y.empresa_id == this.state.empresa && (y.item_modelo.toLowerCase().includes(this.state.modeleSE.toLowerCase()) || y.placa.toLowerCase().includes(this.state.modeleSE.toLowerCase())) && !this.state.modalCadastrarItens.includes(y.item_id)).map(e => (
                                        <li onClick={a => {this.setState({ modeleSE: e.item_modelo }); this.reviewModal(e.item_id)}}>
                                            {e.item_modelo + ' - ' + e.placa}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div> */}
                        
                        
                    </ModalBody>
                    {
                        this.state.lettersInfoConsorcio ? (
                            <ModalFooter>
                                <Button className="btnora" onClick={this.cadastrarConsorcio}>Salvar</Button>
                            </ModalFooter>
                        ) : (null)
                    }
                </Modal>

                {/*Modal Deletar*/}
                <Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                        <ModalBody>
                            Deseja realmente deletar a conta<br></br>(ID: {this.state.modalDeletarId})?
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.deletar} color="danger">Deletar</Button>
                            <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                
                {/* MODAL AVISO */}
                <Modal
                    isOpen={this.state.modalAviso}
                    toggle={this.toggleModalAviso.bind(this)}
                    backdrop={true}
                    keyboard={true}
                    size="sm"
                    fade={false} >
                    <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                    <ModalBody>
                        {this.state.modalAvisoText}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default Page
