import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class RelatorioVendasDetPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            venda: [],
            cliente: [],
            usuario: [],
            item: [],
            itemT: [],
            venda_id: this.props.match.params.id,
            mov: [],
            transferencia: [],
            obs: '',
            pendencia: '', 
            permitir:'',
            modalAviso: false,
            modalAvisoText: '',
            modalCancela: false,
            vendaId: '', 
            itemId: '',
            cancelada: false
		};
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarVendas();
        }
    }

    apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
        let venda = res.data.filter(a => a.restricao_id == 4);

        this.setState({permitir: venda.length > 0 ? true : false})
    }

    apiListarVendas = async event => {
        let res = await api_topmotos.get('/vendas/');
        let data = res.data.filter(a => a.venda_id == this.state.venda_id);

        if(!data[0].venda_cancelada){
    
            this.setState({ 
                venda: data,
                pendencia: data[0].pendencia,
                obs: data[0].observacao_pag,
                itemId: data[0].item_id
            });
    
            this.apiListarClientes(data[0].venda_cliente)
            this.apiListarUsuarios(data[0].venda_usuario)
            this.apiListarItens(data[0].item_id)
            this.apiListarTroca();
            this.apiListarMovimentacoes();
            this.apiListarPermissao();
        } else{
            this.setState({ 
                cancelada: true,
            });
        }
	}
    
    apiListarClientes = async (id) => {
		let res = await api_topmotos.get('/pessoas/');
        
        let data = res.data.filter(a => a.pessoa_id == id); 

        this.setState({ cliente: data});
	}

	apiListarUsuarios = async (id) => {
        let res = await api_topmotos.get('/usuarios');
        
        let data = res.data.filter(a => a.usuario_id == id)

		this.setState({usuario: data});
	}

	apiListarItens = async (id) => {
        let res = await api_topmotos.get('/itens/');
        
        let data = res.data.filter(a => a.item_id == id)

		this.setState({ item: data});
    }

    apiListarMovimentacoes = async (id) => {
        let res = await api_topmotos.get('/financeiro/');

        let data = res.data.filter(a => a.venda_id == this.state.venda_id)

        this.setState({mov: data})

        this.apiListarTranferencia(data)
        
    }

    apiListarTroca = async () =>{
        let res = await api_topmotos.get('/trocas/');

        let data = res.data.filter(a => a.venda == this.state.venda_id)

        if(data.length != 0){
            res = await api_topmotos.get('/itens/');
            data = res.data.filter(a => a.item_id == data[0].item)
    
            this.setState({ itemT: data});
        }
    }

    apiListarTranferencia = async (dados) =>{
        let lista = [];

        for(let i in dados){
            let {data} = await api_topmotos.get("/dadosBanc/id/"+ dados[i].id)

            if(data[0]){lista.push(data[0])} 
            
        }

        this.setState({transferencia: lista})   
    }
    
    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    pesquisar = () => {
        this.filtroVenda(this.state.inicio, this.state.fim, this.state.vendedor, this.state.empresa)
    }

    liberar = () =>{
        api_topmotos.put('/venda/pendencia/' + this.state.venda_id, {status: null})
        api_topmotos.put('/itens/status/update/'+this.state.itemId, {status: 3})

        this.apiListarVendas();
        this.toggleModalAviso();
    }

    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    modalCancelar = (id) =>{
		this.setState({
			vendaId: id
		});

		this.toggleModalCancelar();
	}
 
    cancelarVenda = async e =>{
		let id = this.state.vendaId

		await api_topmotos.put('/venda/cancelada', {venda_id: id})
		
		let res = await api_topmotos.get('/vendaitens/venda/' + id);

		for(let i of res.data){
			await api_topmotos.put('/itens/status/disponivel/' + i.vendaitens_item);
		}

		this.toggleModalCancelar();
        
        this.props.history.push('/vendas/relatorio')
        
    }

    toggleModalCancelar = () => {
		this.setState({ modalCancela: !this.state.modalCancela });
	}
    
    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-head">
                                {!this.state.cancelada ? 
                                <>
                                    <strong>
                                        <p className="title">Relatório Venda</p>
                                    </strong>
                                    {this.state.pendencia? 
                                        this.state.permitir 
                                            ? 
                                                <div>
                                                    <button className="btn-acesso bg-laranja mr-2" onClick={() => this.toggleModalAviso()}>Liberar Acesso</button>
                                                    <button className="btn-acesso bg-dark" onClick={() => this.modalCancelar(this.props.match.params.id)}>Cancelar venda</button> 
                                                </div>
                                            : 
                                                <>
                                                    <p className="title" id="pendente">Pendente</p>
                                                    <div className="infobox">
                                                        <p>Peça aos administradores a liberação.</p>
                                                    </div> 
                                                </>
                                            : 
                                                <div className="d-flex">
                                                    <div className="header-print" onClick={() => window.open('/pdf/'+this.state.venda_id+'/6', '_blank')}>
                                                        <i className="fas fa-print"></i>
                                                    </div>
                                                    <div className="header-print" onClick={() => window.open('/pdfImpressao/'+this.state.venda_id+'/8', '_blank')}>
                                                        <i className="far fa-file"></i>
                                                    </div>
                                                </div>
                                            }
                                        </> 
                                    : 
                                        <strong>
                                            <p className="title">Venda cancelada</p>
                                        </strong>
                                    }
                            </div>
                            {!this.state.cancelada ? <>
                            <div className="relatorio-body">
                                <h1 className="header-relatorio">Detalhes</h1>
                                <div className="box-conteudo">
                                    {this.state.venda.map((venda) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Empresa</p>
                                                <p>{venda.venda_empresa}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Data de venda</p>
                                                <p>{moment(venda.venda_admissaodata).format('DD/MM/YYYY') + ' às ' + moment(venda.venda_admissaodata).format('HH:mm')}</p>
                                            </div>
                                        </>
                                    ))}
                                    {this.state.usuario.map((user) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Vendedor</p>
                                                <p>{user.usuario_nome}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                {this.state.item.length != 0 ? <h1 className="header-relatorio">Automóvel</h1> : ''}
                                <div className="box-conteudo">
                                    {this.state.item.map((item) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Marca</p>
                                                <p>{item.item_marca}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Modelo</p>
                                                <p>{item.item_modelo}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Ano Fab.</p>
                                                <p>{item.item_ano_fab}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Ano Mod.</p>
                                                <p>{item.ano}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Cor</p>
                                                <p>{item.cor}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Km</p>
                                                <p>{item.item_km}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Preço</p>
                                                <p>{(item.item_preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>RENAVAM</p>
                                                <p>{item.renavam}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Chassi</p>
                                                <p>{item.chassi}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Placa</p>
                                                <p>{item.placa}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Combustível</p>
                                                <p>{item.combustivel}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                    {this.state.itemT.length != 0 ? <h1 className="header-relatorio">Automóvel trocado</h1> : ''}
                                <div className="box-conteudo">
                                    {this.state.itemT.map((item) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Marca</p>
                                                <p>{item.item_marca}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Modelo</p>
                                                <p>{item.item_modelo}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Ano Fab.</p>
                                                <p>{item.item_ano_fab}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Ano Mod.</p>
                                                <p>{item.ano}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Cor</p>
                                                <p>{item.cor}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Km</p>
                                                <p>{item.item_km}</p>
                                            </div>
                                            
                                            <div className="conteudo-info">
                                                <p>Preço</p>
                                                <p>{item.item_preco ? (item.item_preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>RENAVAM</p>
                                                <p>{item.renavam}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Chassi</p>
                                                <p>{item.chassi}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Placa</p>
                                                <p>{item.placa}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Combustível</p>
                                                <p>{item.combustivel}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <h1 className="header-relatorio">Comprador</h1>
                                <div className="box-conteudo">
                                    {this.state.cliente.map((cli) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Nome</p>
                                                <p>{cli.pessoa_nome}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>CPF</p>
                                                <p>{cli.pessoa_cpf_cnpj}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>RG</p>
                                                <p>{cli.pessoa_rg}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Telefone</p>
                                                <p>{cli.pessoa_celular}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Celular</p>
                                                <p>{cli.pessoa_telefone}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Email</p>
                                                <p>{cli.pessoa_email}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Endereco</p>
                                                <p>{cli.endereco_logradouro}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Número</p>
                                                <p>{cli.endereco_numero}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Complemento</p>
                                                <p>{cli.endereco_complemento}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Bairro</p>
                                                <p>{cli.endereco_bairro}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Estado</p>
                                                <p>{cli.endereco_estado}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Cidade</p>
                                                <p>{cli.endereco_cidade}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                {this.state.mov.length != 0 ? <h1 className="header-relatorio">Movimentações</h1> : ''}
                                <div className="table">
                                    <table>
                                    {this.state.mov.length != 0 ?
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Descrição
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Plano de Contas
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Tipo Pagamento
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Data de Vencimento
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Valor
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead> : ''}
                                        <tbody>
                                            {
                                                this.state.mov.map((mov) => (
                                                    <tr key={mov.id}>
                                                        <td>{mov.descricao}</td>
                                                        <td>{mov.cat_nome}</td>
                                                        <td>{mov.pagamento}</td>
                                                        <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                        <td>{mov.valor ? mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <p className="py-3">{this.state.obs ? <><strong>Obs: </strong>{this.state.obs}</> : ''}</p>
                                <div>
                                {this.state.transferencia.length != 0 ? <h1 className="header-relatorio">Transferêcia</h1> : ''}
                                {
                                    this.state.transferencia.map((trans) => (
                                        <div className="box-conteudo">
                                            <div className="conteudo-info">
                                                <p>Banco</p>
                                                <p>{trans.banco}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Agência</p>
                                                <p>{trans.agencia}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Tipo de Conta</p>
                                                <p>{trans.tipo_conta}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Tipo de Conta</p>
                                                <p>{trans.conta}</p>
                                            </div>
                                        </div>
                                    ))} 
                                </div>
                                      
                            </div>
                            <div className="relatorio-footer">
                            </div> </> : ''}
                            <Versionamento/>
                        </div>
                    </div>
                </section>

                {/* MODAL AVISO */}
                <Modal
                    isOpen={this.state.modalAviso}
                    toggle={this.toggleModalAviso.bind(this)}
                    backdrop={true}
                    keyboard={true}
                    size="sm"
                    fade={false} >
                    <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                    <ModalBody>
                        <p>Deseja Liberar a venda?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                        <Button onClick={() => { this.liberar()}}>Sim</Button>
                    </ModalFooter>
                </Modal>

                {/* MODAL CANCELAR */}
                <Modal
					isOpen={this.state.modalCancela}
					toggle={this.toggleModalCancelar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalCancelar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente Cancelar a venda?
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-danger" onClick={this.cancelarVenda} color="danger">Cancelar Venda</button>
						{/* <i className="fas fa-times" onClick={() => this.cancelarVenda(this.state.vendaId)}></i> */}
					</ModalFooter>
				</Modal>

            </>
        )
    }

}

export default RelatorioVendasDetPage