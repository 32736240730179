import React from 'react'
import "../styles/style.css"

class NavSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
			navs: [],
		}
	}

	componentDidMount() {
		this.setState({ navs: this.props.navs })
	}

	move(index) {
		const slides = document.querySelectorAll(`.${this.props.slideClass}`)
		slides.forEach((s, i) => {
			s.style.display = null;
			if (i == index) {
				s.style.display = "block";
			}
		})
		this.setState({ index })
	}

	render() {
		return (
			<div>
				<div id="navs" className="form-head" style={{ padding: "0", marginBottom: "-5px" }}>
					{this.state.navs.map((n, index) => (
						<button style={{
							transition: ".4s",
							backgroundColor: "transparent",
							border: "0",
							width: "100%",
							height: "50px",
							borderBottom: `2px solid ${index == this.state.index ? "green" : "white"}`
						}} onClick={() => this.move(index)}>{n}
						</button>
					))}
				</div>
				<div>
					{this.props.children}
				</div>
			</div>
		)
	}
}

export { NavSlider }