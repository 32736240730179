import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import { api_topmotos } from '../../services/api';
import Versionamento from '../../components/Versionamento';

class RelatorioLeadsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dados: [],
            date: '',
            data: []
		};
    }

    componentDidMount() {
		this.apiListarLeads();
    }

    apiListarLeads = async event => {
		let res = await api_topmotos.get('/leads/clientes');
        this.setState({ dados: res.data });
    }

    formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }
    
    data = (event) =>{
        var data = event.target.value + '-01';
        var vetor = data.substr(0, 10).split('-');

        var mesSeg = parseInt(vetor[1]) + 1;

        if(mesSeg == 13){
            var AnoSeg = parseInt(vetor[0]) + 1;
            var dataFim = AnoSeg + '-01-01';
        } else{
            var dataFim = vetor[0] + '-' + mesSeg + '-01';
        }

        this.filtroData(data, dataFim);
    }

    filtroData = async (inicio, fim) =>{
        await api_topmotos.post('/leads/data', {
            dataInicio: inicio,
            dataFim: fim,
        }).then((response) => { 
            this.setState({ data: response.data });
        });

        this.mostrarLinha(this.state.data);
    }

    mostrarLinha = (dados) =>{
        var todos = document.getElementsByClassName('list-item');
        var item;
        
        for(var i = 0; i < todos.length; i++){
            todos[i].style.display = 'none';
        }
        
        if(dados.length != 0){
            for(var i = 0; i < dados.length; i++){
                item = document.getElementById('item' + dados[i].lead_id);
                item.style.display = 'flex';
            } 
        } else{
            item = document.getElementById('nenhum');
            item.style.display = 'flex';
        }
    }
    
    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-head">
                                <strong>
                                    <p>Relatório LEADS</p>
                                </strong>
                                <div className="campo-data">
                                    <input type="month" onChange={this.data}/>
                                </div>
                            </div>
                            <div className="relatorio-body">
                                <p className="titulo">Relatório de leads convertidos em clientes</p>
                                {
                                    this.state.dados.map((lead, id) => (
                                        <div className="list-item" id={'item' + lead.lead_id}>
                                            <div className="data">
                                                <p>{this.formatTime(lead.cliente_admissaodata)}</p>
                                            </div>
                                            <div className="nome">
                                                <p>{lead.lead_nome}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="list-item" id='nenhum'>
                                    <p>Não há nenhum lead convertido a cliente</p>
                                </div>
                            </div>
                            <div className="relatorio-footer">
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default RelatorioLeadsPage