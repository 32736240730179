import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';
import moment from "moment";

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Estoque extends React.Component {

	constructor(props) {
		super(props);

		

		this.state = {
			dados: [],

			pageSize: 1,
			
			searchId: '',
			searchNome: '',
			searchFornecedor: '',
			searchQuantidade: '',
			searchUltimaEntrada: '',
			searchUltimaSaida: '',
			searchValorUnitario: '',
			searchTotal: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',

			pageLimit: 10,
			pageAtual: 0,
		};
	}

	/**
	 * @description Organiza a paginação e ativa um indice
	 */
	 putItem = (pageAtual, index) => {

		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.state.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	nextorprevious = (e, index) => {
		e.preventDefault();

		this.setState({ pageAtual: index })
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/produtosestoque")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarProdutos();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] produtosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	apiListarProdutos = async () => {
		let res = await api_topmotos.get('/notasprodutos');
		this.setState({ dados: res.data });
		this.setState({pageSize: Math.ceil(res.data.length / this.state.pageLimit)})
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}


	filtrar = (dados) => {
		let dadosFilter = dados.filter((d) => {
				return this.filtrarID(d) && this.filtrarNome(d) && this.filtrarFornecedor(d) && this.filtrarQuantidade(d) && this.filtrarValorUnitario(d) && this.filtrarUltimaEntrada(d) && this.filtrarUltimaSaida(d)
		}) 

		this.pageSize = Math.ceil(dadosFilter.length / this.state.pageLimit);

		return dadosFilter
	}

	filtrarID = (d) => {
		return !this.state.searchId || (d.produto_id && d.produto_id.toString().toLowerCase().includes(this.state.searchId.toLowerCase()));
	}
	filtrarNome = (d) => {
		return !this.state.searchNome || (d.produto_nome && d.produto_nome.toString().toLowerCase().includes(this.state.searchNome.toLowerCase()));
	}
	filtrarFornecedor = (d) => {
		return !this.state.searchFornecedor || (d.fornecedor_nome && d.fornecedor_nome.toString().toLowerCase().includes(this.state.searchFornecedor.toLowerCase()));
	}
	filtrarQuantidade = (d) => {
		return !this.state.searchQuantidade || (d.quantidade && d.quantidade.toString().toLowerCase().includes(this.state.searchQuantidade.toLowerCase()));
	}
	filtrarValorUnitario = (d) => {
		return !this.state.searchValorUnitario || (d.valor && d.valor.toString().toLowerCase().includes(this.state.searchValorUnitario.toLowerCase()));
	}
	filtrarTotal = (d) => {
		return !this.state.searchTotal || ((d.valor * d.quantidade) && (d.valor * d.quantidade).toString().toLowerCase().includes(this.state.searchTotal.toLowerCase()));
	}
	filtrarUltimaEntrada = (d) => {
		return !this.state.searchUltimaEntrada || (d.ultima_entrada && moment(d.ultima_entrada).format("DD/MM/YYYY").toString().toLowerCase().includes(this.state.searchUltimaEntrada.toLowerCase()));
	}
	filtrarUltimaSaida = (d) => {
		return !this.state.searchUltimaSaida || (d.ultima_saida && moment(d.ultima_saida).format("DD/MM/YYYY").toString().toLowerCase().includes(this.state.searchUltimaSaida.toLowerCase()));
	}

	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Produtos Estoque</p>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Fornecedor
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Quantidade
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Compra
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Venda
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ultima Entrada
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ultima Saida
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
													<i className="fas fa-sort"></i>
											</div>
										</th>
									</tr>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchId} onChange={a => this.setState({ searchId: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchNome} onChange={a => this.setState({ searchNome: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchFornecedor} onChange={a => this.setState({ searchFornecedor: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchQuantidade} onChange={a => this.setState({ searchQuantidade: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchValorUnitario} onChange={a => this.setState({ searchQuantidade: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchTotal} onChange={a => this.setState({ searchQuantidade: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchUltimaEntrada} onChange={a => this.setState({ searchUltimaEntrada: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.searchUltimaSaida} onChange={a => this.setState({ searchUltimaSaida: a.target.value })} />
											</div>
										</th>
										<th><div></div></th>
									</tr>
								</thead>
								<tbody>

									{
										this.filtrar(this.state.dados).slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((produto, id) => (
											<tr key={produto.produto_id}>
												<td>{produto.produto_id}</td>
												<td>{produto.produto_nome}</td>
												<td>{produto.fornecedor_nome}</td>
												<td>{produto.quantidade}</td>
												<td>{produto.valor_compra ? parseFloat(produto.valor_compra)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}</td>
												<td>{produto.valor_venda ? parseFloat(produto.valor_venda)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}</td>
												<td>{produto.ultima_entrada ? moment(produto.ultima_entrada).format("DD/MM/YYYY") : "sem entradas"}</td>
												<td>{produto.ultima_saida ? moment(produto.ultima_saida).format("DD/MM/YYYY") : "sem saidas"}</td>
												<td className="td-acoes">
													<i className="fa fa-arrow-circle-up" onClick={() => this.props.history.push("/notas/produto/" + produto.produto_id)}></i>&nbsp;&nbsp;&nbsp;
													{/*<i className="fa fa-arrow-circle-down" onClick={() => {}}></i>*/}
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
							<div className="form-footer">
								{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
									</div>

									{
										[...Array(this.state.pageSize)].map((e, index) => (
											this.putItem(this.state.pageAtual, index)
										))
									}

									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.state.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
									</div>
								</div>}
							</div>
						</div>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

						<Versionamento />
					</div>
				</div>
			</section>

		)
	}
}

export default Estoque;
