import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';


class EstoqueCadastroPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            busca: false,

            marcas: [],
            marcaSE: '',

            modelosvei: [],
            modeloSE: '',
            modeloId: '',

            anos: [],
            ano: '',

            cores: [],
            cor: null,

            estoque_minimo: '',
            valor_medio: null,
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/estoque")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount(){
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarMarcas()
            this.apiListarModelos()
            this.apiListarCores()
            this.apiListarAnos()
        }
    }

    // Marcas
    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    // Modelos
    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    // Cores
    apiListarCores = async event => {
        let res = await api_topmotos.get('/cores/');
        this.setState({ cores: res.data });
    }

    // Anos
    apiListarAnos = async event => {
        let res = await api_topmotos.get('/anos/');
        this.setState({ anos: res.data });
    }

    configModelo = async (e) => {
        this.setState({ modeloSE: e });

        if (e.length == 0) {
            this.setState({ modalCadastrarModelo: null })
            return
        }

        let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))

    }

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }
    
    
    apiCadastrarEstoqueMinimo = async e => {
		e.preventDefault();

		const {
			marcaSE,
			modeloId,
			ano,
			cor,
			estoque_minimo,
        } = this.state;
        
        var inputs = [
            [marcaSE, 'marca', 'iconMarca', 'lblMarca'],
            [modeloId, 'modelo', 'iconModelo', 'lblModelo'],
            [ano, 'ano', 'iconAno', 'lblAno'],
            [cor, 'cor', 'iconCor', 'lblCor'],
            [estoque_minimo, 'estoque', 'iconEstoque', 'lblEstoque'],
        ]

		if (!marcaSE ||
			!modeloId ||
			!ano ||
			!estoque_minimo) {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

		} else {
            this.setState({ errorMessage: '' });
            
            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }

			try {
                if(typeof this.state.valor_medio != 'number'){
                    var real = this.state.valor_medio.replace('.', '');
                    real = real.replace(',', '.');
                } else{
                    real = this.state.valor_medio
                }

				await api_topmotos.post('/estoqueminimo', {
					cor_id: this.state.cor,
					anos_fipe_id: this.state.ano,
					estoque_minimo: this.state.estoque_minimo,
                    valor_medio: real
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/estoqueminimo)" });
				this.log(this.state.error);
			}

            this.props.history.push("/estoque")
        }
	}

    mascaraValor = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
						real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return real;
}

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir estoque mínimo</strong></p>

                            </div>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="marca" id="lblMarca">Marca *</label>
                                            <div className="grupo-campo-input input-cad" id="marca">
                                                <i className="fas fa-certificate" id="iconMarca"></i>
                                                <select name="" value={this.state.marcaSE} id="imark" onChange={e => this.setState({ marcaSE: e.target.value})}>
                                                    <option value="NULL">Selecione uma marca</option>
                                                    {
                                                        this.state.marcas.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="modelo" id="lblModelo">Modelo *</label>
                                            <div className="grupo-campo-input input-cad" id="modelo">
                                                <i className="fas fa-asterisk" id="iconModelo"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Modelo do item"
                                                    autoComplete="on"
                                                    onChange={event => this.configModelo(event.target.value)}
                                                    required
                                                    onFocus={a => this.setState({ busca: true })} 
                                                    onBlur={a => this.setState({ busca: false })}
                                                    value={this.state.modeloSE}
                                                    disabled={this.state.marcaSE == "NULL"} 

                                                />
                                            </div>
                                            {/* <ul className={"listC activate"}> */}
                                            <ul className={this.state.busca ? "listC activate" : "listC"}>
                                                {
                                                    this.state.marcaSE != "NULL" ?
                                                        (this.state.modelosvei.filter(y => y.id_marca == this.state.marcaSE && y.nome.toLowerCase().includes(this.state.modeloSE.toLowerCase())).map(e => (
                                                            <li onClick={a => this.setState({modeloSE: e.nome, modeloId: e.id})}>{e.nome}</li>
                                                        )))
                                                        : (null)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>                       

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="ano" id="lblAno">Ano *</label>
                                            <div className="grupo-campo-input input-cad" id="ano">
                                                <i className="fas fa-calendar-alt" id="iconAno"></i>
                                                <select name="" value={this.state.ano} id="imark" onChange={e => this.setState({ano: e.target.value})}>
                                                    <option value="NULL">Selecione o ano</option>
                                                    {
                                                        this.state.anos.filter((ano) => {
                                                            return ano.id_modelo == this.state.modeloId }).map(e => (
                                                            <option value={e.id}>{e.ano}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cor" id="lblCor">Cor </label>
                                            <div className="grupo-campo-input input-cad" id="cor">
                                                <i className="fas fa-paint-roller" id="iconCor"></i>
                                                <select name="" value={this.state.cor} onChange={e => this.setState({ cor: e.target.value})}>
                                                    <option value={null}>Selecione uma cor</option>
                                                    {
                                                        this.state.cores.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>    

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="box" id="lblEstoque">Estoque mínimo *</label>
                                            <div className="grupo-campo-input input-cad" id="estoque">
                                                <i className="fas fa-box" id="iconEstoque"></i>
                                                <input type="number" value={this.state.estoque_minimo} onChange={e => this.setState({estoque_minimo: e.target.value})} placeholder='Quantidade mínima no estoque' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="box" id="lblValorMedio">Valor Médio *</label>
                                            <div className="grupo-campo-input input-cad" id="valorMedio">
                                                <i className="fas fa-dollar-sign" id="iconValorMedio"></i>
                                                <input type="text" value={this.state.valor_medio} onChange={e => this.setState({valor_medio: this.mascaraValor(e.target.value)})} placeholder='Valor Médio do Item' />
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarEstoqueMinimo} className="cadbtn">Cadastrar</Button>

                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default EstoqueCadastroPage
