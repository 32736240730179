import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import React from 'react';
import { Button } from 'reactstrap';
import { api_topmotos } from '../../services/api';


class StatusEditarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: "",
            dash: false,
            cliente: false,
            errmsg: ''
        }

    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/Status")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarStatus(this.props.match.params.id)
        }
    }

    apiListarStatus = async (e) => {
        let {data} = await api_topmotos.post("/status/unique", { id: e});

        this.setState({
            status: data[0].status,
            dash: data[0].exibir_dashboard,
            cliente: data[0].vincular_cliente,
        })
    }

    apiCadastrarStatus = async () => {
        var inputs = [
            [this.state.status, 'status','iconStatus','lblStatus']
        ]

        if (this.state.status) {
            await api_topmotos.post("/status/update/id/" + this.props.match.params.id, {
                status: this.state.status,
                dash: this.state.dash,
                cliente: this.state.cliente
            })

            this.props.history.push("/status")
        } else{
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }
        }

        this.setState({ errmsg: "Preencha todos os campos" })
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar Status</strong></p>

                            </div>
                            <div className="content-formulario">
                                <div className="grupo-input input-style-cad">
                                    <label htmlFor="status" id="lblStatus">Status </label>
                                    <div className="grupo-campo-input input-cad" id="status">
                                        <i className="fas fa-briefcase" id="iconStatus"></i>
                                        <input value={this.state.status} type="text" placeholder="Insira o status" onChange={a => this.setState({ status: a.target.value })} />
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="grupo-input input-style-cad">
                                        <label>Exibir no dashboard </label>
                                        <label>
                                            <input checked={this.state.dash} type="checkbox" className="ckck" onClick={a => this.setState({ dash: a.target.checked })} />
                                            <span className="checkname"></span>
                                        </label>
                                    </div>

                                    <div className="grupo-input input-style-cad">
                                        <label>Vincular com o cliente </label>
                                        <label>
                                            <input checked={this.state.cliente} type="checkbox" className="ckck" onClick={a => this.setState({ cliente: a.target.checked })} />
                                            <span className="checkname"></span>
                                        </label>
                                    </div>

                                </div>

                                <br />
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errmsg}</p>
                                </div>
                                <br />

                                <Button onClick={this.apiCadastrarStatus} className="cadbtn">Editar</Button>

                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default StatusEditarPage