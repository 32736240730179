import React from 'react';
import "../styles/style.css";
import jwt from 'jwt-simple';
import { api_topmotos } from '../services/api';


import { Link } from 'react-router-dom';

class NavMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modulos: [],
			menu_itens: [],
			mAP: false,
		}

		try {
			let tka = localStorage.getItem('token').toString()
			let tk = jwt.decode(tka, "GoldWindLaw");
		} catch (error) {
			this.appLogout();
		}
	}

	componentDidMount(){
		this.apiListarMenu();
		this.apiListarModulos();
	}

	abrirMenu = (menu) => {
		var menuEscolhido = document.getElementById(menu);

		if (menuEscolhido.style.display === 'block') {
			menuEscolhido.style.display = 'none';
		} else {
			menuEscolhido.style.display = 'block';
		}
	}

	menuMob = (menu) => {
		menu = document.getElementById('menu');
		var nav = document.getElementsByTagName('nav');

		if (menu.style.display === 'block') {
			menu.style.display = 'none';
			nav[0].style.height = '60px';
		} else {
			menu.style.display = 'block';
			nav[0].style.height = '100%';
		}
	}

	appLogout = () => {
		localStorage.clear();
		this.props = this.props.props;
		this.props.history.push('/');
	}

	apiListarModulos = async event => {
        let res = await api_topmotos.get('/permissoes/modulo/usuario/'+ localStorage.getItem('usuario_id'));
		this.setState({ modulos: res.data});
    }


	apiListarMenu = async () =>{
		let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
		this.setState({ menu_itens: res.data});
	}

	render() {
		return (

			<nav>
				<div>
					<div className="menu-mob">
						<h1 className="text-center">Gerenciador</h1>
						<i className="fas fa-bars" onClick={() => { this.menuMob() }}></i>
					</div>
					<div className="menu-mob-none" id="menu">
					{
						this.state.modulos.map((mod) => (
						<div key={mod.nome}>
							<div className="menu-item-principal" onClick={() => { this.abrirMenu(mod.nome) }}>
								<div className="d-flex">
									<i className={mod.icone}></i>
									<p>{mod.nome}</p>
								</div>
								<i className="fas fa-chevron-down"></i>
							</div>
							<ul id={mod.nome}>
								{this.state.menu_itens.filter((itens) => {
									return itens.modulo == mod.id }).map((menu) => {
										if (menu.rota === "/gestaoall") return null;
										if (menu.rota === "/movimentacao/") {
											return [
												<Link key={menu.rota} to={menu.rota}><li>Relatório Financeiro</li></Link>,
												<Link key={menu.rota + "AP"} to={menu.rota + "AP"}><li>A Pagar</li></Link>,
												<Link key={menu.rota + "AR"} to={menu.rota + "AR"}><li>A Receber</li></Link>
											]
										}
										return (
												<Link key={menu.rota} to={menu.rota}><li>{menu.itens}</li></Link>
										)
									}
								)} 
							</ul>
						</div>
					))}
						<div className="menu-item-principal sair menu-mob-none">
							<div className="d-flex">
								<i className="fas fa-sign-out-alt"></i>
								<p onClick={this.appLogout}>Sair</p>
							</div>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}

export default NavMenu;
