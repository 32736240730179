import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import React from 'react';


class StatusPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: []
        }

        
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/Status")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarStatus();
        }
    }

    apiListarStatus = async event => {
        let res = await api_topmotos.get('/status');
        this.setState({ status: res.data });
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p>Status</p>
                                <div className="form-add" onClick={() => this.props.history.push("/cadastrarstatus")}>
                                    Adicionar <i className="fas fa-plus"></i>
                                </div>
                            </div>

                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Status
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Exibir no dashboard
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Vincular cliente
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ordem
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>

                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
											    </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.status.map(e => (
                                                <tr>
                                                    <td>{e.id}</td>
                                                    <td>{e.status}</td>
                                                    <td>{e.exibir_dashboard == 1 ? "Sim" : "Não"}</td>
                                                    <td>{e.vincular_cliente == 1 ? "Sim" : "Não"}</td>
                                                    <td>{e.ordenacao}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-pen" onClick={() => this.props.history.push("/editarstatus/" + e.id)}></i>&nbsp;
												</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="form-footer">
                                    <span></span>
                                </div>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default StatusPage
