import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class FinanceiroPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalCadastrarCat: false,
            modalCadastrarCatSub: false,
            modalAlterarCat: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            statusCat: '',
            descricaoCat: '',
            catId: '',
            categorias: [],
            categoriasV: [],
            conta: [],
            categoriaIdForSub: '',
            subCategorias: []
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/financeiro")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.listarCategorias();
            this.listarSub();
        }
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Listar Categorias 
    listarCategorias = async () =>{
        let res = await api_topmotos.get('/categorias/');
        this.setState({ categorias: res.data })
        this.setState({categoriasV: res.data})
    }

    // Listar sub
    listarSub = async () =>{
        let res = await api_topmotos.get('/subcategorias/');
        this.setState({ subCategorias: res.data });
    }

    formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }

    // CATEGORIA
    listarCatId = async(id) =>{
        let {data} = await api_topmotos.get("/categorias/id/" + id)

        this.setState({
            catId: data[0].id,
            descricaoCat: data[0].categoria,
            statusCat: data[0].tipo
        })
    }
    
    // Off/On modal cadastro Cat
    toggleModalCadastrarCat = () => {
		this.setState({ modalCadastrarCat: !this.state.modalCadastrarCat });
    }

    // Off/On modal Alterar Cat
    toggleModalAlterarCat = () => {
        this.setState({ modalAlterarCat: !this.state.modalAlterarCat });
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracaoCat = async (id) => {
        await this.listarCatId(id);
        await this.setState({modalAlterarCat: true})
    }

    // Inserir categoria
    salvarCategoria = async () => {
        try {
            await api_topmotos.post('/categorias/create', {
                descricao: this.state.descricaoCat,
                status: this.state.statusCat,
                admissaodata: this.getTimestamp(),
                contaId: this.props.match.params.id
            })

            this.toggleModalCadastrarCat()
            this.listarCategorias()

        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
            this.log(this.state.error);
        }
    }

    // Alterar conta
    alterarCategoria = async (id) => {
        try {
            await api_topmotos.put('/categorias/update/id/' + id, {
                id: id,
                descricao: this.state.descricaoCat,
                status: this.state.statusCat,
                atualizacao: this.getTimestamp(),
            })

            this.listarCategorias();
            this.listarSub();
            this.toggleModalAlterarCat();

        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/update)" });
            this.log(this.state.error);
        }
    }

    // Abrir Opções
    openToggle = (id) =>{
        var toggles = document.getElementsByClassName('toggleOptions');
        var toggleA = document.getElementById(id);
        
        if(toggleA.style.display == 'block'){
            toggleA.style.display = 'none'
        } else{
            for(var i = 0; i < toggles.length; i++){
                toggles[i].style.display = 'none';
            }

            toggleA.style.display = 'block'
        }

    }

    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.delete('/categorias/delete/id/' + this.state.modalDeletarId);
            this.listarCategorias();
            this.listarSub();
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/categorias/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Categoria deletada com sucesso!' });
    }

    // Abrir modal deletar
    OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/categorias/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
    }

    // SUBCATEGORIA
    // Off/On modal cadastro Cat
    toggleModalCadastrarCatSub = () => {
		this.setState({ modalCadastrarCatSub: !this.state.modalCadastrarCatSub });
    }   

    abrirModalCadastrarSub = (id) =>{
        this.setState({categoriaIdForSub: id});
        this.toggleModalCadastrarCatSub();
    }

    // Inserir categoria
    salvarCategoriaSub = async () => {
        try {
            await api_topmotos.post('/subcategorias/create', {
                descricao: this.state.descricaoCat,
                status: null,
                admissaodata: this.getTimestamp(),
                catId: this.state.categoriaIdForSub
            })

            this.toggleModalCadastrarCatSub()
            this.listarSub()

        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
            this.log(this.state.error);
        }
    }

    // VIEW SUB 
    viewSubCat =  (id) =>{
        var body = document.getElementsByName('categoria-body');
        var sub = document.getElementById(id)
        
        if(sub.style.display == 'block'){
            sub.style.display = 'none'
        } else{
            for(var i = 0; i < body.length; i++){
                body[i].style.display = 'none';
            }

            sub.style.display = 'block'
        }
        
    }

    // Mudar operação
    changeOperacao = async (status) =>{
        if(status){
            let array_categorias = await this.state.categorias.filter((dados) => {
                return dados.tipo == status
            })
    
            this.setState({categoriasV: array_categorias})
        } else{
            this.listarCategorias();
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Plano de Contas</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrarCat: true})}>Adicionar categorias<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="relatorio-titulo">
                                <p>Tipo</p>
                                <div>
                                    <select onChange={event => this.changeOperacao(event.target.value)}>
                                        <option value="">Todas</option>
                                        <option value="R">Receitas</option>
                                        <option value="D">Despesas</option>
                                    </select>
                               </div>
                            </div>
                            
                            {this.state.categoriasV.map((cat) => (
                                <div className="categoria">
                                    <div className="categoria-head d-flex justify-between w-100">
                                        <div className="d-flex-start cat-head-calc" onClick={() => this.viewSubCat('sub'+cat.id)}>
                                            <div className={"bolinha tag-" + cat.tipo}>{cat.categoria.substr(0, 1)}</div>
                                            <p className="p-cat">{cat.categoria}</p>
                                        </div>
                                        <i className="fas fa-ellipsis-h" onClick={() => this.openToggle("toggle" + cat.id)}></i>
                                        <div className="toggleOptions" id={"toggle" + cat.id}>
                                            <p onClick={() => {this.OpenModalAlteracaoCat(cat.id); this.openToggle("toggle" + cat.id)}}><i className='fas fa-pen'></i>Alterar</p>
                                            <p onClick={() => {this.OpenModalDeletar(cat.id); this.openToggle("toggle" + cat.id)}}><i className='fas fa-trash'></i>Excluir</p>
                                            <p onClick={() => {this.abrirModalCadastrarSub(cat.id); this.openToggle("toggle" + cat.id)}}><i className='fas fa-plus'></i>Cadastrar Sub</p>
                                        </div>
                                    </div>
                                    <div className="categoria-body" id={'sub'+cat.id}>
                                        {this.state.subCategorias.filter((sub) => {
                                            return sub.cat_id == cat.id }).map((subCat) => {
                                                    return (
                                                        <>
                                                            <div className="cat-sub-item">
                                                                <p>{subCat.categoria}</p>
                                                                <div className="d-flex">
                                                                    <p className="p-2" onClick={() => this.OpenModalAlteracaoCat(subCat.id)}><i className='fas fa-pen'></i></p>
                                                                    <p onClick={() => {this.OpenModalDeletar(subCat.id)}}><i className='fas fa-trash'></i></p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                        })}
                                        {this.state.subCategorias.filter((sub) => {
                                            return sub.cat_id == cat.id }).length == 0 && "Não há subcategorias"}
                                    </div>
                                </div>
                            ))}
                            <Versionamento/>
                        </div>
                    </div>
    
                    {/* CATEGORIA */}
                    {/* Modal de Cadastro de Categoria */}
                    <Modal
					isOpen={this.state.modalCadastrarCat}
					toggle={this.toggleModalCadastrarCat.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrarCat.bind(this)}>Adicionar Plano de Contas</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="status">Status *</label>
                                            <div className="grupo-campo-input input-cad">
                                            <i class="fas fa-comment-dollar"></i>
                                                <select onChange={event => this.setState({statusCat: event.target.value})}> 
                                                    <option value="">Selecione</option>
                                                    <option value="D">Despesa</option>
                                                    <option value="R">Receita</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="desc">Descrição</label>
                                            <div className="grupo-campo-input input-cad" id="desc">
                                            <i class="fas fa-receipt"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Descreva as informações sobre a conta"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ descricaoCat: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button>Fechar</Button>
                            <Button className="bg-laranja" onClick={this.salvarCategoria}>Salvar</Button>
						</ModalFooter>
					</Modal>

                    {/* Modal de Alterar Categoria */}
                    <Modal
					isOpen={this.state.modalAlterarCat}
					toggle={this.toggleModalAlterarCat.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalAlterarCat.bind(this)}>Alterar Plano de Contas</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    
                                    {this.state.statusCat &&
                                        <div className="col-md-12">
                                            <div className="grupo-input input-style-cad">
                                                <label htmlFor="status">Status *</label>
                                                <div className="grupo-campo-input input-cad">
                                                <i class="fas fa-comment-dollar"></i>
                                                    <select value={this.state.statusCat} onChange={event => this.setState({statusCat: event.target.value})}> 
                                                        <option value="">Selecione</option>
                                                        <option value="D">Despesa</option>
                                                        <option value="R">Receita</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                        <div className="col-md-12">
                                            <div className="grupo-input input-style-cad">
                                                <label htmlFor="desc">Descrição</label>
                                                <div className="grupo-campo-input input-cad" id="desc">
                                                <i class="fas fa-receipt"></i>
                                                    <input
                                                        type="text"
                                                        placeholder="&nbsp;Descreva as informações sobre a conta"
                                                        autoComplete="on"
                                                        value={this.state.descricaoCat}
                                                        onChange={event => this.setState({ descricaoCat: event.target.value })}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button>Fechar</Button>
                            <Button className="bg-laranja" onClick={() => this.alterarCategoria(this.state.catId)}>Salvar</Button>
						</ModalFooter>
					</Modal>

                    {/*Modal Deletar*/}
                    <Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                        <ModalBody>
                            Deseja realmente deletar a categoria<br></br>(ID: {this.state.modalDeletarId})?
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.deletar} color="danger">Deletar</Button>
                            <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                
                    {/* MODAL AVISO */}
                    <Modal
                        isOpen={this.state.modalAviso}
                        toggle={this.toggleModalAviso.bind(this)}
                        backdrop={true}
                        keyboard={true}
                        size="sm"
                        fade={false} >
                        <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                        <ModalBody>
                            {this.state.modalAvisoText}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                
                    {/* SUBCATEGORIA */}
                    {/* Modal de Cadastro de Categoria */}
                    <Modal
					isOpen={this.state.modalCadastrarCatSub}
					toggle={this.toggleModalCadastrarCatSub.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrarCatSub.bind(this)}>Adicionar Plano de Contas</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="desc">Descrição</label>
                                            <div className="grupo-campo-input input-cad" id="desc">
                                            <i class="fas fa-receipt"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Descreva as informações sobre a conta"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ descricaoCat: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button>Fechar</Button>
                            <Button className="bg-laranja" onClick={this.salvarCategoriaSub}>Salvar</Button>
						</ModalFooter>
					</Modal>

                </section>
            </>
        )
    }
}

export default FinanceiroPage