import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';

class AddEspecie extends React.Component {
	constructor(props) {
		super(props);

		this.pageSize = 1;

		this.state = {
			id: null,
			modalCadastrarNome: '',
			errorMessage: '',
		};
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }

	log(msg) {
		console.log("[" + this.getTimestamp() + "] especieUPDATE/CREATE > " + msg);
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/especie")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		}
		if (this.props.match.params.id) {
			await this.getEspecie(this.props.match.params.id)

			if (!this.state.id) {
				this.props.history.push('/especie');
			}
		}
	}

	getEspecie = async (id) => {
		let res = await api_topmotos.get('/especie/id/' + id);
		res = res.data[0];

		this.setState({
			id: res.id,
			modalCadastrarNome: res.nome,
		})
	}

	alterarOuCadastrar = async e => {
		if (this.state.id) {
			this.apiaAterarEspecie()
		} else {
			this.apiCadastrarEspecie()
		}
	}

	apiaAterarEspecie = async e => {
		var inputId = document.getElementById("nome");
		var inputIcone = document.getElementById("lblNome");
		if (!this.state.modalCadastrarNome) {
			inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
			inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
			return;
		} else {
			inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
		}

		try {
			await api_topmotos.put('/especie/update/id/' + this.state.id, {
				nome: this.state.modalCadastrarNome,
			});
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/especie/update/id/)" });
			this.log(this.state.error);
		}

		this.props.history.push('/especie');
	}

	apiCadastrarEspecie = async e => {
		var inputId = document.getElementById("nome");
		var inputIcone = document.getElementById("lblNome");
		if (!this.state.modalCadastrarNome) {
			inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
			inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
			return;
		} else {
			inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
		}

		try {
			await api_topmotos.post('/especie/create', {
				nome: this.state.modalCadastrarNome,
			});
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/especie/create)" });
			this.log(this.state.error);
		}

		this.props.history.push('/especie');
	}

	render() {
		return (
			<>
				<section>
					<div className="content-all d-flex">
						<NavMenu props={this.props} />
						<div className="content-session">
							<NavUsuario />
							<div className="form-head">
								<p><strong>Inserir Espécie</strong></p>
							</div>
							<div className="content-formulario">
								<p className="subtitle-form">Informações da Espécie</p>
								<div className="row">
									{this.state.id ? (
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="nome">Id *</label>
												<div className="grupo-campo-input input-cad disabled">
													<i className="fas fa-user"></i>
													<input
														type="text"
														placeholder="&nbsp;Id"
														autoComplete="on"
														value={this.state.id}
														disabled={true}
													/>
												</div>
											</div>
										</div>
									) : null}
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="nome" id="lblNome">Nome *</label>
											<div className="grupo-campo-input input-cad" id="nome">
												<i className="fas fa-user"></i>
												<input
													type="text"
													placeholder="&nbsp;Informe o Nome do fornecedor"
													autoComplete="on"
													value={this.state.modalCadastrarNome}
													onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
													required
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="aviso-formulario">
									<p className="aviso" id="aviso">{this.state.errorMessage}</p>
								</div>
								<br/><br/>
								<Button onClick={this.alterarOuCadastrar} className="cadbtn">{this.state.id ? 'Salvar' : 'Cadastrar'}</Button>
							</div>
							<Versionamento />
						</div>
					</div>
				</section>
			</>
		)
	}
}

export default AddEspecie