import React from 'react';
import { api_topmotos } from '../../services/api';
import moment from 'moment'


class PDFImpressaoPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            modelo: ''
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas" || a.rota == "/vendas/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount(){
        // if(await this.verificarPermissao()){
        //     this.props.history.push('/dashboardcomercial')
        // } else{
            await this.apiListarModelo(this.props.match.params.modelo)
            await this.apiListarVenda(this.props.match.params.iten)
        // }
    }

    apiListarModelo = async (id) => {
        let {data} = await api_topmotos.post("/modelosdoc/unique", {id: id})
        this.setState({modelo: data[0].conteudo})
    }

    apiListarVenda = async (id) => {
        let {data} = await api_topmotos.post("/vendas/unique", {id: id})

        await this.apiVeiculoId(data.itens[0].vendaitens_item);

        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@data/g, data.venda[0].venda_admissaodata ? moment(data.venda[0].venda_admissaodata).format('DD/MM/YYYY') : '')
        this.setState({modelo: modelo}) 
        await this.openPdf()
    }

    apiVeiculoId = async (id) =>{
        let {data} = await api_topmotos.post("/itens/unique", {id: id})
            
        let modelo = this.state.modelo;  
        
        document.title = `Checklist ${data[0].item_modelo} - ${data[0].placa}`

        modelo = modelo.replace(/@modeloVeiculo/g, data[0].item_modelo ? data[0].item_modelo : '')
        .replace(/@placaVeiculo/g, data[0].placa ? data[0].placa : '')


        this.setState({modelo: modelo}) ;
        
    }

    openPdf = async () =>{
        window.print();
    }

    render() {
        return (
            <>
            <section className="container d-flex align-item-center py-5">
                <div className="">

                    <div dangerouslySetInnerHTML={{__html: this.state.modelo}}>

                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default PDFImpressaoPage;