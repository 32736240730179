import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class ChecklistPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            motivo: '',
            prazo: '',
            id:'',
            motivos: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            ativo:''
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/motivo")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.listarMotivo();
        }
    }

    listarMotivo = async () =>{
        let res = await api_topmotos.get('/motivonVenda/');
        this.setState({ motivos: res.data });
    }

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/motivonVenda/id/" + id)
        
        this.setState({
            id: data[0].id,
            motivo: data[0].motivo,
            prazo: data[0].prazo_notificacao
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar, errorMessage: ''});
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar, errorMessage: ''});
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);

        await this.setState({modalAlterar: true})

    }

    apiCadastrarMotivo = async () => {

        var inputs = [
            [this.state.motivo, 'motivo', 'iconMotivo', 'lblMotivo'],
            [this.state.prazo, 'prazo', 'iconPrazo', 'lblPrazo']
        ]

        if(this.state.motivo && this.state.prazo) {
            try {
                await api_topmotos.post('/motivonVenda/create', {
                    motivo: this.state.motivo,
                    prazo: this.state.prazo
                });

                this.toggleModalCadastrar()
                this.listarMotivo()
            }catch(err) {
                console.log(this.state.motivo, this.state.prazo)
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/motivos/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarMotivo = async () => {
        var inputs = [
            [this.state.motivo, 'motivo', 'iconMotivo', 'lblMotivo'],
            [this.state.prazo, 'prazo', 'iconPrazo', 'lblPrazo']
        ]

        if(this.state.motivo && this.state.prazo) {
            try {
                await api_topmotos.put('/motivonVenda/update/id/' + this.state.id, {
                    id: this.state.id,
                    motivo: this.state.motivo,
                    prazo: this.state.prazo
                })

                this.toggleModalAlterar()
                this.listarMotivo()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/motivonVenda/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var check = document.getElementById("motivo" + id);

        try {
            await api_topmotos.post('/motivonVenda/ativar/id/' + id, {
                id: this.state.id,
                check: check.checked == true ? 0 : 1
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/motivonVenda/create)" });
            this.log(this.state.error);
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Motivo da não Venda</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Motivo<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Id
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Motivo
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Prazo
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Opções
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ativo
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.motivos.map((motivo) => (
                                                <tr key={motivo.id}>
                                                    <td>{motivo.id}</td>
                                                    <td>{motivo.motivo}</td>
                                                    <td>{motivo.prazo_notificacao}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-pen" onClick={() => this.OpenModalAlteracao(motivo.id)}></i>&nbsp;
                                                        {/* <i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(motivo.id)}}></i> */}
                                                    </td>
                                                    <td>
                                                        <label className="switch">
                                                            <input type="checkbox" id={"motivo" + motivo.id} onClick={() => this.toggleAtivar(motivo.id)} defaultChecked={motivo.ativo == 0 ? true : false}/>
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="form-footer">
                                    <span></span>
                                </div>
                            </div>

                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="lg"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Motivo</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="motivo" id="lblMotivo">Motivo*</label>
                                                        <div className="grupo-campo-input input-cad" id="motivo">
                                                            <i className="fas fa-pen" id="iconMotivo"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva o Motivo"
                                                                onChange={event => this.setState({ motivo: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="prazo" id="lblPrazo">Prazo de Notificação (Em dias)*</label>
                                                        <div className="grupo-campo-input input-cad" id="prazo">
                                                        <i class="far fa-calendar-alt" id="iconPrazo"></i>
                                                            <input
                                                                type="number"
                                                                autoComplete="on"
                                                                placeholder=""
                                                                onChange={event => this.setState({ prazo: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrar}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarMotivo}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="lg"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Motivo</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="motivo" id="lblMotivo">Motivo*</label>
                                                        <div className="grupo-campo-input input-cad" id="motivo">
                                                            <i className="fas fa-pen" id="iconMotivo"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva o Motivo"
                                                                value={this.state.motivo}
                                                                onChange={event => this.setState({ motivo: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="prazo" id="lblPrazo">Prazo de Notificação (Em dias)</label>
                                                        <div className="grupo-campo-input input-cad" id="prazo">
                                                            <i class="far fa-calendar-alt" id="iconPrazo"></i>
                                                            <input
                                                                type="number"
                                                                autoComplete="on"
                                                                placeholder=""
                                                                value={this.state.prazo}
                                                                onChange={event => this.setState({ prazo: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarMotivo}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ChecklistPage