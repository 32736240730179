import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

class FornecedorCadastroPage extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			dados: [],

			modalCadastrarOpen: false,
			modalCadastrarNome: '',
			modalCadastrarTelefone: '',
			modalCadastrarEmail: '',
			modalCadastrarCnpj: '',
			modalCadastrarEnderecoId: '',
			modalCadastrarCep: '',
			modalCadastrarEstado: '',
			modalCadastrarCidade: '',
			modalCadastrarBairro: '',
			modalCadastrarLogradouro: '',
			modalCadastrarNumero: '',
			modalCadastrarComplemento: '',

            isFornecedor: null,
            avisoFornecedor: '',
		};
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/fornecedores")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

    async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		}
        this.apiListarFornecedores();
	}

    apiCadastrarFornecedor = async e => {
        e.preventDefault();

        const {
            modalCadastrarNome,
            modalCadastrarTelefone,
            modalCadastrarEmail,
            modalCadastrarCnpj,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            modalCadastrarComplemento,
            modalCadastrarDescricao,
        } = this.state;

        if(!modalCadastrarNome       ||
            !modalCadastrarTelefone    ||
            !modalCadastrarCep         ||
            !modalCadastrarEstado      ||
            !modalCadastrarCidade      ||
            !modalCadastrarBairro      ||
            !modalCadastrarLogradouro  ||
            !modalCadastrarNumero      ||
            !modalCadastrarEstado === 'NULL') {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
                
        } else {
            this.setState({ errorMessage: '' });

            try {
                await api_topmotos.post('/enderecos/create', {
                    endereco_cep:           modalCadastrarCep,
                    endereco_estado:        modalCadastrarEstado,
                    endereco_cidade:        modalCadastrarCidade,
                    endereco_bairro:        modalCadastrarBairro,
                    endereco_logradouro:    modalCadastrarLogradouro,
                    endereco_numero:        modalCadastrarNumero,
                    endereco_complemento:   modalCadastrarComplemento,
                    endereco_admissaodata:  this.getTimestamp(),
                }).then(res => {
                    this.setState({ modalCadastrarEnderecoId: res.data.returnId });
                });
            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }

            try {
                await api_topmotos.post('/fornecedores/create', {
                    fornecedor_nome:          modalCadastrarNome,
                    fornecedor_email:         modalCadastrarEmail,
                    fornecedor_telefone:      modalCadastrarTelefone,
                    fornecedor_cnpj:          modalCadastrarCnpj,
                    fornecedor_endereco:      this.state.modalCadastrarEnderecoId,
                    fornecedor_admissaodata:  this.getTimestamp(),
                    fornecedor_descricao:     modalCadastrarDescricao,
                })
            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/fornecedores/create)" });
                this.log(this.state.error);
            }

            this.props.history.push('/fornecedores');
        }
    }

    getCEP = async (event) => {
		let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');
        this.setState({ modalCadastrarCep: event.target.value });

		if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstado: res['data'].uf,
                modalCadastrarCidade: res['data'].localidade,
                modalCadastrarBairro: res['data'].bairro,
                modalCadastrarLogradouro: res['data'].logradouro,
            })
		}
    }
    
    maskCnpjCpf = (e) => {
        let mask = []

        let regexs = {
            "0": [/^(\d{3})(\d{3})?/, "$1.$2"],
            "1": [/^(\d{3})(\d{3})?(\d{3})?/, "$1.$2.$3"],
            "2": [/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4"],
            "3": [/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5"],
        }

        if (e.length < 5){
            mask = regexs[0]
        }
        else if (e.length < 10){
            mask = regexs[1]
        }
        else if (e.length < 15){
            mask = regexs[2]
        }
        else{
            mask = regexs[3]
        }

        e = e.replace(/\D/g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(mask[0], mask[1]) 
        this.setState({modalCadastrarCnpj: e})

        this.getFornecedor(e);
    }

    apiListarFornecedores = async event => {
		let res = await api_topmotos.get('/fornecedores/');
		this.setState({ dados: res.data });
		this.setState({pageSize: Math.ceil(res.data.length / this.state.pageLimit)})
	}

    getFornecedor = async (e) => {
        let data = this.state.dados.filter(f => f.fornecedor_cnpj === e);
        if (data.length > 0) {
            let fornecedor = await api_topmotos.get("/fornecedores/id/" + data[0].fornecedor_id)
            fornecedor = fornecedor.data;
            console.log(fornecedor[0])
            let aviso = document.getElementById('avisoFornecedor')
            aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
            this.setState({ 
                avisoFornecedor: 'Fornecedor Encontrado', 
                isFornecedor: true,
                modalCadastrarBairro: fornecedor[0].endereco_bairro,
                modalCadastrarLogradouro: fornecedor[0].endereco_logradouro,
                modalCadastrarNumero: fornecedor[0].endereco_numero,
                modalCadastrarComplemento: fornecedor[0].endereco_complemento,
                modalCadastrarCep: fornecedor[0].endereco_cep,
                modalCadastrarCidade: fornecedor[0].endereco_cidade,
                modalCadastrarEstado: fornecedor[0].endereco_estado,
                modalCadastrarTelefone: fornecedor[0].fornecedor_telefone,
                modalCadastrarNome: fornecedor[0].fornecedor_nome,
                modalCadastrarEmail: fornecedor[0].fornecedor_email,
                modalEditarEnderecoId: fornecedor[0].fornecedor_endereco,
                modalCadastrarDescricao: fornecedor[0].fornecedor_descricao || "Fornecedor sem Descrição"
            });
        } else {
            let aviso = document.getElementById('avisoFornecedor')
            aviso.style.cssText = null;
            this.setState({ 
                avisoFornecedor: '', 
                isFornecedor: false,
            });
        }
    }
    
    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir fornecedor</strong></p>
                            </div>
                            <div className="content-formulario">
                                <p className="subtitle-form">Informações sobre o fornecedor</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="rg">CNPJ/CPF</label>
                                            <div className="grupo-campo-input input-cad" id="rg">
                                                <i className="fas fa-id-badge" id="iconRg"></i>
                                                <input maxLength="18" type="text" value={this.state.modalCadastrarCnpj} onChange={a => this.maskCnpjCpf(a.target.value)}/>
                                            </div>
                                            <p className="valorFipe pt-2" id="avisoFornecedor">{this.state.avisoFornecedor}</p>
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="email">E-mail</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="email">
                                                <i className="far fa-envelope" id="iconEmail"></i>
                                                <input
                                                    type="email"
                                                    placeholder="&nbsp;Informe o E-mail do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarEmail: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarEmail}
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nome">Nome *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="nome">
                                                <i className="fas fa-user" id="iconNome"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Nome do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarNome}
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="telefone">Telefone *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="telefone">
                                                <i className="fas fa-phone" id="iconTelefone"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="&nbsp;Informe o Telefone do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarTelefone: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarTelefone}
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <p className="subtitle-form">Informações sobre endereço</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cep">CEP *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cep">
                                                <i className="fas fa-envelope" id="iconCep"></i>
                                                <InputMask
                                                    mask="99999-999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Código Postal (CEP) do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.getCEP(event, "cadastrar")}
                                                    required
                                                    value={this.state.modalCadastrarCep}
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estado">Estado *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="estado">
                                                <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                <select
                                                    name="estado"
                                                    value={this.state.modalCadastrarEstado}
                                                    placeholder="&nbsp;Selecione o Estado de residência do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarEstado: event.target.value })}
                                                    required
                                                    disabled={this.state.isFornecedor}
                                                >
                                                    <option value="NULL">Selecione o Estado de residência do fornecedor</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cidade">Cidade *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cidade">
                                                <i className="fas fa-city" id="iconCidade"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarCidade}
                                                    placeholder="&nbsp;Informe a Cidade de residência do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarCidade: event.target.value })}
                                                    required
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="logradouro">Logradouro *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="logradouro">
                                                <i className="fas fa-road" id="iconLogradouro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarLogradouro}
                                                    placeholder="&nbsp;Informe o Logradouro (Rua/Avenida) do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarLogradouro: event.target.value })}
                                                    required
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="numero">Número *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="numero">
                                                <i className="fas fa-home" id="iconNumero"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Número de Residência do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarNumero: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarNumero}
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="bairro">Bairro *</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="bairro">
                                                <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarBairro}
                                                    placeholder="&nbsp;Informe o Bairro do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarBairro: event.target.value })}
                                                    required
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="complemento">Complemento</label>
                                            <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="complemento">
                                                <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarComplemento}
                                                    placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.) do fornecedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarComplemento: event.target.value })}
                                                    required
                                                    disabled={this.state.isFornecedor}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grupo-input input-style-cad">
                                    <div className={this.state.isFornecedor ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"}>
                                        <textarea style={{border: "0"}} disabled={this.state.isFornecedor} value={this.state.modalCadastrarDescricao} onChange={a => this.setState({modalCadastrarDescricao: a.target.value})} className="box-obs" placeholder="Escreva aqui uma descrição"></textarea>
                                    </div>
                                </div>

                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button disabled={this.state.isFornecedor} onClick={this.apiCadastrarFornecedor} className="cadbtn">Cadastrar</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
					
            </>
        )
    }
}

export default FornecedorCadastroPage
