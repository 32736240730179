import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from "moment";


import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { Link } from 'react-router-dom'

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class VendasConsorcio extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],

			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesEmpresa: '',
			modalDetalhesCliente: '',
			modalDetalhesUsuario: '',
			modalDetalhesDescricao: '',
			modalDetalhesGrupo: '',
			modalDetalhesCota: '',
			modalDetalhesPlano: '',
			modalDetalhesValor: '',
			modalDetalhesAdmissaodata: '',
			modalDetalhesMeses: '',
			modalDetalhesEmpresaC: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarItens: [],
			modalDeletarData: '',

			modalAvisoOpen: false,
			modalAvisoText: '',

			fid: '',
			fve: '',
			fda: '',
			fcl: '',
			fpl: '',
            fgp: '',
			fct: '',
			fst: '',

			openmodal: false,
			modelos: [],
			modelSE: "NULL",
			itpdf: '',
			status: [],
			vendaitens: [],
            pagina: 1, 
			quantidade_paginas: 1,
			modalCancela: false,
			vendaId: '', 
			permissao: [], 
			permitir: false,
            movimentacoes: [],
            
            consorcios: [],
            consorcio: '',
            observacao: '',
            grupo: '',
            cota: '',
            lettersInfoConsorcio: null,
            openmodalConsorcio: false,
		};
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendaconsorcio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
		if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
			await this.apiListarVendas();
			await this.apiListarPermissao();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	setValor = async (itens, modulo) => {
		var somaValor = 0;
		if (itens) {
			let max = itens.length;
			for (let i = 0; i < max; i++) {
				let idItem = itens[i];
				let res = await api_topmotos.get('/itens/id/' + idItem);
				somaValor += res.data[0].item_preco;
			}
			if (modulo === 'cadastrar') {
				this.setState({ modalCadastrarValor: somaValor });
			}
			if (modulo === 'editar') {
				this.setState({ modalEditarValor: somaValor });
			}
		}
	}

	apiListarVendas = async event => {
		let res = await api_topmotos.get('/vendaconsorcio/');
		this.setState({ dados: res.data, quantidade_paginas: Math.ceil(res.data.length / 10)});
	}

	apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
		let cancel = res.data.filter(a => a.restricao_id == 1);
		let view = res.data.filter(a => a.restricao_id  == 5);
		
		if(view.length == 0){
			let venda = this.state.dados.filter(a => a.usuario == localStorage.getItem('usuario_id'));
			this.setState({dados: venda})
		}

		this.setState({permitir: cancel.length > 0 ? true : false})
    }

	handleItens = (valores, modulo) => {
		console.log(valores);
		if (modulo === 'cadastrar') {
			if (valores) {
				this.state.modalCadastrarItens = valores.map((item) => (
					item.value
				))
				console.log(this.state.modalCadastrarItens);
			}
			if (!valores || valores === null || valores === undefined) {
				this.state.modalCadastrarItens = [];
				console.log(this.state.modalCadastrarItens);
			}

			this.setValor(this.state.modalCadastrarItens, 'cadastrar');
		}
		if (modulo === 'editar') {
			if (valores) {
				this.state.modalEditarItens = valores.map((item) => (
					item.value
				))
				console.log(this.state.modalEditarItens);
			}
			if (!valores || valores === null || valores === undefined) {
				this.state.modalEditarItens = [];
				console.log(this.state.modalEditarItens);
			}

			this.setValor(this.state.modalEditarItens, 'editar');
		}
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idVenda) => {
		let resVenda = await api_topmotos.get('/vendaconsorcio/id/' + idVenda);
        resVenda = resVenda.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resVenda.vcid,
			modalDetalhesEmpresa: resVenda.vcem,
			modalDetalhesCliente: resVenda.pessoa_nome,
			modalDetalhesUsuario: resVenda.usuario_nome,
			modalDetalhesData: moment(resVenda.admissaodata).format("DD/MM/YYYY"),
			modalDetalhesValor: resVenda.valor,
			modalDetalhesMeses: resVenda.meses,
			modalDetalhesDescricao: resVenda.observacao,
			modalDetalhesGrupo: resVenda.grupo,
			modalDetalhesCota: resVenda.cota,
			modalDetalhesPlano: resVenda.plano,
			modalDetalhesEmpresaC: resVenda.empresa
		});
	}

	toggleModalCadastrar = () => {
		this.setState({ modalCadastrarOpen: !this.state.modalCadastrarOpen });
	}

	toggleModalCancelar = () => {
		this.setState({ modalCancela: !this.state.modalCancela });
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (idVenda) => {
		let resVenda = await api_topmotos.get('/vendas/id/' + idVenda);
		resVenda = resVenda.data[0];

		let resVendaItens = await api_topmotos.get('/vendaitens/venda/' + idVenda);
		resVendaItens = resVendaItens.data;

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: resVenda.venda_id,
			modalDeletarItens: resVendaItens,
			modalDeletarData: resVenda.venda_data,
		});
	}

	apiDeletarVenda = async e => {
		e.preventDefault();

		let max = this.state.modalDeletarItens.length;
		for (let i = 0; i < max; i++) {
			try {
				await api_topmotos.delete('/vendaitens/delete/id/' + this.state.modalDeletarItnes[i].vendaitens_id);
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/vendaitens/delete)" });
				this.log(this.state.error);
			}
		}

		try {
			await api_topmotos.delete('/vendas/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/vendas/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Venda deletada com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	setQuantidade = (qt) => {
		this.setState({quantidade_paginas: Math.ceil(qt / 10)});
	}

	filtrar = (ara) => {
		let filtro = ara.filter(v => {
			return this.filtrarID(v) && this.filtrarVendedor(v) && this.filtrarCliente(v) && this.filtrarData(v) && this.filtrarPlano(v) && this.filtrarGrupo(v) && this.filtrarCota(v) ;
		})

		return filtro;
	}

	filtrarID = (v) => {
		return !this.state.fid || (v.id &&
			v.id.toString().toLowerCase().includes(this.state.fid.toLowerCase()));
	}

	filtrarVendedor = (v) => {
		return !this.state.fve || (v.usuario_nome &&
			v.usuario_nome.toString().toLowerCase().includes(this.state.fve.toLowerCase()));
	}

	filtrarCliente = (v) => {
		return !this.state.fcl || (v.pessoa_nome &&
			v.pessoa_nome.toString().toLowerCase().includes(this.state.fcl.toLowerCase()));
	}

	filtrarPlano = (v) => {
		return !this.state.fpl || (v.plano &&
			v.plano.toString().toLowerCase().includes(this.state.fpl.toLowerCase()));
    }
    
    filtrarCota = (v) => {
		return !this.state.fct || (v.cota &&
			v.cota.toString().toLowerCase().includes(this.state.fct.toLowerCase()));
    }
    
    filtrarGrupo = (v) => {
		return !this.state.fgp || (v.grupo &&
			v.grupo.toString().toLowerCase().includes(this.state.fgp.toLowerCase()));
	}

	filtrarData = (v) => {
		return !this.state.fda || (v.admissaodata &&
			this.formatTime(v.admissaodata).toString().toLowerCase().includes(this.state.fda.toLowerCase()));
	}

	closeContractModal = () => this.setState({ openmodal: false })

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	cancelarVenda = async e =>{
		let id = this.state.vendaId

		await api_topmotos.put('/vendaconsorcio/delete/id/' + id)

		this.toggleModalCancelar();
		this.apiListarVendas();
	}

	modalCancelar = (id) =>{
		this.setState({
			vendaId: id
		});

		this.toggleModalCancelar();
	}

	habilitarEdicao = (id) =>{
		let mov = this.state.movimentacoes.filter(a => a.venda_id == id);
		if(mov.length > 0){
			let soma = mov.length
			mov = mov.filter(a => a.conta_status == 'AR')
			soma -= mov.length;
			return soma == 0 ? true : false;
		} else{
			return false;
		}
    }

    togglemodalConsorcio = () => this.setState({ openmodalConsorcio: !this.state.openmodalConsorcio })
    
    alterarConsorcio = async () =>{
        var inputs = [
            [this.state.consorcio, 'consorcio', 'lblConsorcio'],
            [this.state.grupo, 'grupo', 'lblGrupo'],
            [this.state.cota, 'cota', 'lblCota'],
            [this.state.observacao, 'observacao', 'lblObservacao']
        ]

        let cont = inputs.length;

        for(let i = 0; i < inputs.length; i++){
            if(inputs[i][0]){
                cont--;
            }

        }

        if(cont == 0){
            try{

                await api_topmotos.put('/vendaconsorcio/update/id/' + this.state.vendaId, {
                    consorcio: this.state.consorcio,
                    grupo: this.state.grupo,
                    cota: this.state.cota,
                    observacao: this.state.observacao
                })
                
                this.togglemodalConsorcio();
                this.apiListarVendas(); 
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendaconsorcio/update)" });
                this.log(this.state.error);
            }
        } else{
            for(var i = 0; i < inputs.length; i++){
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputNome = document.getElementById(inputs[i][2]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputNome = document.getElementById(inputs[i][2]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #777; transition: 0.2s;";
                }
            }
        }
    }

    apiListarConsorcios = async event => {
        let res = await api_topmotos.get('/consorcio/');
        this.setState({ consorcios: res.data });
    }

    openModalAlterar = async (id) =>{
        let resVenda = await api_topmotos.get('/vendaconsorcio/id/' + id);
        resVenda = resVenda.data[0];

		this.setState({
            vendaId: id,
			observacao: resVenda.observacao,
			grupo: resVenda.grupo,
			cota: resVenda.cota,
            plano: resVenda.plano,
            consorcio: resVenda.consorcio
        });
        
        
        await this.apiListarConsorcios();
        this.togglemodalConsorcio();
        this.reviewModalConsorcio(resVenda.consorcio)
    }

    reviewModalConsorcio = async (e) => {
        let data = this.state.consorcios.filter(y => y.id == e)[0]
        
        this.setState({
            lettersInfoConsorcio: data
        })
    }
	

	render() {

		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Vendas Consórcio</p>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Vendedor
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Cliente
												<i className="fas fa-sort"></i>
											</div>
										</th>
                                        <th>
											<div className="d-flex align-center justify-between">
												Plano
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Grupo
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Cota
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Data
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.fid} onChange={a => {this.setState({ fid: a.target.value });}} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.fve} onChange={a => this.setState({ fve: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.fcl} onChange={a => this.setState({ fcl: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.fpl} onChange={a => this.setState({ fpl: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
                                            <i className="fas fa-search"></i>
												<input type="text" value={this.state.fgp} onChange={a => this.setState({ fgp: a.target.value })} />
											</div>
										</th>
                                        <th>
											<div className="d-flex align-center justify-between">
                                            <i className="fas fa-search"></i>
												<input type="text" value={this.state.fct} onChange={a => this.setState({ fct: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.fda} onChange={a => this.setState({ fda: a.target.value })} />

											</div>
										</th>
										<th>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.filtrar(this.state.dados).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map((venda, id) => (
											<tr key={venda.venda_id}>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.id}</td>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.usuario_nome}</td>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.pessoa_nome}</td>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.plano}</td>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.grupo}</td>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.cota}</td>
												<td className={venda.cancelado == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{moment(venda.admissaodata).format("DD/MM/YYYY")}</td>
												<td className="td-acoes">
													{venda.cancelado == 1 ? '' : <>
													{venda.pendencia != 1 ? this.habilitarEdicao(venda.venda_id) ? <i className="fas fa-dollar-sign" onClick={() => this.props.history.push('/editarMov/'+venda.id) }></i> : '' : ''}
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(venda.id)}></i>&nbsp;
													{/* <i className="fas fa-trash" onClick={() => this.modalDeletar(venda.id)}></i> */}
													{/* <i className="fas fa-pen" onClick={() => this.props.history.push("/editarvenda/" + venda.id)}></i>&nbsp; */}
													{!venda.item_modelo ? <i className="fas fa-pen" onClick={() => this.openModalAlterar(venda.id)}></i> : ''}
													{this.state.permitir ? <i className="fas fa-times" onClick={() => this.modalCancelar(venda.id)}></i> : ''}
													</>
													}
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
							<div className="form-footer">
							{this.filtrar(this.state.dados).length > 0 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                    }}>
                                        {<i class="fas fa-angle-left arrows"></i>}
                                    </div>
                                    {
                                        [...Array(Math.ceil((this.filtrar(this.state.dados)).length / 10))].map((pagina, index) => {
                                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                                (this.state.pagina === (Math.ceil((this.filtrar(this.state.dados)).length / 10)) && index + 1 > (Math.ceil((this.filtrar(this.state.dados)).length / 10)) - 3) ||
                                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                            ){
                                                return (
                                                    <div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
                                                    onClick={() => {
                                                        this.setState({pagina: index + 1})
                                                    }}>
                                                        {index + 1}
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null
                                            }
                                        })
                                    }
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina < (Math.ceil((this.filtrar(this.state.dados)).length / 10)) ? this.state.pagina + 1 : (Math.ceil((this.filtrar(this.state.dados)).length / 10))})
                                    }}>
                                        {<i class="fas fa-angle-right arrows"></i>}
                                    </div>
                                </div>}
							</div>
						</div>
						<Versionamento/>
					</div>
				</div>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>Venda</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>ID</p>
								<p>{this.state.modalDetalhesId}</p>
							</div>
							<div className="conteudo-info">
								<p>Empresa</p>
								<p>{this.state.modalDetalhesEmpresa}</p>
							</div>
							<div className="conteudo-info">
								<p>Cliente</p>
								<p>{this.state.modalDetalhesCliente}</p>
							</div>
							<div className="conteudo-info">
								<p>Vendedor</p>
								<p>{this.state.modalDetalhesUsuario}</p>
							</div>
							<div className="conteudo-info">
								<p>Data da Venda</p>
								<p>{this.state.modalDetalhesData}</p>
							</div>
                            <div className="conteudo-info">
								<p>Cota</p>
								<p>{this.state.modalDetalhesCota}</p>
							</div>
                            <div className="conteudo-info">
								<p>Grupo</p>
								<p>{this.state.modalDetalhesGrupo}</p>
							</div>
                            <div className="conteudo-info">
								<p>Observação</p>
								<p>{this.state.modalDetalhesDescricao}</p>
							</div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <h5>Consórcio</h5>
                            </div>
                        </div>

						<div className="box-conteudo">
                            
                            <div className="conteudo-info">
								<p>Plano</p>
								<p>{this.state.modalDetalhesPlano}</p>
							</div>
                            <div className="conteudo-info">
								<p>Empresa</p>
								<p>{this.state.modalDetalhesEmpresaC}</p>
							</div>
							<div className="conteudo-info">
								<p>Meses</p>
								<p>{this.state.modalDetalhesMeses} Meses</p>
							</div>
							<div className="conteudo-info">
								<p>Valor</p>
								<p>{this.state.modalDetalhesValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
							</div>
                            
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

                <Modal 
                    isOpen={this.state.openmodalConsorcio} 
                    toggle={this.togglemodalConsorcio} 
                    backdrop={true} 
                    keyboard={true} 
                    size="md">

                    <ModalHeader toggle={this.togglemodalConsorcio}>
                        Alterar Consórcio
                    </ModalHeader>
                    <ModalBody>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="consorcio" id="lblConsorcio">Consorcio *</label>
                            <div className="grupo-campo-input input-cad" id="consorcio">
                                <select name="" value={this.state.consorcio} onChange={e => {this.setState({ consorcio: e.target.value});  this.reviewModalConsorcio(e.target.value)}}>
                                    <option value="NULL">Selecione um consorcio</option>
                                    {
                                        this.state.consorcios.map(e => (
                                            <option value={e.id}>{e.plano}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            this.state.lettersInfoConsorcio ? (
                                <div className="infamus">
                                    <div className="gridinfoview">
                                        <span className="texto">
                                            <p>Plano</p>
                                            <p>{this.state.lettersInfoConsorcio.plano}</p>
                                        </span>
                                        <span className="texto">
                                            <p>Valor</p>
                                            <p>{parseFloat(this.state.lettersInfoConsorcio.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                        </span>
                                        <span className="texto">
                                            <p>Empresa</p>
                                            <p>{this.state.lettersInfoConsorcio.empresa}</p>
                                        </span>
                                        <span className="texto">
                                            <p>Meses</p>
                                            <p>{this.state.lettersInfoConsorcio.meses}</p>
                                        </span>
                                    </div>
                                </div>
                            ) : (null)
                        }
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="grupo" id="lblGrupo">Grupo *</label>
                            <div className="grupo-campo-input input-cad" id="grupo">
                                <input placeholder="Insira o grupo" type="text" value={this.state.grupo} onChange={(e) => this.setState({ grupo: e.target.value })} />
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="cota" id="lblCota">Cota *</label>
                            <div className="grupo-campo-input input-cad" id="cota">
                                <input placeholder="Insira a cota" type="text" value={this.state.cota} onChange={(e) => this.setState({ cota: e.target.value })} />
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="observacao" id="lblObservacao">Observação *</label>
                            <div className="grupo-campo-input input-cad" id="observacao">
                                <textarea value={this.state.observacao} onChange={(e) => this.setState({ observacao: e.target.value })}>

                                </textarea>
                            </div>
                        </div>
                    </ModalBody>
                    {
                        this.state.lettersInfoConsorcio ? (
                            <ModalFooter>
                                <Button className="btnora" onClick={this.alterarConsorcio}>Salvar</Button>
                            </ModalFooter>
                        ) : (null)
                    }
                </Modal>


				{/* MODAL DELETAR */}
				<Modal
					isOpen={this.state.modalDeletarOpen}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente deletar a venda #{this.state.modalDeletarId}?
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.apiDeletarVenda} color="danger">Deletar</Button>
						<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL AVISO */}
				<Modal
					isOpen={this.state.modalAvisoOpen}
					toggle={this.toggleModalAviso.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
					<ModalBody>
						{this.state.modalAvisoText}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL CANCELAR */}
				<Modal
					isOpen={this.state.modalCancela}
					toggle={this.toggleModalCancelar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalCancelar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente Cancelar o consórcio?
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-danger" onClick={this.cancelarVenda} color="danger">Cancelar Consórcio</button>
						{/* <i className="fas fa-times" onClick={() => this.cancelarVenda(this.state.vendaId)}></i> */}
					</ModalFooter>
				</Modal>

			</section>
		)

	}

}

export default VendasConsorcio;