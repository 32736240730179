import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from "moment"

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Notas extends React.Component {
	constructor(props) {
		super(props);

		this.pageSize = 1;

		this.state = {
			dados: [],

			produtoId: null,

			modalDetalhesOpen: false,
			modalAvisoOpen: false,
			modalDeletarOpen: false,

			modalDetalhesId: '',
			modalDetalhesEstoque: '',

			id: '',
			data: '',
			numero: '',
			fornecedor: '',
			quantidade: '',
			valor: '',

			pageLimit: 10,
			pageAtual: 0,

			tabelaSum: 0,
		};
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] produtosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/notas")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.match.params.produto !== this.props.match.params.produto) {
			this.apiListarNotas(this.props.match.params.produto);	
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarNotas(this.props.match.params.produto);
		}
	}

	apiListarNotas = async (produto) => {
		let res;
		if (produto) {
			res = await api_topmotos.get('/notas/produto/' + this.props.match.params.produto);
		} else {
			res = await api_topmotos.get('/notas/');
		}
		this.setState({ dados: res.data })
		this.pageSize = Math.ceil(res.data.length / this.state.pageLimit);
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	filtrar = (dados) => {
		let sum = 0;
		let dadosFilter = dados.filter((d) => {
			sum += d.valor
			return this.filtrarID(d) && this.filtrarData(d) && this.filtrarNumero(d) && this.filtrarFornecedor(d) && this.filtrarQuantidade(d) && this.filtrarValor(d)
		})

		this.pageSize = Math.ceil(dadosFilter.length / this.state.pageLimit);
		if (this.state.tabelaSum !== sum) {
			this.setState({tabelaSum: sum})
		}

		return dadosFilter
	}

	filtrarID = (d) => {
		return !this.state.id || (d.nota_id && d.nota_id.toString().toLowerCase().includes(this.state.id.toLowerCase()));
	}

	filtrarData = (d) => {
		return !this.state.data || (d.nota_admissaodata.split("T")[0] && d.nota_admissaodata.split("T")[0].toString().toLowerCase().includes(this.state.data.toLowerCase()));
	}

	filtrarNumero = (d) => {
		return !this.state.numero || (d.nota_numero && d.nota_numero.toString().toLowerCase().includes(this.state.numero.toLowerCase()));
	}

	filtrarFornecedor = (d) => {
		return !this.state.fornecedor || (d.fornecedor_nome && d.fornecedor_nome.toString().toLowerCase().includes(this.state.fornecedor.toLowerCase()));
	}

	filtrarQuantidade = (d) => {
		return !this.state.quantidade || (d.quantidade && d.quantidade.toString().toLowerCase().includes(this.state.quantidade.toLowerCase()));
	}

	filtrarValor = (d) => {
		return !this.state.valor || (d.valor && d.valor.toString().toLowerCase().includes(this.state.valor.toLowerCase()));
	}

	/**
	 * @description Organiza a paginação e ativa um indice
	 */
	putItem = (pageAtual, index) => {

		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	nextorprevious = (e, index) => {
		e.preventDefault();

		this.setState({ pageAtual: index })
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id) => {
		let res = await api_topmotos.get('/notas/id/' + id);
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: res.nota_id,
			modalDeletarNome: res.nota_nome,
		})
	}

	apiDeletarProduto = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/notas/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/protudos/update)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Nota deletada com sucesso!' });
	}

	render() {
		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Notas {this.props.match.params.produto ? '(Produto: '+this.props.match.params.produto+')': null}</p>
							<div className="form-add" onClick={a => this.props.history.push("/cadastrarnotas")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Data Admissão
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Número
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Fornecedor
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Quantidade {this.props.match.params.produto ? 'do Produto' : 'Itens'}
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Total {this.props.match.params.produto ? 'do Produto' : null}
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.id} onChange={a => this.setState({ id: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.data} onChange={a => this.setState({ data: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.numero} onChange={a => this.setState({ numero: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.fornecedor} onChange={a => this.setState({ fornecedor: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.quantidade} onChange={a => this.setState({ quantidade: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.valor} onChange={a => this.setState({ valor: a.target.value })} />
											</div>
										</th>
										<th>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.filtrar(this.state.dados).slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((nota, id) => (
											<tr key={nota.nota_id}>
												<td>{nota.nota_id}</td>
												<td>{moment(nota.nota_admissaodata).format("DD/MM/YYYY")}</td>
												<td>{nota.nota_numero}</td>
												<td>{nota.fornecedor_nome}</td>
												<td>{nota.quantidade}</td>
												<td>
													{parseFloat(nota.valor)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
												</td>
												<td className="td-acoes">
													<i className="fa fa-bars" onClick={() => this.props.history.push("/detalhesnotas/" + nota.nota_id)}></i>&nbsp;&nbsp;&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(nota.nota_id)}></i>
												</td>
											</tr>
										))
									}
								</tbody>
								<tr style={{ width: '100%' }}>
								</tr>
							</table>
							<div className="form-footer">
								{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
									</div>

									{
										[...Array(this.pageSize)].map((e, index) => (
											this.putItem(this.state.pageAtual, index)
										))
									}

									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
									</div>
								</div>}
							</div>
							<div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									Valor Total: {parseFloat(this.state.tabelaSum)
									.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
							</div>
						</div>
						<Versionamento />
					</div>
				</div>

				{/* MODAL DELETAR */}
				<Modal
					isOpen={this.state.modalDeletarOpen}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente deletar a Nota {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
					<ModalFooter>
						<Button onClick={this.apiDeletarProduto} color="danger">Deletar</Button>
						<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL AVISO */}
				<Modal
					isOpen={this.state.modalAvisoOpen}
					toggle={this.toggleModalAviso.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
					<ModalBody>
						{this.state.modalAvisoText}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
					</ModalFooter>
				</Modal>

			</section>
		)
	}
}

export default Notas;
