import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class DespesaMotoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            despesasId: '',
            item: '',
            valor: '',
            id:'',
            obs: '',
            despesas: [],
            despesasItens: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            item: ''
        };
    }

    async componentDidMount(){
        if(await this.apiListarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.listarDespesasItens();
            this.listarDespesas();
            this.listarItem();
        }
    }

    apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
        let despesa = res.data.filter(a => a.restricao_id == 3);

        return despesa.length == 0 ? true : false
    }

    listarDespesasItens = async () =>{
        let res = await api_topmotos.get('/despesasItens/item/'+ this.props.match.params.id);
        this.setState({ despesasItens: res.data });
    }

    listarDespesas = async () =>{
        let res = await api_topmotos.get('/despesas');
        this.setState({ despesas: res.data });
    }

    listarItem = async () =>{
        let res = await api_topmotos.get('/itens/id/' + this.props.match.params.id)
        res = res.data.item[0]
        let txt = res.item_modelo + " ( " + res.placa + " )"
        this.setState({ item: txt });
    }

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/despesasItens/id/" + id);
        
        var real = data[0].valor.toLocaleString('pt-br', {minimumFractionDigits: 2});

        this.setState({
            id: data[0].id,
            despesasId: data[0].despesa,
            valor: real,
            obs: data[0].observacao
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar, errorMessage: ''});
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar, errorMessage: ''});
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);

        await this.setState({modalAlterar: true})
    }

    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        var teste = real.split('')
        teste = 
        this.setState({valor: real})
    }

    apiCadastrarDespesa = async () => {
        var inputs = [
            [this.state.despesasId, 'descricao','iconDescricao','lblDescricao'],
            [this.state.valor, 'valor','iconValor','lblValor']
        ]

        if(this.state.despesasId && this.state.valor) {
            try {
                var real = this.state.valor.replace('.', '');
                real = real.replace(',', '.');

                await api_topmotos.post('/despesasItens/create', {
                    despesa: this.state.despesasId,
                    item: this.props.match.params.id,
                    valor: real,
                    obs: this.state.obs
                });

                this.toggleModalCadastrar()
                this.listarDespesasItens();
                this.limparState();
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarDespesa = async () => {
        var inputs = [
            [this.state.despesasId, 'descricao','iconDescricao','lblDescricao'],
            [this.state.valor, 'valor','iconValor','lblValor']
        ]

        if(this.state.despesasId && this.state.valor) {
            try {
                var real = this.state.valor.replace('.', '');
                real = real.replace(',', '.');
                
                await api_topmotos.put('/despesasItens/update/id/' + this.state.id, {
                    despesa: this.state.despesasId,
                    item: this.props.match.params.id,
                    valor: real,
                    obs: this.state.obs
                })

                this.toggleModalAlterar()
                this.limparState();
                this.listarDespesasItens()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
                this.log(this.state.error);
            }
            
        } else {

            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var check = document.getElementById("check" + id);

        try {
            await api_topmotos.post('/checklistD/ativar/id/' + id, {
                id: this.state.id,
                check: check.checked == true ? 0 : 1
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
            this.log(this.state.error);
        }
    }

    limparState = () => {
        this.setState({
            descricao: '',
            id: '',
            valor: '',
            obs: ''
        })
    }

    valorPadrao = (id) =>{
        var res = this.state.despesas.filter(a => a.id == id);
        var valor = res[0].valor_padrao.toLocaleString('pt-br', {minimumFractionDigits: 2});
        var real = valor.replace('R$', '')
        this.setState({valor: real})
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <div>
                                        <p>Despesa Moto</p>
                                        <p className="subtitle">{this.state.item}</p>
                                    </div>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => {this.setState({modalCadastrar: true}); this.limparState()}}>Adicionar Despesas<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-head mt-3"></div>
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Descrição
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Observação
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.despesasItens.map((des) => (
                                                <tr key={des.id}>
                                                    <td>{des.id}</td>
                                                    <td>{des.descricao}</td>
                                                    <td>{des.observacao}</td>
                                                    <td>{des.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-pen" onClick={() => {this.OpenModalAlteracao(des.id)}}></i>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div class="form-footer"><span></span></div>
                            </div>

                            {/* PERGUNTAS */}
                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Despesa</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="descricao" id="lblDescricao">Descrição</label>
                                                        <div className="grupo-campo-input input-cad" id="descricao">
                                                            <i className="fas fa-pen" id="iconDescricao"></i>
                                                            <select value={this.state.despesasId} onChange={e => {this.setState({despesasId: e.target.value}); this.valorPadrao(e.target.value)}}>
                                                                <option value="">Selecione a despesa</option>
                                                                {
                                                                    this.state.despesas.map((des) => (
                                                                        <>
                                                                            <option value={des.id}>{des.descricao}</option>
                                                                        </>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="valor" id="lblValor">Valor</label>
                                                        <div className="grupo-campo-input input-cad" id="valor">
                                                            <i className="fas fa-dollar-sign" id="iconValor"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Informe o valor"
                                                                value={this.state.valor}
                                                                onChange={event => this.mascaraReal(event.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad ">
                                                        <label htmlFor="obs" id="lblObs">Observação</label>
                                                        <textarea value={this.state.obs} onChange={a => this.setState({obs: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarDespesa}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Despesa</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                            <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="descricao" id="lblDescricao">Descricao</label>
                                                        <div className="grupo-campo-input input-cad" id="descricao">
                                                            <i className="fas fa-pen" id="iconDescricao"></i>
                                                            <select value={this.state.despesasId} onChange={e => {this.setState({despesasId: e.target.value}); this.valorPadrao(e.target.value)}}>
                                                                <option value="">Selecione a despesa</option>
                                                                {
                                                                    this.state.despesas.map((des) => (
                                                                        <>
                                                                            <option value={des.id}>{des.descricao}</option>
                                                                        </>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="valor" id="lblValor">Valor</label>
                                                        <div className="grupo-campo-input input-cad" id="valor">
                                                            <i className="fas fa-dollar-sign" id="iconValor"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Informe o valor"
                                                                value={this.state.valor}
                                                                onChange={event => this.mascaraReal(event.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad ">
                                                        <label htmlFor="obs" id="lblObs">Observação</label>
                                                        <textarea value={this.state.obs} onChange={a => this.setState({obs: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarDespesa}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default DespesaMotoPage