import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class TipoPagPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tipo: '',
            id:'',
            tipoPagamento: [],
            tipoPagamentoF: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            ativo:'',
            errorMessage: '',
            compra: false,
            venda: false,
            parcelamento: false,
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/tipoPagamento")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.listarPagamento();
        }
    }

    listarPagamento = async () =>{
        let res = await api_topmotos.get('/tipopag/todos');
        this.setState({ tipoPagamento: res.data, tipoPagamentoF: res.data });
    }

    listarId = async (id) =>{
        let dados = this.state.tipoPagamento.filter(a => a.id == id)
        
        this.setState({
            id: dados[0].id,
            tipo: dados[0].tipo_pagamento,
            compra: dados[0].compra,
            venda: dados[0].venda,
            parcelamento: dados[0].parcelamento
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);
        await this.setState({modalAlterar: true})
    }

    apiCadastrarChecklist = async () => {
        var inputs = [
            [this.state.tipo, 'tipo','iconTipo','lblTipo']
        ]

        if(this.state.tipo) {
            try {
                await api_topmotos.post('/tipopag/create', {
                    tipo: this.state.tipo,
                    compra: this.state.compra ? 1 : 0, 
                    venda: this.state.venda ? 1 : 0,
                    parcelamento: this.state.parcelamento ? 1 : 0
                });

                this.toggleModalCadastrar()
                this.listarPagamento()
                this.limparStates()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/tipoPag/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    limparStates = () =>{
        this.setState({
            compra: false,
            venda: false,
            parcelamento: false
        })
    }

    apiAlterarChecklist = async () => {
        var inputs = [
            [this.state.tipo, 'tipo','iconTipo','lblTipo']
        ]

        if(this.state.tipo) {
            try {
                await api_topmotos.post('/tipopag/update', {
                    id: this.state.id,
                    tipo: this.state.tipo,
                    compra: this.state.compra ? 1 : 0, 
                    venda: this.state.venda ? 1 : 0,
                    parcelamento: this.state.parcelamento ? 1 : 0
                })

                this.toggleModalAlterar()
                this.listarPagamento()
                this.limparStates()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var tipo = document.getElementById("tipo" + id);

        try {
            await api_topmotos.post('/tipopag/ativar/id/' + id, {
                id: this.state.id,
                tipo: tipo.checked == true ? 0 : 1
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/tipopag/create)" });
            this.log(this.state.error);
        }
    }

    changeTipo = (tipo) => { 
        let filtro = this.state.tipoPagamento
        if(tipo == 'V'){
            filtro = filtro.filter(a => a.venda == 1)
        } else if(tipo == 'C'){
            filtro = filtro.filter(a => a.compra == 1)
        }
        this.setState({tipoPagamentoF: filtro})
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Tipos de pagamento</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Tipo<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="relatorio-titulo">
                                <p>Tipo</p>
                                <div>
                                    <select onChange={event => this.changeTipo(event.target.value)}>
                                        <option value="">Todas</option>
                                        <option value="V">Venda</option>
                                        <option value="C">Compra</option>
                                    </select>
                               </div>
                            </div>

                            {this.state.tipoPagamentoF.map((tipo) => (
                                    <div className="item-opcao">
                                        <div className="head default">
                                            <div className="p-head">
                                                <p className="pr-2 pergunta-p">{tipo.id}.</p>
                                                <p className="pergunta-p">{tipo.tipo_pagamento}</p>
                                            </div>
                                            <div className="d-flex">
                                                <label className="switch mr-2">
                                                    <input type="checkbox" id={"tipo" + tipo.id} onClick={() => this.toggleAtivar(tipo.id)} defaultChecked={tipo.ativo == 0 ? true : false}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <i className='fas fa-pen icone' onClick={() => {this.OpenModalAlteracao(tipo.id)}}></i>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }

                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Tipo de Pagamento</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="tipo" id="lblTipo">Tipo</label>
                                                        <div className="grupo-campo-input input-cad" id="tipo">
                                                            <i className="fas fa-pen" id="iconTipo"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a tipo de pagamento"
                                                                onChange={event => this.setState({ tipo: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="" id="">Este Tipo de pagamento é para: </label>
                                                            <div className="check-itens">
                                                                <input type="checkbox" id="input-venda" name={"pagamento"} onChange={event => this.setState({venda: !this.state.venda})}/>
                                                                <label htmlFor={"input-venda"}>Venda</label>
                                                            </div>
                                                            <div className="check-itens">
                                                                <input type="checkbox" id="input-compra" name={"pagamento"} onChange={event => this.setState({compra: !this.state.compra})}/>
                                                                <label htmlFor={"input-compra"}>Compra</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="" id="">Outras opções: </label>
                                                            <div className="check-itens">
                                                                <input type="checkbox" id="input-parc" name={"parcelamento"} onChange={event => this.setState({parcelamento: !this.state.parcelamento})}/>
                                                                <label htmlFor={"input-parc"}>Pagamento em parcela</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrar}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarChecklist}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Pagamento</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="tipo" id="lblTipo">Tipo</label>
                                                        <div className="grupo-campo-input input-cad" id="tipo">
                                                            <i className="fas fa-pen" id="iconTipo"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva um tipo"
                                                                value={this.state.tipo}
                                                                onChange={event => this.setState({ tipo: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="">Este Tipo de pagamento é para: </label>
                                                        <div className="check-itens">
                                                            <input type="checkbox" id="input-venda" name={"pagamento"} checked={this.state.venda} onChange={event => this.setState({venda: !this.state.venda})}/>
                                                            <label htmlFor={"input-venda"}>Venda</label>
                                                        </div>
                                                        <div className="check-itens">
                                                            <input type="checkbox" id="input-compra" name={"pagamento"} checked={this.state.compra} onChange={event => this.setState({compra: !this.state.compra})}/>
                                                            <label htmlFor={"input-compra"}>Compra</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="">Outras opções: </label>
                                                        <div className="check-itens">
                                                            <input type="checkbox" id="input-parc" name={"parcelamento"} checked={this.state.parcelamento} onChange={event => this.setState({parcelamento: !this.state.parcelamento})}/>
                                                            <label htmlFor={"input-parc"}>Pagamento em parcela</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarChecklist}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default TipoPagPage