import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';

import {
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import moment from 'moment';

class NotaCadastroPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			indexperg: 0,
			titles: ['Selecionar Prestador de Serviço'],
			sum: 0,

			addPrestador: null,

			openmodalCadastrar: false,
			openmodal: false,

			produtos: [],
			options: [],

			modalRef: '',
			modalQuantidade: '',
			modalValorUnit: '',

			modalCadastrarItens: [],
			prestadores: [],
			tipos: [],

			modalCadastrarNome: '',
			modalCadastrarTipo: '',
			modalCadastrarDescricao: '',
			modalCadastrarRefInterna: '',
			modalCadastrarRefExterna: '',

			modalDetalhesOpen: false,
			modalDetalhesId: '',
			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesTipo: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefInterna: '',
			modalDetalhesRefExterna: '',

			prestador_nome: '',
			selectPrestador: '',
			selectPrestadorId: '',
			numeroNota: '',
			dataEmissao: '',
			selectFornecedor: '',

			lettersInfo: null,
			clientFocus: '',
		};
	}

	apiListarPrestadores = async () => {
		let res = await api_topmotos.get('/prestadores/');
		res = res.data.filter(r => {
			const list = r.checklist_veiculo ? r.checklist_veiculo.split(",") : null
			console.log(list)
			return list && list.includes(this.props.match.params.tipo);
		});
		this.setState({ prestadores: res });
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/oficina")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] externosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarPrestadores()
		}
	}

	apiCadastrarServico = async (id) => {
		let realCompra = null;
		if (this.state.prestacao) {
			realCompra = this.state.prestacao.replace('.', '');
			realCompra = realCompra.replace(',', '.');
			realCompra = parseFloat(realCompra);
		}

		let prestador_nome = this.state.prestadores.filter(p => {
			return p.prestador_id == id;
		})

		let dataVencimento = moment().add(1, 'months').set("date", prestador_nome[0].prestador_vencimentoPadrao).format("yyyy-MM-DD");

		try {
			await api_topmotos.post('/oficina/prestador/create', {
				item: this.props.match.params.id,
				prestador: id,
				dataSaida: this.state.dataEmissao,
				dataVolta: this.state.dataVolta,
				prestacao: realCompra,
				descricao: this.state.obs,
				dataEmissao: this.getTimestamp(),
				checklist: this.props.match.params.tipo,
			}).then(async res => {
				await api_topmotos.post('/financeiro/create', {
					descricao: res.data.insertId + ' - Oficina Serviço - ' +  (this.state.obs || 'Ordem de Serviço Externa') + ' - ' + prestador_nome[0].prestador_nome,
					dataVencimento: dataVencimento,
					valor: realCompra,
					tipoPagamento: null,
					status: 'AP',
					admissaodata: this.getTimestamp(),
					cat_id: null,
					conta_id: null,
					oficina_id: res.data.insertId,
					dataCompetencia: dataVencimento,
					banco: null,
					parcela_id: null,
					bancoFinanciado: null
				})
			})
			this.props.history.push('/oficina/externos/'+this.props.match.params.id+'/tipo/'+this.props.match.params.tipo);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/oficina/prestador/create)" });
			this.log(this.state.error);
		}
	}

	buttonAddPrestador = () => {
		this.setState({ addPrestador: (
			<>
				<label>&nbsp;&nbsp;</label>
				<Button onClick={a => this.props.history.push("/cadastrarprestador")} className="btnora">
					Adicionar Prestador
				</Button>
			</>
		)})
	}

	mascaraReal = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
						real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return real;
	}

	prestador = () => {
		this.setState({ options: [], sum: 0, modalCadastrarItens: [] })

		var inputId, inputIcone, inputNome;
		if (this.state.dataEmissao && this.state.selectPrestador){
			const prestador = this.state.prestadores.filter((f) => {
				return f.prestador_id == this.state.selectPrestador
			})

			if (prestador.length === 0) {
				inputId = document.getElementById("selectPrestador");
				inputIcone = document.getElementById("iconSelectPrestador");
				inputNome = document.getElementById("lblSelectPrestador");
				inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
				inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
				inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				this.buttonAddPrestador();
				this.aviso("Prestador Invalido");
			} else {
				this.setState({ prestador_nome: prestador[0].prestador_nome })

				this.apiCadastrarServico(prestador[0].prestador_id);

				this.aviso(null)
				this.setState({addPrestador: null})
			}
		} else {
			var inputs = [
				[this.state.dataEmissao, 'dataEmissao', 'iconDataEmissao', 'lblDataEmissao'],
				[this.state.selectPrestador, 'selectPrestador', 'iconSelectPrestador', 'lblSelectPrestador'],
			]

			for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
						inputId = document.getElementById(inputs[i][1]);
						inputIcone = document.getElementById(inputs[i][2]);
						inputNome = document.getElementById(inputs[i][3]);
						inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
						inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
						inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
						inputId = document.getElementById(inputs[i][1]);
						inputIcone = document.getElementById(inputs[i][2]);
						inputNome = document.getElementById(inputs[i][3]);
						inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
						inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
						inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}
		}
	}

	aviso = (msg) => {
		const aviso = document.getElementById("aviso")
		aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
		this.setState({ errorMessage: msg })
	}

	render() {
		return (
			<>
				<section>
					<div className="content-all d-flex">
						<NavMenu props={this.props} />
						<div className="content-session">
							<NavUsuario />
							<div className="form-head">
								<p><strong>Ordem de Serviço Externa</strong></p>
							</div>
							<div className="questionario">
								<p className="head">{this.state.titles[this.state.indexperg]}</p>
								<div className="perguntas no-min-height" id="primeira" style={{ display: "flex" }}>
									<div className="row">
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="selectPrestador" id="lblSelectPrestador">Prestador de Serviço *</label>
												<div className="grupo-campo-input input-cad" id="selectPrestador">
													<i className="fas fa-user" id="iconSelectPrestador"></i>
													<select
															value={this.state.selectPrestador}
															autoComplete="on"
															onChange={event => { this.setState({ selectPrestador: event.target.value }); }}
															required
														>
															<option value="NULL">Selecione o Prestador</option>
															{this.state.prestadores.map(p => (
																<option value={p.prestador_id}>{p.prestador_nome}</option>
															))}
													</select>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												{this.state.addPrestador}&nbsp;&nbsp;
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="dataEmissao" id="lblDataEmissao">Data Envio *</label>
												<div className="grupo-campo-input input-cad" id="dataEmissao">
													<i className="fa fa-calendar" id="iconDataEmissao"></i>
													<input
														value={this.state.dataEmissao}
														type="date"
														onChange={event => { this.setState({ dataEmissao: event.target.value }) }}
														required
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="dataVoltar" id="lblDataVoltar">Data Volta *</label>
												<div className="grupo-campo-input input-cad" id="dataVoltar">
													<i className="fa fa-calendar" id="iconDataVoltar"></i>
													<input
														value={this.state.dataVolta}
														type="date"
														onChange={event => { this.setState({ dataVolta: event.target.value }) }}
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="grupo-input input-style-cad">
												<label htmlFor="prestacao" id="lblPrestacao">Valor da Prestação *</label>
												<div className="grupo-campo-input input-cad" id="prestacao">
													<i className="fas fa-dollar-sign" id="iconPrestacao"></i>
													<input
														value={this.state.prestacao}
														type="text"
														onChange={event => { this.setState({ prestacao: this.mascaraReal(event.target.value.toString()) }) }}
														required
													/>
												</div>
											</div>
										</div>										
									</div>
									<textarea value={this.state.obs} onChange={a => this.setState({obs: a.target.value})} className="box-obs" placeholder="Escreva aqui uma descrição"></textarea>

									<div className="aviso-formulario">
										<p className="aviso" id="aviso">{this.state.errorMessage}</p>
									</div>
									<div className="btn-prox-volt">
										<button onClick={() => this.prestador()}>Finalizar<i class="fas fa-arrow-right"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Versionamento />
				</section>
			</>
		)
	}
}

export default NotaCadastroPage
