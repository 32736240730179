import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

import { api_viacep } from '../../services/api';


class EmpresaCadastroPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
			modalCadastrarOpen: false,
			razao_social: '',
			fantasia: '',
			cnpj: '',
			endereco: '',
			telefone: '',
			email: '',

			modalCadastrarEnderecoId: '',
            modalCadastrarCep: '',
            modalCadastrarEstado: '',
            modalCadastrarCidade: '',
            modalCadastrarBairro: '',
            modalCadastrarLogradouro: '',
            modalCadastrarNumero: '',
            modalCadastrarComplemento: '',

            aviso: '',
            cnpjValido: ''
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/empresas")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } 
    }

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}
	
	getCEP = async (event, modal) => {
        let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');

        this.setState({ modalCadastrarCep: event.target.value });

        if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstado: res['data'].uf,
                modalCadastrarCidade: res['data'].localidade,
                modalCadastrarBairro: res['data'].bairro,
                modalCadastrarLogradouro: res['data'].logradouro,
            })
        }
    }
    
    apiCadastrarEmpresa = async e => {
		e.preventDefault();

		const {
			razao_social,
			cnpj,
			fantasia,
			telefone,
			email,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            modalCadastrarComplemento,
            cnpjValido
        } = this.state;
        
        var inputs = [
            [razao_social, 'razao', 'iconRazao', 'lblRazao'],
			[email, 'email', 'iconEmail', 'lblEmail'],
			[fantasia, 'fantasia', 'iconFantasia', 'lblFantasia'],
			[telefone, 'telefone', 'iconTelefone', 'lblTelefone'],
            [cnpj, 'cnpj', 'iconCnpj', 'lblCnpj'],
            [cnpjValido, 'cnpj', 'iconCnpj', 'lblCnpj'],
            [modalCadastrarCep, 'cep', 'iconCep', 'lblCep'],
            [modalCadastrarEstado, 'estado', 'iconEstado', 'lblEstado'],
            [modalCadastrarCidade, 'cidade', 'iconCidade', 'lblCidade'],
            [modalCadastrarLogradouro, 'logradouro', 'iconLogradouro', 'lblLogradouro'],
            [modalCadastrarNumero, 'numero', 'iconNumero', 'lblNumero'],
            [modalCadastrarBairro, 'bairro', 'iconBairro', 'lblBairro']
        ]

		if (!razao_social ||
			!cnpj ||
			!fantasia ||
			!telefone ||
			!email) {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            // this.log(this.state.error);
            
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}

		} else {
            this.setState({ errorMessage: '' });
            
            for(var i = 0; i < inputs.length; i++){
				var inputId = document.getElementById(inputs[i][1]);
				var inputIcone = document.getElementById(inputs[i][2]);
				var inputNome = document.getElementById(inputs[i][3]);
				inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}

			try {
                await api_topmotos.post('/enderecos/create', {
                    endereco_cep: modalCadastrarCep,
                    endereco_estado: modalCadastrarEstado,
                    endereco_cidade: modalCadastrarCidade,
                    endereco_bairro: modalCadastrarBairro,
                    endereco_logradouro: modalCadastrarLogradouro,
                    endereco_numero: modalCadastrarNumero,
                    endereco_complemento: modalCadastrarComplemento,
                    endereco_admissaodata: this.getTimestamp(),

                }).then(res => {
                    this.setState({ modalCadastrarEnderecoId: res.data.returnId });
                });
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }

			try {
				await api_topmotos.post('/empresas/create', {
					empresa_cnpj: cnpj,
					empresa_razaosocial: razao_social,
					empresa_nomefantasia: fantasia,
					empresa_endereco: this.state.modalCadastrarEnderecoId,
					empresa_telefone: telefone,
					empresa_email: email,
					empresa_admissaodata: this.getTimestamp()
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/empresas/create)" });
				this.log(this.state.error);
			}

            this.props.history.push("/empresas")
        }
    }
    
    validarCNPJ = async (e) =>{
        let cnpj = e.target.value;
        var aviso = document.getElementById('aviso')

        if(cnpj.length == 18){
            cnpj = cnpj.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                await this.setState({aviso: 'CNPJ inválido', cnpjValido: false})
                aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;"
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    await this.setState({aviso: 'CNPJ inválido', cnpjValido: false})
                        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        await this.setState({aviso: 'CNPJ inválido', cnpjValido: false})
                        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                    } else{
                        await this.setState({aviso: 'CNPJ válido', cnpjValido: true});
                        aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
                    }
                }
            }
        } else{
            this.setState({aviso: '', cnpjValido: false})
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir empresa</strong></p>

                            </div>
                            <div className="content-formulario">
								<div className="row">
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="razao_social" id="lblRazao">Razão Social*</label>
											<div className="grupo-campo-input input-cad" id="razao">
												<i className="fas fa-building" id="iconRazao"></i>
												<input
													type="text"
													placeholder="&nbsp;Informe a razão social da empresa"
													autoComplete="on"
													onChange={event => this.setState({ razao_social: event.target.value })}
													required
												/>
											</div>
										</div>
                                    </div>
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="cnpj" id="lblCnpj">CNPJ*</label>
											<div className="grupo-campo-input input-cad" id="cnpj">
												<i className="fas fa-file" id="iconCnpj"></i>
												<InputMask
													mask="99.999.999-9999/99"
													maskChar=""
													alwaysShowMask="true"
													placeholder="&nbsp;Informe o CNPJ da empresa"
													autoComplete="on"
													onChange={event => {this.setState({ cnpj: event.target.value }); this.validarCNPJ(event)}}
													required
												/>
											</div>
                                            <p className="valorFipe pt-2" id="aviso">{this.state.aviso}</p>
                                        </div>
                                    </div>
                                </div>

								<div className="row">
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="fantasia" id="lblFantasia">Fantasia*</label>
											<div className="grupo-campo-input input-cad" id="fantasia">
												<i className="fas fa-building" id="iconFantasia"></i>
												<input
													type="text"
													placeholder="&nbsp;Informe a fantasia da empresa"
													autoComplete="on"
													onChange={event => this.setState({ fantasia: event.target.value })}
													required
												/>
											</div>
										</div>
                                    </div>
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="telefone" id="lblTelefone">Telefone*</label>
											<div className="grupo-campo-input input-cad" id="telefone">
												<i className="fas fa-phone" id="iconTelefone"></i>
												<InputMask
													mask="(99) 9999-9999"
													maskChar="_"
													alwaysShowMask="true"
													type="tel"
													placeholder="&nbsp;Informe o Telefone do usuário"
													autoComplete="on"
													onChange={event => this.setState({ telefone: event.target.value })}
													required
												/>
											</div>
										</div>
                                    </div>
                                </div>
                                
								<div className="row">
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="email" id="lblEmail">E-mail*</label>
											<div className="grupo-campo-input input-cad" id="email">
												<i className="far fa-envelope" id="iconEmail"></i>
												<input
													type="email"
													placeholder="&nbsp;Informe o e-mail da empresa"
													autoComplete="on"
													onChange={event => this.setState({ email: event.target.value })}
													required
												/>
											</div>
										</div>
									</div>
								</div>
                                
								<p className="subtitle-form">Informações de Endereço</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cep" id="lblCep">CEP *</label>
                                            <div className="grupo-campo-input input-cad" id="cep">
                                                <i className="fas fa-envelope" id="iconCep"></i>
                                                <InputMask
                                                    mask="99999-999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                    autoComplete="on"
                                                    onChange={event => this.getCEP(event, "cadastrar")}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>       
                                        
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estado" id="lblEstado">Estado *</label>
                                            <div className="grupo-campo-input input-cad" id="estado">
                                                <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                <select
                                                    name="estado"
                                                    value={this.state.modalCadastrarEstado}
                                                    placeholder="&nbsp;Selecione o Estado de residência"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarEstado: event.target.value })}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o Estado de residência</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cidade" id="lblCidade">Cidade *</label>
                                            <div className="grupo-campo-input input-cad" id="cidade">
                                                <i className="fas fa-city" id="iconCidade"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarCidade}
                                                    placeholder="&nbsp;Informe a Cidade de residência"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarCidade: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>    
                                </div>       

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="logradouro" id="lblLogradouro">Logradouro *</label>
                                            <div className="grupo-campo-input input-cad" id="logradouro">
                                                <i className="fas fa-road" id="iconLogradouro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarLogradouro}
                                                    placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarLogradouro: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="numero" id="lblNumero">Número *</label>
                                            <div className="grupo-campo-input input-cad" id="numero">
                                                <i className="fas fa-home" id="iconNumero"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Número de Residência"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarNumero: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="bairro" id="lblBairro">Bairro *</label>
                                            <div className="grupo-campo-input input-cad" id="bairro">
                                                <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarBairro}
                                                    placeholder="&nbsp;Informe o Bairro"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarBairro: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="complemento">Complemento</label>
                                            <div className="grupo-campo-input input-cad" id="complemento">
                                                <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarComplemento}
                                                    placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarComplemento: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                   
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarEmpresa} className="cadbtn">Cadastrar</Button>

                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default EmpresaCadastroPage