import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';


import {
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Thumbnail } from 'react-bootstrap';
import { parseTwoDigitYear } from 'moment';

class NotaCadastroPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			item: null,
			indexperg: 0,
			sum: 0,

			openmodalCadastrar: false,
			openmodal: false,

			produtos: [],
			options: [],

			modalRef: '',
			modalQuantidade: '',
			modalValorUnit: '',

			modalCadastrarItens: [],
			fornecedores: [],
			tipos: [],

			modalCadastrarNome: '',
			modalCadastrarTipo: '',
			modalCadastrarDescricao: '',
			modalCadastrarRefInterna: '',
			modalCadastrarRefExterna: '',
			modalCadastrarValor: '',

			modalDetalhesOpen: false,
			modalDetalhesId: '',
			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesTipo: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefInterna: '',
			modalDetalhesRefExterna: '',

			lettersInfo: null,
			clientFocus: '',
			descricao: '',
			tipo: '',

			produtoSE: '',
		};
	}

	apiListarTipos = async () => {
		let res = await api_topmotos.get('/produtos/tipos/');
		this.setState({ tipos: res.data });
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/oficina")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] NotasUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarProdutos()
			this.apiGetItem()
			this.apiListarTipos()
		}
	}

	apiGetItem = async () => {
		let res = await api_topmotos.get('/itens/id/' + this.props.match.params.id);
		this.setState({ item: res.data.item[0] });
	}

	apiListarProdutos = async () => {
		let res = await api_topmotos.get('/produtos');
		this.setState({ produtos: res.data });
	}

	getSum = () => {
		let sum = 0;
		this.state.options.forEach((op) => {
			sum += op.valorTotal;
		})
		return sum;
	}

	apiCadastrarProduto = async (e) => {
		const {
			modalCadastrarNome,
			modalCadastrarTipo,
			modalCadastrarDescricao,
			modalCadastrarRefInterna,
			modalCadastrarRefExterna,
			modalCadastrarValorVenda,
			modalCadastrarValorCompra
		} = this.state;

		if (!modalCadastrarNome ||
			!modalCadastrarTipo ||
			!modalCadastrarDescricao ||
			!modalCadastrarRefExterna ||
			!modalCadastrarRefInterna ||
			!modalCadastrarValorVenda ||
			!modalCadastrarValorCompra) {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
			this.log(this.state.error);

		} else {
			this.setState({ errorMessage: '' });

			try {
				await api_topmotos.post('/produtos/create', {
					nome: modalCadastrarNome,
					descricao: modalCadastrarDescricao,
					tipo: modalCadastrarTipo,
					fornecedor: this.state.selectFornecedorId,
					refInterna: modalCadastrarRefInterna,
					refExterna: modalCadastrarRefExterna,
					valor_venda: modalCadastrarValorVenda,
					valor_compra: modalCadastrarValorCompra,
				})

			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/produtos/create)" });
				this.log(this.state.error);
			}

			await this.apiListarProdutos()

			this.setState({ 
				openmodalCadastrar: false,
			})

			this.editarItem(modalCadastrarRefInterna, this.state.modalCadastrarIndex)
		}
	}

	togglemodalCadastrar = () => this.setState({ openmodalCadastrar: !this.state.openmodalCadastrar, clientFocus: true })
	togglemodalDetalhes = () => this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen })

	togglemodal = () => this.setState({ openmodal: !this.state.openmodal })

	additem = () => {
		this.apiListarProdutos();
		this.togglemodal();
	}

	removeItem = async (index) => {
		let newOptions = this.state.options;
		newOptions.splice(index, 1);

		this.setState({options: newOptions });
	}

	apiCadastrarOficina = async () => {
		let verif = false;
		this.state.options.forEach((op, index) => {
			let tr = document.getElementById(index);
			if (!op.produto_id || !op.quantidade) {
				verif = true;
				tr.style.cssText = "background: rgba(255, 115, 21, 0.2); transition: 0.2s;";
			} else {
				tr.style.cssText = null;
			}
		});

		var inputId = document.getElementById('dataF');
		var inputIcone = document.getElementById('iconDataF');
		if (!this.state.data_saida) {
			inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
			inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
		} else {
			inputId.style.cssText = null;
			inputIcone.style.cssText = null;
		}

		if (verif || !this.state.data_saida) return;

		try {
			await api_topmotos.post('/oficina/create', {
				tipo: this.state.tipo,
				dataSaida: this.state.data_saida,
				descricao: this.state.descricao,
				item: this.props.match.params.id,
				produtos: this.state.options,
				checklist: this.props.match.params.tipo
			}).then(res => {
				window.open('/pdfOficina/'+this.props.match.params.id+'/'+this.props.match.params.tipo+'/10?oficina='+res.data.returnId, '_blank')
			})
			
			this.props.history.push('/oficina/item/' + this.props.match.params.id + '/tipo/' + this.props.match.params.tipo);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/oficina/create)" });
			this.log(this.state.error);
		}
	}

	aviso = (msg) => {
		const aviso = document.getElementById("aviso")
		aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
		this.setState({ errorMessage: msg })
	}

	modalDetalhes = async (idProduto) => {
		var produto = this.state.produtos.filter((p) => {
			return p.produto_id === idProduto;
		});
		if (produto.length > 0) {
			produto = produto[0];

			this.setState({
				modalDetalhesOpen: true,
				modalDetalhesId: produto.produto_id,
				modalDetalhesNome: produto.produto_nome,
				modalDetalhesDescricao: produto.produto_descricao,
				modalDetalhesTipo: produto.tipo_nome,
				modalDetalhesFornecedor: produto.fornecedor_nome,
				modalDetalhesRefInterna: produto.refInterna,
				modalDetalhesRefExterna: produto.refExterna,
				modalDetalhesValor: produto.valor,
			});
		}
	}

	modalQuantidadeEditar = (estoque, quantidade, index) => {
		let newOptions = this.state.options;

		newOptions[index].quantidade = quantidade;

		let valorUnit = this.state.options[index].valorUnit || 0;

		newOptions[index].valorTotal = quantidade * valorUnit;
		this.setState({ options: newOptions, sum: this.getSum() })
	}

	trocaTipo = (tipo) => {
		this.state.options.map(o => {
			let produto = this.state.produtos.filter((p) => p.produto_id == o.produto_id);

			let valorUnit = null;
			if (tipo == "venda") {
				valorUnit = produto[0].valor_venda
			} else if (tipo == "manutenção") {
				valorUnit = produto[0].valor_compra
			}

			o.valorUnit = valorUnit
			return o
		})
	}

	editarItem = (refInterna, index) => {
		let newOptions = this.state.options;
		if (refInterna !== 'null') {
			let produto = this.state.produtos.filter((p) => p.refInterna === refInterna);
			if (produto.length > 0) {
				produto = produto[0];

				let valorUnit = null;
				if (this.state.tipo == "venda") {
					valorUnit = produto.valor_venda
				} else if (this.state.tipo == "manutenção") {
					valorUnit = produto.valor_compra
				}

				newOptions.push({
					estoque: produto.produto_quantidade,
					produto_id: produto.produto_id,
					refInterna: produto.refInterna,
					nome: produto.produto_nome,
					descricao: produto.produto_descricao,
					unidade: produto.tipo_nome,
					valorUnit: valorUnit,
				})
				this.setState({ 
					options: newOptions,
					produtoSE: '',
				})
			} else {
				newOptions.push({
					refInterna: refInterna
				});
				this.setState({ 
					options: newOptions,
				})
			}
		}
	}

	createProduto = (index) => {
		if (this.state.clientFocus) return;
		if (this.state.options[index].produto_id) return;

		this.setState({
			modalCadastrarIndex: index,
			modalCadastrarNome: '',
			modalCadastrarTipo: '',
			modalCadastrarDescricao: '',
			modalCadastrarRefInterna: this.state.options[index].refInterna,
			modalCadastrarRefExterna: '',
		})

		this.togglemodalCadastrar();
	}

	mascaraReal = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
						real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return real;
	}

	render() {
		return (
			<>
				<section>
					<div className="content-all d-flex">
						<NavMenu props={this.props} />
						<div className="content-session">
							<NavUsuario />
							<div className="form-head">
								<p><strong>Inserir em Ordem de Serviço Interna (Item: {this.state.item ? this.state.item.item_modelo + ' | ' + this.state.item.placa : '...'})</strong></p>
							</div>
							<div className="content-formulario">
								<p className="subtitle-form">Adicionar Produtos</p>
										<div className="row">
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<Button onClick={() => this.additem()} className="btnora">Adicionar</Button>&nbsp;&nbsp;
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<label htmlFor="tipoS">Tipo do Serviço</label>
													<div className="grupo-campo-input input-cad" id="tipoS">
                            <i className="far fa-calendar-alt" id="iconTipoS"></i>
														<select
															value={this.state.tipo}
															autoComplete="on"
															onChange={event => { this.setState({ tipo: event.target.value }); this.trocaTipo(event.target.value) }}
															required
														>
															<option value="NULL">Selecione o Tipo</option>
															<option value='venda'>Venda</option>
															<option value='manutenção'>Manutenção</option>
															<option value='garantia'>Garantia</option>
													</select>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="grupo-input input-style-cad">
													<label htmlFor="dataSaida">Data Saida</label>
													<div className="grupo-campo-input input-cad" id="dataF">
                            <i className="far fa-calendar-alt" id="iconDataF"></i>
														<input type="date"
															id="dataSaida"
															value={this.state.data_saida}
															onChange={(e) => {this.setState({data_saida: e.target.value})}}
															placeholder="Data de Entrada"
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="table">
												<table>
													<thead>
														<tr>
															<th>
																<div className="d-flex align-center justify-between">
																	Referencia Interna
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Nome
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Unidade
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Quantidade
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Valor
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Descrição
																	<i className="fas fa-sort"></i>
																</div>
															</th>
															<th>
																<div className="d-flex align-center justify-between">
																	Ações
																</div>
															</th>
														</tr>		
														{
															this.state.options.map((produto, index) => (
																<tr key={produto.produto_ref} id={index}>
																	<th>
																		<div className="grupo-input input-style-cad" id="refInterna">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.refInterna}
																					placeholder="Referencia Interna"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="nome">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.nome}
																					placeholder="Nome"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="unidade">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.unidade}
																					placeholder="Tipo Unidade"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="quantidade">
																			<div className="d-flex align-center justify-between">
																				<input type="number"
																					value={produto.quantidade}
																					onFocus={a => this.createProduto(index)}
																					onChange={(e) => {this.modalQuantidadeEditar(produto.estoque, e.target.value, index)}}
																					placeholder="Quantidade"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="valorUnit">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.valorUnit ? parseFloat(produto.valorUnit)
																						.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}													
																					placeholder="Valor"
																				/>
																			</div>
																		</div>
																	</th>
																	<th>
																		<div className="grupo-input input-style-cad" id="descricao">
																			<div className="d-flex align-center justify-between">
																				<input type="text"
																					disabled={true}
																					value={produto.descricao}													
																					placeholder="Descrição"
																				/>
																			</div>
																		</div>
																	</th>
																	<td className="td-acoes">										
																		<div className="d-flex align-center justify-between">
																			<i className="fas fa-eye" onClick={() => this.modalDetalhes(produto.produto_id)}></i>&nbsp;
																			<i className="fas fa-trash" onClick={() => this.removeItem(index)}></i>
																		</div>
																	</td>
																</tr>
															))
														}											
													</thead>
													<tbody>
													</tbody>
												</table>
												<div className="form-footer">
													{ this.state.tipo != "garantia" ? <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														Valor Total: {parseFloat(this.state.sum)
															.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
													</div> : '' }
												</div>
											</div>
											<textarea value={this.state.descricao} onChange={a => this.setState({descricao: a.target.value})} className="box-obs" placeholder="Escreva aqui uma descrição"></textarea>
										</div>
									<div className="aviso-formulario aviso-formulario-err ">
										<p className="aviso text-right">{this.state.errorMessage}</p>
									</div>
									<Button onClick={() => this.apiCadastrarOficina()} className="cadbtn">Finalizar</Button>
								</div>
							</div>
						</div>
					<Versionamento />
				</section>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.togglemodalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.togglemodalDetalhes.bind(this)}>{this.state.modalDetalhesNome} (ID: {this.state.modalDetalhesId})</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Nome</p>
								<p>{this.state.modalDetalhesNome}</p>
							</div>
							<div className="conteudo-info">
								<p>Descrição</p>
								<p>{this.state.modalDetalhesDescricao}</p>
							</div>
							<div className="conteudo-info">
								<p>Fornecedor</p>
								<p>{this.state.modalDetalhesFornecedor}</p>
							</div>
							<div className="conteudo-info">
								<p>Tipo</p>
								<p>{this.state.modalDetalhesTipo}</p>
							</div>
							<div className="conteudo-info">
								<p>Referencia Externa</p>
								<p>{this.state.modalDetalhesRefExterna}</p>
							</div>
							<div className="conteudo-info">
								<p>Referencia Interna</p>
								<p>{this.state.modalDetalhesRefInterna}</p>
							</div>
							<div className="conteudo-info">
								<p>Valor de Venda</p>
								<p>{parseFloat(this.state.modalDetalhesValor)
										.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.togglemodalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* Escolha Produto */}
				<Modal isOpen={this.state.openmodal} toggle={this.togglemodal} backdrop={true} keyboard={true} size="md">
					<ModalHeader toggle={this.togglemodal}>
							Selecionar Produto
					</ModalHeader>
					<ModalBody>
							<div className="grupo-input input-style-cad">
									<label htmlFor="cliente">Produto </label>
									<div className="grupo-campo-input input-cad" id="cliente">
											<i className="fas fa-user-tag" id="iconCliente"></i>
											<input type="text"
													value={this.state.produtoSE}
													onChange={a => this.setState({ produtoSE: a.target.value })}
													onFocus={a => this.setState({ clientFocus: true })}
													onBlur={a => this.setState({ clientFocus: false })}
													placeholder="Insira o Produto"
											/>
									</div>
									<ul className={this.state.clientFocus ? "listC activate" : "listC"}>
										{this.state.produtos.filter(y => { 
											let inUse = this.state.options.filter(o => o.refExterna === y.refExterna);
											return (y.refExterna.includes(this.state.produtoSE) || y.refInterna.includes(this.state.produtoSE) || y.produto_descricao.toLowerCase().includes(this.state.produtoSE.toLowerCase()) || y.produto_nome.toLowerCase().includes(this.state.produtoSE.toLowerCase())) && inUse.length === 0
										}).map(e => (
											<li onClick={a => {this.setState({ produtoSE: e.refInterna });}}>
													{e.produto_nome + ' - ' + e.produto_descricao}
													<br />
													{'(Interna: ' + e.refInterna + ', Externa: ' + e.refExterna + ' )'}
											</li>
										))}
									</ul>
							</div>
					</ModalBody>
					{
							this.state.produtoSE ? (
									<ModalFooter>
											<Button className="btnora" onClick={(e) => this.editarItem(this.state.produtoSE)}>Adicionar</Button>
									</ModalFooter>
							) : (null)
					}
				</Modal>

				{/* Cadastrar Produto */}
				<Modal isOpen={this.state.openmodalCadastrar} toggle={this.togglemodalCadastrar} backdrop={true} keyboard={true} size="lg">
					<ModalHeader toggle={this.togglemodalCadastrar}>
						Cadastrar Produtos
          </ModalHeader>
					<div className="content-formulario">
						<div>
							<div className="row">
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="nome">Nome *</label>
										<div className="grupo-campo-input input-cad" id="nome">
											<i className="fas fa-user" id="iconNome"></i>
											<input
												type="text"
												placeholder="&nbsp;Informe o Nome do Produto"
												autoComplete="on"
												value={this.state.modalCadastrarNome}
												onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
												required
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="descricao">Descrição</label>
										<div className="grupo-campo-input input-cad" id="descricao">
											<i className="fa fa-pen" id="iconDescricao"></i>
											<input
												type="text"
												placeholder="&nbsp;Informe a Descrição do Produto"
												autoComplete="on"
												value={this.state.modalCadastrarDescricao}
												onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
												required
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="refExterna">Referencia Externa *</label>
										<div className="grupo-campo-input input-cad" id="refExterna">
											<i className="fas fa-asterisk" id="iconRefExterna"></i>
											<input type="text" value={this.state.modalCadastrarRefExterna} onChange={event => this.setState({ modalCadastrarRefExterna: event.target.value })} />
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="refInterna">Referencia Interna *</label>
										<div className="grupo-campo-input input-cad disabled" id="refInterna">
											<i className="fas fa-asterisk" id="iconRefInterna"></i>
											<input type="text" value={this.state.modalCadastrarRefInterna} />
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="fornecedor">Fornecedor  *</label>
										<div className="grupo-campo-input input-cad" id="fornecedor">
											<i className="fas fa-warehouse" id="iconFornecedor"></i>
											<input
												disabled={true}
												name="fornecedor"
												placeholder="&nbsp;Selecione o Fornecedor"
												autoComplete="on"
												required
												value={this.state.fornecedor_nome}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="grupo-input input-style-cad">
										<label htmlFor="tipo">Unidade *</label>
										<div className="grupo-campo-input input-cad" id="tipo">
											<i className="fas fa-balance-scale" id="iconTipo"></i>
											<select
												name="tipo"
												placeholder="&nbsp;Selecione a Unidade de Medida"
												autoComplete="on"
												required
												value={this.state.modalCadastrarTipo}
												onChange={a => this.setState({ modalCadastrarTipo: a.target.value })}
											>
												<option value="NULL">Selecione a Unidade de Medida</option>
												{
													this.state.tipos.map((e, id) => (
														<option key={e.tipo_id} value={e.tipo_id}>{e.tipo_nome}</option>
													))
												}
											</select>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
                <div className="col-md-6">
                  <div className="grupo-input input-style-cad">
                    <label htmlFor="valor">Valor de Venda</label>
                    <div className="grupo-campo-input input-cad" id="valor">
                      <i className="fas fa-balance-scale" id="iconTipo"></i>
                      <input type="text" value={this.state.modalCadastrarValorVenda} onChange={a => {this.setState({ modalCadastrarValorVenda: this.mascaraReal(a.target.value)})}}/>
                    </div>
                  </div>
                </div>
								<div className="col-md-6">
                  <div className="grupo-input input-style-cad">
                    <label htmlFor="valor">Valor de Compra</label>
                    <div className="grupo-campo-input input-cad" id="valor">
                      <i className="fas fa-balance-scale" id="iconTipo"></i>
                      <input type="text" value={this.state.modalCadastrarValorCompra} onChange={a => {this.setState({ modalCadastrarValorCompra: this.mascaraReal(a.target.value)})}}/>
                    </div>
                  </div>
                </div>
              </div>
							<Button onClick={(e) => this.apiCadastrarProduto(e)} className="cadbtn mt-1">Cadastrar Produto</Button>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

export default NotaCadastroPage
