import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';


class CadastrarLeadsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            email: '',
            telefone: '',
            itens: [],
            marcas: [],
            cores: [],
            errorMessage: '',
            modelos: [],
            
            marcaSE: "NULL",
            modeleSE: '',
            options: [],
            conhecimentoLoja: '',
            data_retorno: '',
            retorno: ''
        };
    }

    componentDidMount() {
        this.apiListarMarcas();
        this.apiListarModelos();

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    verificarCampo = () =>{
        const {
            nome,
            telefone,
            email
        } = this.state;

        var inputs = [
            [nome, 'nome', 'iconNome', 'lblNome'],
			[email, 'email', 'iconEmail', 'lblEmail'],
			[telefone, 'telefone', 'iconTelefone', 'lblTelefone']
        ]

        for(var i = 0; i < inputs.length; i++){
            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                
            } else{
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }
        }	
    }

    apiCadastrarLeads = async () => {

        this.verificarCampo();

        if (this.state.telefone && this.state.nome && this.state.email && this.state.itens.length >= 1) {
            await api_topmotos.post('/leads/create', {
                nome: this.state.nome,
                telefone: this.state.telefone,
                data: this.getTimestamp(),
                email: this.state.email,
                itens: this.state.itens,
                loja: this.state.conhecimentoLoja,
                retorno: this.state.retorno,
                data_retorno: this.state.data_retorno
            });

            this.props.history.push("/leads")
        }
        else {
            this.setState({ errorMessage: "Preencha todos os campos" });
        }
    }

    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelos: res.data });
    }

    handleItens = (valores, modulo) => {
        console.log(valores);
        this.state.itens = valores.map((item) => (
            item.value
        ))
    }

    removeitem = async (index) => {
        let s = this.state.options;
        let i = this.state.itens;

        s.splice(index, 1);
        i.splice(index, 1);

        this.setState({options: s, itens: i})
    }

    retorno = (e) =>{
        this.setState({retorno: e})

        if(e == "Sim"){
            document.getElementById('data_retorno').style.display = "block"
        } else{
            document.getElementById('data_retorno').style.display = "none";
            this.setState({data_retorno: 'dd-mm-yyy'})
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <strong>
                                    <p>INSERIR LEADS</p>
                                </strong>

                            </div>
                            <div className="content-formulario">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="iname" id="lblNome">Nome*</label>
                                            <div className="grupo-campo-input input-cad" id="nome">
                                                <i className="fas fa-sign" id="iconNome"></i>
                                                <input placeholder="Insira o nome" type="text" onChange={(e) => this.setState({ nome: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="iconEmail" id="lblEmail">Email*</label>
                                            <div className="grupo-campo-input input-cad" id="email">
                                                <i className="fas fa-envelope" id="iconEmail"></i>
                                                <input placeholder="Insira o email" type="text" onChange={(e) => this.setState({ email: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="itel" id="lblTelefone">Telefone*</label>
                                            <div className="grupo-campo-input input-cad" id="telefone">
                                                <i className="fas fa-phone" id="iconTelefone"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="Insira o telefone"
                                                    autoComplete="on"
                                                    onChange={(e) => this.setState({ telefone: e.target.value })}
                                                    required
                                                />
                                                {/* <input placeholder="Insira o telefone" type="text" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="loja" id="lblLoja">Como conheceu a loja?</label>
                                            <div className="grupo-campo-input input-cad" id="loja">
                                                <i class="fas fa-store" id="iconLoja"></i>
                                                <select
                                                    name="nivel"
                                                    placeholder="&nbsp;Selecione o Tipo do item"
                                                    autoComplete="on"
                                                    onChange={a => this.setState({conhecimentoLoja: a.target.value})}
                                                    required
                                                >
                                                    <option value="NULL">Selecione como soube</option>
                                                    <option value="OLX">OLX</option>
                                                    <option value="Facebook">Facebook</option>
                                                    <option value="Site">Site</option>
                                                    <option value="Loja">Loja</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="retorno" id="lblRetorno">O lead marcou retorno?</label>
                                            <div className="grupo-campo-input input-cad" id="retorno">
                                            <i class="fas fa-undo-alt" id="iconRetorno"></i>
                                                <select
                                                    name="nivel"
                                                    autoComplete="on"
                                                    onChange={event => this.retorno(event.target.value)}
                                                    required
                                                >
                                                    <option value="NULL">Selecione uma opção</option>
                                                    <option value="Sim">Sim</option>
                                                    <option value="Não">Não</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="data_retorno">
                                            <label htmlFor="data">Data de Retorno</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input 
                                                    value={this.state.data_retorno} 
                                                    type="date" 
                                                    onChange={(e) => this.setState({ data_retorno: e.target.value })} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="imark">Marca</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                            <i class="fas fa-motorcycle"></i>
                                                <select name="" id="imark" onChange={e => this.setState({ marcaSE: e.target.value })}>
                                                    <option value="NULL">Selecione uma marca</option>
                                                    {
                                                        this.state.marcas.map(e => (
                                                            <option value={e.id}>{e.marca}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="imol">Modelo</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i class="fas fa-motorcycle"></i>
                                                <input 
                                                    placeholder="Insira o modelo" 
                                                    value={this.state.modeleSE} 
                                                    onChange={a => this.setState({modeleSE: a.target.value})} 
                                                    disabled={this.state.marcaSE == "NULL"} 
                                                    type="text" 
                                                    onFocus={a => this.setState({ clientFocus: true })} 
                                                    onBlur={a => this.setState({ clientFocus: false })} />
                                            </div>
                                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                {
                                                    this.state.marcaSE != "NULL" ?
                                                        (this.state.modelos.filter(y => !this.state.itens.includes(y.id) && y.marca == this.state.marcaSE && y.modelo.toLowerCase().includes(this.state.modeleSE.toLowerCase())).slice(0, 10).map(e => (
                                                            <li onClick={a => this.setState({options: this.state.options.concat(e), itens: this.state.itens.concat(e.id) })}>{e.modelo}</li>
                                                        )))
                                                        : (null)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>                       

                                <div className="itensmodsall">
                                    {
                                        this.state.options.map((e, index) => (
                                            <div className="itemMods">
                                                {e.marcaName} - {e.modelo}
                                                <i className="fas fa-times" onClick={a => this.removeitem(index)}></i>
                                            </div>
                                        ))
                                    }
                                </div>

                                <br /><br />
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarLeads} className="cadbtn">Inserir</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default CadastrarLeadsPage