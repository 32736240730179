import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';
import moment from 'moment';

class PrestadorEditarPage extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			dados: [],
			prestadores: [],

			modalCadastrarOpen: false,
			modalCadastrarNome: '',
			modalCadastrarTelefone: '',
			modalCadastrarEmail: '',
			modalCadastrarCnpj: '',
			modalCadastrarEnderecoId: '',
			modalCadastrarCep: '',
			modalCadastrarEstado: '',
			modalCadastrarCidade: '',
			modalCadastrarBairro: '',
			modalCadastrarLogradouro: '',
			modalCadastrarNumero: '',
			modalCadastrarComplemento: '',
        };
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] externosUPDATE/READ > " + msg);
    }

    verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/oficina")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

    async componentDidMount() {
      if (await this.verificarPermissao()) {
				this.props.history.push('/dashboardcomercial')
			} else {
				this.apiListarPrestadores()
        this.apiListarPrestador(this.props.match.params.id);
			}
    }

		apiListarPrestadores = async () => {
			let res = await api_topmotos.get('/prestadores/');
			this.setState({ prestadores: res.data });
		}

    apiListarPrestador = async e => {
        let {data} = await api_topmotos.get("/prestador/oficina/id/" + e)

        this.setState({
						item: data[0].item_id,
						selectPrestador: data[0].prestador_id,
            dataEmissao: moment(data[0].data_saida).format("yyyy-MM-DD"),
            dataVolta: moment(data[0].data_volta).format("yyyy-MM-DD"),
						prestacao: data[0].prestacao,
						obs: data[0].descricao
        })
    }

		mascaraReal = (e) =>  {
			var real = e.replace('.','');
			real = real.replace(',', '')
			real = real + '';
			real = real.replace(/([0-9]{2})$/g, ",$1");
			if( real.length > 6 )
							real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
	
			return real;
		}

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiCadastrarPrestador = async e => {
        e.preventDefault();

        const {
					selectPrestador,
					dataEmissao,
					dataVolta,
					prestacao,
					obs,
        } = this.state;

        if(!selectPrestador  ||
            !dataEmissao) {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
                
        } else {
            this.setState({ errorMessage: '' });

						let realCompra = prestacao;
						if (typeof prestacao != "number") {
							realCompra = prestacao.replace('.', '');
							realCompra = realCompra.replace(',', '.');
							realCompra = parseFloat(realCompra);
						}

            try {

							let prestador_nome = this.state.prestadores.filter(p => {
								return p.prestador_id == selectPrestador;
							})
							console.log(prestador_nome)

							let dataVencimento = moment().add(1, 'months').set("date", prestador_nome[0].prestador_vencimentoPadrao).format("yyyy-MM-DD");
				await api_topmotos.put('/prestador/oficina/id/' + this.props.match.params.id, {
					prestador_id: selectPrestador,
					data_saida: dataEmissao,
					data_volta: dataVolta,
					prestacao: realCompra,
					descricao: obs,

					atualizacaodata: this.getTimestamp(),
				}).then(async () => {
					await api_topmotos.put('/financeiro/oficina/update/id/' + this.props.match.params.id, {
						descricao: this.props.match.params.id + ' - Oficina Serviço - ' +  (obs || 'Ordem de Serviço Externa') + ' - ' + prestador_nome[0].prestador_nome,
						dataVencimento: dataVencimento,
						valor: realCompra,
						alteracaodata: this.getTimestamp(),
						dataCompetencia: dataVencimento,
					})
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/prestador/oficina/id/)" });
				this.log(this.state.error);
			}

        this.props.history.push('/oficina/externos/'+this.state.item+'/tipo/'+this.props.match.params.tipo);
      }
    }
    
    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar Ordem de Serviço Externo</strong></p>
                            </div>
                            <div className="content-formulario">
															<div className="row">
																	<div className="col-md-6">
																		<div className="grupo-input input-style-cad">
																			<label htmlFor="selectPrestador" id="lblSelectPrestador">Prestador de Serviço *</label>
																			<div className="grupo-campo-input input-cad" id="selectPrestador">
																				<i className="fas fa-user" id="iconSelectPrestador"></i>
																				<select
																						value={this.state.selectPrestador}
																						autoComplete="on"
																						onChange={event => { this.setState({ selectPrestador: event.target.value }); }}
																						required
																					>
																						<option value="NULL">Selecione o Prestador</option>
																						{this.state.prestadores.map(p => (
																							<option value={p.prestador_id}>{p.prestador_nome}</option>
																						))}
																				</select>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className="grupo-input input-style-cad">
																			{this.state.addPrestador}&nbsp;&nbsp;
																		</div>
																	</div>
																</div>

																<div className="row">
																	<div className="col-md-6">
																		<div className="grupo-input input-style-cad">
																			<label htmlFor="dataEmissao" id="lblDataEmissao">Data Envio *</label>
																			<div className="grupo-campo-input input-cad" id="dataEmissao">
																				<i className="fa fa-calendar" id="iconDataEmissao"></i>
																				<input
																					value={this.state.dataEmissao}
																					type="date"
																					onChange={event => { this.setState({ dataEmissao: event.target.value }) }}
																					required
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className="grupo-input input-style-cad">
																			<label htmlFor="dataVoltar" id="lblDataVoltar">Data Volta *</label>
																			<div className="grupo-campo-input input-cad" id="dataVoltar">
																				<i className="fa fa-calendar" id="iconDataVoltar"></i>
																				<input
																					value={this.state.dataVolta}
																					type="date"
																					onChange={event => { this.setState({ dataVolta: event.target.value }) }}
																					required
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-6">
																		<div className="grupo-input input-style-cad">
																			<label htmlFor="prestacao" id="lblPrestacao">Valor da Prestação *</label>
																			<div className="grupo-campo-input input-cad" id="prestacao">
																				<i className="fas fa-dollar-sign" id="iconPrestacao"></i>
																				<input
																					value={this.state.prestacao}
																					type="text"
																					onChange={event => { this.setState({ prestacao: this.mascaraReal(event.target.value.toString()) }) }}
																					required
																				/>
																			</div>
																		</div>
																	</div>										
																</div>
																<textarea value={this.state.obs} onChange={a => this.setState({obs: a.target.value})} className="box-obs" placeholder="Escreva aqui uma descrição"></textarea>

                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarPrestador} className="cadbtn">Editar</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
					
            </>
        )
    }
}

export default PrestadorEditarPage
