import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import {
	Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

class RelatorioCompraDetPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            motoNome: '',

            compra: [],
            cliente: [],
            usuario: [],
            item: [],
            itemT: [],
            compra_id: this.props.match.params.id,
            mov: [],
            transferencia: [],
            obs: '',
            checklistD: [],
            checklist: [],
            terceiro: [],
            tipoT: '',
            comprador: [],
		};
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/compras/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarCompras();
            this.apiListarMovimentacoes();
        }
    }

    apiListarCompras = async event => {
        let res = await api_topmotos.get('/compras/all');

        let data = res.data.filter(a => a.id == this.state.compra_id);
        
        this.setState({ 
            compra: data,
            obs: data[0].observacao_pag,
        });

        this.apiListarClientes(data[0].pessoa)
        this.apiListarItens(data[0].item)
        this.apiListarChecklists(data[0].item)
	}
    
    apiListarClientes = async (id) => {
		let res = await api_topmotos.get('/pessoas/');
        
        let data = res.data.filter(a => a.pessoa_id == id); 

        this.setState({ cliente: data});
    }

    apiListarComprador = async (id) => {
		let res = await api_topmotos.get('/usuarios');
        
        let data = res.data.filter(a => a.usuario_id == id); 

        this.setState({ comprador: data});
    }
    
    apiListarChecklists = async (id) =>{
        let res = await api_topmotos.post('/checklistD/resposta/' + id);
        this.setState({checklistD: res.data})

        let ret = await api_topmotos.post('/checklist/resposta/' + id);
        this.setState({checklist: ret.data})
    }

	apiListarUsuarios = async (id) => {
        let res = await api_topmotos.get('/usuarios');
        
        let data = res.data.filter(a => a.usuario_id == id)

		this.setState({usuario: data});
	}

	apiListarItens = async (id) => {
        let res = await api_topmotos.get('/itens/');
        
        let data = res.data.filter(a => a.item_id == id)
        
        if(data[0].terceira_pessoa){
            this.apiListarTerceiraPessoa(data[0].terceira_pessoa)
        }

        this.apiListarComprador(data[0].comprador)
		this.setState({ item: data});
    }

    apiListarTerceiraPessoa = async (id) => {
        let re = await api_topmotos.get('/pessoasT/id/' + id);
        
		this.setState({ terceiro: re.data, tipoT: re.data[0].tipo});
    }

    apiListarMovimentacoes = async (id) => {
        let res = await api_topmotos.get('/financeiro/');

        let data = res.data.filter(a => a.compra_id == this.state.compra_id);

        this.setState({mov: data})

        this.apiListarTranferencia(data)
        
    }

    apiListarTroca = async () =>{
        let res = await api_topmotos.get('/trocas/');

        let data = res.data.filter(a => a.venda == this.state.venda_id)

        if(data.length != 0){
            res = await api_topmotos.get('/itens/');
            data = res.data.filter(a => a.item_id == data[0].item)
    
            this.setState({ itemT: data});
        }
    }

    apiListarTranferencia = async (dados) =>{
        let lista = [];

        for(let i in dados){
            let {data} = await api_topmotos.get("/dadosBanc/id/"+ dados[i].id)

            if(data[0]){lista.push(data[0])} 
            
        }

        this.setState({transferencia: lista})   
    }
    
    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    pesquisar = () => {
        this.filtroVenda(this.state.inicio, this.state.fim, this.state.vendedor, this.state.empresa)
    }

    // Open input-grupo caso tenha (A) Receber/Pagar
    status = (e, input) =>{
        this.setState({status: e})

        if(input == 1){
            var dataPag = document.getElementById('dataPag');
            var pago = document.getElementById('grupo-pago');
    
            if( e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
    
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: null})
                this.setState({valorPago: null})
            }
        } else{
            var dataPag = document.getElementById('dataPag2');
            var pago = document.getElementById('grupo-pago2');

            if(e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: '', valorPago:''})
            }
        }

    }

    gerarProcuracao = () => {
        let search = ''
        if (this.state.item[0].motoNome) {
            search = '?nomeVeiculo='+this.state.item[0].motoNome
        }
        window.open('/pdfcompra/'+this.state.compra_id+'/9'+search, '_blank')
    }

    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-head">
                                <strong>
                                    <p>Relatório Compra</p>
                                </strong>
                                <div className="d-flex">
                                    <div className="header-print" onClick={() => window.open('/pdfcompra/'+this.state.compra_id+'/7', '_blank')}>
                                        <i className="fas fa-print"></i>
                                    </div>
                                    <div className="header-print" onClick={() => { this.gerarProcuracao() }}>
                                        <i className="fas fa-print"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="relatorio-body">
                                <h1 className="header-relatorio">Detalhes</h1>
                                <div className="box-conteudo">
                                    {this.state.compra.map((compra) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Empresa</p>
                                                <p>{compra.empresa_razaosocial}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Data de Compra</p>
                                                <p>{moment(compra.data).format('DD/MM/YYYY') + ' às ' + moment(compra.data).format('HH:mm')}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Preço  de Compra</p>
                                                <p>{compra.valor_compra ? (compra.valor_compra).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                
                                {this.state.item.length != 0 ? <h1 className="header-relatorio">Automóvel</h1> : ''}
                                <div className="box-conteudo">
                                    {this.state.item.map((item) => (
                                        <>
                                            {item.motoNome ? <div className="conteudo-info">
                                                <p>Nome da Moto (Procuração)</p>
                                                <p>{item.motoNome}</p>
                                            </div> : ''}
                                            <div className="conteudo-info">
                                                <p>Marca</p>
                                                <p>{item.item_marca}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Modelo</p>
                                                <p>{item.item_modelo}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Ano Fab.</p>
                                                <p>{item.item_ano_fab}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Ano Mod.</p>
                                                <p>{item.ano}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Cor</p>
                                                <p>{item.cor}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Km</p>
                                                <p>{item.item_km}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Preço</p>
                                                <p>{item.item_preco ? (item.item_preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>RENAVAM</p>
                                                <p>{item.renavam}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Chassi</p>
                                                <p>{item.chassi}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Placa</p>
                                                <p>{item.placa}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Combustível</p>
                                                <p>{item.combustivel}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>    

                                {this.state.checklistD.length > 0 ? <h1 className="header-relatorio">Checklist Documentos</h1> : '' }
                                <div className="box-conteudo">
                                    { this.state.checklistD.map((check, i) => (
                                        <div className="conteudo-info col-md-5">
                                            <p>{i+1}. {check.pergunta}</p>
                                            <p>{check.alternativa}<br/>{check.respostas_checklist_documento}</p>
                                        </div>
                                        ))
                                    }
                                </div>
                                
                                {this.state.checklist.filter(a => a.resposta != '').length > 0 ? <h1 className="header-relatorio">Checklist Veículo</h1> : ''}
                                <div className="box-conteudo">
                                    { this.state.checklist.filter(a => a.resposta != '').map((check, i) => (
                                        <div className="conteudo-info col-md-5">
                                            <p>{i+1}. {check.pergunta}</p>
                                            <p>{check.resposta}</p>
                                        </div>
                                        ))
                                    }
                                </div>
                        
                                <h1 className="header-relatorio">Vendedor do Automóvel</h1>
                                <div className="box-conteudo">
                                    {this.state.cliente.map((cli) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Nome</p>
                                                <p>{cli.pessoa_nome}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>CPF</p>
                                                <p>{cli.pessoa_cpf_cnpj}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>RG</p>
                                                <p>{cli.pessoa_rg}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Telefone</p>
                                                <p>{cli.pessoa_celular}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Celular</p>
                                                <p>{cli.pessoa_telefone}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Email</p>
                                                <p>{cli.pessoa_email}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Endereco</p>
                                                <p>{cli.endereco_logradouro}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Número</p>
                                                <p>{cli.endereco_numero}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Complemento</p>
                                                <p>{cli.endereco_complemento}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Bairro</p>
                                                <p>{cli.endereco_bairro}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Estado</p>
                                                <p>{cli.endereco_estado}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Cidade</p>
                                                <p>{cli.endereco_cidade}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>

                                {this.state.comprador.length >= 1 ? 
                                <>
                                <h1 className="header-relatorio">Comprador do Automóvel</h1>
                                <div className="box-conteudo">
                                    {this.state.comprador.map((cli) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Nome</p>
                                                <p>{cli.usuario_nome}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Celular</p>
                                                <p>{cli.usuario_telefone}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Email</p>
                                                <p>{cli.usuario_email}</p>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                </>: null}
                                
                                {this.state.terceiro.length != 0 ? <h1 className="header-relatorio">Terceiro</h1> : ''}
                                <div className="box-conteudo">
                                    {this.state.terceiro.map((terceiro) => (
                                        <>
                                            <div className="conteudo-info">
                                                <p>Nome</p>
                                                <p>{terceiro.nome}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>CPF</p>
                                                <p>{terceiro.cpf}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>RG</p>
                                                <p>{terceiro.rg}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Telefone</p>
                                                <p>{terceiro.telefone}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Tipo</p>
                                                <p>{terceiro.tipo}</p>
                                            </div>
                                            {this.state.tipoT == 'terceiro' ? 
                                            <>
                                                <div className="conteudo-info">
                                                    <p>Cep</p>
                                                    <p>{terceiro.cep}</p>
                                                </div>
                                                <div className="conteudo-info">
                                                    <p>Logradouro</p>
                                                    <p>{terceiro.logradouro}</p>
                                                </div>
                                                <div className="conteudo-info">
                                                    <p>Número</p>
                                                    <p>{terceiro.numero}</p>
                                                </div>
                                                <div className="conteudo-info">
                                                    <p>Complemento</p>
                                                    <p>{terceiro.complemento}</p>
                                                </div>
                                                <div className="conteudo-info">
                                                    <p>Bairro</p>
                                                    <p>{terceiro.bairro}</p>
                                                </div>
                                                <div className="conteudo-info">
                                                    <p>Cidade</p>
                                                    <p>{terceiro.cidade}</p>
                                                </div>
                                                <div className="conteudo-info">
                                                    <p>Estado</p>
                                                    <p>{terceiro.estado}</p>
                                                </div>
                                            </>
                                            : ''}
                                        </>
                                    ))}
                                </div> 

                                {this.state.mov.length != 0 ? <h1 className="header-relatorio">Movimentações</h1> : ''}
                                <div className="table">
                                    <table>
                                    {this.state.mov.length != 0 ?
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Descrição
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Plano de Contas
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Tipo Pagamento
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Data de Vencimento
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Valor
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead> : ''}
                                        <tbody>
                                            {
                                                this.state.mov.map((mov) => (
                                                    <tr key={mov.id}>
                                                        <td>{mov.descricao}</td>
                                                        <td>{mov.cat_nome}</td>
                                                        <td>{mov.pagamento}</td>
                                                        <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                        <td>{mov.valor ? mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <p className="py-3">{this.state.obs ? <><strong>Obs: </strong>{this.state.obs}</> : ''}</p>
                                <div>
                                {this.state.transferencia.length != 0 ? <h1 className="header-relatorio">Transferêcia</h1> : ''}
                                {
                                    this.state.transferencia.map((trans) => (
                                        <div className="box-conteudo">
                                            <div className="conteudo-info">
                                                <p>Banco</p>
                                                <p>{trans.banco}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Agência</p>
                                                <p>{trans.agencia}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Tipo de Conta</p>
                                                <p>{trans.tipo_conta}</p>
                                            </div>
                                            <div className="conteudo-info">
                                                <p>Tipo de Conta</p>
                                                <p>{trans.conta}</p>
                                            </div>
                                        </div>
                                    ))} 
                                </div>
                            </div>
                            <div className="relatorio-footer">
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default RelatorioCompraDetPage
