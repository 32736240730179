import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Produtos extends React.Component {

	constructor(props) {
		super(props);

		

		this.state = {
			dados: [],

			pageSize: 1,
			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesUnidade: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefExterna: '',
			modalDetalhesRefInterna: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',

			pageLimit: 10,
			pageAtual: 0,
		};
	}

	/**
	 * @description Organiza a paginação e ativa um indice
	 */
	 putItem = (pageAtual, index) => {

		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.state.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	nextorprevious = (e, index) => {
		e.preventDefault();

		this.setState({ pageAtual: index })
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/produtos")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarProdutos();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] produtosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	apiListarProdutos = async () => {
		let res = await api_topmotos.get('/produtos/');
		this.setState({ dados: res.data });
		this.setState({pageSize: Math.ceil(res.data.length / this.state.pageLimit)})
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idProduto) => {
		let resProduto = await api_topmotos.get('/produtos/id/' + idProduto);
		resProduto = resProduto.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resProduto.produto_id,
			modalDetalhesNome: resProduto.produto_nome,
			modalDetalhesDescricao: resProduto.produto_descricao,
			modalDetalhesUnidade: resProduto.tipo_nome,
			modalDetalhesFornecedor: resProduto.fornecedor_nome,
			modalDetalhesRefInterna: resProduto.refInterna,
			modalDetalhesRefExterna: resProduto.refExterna,
			modalDetalhesValorVenda: resProduto.valor_venda,
			modalDetalhesValorCompra: resProduto.valor_compra,
		});
	}

	toggleModalCadastrar = () => {
		this.setState({ modalCadastrarOpen: !this.state.modalCadastrarOpen });
	}

	modalCadastrar = () => {
		this.toggleModalCadastrar();
	}

	toggleModalEditar = () => {
		this.setState({ modalEditarOpen: !this.state.modalEditarOpen });
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id) => {
		let res = await api_topmotos.get('/produtos/id/' + id);
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: res.produto_id,
			modalDeletarNome: res.produto_nome,
		})
	}

	apiDeletarProduto = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/produtos/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/protudos/update)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Produto deletado com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Produtos</p>
							<div className="form-add" onClick={a => this.props.history.push("/cadastrarproduto")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Descrição
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Unidade
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Venda
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Compra
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Fornecedor
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
												</div>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.state.dados.slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((produto, id) => (
											<tr key={produto.produto_id} style={{backgroundColor: produto.valor_venda && produto.valor_compra ? '' : 'rgba(255, 0, 0, 0.1)' }}>
												<td>{produto.produto_id}</td>
												<td>{produto.produto_nome}</td>
												<td>{produto.produto_descricao}</td>
												<td>{produto.tipo_nome}</td>
												<td>{produto.valor_venda ? parseFloat(produto.valor_venda)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}</td>
												<td>{produto.valor_compra ? parseFloat(produto.valor_compra)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}</td>
												<td>{produto.fornecedor_nome}</td>
												<td className="td-acoes">
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(produto.produto_id)}></i>&nbsp;
													<i className="fas fa-pen" onClick={() => this.props.history.push("/editarproduto/" + produto.produto_id)}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(produto.produto_id)}></i>
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
							<div className="form-footer">
								{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
									</div>

									{
										[...Array(this.state.pageSize)].map((e, index) => (
											this.putItem(this.state.pageAtual, index)
										))
									}

									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.state.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
									</div>
								</div>}
							</div>
						</div>

						{/* MODAL DETALHES */}
						<Modal
							isOpen={this.state.modalDetalhesOpen}
							toggle={this.toggleModalDetalhes.bind(this)}
							backdrop={true}
							keyboard={true}
							size="lg"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesNome} (ID: {this.state.modalDetalhesId})</ModalHeader>
							<ModalBody>
								<div className="box-conteudo">
									<div className="conteudo-info">
										<p>Nome</p>
										<p>{this.state.modalDetalhesNome}</p>
									</div>
									<div className="conteudo-info">
										<p>Descrição</p>
										<p>{this.state.modalDetalhesDescricao}</p>
									</div>
									<div className="conteudo-info">
										<p>Fornecedor</p>
										<p>{this.state.modalDetalhesFornecedor}</p>
									</div>
									<div className="conteudo-info">
										<p>Unidade</p>
										<p>{this.state.modalDetalhesUnidade}</p>
									</div>
									<div className="conteudo-info">
										<p>Referencia Externa</p>
										<p>{this.state.modalDetalhesRefExterna}</p>
									</div>
									<div className="conteudo-info">
										<p>Referencia Interna</p>
										<p>{this.state.modalDetalhesRefInterna}</p>
									</div>
									<div className="conteudo-info">
										<p>Valor de Venda</p>
										<p>{this.state.modalDetalhesValorVenda ? parseFloat(this.state.modalDetalhesValorVenda)
												.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'sem valor'}</p>
									</div>
									<div className="conteudo-info">
										<p>Valor de Compra</p>
										<p>{this.state.modalDetalhesValorCompra ? parseFloat(this.state.modalDetalhesValorCompra)
												.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'sem valor'}</p>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL DELETAR */}
						<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar o Produto {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarProduto} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

						<Versionamento />
					</div>
				</div>
			</section>

		)
	}
}

export default Produtos;
