import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

class ProdutoEditarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dados: [],

            tipos: [],
            fornecedores: [],

            modalCadastrarOpen: false,
            modalCadastrarNome: '',
            modalCadastrarTipo: null,
            modalCadastrarDescricao: '',
            modalCadastrarFornecedor: null,
            modalCadastrarRefInterna: '',
            modalCadastrarRefEsterna: '',
            modalCadastrarValor: '',
        };

        this.apiListarProduto(this.props.match.params.id);
        this.apiListarTipos();
        this.apiListarFornecedores();
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] ProdutoUPDATE/READ > " + msg);
    }

    apiListarProduto = async e => {
        let { data } = await api_topmotos.get("/produtos/id/" + e)

        console.log('====================================');
        console.log(data);
        console.log('====================================');

        this.setState({
            modalCadastrarNome: data[0].produto_nome,
            modalCadastrarTipo: data[0].produto_unidade,
            modalCadastrarDescricao: data[0].produto_descricao,
            modalCadastrarFornecedor: data[0].produto_fornecedor,
            modalCadastrarRefInterna: data[0].refInterna,
            modalCadastrarRefEsterna: data[0].refInterna,
            modalCadastrarValorVenda: data[0].valor_venda ? parseFloat(data[0].valor_venda).toLocaleString('pt-BR', { style: 'decimal' })  : '',
            modalCadastrarValorCompra: data[0].valor_compra ? parseFloat(data[0].valor_compra).toLocaleString('pt-BR', { style: 'decimal' }) : ''
        })
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiListarTipos = async () => {
        let res = await api_topmotos.get('/produtos/tipos/');
		this.setState({ tipos: res.data });
    }

    apiListarFornecedores = async () => {
        let res = await api_topmotos.get('/fornecedores/');
		this.setState({ fornecedores: res.data });
    }

    apiCadastrarProduto = async e => {
        e.preventDefault();

        const {
            modalCadastrarNome,
            modalCadastrarTipo,
            modalCadastrarDescricao,
            modalCadastrarFornecedor,
            modalCadastrarRefInterna,
            modalCadastrarRefEsterna,
            modalCadastrarValorVenda,
            modalCadastrarValorCompra,
        } = this.state;

        if (!modalCadastrarNome ||
            modalCadastrarTipo === 'NULL'||
            !modalCadastrarDescricao ||
            modalCadastrarFornecedor === 'NULL' ||
            !modalCadastrarRefInterna ||
            !modalCadastrarRefEsterna ||
            !modalCadastrarValorVenda ||
            !modalCadastrarValorCompra) {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);

        } else {
            this.setState({ errorMessage: '' });

            let realVenda = modalCadastrarValorVenda.replace('.', '');
            realVenda = realVenda.replace(',', '.');
            realVenda = parseFloat(realVenda);
            let realCompra = modalCadastrarValorCompra.replace('.', '');
            realCompra = realCompra.replace(',', '.');
            realCompra = parseFloat(realCompra);
      
            try {
                await api_topmotos.put('/produtos/update/id/' + this.props.match.params.id, {
                    produto_nome: modalCadastrarNome,
                    produto_unidade: modalCadastrarTipo,
                    produto_descricao: modalCadastrarDescricao,
                    produto_fornecedor: modalCadastrarFornecedor,
                    refInterna: modalCadastrarRefInterna,
                    refExterna: modalCadastrarRefEsterna,
                    valor_venda: realVenda,
                    valor_compra: realCompra,
                })
            } catch (err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/produtos/update)" });
                this.log(this.state.error);
            }

            this.props.history.push('/produtos');
        }
    }

    
    mascaraReal = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
			real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return real;
	}

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar Produto</strong></p>
                            </div>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                    </div>
                                    <div className="col-md-6">
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nome">Nome *</label>
                                            <div className="grupo-campo-input input-cad" id="nome">
                                                <i className="fas fa-user" id="iconNome"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Nome do Produto"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarNome}
                                                    onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="descricao">Descrição</label>
                                            <div className="grupo-campo-input input-cad" id="descricao">
                                                <i className="fa fa-pen" id="iconDescricao"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe a Descrição do Produto"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarDescricao}
                                                    onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="refEsterna">Referencia Esterna *</label>
                                            <div className="grupo-campo-input input-cad" id="refEsterna">
                                                <i className="fas fa-asterisk" id="iconRefEsterna"></i>
                                                <input type="text"  value={this.state.modalCadastrarRefEsterna} onChange={event => this.setState({ modalCadastrarRefEsterna: event.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="refInterna">Referencia Interna *</label>
                                            <div className="grupo-campo-input input-cad" id="refInterna">
                                                <i className="fas fa-asterisk" id="iconRefInterna"></i>
                                                <input type="text" value={this.state.modalCadastrarRefInterna} onChange={event => this.setState({ modalCadastrarRefInterna: event.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="fornecedor">Fornecedor  *</label>
                                            <div className="grupo-campo-input input-cad" id="fornecedor">
                                                <i className="fas fa-warehouse" id="iconFornecedor"></i>
                                                <select
                                                    name="fornecedor"
                                                    placeholder="&nbsp;Selecione o Fornecedor"
                                                    autoComplete="on"
                                                    required
                                                    value={this.state.modalCadastrarFornecedor}
                                                    onChange={a => this.setState({ modalCadastrarFornecedor: a.target.value })}
                                                >
                                                    <option value="NULL">Selecione o Fornecedor</option>
                                                    {
                                                        this.state.fornecedores.map((e, id) => (
                                                            <option key={e.fornecedor_id} value={e.fornecedor_id}>{e.fornecedor_nome}</option>
                                                        ))
                                                    }
                                                </select>
                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="tipo">Unidade *</label>
                                            <div className="grupo-campo-input input-cad" id="tipo">
                                                <i className="fas fa-balance-scale" id="iconTipo"></i>
                                                <select
                                                    name="tipo"
                                                    placeholder="&nbsp;Selecione a Unidade de Medida"
                                                    autoComplete="on"
                                                    required
                                                    value={this.state.modalCadastrarTipo}
                                                    onChange={a => this.setState({ modalCadastrarTipo: a.target.value })}
                                                >
                                                    <option value="NULL">Selecione a Unidade de Medida</option>
                                                    {
                                                        this.state.tipos.map((e, id) => (
                                                            <option key={e.tipo_id} value={e.tipo_id}>{e.tipo_nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="valor">Valor de Venda</label>
                                            <div className="grupo-campo-input input-cad" id="valor">
                                                <i className="fas fa-dollar-sign" id="iconTipo"></i>
                                                <input type="text" value={this.state.modalCadastrarValorVenda} onChange={a => {this.setState({modalCadastrarValorVenda: this.mascaraReal(a.target.value)})}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="valor">Valor de Compra</label>
                                            <div className="grupo-campo-input input-cad" id="valor">
                                                <i className="fas fa-dollar-sign" id="iconTipo"></i>
                                                <input type="text" value={this.state.modalCadastrarValorCompra} onChange={a => {this.setState({modalCadastrarValorCompra: this.mascaraReal(a.target.value)})}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarProduto} className="cadbtn">Editar</Button>
                            </div>
                            <Versionamento />
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default ProdutoEditarPage
