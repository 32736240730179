import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class ConsorcioPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            consorcio: [],
            consorcioF: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            plano: '',
            empresa: '',
            meses: '',
            valor: '',
            ativo:'',
            comissao: '',
            errorMessage: '',
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/consorcio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.listarConsorcio();
        }
    }

    listarConsorcio = async () =>{
        let res = await api_topmotos.get('/consorcio/');
        this.setState({ consorcio: res.data, consorcioF: res.data });
    }

    consorcioId = async (id) =>{
        let dados = this.state.consorcio.filter(a => a.id == id)
        
        var real = dados[0].valor.toLocaleString('pt-br', {minimumFractionDigits: 2});
        this.setState({
            id: dados[0].id,
            plano: dados[0].plano,
            empresa: dados[0].empresa,
            meses: dados[0].meses,
            valor: real,
            ativo: dados[0].ativo,
            comissao: dados[0].comissao
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.consorcioId(id);
        await this.setState({modalAlterar: true})
    }

    apiCadastrarConsorcio = async () => {
        var inputs = [
            [this.state.plano, 'plano','iconPlano','lblPlano'],
            [this.state.empresa, 'empresa','iconEmpresa','lblEmpresa'],
            [this.state.meses, 'meses','iconMeses','lblMeses'],
            [this.state.valor, 'valor','iconValor','lblValor'],
            [this.state.comissao, 'comissao','iconComissao','lblComissao']
        ]

        let val = 0;

        for(let i = 0; i < inputs.length; i++){
            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                val++;
            }
        }

        if(val == 0) {
            try {
                var real = this.state.valor.replace('.', '');
                real = real.replace(',', '.');

                await api_topmotos.post('/consorcio/create', {
                    plano: this.state.plano,
                    empresa: this.state.empresa,
                    meses: this.state.meses,
                    valor: real,
                    ativo: 1,
                    comissao: this.state.comissao
                });

                this.toggleModalCadastrar()
                this.listarConsorcio()
                this.limparStates()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/consorcio/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #ddd; transition: 0.2s;";
					inputIcone.style.cssText = "color: #bbb; transition: 0.2s;";
					inputNome.style.cssText = "color: #777; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    limparStates = () =>{
        this.setState({
            plano: '',
            empresa: '',
            meses: '',
            valor: '',
            errorMessage: ''
        })
    }

    apiAlterarConsorcio = async () => {
        var inputs = [
            [this.state.plano, 'plano','iconPlano','lblPlano'],
            [this.state.empresa, 'empresa','iconEmpresa','lblEmpresa'],
            [this.state.meses, 'meses','iconMeses','lblMeses'],
            [this.state.valor, 'valor','iconValor','lblValor'],
            [this.state.comissao, 'comissao','iconComissao','lblComissao']
        ]

        let val = 0;

        for(let i = 0; i < inputs.length; i++){
            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                val++;
            }
        }

        if(val == 0) {
            try {
                var real = this.state.valor.replace('.', '');
                real = real.replace(',', '.');

                await api_topmotos.put('/consorcio/update/id/' + this.state.id, {
                    id: this.state.id,
                    plano: this.state.plano,
                    empresa: this.state.empresa,
                    meses: this.state.meses,
                    valor: real,
                    comissao: this.state.comissao
                })

                this.toggleModalAlterar()
                this.listarConsorcio()
                this.limparStates()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/consorcio/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #ddd; transition: 0.2s;";
					inputIcone.style.cssText = "color: #bbb; transition: 0.2s;";
					inputNome.style.cssText = "color: #777; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var con = document.getElementById("con" + id);

        try {
            await api_topmotos.put('/consorcio/ativar/' + id, {
                ativo: con.checked == true ? 1 : 0
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/consorcio/create)" });
            this.log(this.state.error);
        }
    }

    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valor: real})
    }

    openModalCadastrar =  () =>{
        this.limparStates();
        this.setState({modalCadastrar: true})
    }


    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Consórcio</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.openModalCadastrar()}>Adicionar Consórcio<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="relatorio-titulo">
                                <p>Consórcio</p>
                            </div>

                            <div class="form-head mt-3"></div>
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Plano
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Empresa
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Meses
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Comissão
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.consorcioF.map((con) => (
                                                <tr key={con.id}>
                                                    <td>{con.id}</td>
                                                    <td>{con.plano}</td>
                                                    <td>{con.empresa}</td>
                                                    <td>{con.meses}</td>
                                                    <td>{con.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td>{con.comissao ? `${con.comissao}%` : ''}</td>
                                                    <td className="td-acoes">
                                                        <label className="switch mr-2">
                                                            <input type="checkbox" id={"con" + con.id} onClick={() => this.toggleAtivar(con.id)} defaultChecked={con.ativo == 0 ? false : true}/>
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <i className="fas fa-pen" onClick={() => {this.OpenModalAlteracao(con.id)}}></i>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div class="form-footer"><span></span></div>
                            </div>

                            {/* {this.state.consorcioF.map((tipo) => (
                                    <div className="item-opcao">
                                        <div className="head default">
                                            <div className="p-head">
                                                <p className="pr-2 pergunta-p">{tipo.id}.</p>
                                                <p className="pergunta-p">{tipo.tipo_pagamento}</p>
                                            </div>
                                            <div className="d-flex">
                                                <label className="switch mr-2">
                                                    <input type="checkbox" id={"tipo" + tipo.id} onClick={() => this.toggleAtivar(tipo.id)} defaultChecked={tipo.ativo == 0 ? true : false}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <i className='fas fa-pen icone' onClick={() => {this.OpenModalAlteracao(tipo.id)}}></i>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            } */}

                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Consórcio</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="plano" id="lblPlano">Plano</label>
                                                        <div className="grupo-campo-input input-cad" id="plano">
                                                            <i className="fas fa-pen" id="iconPlano"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva o Plano"
                                                                onChange={event => this.setState({ plano: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="empresa" id="lblEmpresa">Empresa</label>
                                                        <div className="grupo-campo-input input-cad" id="empresa">
                                                            <i className="fas fa-pen" id="iconEmpresa"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva a empresa"
                                                                onChange={event => this.setState({ empresa: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="meses" id="lblMeses">Meses</label>
                                                        <div className="grupo-campo-input input-cad" id="meses">
                                                            <i className="fas fa-pen" id="iconMeses"></i>
                                                            <input
                                                                type="number"
                                                                min='0'
                                                                autoComplete="on"
                                                                placeholder="Escreva os meses"
                                                                onChange={event => this.setState({ meses: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="valor" id="lblValor">Valor</label>
                                                        <div className="grupo-campo-input input-cad" id="valor">
                                                            <i className="fas fa-pen" id="iconValor"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva os valor"
                                                                value={this.state.valor}
                                                                onChange={event => this.mascaraReal(event.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="comissao" id="lblComissao">Comissão</label>
                                                        <div className="grupo-campo-input input-cad" id="comissao">
                                                            <i className="fas fa-percent"></i>
                                                            <input
                                                                type="number"
                                                                placeholder="&nbsp;Informe a comissão por consórcio"
                                                                value={this.state.comissao}
                                                                onChange={event => this.setState({ comissao: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrar}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarConsorcio}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Consórcio</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="plano" id="lblPlano">Plano</label>
                                                        <div className="grupo-campo-input input-cad" id="plano">
                                                            <i className="fas fa-pen" id="iconPlano"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva o Plano"
                                                                value={this.state.plano}
                                                                onChange={event => this.setState({ plano: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="empresa" id="lblEmpresa">Empresa</label>
                                                        <div className="grupo-campo-input input-cad" id="empresa">
                                                            <i className="fas fa-pen" id="iconEmpresa"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva a empresa"
                                                                value={this.state.empresa}
                                                                onChange={event => this.setState({ empresa: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="meses" id="lblMeses">Meses</label>
                                                        <div className="grupo-campo-input input-cad" id="meses">
                                                            <i className="fas fa-pen" id="iconMeses"></i>
                                                            <input
                                                                type="number"
                                                                min='0'
                                                                autoComplete="on"
                                                                placeholder="Escreva os meses"
                                                                value={this.state.meses}
                                                                onChange={event => this.setState({ meses: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="valor" id="lblValor">Valor</label>
                                                        <div className="grupo-campo-input input-cad" id="valor">
                                                            <i className="fas fa-pen" id="iconValor"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva os valor"
                                                                value={this.state.valor}
                                                                onChange={event => this.mascaraReal(event.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="comissao" id="lblComissao">Comissão</label>
                                                        <div className="grupo-campo-input input-cad" id="comissao">
                                                            <i className="fas fa-percent"></i>
                                                            <input
                                                                type="number"
                                                                placeholder="&nbsp;Informe a comissão por consórcio"
                                                                value={this.state.comissao}
                                                                onChange={event => this.setState({ comissao: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarConsorcio}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ConsorcioPage