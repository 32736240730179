import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class EditarVendasPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dados: [],
            empresas: [],
            clientes: [],
            usuarios: [],
            itens: [],
            options: [],

            modalCadastrarOpen: false,
            modalCadastrarCliente: '',
            modalCadastrarUsuario: '',
            modalCadastrarValor: '',
            modalCadastrarMetodopagamento: '',
            modalCadastrarParcelas: '',
            modalCadastrarEntrada: '',
            modalCadastrarDesconto: 0.0,
            modalCadastrarVendaId: '',
            modalCadastrarItens: [],

            openmodal: false,

            modeleSE: '',
            timing: null,
            lettersInfo: null
        };
    }

    async componentDidMount() {
        await this.apiListarClientes();
        await this.apiListarUsuarios();
        await this.apiListarItens();
        this.apiListarVenda(this.props.match.params.id);

    }

    apiListarVenda = async e => {
        let {data} = await api_topmotos.post("/vendas/unique", {id: e})
        
        let it = []
        for (let i of data.itens){
            it.push(i.vendaitens_item)
        }
        
        let m = this.state.itens.filter(y => it.includes(y.item_id));
        it = [];

        for (let i of m){
            it.push(i.item_id)
        }

        this.setState({
            modalCadastrarCliente: data.venda[0].venda_cliente,
            modalCadastrarDesconto: data.venda[0].venda_desconto,
            modalCadastrarEntrada: data.venda[0].venda_entrada,
            modalCadastrarMetodopagamento: data.venda[0].venda_metodopagamento,
            modalCadastrarParcelas: data.venda[0].venda_parcelas,
            modalCadastrarValor: data.venda[0].venda_valor,
            modalCadastrarUsuario: data.venda[0].venda_usuario,
            modalCadastrarItens: it,
            options: m
        })
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiListarClientes = async event => {
        let res = await api_topmotos.get('/clientes/');
        this.setState({ clientes: res.data });
    }

    apiListarUsuarios = async event => {
        let res = await api_topmotos.get('/usuarios/');
        this.setState({ usuarios: res.data });
    }

    apiListarItens = async event => {
        let res = await api_topmotos.get('/itens/');
        this.setState({ itens: res.data });
    }
 

    setValor = async (itens) => {
        var somaValor = 0;
        if (itens) {
            let max = itens.length;
            for (let i = 0; i < max; i++) {
                let idItem = itens[i];
                let res = await api_topmotos.get('/itens/id/' + idItem);
                somaValor += res.data.item[0].item_preco;
            }
            this.setState({ modalCadastrarValor: somaValor });
        }
    }

    additem = async () => {
        let a = this.state.modalCadastrarItens.concat(this.state.lettersInfo.item_id)
        this.setValor(a)

        this.setState({modalCadastrarItens: this.state.modalCadastrarItens.concat(this.state.lettersInfo.item_id), lettersInfo: null, modeleSE: '', options: this.state.options.concat(this.state.lettersInfo)})
        
    }

    reviewModal = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_id == e && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
        this.setState({
            lettersInfo: data[0]
        })
    }

    rebuildModal = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(e.target.value.toLowerCase()) && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        this.setState({
            modeleSE: e.target.value,
        })

        if (item == undefined || e.target.value.length == 0) {
            this.setState({
                lettersInfo: null
            })
            return
        }

        this.setState({
            timing: setTimeout(async () => {
                let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
                this.setState({
                    lettersInfo: data[0]
                })
            }, 450)
        })
    }

    apiCadastrarVenda = async e => {
        e.preventDefault();

        const {
            modalCadastrarCliente,
            modalCadastrarUsuario,
            modalCadastrarValor,
            modalCadastrarMetodopagamento,
            modalCadastrarParcelas,
            modalCadastrarEntrada,
            modalCadastrarDesconto,
            modalCadastrarItens,
        } = this.state;

        if (!modalCadastrarCliente ||
            !modalCadastrarUsuario ||
            !modalCadastrarValor ||
            !modalCadastrarMetodopagamento ||
            !modalCadastrarParcelas ||
            !modalCadastrarItens ||
            modalCadastrarCliente === 'NULL' ||
            modalCadastrarUsuario === 'NULL' ||
            modalCadastrarMetodopagamento === 'NULL' ||
            modalCadastrarItens === 'NULL') {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);

            var aviso = document.getElementById('aviso');

        } else {

            try {
                await api_topmotos.put('/vendas/update/id/' + this.props.match.params.id, {
                    venda_cliente: modalCadastrarCliente,
                    venda_usuario: modalCadastrarUsuario,
                    venda_valor: modalCadastrarValor,
                    venda_metodopagamento: modalCadastrarMetodopagamento,
                    venda_parcelas: modalCadastrarParcelas,
                    venda_entrada: modalCadastrarEntrada,
                    venda_desconto: modalCadastrarDesconto,
                    itens: this.state.modalCadastrarItens
                }).then(res => {
                    this.setState({ modalCadastrarVendaId: res.data.returnId });
                });
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendas/create)" })
                this.log(this.state.error);
            }

            this.props.history.push("/vendas")
        }
    }

    togglemodal = () => this.setState({ openmodal: !this.state.openmodal })

    removeItem = async (index) => {
        let i = this.state.modalCadastrarItens;
        let op = this.state.options;

        i.splice(index, 1)
        op.splice(index, 1)

        this.setValor(i)

        this.setState({modalCadastrarItens: i, options: op});
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.openmodal} toggle={this.togglemodal} backdrop={true} keyboard={true} size="lg">
                    <ModalHeader toggle={this.togglemodal}>
                        Selecionar itens
                    </ModalHeader>
                    <ModalBody>
                        <div className="grupo-input input-style-cad">
                            <label htmlFor="cliente">Modelo </label>
                            <div className="grupo-campo-input input-cad" id="cliente">
                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                <input type="text"
                                    value={this.state.modeleSE}
                                    onChange={this.rebuildModal}
                                    onFocus={a => this.setState({ clientFocus: true })}
                                    onBlur={a => this.setState({ clientFocus: false })}
                                    placeholder="Insira o modelo"

                                />
                            </div>
                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                {
                                    this.state.itens.filter(y => y.compra == 1 && y.item_modelo.toLowerCase().includes(this.state.modeleSE.toLowerCase()) && !this.state.modalCadastrarItens.includes(y.item_id)).slice(0, 10).map(e => (
                                        <li onClick={a => {this.setState({ modeleSE: e.item_modelo }); this.reviewModal(e.item_id)}}>
                                            {e.item_modelo}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            this.state.lettersInfo ? (
                                <div className="infamus">
                                    <div className="imgcropper">
                                        <img src={this.state.lettersInfo.itemfotos_foto} alt="" />
                                    </div>
                                    <br />
                                    <div className="gridinfoview">
                                        <span className="alb lgm">
                                            {this.state.lettersInfo.item_descricao}
                                        </span>
                                        <span className="orange lgm bol">
                                            {this.state.lettersInfo.item_modelo}
                                        </span>
                                        <span className="tl lgm gr bol">
                                            {this.state.lettersInfo.item_ano}
                                        </span>
                                        <span className="lgm bol">
                                            {this.state.lettersInfo.item_combustivel}
                                        </span>
                                        <span className="tl lgm gr bol">
                                            {this.state.lettersInfo.item_km}KM
                                        </span>
                                    </div>
                                </div>
                            ) : (null)
                        }
                    </ModalBody>
                    {
                        this.state.lettersInfo ? (
                            <ModalFooter>
                                <Button className="btnora" onClick={this.additem}>Adicionar</Button>
                            </ModalFooter>
                        ) : (null)
                    }
                </Modal>

                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar Vendas</strong></p>
                            </div>
                            <div className="content-formulario">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cliente">Cliente </label>
                                            <div className="grupo-campo-input input-cad" id="cliente">
                                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                                <select
                                                    name="cliente"
                                                    placeholder="&nbsp;Selecione o Cliente comprador"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarCliente: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarCliente}
                                                >
                                                    <option value="NULL">Selecione a Cliente comprador</option>
                                                    {
                                                        this.state.clientes.map((cliente, id) => (
                                                            <option key={cliente.cliente_id} value={cliente.cliente_id}>{cliente.cliente_nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="usuario">Usuário </label>
                                            <div className="grupo-campo-input input-cad" id="usuario">
                                                <i className="fas fa-user-edit" id="iconUsuario"></i>
                                                <select
                                                    name="usuario"
                                                    placeholder="&nbsp;Selecione o Usuário vendedor"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarUsuario: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarUsuario}
                                                >
                                                    <option value="NULL">Selecione a Usuário vendedor</option>
                                                    {
                                                        this.state.usuarios.map((usuario, id) => (
                                                            <option key={usuario.usuario_id} value={usuario.usuario_id}>{usuario.usuario_nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grupo-input input-style-cad">
                                    <label htmlFor="valor">Itens </label>
                                    
                                    <Button onClick={this.togglemodal} className="btnora">Adicionar</Button>
                                    
                                    <div className="cardsItens">
                                        {
                                            this.state.options.map((e, index) => (
                                                <div className="itensx">
                                                    <div className="cropper">
                                                        <div className="killmoto" onClick={a => this.removeItem(index)}><i className="fas fa-times"></i></div>
                                                        <img src={e.itemfotos_foto} alt=""/>
                                                    </div>
                                                    <p>
                                                        {e.item_modelo}
                                                    </p>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="valor">Valor </label>
                                            <div className="grupo-campo-input input-cad" id="valor">
                                                <i className="fas fa-tag" id="iconValor"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarValor}
                                                    placeholder="&nbsp;Informe o Valor da venda"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarValor: event.target.value })}
                                                    required
                                                    
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="metodoPagamento">Método de Pagamento </label>
                                            <div className="grupo-campo-input input-cad" id="metodoPagamento">
                                                <i className="fas fa-hand-holding-usd" id="iconMetodoPagamento"></i>
                                                <select
                                                    name="metodoPagamento"
                                                    placeholder="&nbsp;Selecione o Método de Pagamento"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarMetodopagamento: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarMetodopagamento}
                                                >
                                                    <option value="NULL">Selecione o Método de Pagamento</option>
                                                    <option value="DINHEIRO">Dinheiro</option>
                                                    <option value="CREDITO">Cartão de Crédito</option>
                                                    <option value="DEBITO">Cartão de Débito</option>
                                                    <option value="CHEQUE">Cheque</option>
                                                    <option value="TRANSFERENCIA">Transferência Bancária</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="parcelas">Quantidade de Parcelas </label>
                                            <div className="grupo-campo-input input-cad" id="parcelas">
                                                <i className="fas fa-divide" id="iconParcelas"></i>
                                                <select
                                                    name="parcelas"
                                                    placeholder="&nbsp;Selecione o Nível do usuário"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarParcelas: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarParcelas}
                                                >
                                                    <option value="NULL">Selecione a Quantidade de Parcelas</option>
                                                    <option value="1">x1</option>
                                                    <option value="2">x2</option>
                                                    <option value="3">x3</option>
                                                    <option value="4">x4</option>
                                                    <option value="5">x5</option>
                                                    <option value="6">x6</option>
                                                    <option value="7">x7</option>
                                                    <option value="8">x8</option>
                                                    <option value="9">x9</option>
                                                    <option value="10">x10</option>
                                                    <option value="11">x11</option>
                                                    <option value="12">x12</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="entrada">Entrada </label>
                                            <div className="grupo-campo-input input-cad" id="entrada">
                                                <i className="fas fa-piggy-bank" id="iconEntrada"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe a Entrada da venda"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarEntrada: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarEntrada}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="desconto">Desconto</label>
                                            <div className="grupo-campo-input input-cad" id="desconto">
                                                <i className="fas fa-donate" id="iconDesconto"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Desconto da venda"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarDesconto: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarDesconto}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>           

                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarVenda} className="cadbtn">Editar</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default EditarVendasPage