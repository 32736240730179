import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

class RelatorioVendasPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modelosvei: [],
            anos: [],
            compras: [],
            vendas: [],
            vendasP: [],
            comprasP: [],
            meses: [],
            inicio: '',
            fim: '',
            dados: [],
            empresas: [],
			clientes: [],
			usuarios: [],
            itens: [],
            dadosF: [],
            pagina: 1, 
            quantidade_paginas: 1,
            vendedor: '',
            empresa: '',
            trocas:[],
            troca: '',
            modelo: '',
            clientFocus2: false,
            ano: '',
            veiculo: '',
            placa: '',
		};
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarCompras();
            this.apiListarModelos();
            this.apiListarAnos();
            this.apiListarVendas();
            this.apiListarEmpresas();
            this.apiListarClientes();
            this.apiListarUsuarios();
            this.apiListarItens();
            this.apiListarTrocas();
        }
    }

    // Modelos
    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    apiListarCompras = async event => {
		let res = await api_topmotos.get('/compras/all');
        this.setState({ compras: res.data, comprasP: res.data });
        this.data('', 1);
    }

    apiListarVendas = async event => {
        let res = await api_topmotos.get('/vendas/');

        console.log(res.data)
        
        res = res.data.filter(a => a.venda_cancelada != 1);
        res = res.filter(a => a.venda_usuario != null);

		this.setState({ 
            dados: res,
            dadosF: res,
            quantidade_paginas: Math.ceil(res.length / 10), 
        });
	}

    data = (tipo) =>{
        let data = new Date();
        var inicio = new Date(data.getFullYear(), data.getMonth(), '01');
        var fim = new Date(data.getFullYear(), data.getMonth(), '31');
    
        if(tipo == 1){
            this.filtroCompraData(inicio, fim)
        } else if(tipo == 2){
            this.filtroVendaData(inicio, fim)
        }
    }

    filtroVenda = async (veiculo, ano, modelo, inicio, fim, vendedor, empresa, placa) =>{
        let res = await this.state.dados

        if (placa) {
            res = await res.filter((compras) => {
                return compras.placa && compras.placa.toLowerCase().includes(placa.toLowerCase());
            })
        }

        if (veiculo) {
            res = await res.filter((compras) => {
                var data = compras.item_modelo + ' - ' + compras.placa
                return data.toLowerCase().includes(veiculo)
            })
        }

        if (ano) {
            res = await res.filter((compras) => {
                var data = compras.item_ano_fab;
                return data == ano
            })
        }

        if(inicio && fim){
            res = await this.state.dados.filter((dados) => {
                var data = dados.venda_admissaodata;
                
                return data >= inicio &&
                    data <= fim
            })
        }

        if(vendedor){
            res = await res.filter((dados) => {
                var data = dados.usuario_id;
                
                return data == vendedor
            })
        }

        if(empresa){
            res = await res.filter((dados) => {
                var data = dados.venda_empresa_id;
                return data == empresa
            })
        }

        if(modelo){
            res = await res.filter((compras) => {
                var data = compras.item_modelo;
                
                return data == modelo
            })
        }

        let tr = this.state.trocas.map((tr) => {return tr.venda})

        let count = 0;

        for(let i of tr){
            if(res.filter(a => a.venda_id == i).length > 0){
                count++
            }
        }

            this.setState({
                dadosF: res,
                quantidade_paginas: Math.ceil(res.length / 10),
                troca: count
            })
        
    }

    apiListarAnos = async event => {
		let res = await api_topmotos.get('/anosAll/group');
        console.log(res)
		this.setState({ anos: res.data });
    }
    
    apiListarEmpresas = async event => {
		let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
    }
    
    apiListarClientes = async event => {
		let res = await api_topmotos.get('/clientes/');
		this.setState({ clientes: res.data });
	}

	apiListarUsuarios = async event => {
		let res = await api_topmotos.get('/usuarios');
		this.setState({ usuarios: res.data });
	}

	apiListarItens = async event => {
		let res = await api_topmotos.get('/itens/');
		this.setState({ itens: res.data });

		let selectOptions = this.state.itens.map((item, id) => (
			{ value: item.item_id, label: item.item_modelo + " - Cor: " + item.cor }
		))

		this.setState({ options: selectOptions });
    }

    apiListarTrocas = async event => {
        let res = await api_topmotos.get('/trocas');
        res = res.data.filter(a => a.troca_cancelada != 1)

        let tr = res.map((tr) => {return tr.venda})

        let count = 0;

        for(let i of tr){
            if(this.state.dados.filter(a => a.venda_id == i).length > 0){
                count++
            }
        }

        this.setState({ trocas: res, troca: count });
    }
    
    
    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    pesquisar = () => {
        this.filtroVenda(this.state.veiculo, this.state.ano, this.state.modelo,this.state.inicio, this.state.fim, this.state.vendedor, this.state.empresa, this.state.placa)
    }

    configModelo = async (e) => {
        this.setState({ modelo: e });

        // let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))
        // if (c.length > 0) {
        //     this.setState({ modalCadastrarModelo: c[0].nome })
        // }
        // else {
        //     this.setState({ modalCadastrarModelo: e })
        // }
    }

    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-head">
                                <strong>
                                    <p>Relatório Vendas</p>
                                </strong>
                            </div>
                            <div className="relatorio-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="dataI">Data Início</label>
                                            <div className="grupo-campo-input input-cad" id="dataI">
                                                <i className="far fa-calendar-alt" id="iconDataI"></i>
                                                <input type="date" onChange={a => this.setState({inicio: a.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="dataF">Data Fim</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i className="far fa-calendar-alt" id="iconDataF"></i>
                                                <input type="date" id="dataF" onChange={a => {this.setState({fim: a.target.value})}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="vendedor">Vendedor</label>
                                            <div className="grupo-campo-input input-cad" id="vendedor">
                                                <i className="fas fa-user" id="iconVendedor"></i>
                                                <select onChange={a => this.setState({vendedor: a.target.value})}>
                                                    <option value="">Selecione um Vendedor</option>
                                                    { this.state.usuarios.map((user) => (
                                                        <option value={user.usuario_id}>{user.usuario_nome}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="placa">Placa</label>
                                            <div className="grupo-campo-input input-cad" id="placa">
                                                <i className="fas fa-car" id="iconPlaca"></i>
                                                <input type="text" placeholder="Buscar por Placa" onChange={e => this.setState({placa: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="empresa">Empresa</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i className="fas fa-building" id="iconEmpresa"></i>
                                                <select onChange={a => this.setState({empresa: a.target.value})}>
                                                    <option value="">Selecione a  Empresa</option>
                                                    { this.state.empresas.map((emp) => (
                                                        <option value={emp.empresa_id}>{emp.empresa_razaosocial}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="modelo" id="lblModelo">Modelo </label>
                                            <div className="grupo-campo-input input-cad" id="modelo">
                                                <i className="fas fa-asterisk" id="iconModelo"></i>
                                                <input
                                                        type="text"
                                                        placeholder="&nbsp;Informe o Modelo do item"
                                                        autoComplete="on"
                                                        onChange={event => this.configModelo(event.target.value)}
                                                        required
                                                        onFocus={a => this.setState({ clientFocus2: true })} 
                                                        onBlur={a => this.setState({ clientFocus2: false })}
                                                        value={this.state.modelo}

                                                />
                                            </div>
                                            <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                {
                                                        this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(this.state.modelo.toLowerCase())).map(e => (
                                                            <li onClick={a => this.setState({modelo: e.nome})}>{e.nome}</li>
                                                        ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                            <div className="grupo-input input-style-cad">
                                                <label htmlFor="empresa">Ano de Fabricação</label>
                                                <div className="grupo-campo-input input-cad" id="empresa">
                                                    <i className="fa fa-calendar" id="iconEmpresa"></i>
                                                    <select onChange={a => this.setState({ano: a.target.value})}>
                                                        <option value="">Selecione o Ano</option>
                                                        { this.state.anos.map((ano) => (
                                                            <option value={ano.ano}>{ano.ano}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="veiculo">Palavra Chave</label>
                                            <div className="grupo-campo-input input-cad" id="veiculo">
                                                <i className="fas fa-search" id="iconVeiculo"></i>
                                                <input type="text" placeholder="Buscar por palavra chave" onChange={e => this.setState({veiculo: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom: "30px", marginTop: "-20px"}}>
                                    <div className="col-md-3">
                                        <div className="grupo-campo-input input-cad">
                                            <label style={{height: '19px'}}></label>
                                            <div className="grupo-campo-input input-cad">
                                                <button className="btn-env btn-add" onClick={() => this.pesquisar()}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="txt-info">
                                            <p>Total de vendas</p>
                                            <p>{this.state.dadosF.length}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="txt-info">
                                            <p>Total de trocas</p>
                                            <p>{this.state.troca}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="txt-info">
                                            <p>Legenda</p>
                                            <div className="d-flex justify-content-start pendente">
                                                <p></p><p></p>
                                                <i className="fas fa-exclamation pr-2"></i>
                                                <p className="pendente">Pendente</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="info-box">
                                    <p>Total de Compras Realizadas</p>
                                    {this.state.comprasP.length}
                                </div>
                                <div className="info-box">
                                    <p>Total de Vendas Realizadas</p>
                                    {this.state.vendasP.length}
                                </div> */}
                                <div className="table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        ID
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Vendedor
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Cliente
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Itens
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Data
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Ações
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.state.dadosF.slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map((venda, id) => (
                                                    <tr key={venda.venda_id}>
                                                        <td className={venda.pendencia ? "pendente" : ''}>{venda.pendencia ? <i className="fas fa-exclamation pr-2"></i> : ''}{venda.venda_id}</td>
                                                        <td className={venda.pendencia ? "pendente" : ''}>{venda.usuario_nome}</td>
                                                        <td className={venda.pendencia ? "pendente" : ''}>{venda.cliente_nome}</td>
                                                        <td className={venda.pendencia ? "pendente" : ''}>{venda.item_modelo + ' - ' + venda.placa}</td>
                                                        {/* <td>{this.state.status.filter((y) => {return y.id == venda.venda_status}).map((teste)=>{ return teste.descricao})}</td> */}
                                                        <td className={venda.pendencia ? "pendente" : ''}>{moment(venda.venda_admissaodata).format("DD/MM/YYYY HH:mm")}</td>
                                                        <td className="td-acoes">
                                                            {<i className="fas fa-print" onClick={() => this.props.history.push("../vendas/detalhe/" + venda.venda_id)}></i>}
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                    <div className="form-footer">
                                        <span></span>
                                    </div>
                                </div>
                                {this.state.dadosF.length > 0 && <div style={{padding: '10px 0px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                    }}>
                                        {<i class="fas fa-angle-left arrows"></i>}
                                    </div>
                                    {
                                         [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                                (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                            ){
                                                return (
                                                    <div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
                                                    onClick={() => {
                                                        this.setState({pagina: index + 1})
                                                    }}>
                                                        {index + 1}
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null
                                            }
                                        })
                                    }
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                                    }}>
                                        {<i class="fas fa-angle-right arrows"></i>}
                                    </div>
                                </div>}
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default RelatorioVendasPage