import React from 'react';
import { api_topmotos } from '../../services/api';
import moment from "moment";


class PDFCompraPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            modelo: '',
            model: this.props.match.params.modelo,
            iten: [],
            pessoa: '',
            pessoas: [],
            obs: '',
            title: '@veiculo - @cliente'
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota === "/itens" || a.rota === "/compras/relatorio")
            if(res.length === 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }


    async componentDidMount(){
        // if(await this.verificarPermissao()){
        //     this.props.history.push('/dashboardcomercial')
        // } else{
            await this.apiListarModelo(this.props.match.params.modelo)
            await this.apiListarCompras(this.props.match.params.iten)
        // }

    }

    apiListarModelo = async (id) => {
        let {data} = await api_topmotos.post("/modelosdoc/unique", {id: id})
        this.setState({modelo: data[0].conteudo})   
    }

    apiListarCompras = async (id) => {
        let {data} = await api_topmotos.get("/compras/id/"+id)

        this.setState({obs: data[0].observacao_pag})

        var pessoa = data[0].pessoa;

        if(this.state.model === 9){
            await this.apiVeiculoId(data[0].item, pessoa);
        } else{
            await this.apiClienteId(pessoa);
            await this.apiVeiculoId(data[0].item, pessoa);
            await this.apiChecklist(data[0].item);
            await this.apiListarEmpresas(data[0].empresa);
            await this.apiListarMovimentacao(id);
        }

        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@valorCompra/g, parseFloat(data[0].valor_compra).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
        .replace(/@dataCompra/g, new Date(data[0].data_chegada).toLocaleString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric'}))
        .replace(/@horarioCompra/g, moment(data[0].hora_chegada, 'HH:mm:ss').format('HH:mm'))
        this.setState({modelo: modelo})   
        
        var tabelas = document.getElementsByTagName('table');

        for(var i = 0; i < tabelas.length; i++){
            tabelas[i].classList.add('table-black')
            tabelas[i].style.margin = '10px 0px';
        }

        await this.openPdf()
    }

    apiClienteId = async (id) =>{
        let {data} = await api_topmotos.get("/pessoas/id/" + id)

        let modelo = this.state.modelo;  
        let title = this.state.title;

        modelo = modelo.replace(/@nomePessoa/g, data[0].pessoa_nome)
        .replace(/@rgPessoa/g, data[0].pessoa_rg)
        .replace(/@cpfPessoa/g, data[0].pessoa_cpf_cnpj)
        .replace(/@ruaPessoa/g, data[0].endereco_logradouro)
        .replace(/@nPessoa/g, data[0].endereco_numero)
        .replace(/@cidadePessoa/g, data[0].endereco_cidade)
        .replace(/@estadoPessoa/g, data[0].endereco_estado)
        .replace(/@telPessoa/g, data[0].pessoa_telefone)
        .replace(/@celPessoa/g, data[0].pessoa_celular ? data[0].pessoa_celular : '' )
        .replace(/@cepPessoa/g, data[0].endereco_cep)
        .replace(/@BairroPessoa/g, data[0].endereco_bairro)
        .replace(/@emailPessoa/g, data[0].pessoa_email)

        title = title.replace(/@cliente/g, data[0].pessoa_nome)

        this.setState({modelo: modelo, title: title})   
        
    }

    apiVeiculoId = async (id, idpes) =>{
        let {data} = await api_topmotos.post("/itens/unique", {id: id})
        
        let modelo = this.state.modelo;  
        let title = this.state.title;

        if(data[0].terceira_pessoa){
            if(this.state.model === 9){
                let re = await api_topmotos.get('/pessoasT/id/' + data[0].terceira_pessoa);
                
                if(re.data[0].tipo === 'terceiro'){
                    modelo = modelo.replace(/@nomePessoa/g, re.data[0].nome)
                    .replace(/@rgPessoa/g, re.data[0].rg)
                    .replace(/@cpfPessoa/g, re.data[0].cpf)
                    .replace(/@ruaPessoa/g, re.data[0].logradouro)
                    .replace(/@nPessoa/g, re.data[0].numero)
                    .replace(/@cidadePessoa/g, re.data[0].cidade)
                    .replace(/@estadoPessoa/g, re.data[0].estado)
                    .replace(/@telPessoa/g, re.data[0].telefone)
                    .replace(/@cepPessoa/g, re.data[0].cep)
                    .replace(/@BairroPessoa/g, re.data[0].bairro)
    
                    this.setState({modelo: modelo, title: title}) 
                }

            } else{
                this.apiTerceiraPessoa(data[0].terceira_pessoa)
                modelo = modelo.replace(/@modoDeCompraL/g, 'X')
                .replace(/@modoDeCompraP/g, '')
            }
        } else{
            if(this.state.model === 9){
                let re = await api_topmotos.get("/pessoas/id/" + idpes)

                modelo = modelo.replace(/@nomePessoa/g, re.data[0].pessoa_nome)
                .replace(/@rgPessoa/g, re.data[0].pessoa_rg)
                .replace(/@cpfPessoa/g, re.data[0].pessoa_cpf_cnpj)
                .replace(/@ruaPessoa/g, re.data[0].endereco_logradouro)
                .replace(/@nPessoa/g, re.data[0].endereco_numero)
                .replace(/@cidadePessoa/g, re.data[0].endereco_cidade)
                .replace(/@estadoPessoa/g, re.data[0].endereco_estado)
                .replace(/@telPessoa/g, re.data[0].pessoa_telefone)
                .replace(/@celPessoa/g, re.data[0].pessoa_celular ? re.data[0].pessoa_celular : '' )
                .replace(/@cepPessoa/g, re.data[0].endereco_cep)
                .replace(/@BairroPessoa/g, re.data[0].endereco_bairro)
                .replace(/@emailPessoa/g, re.data[0].pessoa_email)

                title = title.replace(/@cliente/g, re.data[0].pessoa_nome)

                this.setState({modelo: modelo, title: title})   
            }
            this.apiPessoa(idpes)
            modelo = modelo.replace(/@modoDeCompraP/g, 'X')
            .replace(/@modoDeCompraL/g, '')
        }
        
        modelo = modelo.replace(/@marcaVeiculo/g, data[0].item_marca)
        .replace(/@especieVeiculo/g, data[0].especieNome)
        .replace(/@tipoVeiculo/g, data[0].item_tipo)
        .replace(/@modeloVeiculo/g, data[0].item_modelo)
        .replace(/@ruaCliente/g, data[0].endereco_logradouro)
        .replace(/@anoMVeiculo/g, data[0].ano_mod === 3200 ? '0Km' : data[0].ano_mod)
        .replace(/@anoFVeiculo/g, data[0].item_ano_fab)
        .replace(/@placaVeiculo/g, data[0].placa)
        .replace(/@kmVeiculo/g, data[0].item_km)
        .replace(/@chassiVeiculo/g, data[0].chassi)
        .replace(/@renavanVeiculo/g, data[0].renavam)
        .replace(/@estiloVeiculo/g, data[0].estilo)
        .replace(/@corVeiculo/g, data[0].cor)
        .replace(/@combVeiculo/g, data[0].combustivel)
        
        if (this.props.location.search.split("=")[1]) {
            modelo = modelo.replace(/@nomeVeiculo/g, "NOME DA MOTO: "+decodeURI(this.props.location.search.split("=")[1])+" - ")
        } else {
            modelo = modelo.replace(/@nomeVeiculo/g, "")
        }

        title = title.replace(/@veiculo/g, data[0].item_modelo)

        this.setState({modelo: modelo, title: title}) ;
        
    }

    apiListarEmpresas = async (id) => {
        let {data} = await api_topmotos.get("/empresas/id/" + id)
            
        let modelo = this.state.modelo;  
    
        modelo = modelo.replace(/@razao_socialEmpresa/g, data[0].empresa_razaosocial)
        .replace(/@cnpjEmpresa/g, data[0].empresa_cnpj)
        .replace(/@telEmpresa/g, data[0].empresa_telefone)
        .replace(/@ruaEmpresa/g, data[0].endereco_logradouro)
        .replace(/@nEmpresa/g, data[0].endereco_numero)
        .replace(/@bairroEmpresa/g, data[0].endereco_bairro)
        .replace(/@cidadeEmpresa/g, data[0].endereco_cidade)
        .replace(/@estadoEmpresa/g, data[0].endereco_estado)

        this.setState({modelo: modelo}) ;
    }

    openPdf = async () =>{
        if(this.state.model == 7){
            let div = document.querySelector(".w-100")
            div.classList.add('termo')
            document.title = "Compra " + this.state.title
        } else{
            document.title = 'Procuração ' + this.state.title
        }
        window.print();
    }

    apiChecklist = async (id) =>{
        let {data} = await api_topmotos.post("/checklistD/resposta/"+ id)

        let respostas = [];
    
        for(var i of data){
            respostas.push(
                `<p><strong>${i.pergunta.toUpperCase()}</strong> ${i.alternativa.toUpperCase()}${i.respostas_checklist_documento ? '(Obs: ' + i.respostas_checklist_documento + ')' : ''}</p>`
            )
        }
    
        respostas = respostas.join('');
        let modelo = this.state.modelo; 
    
        modelo = modelo.replace(/@respostasChecklist/g, respostas)
        this.setState({modelo: modelo})   
    }

    apiListarMovimentacao = async (id) =>{
        let {data} = await api_topmotos.get("/financeiro/compra/"+ id);

        await this.apiMovimentacoesId(id)

        await this.apiListarTranferencia(data)
    }

    apiListarTranferencia = async (dados) =>{
        let lista = [];
        let modelo = this.state.modelo; 

        
        if(this.state.obs){
            let obs = "<strong style='padding: 10px 0px;'>Obs</strong>: " + this.state.obs + "<br/>";
            lista.push(obs)
        }

        for(let i in dados){
            let {data} = await api_topmotos.get("/dadosBanc/id/"+ dados[i].id)

            if(data[0]){
                let transferencia = "<strong>Em transferência para a conta</strong>: " + data[0].banco + ' Ag: '+ data[0].agencia + " " + data[0].tipo_conta + ": " + data[0].conta;
                lista.push(transferencia)
            }
        }

        lista = lista.join('')

        if(lista){
            modelo = modelo.replace(/@transferencia/g, lista)
        } else{
            modelo = modelo.replace(/@transferencia/g, '')
        }
    
        this.setState({modelo: modelo})   
    }

    apiTerceiraPessoa = async (id) =>{
        let {data} = await api_topmotos.get('/pessoasT/id/' + id);

        let modelo = this.state.modelo;  
        modelo = modelo.replace(/@nomeTerceiro/g, data[0].nome.toUpperCase())
        .replace(/@cpfTerceiro/g, data[0].cpf)
        .replace(/@telefoneTerceiro/g, data[0].telefone)

        this.setState({modelo: modelo}) ;
    }

    apiPessoa = async (id) =>{
        let {data} = await api_topmotos.get("/pessoas/id/" + id)

        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@nomeTerceiro/g, data[0].pessoa_nome.toUpperCase())
        .replace(/@cpfTerceiro/g, data[0].pessoa_cpf_cnpj)
        .replace(/@telefoneTerceiro/g, data[0].pessoa_telefone)
        .replace(/@emailTerceiro/g, data[0].pessoa_email)

        this.setState({modelo: modelo})       
    }

    apiMovimentacoesId = async (id) =>{
        let {data} = await api_topmotos.get("/financeiro/compra/" + id);
        let res = await api_topmotos.get("/tParcelamento");
        let p = {}
        for (let i of res.data){
            p[`${i.mov}`] = i
        }

        let modelo = this.state.modelo; 

        if(data){

            let tabela = [];
            tabela.push('<table>')
            tabela.push(
                `<tr>
                    <th>Valor</th>
                    <th>Data de Vencimento</th>
                    <th>Tipo de Pagamento</th>
                </tr>`
            )
    
            for(var i of data){
                tabela.push(
                    `<tr>
                        <td>${parseFloat(i.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        ${p[i.id] ? ` <br/>Parcelado em ${p[i.id].parcelamento} de ${parseFloat(p[i.id].valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : '' }</td>
                        <td>${moment(i.dataVencimento).format("DD-MM-YYYY")}</td>
                        <td>
                            ${i.pagamento} <br />
                            ${i.pagamento === "Financiamento" ? i.banco_financiado : ''}
                        </td>
                    </tr>`
                )
            }
    
            tabela.push('</table>')
            tabela = tabela.join('')
    
            modelo = modelo.replace(/@movimentacao/g, tabela)
        } else{
            modelo = modelo.replace(/@movimentacao/g, '')
        }

        this.setState({modelo: modelo})   
    }

    render() {
        return (
            <>
            <section className="container d-flex align-item-center py-5 break--word">
                <div className="w-100">

                    <div dangerouslySetInnerHTML={{__html: this.state.modelo}} style={{height: "100%"}}>

                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default PDFCompraPage;
