import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class MovimentacaoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            contas: [],
            contasTabela: [],
            contasOperacoes: [],
            movimentacoes: [],
            movimentacoesTabela: [],
            categorias: [],

            conta: '',
            categoria: '',
            nomeConta: '',
            nomeCategoria: '',
            nomePagamento: '',

            arrow: '',
            arrowP: '',

            operacao: '',
            descricao: '',
            dataPagamento: null,
            dataVencimento: '',
            valor: '',
            status: '',
            contaId: '',
            admissao: '',
            alteracao: '',
            valorPago: '',
            banco_financiado: '',

            contas_receber: 0,
            contas_apagar: 0,
            contas_recebidas: 0,
            contas_pagas: 0,
            previsao: 0,

            lucro: 0,
            porcR: '',
            porcP: '',

            fim: '',
            inicio: '',

            tipoPagamento: [],
            tipoPag: '',
            tipo: '',

            dataSE: '',

            contasBancarias: [],
            contaB: '',
            dataCompetencia: '',

            repetirLancamento: '',
            meses: '',
            dataRepeticao: [],
            parcela_id: '',
            valorParcela: '',
            
            display: 'none',
            nConta: '',
            banco: '',
            agencia: '',
            tipoConta: '',
            dadosBanc: '',
            contaBanc: '',
            dataInicio: '',
            
            pagina: 1, 
            quantidade_paginas: 1,
            
            displayLoading: false,

            type: null,

            filterAP: <> 
                <option value="AP">A Pagar</option>
                <option value="P">Pago</option>
            </>,
            filterAR: <> 
                <option value="AR">A Receber</option>
                <option value="R">Recebido</option>    
            </>,

            filterCategoriasOpen: false, 
            filterCategorias: null,
            selectCategorias: [],

            tabelaSum: 0,
            tabelaSumPago: 0,
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            let resM = res.data.filter(a => a.rota == "/movimentacao/")

            if(resM.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    filterOperacao = async () => {
        if (this.props.match.params.id === "AP" || this.props.match.params.id === "AR") {
            this.changeOperacao(this.props.match.params.id, true)
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({type: null})
            this.setState({dataInicio: ''})
            this.setState({dataSE: ''})
            await this.listarCategorias();
            await this.listarMovimentacao();
            this.listarPagamento();
            this.listarContas();
            this.listarContasBancarias();
            await this.filterOperacao();
            this.sumValor();
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            await this.listarCategorias();
            await this.listarMovimentacao();
            this.listarPagamento();
            this.listarContas();
            this.listarContasBancarias();
            await this.filterOperacao();
            this.sumValor();
        }
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Listar todas as contas
    listarMovimentacao = async () =>{
        let res;
        if(this.props.match.params.id && this.props.match.params.id !== "AP" && this.props.match.params.id !== "AR"){
            res = await api_topmotos.get('/financeiro/conta/' + this.props.match.params.id);
        } else{
            res = await api_topmotos.get('/financeiro/');
        }

        this.setState({ movimentacoes: res.data.filter(mov => mov.mov_cancelada != 1) });
        this.setState({ movimentacoesTabela: res.data.filter(mov => mov.mov_cancelada != 1) });
        await this.contas();
        await this.lucro();
    }

    // Listar tipo pagamentos
    listarPagamento = async () =>{
        let res = await api_topmotos.get('/tipopag/');
        this.setState({ tipoPagamento: res.data });
    }

    // Listar Contas 
    listarContas = async () =>{
        let res = await api_topmotos.get('/contas/');
        this.setState({ contas: res.data });
    }

    // Listar Contas Bancárias
    listarContasBancarias = async () =>{
        let res = await api_topmotos.get('/contasBancarias/');
        this.setState({ contasBancarias: res.data });
    }

    openCategorias = () => {
        this.setState({ filterCategoriasOpen: !this.state.filterCategoriasOpen })
        this.setState({ filterCategorias: 
            <div className="body">
                <div style={{justifyContent: "center", flexWrap: "wrap", display: "flex"}}>
                    {this.state.categorias.map((cat, index) => (
                        <div className="grupo-input" style={{width: "33%"}}>
                            <label className="switch">
                                <input type="checkbox" onClick={() => this.changeCategorias(index)} defaultChecked={this.state.selectCategorias[index]} />
                                <span className="slider round"></span>
                            </label>
                            <label style={{marginLeft: '10px'}}>{cat.categoria}</label>
                        </div>
                    ))}
                </div>
            </div>
        });
    }

    changeCategorias = async (index) => {
        let select = this.state.selectCategorias;
        select[index] = !select[index];
        this.setState({ selectCategorias: select })
        this.changeData(this.state.dataSE);
        this.sumValor();
    }

    // Listar Categorias 
    listarCategorias = async () =>{
        let res = await api_topmotos.get('/categoriasAll/');
        this.setState({ categorias: res.data });
        this.setState({ selectCategorias: res.data.map(() => true) });
        this.setState({ filterCategorias: 
            <div className="body">
                <div style={{justifyContent: "center", flexWrap: "wrap", display: "flex"}}>
                    {this.state.categorias.map((cat, index) => (
                        <div className="grupo-input" style={{width: "33%"}}>
                            <label className="switch">
                                <input type="checkbox" onClick={() => this.changeCategorias(index)} defaultChecked={this.state.selectCategorias[index]} />
                                <span className="slider round"></span>
                            </label>
                            <label style={{marginLeft: '10px'}}>{cat.categoria}</label>
                        </div>
                    ))}
                </div>
            </div>
        });
    }

    // Arrumar data para filtragem 
    contas = async (dataS)  =>{
        if(dataS){
            var partesData = dataS.split("-");
            var fim = new Date(partesData[0], partesData[1] - 1, partesData[2]);
            var partesInicio = this.state.dataInicio.split("-")
            var inicio = new Date(partesInicio[0], partesInicio[1] - 1, partesInicio[2]);
            
            this.setState({inicio: inicio})
            this.setState({fim: fim})
            

            await this.receberContas( inicio, fim)
            await this.pagarContas( inicio, fim)
            await this.contasData( inicio, fim)
            await this.lucro()
        } else{
            let data = new Date();
            let inicio = new Date(data.getFullYear(), data.getMonth(), '01');
            let fim = new Date(data.getFullYear(), data.getMonth(), '31');
            this.setState({inicio: inicio})
            this.setState({fim: fim})

            await this.receberContas(inicio, fim)
            await this.pagarContas(inicio, fim)
            await this.contasData(inicio, fim)
            await this.lucro()
        }
        this.sumValor();

    }

    // Filtro de contas com status (A) Receber e porcentagem
    receberContas = async (inicio, fim) =>{
        let array_contas = await this.state.movimentacoes.filter((dados) => {
            var strData = moment(dados.dataVencimento).format('DD-MM-YYYY');
            var partesData = strData.split("-");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            
            return data >= inicio &&
                data <= fim
        })

        let contasR = array_contas.filter((dados)=>{
            return dados.conta_status == 'AR' || dados.conta_status == 'R'
        })

        let recebido = contasR.filter((dados)=>{
            return dados.conta_status == 'R'
        })

        this.setState({porcR: recebido.length == 0 ? '0%' : recebido.length * 100 / contasR.length + '%'})

        var total = contasR.reduce((total, numero) => total + numero.valor, 0);
        total = total.toFixed(2)

        var totalR = recebido.reduce((total, numero) => total + numero.valor, 0);
        totalR = totalR.toFixed(2)

        this.setState({
            contas_receber: total,
            contas_recebidas: totalR
        })
    }

    // Filtro de contas com status (A) Pagar e porcentagem
    pagarContas = async (inicio, fim)  =>{
        
        let array_contas = this.state.movimentacoes.filter((dados) => {
            var strData = moment(dados.dataVencimento).format('DD-MM-YYYY');
            var partesData = strData.split("-");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            
            return data >= inicio &&
                data <= fim 
        })

        let contasP = array_contas.filter((dados)=>{
            return dados.conta_status == 'AP' || dados.conta_status == 'P'
        })

        let pago = contasP.filter((dados)=>{
            return dados.conta_status == 'P'
        })

        this.setState({porcP: pago.length == 0 ? '0%' : pago.length * 100 / contasP.length + '%'})

        var total = contasP.reduce((total, numero) => total + numero.valor, 0);
        total = total.toFixed(2)

        var totalP = pago.reduce((total, numero) => total + numero.valor, 0);
        totalP = totalP.toFixed(2)

        this.setState({
            contas_apagar: total,
            contas_pagas: totalP
        })
    }

    // Conta para descobrir o lucro
    lucro = () =>{
        this.setState({lucro: this.state.contas_recebidas - this.state.contas_pagas})
        this.setState({previsao: this.state.contas_receber - this.state.contas_apagar})
        
        this.state.lucro > 0 ? this.setState({arrow: 'fas fa-arrow-up positivo'}) : this.setState({arrow: 'fas fa-arrow-down negativo'})
        this.state.previsao > 0 ? this.setState({arrowP: 'fas fa-arrow-up positivo'}) : this.setState({arrowP: 'fas fa-arrow-down negativo'})

    }

    // Alterar tabela 
    contasData = async (inicio, fim) =>{
        let filters = this.state.selectCategorias.map((select, index) => {
            return {cat: this.state.categorias[index].categoria, select: select};
        }).filter((cat) => {
            return cat.select;
        }).map((filter) => {
            return filter.cat;
        });

        let array_contas = await this.state.movimentacoes.filter((dados) => {
            var strData = moment(dados.dataVencimento).format('DD-MM-YYYY');
            var partesData = strData.split("-");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            
            return data >= inicio &&
                data <= fim
        }).filter((movimentacao) => {
            return filters.includes(movimentacao.cat_nome) || movimentacao.cat_nome == null;
        });

        if (this.state.type) {
            array_contas = array_contas.filter((conta) => {
                return conta.conta_status === this.state.type;
            })
        }

        this.setState({ movimentacoesTabela: array_contas, quantidade_paginas: Math.ceil(array_contas.length / 10), pagina: 1 });
    }

    // Selecionar conta por ID
    listarContaId = async (id) =>{
        let {data} = await api_topmotos.get("/financeiro/id/" + id)
        
        var valor = data[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        var real = valor.replace('R$', '')

        if(data[0].valor_pago){
            var valor2 = data[0].valor_pago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            var real2 = valor2.replace('R$', '')
            this.setState({valorPago: real2})
        } else{
            this.setState({valorPago: null})
        }

        var conta = this.state.contasBancarias.filter(a => a.id == data[0].contaB_id)

        this.setState({
            banco_financiado: data[0].banco_financiado ? data[0].banco_financiado : null,
            contaId: data[0].id,
            descricao: data[0].descricao,
            admissao: data[0].dataAdmissao ? moment(data[0].dataAdmissao).format('YYYY-MM-DD') : null,
            alteracao: data[0].dataAtualizacao ? moment(data[0].dataAtualizacao).format('YYYY-MM-DD') : null,
            dataVencimento: data[0].dataVencimento ? moment(data[0].dataVencimento).format('YYYY-MM-DD') : null,
            dataPagamento: data[0].dataPagamento ? moment(data[0].dataPagamento).format('YYYY-MM-DD') : null,
            valor: real,
            contaBanc: conta.length > 0 ? conta[0].descricao : '',
            tipo: data[0].tipo_pagamento_id,
            status: data[0].conta_status,
            conta: data[0].contas_id,
            categoria: data[0].categorias_id,
            contaB: data[0].contaB_id,
            dataCompetencia: data[0].dataCompetencia ? moment(data[0].dataCompetencia).format('YYYY-MM-DD') : null,
        })

        let dados = await api_topmotos.get("/dadosBanc/id/"+ data[0].id)

        if(dados.data[0]){
            this.setState({
                dadosBanc: dados.data[0].id,
                nConta: dados.data[0].conta,
                banco: dados.data[0].banco,
                agencia: dados.data[0].agencia,
                tipoConta: dados.data[0].tipo_conta,
                display: 'block'
            }) 
        } else{
            this.setState({
                display: 'none',
                agencia: '',
                dadosBanc: '',
                nConta: '',
                banco: '',
                agencia: '',
                tipoConta: '',
            }) 
        }

        if(this.state.tipo){
            var tipo = this.state.tipo
            var filter = this.state.tipoPagamento.filter((dados)=>{
                return dados.id == tipo
            })
            
            this.setState({nomePagamento: filter[0].tipo_pagamento});
        }

        if(this.state.conta){
            var tipo = this.state.conta
            var filter = this.state.contas.filter((dados)=>{
                return dados.id == tipo
            })
            
            this.setState({nomeConta: filter[0].descricao});
        }

        if(this.state.categoria){
            var tipo = this.state.categoria
            var filter = this.state.categorias.filter((dados)=>{
                return dados.id == tipo
            })
            
            this.setState({nomeCategoria: filter[0].categoria});
        }

    }

    // Open input-grupo caso tenha (A) Receber/Pagar
    status = (e, input) =>{
        this.setState({status: e})

        if(input == 1){
            var dataPag = document.getElementById('dataPag');
            var pago = document.getElementById('grupo-pago');
    
            if( e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
    
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: null})
                this.setState({valorPago: null})
            }
        } else{
            var dataPag = document.getElementById('dataPag2');
            var pago = document.getElementById('grupo-pago2');

            if(e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: '', valorPago:''})
            }
        }

    }

    // Mascára real R$00,00
    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valor: real})
    }

    // Mascára real R$00,00
    mascaraRealPago = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorPago: real})
    }

    // Inserir conta
    salvar = async () => {

        const {
            status,
            descricao,
            tipo,
            conta,
            valor,
            dataVencimento,
            categoria
        } = this.state

        await this.repetirLancamento();

        let parcela_id;

        if(this.state.repetirLancamento == 2){
            await api_topmotos.post('/parcelamento/create', {
                admissaodata: this.getTimestamp()
            }).then(res => {
                this.setState({parcela_id: res.data.returnId})
            })
        }

        var inputs = [
            [status, 'status', 'iconStatus', 'lblStatus'],
            [descricao, 'desc', 'iconDesc', 'lblDesc'],
            [tipo, 'pag', 'iconPag', 'lblPag'],
            [conta, 'centro', 'iconCentro', 'lblCentro'],
            [valor, 'valor', 'iconValor', 'lblValor'],
            [dataVencimento, 'venc', 'iconVenc', 'lblVenc'],
            [categoria, 'plano', 'iconPlano', 'lblPlano']
        ]

        let responder = 0

        for(let i = 0; i < inputs.length; i++){
            if(inputs[i][0] == '')
                responder++
        }

        if(responder == 0){
            var real = this.state.valor.replace('.', '');
            real = real.replace(',', '.');

            if(this.state.valorPago){
                var realPago = this.state.valorPago.replace('.', '');
                realPago = realPago.replace(',', '.');
            }

            try {
                
                this.toggleLoading(); 

                if(this.state.repetirLancamento){
                    for(let i in this.state.dataRepeticao){
                        await api_topmotos.post('/financeiro/create', {
                            descricao: this.state.descricao,
                            dataVencimento: this.state.dataRepeticao[i],
                            dataPagamento: this.state.dataPagamento,
                            dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                            banco: this.state.contaB ? this.state.contaB : null,
                            valor: this.state.repetirLancamento == 2 ? this.state.valorParcela : real,
                            valorPago: realPago,
                            tipoPagamento: this.state.tipo,
                            status: this.state.status,
                            admissaodata: this.getTimestamp(),
                            cat_id: this.state.categoria,
                            conta_id: this.state.conta,
                            parcela_id: this.state.parcela_id ? this.state.parcela_id : null
                        }).then(res => {
                            if(this.state.tipoPag && this.state.dataRepeticao.length == (parseInt(i)+1)){
                                this.apiCadastrarDadosBanc(res.data.returnId)
                            }
                        });
                    }
                } else{
                    await api_topmotos.post('/financeiro/create', {
                        descricao: this.state.descricao,
                        dataVencimento: this.state.dataVencimento,
                        dataPagamento: this.state.dataPagamento,
                        dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                        banco: this.state.contaB ? this.state.contaB : null,
                        valor: real,
                        valorPago: realPago,
                        tipoPagamento: this.state.tipo,
                        status: this.state.status,
                        admissaodata: this.getTimestamp(),
                        cat_id: this.state.categoria,
                        conta_id: this.state.conta,
                        parcela_id: null
                    }).then(res => {
                        if(this.state.tipoPag){
                            this.apiCadastrarDadosBanc(res.data.returnId)
                        }
                    });
                }

                await this.listarMovimentacao();
                await this.filterOperacao();
                this.toggleModalCadastrar();
                this.limparState();

                
                this.toggleLoading();
            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
                this.log(this.state.error);
            }

        } else{
            for(let i = 0; i < inputs.length; i++){
                
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #ddd; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #bbb; transition: 0.2s;";
                    inputNome.style.cssText = "color: #777; transition: 0.2s;";
                }
            }
        }
    }

    apiCadastrarDadosBanc = async (id) =>{
        try{

            await api_topmotos.post('/dadosBanc/create', {
                banco: this.state.banco,
                agencia: this.state.agencia,
                conta: this.state.nConta,
                tipo: this.state.tipoConta,
                mov: id
            })

            this.setState({display: 'none'})
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/dadosBanc/create)" });
            this.log(this.state.error);
        }
    }

    // Alterar conta
    alterar = async (id) => {
        var inputs = [
            this.state.descricao,
            this.state.dataVencimento,
            this.state.valor,
            this.state.tipoPagamento,
            this.state.status
        ]

        let responder = 0

        for(let i = 0; i < inputs.length; i++){
            if(inputs[i] == '')
                responder++
        }

        if(responder == 0){
            var real = this.state.valor.replace('.', '');
            real = real.replace(',', '.');
            if(this.state.valorPago){
                var real2 = this.state.valorPago.replace('.', '');
                real2 = real2.replace(',', '.');
            }

            try {
                
                this.toggleLoading();

                await api_topmotos.put('/financeiro/update/id/' + id, {
                    id: id,
                    descricao: this.state.descricao,
                    dataVencimento: this.state.dataVencimento,
                    dataPagamento: this.state.dataPagamento == '' ? null : this.state.dataPagamento,
                    valor: parseFloat(real),
                    tipoPagamento: this.state.tipo,
                    status: this.state.status,
                    alteracaodata: this.getTimestamp(),
                    valor_pago: real2 ? parseFloat(real2) : null,
                    conta_id: this.state.conta,
                    cat_id : this.state.categoria,
                    dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                    banco: this.state.contaB ? this.state.contaB : null

                })

                await this.listarMovimentacao();

                if(this.state.dadosBanc){
                    await this.apiAlterarDadosBanc(this.state.dadosBanc)
                } else{
                    this.apiCadastrarDadosBanc(id)
                }

                await this.filterOperacao();
                this.changeData(this.state.dataSE);
                
                this.toggleLoading();

            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/update)" });
                this.log(this.state.error);
            }

        } else{
            // Responda todos os inputs
        }
        this.setState({modalAlterar: false})
        this.limparState()
    }

    apiAlterarDadosBanc = async (id) =>{
        try{
            await api_topmotos.put('/dadosBanc/update/' + id, {
                banco: this.state.banco,
                agencia: this.state.agencia,
                conta: this.state.nConta,
                tipo: this.state.tipoConta
            })

            this.setState({display: 'none'})
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/dadosBanc/create)" });
            this.log(this.state.error);
        }
    }

    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.delete('/financeiro/delete/id/' + this.state.modalDeletarId);
            await this.listarMovimentacao();
            await this.filterOperacao();
            this.changeData(this.state.dataSE);
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeito/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Contas deletado com sucesso!' });
    }

    // Limpar todos os states
    limparState = () =>{
        this.setState({
            descricao: '',
            dataVencimento: '',
            dataPagamento: '',
            valor: '',
            valorPago: '',
            tipo: '',
            status: '',
            meses: '',
            repetirLancamento: '',
            dataRepeticao: [],
            parcela_id: '',
            conta: '',
            categoria: ''
        })
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarContaId(id);

        await this.setState({modalAlterar: true})
        
        if(this.state.dataPagamento != '' && this.state.dataPagamento != null){
            document.getElementById('dataPag2').style.display = 'block'
            document.getElementById('grupo-pago2').style.display = 'block'
        } else{
            document.getElementById('dataPag2').style.display = 'none'
            document.getElementById('grupo-pago2').style.display = 'block'
        }

    }

    // Abrir modal detalhes
    OpenModalView = (id) => {
        this.listarContaId(id)
        this.setState({modalDetalhes: true});
    }

    // Abrir modal deletar
    OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/financeiro/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
	}

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Off/On modal alterar
    toggleModalAlterar = () => {
        this.setState({ modalAlterar: !this.state.modalAlterar, display: 'none' });
        this.limparState()
    }

    // Off/On modal detalhes
    toggleModalDetalhes = () => {
		this.setState({ modalDetalhes: !this.state.modalDetalhes });
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }
    
    formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }
    
    // Off/On modal alterar
    corStatus = (status) =>{

        var status_repla = status.toLowerCase(); 

        return (
            <div className="d-flex w-100">
                <div class={"tag-status tag-" + status_repla}>
                </div>
            </div>
        )
        
    }

    // Barra porcentagem
    barra = () =>{
        let porc1 = this.state.porcP.replace('%','')
        let porc2 = this.state.porcR.replace('%','')

        if(Math.round(porc1) > Math.round(porc2)){
            return(
                <>
                    <div id="bar-pagar" style={{width: this.state.porcP}}></div>
                    <div id="bar-receber" style={{width: this.state.porcR}}></div>
                </>
            )
        } else{
            return(
                <>
                    <div id="bar-receber" style={{width: this.state.porcR}}></div>
                    <div id="bar-pagar" style={{width: this.state.porcP}}></div>
                </>
            )
        }
    }

    // Mudar data 
    changeData = (e) =>{
        this.contas(e);
    }

    // mudar operação
    changeOperacao = (e, force=false) =>{
        if(e === ""){
            let inicio = moment(this.state.inicio).format('YYYY-MM-DD');
            let fim =  moment(this.state.fim).format('YYYY-MM-DD');
            this.contas(inicio, fim)
        } else if (force || this.state.type === null){
            this.setState({ type: e })
            let contas = this.state.movimentacoes.filter((dados)=>{
                var strData = moment(dados.dataVencimento).format('DD-MM-YYYY');
                var partesData = strData.split("-");
                var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);

                return dados.conta_status === e && data >= this.state.inicio && data <= this.state.fim
            })
    
            this.setState({movimentacoesTabela: contas, quantidade_paginas: Math.ceil(contas.length / 10), pagina: 1})
        }
    }

    repetirLancamento = async () =>{
        var date = new Date();

        var mes;
        var meses = [];
        var anos = 0;
        var dia = moment(this.state.dataVencimento).format('DD');
        var ano = parseInt(moment(this.state.dataVencimento).format('YYYY'));

        for (let i = 0; i < this.state.meses; i++){
            mes = parseInt(moment(this.state.dataVencimento).format('MM')) + i;

            if(mes%12 == 0){
                anos++;
                mes = `${ano + anos - 1}-12`
            } else{
                if(mes > 12){
                    mes = mes - (12 * anos)
                    mes = `${ano + anos}-${mes}`
                } else{
                    mes = `${ano + anos}-${mes}`
                }
            }

            meses.push(mes + '-' + dia)
    
        }

        this.setState({dataRepeticao: meses})

    }

    valorParcela = async (meses) =>{
        const {valor, repetirLancamento} = this.state

        if(repetirLancamento == 2){
            if(meses && valor){
                var real = valor.replace('.', '');
                real = real.replace(',', '.');
    
                this.setState({valorParcela: real / meses})
            }
        } else{
            this.setState({valorParcela: ''})
        }
    }

    verificarTransferencia = (e) =>{
        let transferencia = this.state.tipoPagamento.filter(y => y.id == e)
        if(transferencia[0].dados_bancarios == 1){
            this.setState({
                display: 'block',
                tipoPag: true
            })
        } else{
            this.setState({
                tipoPag: false,
                display: 'none',
                nConta: '',
                banco: '',
                agencia: '',
                tipoConta: ''
            }) 
        }
    }

    toggleLoading = () =>{
        this.setState({ displayLoading: !this.state.displayLoading });
    }

    sumValor = async () => {  
        var sum = 0, sumPago = 0;
        for (var i in this.state.movimentacoesTabela) {
            sum += this.state.movimentacoesTabela[i].valor;
            sumPago += this.state.movimentacoesTabela[i].valor_pago;
        }
        this.setState({tabelaSum: sum, tabelaSumPago: sumPago});
    }

    openPrint = () => {
		window.print();
	}

    render() {
        return (
            <>

                <div className="overlay" style={{display: this.state.displayLoading ? 'flex' : 'none'}}>
                    <div className="modal__loading">
                        <img src={require("../../imgs/loading.gif")} alt=""/>
                        <p>Loading</p>
                    </div>
                </div>
                
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Analise Geral</p>
                                    <button className="btn-add" onClick={() => this.setState({modalCadastrar: true})}>Adicionar movimentação</button>
                                </div>

                                <div className="body">
                                    <div>
                                        <div className="d-flex align-center line-separa">
                                                <div className="indicadores">
                                                    <i class="fas fa-dollar-sign" id="receber"></i>
                                                    <div>
                                                        <p>Receitas</p>
                                                        <p>{parseFloat(this.state.contas_receber).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                <div className="indicadores">
                                                    <i class="fas fa-dollar-sign" id="pagar"></i>
                                                    <div>
                                                        <p>Despesas</p>
                                                        <p>{parseFloat(this.state.contas_apagar).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                
                                                <div className="indicadores">
                                                    <i class="fas fa-dollar-sign" id="previsao"></i>
                                                    <div>
                                                        <p>Previsão</p>
                                                        <p>{parseFloat(this.state.previsao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<i className={this.state.arrowP}></i></p>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="d-flex mt-2 justify-content-start">
                                                <div className="indicadores">
                                                    <i class="fas fa-dollar-sign" id="recebido"></i>
                                                    <div>
                                                        <p>Recebido</p>
                                                        <p>{parseFloat(this.state.contas_recebidas).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                <div className="indicadores">
                                                    <i class="fas fa-dollar-sign" id="pago"></i>
                                                    <div>
                                                        <p>Pago</p>
                                                        <p>{parseFloat(this.state.contas_pagas).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                <div className="indicadores">
                                                    <i class="fas fa-dollar-sign" id="lucro"></i>
                                                    <div>
                                                        <p>Saldo</p>
                                                        <p>{parseFloat(this.state.lucro).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<i className={this.state.arrow}></i></p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label htmlFor="dataI">Data Início</label>
                                                <div className="grupo-campo-input input-cad" id="dataI">
                                                    <i className="far fa-calendar-alt" id="iconDataI"></i>
                                                    <input 
                                                        value={this.state.dataInicio}
                                                        type="date"
                                                        onChange={event => {this.setState({dataInicio: event.target.value})}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label htmlFor="dataF">Data Fim</label>
                                                <div className="grupo-campo-input input-cad" id="dataF">
                                                    <i className="far fa-calendar-alt" id="iconDataF"></i>
                                                    <input 
                                                        value={this.state.dataSE}
                                                        type="date"
                                                        onChange={event => {this.changeData(event.target.value); this.setState({dataSE: event.target.value})}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="barra-porcentagem">
                                    <div className="barra-cheia">
                                        {this.barra()}
                                    </div>
                                </div>

                                {this.props.match.params.id || !this.state.filterCategoriasOpen ? null : this.state.filterCategorias}
                            </div>
                            <div className="relatorio-titulo">
                                <p>Tabela de contas</p>
                                {this.props.match.params.id ? <div>
                                <select value={this.state.type || ""} onChange={event => this.changeOperacao(event.target.value, true)}>
                                        {this.props.match.params.id === "AP" ? this.state.filterAP
                                        : this.props.match.params.id === "AR" ? this.state.filterAR
                                        : <> 
                                            <option value="">Todos</option>
                                            {this.state.filterAP} 
                                            {this.state.filterAR}
                                        </>}
                                    </select>
                                </div>:<div>
                                    <p style={{cursor: 'pointer'}} onClick={() => this.openCategorias()}>Filtros</p>
                                </div>}                              
                            </div>
                            <div className="form-head">
                                <span></span>
                                <div className="td-acoes" style={{width: '70px'}}>
                                    <i className="fas fa-print" onClick={() => this.openPrint()}></i>
                                </div>
						    </div>
                            <div className="table">
                                <table className="print-table mov" style={{display: "none"}}>
                                    <p style={{textAlign: "center", fontSize: "15px", margin: "10px"}}>
                                        Movimentações
                                    </p>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Operação
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Placa
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Descrição
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Plano de Contas
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Tipo Pagamento
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Data de Vencimento
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.movimentacoesTabela.map((mov) => (
                                                <tr key={mov.id}>
                                                    <td>{this.corStatus(mov.conta_status)}</td>
                                                    <td>{mov.placa || null}</td>
                                                    <td>{mov.descricao}</td>
                                                    <td>{mov.cat_nome}</td>
                                                    <td>{mov.pagamento}</td>
                                                    <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                    <td>{mov.valor ? mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <table className="no-print">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Operação
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Placa
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Descrição
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Plano de Contas
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Tipo Pagamento
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Data de Vencimento
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor Previsto
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor Pago
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.movimentacoesTabela.slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map((mov) => (
                                                <tr key={mov.id}>
                                                    <td>{this.corStatus(mov.conta_status)}</td>
                                                    <td>{mov.placa || null}</td>
                                                    <td>{mov.descricao}</td>
                                                    <td>{mov.cat_nome}</td>
                                                    <td>{mov.pagamento}</td>
                                                    <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                    <td>{mov.valor ? mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                                    <td>{mov.valor_pago ? mov.valor_pago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-eye" onClick={() => this.OpenModalView(mov.id)}></i>&nbsp;
                                                        <i className="fas fa-pen" onClick={() => this.OpenModalAlteracao(mov.id)}></i>&nbsp;
                                                        <i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(mov.id)}}></i>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-footer">
                            {this.state.movimentacoesTabela.length > 0 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff'}}>
                                <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                    this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                }}>
                                    {<i class="fas fa-angle-left arrows"></i>}
                                </div>
                                {
                                    [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                        if( (this.state.pagina === 1 && index + 1 < 4) || 
                                            (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                            (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                        ){
                                            return (
                                                <div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
                                                onClick={() => {
                                                    this.setState({pagina: index + 1})
                                                }}>
                                                    {index + 1}
                                                </div>
                                            )
                                        }
                                        else{
                                            return null
                                        }
                                    })
                                }
                                <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                    this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                                }}>
                                    {<i class="fas fa-angle-right arrows"></i>}
                                </div>
                            </div>}
                                <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									Valor Previsto: {parseFloat(this.state.tabelaSum)
									.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    <span><b>&nbsp;&nbsp;|&nbsp;&nbsp;</b></span>
                                    Valor Pago: {parseFloat(this.state.tabelaSumPago)
									.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
								</div>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                
                    {/* MOVIMENTAÇÃO */}
                    {/* Modal de Cadastro */}
                    <Modal
					isOpen={this.state.modalCadastrar}
					toggle={this.toggleModalCadastrar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Movimentações</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="status" id="lblStatus">Status *</label>
                                            <div className="grupo-campo-input input-cad" id="status">
                                            <i className="fas fa-comment-dollar" id="iconStatus"></i>
                                                <select onChange={event => this.status(event.target.value, 1)}> 
                                                    <option value="">Selecione</option>
                                                    {this.props.match.params.id ? 
                                                        this.props.match.params.id === "AP" ?
                                                        (<>
                                                            <option value="AP">Pagar</option>
                                                            <option value="P">Pago</option>
                                                        </>)
                                                        : (<>
                                                            <option value="AR">Receber</option>
                                                            <option value="R">Recebido</option>
                                                        </>)                             
                                                    : (<>
                                                            <option value="AP">Pagar</option>
                                                            <option value="P">Pago</option>
                                                            <option value="AR">Receber</option>
                                                            <option value="R">Recebido</option>
                                                        </>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="desc" id="lblDesc">Descrição *</label>
                                            <div className="grupo-campo-input input-cad" id="desc">
                                            <i class="fas fa-receipt" id="iconDesc"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Descreva as informações sobre a conta"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ descricao: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblPag">Tipo de pagamento *</label>
                                            <div className="grupo-campo-input input-cad" id="pag">
                                                <i class="fas fa-dollar-sign"  id="iconPag"></i>
                                                <select onChange={event => {this.setState({ tipo: event.target.value }); this.verificarTransferencia(event.target.value)}}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.tipoPagamento.map((tipo) => (
                                                        <option value={tipo.id}>{tipo.tipo_pagamento}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label  id="lblCentro">Centro de Custos *</label>
                                            <div className="grupo-campo-input input-cad" id="centro">
                                                <i class="fas fa-dollar-sign"  id="iconCentro"></i>
                                                <select onChange={event => this.setState({ conta: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contas.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblValor">Valor *</label>
                                            <div className="grupo-campo-input input-cad" id="valor">
                                            <i class="fas fa-dollar-sign" id="iconValor"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valor}
                                                    onChange={event => this.mascaraReal(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="grupo-pago">
                                            <label>Valor Pago/Recebido</label>
                                            <div className="grupo-campo-input input-cad">
                                            <i class="fas fa-dollar-sign"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valorPago}
                                                    onChange={event => this.mascaraRealPago(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad d-flex justify-content-start">
                                            <label htmlFor="" id="lblRepetirLancamento">Repetir Lançamento</label>
                                            <div className="obrigatorio">
                                                <input type="radio" name="repetirLancamento" value="1" id="fixo" onChange={event => {this.setState({repetirLancamento: event.target.value, meses: '', valorParcela: ''})}}/>
                                                <label htmlFor="fixo">Fixo</label>
                                            </div>
                                            <div className="obrigatorio">
                                                <input type="radio" name="repetirLancamento" value="2" id="parcela" onChange={event => {this.setState({repetirLancamento: event.target.value, meses: '', valorParcela: ''})}}/>
                                                <label htmlFor="parcela">Parcelamento</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{display: this.state.repetirLancamento ? 'block' : 'none'}}>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Quantos meses? *</label>
                                            <div className="grupo-campo-input input-cad">
                                                <input
                                                    type="number"
                                                    autoComplete="on"
                                                    value={this.state.meses}
                                                    onChange={event => {this.setState({ meses: event.target.value }); this.valorParcela(event.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p id="valor-mensal" style={{display: this.state.valorParcela ? 'block' : 'none'}}>
                                            <i class="fas fa-info-circle"></i>
                                             {this.state.meses} vezes de {(this.state.valorParcela).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblVenc">Data de vencimento *</label>
                                            <div className="grupo-campo-input input-cad" id="venc">
                                                <i class="far fa-calendar-alt" id="iconVenc"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataVencimento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="dataPag">
                                            <label>Data de pagamento</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataPagamento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblPlano">Plano de contas *</label>
                                            <div className="grupo-campo-input input-cad" id="plano">
                                                <i class="fas fa-dollar-sign" id="iconPlano"></i>
                                                <select onChange={event => this.setState({ categoria: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.categorias.map((cat) => (
                                                        <option value={cat.id}>{cat.categoria}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Conta Bancária</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select onChange={event => this.setState({ contaB: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contasBancarias.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Data de competência </label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataCompetencia: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div style={{display: this.state.display}}>
                                    <p class="subtitle-form">Dados bancários</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblBanco">Banco *</label>
                                                <div className="grupo-campo-input input-cad" id="banco" >
                                                    <i class="fas fa-dollar-sign" id="iconBanco"></i>
                                                    <input 
                                                        onChange={event => this.setState({banco: event.target.value})} 
                                                        placeholder="&nbsp;Informe o Banco" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblAgencia">Agência *</label>
                                                <div className="grupo-campo-input input-cad" id="agencia" >
                                                    <i class="fas fa-dollar-sign" id="iconAgencia"></i>
                                                    <input 
                                                        onChange={event => this.setState({agencia: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblNConta">Conta *</label>
                                                <div className="grupo-campo-input input-cad" id="nconta" >
                                                    <i class="fas fa-dollar-sign" id="iconNConta"></i>
                                                    <input 
                                                        onChange={event => this.setState({nConta: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Conta" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTipoConta">Tipo de conta *</label>
                                                <div className="grupo-campo-input input-cad" id="tipoConta" >
                                                    <i class="fas fa-dollar-sign" id="iconTipoConta"></i>
                                                    <select onChange={ e => this.setState({tipoConta: e.target.value})}>
                                                        <option value="">Selecione o tipo da conta</option>
                                                        <option value="Conta Corrente">Conta Corrente</option>
                                                        <option value="Conta Poupança">Conta Poupança</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={this.toggleModalCadastrar.bind(this)}>Fechar</Button>
                            <Button className="bg-laranja" onClick={this.salvar}>Salvar</Button>
						</ModalFooter>
					</Modal>

                    {/*Modal de Alteração*/}
                    <Modal
					isOpen={this.state.modalAlterar}
					toggle={this.toggleModalAlterar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Movimentações</ModalHeader>
						<ModalBody>
                        <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="status">Status *</label>
                                            <div className="grupo-campo-input input-cad" id="status">
                                            <i class="fas fa-comment-dollar"></i>
                                                <select
                                                    value={this.state.status}
                                                    onChange={event => this.status(event.target.value, 2)}> 
                                                    <option value="">Selecione</option>
                                                    {this.state.status === "AP" || this.state.status === "P" ?
                                                        (<>
                                                            <option value="AP">Pagar</option>
                                                            <option value="P">Pago</option>
                                                        </>)
                                                        : (<>
                                                            <option value="AR">Receber</option>
                                                            <option value="R">Recebido</option>
                                                        </>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="desc">Descrição *</label>
                                            <div className="grupo-campo-input input-cad" id="desc">
                                            <i class="fas fa-receipt"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Descreva as informações sobre a conta"
                                                    autoComplete="on"
                                                    value={this.state.descricao}
                                                    onChange={event => this.setState({ descricao: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Tipo de pagamento *</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select 
                                                    value={this.state.tipo}
                                                    onChange={event => {this.setState({ tipo: event.target.value }); this.verificarTransferencia(event.target.value)}}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.tipoPagamento.map((tipo) => (
                                                        <option value={tipo.id}>{tipo.tipo_pagamento}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Centro de Custos *</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select 
                                                    value={this.state.conta}
                                                    onChange={event => this.setState({ conta: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contas.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Valor *</label>
                                            <div className="grupo-campo-input input-cad">
                                            <i class="fas fa-dollar-sign"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valor}
                                                    onChange={event => this.mascaraReal(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="grupo-pago2">
                                            <label>Valor Pago/Recebido</label>
                                            <div className="grupo-campo-input input-cad">
                                            <i class="fas fa-dollar-sign"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valorPago}
                                                    onChange={event => this.mascaraRealPago(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Data de vencimento *</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    value={this.state.dataVencimento}
                                                    onChange={event => this.setState({ dataVencimento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="dataPag2">
                                            <label>Data de pagamento *</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    value={this.state.dataPagamento}
                                                    onChange={event => this.setState({ dataPagamento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Plano de contas *</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select 
                                                    value={this.state.categoria}
                                                    onChange={event => this.setState({ categoria: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.categorias.map((cat) => (
                                                        <option value={cat.id}>{cat.categoria}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Conta Bancária </label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select value={this.state.contaB} onChange={event => this.setState({ contaB: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contasBancarias.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Data de competência </label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    value={this.state.dataCompetencia}
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataCompetencia: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div style={{display: this.state.display}}>
                                    <p class="subtitle-form">Dados bancários</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblBanco">Banco *</label>
                                                <div className="grupo-campo-input input-cad" id="banco" >
                                                    <i class="fas fa-dollar-sign" id="iconBanco"></i>
                                                    <input 
                                                        value={this.state.banco}
                                                        onChange={event => this.setState({banco: event.target.value})} 
                                                        placeholder="&nbsp;Informe o Banco" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblAgencia">Agência *</label>
                                                <div className="grupo-campo-input input-cad" id="agencia" >
                                                    <i class="fas fa-dollar-sign" id="iconAgencia"></i>
                                                    <input 
                                                        value={this.state.agencia}
                                                        onChange={event => this.setState({agencia: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblNConta">Conta *</label>
                                                <div className="grupo-campo-input input-cad" id="nconta" >
                                                    <i class="fas fa-dollar-sign" id="iconNConta"></i>
                                                    <input 
                                                        value={this.state.nConta}
                                                        onChange={event => this.setState({nConta: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Conta" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTipoConta">Tipo de conta *</label>
                                                <div className="grupo-campo-input input-cad" id="tipoConta" >
                                                    <i class="fas fa-dollar-sign" id="iconTipoConta"></i>
                                                    <select value={this.state.tipoConta} onChange={ e => this.setState({tipoConta: e.target.value})}>
                                                        <option value="">Selecione o tipo da conta</option>
                                                        <option value="Conta Corrente">Conta Corrente</option>
                                                        <option value="Conta Poupança">Conta Poupança</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
                            <Button className="bg-laranja" onClick={() => this.alterar(this.state.contaId)}>Salvar</Button>
						</ModalFooter>
					</Modal>
                                        
                    {/*Modal Detalhes*/}
                    <Modal
					isOpen={this.state.modalDetalhes}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.descricao}</ModalHeader>
                        <ModalBody>
                            <div className="box-conteudo">
                                <div className="conteudo-info">
                                    <p>Descricao</p>
                                    <p>{this.state.descricao}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data Admissão</p>
                                    <p>{moment(this.state.admissao).format('DD/MM/YYYY')}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data Atualização</p>
                                    <p>{this.state.alteracao ? moment(this.state.alteracao).format('DD/MM/YYYY') : 'Não houve alteração'}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data Vencimento</p>
                                    <p>{moment(this.state.dataVencimento).format('DD/MM/YYYY')}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data Pagamento</p>
                                    <p>{this.state.dataPagamento ? moment(this.state.dataPagamento).format('DD/MM/YYYY') : 'Pagamento não efetuado' }</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Valor</p>
                                    <p>R${this.state.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Valor Pago</p>
                                    <p>{this.state.valorPago ? 'R$' + this.state.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Pagamento não efetuado'}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Tipo de Pagamento</p>
                                    <p>{this.state.nomePagamento}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Status</p>
                                    <p>{this.state.status}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Plano de contas</p>
                                    <p>{this.state.nomeCategoria}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Centro de custos</p>
                                    <p>{this.state.nomeConta}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Conta bancária</p>
                                    <p>{this.state.contaBanc}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data de competência</p>
                                    <p>{this.state.dataCompetencia ? moment(this.state.dataCompetencia).format('DD/MM/YYYY') : 'Não há data de competência'}</p>
                                </div>

                                {this.state.banco ? <p class="w-100 subtitle-form">Dados bancários</p> : ''}  

                                {this.state.banco ? <div className="conteudo-info">
                                    <p>Banco</p>
                                    <p>{this.state.banco}</p>
                                </div> : ''}

                                {this.state.agencia ? <div className="conteudo-info">
                                    <p>Agência</p>
                                    <p>{this.state.agencia}</p>
                                </div> : ''}

                                {this.state.nConta ? <div className="conteudo-info">
                                    <p>Conta</p>
                                    <p>{this.state.nConta}</p>
                                </div> : ''}

                                {this.state.tipoConta ? <div className="conteudo-info">
                                    <p>Tipo de conta</p>
                                    <p>{this.state.tipoConta}</p>
                                </div> : ''}

                                {this.state.banco_financiado ? <div className="conteudo-info">
                                    <p>Banco Financiamento</p>
                                    <p>{this.state.banco_financiado}</p>
                                </div> : ''}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                    
                    {/*Modal Deletar*/}
                    <Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                        <ModalBody>
                            Deseja realmente deletar a conta<br></br>(ID: {this.state.modalDeletarId})?
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.deletar} color="danger">Deletar</Button>
                            <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                
                    {/* MODAL AVISO */}
                    <Modal
                        isOpen={this.state.modalAviso}
                        toggle={this.toggleModalAviso.bind(this)}
                        backdrop={true}
                        keyboard={true}
                        size="sm"
                        fade={false} >
                        <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                        <ModalBody>
                            {this.state.modalAvisoText}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                        </ModalFooter>
                    </Modal>

                </section>
            </>
        )
    }
}

export default MovimentacaoPage
