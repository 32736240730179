import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';
import moment from "moment";

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Externos extends React.Component {

	constructor(props) {
		super(props);

		

		this.state = {
			checklistNome: '',
			item: null,
			dados: [],
			
			searchId: '',
			searchNome: '',
			searchDescricao: '',
			searchQuantidade: '',
			searchEntrada: '',

			modalDetalhesOpen: false,
			modalDetalhesId: '',
			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesUnidade: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefInterna: '',
			modalDetalhesRefExterna: '',
			modalDetalhesValor: '',

			modalDetalhesDescricaoOficina: '',
			modalDetalhesQuantidade: '',
			modalDetalhesEntrada: '',

			modalDeletarOpen: false,
			modalDeletarDate: '',
			modalDeletarId: '',

			modalAvisoOpen: false,
			modalAvisoText: '',
		};
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/oficina")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			await this.apiGetItem();
			await this.apiChecklist();
			this.apiListarPecasProduto();
		}
	}

	apiGetItem = async () => {
		let res = await api_topmotos.get('/itens/id/' + this.props.match.params.id);
		this.setState({ item: res.data.item[0] });
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] produtosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	groupBy = (object, property) => {
		var i = 0, val, result = {};
		for (; i < object.length; i++) {
			val = object[i][property]
			val = moment(val).format("DD/MM/YYYY")
			if (!result[val]) result[val] = [];
			result[val].push(object[i]);
		}
		return result;
	}

	apiListarPecasProduto = async () => {
		let res = await api_topmotos.get('/oficina/prestador/id/' + this.props.match.params.id + '/tipo/' + this.props.match.params.tipo);
		let dados = this.groupBy(res.data || [], 'data_saida');
		this.setState({ dados });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id, date) => {
		let res = await api_topmotos.get('/prestador/oficina/id/' + id);
	
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarDate: moment(date).format("yyyy-MM-DD"),
			modalDeletarId: res.id,
		})
	}

	apiDeletarProduto = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/oficina/delete/' + this.state.modalDeletarId);
			
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/oficina/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Oficina deletada com sucesso!' });
	}

	sum = (data) => {
		let sum = 0
		this.state.dados[data].forEach(d => {
			sum += d.prestacao
		})
		return sum
	}

	apiChecklist = async () => {
		let res = await api_topmotos.get('/checklist/id/' + this.props.match.params.tipo);
		res = res.data[0];
		
		this.setState({ checklistNome: res.pergunta })
	}

	render() {
		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Checklist {this.state.checklistNome} | Ordem de Serviço Externa (Item: {this.state.item ? this.state.item.item_modelo + ' | ' + this.state.item.placa : '...'})</p>
							<div className="form-add" onClick={a => this.props.history.push("/adicionarservico/" + this.state.item.item_id+ '/tipo/' + this.props.match.params.tipo)}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
						{Object.keys(this.state.dados).map((data, id) => (
							<>
							<div class="barra-slide">
									<div>
											<p>{moment(this.state.dados[data][0].data_saida).format("DD/MM/YYYY")}</p>
									</div>
							</div>
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Prestador de Serviço
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Prestação
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Data de Saida
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Data de Volta
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Descrição
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
													<i className="fas fa-sort"></i>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.dados[data].map((produto, id) => (
											<tr key={produto.id}>
												<td>{produto.id}</td>
												<td>{produto.prestador_nome}</td>
												<td>{produto.prestacao ? parseFloat(produto.prestacao)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}</td>
												<td>{moment(produto.data_saida).format("DD/MM/YYYY")}</td>
												<td>{produto.data_volta ? moment(produto.data_volta).format("DD/MM/YYYY") : ' '}</td>
												<td>{produto.descricao}</td>
												<td className="td-acoes">
													<i className="fas fa-pen" onClick={() => this.props.history.push("/oficina/editarexterno/" + produto.id+"/tipo/"+this.props.match.params.tipo)}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(produto.id)}></i>
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
							<div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								Valor Total: {this.sum(data)
									.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
							</div>
							</>
						))}
						</div>

						{/* MODAL DELETAR */}
						<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar a ordem de serviço externa (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarProduto} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

						<Versionamento />
					</div>
				</div>
			</section>
		)
	}
}

export default Externos;
