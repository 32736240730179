import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';


class UsuarioCadastroPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
			modalCadastrarOpen: false,
			modalCadastrarNome: '',
			modalCadastrarTelefone: '',
			modalCadastrarEmail: '',
			modalCadastrarSenha: '',
			modalCadastrarNivel: '',
			senhaVisivel: false,
			empresas: [],
			empresaId: '',
			error: '',
			errorMessage: '',
			porcentagemConsorcio: null,
			porcentagemVenda: null,
			usuarios: [], 
			emailExistente: false,
        }
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/usuarios")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
			this.apiListarEmpresas();
			this.apiListarUsuarios();
		}
	}

    log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }
    
    togglePassword = () => {
		var s = this.state.senhaVisivel;
		this.setState({ senhaVisivel: !s });
	}
	
	apiListarEmpresas = async event => {
        let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
	}
	
	apiListarUsuarios = async event => {
		let res = await api_topmotos.get('/usuarios/');
		this.setState({ usuarios: res.data });
	}

	verificarEmail = (email) =>{
		this.setState({ modalCadastrarEmail: email })

		var usuario = this.state.usuarios.filter( y => y.usuario_email == email)

		if(usuario[0]){
			this.setState({ errorMessage: "Email já existente!", emailExistente: true});
			var inputId = document.getElementById('email');
			var inputIcone = document.getElementById('iconEmail');
			inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
			inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
		
		} else{
			this.setState({ errorMessage: "", emailExistente: false});
			var inputId = document.getElementById('email');
			var inputIcone = document.getElementById('iconEmail');
			inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
		}
	}
    
    apiCadastrarUsuario = async e => {
		e.preventDefault();

		const {
			modalCadastrarNome,
			modalCadastrarEmail,
			modalCadastrarSenha,
			modalCadastrarTelefone,
			modalCadastrarNivel,
			empresaId,
		} = this.state;

		var inputs = [
			[modalCadastrarNome, 'nome', 'iconNome', 'lblNome'],
			[modalCadastrarEmail, 'email', 'iconEmail', 'lblEmail'],
			[modalCadastrarSenha, 'senha', 'iconSenha', 'lblSenha'],
			[modalCadastrarTelefone, 'telefone', 'iconTelefone', 'lblTelefone'],
			[modalCadastrarNivel, 'nivel', 'iconNivel', 'lblNivel']
        ]

		if (!modalCadastrarNome ||
			!modalCadastrarEmail ||
			!modalCadastrarTelefone ||
			!modalCadastrarSenha ||
			!modalCadastrarNivel ||
			modalCadastrarNivel === 'NULL') {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
			this.log(this.state.error);

			for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var ssInputNome = document.getElementById(inputs[i][1]);
					var ssInputIconNome = document.getElementById(inputs[i][2]);
					var ssInputLabel = document.getElementById(inputs[i][3]);
					ssInputNome.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					ssInputIconNome.style.cssText = "color: #FF7115; transition: 0.2s;";
					ssInputLabel.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var ssInputNome = document.getElementById(inputs[i][1]);
					var ssInputIconNome = document.getElementById(inputs[i][2]);
					var ssInputLabel = document.getElementById(inputs[i][3]);
					ssInputNome.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					ssInputIconNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					ssInputLabel.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}	

		} else if (this.state.emailExistente){
			this.setState({ errorMessage: "Email já existente!"})
		} else {
			this.setState({ errorMessage: '' });

			for(var i = 0; i < inputs.length; i++){
				ssInputNome = document.getElementById(inputs[i][1]);
				ssInputIconNome = document.getElementById(inputs[i][2]);
				ssInputLabel = document.getElementById(inputs[i][3]);
				ssInputNome.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				ssInputLabel.style.cssText = "color: #FF7115; transition: 0.2s;";
			}	

			try {
				await api_topmotos.post('/usuarios/create', {
					usuario_nome: modalCadastrarNome,
					usuario_email: modalCadastrarEmail,
					usuario_telefone: modalCadastrarTelefone,
					usuario_senha: modalCadastrarSenha,
					usuario_nivel: modalCadastrarNivel,
					usuario_admissaodata: this.getTimestamp(),
					usuario_empresa: empresaId,
					comissao_consorcio: this.state.porcentagemConsorcio,
					comissao_venda: this.state.porcentagemVenda
				}).then(res =>{
					this.props.history.push("/Restricao/" + res.data.returnId)
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/usuarios/create)" });
				this.log(this.state.error);
			}
        }
	}

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir usuário</strong></p>

                            </div>
                            <div className="content-formulario">
								

								<div className="row">
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="nome" id="lblNome">Nome*</label>
											<div className="grupo-campo-input input-cad" id="nome">
												<i className="fas fa-user" id="iconNome"></i>
												<input
													type="text"
													placeholder="&nbsp;Informe o Nome do usuário"
													autoComplete="on"
													onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
													required
												/>
											</div>
										</div>
                                    </div>
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="telefone" id="lblTelefone">Telefone*</label>
											<div className="grupo-campo-input input-cad" id="telefone">
												<i className="fas fa-phone" id="iconTelefone"></i>
												<InputMask
													mask="(99) 99999-9999"
													maskChar="_"
													alwaysShowMask="true"
													type="tel"
													placeholder="&nbsp;Informe o Telefone do usuário"
													autoComplete="on"
													onChange={event => this.setState({ modalCadastrarTelefone: event.target.value })}
													required
												/>
											</div>
										</div>
                                    </div>
                                </div>
                                
								<div className="row">
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="email" id="lblEmail">E-mail*</label>
											<div className="grupo-campo-input input-cad" id="email">
												<i className="far fa-envelope" id="iconEmail"></i>
												<input
													type="email"
													placeholder="&nbsp;Informe o E-mail do usuário"
													autoComplete="on"
													onChange={event => this.verificarEmail(event.target.value)}
													required
												/>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="senha" id="lblSenha">Senha</label>
											<div className="grupo-campo-input input-cad" id="senha">
												<i className="fas fa-lock" id="iconSenha"></i>
												<input
													type={(this.state.senhaVisivel) ? "text" : "password"}
													placeholder="&nbsp;Informe a Senha do usuário"
													autoComplete="on"
													onChange={event => this.setState({ modalCadastrarSenha: event.target.value })}
													required
												/>
												<i
													className={`fa ${this.state.senhaVisivel ? "fa-eye-slash" : "fa-eye"}`}
													id="iconOlho"
													onClick={this.togglePassword}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="nivel" id="lblNivel">Nível*</label>
											<div className="grupo-campo-input input-cad" id="nivel">
												<i className="fas fa-user-shield" id="iconNivel"></i>
												<select
													name="nivel"
													placeholder="&nbsp;Selecione o Nível do usuário"
													autoComplete="on"
													onChange={event => this.setState({ modalCadastrarNivel: event.target.value })}
													required
												>
													<option value="NULL">Selecione o Nível do usuário</option>
													<option value="Funcionário">Funcionário</option>
													<option value="Gerente">Gerente</option>
													<option value="Administrador">Administrador</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="empresa">Empresa</label>
											<div className="grupo-campo-input input-cad" id="empresa">
												<i className="fas fa-user-edit" id="iconempresa"></i>
												<select
													name="empresa"
													placeholder="&nbsp;Selecione a empresa"
													autoComplete="on"
													onChange={event => this.setState({ empresaId: event.target.value })}
													required
												>
													<option value="">Selecione a empresa</option>
													{
														this.state.empresas.map((empresa, id) => (
															<option key={empresa.empresa_id} value={empresa.empresa_id}>{empresa.empresa_nomefantasia}</option>
														))
													}
												</select>
											</div>
										</div>
									</div>
								</div>
                                
								<div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="venda" id="lblVenda">Comissão por venda</label>
                                            <div className="grupo-campo-input input-cad" id="venda">
                                                <i className="fas fa-percent"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe a comissão por venda"
                                                    onChange={event => this.setState({ porcentagemVenda: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="consorcio" id="lblConsorcio">Comissão por Consórcio</label>
                                            <div className="grupo-campo-input input-cad" id="consorcio">
                                                <i className="fas fa-percent"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe a comissão por consórcio"
                                                    onChange={event => this.setState({ porcentagemConsorcio: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

								<div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errorMessage}</p>
                                </div>

                                <Button onClick={this.apiCadastrarUsuario} className="cadbtn mt-1">Cadastrar</Button>
                                
                            </div>
							<Versionamento/>
						</div>
                    </div>
                </section>

            </>
        )
    }
}

export default UsuarioCadastroPage