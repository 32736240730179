import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';
import moment from "moment";

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Estoque extends React.Component {

	constructor(props) {
		super(props);

		

		this.state = {
			checklistNome: '',
			item: null,
			dados: [],
			
			searchId: '',
			searchNome: '',
			searchDescricao: '',
			searchQuantidade: '',
			searchEntrada: '',

			modalDetalhesOpen: false,
			modalDetalhesId: '',
			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesUnidade: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefInterna: '',
			modalDetalhesRefExterna: '',
			modalDetalhesValor: '',

			modalDetalhesDescricaoOficina: '',
			modalDetalhesQuantidade: '',
			modalDetalhesEntrada: '',

			modalDeletarOpen: false,
			modalDeletarDate: '',
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',
		};
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/oficina")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			await this.apiGetItem();
			await this.apiChecklist();
			this.apiListarPecasProduto();
		}
	}

	apiGetItem = async () => {
		let res = await api_topmotos.get('/itens/id/' + this.props.match.params.id);
		this.setState({ item: res.data.item[0] });
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] produtosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	groupBy = (object, property) => {
		var i = 0, val, result = {};
		for (; i < object.length; i++) {
			val = object[i][property]
			if (!result[val]) result[val] = [];
			result[val].push(object[i]);
		}
		return result;
	}

	apiListarPecasProduto = async () => {
		let res = await api_topmotos.get('/oficina/id/' + this.props.match.params.id + '/tipo/' + this.props.match.params.tipo);
		let dados = this.groupBy(res.data || [], 'oficina_data_saida');
		this.setState({ dados });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDetalhes = async (data, idProduto) => {
		let resProduto = await api_topmotos.get('/produtos/id/' + idProduto);
		resProduto = resProduto.data[0];

		let resOficina =  data.filter((d) => d.produto_id === idProduto);
		resOficina = resOficina[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resProduto.produto_id,
			modalDetalhesNome: resProduto.produto_nome,
			modalDetalhesDescricao: resProduto.produto_descricao,
			modalDetalhesUnidade: resProduto.tipo_nome,
			modalDetalhesFornecedor: resProduto.fornecedor_nome,
			modalDetalhesRefInterna: resProduto.refInterna,
			modalDetalhesRefExterna: resProduto.refExterna,

			modalDetalhesValorTotal: resOficina.produto_valor,
			modalDetalhesDescricaoOficina: resOficina.oficina_descricao,
			modalDetalhesQuantidade: resOficina.oficina_produto_quantidade,
			modalDetalhesSaida: resOficina.oficina_data_saida,
		});
	}

	modalDeletar = async (id, date) => {
		let res = await api_topmotos.get('/produtos/id/' + id);
		res = res.data[0];
		console.log(date)

		this.setState({
			modalDeletarOpen: true,
			modalDeletarDate: moment(date).format("yyyy-MM-DD"),
			modalDeletarId: res.produto_id,
			modalDeletarNome: res.produto_nome,
		})
	}

	apiDeletarProduto = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/oficina/delete/produto/' + this.state.modalDeletarId + '/' + this.props.match.params.id + '/' + this.state.modalDeletarDate);
			
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/oficina/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Produtos deletado com sucesso!' });
	}

	sum = (data) => {
		let sum = 0
		this.state.dados[data].forEach(d => {
			sum += d.produto_valor
		})
		return sum
	}

	apiChecklist = async () => {
		let res = await api_topmotos.get('/checklist/id/' + this.props.match.params.tipo);
		res = res.data[0];
		
		this.setState({ checklistNome: res.pergunta })
	}

	render() {
		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Checklist {this.state.checklistNome} | Ordem de Serviço Interna (Item: {this.state.item ? this.state.item.item_modelo + ' | ' + this.state.item.placa : '...'})</p>
							<div className="form-add" onClick={a => this.props.history.push("/adicionaroficina/" + this.state.item.item_id + '/tipo/' + this.props.match.params.tipo)}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
						{Object.keys(this.state.dados).map((data, id) => (
							<>
							<div class="barra-slide">
									<div>
											<p>{moment(this.state.dados[data][0].oficina_data_saida).format("DD/MM/YYYY")}</p>
									</div>
							</div>
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Produto Nome
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Unidade
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Quantidade
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Total
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Referencia Interna
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Tipo
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
													<i className="fas fa-sort"></i>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.dados[data].map((produto, id) => (
											<tr key={produto.produto_id}>
												<td>{produto.produto_id}</td>
												<td>{produto.produto_nome}</td>
												<td>{produto.tipo_nome}</td>
												<td>{produto.oficina_produto_quantidade}</td>
												<td>{produto.produto_valor ? parseFloat(produto.produto_valor)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' '}</td>
												<td>{produto.refInterna}</td>
												<td>{produto.oficina_tipo ? produto.oficina_tipo.toUpperCase() : ''}</td>
												<td className="td-acoes">
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(this.state.dados[data], produto.produto_id)}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(produto.produto_id, produto.oficina_data_saida)}></i>
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
							<div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								Valor Total: {this.sum(data)
									.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
							</div>
							</>
						))}
						</div>

						{/* MODAL DETALHES */}
						<Modal
							isOpen={this.state.modalDetalhesOpen}
							toggle={this.toggleModalDetalhes.bind(this)}
							backdrop={true}
							keyboard={true}
							size="lg"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>Oficina: {this.state.modalDetalhesNome} (ID: {this.state.modalDetalhesId})</ModalHeader>
							<ModalBody>
								<div className="box-conteudo">
									<div className="conteudo-info">
										<p>Nome</p>
										<p>{this.state.modalDetalhesNome}</p>
									</div>
									<div className="conteudo-info">
										<p>Descrição do Produto</p>
										<p>{this.state.modalDetalhesDescricao}</p>
									</div>
									<div className="conteudo-info">
										<p>Fornecedor</p>
										<p>{this.state.modalDetalhesFornecedor}</p>
									</div>
									<div className="conteudo-info">
										<p>Unidade</p>
										<p>{this.state.modalDetalhesUnidade}</p>
									</div>
									<div className="conteudo-info">
										<p>Referencia Externa</p>
										<p>{this.state.modalDetalhesRefExterna}</p>
									</div>
									<div className="conteudo-info">
										<p>Referencia Interna</p>
										<p>{this.state.modalDetalhesRefInterna}</p>
									</div>
									<div className="conteudo-info">
										<p>Quantidade</p>
										<p>{this.state.modalDetalhesQuantidade}</p>
									</div>
									<div className="conteudo-info">
										<p>Valor Unitario</p>
										<p>{parseFloat(this.state.modalDetalhesValorTotal / this.state.modalDetalhesQuantidade)
													.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									</div>
									<div className="conteudo-info">
										<p>Valor Total</p>
										<p>{parseFloat(this.state.modalDetalhesValorTotal || 0)
													.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
									</div>
									<div className="conteudo-info">
										<p>Data de Saida</p>
										<p>{moment(this.state.modalDetalhesSaida).format("DD/MM/YYYY")}</p>
									</div>
									<div className="conteudo-info">
										<p>Descrição Oficina</p>
										<p>{this.state.modalDetalhesDescricaoOficina || "Sem Descrição"}</p>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL DELETAR */}
						<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar o Produto {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarProduto} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

						<Versionamento />
					</div>
				</div>
			</section>

		)
	}
}

export default Estoque;
