import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class ComprasPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compras: [],
            modalDetalhesOpen: false,
			modalDetalhesId: 0
        };
    }

    componentDidMount(){
        this.apiListarCompras();
    }

    apiListarCompras = async event => {
		let res = await api_topmotos.get('/compras/all');
		this.setState({ compras: res.data });
    }  

    toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalChecklist = async (compra) => {
		let res = await api_topmotos.post('/compras/itens/unique', {id: compra});
		res = res.data[0];

		this.setState({modalDetalhesOpen: true});
	}

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="form-head">
                                <p>Compras</p>
						    </div>
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Item
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor da Compra
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Data
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Checklist
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.state.compras.map((compra, id) => (
                                                <tr key={compra.id}>
                                                    <td>{compra.id}</td>
                                                    <td>{compra.item_modelo}</td>
                                                    <td>{compra.valor_compra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td>{moment(compra.data).format("DD/MM/YYYY HH:mm")}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-eye" onClick={() => this.modalChecklist(compra.id)}></i>&nbsp;
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                                <div className="form-footer">
                                    <span></span>
                                </div>
                            </div>
                        
                            <Versionamento/>
                        </div>
                    </div>  

                    {/* MODAL DETALHES */}
                    <Modal
                        isOpen={this.state.modalDetalhesOpen}
                        toggle={this.toggleModalDetalhes.bind(this)}
                        backdrop={true}
                        keyboard={true}
                        size="lg"
                        fade={false} >
                        <ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>Checklist</ModalHeader>
                        <ModalBody>
                            <div className="box-conteudo">
                                
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
                        </ModalFooter>
                    </Modal>

                </section>
            </>
        )
    }
}

export default ComprasPage
