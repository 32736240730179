import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

class PrestadorCadastroPage extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			dados: [],
            categorias: [],

            checklists: {},

			modalCadastrarOpen: false,
			modalCadastrarNome: '',
			modalCadastrarTelefone: '',
            modalCadastrarCelular: '',
			modalCadastrarEmail: '',
			modalCadastrarVencimentoP: '',

            modalCadastrarCep: '',
			modalCadastrarEstado: '',
			modalCadastrarCidade: '',
			modalCadastrarBairro: '',
			modalCadastrarLogradouro: '',
			modalCadastrarNumero: '',
			modalCadastrarComplemento: '',

            modalCadastrarDescricao: '',

            modalCadastrarEnderecoId: null,
		};
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] prestadorUPDATE/READ > " + msg);
    }

    selectCheckBox(e, index, id) {
        let checklists = this.state.checklists
        if (e.target.checked) checklists[index] = id
        else delete checklists[index]
        this.setState({checklists})
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/prestadores")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

    async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		}
        this.listarCategorias()
	}

    apiCadastrarPrestador = async e => {
        e.preventDefault();

        const {
            modalCadastrarNome,
            modalCadastrarTelefone,
            modalCadastrarCelular,
            modalCadastrarEmail,
			modalCadastrarVencimentoP,
            modalCadastrarCategoria,
            checklists,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            modalCadastrarComplemento,
            modalCadastrarDescricao,
        } = this.state;

        if(!modalCadastrarNome       ||
            !modalCadastrarTelefone    ||
            !modalCadastrarVencimentoP  ||
            !modalCadastrarEmail ||
            !modalCadastrarCep ||
            !modalCadastrarEstado ||
            !modalCadastrarCidade ||
            !modalCadastrarBairro ||
            !modalCadastrarLogradouro ||
            !modalCadastrarNumero ||
            Object.values(checklists).length <= 0) {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
                
        } else {
            this.setState({ errorMessage: '' });

            try {
                await api_topmotos.post('/enderecos/create', {
                    endereco_cep:           modalCadastrarCep,
                    endereco_estado:        modalCadastrarEstado,
                    endereco_cidade:        modalCadastrarCidade,
                    endereco_bairro:        modalCadastrarBairro,
                    endereco_logradouro:    modalCadastrarLogradouro,
                    endereco_numero:        modalCadastrarNumero,
                    endereco_complemento:   modalCadastrarComplemento,
                    endereco_admissaodata:  this.getTimestamp(),
                }).then(res => {
                    this.setState({ modalCadastrarEnderecoId: res.data.returnId });
                });
            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }

            try {
                await api_topmotos.post('/prestadores/create', {
                    prestador_endereco:      this.state.modalCadastrarEnderecoId,
					prestador_nome:          modalCadastrarNome,
                    prestador_email:         modalCadastrarEmail,
                    prestador_telefone:      modalCadastrarTelefone,
                    prestador_celular:       modalCadastrarCelular,
                    prestador_vencimentoP:   modalCadastrarVencimentoP,
                    prestador_admissaodata:  this.getTimestamp(),
                    prestador_categoria:     modalCadastrarCategoria,
                    prestador_checklist:     Object.values(checklists),
                    prestador_descricao:     modalCadastrarDescricao,
                })
            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/fornecedores/create)" });
                this.log(this.state.error);
            }

            this.props.history.push('/prestadores');
        }
    }

    listarCategorias = async () =>{
        let res = await api_topmotos.get('/prestadores/categorias');
        this.setState({ categorias: res.data });
        console.log(res.data)
    }

    getCEP = async (event) => {
		let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');
        this.setState({ modalCadastrarCep: event.target.value });

		if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstado: res['data'].uf,
                modalCadastrarCidade: res['data'].localidade,
                modalCadastrarBairro: res['data'].bairro,
                modalCadastrarLogradouro: res['data'].logradouro,
            })
		}
    }
    
    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir prestador de serviço</strong></p>
                            </div>
                            <div className="content-formulario">
                                <p className="subtitle-form">Informações sobre o prestador de serviço</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nome">Nome *</label>
                                            <div className="grupo-campo-input input-cad" id="nome">
                                                <i className="fas fa-user" id="iconNome"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Nome do Prestador"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="email">E-mail</label>
                                            <div className="grupo-campo-input input-cad" id="email">
                                                <i className="far fa-envelope" id="iconEmail"></i>
                                                <input
                                                    type="email"
                                                    placeholder="&nbsp;Informe o E-mail do Prestador"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarEmail: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="telefone">Telefone *</label>
                                            <div className="grupo-campo-input input-cad" id="telefone">
                                                <i className="fas fa-phone" id="iconTelefone"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="&nbsp;Informe o Telefone do Prestador"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarTelefone: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="telefone">Celular *</label>
                                            <div className="grupo-campo-input input-cad" id="telefone">
                                                <i className="fas fa-phone" id="iconTelefone"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="&nbsp;Informe o Telefone do Prestador"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarCelular: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">                                  
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="" id="lblObrigatoria">Quais os Checklists de Veiculos Relacionados?</label>
                                            {this.state.categorias.map((c,index) => (
                                                <div className="obrigatorio">
                                                    <input type="checkbox" name="checklists" value="0" id={c.id}  defaultChecked={false} onChange={(e) => this.selectCheckBox(e, index, c.id)}/>
                                                    <label htmlFor={c.id}>{c.nome}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="dataVencimentoP">Dia de Vencimento Padrão *</label>
                                            <div className="grupo-campo-input input-cad" id="dataVencimentoP">
                                                <i className="fas fa-calendar" id="iconDataVencimentoP"></i>
                                                <input type="number"
                                                    id="dataVencimentoP"
                                                    value={this.state.modalCadastrarVencimentoP}
                                                    onChange={(e) => {this.setState({modalCadastrarVencimentoP: e.target.value})}}
                                                    placeholder="Dia de Vencimento Padrão"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="subtitle-form">Informações sobre endereço</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cep">CEP *</label>
                                            <div className="grupo-campo-input input-cad" id="cep">
                                                <i className="fas fa-envelope" id="iconCep"></i>
                                                <InputMask
                                                    mask="99999-999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                    autoComplete="on"
                                                    onChange={event => this.getCEP(event, "cadastrar")}
                                                    required
                                                    value={this.state.modalCadastrarCep}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estado">Estado *</label>
                                            <div className="grupo-campo-input input-cad" id="estado">
                                                <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                <select
                                                    name="estado"
                                                    value={this.state.modalCadastrarEstado}
                                                    placeholder="&nbsp;Selecione o Estado de residência"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarEstado: event.target.value })}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o Estado de residência</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cidade">Cidade *</label>
                                            <div className="grupo-campo-input input-cad" id="cidade">
                                                <i className="fas fa-city" id="iconCidade"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarCidade}
                                                    placeholder="&nbsp;Informe a Cidade de residência"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarCidade: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="logradouro">Logradouro *</label>
                                            <div className="grupo-campo-input input-cad" id="logradouro">
                                                <i className="fas fa-road" id="iconLogradouro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarLogradouro}
                                                    placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarLogradouro: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="numero">Número *</label>
                                            <div className="grupo-campo-input input-cad" id="numero">
                                                <i className="fas fa-home" id="iconNumero"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe o Número de Residência"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarNumero: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarNumero}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="bairro">Bairro *</label>
                                            <div className="grupo-campo-input input-cad" id="bairro">
                                                <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarBairro}
                                                    placeholder="&nbsp;Informe o Bairro"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarBairro: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="complemento">Complemento</label>
                                            <div className="grupo-campo-input input-cad" id="complemento">
                                                <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarComplemento}
                                                    placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarComplemento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grupo-input input-style-cad">
                                    <div className="grupo-campo-input input-cad">
                                        <textarea style={{border: "0"}} value={this.state.modalCadastrarDescricao} onChange={a => this.setState({modalCadastrarDescricao: a.target.value})} className="box-obs" placeholder="Escreva aqui uma descrição"></textarea>
                                    </div>
                                </div>
                                
                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarPrestador} className="cadbtn">Cadastrar</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
					
            </>
        )
    }
}

export default PrestadorCadastroPage
