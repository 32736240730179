import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class AtendimentoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dados: [],
            clientes: [],
            itens: [],
            telefone: [],

            modalDetalhesOpen: false,
            modalDetalhesId: 0,
            modalDetalhesNome: '',

            modelos: [],
            clientName: '',
            cliente: null,
            desc: null,
            tipo: null, 
            options:[],
            retorno: '',
            motivo: '',
            tipo_atend: [],
            marcas: [],
            telefoneUn: '', 
            tipo_atend: [], 
            modelosUn: []
        };
    }

    componentDidMount() {
        this.apiListarAtends();
        this.apiListarClientes();
        this.apiListarItens();
        this.apiListarMarcas();
        this.apiListarModelos();
    }

    apiListarAtends = async () => {
        let res = await api_topmotos.get('/atend');
        this.setState({ dados: res.data });
    }

    apiListarClientes = async event => {
        let res = await api_topmotos.get('/pessoas/');
        let j = []
        let t = []

        for (let i of res.data) {
            j[`${i.pessoa_id}`] = i.pessoa_nome;
            t[`${i.pessoa_id}`] = i.pessoa_telefone;
        }

        this.setState({ clientes: j });
        this.setState({ telefone: t });
    }

    apiListarItens = async event => {
        let res = await api_topmotos.get('/atend/itens/');

        let j = [];

        for (let i of res.data) {
            j[`${i.id}`] = i.item;
        }

        this.setState({ itens: j });
    }

    formatTime(e) {
        let [dias, tempo] = e.split("T");
        let [ano, mes, dia] = dias.split('-');
        let [hora, minutos, segundos] = tempo.split(':');

        return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }

    // Modal Detalhes

    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelos: res.data });
    }

    toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
    }
    
    modalDetalhes = async (e) => {
		let {data} = await api_topmotos.post("/atend/unique", {id: e});
        let it = []
        
        let c = this.state.clientes.filter((y, i) => i == data.atendimento[0].cliente)[0];
        let t = this.state.telefone.filter((y, i) => i == data.atendimento[0].cliente)[0];
        let item = this.state.itens.filter((y, i) => i == data.atendimento[0].tipo_atendimento)[0];

        for (let i of data.itens){
            it.push(i.itens_id);
        }

        let m = this.state.modelos.filter(y => it.includes(y.id)).sort((a, b) => a.id > b.id);
        it = []

        for (let i of m){
            it.push(i.nome);
        }
        
        this.setState({
            modalDetalhesOpen: true,
            modalDetalhesId: data.atendimento[0].id,
            modalDetalhesNome: c,
            clientName: c,
            cliente: data.atendimento[0].cliente,
            desc: data.atendimento[0].descricao,
            tipo: item,
            options: m,
            telefoneUn: t,
            modelosUn: it,
            retorno: moment(data.atendimento[0].data_retorno).format('DD/MM/YYYY'),
            motivo: data.atendimento[0].motivo_venda
        })
	}

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p>Atendimentos</p>
                                <div className="form-add" onClick={() => this.props.history.push("/cadastraratendimento")}>
                                    Adicionar <i className="fas fa-plus"></i>
                                </div>
                            </div>
 
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Tipo
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Cliente
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Contato
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Motivo
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Data Retorno
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
											    </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dados.map(e => (
                                                <>
                                                    <tr key={e.id}>
                                                        <td>{e.id}</td>
                                                        <td>{this.state.itens[e.tipo_atendimento]}</td>
                                                        <td>{this.state.clientes[e.cliente]}</td>
                                                        <td>{this.state.telefone[e.cliente]}</td>
                                                        <td>{e.motivo_venda}</td>
                                                        <td>{e.data_retorno ? moment(e.data_retorno).format('DD/MM/YYYY') : ''}</td>
                                                        <td className="td-acoes">
                                                            <i className="fas fa-eye" onClick={() => this.modalDetalhes(e.id)}></i>&nbsp;
                                                            <i className="fas fa-pen" onClick={() => this.props.history.push("/editaratendimento/" + e.id)}></i>&nbsp;
												        </td>
                                                    </tr>
                                                </>
                                            ))
                                        }

                                    </tbody>
                                </table>
                                <div className="form-footer">
                                    <span></span>
                                </div>
                            </div>
                        
                            <Versionamento/>
                        </div>
                    </div>

                    {/* MODAL DETALHES */}
                    <Modal
                        isOpen={this.state.modalDetalhesOpen}
                        toggle={this.toggleModalDetalhes.bind(this)}
                        backdrop={true}
                        keyboard={true}
                        size="lg"
                        fade={false} >
                        <ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesId} - {this.state.modalDetalhesNome}</ModalHeader>
                        <ModalBody>
                            <div className="box-conteudo">
                                <div className="conteudo-info">
                                    <p>Cliente</p>
                                    <p>{this.state.clientName}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Meio de atendimento</p>
                                    <p>{this.state.tipo}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Telefone</p>
                                    <p>{this.state.telefoneUn}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Motivo</p>
                                    <p>{this.state.motivo}</p>
                                </div>
                                <div className="conteudo-info-2">
                                    <p>Descrição</p>
                                    <p>{this.state.desc}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data de retorno</p>
                                    <p>{this.state.retorno}</p>
                                </div>
                                <div className="conteudo-info-modelos">
                                    <p>Modelos</p>
                                    {this.state.modelosUn.map(e => (
                                        <>
                                        <li>{e}</li>
                                        </>))
                                    }
                                    {this.state.modelosUn.length == 0 && <li>Nenhum modelo de interesse</li>}
                                </div>
                                
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                </section>

            </>
        )
    }
}

export default AtendimentoPage