import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class ChecklistPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pergunta: '',
            obrigatorio: '',
            id:'',
            checklist: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            ativo:'',
            errorMessage: ''
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/checklist")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.listarChecklist();
        }
    }

    listarChecklist = async () =>{
        let res = await api_topmotos.get('/checklist/');
        this.setState({ checklist: res.data });
    }

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/checklist/id/" + id)
        
        this.setState({
            id: data[0].id,
            pergunta: data[0].pergunta,
            obrigatorio: data[0].obrigatorio
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);
        await this.setState({modalAlterar: true})
    }

    apiCadastrarChecklist = async () => {
        var inputs = [
            [this.state.pergunta, 'pergunta','iconPergunta','lblPergunta'],
            [this.state.obrigatorio, 'lblObrigatoria','lblObrigatoria','lblObrigatoria']
        ]

        if(this.state.pergunta) {
            try {
                await api_topmotos.post('/checklist/create', {
                    pergunta: this.state.pergunta,
                    obrigatorio: this.state.obrigatorio
                });

                this.toggleModalCadastrar()
                this.listarChecklist()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarChecklist = async () => {
        var inputs = [
            [this.state.pergunta, 'pergunta','iconPergunta','lblPergunta'],
            // [this.state.obrigatorio, '','','lblObrigatoria']
        ]

        if(this.state.pergunta && this.state.obrigatorio) {
            try {
                await api_topmotos.put('/checklist/update/id/' + this.state.id, {
                    id: this.state.id,
                    pergunta: this.state.pergunta,
                    obrigatorio: this.state.obrigatorio
                })

                this.toggleModalAlterar()
                this.listarChecklist()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var check = document.getElementById("check" + id);

        try {
            await api_topmotos.post('/checklist/ativar/id/' + id, {
                id: this.state.id,
                check: check.checked == true ? 0 : 1
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
            this.log(this.state.error);
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Checklist Veículos</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Checklist<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            {this.state.checklist.map((check) => (
                                    <div className="item-opcao">
                                        <div className="head default">
                                            <div className="p-head">
                                                <p className="pr-2 pergunta-p">{check.id}.</p>
                                                <p className="pergunta-p">{check.pergunta}</p>
                                                <p className="pergunta-p">{check.obrigatorio  == 0 ? '*' : ''}</p>
                                            </div>
                                            <div>
                                                <label className="switch mr-2">
                                                    <input type="checkbox" id={"check" + check.id} onClick={() => this.toggleAtivar(check.id)} defaultChecked={check.ativo == 0 ? true : false}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <i className='fas fa-pen' onClick={() => {this.OpenModalAlteracao(check.id)}}></i>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }

                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="pergunta" id="lblPergunta">Pergunta</label>
                                                        <div className="grupo-campo-input input-cad" id="pergunta">
                                                            <i className="fas fa-pen" id="iconPergunta"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a pergunta"
                                                                onChange={event => this.setState({ pergunta: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="lblObrigatoria">Está pergunta é obrigatória?</label>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="0" id="s" onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="s">Sim</label>
                                                        </div>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="1" id="n" onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="n">Não</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrar}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarChecklist}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="pergunta" id="lblPergunta">Pergunta</label>
                                                        <div className="grupo-campo-input input-cad" id="pergunta">
                                                            <i className="fas fa-pen" id="iconPergunta"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a pergunta"
                                                                value={this.state.pergunta}
                                                                onChange={event => this.setState({ pergunta: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="lblObrigatoria">Está pergunta é obrigatória?</label>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="0" id="s"  defaultChecked={this.state.obrigatorio == 0 ? true : false} onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="s">Sim</label>
                                                        </div>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="1" id="n" defaultChecked={this.state.obrigatorio == 1 ? true : false} onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="n">Não</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarChecklist}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ChecklistPage