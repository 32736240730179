import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class DespesaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            descricao: '',
            valor: '',
            id:'',
            despesa_constante: '',
            despesas: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            // modalDeletar: false,
            // modalDeletarId: '',
            // modalAviso: false,
            // modalAvisoText: '',
            // ativo:''
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/despesas")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.listarDespesas();
        }
    }

    listarDespesas = async () =>{
        let res = await api_topmotos.get('/despesas/');
        this.setState({ despesas: res.data });
    }

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/despesas/id/" + id)
        
        var real = data[0].valor_padrao.toLocaleString('pt-br', {minimumFractionDigits: 2});

        this.setState({
            id: data[0].id,
            descricao: data[0].descricao,
            valor: real,
            despesa_constante: data[0].despesa_const
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar, errorMessage: ''});
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar, errorMessage: ''});
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);

        await this.setState({modalAlterar: true})
    }

    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valor: real})
    }

    apiCadastrarDespesa = async () => {
        var inputs = [
            [this.state.descricao, 'descricao','iconDescricao','lblDescricao'],
            [this.state.valor, 'valor','iconValor','lblValor']
        ]

        if(this.state.descricao && this.state.valor) {
            try {
                var real = this.state.valor.replace('.', '');
                real = real.replace(',', '.');

                await api_topmotos.post('/despesas/create', {
                    descricao: this.state.descricao,
                    valor: real,
                    despesa_constante: this.state.despesa_constante ? 1 : 0
                });

                this.toggleModalCadastrar()
                this.listarDespesas()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarDespesa = async () => {
        var inputs = [
            [this.state.descricao, 'descricao','iconDescricao','lblDescricao'],
            [this.state.valor, 'valor','iconValor','lblValor']
        ]

        if(this.state.descricao && this.state.valor) {
            try {
                var real = this.state.valor.replace('.', '');
                real = real.replace(',', '.');
                
                await api_topmotos.put('/despesas/update/id/' + this.state.id, {
                    descricao: this.state.descricao,
                    valor: real,
                    despesa_constante: this.state.despesa_constante ? 1 : 0
                })

                this.toggleModalAlterar()
                this.limparState()
                this.listarDespesas()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
                this.log(this.state.error);
            }
            
        } else {

            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var check = document.getElementById("check" + id);

        try {
            await api_topmotos.post('/checklistD/ativar/id/' + id, {
                id: this.state.id,
                check: check.checked == true ? 0 : 1
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
            this.log(this.state.error);
        }
    }

    limparState = () => {
        this.setState({
            descricao: '',
            id: '',
            valor: '',
            despesa_const: ''
        })
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Despesas</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => {this.setState({modalCadastrar: true}); this.limparState()}}>Adicionar Despesas<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-head mt-3"></div>
                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Descrição
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Valor Padrão
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Despesa fixa em Compra
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.despesas.map((des) => (
                                                <tr key={des.id}>
                                                    <td>{des.id}</td>
                                                    <td>{des.descricao}</td>
                                                    <td>{des.valor_padrao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td>{des.despesa_const ? 'Sim' : 'Não'}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-pen" onClick={() => {this.OpenModalAlteracao(des.id)}}></i>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div class="form-footer"><span></span></div>
                            </div>

                            {/* PERGUNTAS */}
                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Despesa</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="descricao" id="lblDescricao">Descricao</label>
                                                        <div className="grupo-campo-input input-cad" id="descricao">
                                                            <i className="fas fa-pen" id="iconDescricao"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva a descrição da despesa"
                                                                value={this.state.descricao}
                                                                onChange={event => this.setState({ descricao: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="valor" id="lblValor">Valor Padrão</label>
                                                        <div className="grupo-campo-input input-cad" id="valor">
                                                            <i className="fas fa-dollar-sign" id="iconValor"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Informe o valor"
                                                                value={this.state.valor}
                                                                onChange={event => this.mascaraReal(event.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <div className="check-itens">
                                                            <input type="checkbox" id="input-venda" name={"pagamento"} onChange={event => this.setState({despesa_constante: !this.state.despesa_constante})}/>
                                                            <label htmlFor={"input-venda"}>Despesa fixa em compra</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarDespesa}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Despesa</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="descricao" id="lblDescricao">Descricao</label>
                                                        <div className="grupo-campo-input input-cad" id="descricao">
                                                            <i className="fas fa-pen" id="iconDescricao"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Escreva a descrição da despesa"
                                                                value={this.state.descricao}
                                                                onChange={event => this.setState({ descricao: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="valor" id="lblValor">Valor Padrão</label>
                                                        <div className="grupo-campo-input input-cad" id="valor">
                                                            <i className="fas fa-dollar-sign" id="iconValor"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Informe o valor"
                                                                value={this.state.valor}
                                                                onChange={event => this.mascaraReal(event.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <div className="check-itens">
                                                            <input type="checkbox" id="input-venda" name={"pagamento"} checked={this.state.despesa_constante} onChange={event => this.setState({despesa_constante: !this.state.despesa_constante})}/>
                                                            <label htmlFor={"input-venda"}>Despesa fixa em compra</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarDespesa}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default DespesaPage