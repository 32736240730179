import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

class RelatorioPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            compras: [],
            vendas: [],
            vendasP: [],
            comprasP: [],
            meses: [],
            inicio: '',
            fim: ''
		};
    }

    componentDidMount() {
        this.apiListarCompras();
        this.apiListarVendas();
    }

    apiListarCompras = async event => {
		let res = await api_topmotos.get('/compras/all');
        this.setState({ compras: res.data, comprasP: res.data });
        this.data('', 1);
    }

    apiListarVendas = async event => {
		let res = await api_topmotos.get('/vendas');
        this.setState({ vendas: res.data, vendasP: res.data });
        console.log(res.data)
        this.data('', 2);
    }

    selecionarPeriodo = (final) =>{
        var partesDataIni = this.state.inicio.split("-");
        var partesDataFim = final.split("-");
        var inicio = new Date(partesDataIni[0], partesDataIni[1] - 1, '01');
        var fim = new Date(partesDataFim[0], partesDataFim[1] - 1, '31');

        this.filtroCompraData(inicio, fim)
        this.filtroVendaData(inicio, fim)
    }

    data = (tipo) =>{
        let data = new Date();
        var inicio = new Date(data.getFullYear(), data.getMonth(), '01');
        var fim = new Date(data.getFullYear(), data.getMonth(), '31');
    
        if(tipo == 1){
            this.filtroCompraData(inicio, fim)
        } else if(tipo == 2){
            this.filtroVendaData(inicio, fim)
        }
    }

    filtroCompraData = async (inicio, fim) =>{
        let res = await this.state.compras.filter((dados) => {
            var strData = moment(dados.data).format('DD-MM-YYYY');
            var partesData = strData.split("-");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            
            return data >= inicio &&
                data <= fim
        })

        this.setState({comprasP: res})
        
    }

    filtroVendaData = async (inicio, fim) =>{
        let res = await this.state.vendas.filter((dados) => {
            var strData = moment(dados.venda_admissaodata).format('DD-MM-YYYY');
            var partesData = strData.split("-");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            
            return data >= inicio &&
                data <= fim
        })

        this.setState({vendasP: res})
    }
    
    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-head">
                                <strong>
                                    <p>Relatório Compras e Vendas</p>
                                </strong>
                                <div className="campo-data">
                                    <input type="month" onChange={a => this.setState({inicio: a.target.value})}/>
                                </div>
                                <div className="campo-data">
                                    <input type="month" onChange={a => {this.setState({fim: a.target.value}); this.selecionarPeriodo(a.target.value)}}/>
                                </div>
                            </div>
                            <div className="relatorio-body">
                                <div className="info-box">
                                    <p>Total de Compras Realizadas</p>
                                    {this.state.comprasP.length}
                                </div>
                                <div className="info-box">
                                    <p>Total de Vendas Realizadas</p>
                                    {this.state.vendasP.length}
                                </div>
                                {
								}
                            </div>
                            <div className="relatorio-footer">
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default RelatorioPage