import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import jwt from 'jwt-simple';


class CadastrarAtendimentoPage extends React.Component {
    constructor(props) {
        super(props);

        let tk = jwt.decode(localStorage.getItem("token"), "GoldWindLaw")
        tk = tk.iud

        this.state = {
            clientes: [],
            tipo_atend: [],
            errorMessage: '',

            cliente: null,
            desc: null,
            tipo: null,

            clientFocus: false,
            clientFocus2: false,
            clientName: '',
            userid: tk,

            retorno: '',

            marcas: [],
            modelos: [],
            marcaSE: "NULL",
            modeleSE: '',
            options: [],
            itens:[],
            motivo: ''
        };
    }

    componentDidMount() {
        this.apiListarClientes();
        this.apiListarItens();
        this.apiListarMarcas();
        this.apiListarModelos();

    }

    apiListarClientes = async event => {
        let res = await api_topmotos.get('/pessoas/');
        this.setState({ clientes: res.data });
    }

    apiListarItens = async event => {
        let res = await api_topmotos.get('/atend/itens/');
        this.setState({ tipo_atend: res.data });
    }

    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelos: res.data });
    }

    verificarCampo = () =>{
        const {
            cliente,
            tipo
        } = this.state;

        var inputs = [
            [cliente, 'cliente', 'iconCliente', 'lblCliente'],
			[tipo, 'atendimento', 'iconAtendimento', 'lblAtendimento']
        ]

        for(var i = 0; i < inputs.length; i++){
            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                
            } else{
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }
        }	
    }

    apiCadastrarAtend = async () => {
        this.verificarCampo();

        if (this.state.tipo && this.state.cliente) {
            await api_topmotos.post('/atend/create/', {
                tipo: this.state.tipo,
                cliente: this.state.cliente,
                desc: this.state.desc,
                user: this.state.userid,
                retorno: this.state.retorno,
                itens: this.state.itens,
                motivo: this.state.motivo
            });

            this.props.history.push('/atendimento')
        }
        else {
            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    configAutoComplete = async (e) => {
        this.setState({clientName: e.target.value});

        if (e.target.value.length == 0){
            this.setState({cliente: null})
            return
        }

        let c = this.state.clientes.filter(y => y.pessoa_nome.toLowerCase().includes(e.target.value.toLowerCase()))
        if (c.length > 0){
            this.setState({cliente: c[0].pessoa_id})
        }
        else{
            this.setState({cliente: null})
        }   
    }

    handleItens = (valores, modulo) => {
        console.log(valores);
        this.state.itens = valores.map((item) => (
            item.value
        ))
    }

    removeitem = async (index) => {
        let s = this.state.options;
        let i = this.state.itens;

        s.splice(index, 1);
        i.splice(index, 1);

        this.setState({options: s, itens: i})
    }

    teste = (a) =>{
        console.log(a)
    }

    
    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir atendimento</strong></p>

                            </div>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="client" id="lblCliente">Cliente*</label>
                                            <div className="grupo-campo-input input-cad" id="cliente">
                                                <i className="fas fa-user" id="iconCliente"></i>
                                                <input 
                                                    type="text" 
                                                    placeholder="Selecione um cliente" 
                                                    value={this.state.clientName} 
                                                    onFocus={a => this.setState({clientFocus: true})} 
                                                    onBlur={a => this.setState({clientFocus: false})} 
                                                    onChange={this.configAutoComplete} 
                                                />

                                            </div>
                                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                {
                                                    this.state.clientes.filter(y => y.pessoa_nome.toLowerCase().includes(this.state.clientName.toLowerCase())).map(e => (
                                                        <li onClick={a => this.setState({clientName: e.pessoa_nome, cliente: e.pessoa_id})}>{e.pessoa_nome}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="atendimento" id="lblAtendimento">Tipo de atendimento*</label>
                                            <div className="grupo-campo-input input-cad" id="atendimento">
                                                <i className="fas fa-briefcase" id="iconAtendimento"></i>
                                                <select
                                                    name="empresa"
                                                    placeholder="&nbsp;Selecione a Empresa vendedora"
                                                    autoComplete="on"
                                                    required
                                                    onChange={a => this.setState({ tipo: a.target.value })}
                                                >
                                                    <option value="NULL">Selecione o tipo de atendimento</option>
                                                    {
                                                        this.state.tipo_atend.map((e, id) => (
                                                            <option key={e.id} value={e.id}>{e.item}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="motivo">Motivo da não venda?</label>
                                            <div className="grupo-campo-input input-cad" id="motivo">
                                                <i class="far fa-question-circle"></i>
                                                <select
                                                    name="nivel"
                                                    placeholder="&nbsp;Selecione o motivo"
                                                    autoComplete="on"
                                                    value={this.state.motivo}
                                                    onChange={a => this.setState({motivo: a.target.value})}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o motivo</option>
                                                    <option value="Não tem">Não tem em estoque o produto</option>
                                                    <option value="S/ Entr">Cliente não tem o valor da entrada para o financiamento</option>
                                                    <option value="Restr">Restriçao no nome do cliente</option>
                                                    <option value="Conc">Comprou no concorrente</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="empresa">Descrição (opcional) </label>
                                            <div className="grupo-campo-input input-cad hightop" id="empresa">
                                                <i className="fas fa-briefcase" id="iconEmpresa"></i>
                                                <textarea placeholder="Insira um descrição" type="text" onChange={a => this.setState({ desc: a.target.value })} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="">Data de retorno </label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="far fa-calendar-alt"></i>
                                                <input type="date" 
                                                    value={this.state.retorno}
                                                    onChange={event => this.setState({ retorno: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="imark">Marca</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i className="fas fa-envelope" id="iema"></i>
                                                <select name="" id="imark" onChange={e => this.setState({ marcaSE: e.target.value })}>
                                                    <option value="NULL">Selecione uma marca</option>
                                                    {
                                                        this.state.marcas.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="imol">Modelo</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i className="fas fa-envelope" id="imol"></i>
                                                <input 
                                                    placeholder="Insira o modelo" 
                                                    value={this.state.modeleSE} 
                                                    onChange={a => this.setState({modeleSE: a.target.value})} 
                                                    disabled={this.state.marcaSE == "NULL"} 
                                                    type="text" 
                                                    onFocus={a => this.setState({ clientFocus2: true })} 
                                                    onBlur={a => this.setState({ clientFocus2: false })} />
                                            </div>
                                            <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                {
                                                    this.state.marcaSE != "NULL" ?
                                                        (this.state.modelos.filter(y => !this.state.itens.includes(y.id) && y.id_marca == this.state.marcaSE && y.nome.toLowerCase().includes(this.state.modeleSE.toLowerCase())).slice(0, 10).map(e => (
                                                            <li onClick={a => this.setState({options: this.state.options.concat(e), itens: this.state.itens.concat(e.id) })}>{e.nome}</li>
                                                        )))
                                                        : (null)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>                       

                                <div className="itensmodsall">
                                    {
                                        this.state.options.map((e, index) => (
                                            <div className="itemMods">
                                                {e.marcaName} - {e.nome}
                                                <i className="fas fa-times" onClick={a => this.removeitem(index)}></i>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarAtend} className="cadbtn mt-3">Inserir</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default CadastrarAtendimentoPage