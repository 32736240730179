import React from 'react';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

class PDFImpressaoPage extends React.Component {
    constructor(props){
        super(props);


        this.state = {
            modelo: '',
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas" || a.rota == "/vendas/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount(){
        // if(await this.verificarPermissao()){
        //     this.props.history.push('/dashboardcomercial')
        // } else{
            await this.apiListarModelo(this.props.match.params.modelo)
            await this.apiListarOrdem(this.props.match.params.item, this.props.match.params.tipo, decodeURI(this.props.location.search.split("=")[1]))
        // }
    }

    apiListarModelo = async (id) => {
        let {data} = await api_topmotos.post("/modelosdoc/unique", {id: id})
				console.log(data[0].conteudo)
        this.setState({modelo: data[0].conteudo})
    }

    apiListarOrdem = async (item, tipo, oficina) => {
				let {data} = await api_topmotos.get("/oficina/id/" +item+ "/tipo/" + tipo)

        await this.apiVeiculoId(item);
				await this.apiMovimentacoesId(item, tipo, oficina)

        let modelo = this.state.modelo;

        modelo = modelo.replace(/@nomeServico/g, data[0].oficina_tipo.toUpperCase())
				.replace(/@data /g, moment().format('DD/MM/YYYY'))
				.replace(/@observacao/g, data[0].oficina_descricao ? data[0].oficina_descricao : '')
				.replace(/@dataEntrada/g, data[0].oficina_data_saida ? moment(data[0].oficina_data_saida).format('DD/MM/YYYY') : '')

				this.setState({modelo: modelo}) 
				console.log(modelo)
        await this.openPdf()
    }

		apiListarIten = async (id) => {
				let res = await api_topmotos.get('/vendaitens')
				let item = res.data.filter(r => r.vendaitens_item == id)

				let venda = await api_topmotos.post("/vendas/unique", {id: item[0].vendaitens_venda})
				let {data} = await api_topmotos.get("/pessoas/id/" + venda.data.venda[0].venda_cliente)

        let modelo = this.state.modelo;  

				const endereco = data[0].endereco_logradouro+", "+data[0].endereco_numero+" - "+data[0].endereco_bairro+". CEP: "+data[0].endereco_cep

        modelo = modelo.replace(/@nomePessoa/g, data[0].pessoa_nome)
				.replace(/@enderecoPessoa/g, endereco)
        .replace(/@cpfPessoa/g, data[0].pessoa_cpf_cnpj)
        .replace(/@cidadePessoa/g, data[0].endereco_cidade+" - "+data[0].endereco_estado)
        .replace(/@telefonePessoa/g, data[0].pessoa_celular || data[0].pessoa_telefone || '')
        this.setState({modelo: modelo})		 
		}

    apiVeiculoId = async (id) =>{
        let {data} = await api_topmotos.post("/itens/unique", {id: id})

				await this.apiListarIten(id);
            
        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@modeloVeiculo/g, data[0].item_modelo ? data[0].item_modelo : '')
        .replace(/@placaVeiculo/g, data[0].placa ? data[0].placa : '')
				.replace(/@anoFVeiculo/g, data[0].item_ano_fab)
				.replace(/@corVeiculo/g, data[0].cor)
				.replace(/@kmVeiculo/g, data[0].item_km)

        this.setState({modelo: modelo}) ;
    }

		apiMovimentacoesId = async (id, tipo, oficina) =>{
			let {data} = await api_topmotos.get("/oficina/id/"+id+"/tipo/"+tipo);
			const itens = data.filter(i => {
				return i.oficina_id == oficina
			})

			let modelo = this.state.modelo; 

			if(itens){
					let tabela = [];
					let total = 0;
					tabela.push('<table>')
					tabela.push(
							`<tr>
									<th>Produto</th>
									<th>Valor Unítario</th>
									<th>Quantidade</th>
									<th>Valor Total</th>
							</tr>`
					)
	
					for(var i of itens){
							total += i.produto_valor;
							tabela.push(
									`<tr>
											<td>${i.produto_nome}</td>
											<td>${parseFloat(i.produto_valor / i.oficina_produto_quantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
											<td>${i.oficina_produto_quantidade}</td>
											<td>${parseFloat(i.produto_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
									</tr>`
							)
					}

					tabela.push(
							`<tr>
									<th colspan="3">Total</th>
									<td>${parseFloat(total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>		
							</tr>`
					)
	
					tabela.push('</table>')

					tabela = tabela.join('')
	
					modelo = modelo.replace(/@formaPagamento/g, tabela)
			} else{
					modelo = modelo.replace(/@formaPagamento/g, '')
			}

			this.setState({modelo: modelo})   
		}

    openPdf = async () =>{
        window.print();
    }

    render() {
        return (
            <>
            <section className="container d-flex align-item-center py-5">
                <div className="">
									<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
										<img src="/logoExterno.png" alt="" style={{height: "100px"}}/>;
									</div>
									<br />
                  <div dangerouslySetInnerHTML={{__html: this.state.modelo}} style={{display: "flex", width: "80%"}}>

                  </div>
									<br />
									<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
										<img src="/contatoExterno.png" alt="" style={{height: "120px"}}/>;
									</div>
                </div>
            </section>
            </>
        )
    }
}

export default PDFImpressaoPage;