import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Leads extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],
			
			modalDetalhesOpen: false,
            modalDetalhesId: 0,
			modalDetalhesNome: '',
			
			marcas: [],
			modelos: [],
			nome: '',
			email: '',
			telefone: '',
			conheceu: '',
			retorno: '',
			retornoData: '',
			
			modelosUn: [],
			dataAdmissao: '', 
			dataAtualizacao: ''
		};
	}

	componentDidMount() {
		this.apiListarLeads();
		this.apiListarMarcas();
		this.apiListarModelos();
	}

	apiListarLeads = async event => {
		let res = await api_topmotos.get('/leads/');
		this.setState({ dados: res.data });
	}

	log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
	}
	
    // Modal Detalhes

    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelos: res.data });
    }

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
    }
	
	modalDetalhes = async (e) => {
		let {data} = await api_topmotos.post("/leads/unique", {id: e});
        let it = []
		
        for (let i of data.itens){
            it.push(i.leaditens_item);
		}

        let m = this.state.modelos.filter(y => it.includes(y.id)).sort((a, b) => a.id > b.id);
        it = []

        for (let i of m){
            it.push(i.nome);
		}


        this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: data.lead[0].lead_id,
			modalDetalhesNome: data.lead[0].lead_nome,
			nome: data.lead[0].lead_nome,
			email: data.lead[0].lead_email,
			telefone: data.lead[0].lead_telefone,
			conheceu: data.lead[0].conhecimento_loja,
			retorno: data.lead[0].status_retorno,
			retornoData: data.lead[0].data_retorno ? moment(data.lead[0].data_retorno).format('DD/MM/YY HH:mm') : '',
			dataAdmissao: moment(data.lead[0].lead_admissaodata).format('DD/MM/YY HH:mm'),
			dataAtualizacao: data.lead[0].lead_atualizacaodata ? moment(data.lead[0].lead_atualizacaodata).format('DD/MM/YY HH:mm') : '',
            modelosUn: it
        })
	}

	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Leads</p>
							<div className="d-flex">
								<div className="form-relatorio" onClick={() => this.props.history.push("/leads/relatorio")}>
									<p>Relatório</p> <i class="far fa-clipboard"></i>
								</div> 
								<div className="form-add" onClick={() => this.props.history.push("/cadastrarleads")}>
									Adicionar <i className="fas fa-plus"></i>
								</div> 
							</div>
						</div>
						
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Telefone
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												E-mail
													<i className="fas fa-sort"></i>
											</div>
										</th>

										<th>
											<div className="d-flex align-center justify-between">
												Cliente
													<i className="fas fa-sort"></i>
											</div>
										</th>
										
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.dados.map((lead, id) => (
											<tr>
												<td>{lead.lead_id}</td>
												<td>{lead.lead_nome}</td>
												<td>{lead.lead_telefone}</td>
												<td>{lead.lead_email}</td>
												<td>{lead.cliente == null ? "Não" : "Sim"}</td>
												<td className="td-acoes">
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(lead.lead_id)}></i>&nbsp;
													<i className="fas fa-pen" onClick={() => this.props.history.push("/editarleads/" + lead.lead_id)}></i>&nbsp;
													<i class="fas fa-retweet" onClick={() => this.props.history.push("/cadastrarcliente/" + lead.lead_id)}></i>&nbsp;
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
							<div className="form-footer">
								<span></span>
							</div>
						</div>
						<Versionamento />
					</div>
				</div>
				
				{/* MODAL DETALHES */}
				<Modal
                        isOpen={this.state.modalDetalhesOpen}
                        toggle={this.toggleModalDetalhes.bind(this)}
                        backdrop={true}
                        keyboard={true}
                        size="lg"
                        fade={false} >
                        <ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesId} - {this.state.modalDetalhesNome}</ModalHeader>
                        <ModalBody>
                            <div className="box-conteudo">
                                <div className="conteudo-info">
                                    <p>Nome</p>
                                    <p>{this.state.nome}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>E-mail</p>
                                    <p>{this.state.email}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Telefone</p>
                                    <p>{this.state.telefone}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Como conheceu a loja?</p>
                                    <p>{this.state.conheceu}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Marcou Retorno?</p>
                                    <p>{this.state.retorno}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data de retorno</p>
                                    <p>{this.state.retornoData}</p>
                                </div>
								<div className="conteudo-info">
                                    <p>Data de Admissão</p>
                                    <p>{this.state.dataAdmissao}</p>
                                </div>
                                <div className="conteudo-info">
                                    <p>Data de atualização</p>
                                    <p>{this.state.dataAtualizacao}</p>
                                </div>
                                <div className="conteudo-info-modelos">
                                    <p>Modelos</p>
                                    {this.state.modelosUn.map(e => (
                                        <>
                                        <li>{e}</li>
                                        </>))
                                    }
                                    {this.state.modelosUn.length == 0 && <li>Nenhum modelo de interesse</li>}
                                </div>
                                
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
			</section>

		)
	}
}

export default Leads;