import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { ListItemText } from '@material-ui/core';

class NotasDetalhes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dados: [],

			pageSize: 1,

			modalDetalhesOpen: false,

			modalDetalhesId: '',
			modalDetalhesEstoque: '',

			modalDetalhesNome: '',
			modalDetalhesDescricao: '',
			modalDetalhesTipo: '',
			modalDetalhesFornecedor: '',
			modalDetalhesRefInterna: '',
			modalDetalhesRefExterna: '',

			id: '',
			data: '',
			quantidade: '',
			valor: '',

			pageLimit: 10,
			pageAtual: 0,
		};
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] produtosUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/notas")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarProduto();
		}
	}

	apiListarProduto = async () => {
		let res = await api_topmotos.get('/notas/id/' + this.props.match.params.id);
		this.setState({ dados: res.data })
		this.state.pageSize = Math.ceil(res.data.length / this.state.pageLimit);
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idProduto) => {
		let produto = this.state.dados.filter((p) => {
			return p.produto_id === idProduto;
		});
		produto = produto[0]

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: produto.produto_id,
			modalDetalhesNome: produto.produto_nome,
			modalDetalhesDescricao: produto.produto_descricao,
			modalDetalhesTipo: produto.tipo_nome,
			modalDetalhesFornecedor: produto.fornecedor_nome,
			modalDetalhesRefInterna: produto.refInterna,
			modalDetalhesRefExterna: produto.refExterna,
		});
	}

	/**
	 * @description Organiza a paginação e ativa um indice
	 */
	putItem = (pageAtual, index) => {

		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.state.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	nextorprevious = (e, index) => {
		e.preventDefault();

		this.setState({ pageAtual: index })
	}

	render() {
		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Produtos da Nota (ID: {this.props.match.params.id})</p>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Tipo
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Quantidade
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Unitario
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Total
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
												</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.dados.slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((produto, id) => (
											<tr key={produto.produto_id}>
												<td>{produto.produto_id}</td>
												<td>{produto.produto_nome}</td>
												<td>{produto.tipo_nome}</td>
												<td>{produto.quantidade}</td>
												<td>
													{parseFloat(produto.produto_valor / produto.quantidade)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
												</td>
												<td>
													{parseFloat(produto.produto_valor)
														.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
												</td>
												<td className="td-acoes">
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(produto.produto_id)}></i>&nbsp;
												</td>
											</tr>
										))
									}

								</tbody>
								<tr style={{ width: '100%' }}>

								</tr>
							</table>

							<div className="form-footer">
								{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
									</div>

									{
										[...Array(this.state.pageSize)].map((e, index) => (
											this.putItem(this.state.pageAtual, index)
										))
									}

									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.state.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
									</div>
								</div>}
							</div>
						</div>
						<Versionamento />
					</div>
				</div>


				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesNome} (ID: {this.state.modalDetalhesId})</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Nome</p>
								<p>{this.state.modalDetalhesNome}</p>
							</div>
							<div className="conteudo-info">
								<p>Descrição</p>
								<p>{this.state.modalDetalhesDescricao}</p>
							</div>
							<div className="conteudo-info">
								<p>Fornecedor</p>
								<p>{this.state.modalDetalhesFornecedor}</p>
							</div>
							<div className="conteudo-info">
								<p>Tipo</p>
								<p>{this.state.modalDetalhesTipo}</p>
							</div>
							<div className="conteudo-info">
								<p>Referencia Externa</p>
								<p>{this.state.modalDetalhesRefExterna}</p>
							</div>
							<div className="conteudo-info">
								<p>Referencia Interna</p>
								<p>{this.state.modalDetalhesRefInterna}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

			</section>
		)
	}
}

export default NotasDetalhes;
