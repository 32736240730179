import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import InputMask from 'react-input-mask';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ItemEditarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            especies: [],
            dados: [],
            modalCadastrarOpen: false,
            modalCadastrarMarca: '',
            modalCadastrarModelo: '',
            modalCadastrarAno: 0,
            modalCadastrarKm: 0.0,
            modalCadastrarCor: '',
            modalCadastrarCombustivel: '',
            modalCadastrarPreco: 0.0,
            modalCadastrarDescricao: '',
            modalCadastrarEstoque: 0,
            modalCadastrarFornecedor: 0,
            modalCadastrarTipo: '',
            modalCadastrarEstilo: '',
            modalCadastrarPropriedades: '',
            modalCadastrarObservacao: '',
            comprador: null,

            terceiro: '',

            forname: '',
            clientFocus: false,
            imgsFile: [],
            imgsView: [],
            idIten: 0,
            consig: false,
            chassi: '',
            renavam: '',
            openmodal: false,
            
            yesp: false,
            modelos: [],
            modelSE: 'NULL',
            itpdf: null,
            tipo_i: '',
            placa: '',

            marcaSE: 'NULL',
            clientFocus2: false,
            modelosvei: [],
            marcas: [],
            modeloSE: '',
            status: 'NULL',
            statusList: [],
            hostimg: [],
            
            cores: [],
            coresSE: 'NULL',

            combustiveis: [],
            combustiveisSE: 'NULL',

            estilos: [],
            estilosSE: 'NULL',

            propriedades: [],
            propriedadesSE: 'NULL',

            anos: [],
            anosSE: '',

            modeloId: '',
            anoF: '',

            capa: 's',
            motoNomeSE: '',
            especie: '',
            now: false,
            comprador_name: '',
        }
        
    }

    verificarPermissaoStatus = async event => {
        if(localStorage.getItem('usuario_id')){
            let des = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
			let editar = des.data.filter(a => a.restricao_id == 6);
			
            if(editar.length == 0){
                return true
            } else {
                return false
            }
        } else{
            return true
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let des = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
			let editar = des.data.filter(a => a.restricao_id == 2);
			
            if(editar.length == 0){
                return true
            } else {
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.setState({pStatus: await this.verificarPermissaoStatus()})
            await this.apiListarFornecedores();
            this.apiListarModelosDoc();
            await this.apiListarModelos();
            await this.apiListarMarcas();
            await this.apiListarCores();
            await this.apiListarCombustivel();
            await this.apiListarEspecies();
            await this.apiListarEstilos();
            await this.apiListarPropriedades();
            await this.apiListarAnos();
            this.apiListarItem(this.props.match.params.id);
        }
    }

    // Espécies
    apiListarEspecies = async () => {
        let res = await api_topmotos.get('/especie');
        this.setState({ especies: res.data });
    }

    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    apiListarModelos = async (mes) => {
        if(mes){
            let res = await api_topmotos.post('/modelos/mes', {mes: mes});
            this.setState({ modelosvei: res.data });
        } else{
            let res = await api_topmotos.get('/modelos/');
            this.setState({ modelosvei: res.data });
        }
    }

    apiListarCores = async event =>{
        let res = await api_topmotos.get('/cores/');
        this.setState({ cores: res.data });
    }

    apiListarCombustivel = async event =>{
        let res = await api_topmotos.get('/combustivel/');
        this.setState({ combustiveis: res.data });
    }

    apiListarEstilos = async event =>{
        let res = await api_topmotos.get('/estilo/');
        this.setState({ estilos: res.data });
    }

    apiListarPropriedades = async event =>{
        let res = await api_topmotos.get('/propriedades/');
        this.setState({ propriedades: res.data });
    }

    // Anos
    apiListarAnos = async (ano) => {
        let res = await api_topmotos.get('/anos/');
        
        if(ano){
            for(let i of ano){
                i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
            }
            
            this.setState({ anos: ano });
        }else{
            for(let i of res.data){
                i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
            }

            this.setState({ anos: res.data });
        }

    }

    apiListarItem = async e => {
        let resItem = await api_topmotos.get('/itens/id/' + e);

        let {data} = await api_topmotos.get('/status');
        let imgs = resItem.data.imgs;
        resItem = resItem.data.item[0];

        // const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
        
        // const d = new Date(resItem.item_admissaodata);
	    // var referencia = monthNames[d.getMonth()] + "/" +d.getFullYear();

        // let res = await api_topmotos.post('/anos/mes', {mes: referencia});
        // this.setState({ anos: res.data });

        // let anI = resItem.item_ano;
        // let anos = this.state.anos.filter(y => y.id == resItem.item_ano)[0] || []
        // anos.ano == 3200 ? anos.ano = 'Zero Km' : anos.ano = anos.ano 

        let res = await api_topmotos.get('/anos/mes/id/' + resItem.item_ano);
        let ano = res.data.filter(a =>{ return a.ano == resItem.ano_mod && a.id_modelo == resItem.id_modelo})

        this.apiListarAnos(res.data);
        this.apiListarModelos(res.data[0].mes_referencia)

        let ma = this.state.marcas.filter(y => y.nome == resItem.item_marca)[0] || []
        // let mo = this.state.modelosvei.filter(y => y.id == anos.id_modelo)[0] || []
        let fo = this.state.dados.filter(y => y.pessoa_id == resItem.item_fornecedor)[0] || []
        let co = this.state.cores.filter(y => y.id == resItem.cor_id)[0] || []
        let cb = this.state.combustiveis.filter(y => y.id == resItem.combustivel_id)[0] || []  
        let es = this.state.estilos.filter(y => y.id == resItem.estilo_id)[0] || []  
        let especie = this.state.especies.filter(y => y.id == resItem.item_especie)[0] || []  
        let cn = this.state.dados.filter(y => y.pessoa_id == resItem.comprador)[0] || []

        this.setState({
            especie: especie.id,
            motoNomeSE: resItem.motoNome,
            terceiro: resItem.terceira_pessoa,
            modalCadastrarAno: ano[0].id,
            modalCadastrarKm: resItem.item_km,
            modalCadastrarFornecedor: resItem.item_fornecedor,
            modalCadastrarTipo: resItem.item_tipo,
            chassi: resItem.chassi,
            placa: resItem.placa,
            renavam: resItem.renavam,
            chassi: resItem.chassi,
            consig: resItem.consignado,
            marcaSE: ma.id || "NULL",
            modalCadastrarMarca: ma.nome || null,
            modalCadastrarModelo: resItem.item_modelo || null,
            modeloSE: resItem.item_modelo || "NULL",
            modeloId: resItem.id_modelo || null,
            modalCadastrarCor: co.id || null,
            coresSE: co.id || "NULL",
            modalCadastrarEstilo: es.id || null,
            estilosSE: es.id || "NULL",
            combustiveisSE: cb.id || "NULL",
            modalCadastrarCombustivel: cb.id || null,
            modalCadastrarDescricao: resItem.item_descricao,
            modalCadastrarPreco: resItem.item_preco,
            forname: fo.pessoa_nome || "NULL",
            modalCadastrarFornecedor: fo.pessoa_id || null,
            propriedadesSE: resItem.prop_id || 'NULL',
            modalCadastrarPropriedades: resItem.prop_id  || null,
            modalCadastrarObservacao: resItem.observacao,
            status: resItem.status,
            statusList: data,
            anoF: resItem.item_ano_fab,
            hostimg: imgs,
            comprador: resItem.comprador || null,
            comprador_name: cn.pessoa_nome || "NULL",
        })

        this.abrirEstilo(resItem.item_tipo)
    }

    apiListarFornecedores = async event => {
        let res = await api_topmotos.get('/pessoas/');
        this.setState({ dados: res.data });
    }

    apiListarModelosDoc = async event => {
        let res = await api_topmotos.get('/modelosdoc/pro/');
        this.setState({ modelos: res.data });
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiCadastrarItem = async e => {
        e.preventDefault();

        const {
            especie,
            motoNomeSE,
            modalCadastrarModelo,
            modalCadastrarMarca,
            modalCadastrarAno,
            modalCadastrarKm,
            modalCadastrarCor,
            modalCadastrarCombustivel,
            modalCadastrarPreco,
            modalCadastrarDescricao,
            modalCadastrarEstoque,
            modalCadastrarFornecedor,
            modalCadastrarTipo,
            modalCadastrarEstilo,
            modalCadastrarPropriedades,
            modalCadastrarObservacao,
            anoF,
            comprador
        } = this.state;

        if (!modalCadastrarPreco ||
            !modalCadastrarModelo ||
            !modalCadastrarMarca ||
            !modalCadastrarAno ||
            !modalCadastrarKm ||
            !modalCadastrarCor ||
            !modalCadastrarCombustivel ||
            !modalCadastrarTipo ||
            !anoF ||
            modalCadastrarTipo === 'NULL' ||
            this.state.marcaSE === "NULL" ||
            this.state.coresSE === "NULL" ||
            this.state.combustiveisSE === "NULL" ||
            this.state.status === 'NULL' ||
            modalCadastrarAno === '____') {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
            alert("Preencha todos os dados para cadastrar!")

        } else {
            this.setState({ errorMessage: '' });

            let re;

            let ids = []

            for (let i of this.state.hostimg){
                ids.push(i.itemfotos_id)
            }

            if(typeof this.state.modalCadastrarPreco != 'number'){
                var real = this.state.modalCadastrarPreco.replace('.', '');
                real = real.replace(',', '.');
            } else{
                real = this.state.modalCadastrarPreco
            }

            let ano = this.state.anos.filter(a => a.id == modalCadastrarAno)
            ano[0].ano = ano[0].ano == "Zero Km" ? 3200 : ano[0].ano

            try {
                re = await api_topmotos.put('/itens/update/id/' + this.props.match.params.id, {
                    item_especie: especie,
                    item_motoNome: motoNomeSE,
                    item_modelo: modalCadastrarModelo,
                    item_marca: modalCadastrarMarca,
                    item_ano: modalCadastrarAno,
                    ano_mod: ano[0].ano,
                    item_km: modalCadastrarKm,
                    cor_id: modalCadastrarCor,
                    combustivel_id: modalCadastrarCombustivel,
                    item_preco: real ? real : null,
                    item_descricao: modalCadastrarDescricao,
                    item_fornecedor: modalCadastrarFornecedor,
                    item_tipo: modalCadastrarTipo,
                    item_atualizacaodata: this.getTimestamp(),
                    chassi: this.state.chassi,
                    renavam: this.state.renavam,
                    placa: this.state.placa,
                    estilo_id: modalCadastrarEstilo,
                    status: this.state.status,
                    prop_id: modalCadastrarPropriedades,
                    anoF: anoF,
                    observacao: modalCadastrarObservacao,
                    id_modelo: this.state.modeloId,
                    imgs: ids,
                    comprador: comprador
                })
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/itens/create)" })
                this.log(this.state.error);
            }

            let cont = 0, capa;

            if (re && this.state.imgsFile.length > 0) {
                for (let i of this.state.imgsFile) {
                    let form = new FormData()
                    form.append("id", this.props.match.params.id)
                    form.append("img", i)
                    
                    await api_topmotos.post("/itens/foto", form).then(res => {
                        if(this.state.now == true){
                            if(this.state.capa == i){
                                console.log(this.state.capa)
                                capa = res.data.insertId
                            }
                        }
                    });

                    cont++; 
                }
            } else{
                if (typeof this.state.capa.isN === 'number') {
                    this.cadastrarCapa(this.state.capa)
                }
            }


            if (this.state.consig) {
                this.setState({ itpdf: this.props.match.params.id, openmodal: true })
            }
            else {
                this.props.history.push("/itens")
            }
        }
    }

    fotos = async () =>{

    }

    cadastrarCapa = async (capa) => {
        let res = await api_topmotos.get('/itens/fotos');
        res = res.data.filter(a => a.itemfotos_item == this.props.match.params.id && a.itemfotos_capa == 1)

        for(let i of res){
            await api_topmotos.post('/itens/capa/update/' + i.itemfotos_id)
        }

        console.log(capa)
        await api_topmotos.post('/itens/capa/' + capa)
    }

    configAutoComplete = async (e) => {
        this.setState({ forname: e.target.value });

        if (e.target.value.length == 0) {
            this.setState({ modalCadastrarFornecedor: null })
            return
        }

        let c = this.state.dados.filter(y => y.pessoa_nome.toLowerCase().includes(e.target.value.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarFornecedor: c[0].pessoa_id })
        }
        else {
            this.setState({ modalCadastrarFornecedor: null })
        }
    }

    configComprador = async (e) => {
        this.setState({ comprador_name: e.target.value });

        if (e.target.value.length == 0) {
            this.setState({ comprador: null })
            return
        }

        let c = this.state.dados.filter(y => y.pessoa_nome.toLowerCase().includes(e.target.value.toLowerCase()))
        if (c.length > 0) {
            this.setState({ comprador: c[0].pessoa_id })
        }
        else {
            this.setState({ comprador: null })
        }
    }

    renderImgs = (e) => {
        this.setState({ imgsFile: Array.from(e.target.files) })
        let self = this;

        for (let i of e.target.files) {

            let render = new FileReader();

            render.onload = (s) => {
                self.setState({ imgsView: self.state.imgsView.concat(s.target.result) })
            }

            render.readAsDataURL(i);
        }
    }

    removeimg = async (index) => {
        let ft = this.state.imgsView;
        let ftfiles = this.state.imgsFile;
        ft.splice(index, 1);
        ftfiles.splice(index, 1);

        if(this.state.capa == index){
            this.setState({capa: '', now: false})
        }

        this.setState({ imgsView: ft, imgsFile: ftfiles })
    }

    removeimghost = async (index, id) => {
        let ftfiles = this.state.hostimg;
        ftfiles.splice(index, 1);

        if(this.state.capa == id){
            this.setState({capa: '', now: false})
        }

        this.setState({ hostimg: ftfiles })

    }

    configModelo = async (e) => {
        this.setState({ modeloSE: e });

        if (e.length == 0) {
            this.setState({ modalCadastrarModelo: null })
            return
        }

        let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarModelo: c[0].nome })
        }
        else {
            this.setState({ modalCadastrarModelo: null })
        }
    }

    abrirEstilo = (tipo) =>{
        var estilo = document.getElementById('estilo');
        
        if(tipo != 'Moto' && tipo != 'Motoneta'){
            estilo.style.display = "none";
            this.setState({modalCadastrarEstilo: null});
        } else{
            estilo.style.display = "block";
        }
    }

    changeModalCadastrarTipo = (event) => {
        this.setState({ modalCadastrarTipo: event.target.value });
        this.abrirEstilo(event.target.value);
    }

    mascaraVenda = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({modalCadastrarPreco: real})
    }

    imgId = (id, capa) => {
        if(this.state.capa == id && capa == 'index'){
            return('capa--active')
        } else{
            if(capa == 1 && this.state.capa == 's'){
                return('capa--active')
            } else if(this.state.capa == id){
                return('capa--active')
            } 
        }
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.openmodal}>
                    <ModalHeader>
                        Imprimir procuração
                    </ModalHeader>
                    {
                        !this.state.yesp ? (
                            <>
                                <ModalBody>
                                    Deseja imprimir a procuração?
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={a => this.props.history.push("/itens")}>Não</Button>
                                    <Button className="btnora" onClick={a => this.setState({ yesp: true })}>Sim</Button>
                                </ModalFooter>
                            </>
                        ) : (
                                <>
                                    <ModalBody>
                                        Qual o modelo? <br />

                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="tipo">Modelo</label>
                                            <div className="grupo-campo-input input-cad" id="tipo">
                                                <i className="fas fa-wrench" id="iconTipo"></i>
                                                <select onChange={a => this.setState({ modelSE: a.target.value })}>
                                                    <option value="NULL">Selecione o modelo</option>
                                                    {
                                                        this.state.modelos.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        {
                                            this.state.modelSE != "NULL" ? (
                                                <Button className="btnora" onClick={a => this.props.history.push(`/pdf/${this.state.itpdf}/${this.state.modelSE}`)}>Imprimir</Button>
                                            ) : (null)
                                        }
                                    </ModalFooter>
                                </>
                            )
                    }
                </Modal>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar veículo</strong></p>
                            </div>

                            <div className="content-formulario">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="tipo">Tipo *</label>
                                            <div className="grupo-campo-input input-cad" id="tipo">
                                                <i className="fas fa-wrench" id="iconTipo"></i>
                                                <select
                                                    name="nivel"
                                                    placeholder="&nbsp;Selecione o Tipo do item"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarTipo}
                                                    onChange={this.changeModalCadastrarTipo}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o Tipo do item</option>
                                                    <option value="Moto">Motocicleta</option>
                                                    <option value="Motoneta">Motoneta</option>
                                                    <option value="Carro">Carro</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="estilo">
                                            <label htmlFor="estoque">Estilo</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="fas fa-motorcycle"></i>
                                                <select value={this.state.estilosSE} onChange={e => this.setState({ estilosSE: e.target.value, modalCadastrarEstilo: e.target.value})}>
                                                    <option value="NULL">Selecione um estilo</option>
                                                    {
                                                        this.state.estilos.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="marca">Marca *</label>
                                            <div className="grupo-campo-input input-cad" id="marca">
                                                <i className="fas fa-certificate" id="iconMarca"></i>
                                                <select value={this.state.marcaSE} id="imark" onChange={e => this.setState({ marcaSE: e.target.value, modalCadastrarMarca: e.target.selectedOptions[0].innerHTML, modalCadastrarModelo: null, modeloSE: '' })}>
                                                    <option value="NULL">Selecione uma marca</option>
                                                    {
                                                        this.state.marcas.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="modelo">Modelo *</label>
                                            <div className="grupo-campo-input input-cad" id="modelo">
                                                <i className="fas fa-asterisk" id="iconModelo"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Modelo do item"
                                                    autoComplete="on"
                                                    onChange={event => this.configModelo(event.target.value)}
                                                    required
                                                    onFocus={a => this.setState({ clientFocus2: true })} 
                                                    onBlur={a => this.setState({ clientFocus2: false })}
                                                    value={this.state.modeloSE}
                                                    disabled={this.state.marcaSE == "NULL"} 

                                                />
                                            </div>
                                            <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                {
                                                    this.state.marcaSE != "NULL" ?
                                                        (this.state.modelosvei.filter(y => y.id_marca == this.state.marcaSE && y.nome.toLowerCase().includes(this.state.modeloSE.toLowerCase())).map(e => (
                                                            <li onClick={a => this.setState({modeloSE: e.nome, modalCadastrarModelo: e.nome, modeloId: e.id})}>{e.nome}</li>
                                                        )))
                                                        : (null)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="ano">Ano do Modelo*</label>
                                            <div className="grupo-campo-input input-cad" id="ano">
                                                <i className="fas fa-calendar-alt" id="iconAno"></i>
                                                <select name="" value={this.state.modalCadastrarAno} id="imark" onChange={e => this.setState({modalCadastrarAno: e.target.value})}>
                                                    <option value="NULL">Selecione o ano</option>
                                                    {
                                                        this.state.anos.filter((ano) => {
                                                            return ano.id_modelo == this.state.modeloId}).map(e => (
                                                            <option value={e.id}>{e.ano}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="anoF" id="lblAnoF">Ano de Fabricação*</label>
                                            <div className="grupo-campo-input input-cad" id="anoF">
                                                <i className="fas fa-calendar-alt" id="iconAnoF"></i>
                                                <input
                                                    type="number"
                                                    min="1985"
                                                    value={this.state.anoF}
                                                    onChange={a => this.setState({anoF: a.target.value})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="km">Quilometragem *</label>
                                            <div className="grupo-campo-input input-cad" id="km">
                                                <i className="fa fa-tachometer-alt" id="iconKm"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe a Quilometragem do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarKm: event.target.value })}
                                                    required
                                                    value={this.state.modalCadastrarKm}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cor">Cor *</label>
                                            <div className="grupo-campo-input input-cad" id="cor">
                                                <i className="fas fa-paint-roller" id="iconCor"></i>
                                                <select value={this.state.coresSE} onChange={e => this.setState({ coresSE: e.target.value, modalCadastrarCor: e.target.value})}>
                                                    <option value="NULL">Selecione uma cor</option>
                                                    {
                                                        this.state.cores.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="combustivel">Combustível *</label>
                                            <div className="grupo-campo-input input-cad" id="combustivel">
                                                <i className="fa fa-gas-pump" id="iconCombustivel"></i>
                                                <select value={this.state.combustiveisSE} onChange={e => this.setState({ combustiveisSE: e.target.value, modalCadastrarCombustivel: e.target.value})}>
                                                    <option value="NULL">Selecione um combustível</option>
                                                    {
                                                        this.state.combustiveis.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>  

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="pla">Nome da Moto (Procuração)</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i className="fa fa-car"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Nome da Moto"
                                                    autoComplete="on"
                                                    value={this.state.motoNomeSE}
                                                    onChange={event => {this.setState({motoNomeSE: event.target.value})}}
                                                />
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="especieT" id="lblEspecieT">Espécie/Tipo *</label>
                                            <div className="grupo-campo-input input-cad" id="especieT">
                                                <i className="fas fa-wrench" id="iconTipo"></i>
                                                <select
                                                    name="nivel"
                                                    placeholder="&nbsp;Selecione a Espécie"
                                                    autoComplete="on"
                                                    onChange={(e) => this.setState({especie: e.target.value})}
                                                    required
                                                    value={this.state.especie}
                                                >
                                                    <option value="">Selecione a Espécie da Moto</option>
                                                    {
                                                        this.state.especies.map((e) => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div> 
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="descricao">Descrição</label>
                                            <div className="grupo-campo-input input-cad hightop" id="descricao">
                                                <i className="fa fa-marker" id="iconDescricao"></i>
                                                <textarea
                                                    type="text"
                                                    placeholder="&nbsp;Informe a Descricao do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
                                                    value={this.state.modalCadastrarDescricao}

                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>           

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="preco">Preço</label>
                                            <div className="grupo-campo-input input-cad" id="preco">
                                                <i className="fa fa-dollar-sign" id="iconPreco"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Preço do item"
                                                    autoComplete="on"
                                                    onChange={event => this.mascaraVenda(event.target.value)} 
                                                    value={this.state.modalCadastrarPreco}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="fornecedor">Fornecedor *</label>
                                            <div className="grupo-campo-input input-cad" id="fornecedor">
                                                <i className="fa fa-hand-holding-usd" id="iconFornecedor"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Fornecedor do item"
                                                    autoComplete="on"
                                                    value={this.state.forname} onFocus={a => this.setState({ clientFocus: true })} onBlur={a => this.setState({ clientFocus: false })} onChange={this.configAutoComplete}
                                                />
                                            </div>
                                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                {
                                                    this.state.dados.filter(y => y.pessoa_nome.toLowerCase().includes(this.state.forname.toLowerCase())).map(e => (
                                                        <li onClick={a => this.setState({ forname: e.pessoa_nome, modalCadastrarFornecedor: e.pessoa_id })}>{e.pessoa_nome}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estoque">RENAVAM</label>
                                            <div className="grupo-campo-input input-cad" id="havam">
                                                <i className="fa fa-car" id="havam"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe o RENAVAM"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ renavam: event.target.value })}
                                                    value={this.state.renavam}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estoque">Chassi</label>
                                            <div className="grupo-campo-input input-cad" id="cha">
                                                <i className="fa fa-car" id="cha"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o chassi"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ chassi: event.target.value })}
                                                    value={this.state.chassi}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="pla">Placa</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i className="fa fa-sign" id="pla"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe a placa"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ placa: event.target.value })}
                                                    value={this.state.placa}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="prop">Propriedades</label>
                                            <div className="grupo-campo-input input-cad" id="prop">
                                                <i className="fas fa-paint-roller" id="iconprop"></i>
                                                <select name=""  value={this.state.propriedadesSE} onChange={e => this.setState({ propriedadesSE: e.target.value, modalCadastrarPropriedades: e.target.value})}>
                                                    <option value="NULL">Selecione uma proepriedade</option>
                                                    {
                                                        this.state.propriedades.map(e => (
                                                            <option value={e.id}>{e.descricao}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="">
                                            <label htmlFor="comprador">Comprador *</label>
                                            <div className="grupo-campo-input input-cad" id="comprador">
                                                <i className="fa fa-hand-holding-usd" id="iconComprador"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Comprador do item"
                                                    autoComplete="on"
                                                    value={this.state.comprador_name} onFocus={a => this.setState({ clientFocus3: true })} onBlur={a => this.setState({ clientFocus3: false })} onChange={this.configComprador}
                                                />
                                            </div>
                                            <ul className={this.state.clientFocus3 ? "listC activate" : "listC"}>
                                                {
                                                    this.state.dados.filter(y => y.pessoa_nome.toLowerCase().includes(this.state.comprador_name.toLowerCase())).map(e => (
                                                        <li onClick={a => this.setState({ comprador_name: e.pessoa_nome, comprador: e.pessoa_id })}>{e.pessoa_nome}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="observacao">Observação</label>
                                            <div className="grupo-campo-input input-cad hightop" id="observacao">
                                                <i className="fa fa-marker" id="iconobservacao"></i>
                                                <textarea
                                                    type="text"
                                                    placeholder="&nbsp;Observaçoes"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarObservacao}
                                                    onChange={event => this.setState({ modalCadastrarObservacao: event.target.value })}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estoque">Status</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i className="fa fa-briefcase"></i>
                                                <select disabled={this.state.pStatus} value={this.state.status} onChange={a => this.setState({status: a.target.value})}>
                                                    <option value="NULL">Selecione um status</option>
                                                    {
                                                        this.state.statusList.map(e => (
                                                            <option value={e.id}>{e.status}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="imgs">Imagens</label>
                                            <label>
                                                <div className="grupo-campo-input input-cad" id="imgs">
                                                    <i className="fa fa-camera" id="iconImg"></i>
                                                    {this.state.imgsFile.length} Imagens selecionadas
                                                </div>
                                                <input type="file" hidden accept="image/*" multiple onChange={this.renderImgs} />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="ambar">
                                        {
                                                this.state.hostimg.map((e, index) => (
                                                    <div className="cropper" id={this.imgId(e.itemfotos_id, e.itemfotos_capa)} style={{backgroundImage: `url(${e.itemfotos_foto})`}} onClick={() => {this.setState({capa: e.itemfotos_id, now: false});}}>
                                                        {/* <img src={e.itemfotos_foto} alt="" /> */}
                                                        <div className="overpass">
                                                            <i className="fa fa-times" onClick={a => this.removeimghost(index, e.itemfotos_id)}></i>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {
                                                this.state.imgsView.map((e, index) => (
                                                    <div className="cropper" id={this.imgId(index, 'index')} style={{backgroundImage: `url(${e})`}} onClick={() => this.setState({capa: index, now: true})}>
                                                        {/* <img src={e} alt="" /> */}
                                                        <div className="overpass">
                                                            <i className="fa fa-times" onClick={a => this.removeimg(index)}></i>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarItem} className="cadbtn">Editar</Button>
                            </div>
                        </div>
                        <Versionamento/>
                    </div>
                </section>
            </>
        )
    }
}

export default ItemEditarPage
