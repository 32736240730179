import React from 'react';
import { api_topmotos } from '../services/api';
import "../styles/style.css";
import moment from 'moment';

class NavUsuario extends React.Component {
  
  
  constructor(props) {
    super(props);
    
    
    this.state = {
      nome: '',
      empresa: [],
      empresaNome: '',
      permissao: '',
      notificacao: '',
      dia: moment().format('YYYY-MM-DD'),
      readNotificacao: [],
    };
  }

	async componentDidMount() {
    
    if(!localStorage.getItem('usuario_nome')){
      // window.open('/')
    } else{
      await this.apiListarNotificacaoLidas()
      this.setState({nome: localStorage.getItem('usuario_nome')});
      this.apiListarEmpresas();
      this.apiListarMenu();
      this.listarNotificacao();
    }
  }
  
  apiListarEmpresas = async () => {
    let res = await api_topmotos.get('/empresas/id/' + localStorage.getItem('usuario_empresa'));
    if (res.data.length !== 0) this.setState({empresaNome: res.data[0].empresa_razaosocial});
  }

  apiListarMenu = async () =>{
		let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
    res = res.data.filter(a => a.itens == 'Notificações');
    
    this.setState({permissao: res.length > 0 ? true : false })
  }

  apiListarNotificacaoLidas = async () => {
    let res = await api_topmotos.get('/notificacao/id/' + localStorage.getItem('usuario_id'));
    this.setState({readNotificacao: res.data});
}

verificarPermissao = async event => {
  if(localStorage.getItem('usuario_id')){
      let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));

      let pF = res.data.filter(a => a.rota == "/movimentacao/")

      if(pF.length == 0){
          return true
      } else{
          return false
      }
  } else{
      return true
  }
}

  listarNotificacao = async () => {
    let resNotRead = []
    if (!await this.verificarPermissao()) {
      let res = await api_topmotos.get('/financeiro/');
      res = res.data.filter(a => moment(a.dataVencimento).format('YYYY-MM-DD') == this.state.dia && (a.conta_status == 'AP' || a.conta_status == 'AR') && a.mov_cancelada != 1)
      resNotRead = res.filter(mov => {
        return this.state.readNotificacao.every(notificacao => {
            return !(notificacao.notificacao_type == "financeiro" && notificacao.reference_id == mov.id);
        });
      });
    }

    let resItens = await api_topmotos.get('/itens/');
    let resItensNotRead = resItens.data.filter(mol => {
      return this.state.readNotificacao.every(notificacao => {
        return !(notificacao.notificacao_type == "itens" && notificacao.reference_id == mol.id);
      });
    });
    var filterItens = resItensNotRead.filter(y => {
      let dias = 90 - Math.floor(moment.duration(moment().diff(moment(y.venda_data))).asDays());
      return (dias === 45 || dias === 80) && y.terceira_pessoa && y.compra == 1;
    });

    let resEstoqueMin = await api_topmotos.get('/modelosano/');
    resEstoqueMin = resEstoqueMin.data.filter(y => y.quantidade < y.estoque_minimo)
    let resEstoqueMinNotRead = resEstoqueMin.filter(mov => {
      return this.state.readNotificacao.every(notificacao => {
          return !(notificacao.notificacao_type == "modelosano" && notificacao.reference_id == mov.id_cor_ano);
      });
    });

    let resLeads = await api_topmotos.get('/leadsitens/');
    let filterLeads = resLeads.data.filter(a => {
      return Math.floor(moment.duration(moment().diff(moment(a.leaditens_admissaodata))).asDays()) < 30;
    })
    let resLeadsNotRead = filterLeads.filter(lead => {
      return this.state.readNotificacao.every(notificacao => {
        let status = !((notificacao.notificacao_type == "leadsitens" || notificacao.notificacao_type == "leadsitensInEstoque") && notificacao.reference_id == lead.id);
        if (!status) lead.read = true
        return status;
      });
    });
    
    this.setState({notificacao: resNotRead.length + resEstoqueMinNotRead.length + filterItens.length + resLeadsNotRead.length})
  }
  

	render() {
		return (

			<div className="content-nome">
        { this.state.permissao ?
          <div className="d-flex justify-center align-center bells pointer" onClick={() => window.open("/notificacao")}>
            <i className="far fa-bell"></i>
            {window.location.href.split('/')[3] !== 'notificacao' && this.state.notificacao > 0 ? <span  className="bells-status">{this.state.notificacao}</span> : ''}
          </div> : ''
        }
        <div className="d-flex justify-center align-center">
          <p>{this.state.empresaNome}</p>
          <i className="fas fa-building"></i>
        </div>
        <div className="d-flex justify-center align-center">
          <p>{this.state.nome}</p>
          <i className="fas fa-user"></i>
        </div>
      </div>
      
		)
	}
}

export default NavUsuario;
