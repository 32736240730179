import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Estoque extends React.Component {

	constructor(props) {
        super(props);
        
        this.pageSize = 1;

		this.state = {
            dados: [],
						dadosF: [],

            modalDetalhesOpen: false,
						modalDetalhesId: '',
						modalDetalhesModelo: '',
						modalDetalhesMarca: '',
						modalDetalhesSimples: '',
						modalDetalhesAno: '',
						modalDetalhesCor: '',
            modalDetalhesEstoque: '',
            
            id: '',
            modelo: '',
            marca: '',
            simples: '',
            ano: '',
            estoque: '',
            cor: '',
						quantidade: '',
						faltante: '',

						clientFocus2: false,

            pageLimit: 10,
            pageAtual: 0,

						modalDeletarOpen: false,
						modalDeletarId: null,
						modalDeletarNome: '',

						valorTotal: 0,
						estoqueTotal: 0,

						modelosvei: [],
						anos: [],

						veiculo: '',
		};
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/estoque")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
			this.apiListarEstoqueMinimo();
			this.apiListarModelos()
			this.apiListarAnos()
		}
	}

	// Modelos
	apiListarModelos = async event => {
		let res = await api_topmotos.get('/modelos/');
		this.setState({ modelosvei: res.data });
	}

	apiListarAnos = async event => {
		let res = await api_topmotos.get('/anosAll/group');
        console.log(res)
		this.setState({ anos: res.data });
  }

	configModelo = async (e) => {
		this.setState({ modelo: e });
	}

	apiListarEstoqueMinimo = async event => {
        let res = await api_topmotos.get('/modelosano/');
        this.setState({dados: res.data, dadosF: res.data})
				let estoqueTotal = 0, valorTotal = 0;
				res.data.forEach((r) => {
					estoqueTotal += r.estoque_minimo
					valorTotal += r.valor_medio * r.estoque_minimo
				})
				this.setState({estoqueTotal, valorTotal});
        this.pageSize = Math.ceil(res.data.length / this.state.pageLimit);
    }

    toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}
    
    modalDetalhes = async (idAno) => {
		let resModelo = await api_topmotos.get('/modelosano/' + idAno);
        resModelo = resModelo.data[0];

		this.setState({
			modalDetalhesValor: resModelo.valor_medio ? this.mascaraValor(resModelo.valor_medio.toFixed(2)) : '',
			modalDetalhesOpen: true,
			modalDetalhesId: resModelo.id_cor_ano,
			modalDetalhesModelo: resModelo.nome,
			modalDetalhesMarca: resModelo.marcaName,
			modalDetalhesSimples: resModelo.nome_simples,
			modalDetalhesAno: resModelo.ano,
			modalDetalhesCor: resModelo.cor,
			modalDetalhesEstoque: resModelo.estoque_minimo
		});
    }
    
    update = async () => {
				if(typeof this.state.modalDetalhesValor != 'number'){
					var real = this.state.modalDetalhesValor.replace('.', '');
					real = real.replace(',', '.');
				} else{
						real = this.state.modalDetalhesValor
				}
        let atualizar = await api_topmotos.put('/estoqueminimo/', {estoque_minimo: this.state.modalDetalhesEstoque, valor_medio: real || null, id: this.state.modalDetalhesId});
				this.removeReadNotificacao('modelosano', this.state.modalDetalhesId)
        console.log(atualizar)
        this.apiListarEstoqueMinimo()
        this.toggleModalDetalhes()
    }

		removeReadNotificacao = async (type, id) => {
			await api_topmotos.post('/notificacao/delete/id/' + localStorage.getItem('usuario_id'), {
					notificacao_type: type,
					reference_id: id,
			});
		}

    filtrar = (dados) => {
        let dadosFilter = dados.filter((d) => {
            return this.filtrarID(d) && this.filtrarModelo(d) && this.filtrarMarca(d) && this.filtrarSimples(d) && this.filtrarAno(d) && this.filtrarCor(d) && this.filtrarEstoque(d) && this.filtrarValorMedio(d) && this.filtrarFaltante(d)
        }) 

        this.pageSize = Math.ceil(dadosFilter.length / this.state.pageLimit);

        return dadosFilter
    }

    filtrarID = (d) => {
		return !this.state.id || ( d.id_cor_ano && d.id_cor_ano.toString().toLowerCase().includes(this.state.id.toLowerCase()) );
	}

    filtrarModelo = (d) => {
		return !this.state.modelo || ( d.nome && d.nome.toString().toLowerCase().includes(this.state.modelo.toLowerCase()) );
	}

    filtrarMarca = (d) => {
		return !this.state.marca || ( d.marcaName && d.marcaName.toString().toLowerCase().includes(this.state.marca.toLowerCase()) );
	}

    filtrarSimples = (d) => {
		return !this.state.simples || ( d.nome_simples && d.nome_simples.toString().toLowerCase().includes(this.state.simples.toLowerCase()) );
	}

    filtrarAno = (d) => {
		return !this.state.ano || ( d.ano && d.ano.toString().toLowerCase().includes(this.state.ano.toLowerCase()) );
	}

    filtrarCor = (d) => {
		return !this.state.cor || ( d.cor && d.cor.toString().toLowerCase().includes(this.state.cor.toLowerCase()) );
	}

	filtrarFaltante = (d) => {
		return !this.state.faltante || ( (d.estoque_minimo - d.quantidade && (d.estoque_minimo - d.quantidade).toString().includes(this.state.faltante)) || d.estoque_minimo - d.quantidade == this.state.faltante );
  }


    filtrarEstoque = (d) => {
		return !this.state.estoque || ( (d.estoque_minimo && d.estoque_minimo.toString().includes(this.state.estoque)) || d.estoque_minimo == this.state.estoque );
    }

		filtrarValorMedio = (d) => {
			return !this.state.valor_medio || ( (d.valor_medio && d.valor_medio.toString().includes(this.state.valor_medio)) || d.valor_medio == this.state.valor_medio );
		}
    
    	/**
	 * @description Troca o indice da paginação
	 */
	nextorprevious = (e, index) => {
		e.preventDefault();

		this.setState({ pageAtual: index })
	}

	/**
	 * @description Organiza a paginação e ativa um indice
	 */
	putItem = (pageAtual, index) => {

		let item = (
			<div style={{width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left'}} key={index}>
				<button style={{width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222'}} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id) => {
		let res = await api_topmotos.get('/modelosano/' + id);
		console.log(id)
		console.log(res)
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: res.id_cor_ano,
			modalDeletarNome: res.nome,
		})
	}

	apiDeletarEstoqueMin = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/estoqueminimo/' + this.state.modalDeletarId);
			
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/estoqueminimo/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Estoque Ideal deletado com sucesso!' });
	}

	mascaraValor = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
						real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		console.log(real)
		return real;
}

	render() {
		const { senhaVisivel } = this.state;

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
              <div className="form-head">
								<p>Estoque mínimo (Estoque Ideal: {this.state.estoqueTotal} | Valor Médio: R${this.mascaraValor(this.state.valorTotal.toFixed(2))})</p>
              <div className="form-add" onClick={a => this.props.history.push("/cadastrarestoqueminimo")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table relatorio-body">
							<div className="row">
								<div className="col-md-3">
										<div className="grupo-input input-style-cad">
												<label htmlFor="modelo" id="lblModelo">Modelo </label>
												<div className="grupo-campo-input input-cad" id="modelo">
														<i className="fas fa-asterisk" id="iconModelo"></i>
														<input
															type="text"
															placeholder="&nbsp;Informe o Modelo do item"
															autoComplete="on"
															onChange={event => this.configModelo(event.target.value)}
															required
															onFocus={a => this.setState({ clientFocus2: true })} 
															onBlur={a => this.setState({ clientFocus2: false })}
															value={this.state.modelo}
														/>
												</div>
												<ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
														{
																		this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(this.state.modelo.toLowerCase())).map(e => (
																				<li onClick={a => this.setState({modelo: e.nome})}>{e.nome}</li>
																		))
														}
												</ul>
										</div>
								</div>
								<div className="col-md-3">
												<div className="grupo-input input-style-cad">
														<label htmlFor="empresa">Ano de Fabricação</label>
														<div className="grupo-campo-input input-cad" id="empresa">
																<i className="fas fa-user" id="iconEmpresa"></i>
																<select onChange={a => this.setState({ano: a.target.value})}>
																		<option value="">Selecione o Ano</option>
																		{ this.state.anos.map((ano) => (
																				<option value={ano.ano}>{ano.ano}</option>
																		))}
																</select>
														</div>
												</div>
								</div>
							</div>
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Marca
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Modelo
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Versão
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ano
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Cor
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Quantidade Faltante
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Estoque Ideal
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Valor Médio
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>
                  <tr>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.id} onChange={a => this.setState({ id: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.marca} onChange={a => this.setState({ marca: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.simples} onChange={a => this.setState({ simples: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.modelo} onChange={a => this.setState({ modelo: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.ano} onChange={a => this.setState({ ano: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.cor} onChange={a => this.setState({ cor: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.faltante} onChange={a => this.setState({ faltante: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.estoque} onChange={a => this.setState({ estoque: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.valor_medio} onChange={a => this.setState({ quantidade: a.target.value })} />

											</div>
										</th>
										<th>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.filtrar(this.state.dadosF).slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((modelo, id) => (
											<tr key={modelo.id_cor_ano}>
												<td>{modelo.id_cor_ano}</td>
												<td>{modelo.marcaName}</td>
												<td>{modelo.nome_simples}</td>
												<td>{modelo.nome}</td>
												<td>{modelo.ano}</td>
												<td>{modelo.cor}</td>
												<td>{modelo.estoque_minimo - modelo.quantidade == 0 ? "Ok" : modelo.estoque_minimo - modelo.quantidade}</td>
												<td>{modelo.estoque_minimo}</td>
												<td>{modelo.valor_medio ? modelo.valor_medio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
												<td className="td-acoes">
													<i className="fas fa-pen" onClick={() => {this.modalDetalhes(modelo.id_cor_ano)}}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(modelo.id_cor_ano)}></i>
												</td>
											</tr>
										))
									}

								</tbody>
                                <tr style={{width: '100%'}}>
                                    
                                </tr>
							</table>
							<div className="form-footer">
							{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{width: '30px', border: 'none', background: 'transparent', float: 'left'}}>
                                    <button style={{width: '30px', border: 'none', background: 'transparent', float: 'left'}} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
                                </div>

                                {
                                    [...Array(this.pageSize)].map((e, index) => (
                                        this.putItem(this.state.pageAtual, index)
                                    ))
                                }

                                <div style={{width: '30px', border: 'none', background: 'transparent', float: 'left'}} >
                                    <button style={{width: '30px', border: 'none', background: 'transparent', float: 'left'}} disabled={this.state.pageAtual >= this.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
                                </div>
							</div>}
							</div>
						</div>
						<Versionamento/>
					</div>
				</div>


        <Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesModelo} - {this.state.modalDetalhesId}</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Modelo</p>
								<p>{this.state.modalDetalhesModelo}</p>
							</div>
							<div className="conteudo-info">
								<p>Marca</p>
								<p>{this.state.modalDetalhesMarca}</p>
							</div>
							<div className="conteudo-info">
								<p>Nome simples</p>
								<p>{this.state.modalDetalhesSimples}</p>
							</div>
							<div className="conteudo-info">
								<p>Ano</p>
								<p>{this.state.modalDetalhesAno}</p>
							</div>
							<div className="conteudo-info">
								<p>Cor</p>
								<p>{this.state.modalDetalhesCor}</p>
							</div>
							<div className="conteudo-info">
								<p>Estoque Ideal</p>
								<input type='number' style={{padding: '2px', width: '60px'}} defaultValue={this.state.modalDetalhesEstoque} onChange={(event) => {this.setState({modalDetalhesEstoque: event.target.value})}}/>
							</div>
							<div className="conteudo-info">
								<p>Valor Médio</p>
								<input type='text' style={{padding: '2px', width: '140px'}} value={this.state.modalDetalhesValor} onChange={(event) => {this.setState({modalDetalhesValor: this.mascaraValor(event.target.value)})}}/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => {this.update()}}>Atualizar</Button>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

					{/* MODAL DELETAR */}
					<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar o Produto {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarEstoqueMin} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

			</section>

		)
	}
}

export default Estoque;
