import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from "moment";


import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { Link } from 'react-router-dom'

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class Vendas extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],
			empresas: [],
			clientes: [],
			usuarios: [],
			itens: [],
			options: [],

			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesEmpresa: '',
			modalDetalhesCliente: '',
			modalDetalhesUsuario: '',
			modalDetalhesValor: '',
			modalDetalhesMetodopagamento: '',
			modalDetalhesParcelas: '',
			modalDetalhesEntrada: '',
			modalDetalhesDesconto: 0.0,
			modalDetalhesAdmissaodata: '',
			modalDetalhesAtualizacaodata: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarItens: [],
			modalDeletarData: '',

			modalAvisoOpen: false,
			modalAvisoText: '',

			fid: '',
			fve: '',
			fda: '',
			fcl: '',
			fpl: '',
			fst: '',

			openmodal: false,
			modelos: [],
			modelSE: "NULL",
			itpdf: '',
			status: [],
			vendaitens: [],
            pagina: 1, 
			quantidade_paginas: 1,
			modalCancela: false,
			vendaId: '', 
			permissao: [], 
			permitir: false,
			movimentacoes: [],
		};
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
		if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
			this.apiListarVendas();
			this.apiListarEmpresas();
			// this.apiListarClientes();
			this.apiListarUsuarios();
			this.apiListarItens();
			this.apiListarModelosDoc();
			this.apiListarStatus();
			this.apiListarPermissao();
			this.apiListarMovimentacoes();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	apiListarModelosDoc = async event => {
		let res = await api_topmotos.get('/modelosdoc/ven');
		this.setState({ modelos: res.data });
	}

	setValor = async (itens, modulo) => {
		var somaValor = 0;
		if (itens) {
			let max = itens.length;
			for (let i = 0; i < max; i++) {
				let idItem = itens[i];
				let res = await api_topmotos.get('/itens/id/' + idItem);
				somaValor += res.data[0].item_preco;
			}
			if (modulo === 'cadastrar') {
				this.setState({ modalCadastrarValor: somaValor });
			}
			if (modulo === 'editar') {
				this.setState({ modalEditarValor: somaValor });
			}
		}
	}

	apiListarVendas = async event => {
		let res = await api_topmotos.get('/vendas/');
		this.setState({ dados: res.data, quantidade_paginas: Math.ceil(res.data.length / 10)});
	}

	apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
		let cancel = res.data.filter(a => a.restricao_id == 1);
		let view = res.data.filter(a => a.restricao_id  == 5);

		if(view.length == 0){
			let venda = this.state.dados.filter(a => a.venda_usuario == localStorage.getItem('usuario_id'));
			this.setState({dados: venda})
		}

		this.setState({permitir: cancel.length > 0 ? true : false})
    }

	apiListarEmpresas = async event => {
		let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
	}

	apiListarStatus = async event => {
		let res = await api_topmotos.get('/vendaStatus/');
		this.setState({ status: res.data });
	}

	apiListarClientes = async event => {
		let res = await api_topmotos.get('/clientes/');
		this.setState({ clientes: res.data });
	}

	apiListarUsuarios = async event => {
		let res = await api_topmotos.get('/usuarios/');
		this.setState({ usuarios: res.data });
	}

	apiListarItens = async event => {
		let res = await api_topmotos.get('/itens/');
		this.setState({ itens: res.data });

		let selectOptions = this.state.itens.map((item, id) => (
			{ value: item.item_id, label: item.item_modelo + " - Cor: " + item.cor }
		))

		this.setState({ options: selectOptions });
	}

	apiListarMovimentacoes = async event =>{
		let res = await api_topmotos.get('/financeiro/');
        this.setState({ movimentacoes: res.data.filter(mov => mov.mov_cancelada != 1) });
	}

	handleItens = (valores, modulo) => {
		if (modulo === 'cadastrar') {
			if (valores) {
				this.state.modalCadastrarItens = valores.map((item) => (
					item.value
				))
			}
			if (!valores || valores === null || valores === undefined) {
				this.state.modalCadastrarItens = [];
			}

			this.setValor(this.state.modalCadastrarItens, 'cadastrar');
		}
		if (modulo === 'editar') {
			if (valores) {
				this.state.modalEditarItens = valores.map((item) => (
					item.value
				))
			}
			if (!valores || valores === null || valores === undefined) {
				this.state.modalEditarItens = [];
			}

			this.setValor(this.state.modalEditarItens, 'editar');
		}
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idVenda) => {
		let resVenda = await api_topmotos.get('/vendas/id/' + idVenda);
		resVenda = resVenda.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resVenda.venda_id,
			modalDetalhesEmpresa: resVenda.venda_empresan,
			modalDetalhesCliente: resVenda.venda_clienten,
			modalDetalhesUsuario: resVenda.venda_usuarion,
			modalDetalhesData: moment(resVenda.venda_admissaodata).format("DD/MM/YYYY"),
			modalDetalhesValor: resVenda.venda_valor,
			modalDetalhesMetodopagamento: resVenda.venda_metodopagamento,
			modalDetalhesParcelas: resVenda.venda_parcelas,
			modalDetalhesEntrada: resVenda.venda_entrada,
			modalDetalhesDesconto: resVenda.venda_desconto,
			modalDetalhesAdmissaodata: resVenda.venda_admissaodata,
			modalDetalhesAtualizacaodata: resVenda.venda_atualizacaodata,
		});
	}

	toggleModalCadastrar = () => {
		this.setState({ modalCadastrarOpen: !this.state.modalCadastrarOpen });
	}

	toggleModalCancelar = () => {
		this.setState({ modalCancela: !this.state.modalCancela });
	}

	modalCadastrar = () => {
		this.toggleModalCadastrar();
	}

	apiCadastrarVenda = async e => {
		e.preventDefault();

		const {
			modalCadastrarEmpresa,
			modalCadastrarCliente,
			modalCadastrarUsuario,
			modalCadastrarValor,
			modalCadastrarMetodopagamento,
			modalCadastrarParcelas,
			modalCadastrarEntrada,
			modalCadastrarDesconto,
			modalCadastrarItens,
		} = this.state;

		if (!modalCadastrarEmpresa ||
			!modalCadastrarCliente ||
			!modalCadastrarUsuario ||
			!modalCadastrarValor ||
			!modalCadastrarMetodopagamento ||
			!modalCadastrarParcelas ||
			!modalCadastrarItens ||
			modalCadastrarEmpresa === 'NULL' ||
			modalCadastrarCliente === 'NULL' ||
			modalCadastrarUsuario === 'NULL' ||
			modalCadastrarMetodopagamento === 'NULL' ||
			modalCadastrarItens === 'NULL') {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
			this.log(this.state.error);

			var aviso = document.getElementById('aviso');
			aviso.style.cssText = "color: #FF0000; transition: 0.2s;";

			if (!modalCadastrarEmpresa || modalCadastrarEmpresa === 'NULL') {
				var ssInputEmpresa = document.getElementById('empresa');
				var ssInputIconEmpresa = document.getElementById('iconEmpresa');
				ssInputEmpresa.style.cssText = "border: 1px solid #FF0000; transition: 0.2s;";
				ssInputIconEmpresa.style.cssText = "color: #FF0000; transition: 0.2s;";
			} else {
				ssInputEmpresa = document.getElementById('empresa');
				ssInputIconEmpresa = document.getElementById('iconEmpresa');
				ssInputEmpresa.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconEmpresa.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
			if (!modalCadastrarCliente || modalCadastrarCliente === 'NULL') {
				var ssInputCliente = document.getElementById('cliente');
				var ssInputIconCliente = document.getElementById('iconCliente');
				ssInputCliente.style.cssText = "border: 1px solid #FF0000; transition: 0.2s;";
				ssInputIconCliente.style.cssText = "color: #FF0000; transition: 0.2s;";
			} else {
				ssInputCliente = document.getElementById('cliente');
				ssInputIconCliente = document.getElementById('iconCliente');
				ssInputCliente.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconCliente.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
			if (!modalCadastrarUsuario || modalCadastrarUsuario === 'NULL') {
				var ssInputUsuario = document.getElementById('usuario');
				var ssInputIconUsuario = document.getElementById('iconUsuario');
				ssInputUsuario.style.cssText = "border: 1px solid #FF0000; transition: 0.2s;";
				ssInputIconUsuario.style.cssText = "color: #FF0000; transition: 0.2s;";
			} else {
				ssInputUsuario = document.getElementById('usuario');
				ssInputIconUsuario = document.getElementById('iconUsuario');
				ssInputUsuario.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconUsuario.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
			if (!modalCadastrarValor || modalCadastrarValor === 'NULL') {
				var ssInputValor = document.getElementById('valor');
				var ssInputIconValor = document.getElementById('iconValor');
				ssInputValor.style.cssText = "border: 1px solid #FF0000; transition: 0.2s;";
				ssInputIconValor.style.cssText = "color: #FF0000; transition: 0.2s;";
			} else {
				ssInputValor = document.getElementById('valor');
				ssInputIconValor = document.getElementById('iconValor');
				ssInputValor.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconValor.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
			if (!modalCadastrarMetodopagamento || modalCadastrarMetodopagamento === 'NULL') {
				var ssInputMetodopagamento = document.getElementById('metodoPagamento');
				var ssInputIconMetodopagamento = document.getElementById('iconMetodoPagamento');
				ssInputMetodopagamento.style.cssText = "border: 1px solid #FF0000; transition: 0.2s;";
				ssInputIconMetodopagamento.style.cssText = "color: #FF0000; transition: 0.2s;";
			} else {
				ssInputMetodopagamento = document.getElementById('metodoPagamento');
				ssInputIconMetodopagamento = document.getElementById('iconMetodoPagamento');
				ssInputMetodopagamento.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconMetodopagamento.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
			if (!modalCadastrarParcelas || modalCadastrarParcelas === 'NULL') {
				var ssInputParcelas = document.getElementById('parcelas');
				var ssInputIconParcelas = document.getElementById('iconParcelas');
				ssInputParcelas.style.cssText = "border: 1px solid #FF0000; transition: 0.2s;";
				ssInputIconParcelas.style.cssText = "color: #FF0000; transition: 0.2s;";
			} else {
				ssInputParcelas = document.getElementById('parcelas');
				ssInputIconParcelas = document.getElementById('iconParcelas');
				ssInputParcelas.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconParcelas.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}

		} else {
			this.setState({ errorMessage: '' });

			ssInputEmpresa = document.getElementById('empresa');
			ssInputIconEmpresa = document.getElementById('iconEmpresa');
			ssInputEmpresa.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			ssInputIconEmpresa.style.cssText = "color: #BFBFC3; transition: 0.2s;";

			ssInputCliente = document.getElementById('cliente');
			ssInputIconCliente = document.getElementById('iconCliente');
			ssInputCliente.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			ssInputIconCliente.style.cssText = "color: #BFBFC3; transition: 0.2s;";

			ssInputUsuario = document.getElementById('usuario');
			ssInputIconUsuario = document.getElementById('iconUsuario');
			ssInputUsuario.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			ssInputIconUsuario.style.cssText = "color: #BFBFC3; transition: 0.2s;";

			ssInputValor = document.getElementById('valor');
			ssInputIconValor = document.getElementById('iconValor');
			ssInputValor.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			ssInputIconValor.style.cssText = "color: #BFBFC3; transition: 0.2s;";

			ssInputMetodopagamento = document.getElementById('metodoPagamento');
			ssInputIconMetodopagamento = document.getElementById('iconMetodoPagamento');
			ssInputMetodopagamento.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			ssInputIconMetodopagamento.style.cssText = "color: #BFBFC3; transition: 0.2s;";

			ssInputParcelas = document.getElementById('parcelas');
			ssInputIconParcelas = document.getElementById('iconParcelas');
			ssInputParcelas.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			ssInputIconParcelas.style.cssText = "color: #BFBFC3; transition: 0.2s;";

			try {
				await api_topmotos.post('/vendas/create', {
					venda_empresa: modalCadastrarEmpresa,
					venda_cliente: modalCadastrarCliente,
					venda_usuario: modalCadastrarUsuario,
					venda_valor: modalCadastrarValor,
					venda_metodopagamento: modalCadastrarMetodopagamento,
					venda_parcelas: modalCadastrarParcelas,
					venda_entrada: modalCadastrarEntrada,
					venda_desconto: modalCadastrarDesconto,
					venda_admissaodata: this.getTimestamp(),
				}).then(res => {
					this.setState({ modalCadastrarVendaId: res.data.returnId });
				});
			} catch (err) {
				this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendas/create)" })
				this.log(this.state.error);
			}

			let max = modalCadastrarItens.length;
			for (let i = 0; i < max; i++) {
				try {
					await api_topmotos.post('/vendaitens/create', {
						vendaitens_venda: this.state.modalCadastrarVendaId,
						vendaitens_item: modalCadastrarItens[i],
						vendaitens_admissaodata: this.getTimestamp()
					})
				} catch (err) {
					this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendaitens/create)" })
					this.log(this.state.error);
				}
			}

			this.setState({ modalAvisoOpen: true });
			this.setState({ modalAvisoText: 'Venda registrada com sucesso!' });
		}
	}

	toggleModalEditar = () => {
		this.setState({ modalEditarOpen: !this.state.modalEditarOpen });
	}

	modalEditar = async (idVenda) => {
		let resVenda = await api_topmotos.get('/vendas/id/' + idVenda);
		resVenda = resVenda.data[0];

		let resVendaItens = await api_topmotos.get('/vendaitens/venda/' + idVenda);
		resVendaItens = resVendaItens.data;

		this.setState({
			modalEditarOpen: true,
			modalEditarId: resVenda.venda_id,
			modalEditarEmpresa: resVenda.venda_empresa,
			modalEditarCliente: resVenda.venda_cliente,
			modalEditarUsuario: resVenda.venda_usuario,
			modalEditarValor: resVenda.venda_valor,
			modalEditarMetodopagamento: resVenda.venda_metodopagamento,
			modalEditarParcelas: resVenda.venda_parcelas,
			modalEditarEntrada: resVenda.venda_entrada,
			modalEditarDesconto: resVenda.venda_desconto,
			modalEditarAdmissaodata: resVenda.venda_admissaodata,
			modalEditarItens: [],
		})
	}

	apiEditarVenda = async e => {
		e.preventDefault();

		const {
			modalEditarEmpresa,
			modalEditarCliente,
			modalEditarUsuario,
			modalEditarValor,
			modalEditarMetodopagamento,
			modalEditarParcelas,
			modalEditarEntrada,
			modalEditarDesconto,
			modalEditarItens,
			modalEditarId,
		} = this.state;

		if (!modalEditarEmpresa ||
			!modalEditarCliente ||
			!modalEditarUsuario ||
			!modalEditarValor ||
			!modalEditarMetodopagamento ||
			!modalEditarParcelas ||
			!modalEditarItens ||
			modalEditarEmpresa === 'NULL' ||
			modalEditarCliente === 'NULL' ||
			modalEditarUsuario === 'NULL' ||
			modalEditarMetodopagamento === 'NULL' ||
			modalEditarItens === 'NULL') {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
			this.log(this.state.error);

		} else {
			this.setState({ errorMessage: '' });

			try {
				await api_topmotos.put('/vendas/update/id/' + modalEditarId, {
					venda_empresa: modalEditarEmpresa,
					venda_cliente: modalEditarCliente,
					venda_usuario: modalEditarUsuario,
					venda_valor: modalEditarValor,
					venda_metodopagamento: modalEditarMetodopagamento,
					venda_parcelas: modalEditarParcelas,
					venda_entrada: modalEditarEntrada,
					venda_desconto: modalEditarDesconto,
					venda_atualizacaodata: this.getTimestamp(),
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/vendas/update)" });
				this.log(this.state.error);
			}

			this.setState({ modalAvisoOpen: true });
			this.setState({ modalAvisoText: 'Item atualizado com sucesso!' });
		}
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (idVenda) => {
		let resVenda = await api_topmotos.get('/vendas/id/' + idVenda);
		resVenda = resVenda.data[0];

		let resVendaItens = await api_topmotos.get('/vendaitens/venda/' + idVenda);
		resVendaItens = resVendaItens.data;

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: resVenda.venda_id,
			modalDeletarItens: resVendaItens,
			modalDeletarData: resVenda.venda_data,
		});
	}

	apiDeletarVenda = async e => {
		e.preventDefault();

		let max = this.state.modalDeletarItens.length;
		for (let i = 0; i < max; i++) {
			try {
				await api_topmotos.delete('/vendaitens/delete/id/' + this.state.modalDeletarItnes[i].vendaitens_id);
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/vendaitens/delete)" });
				this.log(this.state.error);
			}
		}

		try {
			await api_topmotos.delete('/vendas/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/vendas/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Venda deletada com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	setQuantidade = (qt) => {
		this.setState({quantidade_paginas: Math.ceil(qt / 10)});
	}

	filtrar = (ara) => {
		let filtro = ara.filter(v => {
			return this.filtrarID(v) && this.filtrarVendedor(v) && this.filtrarCliente(v) && this.filtrarData(v) && this.filtrarPlaca(v) ;
		})

		return filtro;
	}

	filtrarID = (v) => {
		return !this.state.fid || (v.venda_id &&
			v.venda_id.toString().toLowerCase().includes(this.state.fid.toLowerCase()));
	}

	filtrarVendedor = (v) => {
		return !this.state.fve || (v.usuario_nome &&
			v.usuario_nome.toString().toLowerCase().includes(this.state.fve.toLowerCase()));
	}

	filtrarCliente = (v) => {
		return !this.state.fcl || (v.cliente_nome &&
			v.cliente_nome.toString().toLowerCase().includes(this.state.fcl.toLowerCase()));
	}

	filtrarPlaca = (v) => {
		return !this.state.fpl || (v.placa &&
			v.placa.toString().toLowerCase().includes(this.state.fpl.toLowerCase()));
	}

	filtrarData = (v) => {
		return !this.state.fda || (v.venda_admissaodata &&
			this.formatTime(v.venda_admissaodata).toString().toLowerCase().includes(this.state.fda.toLowerCase()));
	}

	closeContractModal = () => this.setState({ openmodal: false })

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	testes = async () =>{
		// let res = await api_topmotos.get('/vendaitens/venda/' + id);

		// let itens = res.data[0].vendaitens_item

		// this.state.itens.filter((y) => {return y.itens_id == itens})
	}

	cancelarVenda = async e =>{
		let id = this.state.vendaId

		await api_topmotos.put('/venda/cancelada', {venda_id: id})
		
		let res = await api_topmotos.get('/vendaitens/venda/' + id);

		for(let i of res.data){
			await api_topmotos.put('/itens/status/disponivel/' + i.vendaitens_item);
		}

		this.toggleModalCancelar();
		this.apiListarVendas();
	}

	modalCancelar = (id) =>{
		this.setState({
			vendaId: id
		});

		this.toggleModalCancelar();
	}

	habilitarEdicao = (id) =>{
		let mov = this.state.movimentacoes.filter(a => a.venda_id == id);
		if(mov.length > 0){
			let soma = mov.length
			mov = mov.filter(a => a.conta_status == 'AR')
			soma -= mov.length;
			return soma == 0 ? true : false;
		} else{
			return false;
		}
	}
	
	openPdf = (vendaId) =>{
        window.open('/pdf/'+vendaId+'/6', '_blank')
        window.open('/pdfImpressao/'+vendaId+'/8', '_blank')
		}

	render() {

		return (
			<section>

				<Modal isOpen={this.state.openmodal} toggle={this.closeContractModal}>
					<ModalHeader toggle={this.closeContractModal}>
						Imprimir contrato
                    </ModalHeader>
					<>
						<ModalBody>
							Qual o modelo? <br />

							<div className="grupo-input input-style-cad">
								<label htmlFor="tipo">Modelo</label>
								<div className="grupo-campo-input input-cad" id="tipo">
									<i className="fas fa-wrench" id="iconTipo"></i>
									<select onChange={a => this.setState({ modelSE: a.target.value })}>
										<option value="NULL">Selecione o modelo</option>
										{
											this.state.modelos.map(e => (
												<option value={e.id}>{e.nome}</option>
											))
										}
									</select>

								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							{
								this.state.modelSE != "NULL" ? (
									<Link to={`/pdf/${this.state.itpdf}/${this.state.modelSE}`} target="_blank">
										<Button className="btnora">Imprimir</Button>
									</Link>
								) : (null)
							}
						</ModalFooter>
					</>
				</Modal>

				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Vendas</p>
							<div className="form-add" onClick={() => this.props.history.push("/vendaFase")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Vendedor
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Cliente
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Itens
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Status
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Data
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>


									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.fid} onChange={a => {this.setState({ fid: a.target.value });}} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.fve} onChange={a => this.setState({ fve: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.fcl} onChange={a => this.setState({ fcl: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.fpl} onChange={a => this.setState({ fpl: a.target.value })} />

											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>

												<input type="text" value={this.state.fda} onChange={a => this.setState({ fda: a.target.value })} />

											</div>
										</th>
										<th>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.filtrar(this.state.dados).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map((venda, id) => (
											<tr key={venda.venda_id}>
												<td className={venda.venda_cancelada == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.venda_id}</td>
												<td className={venda.venda_cancelada == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.usuario_nome}</td>
												<td className={venda.venda_cancelada == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.cliente_nome}</td>
												<td className={venda.venda_cancelada == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{venda.item_modelo ? venda.item_modelo + ' - ' + venda.placa : ''}</td>
												<td className={venda.venda_cancelada == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{this.state.status.filter((y) => {return y.id == venda.venda_status}).map((teste)=>{ return teste.descricao})}</td>
												<td className={venda.venda_cancelada == 1 ? 'cancelado' : venda.pendencia ? "pendente" : ''}>{moment(venda.venda_admissaodata).format("DD/MM/YYYY HH:mm")}</td>
												<td className="td-acoes">
													{venda.venda_cancelada == 1 ? '' : <>
													{venda.pendencia != 1 ? <i className="fas fa-print" onClick={() => this.openPdf(venda.venda_id)}></i> : ''}
													{venda.pendencia != 1 ? this.habilitarEdicao(venda.venda_id) ? <i className="fas fa-dollar-sign" onClick={() => this.props.history.push('/editarMov/'+venda.venda_id) }></i> : '' : ''}
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(venda.venda_id)}></i>&nbsp;
													{/* <i className="fas fa-trash" onClick={() => this.modalDeletar(venda.venda_id)}></i> */}
													{/* <i className="fas fa-pen" onClick={() => this.props.history.push("/editarvenda/" + venda.venda_id)}></i>&nbsp; */}
													{!venda.item_modelo ? <i className="fas fa-pen" onClick={() => this.props.history.push("/vendaR/" + venda.venda_id)}></i> : ''}
													{this.state.permitir ? <i className="fas fa-times" onClick={() => this.modalCancelar(venda.venda_id)}></i> : ''}
													</>
													}
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
							<div className="form-footer">
							{this.filtrar(this.state.dados).length > 0 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                    }}>
                                        {<i class="fas fa-angle-left arrows"></i>}
                                    </div>
                                    {
                                        [...Array(Math.ceil((this.filtrar(this.state.dados)).length / 10))].map((pagina, index) => {
                                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                                (this.state.pagina === (Math.ceil((this.filtrar(this.state.dados)).length / 10)) && index + 1 > (Math.ceil((this.filtrar(this.state.dados)).length / 10)) - 3) ||
                                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                            ){
                                                return (
                                                    <div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
                                                    onClick={() => {
                                                        this.setState({pagina: index + 1})
                                                    }}>
                                                        {index + 1}
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null
                                            }
                                        })
                                    }
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina < (Math.ceil((this.filtrar(this.state.dados)).length / 10)) ? this.state.pagina + 1 : (Math.ceil((this.filtrar(this.state.dados)).length / 10))})
                                    }}>
                                        {<i class="fas fa-angle-right arrows"></i>}
                                    </div>
                                </div>}
							</div>
						</div>
						<Versionamento/>
					</div>
				</div>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>Venda</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>ID</p>
								<p>{this.state.modalDetalhesId}</p>
							</div>
							<div className="conteudo-info">
								<p>Empresa</p>
								<p>{this.state.modalDetalhesEmpresa}</p>
							</div>
							<div className="conteudo-info">
								<p>Cliente</p>
								<p>{this.state.modalDetalhesCliente}</p>
							</div>
							<div className="conteudo-info">
								<p>Usuário</p>
								<p>{this.state.modalDetalhesUsuario}</p>
							</div>
							<div className="conteudo-info">
								<p>Data da Venda</p>
								<p>{this.state.modalDetalhesData}</p>
							</div>
							<div className="conteudo-info">
								<p>Valor da Venda</p>
								<p>R$ {this.state.modalDetalhesValor}</p>
							</div>
							<div className="conteudo-info">
								<p>Data de cadastro</p>
								<p>{this.formatTime(this.state.modalDetalhesAdmissaodata)}</p>
							</div>
							<div className="conteudo-info">
								<p>Ultima vez Atualizado</p>
								<p>{this.formatTime(this.state.modalDetalhesAtualizacaodata)}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL EDITAR */}
				<Modal
					isOpen={this.state.modalEditarOpen}
					toggle={this.toggleModalEditar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalEditar.bind(this)}>Editar</ModalHeader>
					<ModalBody>
						<div className="content-formulario">
							<div className="descricao-formulario">
								<p>Preenchimento de formulário para editar registro de vendas do sistema.</p>
								<p>Todos os campos com * são obrigatórios.</p>
							</div>
							<div className="grupo-input input-style-cad">
								<label htmlFor="empresa">Empresa *</label>
								<div className="grupo-campo-input input-cad" id="empresa">
									<i className="fas fa-briefcase" id="iconEmpresa"></i>
									<select
										value={this.state.modalEditarEmpresa}
										name="empresa"
										placeholder="&nbsp;Selecione a Empresa vendedora"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarEmpresa: event.target.value })}
										required
									>
										<option value="NULL">Selecione a Empresa vendedora</option>
										{
											this.state.empresas.map((empresa, id) => (
												<option key={empresa.empresa_id} value={empresa.empresa_id}>{empresa.empresa_nomefantasia}</option>
											))
										}
									</select>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="cliente">Cliente *</label>
								<div className="grupo-campo-input input-cad" id="cliente">
									<i className="fas fa-user-tag" id="iconCliente"></i>
									<select
										value={this.state.modalEditarCliente}
										name="cliente"
										placeholder="&nbsp;Selecione o Cliente comprador"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarCliente: event.target.value })}
										required
									>
										<option value="NULL">Selecione a Cliente comprador</option>
										{
											this.state.clientes.map((cliente, id) => (
												<option key={cliente.cliente_id} value={cliente.cliente_id}>{cliente.cliente_nome}</option>
											))
										}
									</select>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="usuario">Usuário *</label>
								<div className="grupo-campo-input input-cad" id="usuario">
									<i className="fas fa-user-edit" id="iconUsuario"></i>
									<select
										value={this.state.modalEditarUsuario}
										name="usuario"
										placeholder="&nbsp;Selecione o Usuário vendedor"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarUsuario: event.target.value })}
										required
									>
										<option value="NULL">Selecione a Usuário vendedor</option>
										{
											this.state.usuarios.map((usuario, id) => (
												<option key={usuario.usuario_id} value={usuario.usuario_id}>{usuario.usuario_nome}</option>
											))
										}
									</select>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<Autocomplete
									multiple
									id="tags-outlined"
									options={this.state.options}
									getOptionLabel={(option) => option.label}
									onChange={(event, valores) => this.handleItens(valores, 'editar')}
									filterSelectedOptions
									size="small"
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label="Selecione os itens"
											placeholder="Itens"
										/>
									)}
								/>

							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="valor">Valor *</label>
								<div className="grupo-campo-input input-cad" id="valor">
									<i className="fas fa-tag" id="iconValor"></i>
									<input
										type="text"
										value={this.state.modalEditarValor}
										placeholder="&nbsp;Informe o Valor da venda"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarValor: event.target.value })}
										required
									/>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="metodoPagamento">Método de Pagamento *</label>
								<div className="grupo-campo-input input-cad" id="metodoPagamento">
									<i className="fas fa-hand-holding-usd" id="iconMetodoPagamento"></i>
									<select
										value={this.state.modalEditarMetodopagamento}
										name="metodoPagamento"
										placeholder="&nbsp;Selecione o Método de Pagamento"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarMetodopagamento: event.target.value })}
										required
									>
										<option value="NULL">Selecione o Método de Pagamento</option>
										<option value="DINHEIRO">Dinheiro</option>
										<option value="CREDITO">Cartão de Crédito</option>
										<option value="DEBITO">Cartão de Débito</option>
										<option value="CHEQUE">Cheque</option>
										<option value="TRANSFERENCIA">Transferência Bancária</option>
									</select>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="parcelas">Quantidade de Parcelas *</label>
								<div className="grupo-campo-input input-cad" id="parcelas">
									<i className="fas fa-divide" id="iconParcelas"></i>
									<select
										value={this.state.modalEditarParcelas}
										name="parcelas"
										placeholder="&nbsp;Selecione o Nível do usuário"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarParcelas: event.target.value })}
										required
									>
										<option value="NULL">Selecione a Quantidade de Parcelas</option>
										<option value="1">x1</option>
										<option value="2">x2</option>
										<option value="3">x3</option>
										<option value="4">x4</option>
										<option value="5">x5</option>
										<option value="6">x6</option>
										<option value="7">x7</option>
										<option value="8">x8</option>
										<option value="9">x9</option>
										<option value="10">x10</option>
										<option value="11">x11</option>
										<option value="12">x12</option>
									</select>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="entrada">Entrada *</label>
								<div className="grupo-campo-input input-cad" id="entrada">
									<i className="fas fa-piggy-bank" id="iconEntrada"></i>
									<input
										value={this.state.modalEditarEntrada}
										type="text"
										placeholder="&nbsp;Informe a Entrada da venda"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarEntrada: event.target.value })}
										required
									/>
								</div>
							</div>

							<div className="grupo-input input-style-cad">
								<label htmlFor="desconto">Desconto</label>
								<div className="grupo-campo-input input-cad" id="desconto">
									<i className="fas fa-donate" id="iconDesconto"></i>
									<input
										value={this.state.modalEditarDesconto}
										type="text"
										placeholder="&nbsp;Informe o Desconto da venda"
										autoComplete="on"
										onChange={event => this.setState({ modalEditarDesconto: event.target.value })}
										required
									/>
								</div>
							</div>

							<div className="aviso-formulario">
								<p className="aviso" id="aviso">{this.state.errorMessage}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.apiEditarVenda}>Cadastrar</Button>
						<Button onClick={this.toggleModalEditar.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL DELETAR */}
				<Modal
					isOpen={this.state.modalDeletarOpen}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente deletar a venda #{this.state.modalDeletarId}?
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.apiDeletarVenda} color="danger">Deletar</Button>
						<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL AVISO */}
				<Modal
					isOpen={this.state.modalAvisoOpen}
					toggle={this.toggleModalAviso.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
					<ModalBody>
						{this.state.modalAvisoText}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
					</ModalFooter>
				</Modal>


				{/* MODAL CANCELAR */}
				<Modal
					isOpen={this.state.modalCancela}
					toggle={this.toggleModalCancelar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalCancelar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente Cancelar a venda?
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-danger" onClick={this.cancelarVenda} color="danger">Cancelar Venda</button>
						{/* <i className="fas fa-times" onClick={() => this.cancelarVenda(this.state.vendaId)}></i> */}
					</ModalFooter>
				</Modal>

			</section>
		)

	}

}

export default Vendas;