import React from 'react';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      apiData: '',
      apiUsuarioEmail: '',
      apiUsuarioSenha: '',
      usuarioId: 0,
      usuarioNome: '',
      usuarioEmail: '',
      usuarioTelefone: '',
      usuarioSenha: '',
      usuarioNivel: 0,
      usuarioData: '',
      senhaVisivel: false,
      error: '',
      errorMessage: '',
    };

  }

  getUsuarioPorId(id) {
    try {
      api_topmotos.get('/usuarios/id/' + id)
    } catch (err) {
      this.setState({ error: "Ocorreu um erro!" });
    }
  }

  log(msg) {
    console.log("[" + this.getTimestamp() + "] clienteREGISTRO > " + msg)
  }

  getTimestamp() {
    var today = new Date();
    var date = today.getFullYear() + '-'
      + ('0' + (today.getMonth() + 1)) + '-'
      + ('0' + today.getDate()).slice(-2);

    var time = ('0' + today.getHours()).slice(-2) + ':'
      + ('0' + today.getMinutes()).slice(-2) + ':'
      + ('0' + today.getSeconds()).slice(-2);

    var dateTime = date + ' ' + time;
    return dateTime;
  }

  togglePassword = () => {
    var s = this.state.senhaVisivel;
    this.setState({ senhaVisivel: !s });
  }

  apiLoginUsuario = async event => {
    event.preventDefault();

    const {
      usuarioEmail,
      usuarioSenha
    } = this.state;

    if (!usuarioEmail || !usuarioSenha) {
      this.setState({ errorMessage: "Preencha todos os campos!" })
      return
    }

    let { data } = await api_topmotos.post('/usuarios/login', { email: usuarioEmail, senha: usuarioSenha })


    if (data == "FALL") {
      this.setState({ errorMessage: "Usuário inválido!" })
      return
    }

    let d;
    try {
      d = jwt.decode(data.token, "GoldWindLaw")
    } catch (error) {
      this.setState({ errorMessage: "Sessão expirada!" })
      return
    }

    localStorage.setItem("token", data.token)
    localStorage.setItem('usuario_id', d.uid);
    localStorage.setItem('usuario_nome', d.usa);
    localStorage.setItem('usuario_nivel', d.nvl);
    localStorage.setItem('usuario_empresa', d.emp);

    this.props.history.push('/notificacao');
  }

  render() {
    const { senhaVisivel } = this.state;

    return (

      <section>
        <div className="content-lexlogin">
          <div className="magicLogin">
            <img src={require("../../imgs/motoca.jpeg")} alt="" className="pyro" />
            <div className="overlay">
              <img src={require("../../imgs/logo.png")} alt="" />
            </div>
          </div>
          <div className="login p-3">
            <h1>Login</h1>
            <p>Faça seu login, e entre no gerenciador.</p>
            <div className="grupo-input-login" id="email">
              <i className="far fa-envelope" id="iconEmail"></i>
              <input
                type="email"
                placeholder="&nbsp;Informe o e-mail do usuário"
                autoComplete="on"
                onChange={event => this.setState({ usuarioEmail: event.target.value, errorMessage:'' })}
                required
              />
            </div>
            <div className="grupo-input-login" id="senha">
              <i className="fas fa-lock" id="iconSenha"></i>
              <input
                type={(senhaVisivel) ? "text" : "password"}
                placeholder="&nbsp;Informe a senha do usuário"
                autoComplete="on"
                onChange={event => this.setState({ usuarioSenha: event.target.value, errorMessage:'' })}
                required
              />
              <i
                className={`fa ${senhaVisivel ? "fa-eye-slash" : "fa-eye"}`}
                id="iconOlho"
                onClick={this.togglePassword}
              />
            </div>
            <button className="grupo-input-submit" onClick={this.apiLoginUsuario}>Entrar</button>
            <div className="aviso-formulario">
              <p className="aviso avisoerr">{this.state.errorMessage}</p>
            </div>
          </div>
        </div>

      </section>

    )
  }
}

export default Login;