import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class GestaoPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            dados: [],
            head: [],
            despesas: [],
            pagina: 1, 
            quantidade_paginas: 1,
						status: [],
			quantidade: '',
			data: '',
			selectStatus: '',

			usuarios: [],
			userId: '',

			listAll: false,

			filter: {},
		};

		this.verificarPermissao();
	}

	componentDidMount() {
        this.apiListarHead();
				this.apiListarUsuarios();
        this.apiGestao();
				this.apiListarStatus();
	}

	verificarPermissao = async event => {
		if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            let gestaoall = res.data.filter(a => a.rota == "/gestaoall");
						this.setState({listAll: gestaoall.length == 0 ? false : true});
						res = res.data.filter(a => a.rota == "/gestao")
            if(res.length == 0){
                this.props.history.push('/dashboardcomercial')
            }
        } else{
            this.props.history.push('/dashboardcomercial')
        }
    }

	log(msg) {
		console.log("[" + this.getTimestamp() + "] clientesUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
  }

	apiListarUsuarios = async () => {
		let res = await api_topmotos.get('/usuarios');
		this.setState({usuarios: res.data})
	}
    
	apiListarHead = async event => {
        let head = ['Data Compra', 'Data Venda', 'Vendedor', 'Modelo - Ano - Cor', 'Placa'];

        let res = await api_topmotos.get('/despesas/');
        for(let i of res.data){
            head.push(i.descricao)
        }
        
        head.push('Aquisição', 'Gastos', 'Venda', 'Margem', 'Comissão')
				let filter = this.state.filter
				for(let i of head){
						if (head[i]) filter[head[i]] = '';
				}

        this.setState({filter: filter , head: head, despesas: res.data})
    }
    
    apiGestao = async event => {
			let res = await api_topmotos.get('/gestao');
				
        this.setState({
            dados: res.data,
            quantidade_paginas: Math.ceil((Object.values(res.data).length)/ 10),
            quantidade: (Object.values(res.data).length)
        })
	}
	
	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	groupBy = (object, property) => {
		var val, list = {}, result = [];
		Object.values(object).forEach(o => {
			val = o[property]
			if (!list[val]) list[val] = [];
			list[val].push(o);
		})
		Object.values(list).forEach(v => {
			val = v[0]
			result.push(val)
		});

		return result.reverse();
	}

	alterFilter = (value, head) => {
		let filter = this.state.filter
		filter[head] = value
		this.setState({filter})
	}
	
	filtrar = (ara) => {
		ara = this.groupBy(ara, 'data')
		let filtro = Object.values(ara).filter(v => {
			return this.filtrarDespesas(v) && (localStorage.getItem('usuario_id') == v.usuario_id || this.state.listAll) && this.filtrarData(v) && this.filtrarNome(v) && this.filtrarStatus(v) && this.filtrarHead(v);
		})

		return filtro;
	}

	filtrarStatus = (v) => {
		return !this.state.selectStatus || (v.status &&
			v.status.toString().toLowerCase().includes(this.state.selectStatus.toLowerCase()));
	}

	filtrarData = (v) => {
		return !this.state.data || (moment(v.venda_admissaodata).format('YYYY-MM') &&
			moment(v.venda_admissaodata).format('YYYY-MM').toString().toLowerCase().includes(this.state.data.toLowerCase()));
	}

	filtrarNome = (v) => {
		return !this.state.userId || (v.usuario_id &&
			v.usuario_id.toString().toLowerCase().includes(this.state.userId.toLowerCase()));
  }

	filtrarDespesas = (v) => {
		let despesasF = this.state.despesas.some(d => {
			return this.state.filter[d.descricao] && this.state.filter[d.descricao] !== ""
		})
		return !despesasF || (v.despesa.length > 0 && v.despesa.every(d => {
			return !this.state.filter[d.descricao] || (d.valor && d.valor !== "" &&
			this.formatValue(d.valor).toString().toLowerCase().includes(this.state.filter[d.descricao].toLowerCase()))
		}))
	}

	filtrarDespesa = (item, id) => {
		var despesa = item.find(a => a.despesa == id)
		return despesa ? parseFloat(despesa.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
	}
    
  filtrarHead = (a) => {
			let comissao = a.comissao ? Number((a.venda - this.gastos(a.despesa, a.valor)) * a.comissao / 100) > 120
				? this.formatValue((a.venda - this.gastos(a.despesa, a.valor)) * a.comissao / 100)
				: this.formatValue(120)
			: ''
			return this.filtrarDate(a.data, this.state.filter[this.state.head[0]]) &&
			this.filtrarDate(a.venda_admissaodata, this.state.filter[this.state.head[1]]) &&
			this.filtrarValues(a.usuario_nome, this.state.filter[this.state.head[2]]) &&
			this.filtrarValues(a.item_marca+' '+a.item_modelo+' '+a.ano_mod+' '+a.cor, this.state.filter[this.state.head[3]]) &&
			this.filtrarValues(a.placa, this.state.filter[this.state.head[4]]) &&
			this.filtrarValues(this.formatValue(a.valor), this.state.filter[this.state.head[5+this.state.despesas.length]]) &&
			this.filtrarValues(this.formatValue(this.gastos(a.despesa, a.valor)), this.state.filter[this.state.head[6+this.state.despesas.length]]) &&
			this.filtrarValues(this.formatValue(a.venda), this.state.filter[this.state.head[7+this.state.despesas.length]]) &&
			this.filtrarValues(this.formatValue(a.venda - this.gastos(a.despesa, a.valor)), this.state.filter[this.state.head[8+this.state.despesas.length]]) &&
			this.filtrarValues(comissao, this.state.filter[this.state.head[9+this.state.despesas.length]]) 
	}

	filtrarValues = (value, filter) => {
		return !filter|| (value &&
			value.toString().toLowerCase().includes(filter.toLowerCase()));
	}
	filtrarDate = (date, filter) => {
		let value = moment(date).format('DD/MM/YYYY')
		return !filter|| (value &&
			value.toString().toLowerCase().includes(filter.toLowerCase()));
	}
	
	gastos = (despesas, compra) =>{
		var valor = compra, despesa = [];
		this.state.despesas.map(a => (
			despesa.push(despesas.find(i => i.despesa == a.id))
		))

		for(let i of despesa){
			if(i){
				valor += i.valor
			}
		}

		return valor ? valor : ''
	}

	formatValue = (valor) => {
		return valor ? parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
	}

	openPrint = () => {
		window.print();
	}

	apiListarStatus = async event => {
		let res = await api_topmotos.get('/status/ordem');
		this.setState({ status: res.data, statusSE: res.data[0] });
	}

	render() {

		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Gestão</p>
							<div style={{display: 'flex'}}>
								<div className="td-acoes" style={{width: '70px', float: 'left'}}>
									<i className="fas fa-print" onClick={() => this.openPrint()}></i>
								</div>
								<div className="grupo-input input-style-cad" style={{width: '230px', float: 'left', marginRight: "20px"}}>
									<div className="grupo-campo-input input-cad" id="statusI">
										<select
                    	name="tipo"
                     	placeholder="&nbsp;Selecione o Status"
                     	autoComplete="on"
                     	required
                     	onChange={a => this.setState({selectStatus: a.target.value})}
                    >

                    <option value="">Todos os Status</option>
                    {
												this.state.status.map((e) => (
													<option key={e.id} value={e.id}>{e.status}</option>
												))
                    }
										</select>
									</div>
								</div>
								{this.state.listAll ? <div className="grupo-input input-style-cad" style={{width: '230px', float: 'left', marginRight: "20px"}}>
									<div className="grupo-campo-input input-cad" id="userI">
										<select
                    	name="tipo"
                     	placeholder="&nbsp;Selecione o Vendedor"
                     	autoComplete="on"
                     	required
                     	onChange={a => this.setState({userId: a.target.value})}
                    >

                    <option value="">Todos os Vendedores</option>
                    {
												this.state.usuarios.map((user) => (
													<option key={user.usuario_id} value={user.usuario_id}>{user.usuario_nome}</option>
												))
                    }
										</select>
									</div>
								</div>: null}
								<div className="grupo-input input-style-cad" style={{width: '230px'}}>
									<div className="grupo-campo-input input-cad" id="dataI">
										<i className="far fa-calendar-alt" id="iconDataI"></i>
										<input type="month" value={this.state.data} onChange={a => this.setState({ data: a.target.value, pagina: 1})}/>
									</div>
								</div>
							</div>
						</div>
					<div className="table">
						<table className="print-table gestao" style={{display: "none"}}>
								<p style={{textAlign: "center", fontSize: "15px", margin: "10px"}}>
									Relatório Consolidado de Vendas
								</p>
								<thead>
									<tr>
									{
										this.state.head.map(th => (
											<th>
												<div className="d-flex align-center justify-between">
													{th}
												</div>
											</th>
										))
									}
									</tr>
								</thead>
								<tbody>
									{this.filtrar(this.state.dados).map(a => (
										<tr>
												<td>{moment(a.data).format('DD/MM/YYYY')}</td>
												<td>{a.venda_admissaodata ? moment(a.venda_admissaodata).format('DD/MM/YYYY') : ' '}</td>
												<td>{a.usuario_nome}</td>
												<td>{`${a.item_marca}  ${a.item_modelo} -  ${a.ano_mod} - ${a.cor}`}</td>
												<td>{a.placa}</td>
												{this.state.despesas.map(i => (
													<td>{this.filtrarDespesa(a.despesa, i.id)}</td>
												))}
											<td>{this.formatValue(a.valor)}</td>
											<td>{this.formatValue(this.gastos(a.despesa, a.valor))}</td>
											<td>{this.formatValue(a.venda)}</td>
											<td>{this.formatValue(a.venda - this.gastos(a.despesa, a.valor))}</td>
											<td>{a.comissao 
												? Number((a.venda - this.gastos(a.despesa, a.valor)) * a.comissao / 100) > 120
													? this.formatValue((a.venda - this.gastos(a.despesa, a.valor)) * a.comissao / 100)
													: this.formatValue(120)
											: ''}
											</td>
										</tr>
										))}
									</tbody>
							</table>
							<table>
								<thead>
									<tr>
                    {
                      this.state.head.map(th => (
                        <th>
                          <div className="d-flex align-center justify-between">
                            {th}
                          </div>
                        </th>
                      ))
                    }
									</tr>
									<tr>
										{this.state.head.map(th => (
											<th>
												<div className="d-flex align-center justify-between">
													<i className="fas fa-search"></i>
													<input type="text" value={this.state.filter[th]} onChange={a => this.alterFilter(a.target.value, th)} />
												</div>
											</th>
										))}
									</tr>
                </thead>
								<tbody>
                                    {this.filtrar(this.state.dados).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(a => (
                                        <tr>
																					<td>{moment(a.data).format('DD/MM/YYYY')}</td>
																					<td>{a.venda_admissaodata ? moment(a.venda_admissaodata).format('DD/MM/YYYY') : ' '}</td>
																						<td>{a.usuario_nome}</td>
                                            <td>{`${a.item_marca}  ${a.item_modelo} -  ${a.ano_mod} - ${a.cor}`}</td>
                                            <td>{a.placa}</td>
                                            {this.state.despesas.map(i => (
                                                <td>{this.filtrarDespesa(a.despesa, i.id)}</td>
                                            ))}
																					<td>{this.formatValue(a.valor)}</td>
																					<td>{this.formatValue(this.gastos(a.despesa, a.valor))}</td>
																					<td>{this.formatValue(a.venda)}</td>
																					<td>{this.formatValue(a.venda - this.gastos(a.despesa, a.valor))}</td>
																					<td>{a.comissao 
																					? Number((a.venda - this.gastos(a.despesa, a.valor)) * a.comissao / 100) > 120
																						? this.formatValue((a.venda - this.gastos(a.despesa, a.valor)) * a.comissao / 100)
																						: this.formatValue(120)
																					: ''
																					}</td>
                                        </tr>
                                    ))}
								</tbody>
							</table>
						</div>
						{this.filtrar(this.state.dados).length > 0 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fff'}}>
							<div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
								this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
							}}>
								{<i class="fas fa-angle-left arrows"></i>}
							</div>
							{
								[...Array(Math.ceil((this.filtrar(this.state.dados)).length / 10))].map((pagina, index) => {
									if( (this.state.pagina === 1 && index + 1 < 4) || 
										(this.state.pagina === (Math.ceil((this.filtrar(this.state.dados)).length / 10)) && index + 1 > (Math.ceil((this.filtrar(this.state.dados)).length / 10)) - 3) ||
										(index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
									){
										return (
											<div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
											onClick={() => {
												this.setState({pagina: index + 1})
											}}>
												{index + 1}
											</div>
										)
									}
									else{
										return null
									}
								})
							}
							<div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
								this.setState({pagina: this.state.pagina < (Math.ceil((this.filtrar(this.state.dados)).length / 10)) ? this.state.pagina + 1 : (Math.ceil((this.filtrar(this.state.dados)).length / 10))})
							}}>
								{<i class="fas fa-angle-right arrows"></i>}
							</div>
						</div>}
						<div className="form-footer">
							<span></span>
						</div>
						<Versionamento/>
					</div>
				</div>	
      </section>
		)
	}
}

export default GestaoPage;
