import axios from 'axios';

// DECLARAÇÃO DE URLs
// axios.defaults.headers["Tokenmoto"] = 

const api_topmotos  = axios.create({ baseURL: process.env.REACT_APP_TOPMOTOS_API_URL, headers: {Tokenmoto: localStorage.getItem('token')}});
const api_viacep    = axios.create({ baseURL: process.env.REACT_APP_VIACEP_API_URL });

// api_topmotos.defaults.headers.commom

console.log(localStorage.getItem("token"));


// headers: {toak: }

export {
    api_topmotos,
    api_viacep
}