import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'


class ModeloPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dados: []
        };
    }


    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/modelos")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.apiListarModelos();
        }
    }

    apiListarModelos = async () => {
        let { data } = await api_topmotos.get("/modelosdoc")
        this.setState({ dados: data })
    }

    formatTime(e) {
        let [dias, tempo] = e.split("T");
        let [ano, mes, dia] = dias.split('-');
        let [hora, minutos, segundos] = tempo.split(':');

        return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p>Modelos</p>
                                <div className="form-add" onClick={() => this.props.history.push("/cadastrarmodelos")}>
                                    Adicionar <i className="fas fa-plus"></i>
                                </div>
                            </div>

                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    ID
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Nome
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Tipo
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Dia e hora
													<i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Ações
										    	</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dados.map(e => (
                                                <tr>
                                                    <td>{e.id}</td>
                                                    <td>{e.nome}</td>
                                                    <td>{e.tipo == 1 ? "Contrato" : "Procuração"}</td>
                                                    <td>{moment(e.dia_hora).format('DD/MM/YY HH:mm')}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-pen" onClick={() => this.props.history.push("/editarmodelo/" + e.id)}></i>&nbsp;
												    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="form-footer">
                                    <span></span>
                                </div>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ModeloPage