import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

import { api_viacep } from '../../services/api';


class RestricaoPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            modulos: [],
            menu_itens: [],
            itens: [],
            itensUp: [],
            restricao: [],
            itensR: [],
            itensRUp: [],
            
        }
    }

    componentDidMount(){
        this.apiListarModulos();
        this.apiListarRestricao();
        this.apiListarMenuItens();
    }

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }
    
    apiListarModulos = async event => {
        let res = await api_topmotos.get('/modulos/');
        this.setState({ modulos: res.data});
    }

    apiListarRestricao = async event => {
        let res = await api_topmotos.get('/restricao/');
        this.setState({ restricao: res.data});
        
        this.apiListarUserRestricao();
    }

    apiListarMenuItens = async event => {
        let res = await api_topmotos.get('/menu');
        this.setState({ menu_itens: res.data});

        let it = {}

        for(let i of res.data){
            it[`${i.id}`] = 0
        }
        
        this.setState({itens: it})
        
        this.apiListarUser();
    }

    apiListarUser = async event => {
        let res = await api_topmotos.get('/permissoes/user/' + this.props.match.params.id);
        
        let it = {}
        let te = {}

        for(let i of this.state.menu_itens){
            let itens = res.data.filter(a => a.id == i.id)
            it[`${i.id}`] = itens.length == 0 ? 0 : true
            te[`${i.id}`] = itens.length == 0 ? 0 : true
        }
        
        this.setState({itensUp: te, itens: it})
    }

    apiListarUserRestricao = async event => {
        let res = await api_topmotos.get('/permissaoSistema/user/' + this.props.match.params.id);
        
        let it = {}
        let te = {}

        for(let i of this.state.restricao){
            let itens = res.data.filter(a => a.restricao_id == i.id)
            it[`${i.id}`] = itens.length == 0 ? 0 : true
            te[`${i.id}`] = itens.length == 0 ? 0 : true
        }
        
        this.setState({itensRUp: te, itensR: it})
    }

    selecionarItens = (id, val) =>{
        let {itens} = this.state;

        itens[id] = val

        this.setState({itens: itens})
    }

    selecionarItensRestricao = (id, val) =>{
        let {itensR} = this.state;

        itensR[id] = val

        this.setState({itensR: itensR})
    }

    salvar = async () =>{
        await this.apiCadastrarPermissao();
        await this.apiCadastrarPermissaoSistema();
        
        this.props.history.push('/usuarios')
    }


    apiCadastrarPermissao = async () =>{

        if(this.state.itens){
            for(let i in this.state.itens){
                if(this.state.itensUp[i] == true && this.state.itens[i] == false)
                {
                    api_topmotos.post(`/permissoes/delete`, {
                        usuario: this.props.match.params.id,
                        menu: i,
                    })
                    continue

                }
                
                if(!this.state.itens[i] || (this.state.itensUp[i] == true && this.state.itens[i] == true)){
                  continue  
                } 
                
                try{
                    api_topmotos.post(`/permissoes/create`, {
                        usuario: this.props.match.params.id,
                        menu: i,
                    })

                } catch{
                    this.setState({ error: "ocorreu um erro ao conectar com a API! (/itens/update)" });
                    this.log(this.state.error);
                }
            }

        } 
    }

    apiCadastrarPermissaoSistema = async () =>{
        if(this.state.itensR){
            for(let i in this.state.itensR){
                if(this.state.itensRUp[i] == true && this.state.itensR[i] == false)
                {
                    api_topmotos.post(`/permissaoSistema/delete`, {
                        usuario: this.props.match.params.id,
                        restricao: i,
                    })
                    continue

                }
                
                if(!this.state.itensR[i] || (this.state.itensRUp[i] == true && this.state.itensR[i] == true)){
                  continue  
                } 
                
                try{
                    api_topmotos.post(`/permissaoSistema/create`, {
                        usuario: this.props.match.params.id,
                        restricao: i,
                    })

                } catch{
                    this.setState({ error: "ocorreu um erro ao conectar com a API! (/itens/update)" });
                    this.log(this.state.error);
                }
            }
        } 
    }


    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Restrição de Usuário</strong></p>

                            </div>
                            <div className="content-formulario">
                                    {
                                        this.state.modulos.map((mod) => (
                                            <>
                                                <p className="py-2"><strong>{mod.nome}</strong></p>
                                                {this.state.menu_itens.filter((itens) => {
                                                return itens.modulo == mod.id }).map((menu) => {
                                                    return (
                                                        <>
                                                            <div className="check-itens">
                                                                <input type="checkbox" id={"menu" + menu.id} name={"menu" + menu.id} value={menu.id} onClick={a => this.selecionarItens(menu.id, a.target.checked)} defaultChecked={this.state.itensUp[menu.id]}/>
                                                                <label htmlFor={"menu" + menu.id}>{menu.itens}</label>
                                                            </div>
                                                        </>
                                                    )
                                            })} 
                                            </>
                                            
                                        ))
                                    }

                                    <p className="py-2"><strong>Permissões do Sistema</strong></p>

                                    {this.state.restricao.map((res) => {
                                        return (
                                            <>
                                                <div className="check-itens">
                                                    <input type="checkbox" id={"permissao" + res.id} name={"permissao" + res.id} value={res.id} onClick={a => this.selecionarItensRestricao(res.id, a.target.checked)} defaultChecked={this.state.itensRUp[res.id]}/>
                                                    <label htmlFor={"permissao" + res.id}>{res.descricao}</label>
                                                </div>
                                            </>
                                        )
                                    })}
                                <button onClick={this.salvar} className="cadbtn btn mt-1">Confirmar</button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default RestricaoPage