import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';
import { api_viacep } from '../../services/api';
import InputMask from 'react-input-mask';
import moment from 'moment';

import veiculosImg from '../../imgs/desenho_venda.png';
import termoImg from '../../imgs/desenho_termo.png';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class VendasR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            venda: [],
            cliente: [],
            statusVenda: [],
            vendedores: [],
            empresas: [],
            categorias: [],
            contas: [],
            movimentacoes: [],
            movimentacoesTabela: [],
            tipoPagamento: [],
            itens: [],
            options: [],
            modalCadastrarItens: [],

            clienteId: '',
            nome: '',
            email: '',
            telefone: '',
            cpf: '',

            enderecoId: '',
            cep: '',
            cidade: '',
            estado: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',

            openmodal: false,
            valor: '',
            modeleSE: '',
            timing: null,
            lettersInfo: null,

            vendedor: '',
            empresaId: '',
            status: '',

            modalCadastrar: false,

            statusMov: '',
            dataPagamento: '',
            valorPago: '',
            descricao: '',
            tipo: '',
            conta: '',
            categoria: '',
            valor1: '',
            dataVencimento: '',

            vendaId: '',

            passo: '',
            indexperg: 0,
            titles: ['Cliente', 'Selecionar Caminho', 'Termo de Venda', 'Pagamento'],
            valorVenda: '',

            troca: '',
            modeloTroca: '',
            idVenda: '',
            modeloVenda: '',
            itemTroca: '',
            itemId: '',
            compra_id: '',
            valorAr: '',
            descricaoTroca: '',
            categoriaTroca: '',
            compraId: '',
            trocaV: false,

            contasBancarias: [],
            contaB: '',
            dataCompetencia: '',

            repetirLancamento: '',
            meses: '',
            dataRepeticao: [],
            parcela_id: '',
            valorParcela: '',

            display: 'none',
            nConta: '',
            banco: '',
            agencia: '',
            tipoConta: '',
            tipoPag: '',
            movId: '',

            empresas: [],
            empresa: '',

            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',
            valorDeletado: '',
            venda_id: '',
            mov: '',
            valorMov: '',
            permitir: '',
            
            displayTParcela: 'none',
            tipoParcelamento: '',
            valorTParcela: '',
            tParcela: '',
            statusCompra: '',
            
            displayLoading: false,

            trocaS: false,
            movC: '',
            valorTroca: ''
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            await this.listarVenda();
            await this.listarCliente();
            await this.listarStatus();
            await this.listarVendedores();
            await this.listarEmpresas();
            await this.listarCategorias();
            await this.listarContas();
            await this.listarMovimentacao();
            await this.listarPagamento();
            await this.listarEndereco();
            await this.listarItens(); 
            await this.listarContasBancarias();
            this.apiListarPermissao();
        }
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    listarVenda = async () =>{
        let res = await api_topmotos.get('/vendas/id/'+this.props.match.params.id);
        this.setState({ 
            venda: res.data, 
            vendaId: this.props.match.params.id,
            clienteId: res.data[0].venda_cliente, 
            empresaId: res.data[0].venda_empresa,
            status: res.data[0].venda_status,
        });
    }

    listarCliente = async () =>{
        let res = await api_topmotos.get('/pessoas/id/'+this.state.clienteId);
        this.setState({
            cliente: res.data,
            nome: res.data[0].pessoa_nome,
            email: res.data[0].pessoa_email,
            telefone: res.data[0].pessoa_telefone,
            celular: res.data[0].pessoa_celular,
            rg: res.data[0].pessoa_rg,
            cpf: res.data[0].pessoa_cpf_cnpj,
            enderecoId: res.data[0].pessoa_endereco
        });


    }

    listarStatus = async () =>{
        let res = await api_topmotos.get('/vendaStatus/');
        this.setState({ statusVenda: res.data });
    }

    listarVendedores = async () =>{
        let res = await api_topmotos.get('/usuarios/');
        this.setState({ vendedores: res.data });
    }

    listarEmpresas = async event => {
        let res = await api_topmotos.get('/empresas/');
        this.setState({ empresas: res.data });
    }

    listarCategorias = async () =>{
        let res = await api_topmotos.get('/categoriasAll/');

        let groups = res.data.filter(r => {
            return !r.cat_id && r.tipo == "R";
        })
        groups.forEach(g => {
            g.options = res.data.filter(r => {
                return r.cat_id == g.id;
            })
        })

        this.setState({ categorias: groups });
    }

    listarContas = async () =>{
        let res = await api_topmotos.get('/contas/');
        this.setState({ contas: res.data });
    }

    listarMovimentacao = async () =>{
        let res = await api_topmotos.get('/financeiro/');
        this.setState({ movimentacoes: res.data });
    }

    listarPagamento = async () =>{
        let res = await api_topmotos.get('/tipopag/');
        res = res.data.filter( t => t.venda == 1);
        this.setState({ tipoPagamento: res });
    }

    listarEndereco = async () => {
        let res = await api_topmotos.get('/enderecos/id/'+this.state.enderecoId);
        this.setState({
            numero: res.data[0].endereco_numero, 
            complemento: res.data[0].endereco_complemento
        })

        this.getCEP(res.data[0].endereco_cep)
    }

    listarItens = async event => {
        let res = await api_topmotos.get('/itens/');
        this.setState({ itens: res.data.filter(y => y.status == 1 || y.status == 5) });
    }

    // Listar Contas Bancárias
    listarContasBancarias = async () =>{
        let res = await api_topmotos.get('/contasBancarias/');
        this.setState({ contasBancarias: res.data });
    }

    getCEP = async (event) => {
        let cep = event.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');

        this.setState({ cep: event});

        if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                estado: res['data'].uf,
                cidade: res['data'].localidade,
                bairro: res['data'].bairro,
                logradouro: res['data'].logradouro,
            })
        }
    }

    slide = (cont) => {
        var questionario = document.getElementsByClassName("perguntas");
        var aberta;

        for(var i = 0; i < questionario.length; i++){
            if(questionario[i].style.display == 'flex'){
                aberta = i;
            }
            questionario[i].style.display = 'none';
        }

        questionario[aberta + cont].style.display = 'flex'
        this.setState({indexperg: aberta + cont})
    }

    // Itens

    togglemodal = () => this.setState({ openmodal: !this.state.openmodal })

    setValor = async (itens) => {
        var somaValor = 0;
        if (itens) {
            let max = itens.length;
            for (let i = 0; i < max; i++) {
                let idItem = itens[i];
                let res = await api_topmotos.get('/itens/id/' + idItem);
                somaValor += res.data.item[0].item_preco;
            }
            this.setState({ valor: somaValor, valorMov: somaValor});
        }
    }

    additem = async () => {
        let a = this.state.modalCadastrarItens.concat(this.state.lettersInfo.item_id);

        this.setValor(a)
        this.setState({modalCadastrarItens: this.state.modalCadastrarItens.concat(this.state.lettersInfo.item_id), lettersInfo: null, modeleSE: '', options: this.state.options.concat(this.state.lettersInfo)})
        
    }

    reviewModal = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_id == e && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
        this.setState({
            lettersInfo: data[0]
        })
    }

    rebuildModal = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(e.target.value.toLowerCase()) && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        this.setState({
            modeleSE: e.target.value,
        })

        if (item == undefined || e.target.value.length == 0) {
            this.setState({
                lettersInfo: null
            })
            return
        }

        this.setState({
            timing: setTimeout(async () => {
                let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
                this.setState({
                    lettersInfo: data[0]
                })
            }, 450)
        })
    }

    rebuildModalTroca = async (e) => {
        if (this.state.timing) {
            clearTimeout(this.state.timing)
        }

        let item = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(e.target.value.toLowerCase()) && !this.state.modalCadastrarItens.includes(y.item_id))[0]

        this.setState({
            modeloTroca: e.target.value,
        })

        if (item == undefined || e.target.value.length == 0) {
            this.setState({
                lettersInfo: null
            })
            return
        }

        this.setState({
            timing: setTimeout(async () => {
                let { data } = await api_topmotos.post("/itens/unique", { id: item.item_id })
                this.setState({
                    lettersInfo: data[0]
                })
            }, 450)
        })
    }

    removeItem = async (index) => {
        let i = this.state.modalCadastrarItens;
        let op = this.state.options;

        i.splice(index, 1)
        op.splice(index, 1)

        this.setValor(i)

        this.setState({modalCadastrarItens: i, options: op});
    }

    // Update venda

    apiCadastrarVenda = async e => {

        const {
            valor,
            modalCadastrarItens
        } = this.state;

        var inputs = [
            [valor, 'valor', 'iconValor', 'lblValor'],
        ]

        if(this.state.passo){
            this.slide(+1)
        } else{
            if(valor){
                this.toggleLoading();

                for(var i = 0; i < inputs.length; i++){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
    
                try {
                    await api_topmotos.put('/vendasR/update/id/' + this.props.match.params.id, {
                        venda_empresa: this.state.empresa,
                        venda_usuario: localStorage.getItem('usuario_id'),
                        venda_valor: valor,
                        status: this.state.status
                    }).then( res => {
                        this.cadastrarItens();
    
                        var item = this.state.itens.filter((it) => {
                            return it.item_id == this.state.modalCadastrarItens[0]
                        })
    
                        this.setState({descricao: this.state.vendaId + ' - Venda - ' + item[0].item_modelo, passo: 1, valorVenda: valor, modeloVenda: item[0].item_modelo + '-' + item[0].placa})
                        this.toggleLoading();
                        this.slide(+1)
                    })
    
        
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendas/create)" })
                    this.log(this.state.error);
                } 
            } else{
                for(var i = 0; i < inputs.length; i++){
                    if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                        var inputId = document.getElementById(inputs[i][1]);
                        var inputIcone = document.getElementById(inputs[i][2]);
                        var inputNome = document.getElementById(inputs[i][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                        
                    } else{
                        var inputId = document.getElementById(inputs[i][1]);
                        var inputIcone = document.getElementById(inputs[i][2]);
                        var inputNome = document.getElementById(inputs[i][3]);
                        inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    }
                }
            }
        }

    }

    apiCadastrarStatus = async e => {
        const {
            status
        } = this.state;

        var inputs = [
            [status, 'status', 'iconStatus', 'lblStatus']
        ]

        if(status){
            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }
    
            try {
                await api_topmotos.put('/vendasR/update/id/' + this.props.match.params.id, {
                    status: status,
                    venda_usuario: localStorage.getItem('usuario_id'),
                    venda_empresa: localStorage.getItem('usuario_empresa')
                }).then( res => {
                    this.props.history.push('/vendas')  
                })
    
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendas/create)" })
                this.log(this.state.error);
            } 
        } else{
            for(var i = 0; i < inputs.length; i++){
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
            }
        }
    }

    cadastrarItens = async () =>{
        const { modalCadastrarItens} = this.state;

        let max = modalCadastrarItens.length;

        for (let i = 0; i < max; i++) {
        
            try {
                await api_topmotos.post('/vendaitens/create', {
                    vendaitens_venda: this.props.match.params.id,
                    vendaitens_item: modalCadastrarItens[i],
                    vendaitens_admissaodata: this.getTimestamp()
                })

                await api_topmotos.put('/itens/update/status/id/'+this.state.modalCadastrarItens[i])

            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendaitens/create)" })
                this.log(this.state.error);
            }


        }
    }

    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    status = (e, input) =>{
        this.setState({statusMov: e})

        if(input == 1){
            var dataPag = document.getElementById('dataPag');
            var pago = document.getElementById('grupo-pago');
    
            if( e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
    
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: null})
                this.setState({valorPago: null})
            }
        } else{
            var dataPag = document.getElementById('dataPag2');
            var pago = document.getElementById('grupo-pago2');

            if(e == 'R' || e == 'P'){
                dataPag.style.display = 'block';
                pago.style.display = 'block';
            } else{
                dataPag.style.display = 'none';
                pago.style.display = 'none';
                this.setState({dataPagamento: '', valorPago:''})
            }
        }

    }

    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valor1: real})
    }

    // Mascára real R$00,00
    mascaraRealPago = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorPago: real})
    }

    // Mascára parcelamento R$00,00
    mascaraParcelamento = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorTParcela: real})
    }

    salvar = async () => {

        await this.repetirLancamento();

        let parcela_id;

        if(this.state.repetirLancamento == 2){
            await api_topmotos.post('/parcelamento/create', {
                admissaodata: this.getTimestamp()
            }).then(res => {
                this.setState({parcela_id: res.data.returnId})
            })
        }

        var inputs = [
            [this.state.dataVencimento, 'vencimento', 'iconVencimento', 'lblVencimento'],
            [this.state.valor1, 'valorM', 'iconValorM', 'lblValorM'],
            [this.state.categoria, 'categoria', 'iconCategoria', 'lblCategoria'],
            [this.state.conta, 'conta', 'iconConta', 'lblConta'],
            [this.state.tipo, 'pagamento', 'iconPagamento', 'lblPagamento']
        ]

        var inputsTransf = [
            [this.state.banco, 'banco', 'iconBanco', 'lblBanco'],
            [this.state.agencia, 'agencia', 'iconAgencia', 'lblAgencia'],
            [this.state.nConta, 'nconta', 'iconNConta', 'lblNConta'],
            [this.state.tipoConta, 'tipoConta', 'iconTipoConta', 'lblTipoConta']
        ]

        let responder = 0;

        let transferencia = false;

        for(let i = 0; i < inputs.length; i++){
            if(inputs[i][0])
                responder++
        }

        if(this.state.tipoPag){
            if(!this.state.agencia || 
                !this.state.banco ||
                !this.state.nConta || 
                !this.state.tipoConta){

                for(var i = 0; i < inputsTransf.length; i++){
                    if(!inputsTransf[i][0] || inputsTransf[i][0] == 'NULL' ){
                        var inputId = document.getElementById(inputsTransf[i][1]);
                        var inputIcone = document.getElementById(inputsTransf[i][2]);
                        var inputNome = document.getElementById(inputsTransf[i][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    } else{
                        var inputId = document.getElementById(inputsTransf[i][1]);
                        var inputIcone = document.getElementById(inputsTransf[i][2]);
                        var inputNome = document.getElementById(inputsTransf[i][3]);
                        inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    }
                }
                transferencia = true
            }
        } else{
            transferencia = false
        }

        if(responder == inputs.length && !transferencia){

            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }

            var real = this.state.valor1.replace('.', '');
            real = real.replace(',', '.');

            var real2 = '';

            var trocaV = false;

            if(this.state.troca){
                if((this.state.valorVenda - real) < 0){
                    this.setState({
                        valorAr: this.state.valorVenda - real,
                        valorMov: this.state.valorVenda - real
                    })

                    real2 = this.state.valorVenda
                    
                    this.ApiAlterarValorTroca(this.state.itemId);
                } else{
                    this.ApiAlterarValorMenorTroca(this.state.itemId, real);
                }
            } else{
                if((this.state.valorVenda - real) < 0){
                    trocaV = true;
                }
            }

            var status;

            if(this.state.troca){
                status = 'R'
            } else if((this.state.valorVenda - real) < 0 && this.state.trocaS){
                status = 'AP'
            } else{
                status = 'AR'
            }

            if(this.state.statusCompra){
                status = this.state.statusCompra
            }

            try {
                this.toggleLoading();

                if(this.state.repetirLancamento){
                    for(let i in this.state.dataRepeticao){
                        await api_topmotos.post('/financeiro/create', {
                            descricao: this.state.trocaS ? trocaV ? this.state.descricaoTroca : this.state.descricao : this.state.descricao,
                            dataVencimento: this.state.dataRepeticao[i],
                            valor: this.state.repetirLancamento == 2 ? this.state.valorParcela : real,
                            tipoPagamento: this.state.tipo,
                            status: status,
                            admissaodata: this.getTimestamp(),
                            cat_id: this.state.categoria,
                            conta_id: this.state.conta,
                            venda_id: this.state.trocaS ? trocaV ? null : this.state.vendaId : this.state.vendaId,
                            compraId: this.state.trocaS ? trocaV ? this.state.compraId : null : null,
                            dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                            banco: this.state.contaB ? this.state.contaB : null,
                            parcela_id: this.state.parcela_id ? this.state.parcela_id : null
                        }).then(res => {
                            // if(this.state.tipoPag && this.state.dataRepeticao.length == (parseInt(i)+1)){
                            //     this.apiCadastrarDadosBanc(res.data.returnId)
                            // }
                            
                            if(this.state.tipoParcelamento && this.state.dataRepeticao.length == (parseInt(i)+1)){
                                this.apiCadastrarTipoParcelamento(res.data.returnId)
                            }
                        });
                    }
                } else{
                    await api_topmotos.post('/financeiro/create', {
                        descricao: this.state.trocaS ? trocaV ? this.state.descricaoTroca : this.state.descricao : this.state.descricao,
                        dataVencimento: this.state.dataVencimento,
                        valor: real2 ? real2 : real,
                        tipoPagamento: this.state.tipo,
                        status: status,
                        admissaodata: this.getTimestamp(),
                        cat_id: this.state.categoria,
                        conta_id: this.state.conta,
                        venda_id: this.state.trocaS ? trocaV ? null : this.state.vendaId : this.state.vendaId,
                        compraId: this.state.trocaS ? trocaV ? this.state.compraId : null : null,
                        dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                        banco: this.state.contaB ? this.state.contaB : null,
                        parcela_id: null
                    }).then(res => {
                        // if(this.state.tipoPag){
                        //     this.apiCadastrarDadosBanc(res.data.returnId)
                        // }
                        if(this.state.tipoParcelamento){
                            this.apiCadastrarTipoParcelamento(res.data.returnId)
                        }
                    });
                }

                await this.atualizarMovimentacao();
                this.setState({troca: false})
                this.setState({
                    // valorVenda: trocaV ? parseFloat(real) + this.state.valorVenda : this.state.valorVenda - real, 
                    descricao: this.state.vendaId + ' - Venda - ' + this.state.modeloVenda})
                await this.atualizarValorRestante();
                this.toggleModalCadastrar();
                this.setState({valor1: ''});
                this.limparState();
                this.toggleLoading();

                if ((this.state.valorVenda >= 0) && this.state.trocaS){
                    this.setState({trocaS: false})
                } 

            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
                this.log(this.state.error);
            }
        } else{
            for(i = 0; i < inputs.length; i++){
                
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
            }
        }
    }

    limparState = () =>{
        this.setState({
            meses: '',
            repetirLancamento: '',
            dataRepeticao: [],
            parcela_id: '',
            banco: '',
            agencia: '',
            nConta: '', 
            tipoConta: '',
            dataCompetencia: '',
            categoria: '',
            conta: '',
            tipo: '',
            dataVencimento: '',
            valor1: ''
        })
    }

    apiCadastrarMovimentacaoAP = async () =>{
        var real = this.state.valorAr * (-1);

        try {
            await api_topmotos.post('/financeiro/create', {
                descricao: this.state.descricaoTroca,
                dataVencimento: this.state.dataVencimento,
                valor: real,
                tipoPagamento: this.state.tipo,
                status: 'AP',
                admissaodata: this.getTimestamp(),
                cat_id: this.state.categoriaTroca,
                conta_id: this.state.conta,
                compraId: this.state.compraId
            })

            this.setState({valorVenda: this.state.valorVenda - (real * (-1)), descricao: this.state.idVenda + ' - Venda - ' + this.state.modeloVenda})
            this.atualizarMovimentacao();
        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeiro/create)" });
            this.log(this.state.error);
        }
    }

    apiCadastrarTroca = async (id) =>{
        try{
            await api_topmotos.post('/troca/create', {
                item: this.state.itemId,
                venda: id
            })
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/troca/create)" });
            this.log(this.state.error);
        }
    }

    ApiAlterarValorTroca = async (id) =>{
        let res = await api_topmotos.get('/financeiro/compra/id/' + id);

        this.setState({
            descricaoTroca: res.data[0].descricao, 
            categoriaTroca: res.data[0].categorias_id, 
            compraId: res.data[0].compra_id
        })

        try{
            for(let i in res.data){
                await api_topmotos.put('/financeiro/compra/update/id/' + res.data[i].id,{
                    valor: (this.state.valor / res.data.length).toFixed(2)
                })
            }

            await this.apiCadastrarTroca(this.state.vendaId);
            await this.atualizarMovimentacao();
            await this.atualizarValorRestante();
        } catch(err){
        }
    }

    ApiAlterarValorMenorTroca = async (id, valor) =>{
        let res = await api_topmotos.get('/financeiro/compra/id/' + id);
        this.setState({compraId: res.data[0].compra_id})
        
        var real = this.state.valor1.replace('.', '')
        real = real.replace(',', '.');
        
        try{
            for(let i in res.data){
                await api_topmotos.put('/financeiro/compra/update/menor/id/' + res.data[i].id,{
                    valor: (real / res.data.length).toFixed(2)
                })
            }

            await this.apiCadastrarTroca(this.state.vendaId);
            await this.atualizarMovimentacao();
        } catch(err){
        }
    }

    atualizarMovimentacao = async () =>{
        await this.listarMovimentacao();

        var mov = await this.state.movimentacoes.filter((mov) => {
            return mov.venda_id == this.state.vendaId || mov.compra_id == this.state.compraId && this.state.compraId != null
        })

        this.setState({movimentacoesTabela: mov})
    }

    atualizarValorRestante = () =>{
        let soma = 0, somaCompra = 0, somaPaga = 0,  somaRecebida = 0; 

        for(let i of this.state.movimentacoesTabela){
            if(i.compra_id){
                somaCompra += i.valor
                if(i.conta_status == 'P'){
                    somaPaga += i.valor;
                }
            } else{
                soma += i.valor
                if(i.conta_status == 'R'){
                    somaRecebida += i.valor;
                }
            }
        }

        if (this.state.valorTroca) {
            (somaCompra - this.state.valorTroca == 0) ? this.setState({trocaS: false}) : this.setState({trocaS: true}) 
        } 

        if(Math.round(somaPaga - somaRecebida) == 0) {
            this.setState({statusCompra: '', movC: true}) 
        } else{
            this.setState({movC: false}) 
        }

        somaCompra =  somaCompra - soma;

        this.setState({valorVenda: this.state.valorMov < 0 ? this.state.valorMov + somaCompra : this.state.valorMov - soma})
    }

    corStatus = (status) =>{

        var status_repla = status.toLowerCase(); 

        return (
            <div className="d-flex w-100">
                <div class={"tag-status tag-" + status_repla}>
                </div>
            </div>
        )
        
    }

    openPdf = () =>{
        window.open('/pdf/'+this.state.vendaId+'/6', '_blank')
        window.open('/pdfImpressao/'+this.state.vendaId+'/8', '_blank')
        this.props.history.push('/vendas')
    }

    trocaMovel = async (id) =>{
        let res = await api_topmotos.get('/itens/id/' + id);
        let res2 = await api_topmotos.get('/compras/itens/' + id);

        let valor = parseFloat(res2.data[0].valor_compra).toLocaleString('pt-BR');

        this.setState({
            descricao: this.state.vendaId + ' - Venda - ' + this.state.modeloVenda + ` (Troca por: ${res.data.item[0].item_modelo} ${res.data.item[0].placa} )`, 
            valor1: valor,
            valorTroca: res2.data[0].valor_compra,
            itemTroca: id
        })
    }

    verificarTipo = (e) =>{
        if(e){
            var tipo = this.state.tipoPagamento.filter(y => y.id == e)
            var verificarI = tipo[0].tipo_pagamento.toUpperCase() == 'TROCA' ? true : false
            
            this.listarItens();
            
            this.setState({tipo: e, troca: verificarI ? true : false, trocaS: this.state.trocaS ? true : verificarI ? true : false});

            if(tipo[0].parcelamento == 1){
                this.setState({
                    displayTParcela: 'block',
                    tipoParcelamento: true,
                    tipoPag: false,
                    display: 'none',
                })
            } else{
                this.setState({
                    displayTParcela: 'none',
                    tipoParcelamento: false,
                    tParcela: '',
                    valorTParcela: '',
                }) 
            }
        } else{
            this.setState({
                tipo: e,
                troca: false,
                tParcela: '',
                valorTParcela: ''
            });
        }
    }

    // apiCadastrarDadosBanc = async (id) =>{
    //     try{
    //         await api_topmotos.post('/dadosBanc/create', {
    //             banco: this.state.banco,
    //             agencia: this.state.agencia,
    //             conta: this.state.nConta,
    //             tipo: this.state.tipoConta,
    //             mov: id
    //         })
    //     } catch (err) {
    //         this.setState({ error: "Ocorreu um erro ao conectar com a API! (/dadosBanc/create)" });
    //         this.log(this.state.error);
    //     }
    // }

    // verificarTransferencia = (e) =>{
    //     let transferencia = this.state.tipoPagamento.filter(y => y.id == e)
    //     if(transferencia[0].dados_bancarios == 1){
    //         this.setState({
    //             display: 'block',
    //             tipoPag: true
    //         })

    //     } else{
    //         this.setState({
    //             tipoPag: false,
    //             display: 'none',
    //             nConta: '',
    //             banco: '',
    //             agencia: '',
    //             tipoConta: ''
    //         }) 
    //     }
    // }

    apiCadastrarTipoParcelamento = async (id) =>{
        try{
            var real = this.state.valorTParcela.replace('.', '');
            real = real.replace(',', '.');

            await api_topmotos.post('/tParcelamento/create', {
                valor: real,
                parcelas: this.state.tParcela,
                mov: id
            })

            this.setState({displayTParcela: 'none'})
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/tParcelamento/create)" });
            this.log(this.state.error);
        }
    }

    repetirLancamento = async () =>{
        var date = new Date();

        var mes;
        var meses = [];
        var anos = 0;
        var dia = moment(this.state.dataVencimento).format('DD');
        var ano = parseInt(moment(this.state.dataVencimento).format('YYYY'));

        for (let i = 0; i < this.state.meses; i++){
            mes = parseInt(moment(this.state.dataVencimento).format('MM')) + i;

            if(mes%12 == 0){
                anos++;
                mes = `${ano + anos - 1}-12`
            } else{
                if(mes > 12){
                    mes = mes - (12 * anos)
                    mes = `${ano + anos}-${mes}`
                } else{
                    mes = `${ano + anos}-${mes}`
                }
            }

            meses.push(mes + '-' + dia)
    
        }

        this.setState({dataRepeticao: meses})

    }

    valorParcela = async (meses) =>{
        const {valor1, repetirLancamento} = this.state

        if(repetirLancamento == 2){
            if(meses && valor1){
                var real = valor1.replace('.', '');
                real = real.replace(',', '.');
    
                this.setState({valorParcela: real / meses})
            }
        } else{
            this.setState({valorParcela: ''})
        }
    }

    ano = (ano, fab) => {
        
        ano = ano == 3200 ? '0km' : ano.toString().slice(2, 4) ;
        fab = fab ? fab.toString().slice(2, 4) : '';
        
        let anos = fab + "/" + ano;
        return(anos)
    }
    
    atualizarMovimentacaoS = async (compra) =>{
        await this.listarMovimentacao();

        var mov = await this.state.movimentacoes.filter((mov) => {
            return mov.compra_id == this.state.compraId || mov.venda_id == this.state.vendaId
        })

        this.setState({movimentacoesTabela: mov})
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    OpenModalDeletar = async (id, compra, venda) => {
		let res = await api_topmotos.get('/financeiro/id/' + id);
        res = res.data[0];

		this.setState({
            modalDeletar: true,
            modalDeletarId: res.id,
            mov: compra ? compra : '',
            // descricao: res.conta_status == 'R' ? res.descricao : this.state.descricao,
            statusCompra: res.conta_status == 'R' || res.conta_status == 'P' ? res.conta_status : '',
            trocaS: compra ? true : false,
            descricaoTroca: compra ?  `${compra} - Compra - ${this.state.modeloTroca}` : this.state.descricaoTroca,
            compraId: compra ? compra : this.state.compraId
		});
    }
    
    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.delete('/financeiro/delete/id/' + this.state.modalDeletarId);
            this.setState({valorVenda: this.state.valorVenda < 0 ? this.state.mov ? this.state.valorVenda - this.state.valorDeletado : this.state.valorVenda + this.state.valorDeletado : this.state.mov ? this.state.valorVenda - this.state.valorDeletado : this.state.valorVenda + this.state.valorDeletado})
            this.atualizarMovimentacaoS(this.state.mov);
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeito/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Contas deletado com sucesso!' });
    }

    salvarObs = async () =>{
        if(this.state.obsPag){
            await api_topmotos.put('/venda/obs/' + this.state.vendaId, {
                obs: this.state.obsPag
            });
        } 

        if(this.state.valorVenda == 0 || this.state.permitir){
            await this.openPdf();
        } else{
            api_topmotos.put('/venda/pendencia/' + this.state.vendaId, {status: 1})

            let max = this.state.modalCadastrarItens.length;
            for (let i = 0; i < max; i++) {
                try {
                    await api_topmotos.put('/itens/status/update/'+this.state.modalCadastrarItens[i], {status: 2})
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/vendaitens/create)" })
                    this.log(this.state.error);
                }
            }
            
            this.props.history.push('/vendas')
        }
    }

    apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
        let venda = res.data.filter(a => a.restricao_id == 4);

        this.setState({permitir: venda.length > 0 ? true : false})
    }

    toggleLoading = () =>{
        this.setState({ displayLoading: !this.state.displayLoading });
    }

    openTermoVenda = () =>{
        this.listarItens();
        this.togglemodal();
    }

    render() {
        return (
            <>
                <div className="overlay" style={{display: this.state.displayLoading ? 'flex' : 'none'}}>
                    <div className="modal__loading">
                        <img src={require("../../imgs/loading.gif")} alt=""/>
                        <p>Loading</p>
                    </div>
                </div>

                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />

                            <div className="questionario">
                                <p className="head">{this.state.titles[this.state.indexperg]}</p>
                                <div className="perguntas" style={{display:"flex"}}>
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cpf">CPF Cliente</label>
                                                    <div className="grupo-campo-input input-cad disabled" id="cpf">
                                                        <i class="fas fa-id-card"></i>
                                                        <InputMask
                                                            maxLength="18"
                                                            type="text"
                                                            placeholder="&nbsp;Informe o CPF ou CNPJ"
                                                            autoComplete="on"
                                                            value={this.state.cpf}
                                                            disabled
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pessoa d-block">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="nome">Nome *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="nome">
                                                            <i className="fas fa-user" id="iconNome"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Nome"
                                                                autoComplete="on"
                                                                value={this.state.nome}
                                                                onChange={event => this.setState({ nome: event.target.value })}
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="email">E-mail</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="email">
                                                            <i className="far fa-envelope" id="iconEmail"></i>
                                                            <input
                                                                type="email"
                                                                placeholder="&nbsp;Informe o E-mail"
                                                                autoComplete="on"
                                                                value={this.state.email}
                                                                disabled
                                                                onChange={event => this.setState({ email: event.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="celular">Celular *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="celular">
                                                            <i className="fas fa-phone" id="iconCelular"></i>
                                                            <InputMask
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Telefone"
                                                                autoComplete="on"
                                                                value={this.state.telefone}
                                                                disabled
                                                                onChange={event => this.setState({ telefone: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="telefone">Telefone </label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="telefone">
                                                            <i className="fas fa-phone" id="iconTelefone"></i>
                                                            <InputMask
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Telefone"
                                                                autoComplete="on"
                                                                value={this.state.celular}
                                                                disabled
                                                                onChange={event => this.setState({ celular: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="rg">Rg </label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="rg">
                                                            <i className="fas fa-id-card" id="iconRg"></i>
                                                            <InputMask
                                                                type="text"
                                                                placeholder="&nbsp;Informe o RG"
                                                                autoComplete="on"
                                                                value={this.state.rg}
                                                                disabled
                                                                onChange={event => this.setState({ rg: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cep">CEP *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="cep">
                                                            <i className="fas fa-envelope" id="iconCep"></i>
                                                            <InputMask
                                                                mask="99999-999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                                autoComplete="on"
                                                                value={this.state.cep}
                                                                disabled
                                                                onChange={event => this.getCEP(event)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>       
                                                    
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="estado">Estado *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="estado">
                                                            <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                            <select
                                                                name="estado"
                                                                value={this.state.estado}
                                                                placeholder="&nbsp;Selecione o Estado de residência"
                                                                autoComplete="on"
                                                                required
                                                                disabled
                                                            >
                                                                <option value="NULL">Selecione o Estado de residência</option>
                                                                <option value='AC'>Acre</option>
                                                                <option value='AL'>Alagoas</option>
                                                                <option value='AP'>Amapá</option>
                                                                <option value='AM'>Amazonas</option>
                                                                <option value='BA'>Bahia</option>
                                                                <option value='CE'>Ceará</option>
                                                                <option value='DF'>Distrito Federal</option>
                                                                <option value='ES'>Espírito Santo</option>
                                                                <option value='GO'>Goiás</option>
                                                                <option value='MA'>Maranhão</option>
                                                                <option value='MT'>Mato Grosso</option>
                                                                <option value='MS'>Mato Grosso do Sul</option>
                                                                <option value='MG'>Minas Gerais</option>
                                                                <option value='PA'>Pará</option>
                                                                <option value='PB'>Paraíba</option>
                                                                <option value='PR'>Paraná</option>
                                                                <option value='PE'>Pernambuco</option>
                                                                <option value='PI'>Piauí</option>
                                                                <option value='RJ'>Rio de Janeiro</option>
                                                                <option value='RN'>Rio Grande do Norte</option>
                                                                <option value='RS'>Rio Grande do Sul</option>
                                                                <option value='RO'>Rondônia</option>
                                                                <option value='RR'>Roraima</option>
                                                                <option value='SC'>Santa Catarina</option>
                                                                <option value='SP'>São Paulo</option>
                                                                <option value='SE'>Sergipe</option>
                                                                <option value='TO'>Tocantins</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cidade">Cidade *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="cidade">
                                                            <i className="fas fa-city" id="iconCidade"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.cidade}
                                                                placeholder="&nbsp;Informe a Cidade de residência"
                                                                autoComplete="on"
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>    
                                            </div>       

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="logradouro">Logradouro *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="logradouro">
                                                            <i className="fas fa-road" id="iconLogradouro"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.logradouro}
                                                                placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                                autoComplete="on"
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="numero">Número *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="numero">
                                                            <i className="fas fa-home" id="iconNumero"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Número de Residência"
                                                                autoComplete="on"
                                                                required
                                                                value={this.state.numero}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="bairro">Bairro *</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="bairro">
                                                            <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.bairro}
                                                                placeholder="&nbsp;Informe o Bairro"
                                                                autoComplete="on"
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="complemento">Complemento</label>
                                                        <div className={"grupo-campo-input input-cad disabled"} id="complemento">
                                                            <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.complemento}
                                                                placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                                autoComplete="on"
                                                                disabled
                                                                
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <div></div>
                                        <button onClick={() => this.slide(+1)}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="row justify-content-center py-4">
                                            <div className="col-md-5">
                                                <div className="card-venda">
                                                    <div className="titulo">
                                                        Mudar Status
                                                    </div>
                                                    <img src={veiculosImg} alt=""/>
                                                    <p className="texto">Selecione o status da venda</p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="grupo-input input-style-cad">
                                                                <div className="grupo-campo-input input-cad" id="status">
                                                                    <i className="fas fa-sign" id="iconStatus"></i>
                                                                    <select  value={this.state.status} onChange={event => {this.setState({status: event.target.value})}}>
                                                                        <option value="">Selecione status</option>
                                                                        {
                                                                            this.state.statusVenda.map((e, index) => (
                                                                                <option value={e.id}>{e.descricao}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-prox-volt justify-content-center">
                                                        <button onClick={() => {this.apiCadastrarStatus()
                                                        }}>Enviar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="card-venda">
                                                    <div className="titulo">
                                                        Termo de venda
                                                    </div>
                                                    <img src={termoImg} alt=""/>
                                                    <p className="texto">Finalize sua venda</p>
                                                    <div className="btn-prox-volt justify-content-center py-4">
                                                        <button onClick={() => {this.slide(+1)}}>Termo de Venda</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="valor">Itens </label>
                                            
                                            <Button onClick={this.openTermoVenda} className="btnora">Adicionar</Button>
                                            
                                            <div className="cardsItens">
                                                {
                                                    this.state.options.map((e, index) => (
                                                        <div className="itensx">
                                                            <div className="cropper">
                                                                <div className="killmoto" onClick={a => this.removeItem(index)}><i className="fas fa-times"></i></div>
                                                                <img src={e.itemfotos_foto} alt=""/>
                                                            </div>
                                                            <p>
                                                                {e.item_modelo}
                                                            </p>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="valor" id="lblValor">Valor </label>
                                                    <div className="grupo-campo-input input-cad" id="valor">
                                                        <i className="fas fa-tag" id="iconValor"></i>
                                                        <input
                                                            type="text"
                                                            value={this.state.valor ? parseFloat(this.state.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }): ''}
                                                            placeholder="&nbsp;Informe o Valor da venda"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ valor: event.target.value, valorMov: event.target.value})}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => {
                                            this.apiCadastrarVenda(); 
                                        }}>Proximo <i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="d-flex justify-content-between my-2">
                                            <div className="d-flex align-center line-separa">
                                                <div className="indicadores m-3 d-flex">
                                                    <i class="fas fa-dollar-sign" id="receber"></i>
                                                    <div>
                                                        <p>Total</p>
                                                        <p>{parseFloat(this.state.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                <div className="indicadores m-3 d-flex">
                                                    <i class="fas fa-dollar-sign" id="recebido"></i>
                                                    <div>
                                                        <p>Restante</p>
                                                        <p>{parseFloat(this.state.valorVenda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <button className="btn-add p-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar movimentação<i class="fas fa-plus"></i></button>
                                        </div>
                                        <div className="table mt-3">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Operação
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Descrição
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Data de Vencimento
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Valor
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Ações
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.movimentacoesTabela.map((mov) => (
                                                            <tr key={mov.id}>
                                                                <td>{this.corStatus(mov.conta_status)}</td>
                                                                <td>{mov.descricao}</td>
                                                                <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                                <td>{mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                <td className="td-acoes">
                                                                    <i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(mov.id, mov.compra_id, mov.venda_id); this.setState({valorDeletado: mov.valor})}}></i>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="grupo-input input-style-cad mt-4">
                                            <label htmlFor="obsPag" id="lblObsPag">Observação Forma de Pagamento</label>
                                            <textarea value={this.state.obsPag} onChange={a => this.setState({obsPag: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => {this.salvarObs()}}>Salvar</button>
                                    </div>
                                </div>                         
                           </div>
                        
                            <Versionamento/>
                        </div>
                    </div>
                </section>

                {/* Modal de Cadastro */}
                <Modal
					isOpen={this.state.modalCadastrar}
					toggle={this.toggleModalCadastrar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
					<ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Conta</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblValorM">Valor *</label>
                                            <div className="grupo-campo-input input-cad" id="valorM">
                                            <i class="fas fa-dollar-sign" id="iconValorM"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valor1}
                                                    onChange={event => this.mascaraReal(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad d-flex justify-content-start">
                                            <label htmlFor="" id="lblRepetirLancamento">Repetir Lançamento</label>
                                            <div className="obrigatorio">
                                                <input type="radio" name="repetirLancamento" value="2" id="parcela" onChange={event => {this.setState({repetirLancamento: event.target.value, meses: '', valorParcela: ''})}}/>
                                                <label htmlFor="parcela">Parcelamento</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{display: this.state.repetirLancamento ? 'block' : 'none'}}>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Quantos meses? *</label>
                                            <div className="grupo-campo-input input-cad">
                                                <input
                                                    type="number"
                                                    autoComplete="on"
                                                    value={this.state.meses}
                                                    onChange={event => {this.setState({ meses: event.target.value }); this.valorParcela(event.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p id="valor-mensal" style={{display: this.state.valorParcela ? 'block' : 'none'}}>
                                            <i class="fas fa-info-circle"></i>
                                             {this.state.meses} vezes de {(this.state.valorParcela).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblPagamento">Tipo de pagamento *</label>
                                            <div className="grupo-campo-input input-cad" id="pagamento">
                                                <i class="fas fa-dollar-sign" id="iconPagamento"></i>
                                                <select onChange={event => {this.verificarTipo(event.target.value);}}>
                                                    {/* this.verificarTransferencia(event.target.value) */}
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.tipoPagamento.map((tipo) => (
                                                        <option value={tipo.id}>{tipo.tipo_pagamento}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblConta">Centro de Custos *</label>
                                            <div className="grupo-campo-input input-cad" id="conta">
                                                <i class="fas fa-dollar-sign" id="iconConta"></i>
                                                <select onChange={event => this.setState({ conta: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contas.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblVencimento">Data de vencimento *</label>
                                            <div className="grupo-campo-input input-cad" id="vencimento">
                                                <i class="far fa-calendar-alt" id="iconVencimento"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataVencimento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblCategoria">Plano de Contas *</label>
                                            <div className="grupo-campo-input input-cad" id="categoria">
                                                <i class="fas fa-dollar-sign" id="iconCategoria"></i>
                                                <select onChange={event => this.setState({ categoria: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.categorias.map((cat) => (
                                                        <>
                                                            <option className="select__group" value={cat.id}>{cat.categoria}</option>
                                                            {cat.options.map((op) => (
                                                                <option value={op.id}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {op.categoria}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Conta Bancária</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select onChange={event => this.setState({ contaB: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contasBancarias.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Data de competência</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataCompetencia: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{display: this.state.troca ? 'block' : 'none'}}>
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cliente">Modelo </label>
                                            <div className="grupo-campo-input input-cad" id="cliente">
                                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                                <input type="text"
                                                    value={this.state.modeloTroca}
                                                    onChange={this.rebuildModalTroca}
                                                    onFocus={a => this.setState({ clientFocus: true })}
                                                    onBlur={a => this.setState({ clientFocus: false })}
                                                    placeholder="Insira o modelo"
                                                />
                                            </div>
                                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                {
                                                    this.state.itens.filter(y => y.compra == 1 && y.item_modelo != this.state.modeleSE && y.empresa_id == localStorage.getItem('usuario_empresa') && (y.item_modelo.toLowerCase().includes(this.state.modeloTroca.toLowerCase()) || y.placa.toLowerCase().includes(this.state.modeloTroca.toLowerCase())) && !this.state.modalCadastrarItens.includes(y.item_id)).map(e => (
                                                        <li onClick={a => {this.setState({ modeloTroca: e.item_modelo, itemId: e.item_id}); this.trocaMovel(e.item_id)}}>
                                                            {e.item_modelo +  " - " + e.placa}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            
                                <div style={{display: this.state.displayTParcela}}>
                                    <p class="subtitle-form">Parcelamento</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTParcela">Quantas parcelas? *</label>
                                                <div className="grupo-campo-input input-cad" id="tParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconTParcela"></i>
                                                    <input 
                                                        onChange={event => this.setState({tParcela: event.target.value})} 
                                                        placeholder="&nbsp;Informe a quantidade de parcelas" 
                                                        min="0"
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblValorTParcela">Valor da Parcela*</label>
                                                <div className="grupo-campo-input input-cad" id="valorTParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconValorTParcela"></i>
                                                    <input 
                                                        onChange={event => this.mascaraParcelamento(event.target.value)} 
                                                        value={this.state.valorTParcela}
                                                        // placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* <div style={{display: this.state.display}}>
                                    <p class="subtitle-form">Dados bancários</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblBanco">Banco *</label>
                                                <div className="grupo-campo-input input-cad" id="banco" >
                                                    <i class="fas fa-dollar-sign" id="iconBanco"></i>
                                                    <input 
                                                        onChange={event => this.setState({banco: event.target.value})} 
                                                        placeholder="&nbsp;Informe o Banco" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblAgencia">Agência *</label>
                                                <div className="grupo-campo-input input-cad" id="agencia" >
                                                    <i class="fas fa-dollar-sign" id="iconAgencia"></i>
                                                    <input 
                                                        onChange={event => this.setState({agencia: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblNConta">Conta *</label>
                                                <div className="grupo-campo-input input-cad" id="nconta" >
                                                    <i class="fas fa-dollar-sign" id="iconNConta"></i>
                                                    <input 
                                                        onChange={event => this.setState({nConta: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Conta" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTipoConta">Tipo de conta *</label>
                                                <div className="grupo-campo-input input-cad" id="tipoConta" >
                                                    <i class="fas fa-dollar-sign" id="iconTipoConta"></i>
                                                    <select onChange={ e => this.setState({tipoConta: e.target.value})}>
                                                        <option value="">Selecione o tipo da conta</option>
                                                        <option value="Conta Corrente">Conta Corrente</option>
                                                        <option value="Conta Poupança">Conta Poupança</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
						</ModalBody>
						<ModalFooter>
						<Button onClick={this.toggleModalCadastrar.bind(this)}>Fechar</Button>
                        <Button className="bg-laranja" onClick={this.salvar}>Salvar</Button>
					</ModalFooter>
				</Modal>

                <Modal isOpen={this.state.openmodal} toggle={this.togglemodal} backdrop={true} keyboard={true} size="md">
                    <ModalHeader toggle={this.togglemodal}>
                        Selecionar itens
                    </ModalHeader>
                    <ModalBody>
                        <div className="grupo-input input-style-cad" id="">
                            <label htmlFor="empresa" id="lblEmpresa">Empresa *</label>
                            <div className="grupo-campo-input input-cad" id="empresa">
                                <i className="fas fa-building" id="iconEmpresa"></i>
                                <select name="" value={this.state.empresa} onChange={e => this.setState({ empresa: e.target.value})}>
                                    <option value="NULL">Selecione uma empresa</option>
                                    {
                                        this.state.empresas.map(e => (
                                            <option value={e.empresa_id}>{e.empresa_nomefantasia}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="grupo-input input-style-cad">
                            <label htmlFor="cliente">Modelo </label>
                            <div className="grupo-campo-input input-cad" id="cliente">
                                <i className="fas fa-user-tag" id="iconCliente"></i>
                                <input type="text"
                                    value={this.state.modeleSE}
                                    onChange={this.rebuildModal}
                                    onFocus={a => this.setState({ clientFocus: true })}
                                    onBlur={a => this.setState({ clientFocus: false })}
                                    placeholder="Insira o modelo"
                                />
                            </div>
                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                {
                                    this.state.itens.filter(y => y.compra == 1 && y.empresa_id == this.state.empresa && (y.item_modelo.toLowerCase().includes(this.state.modeleSE.toLowerCase()) || y.placa.toLowerCase().includes(this.state.modeleSE.toLowerCase())) && !this.state.modalCadastrarItens.includes(y.item_id)).map(e => (
                                        <li onClick={a => {this.setState({ modeleSE: e.item_modelo }); this.reviewModal(e.item_id)}}>
                                            {e.item_modelo + ' - ' + e.placa}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            this.state.lettersInfo ? (
                                <div className="infamus">
                                    <div className="imgcropper">
                                        <div className="img-veiculo" style={{backgroundImage: `url("${this.state.lettersInfo.itemfotos_foto ? this.state.lettersInfo.itemfotos_foto : require('../../imgs/sFoto.png')}")`}}></div>
                                        {/* <img src={this.state.lettersInfo.itemfotos_foto} alt="" /> */}
                                    </div>
                                    <br />
                                    <div className="gridinfoview">
                                        <span className="orange lgm bol">
                                            {this.state.lettersInfo.item_modelo}
                                        </span>
                                        <span className=" lgm gr bol">
                                            {parseFloat(this.state.lettersInfo.item_preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                        <span className="tl lgm gr bol">
                                            {this.ano(this.state.lettersInfo.ano_mod, this.state.lettersInfo.item_ano_fab)}
                                        </span>
                                        <span className=" gr lgm bol">
                                            {this.state.lettersInfo.combustivel}
                                        </span>
                                        <span className="tl lgm gr bol">
                                            {this.state.lettersInfo.placa}
                                        </span>
                                        <span className=" gr lgm bol">
                                            {this.state.lettersInfo.cor}
                                        </span>
                                        <span className="alb lgm">
                                            {this.state.lettersInfo.item_descricao}
                                        </span>
                                    </div>
                                </div>
                            ) : (null)
                        }
                    </ModalBody>
                    {
                        this.state.lettersInfo ? (
                            <ModalFooter>
                                <Button className="btnora" onClick={this.additem}>Adicionar</Button>
                            </ModalFooter>
                        ) : (null)
                    }
                </Modal>

                {/*Modal Deletar*/}
                <Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                        <ModalBody>
                            Deseja realmente deletar a conta<br></br>(ID: {this.state.modalDeletarId})?
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.deletar} color="danger">Deletar</Button>
                            <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                
                {/* MODAL AVISO */}
                <Modal
                    isOpen={this.state.modalAviso}
                    toggle={this.toggleModalAviso.bind(this)}
                    backdrop={true}
                    keyboard={true}
                    size="sm"
                    fade={false} >
                    <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                    <ModalBody>
                        {this.state.modalAvisoText}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }
}

export default VendasR
