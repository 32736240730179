import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';

import InputMask from 'react-input-mask';

import { Button } from 'reactstrap';

class PessoasCadastrarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalCadastrarOpen: false,
            modalCadastrarNome: '',
            modalCadastrarTelefone: '',
            modalCadastrarEmail: '',
            modalCadastrarCpf: '',
            modalCadastrarRg: '',
            modalCadastrarEnderecoId: '',
            modalCadastrarCep: '',
            modalCadastrarEstado: '',
            modalCadastrarCidade: '',
            modalCadastrarBairro: '',
            modalCadastrarLogradouro: '',
            modalCadastrarNumero: '',
            modalCadastrarComplemento: '',
            lead: null,

            aviso: '',
            cpfValido: false,
            rg: '',

            pessoas: [],
            is_pessoa: false,
            modalCadastrarCelular: ''
        }

        this.verificarPermissao();

        if (this.props.match.params.id) {
            this.apiListarLeads();
        }

    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/pessoas")
            if(res.length == 0){
                this.props.history.push('/dashboardcomercial')
            }
        } else{
            this.props.history.push('/dashboardcomercial')
        }
    }

    componentDidMount(){
        this.apiListarPessoas()
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pessoasUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiListarPessoas = async () => {
        let res = await api_topmotos.get('/pessoas/');
        this.setState({ pessoas: res.data });
    }

    apiListarLeads = async () => {
        let { data } = await api_topmotos.post('/leads/unique/', { id: this.props.match.params.id });

        if (data.lead.length == 0) {
            return
        }

        this.setState({
            modalCadastrarNome: data.lead[0].lead_nome,
            modalCadastrarEmail: data.lead[0].lead_email,
            modalCadastrarTelefone: data.lead[0].lead_telefone,
            lead: data.lead[0].lead_id
        })
    }

    getCEP = async (event, modal) => {
        let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');

        this.setState({ modalCadastrarCep: event.target.value });

        if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstado: res['data'].uf,
                modalCadastrarCidade: res['data'].localidade,
                modalCadastrarBairro: res['data'].bairro,
                modalCadastrarLogradouro: res['data'].logradouro,
            })
        }
    }

    verificarCampo = () =>{
        const {
            modalCadastrarNome,
            modalCadastrarTelefone,
            modalCadastrarCelular,
            modalCadastrarEmail,
            modalCadastrarCpf,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            cpfValido
        } = this.state;

        var inputs = [
            [modalCadastrarNome, 'nome', 'iconNome', 'lblNome'],
            [modalCadastrarCpf, 'cpf', 'iconCpf', 'lblCpf'],
            [cpfValido, 'cpf', 'iconCpf', 'lblCpf'],
			[modalCadastrarTelefone, 'celular', 'iconCelular', 'lblCelular'],
            [modalCadastrarCep, 'cep', 'iconCep', 'lblCep'],
            [modalCadastrarEstado, 'estado', 'iconEstado', 'lblEstado'],
            [modalCadastrarCidade, 'cidade', 'iconCidade', 'lblCidade'],
            [modalCadastrarLogradouro, 'logradouro', 'iconLogradouro', 'lblLogradouro'],
            [modalCadastrarNumero, 'numero', 'iconNumero', 'lblNumero'],
            [modalCadastrarBairro, 'bairro', 'iconBairro', 'lblBairro']
        ]

        for(var i = 0; i < inputs.length; i++){
            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                
            } else{
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }
        }	
    }

    apiCadastrarPessoa = async e => {
        e.preventDefault();

        const {
            modalCadastrarNome,
            modalCadastrarTelefone,
            modalCadastrarCelular,
            modalCadastrarEmail,
            modalCadastrarCpf,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            modalCadastrarComplemento,
            cpfValido,
            rg,
            is_pessoa
        } = this.state;


        if(!is_pessoa){
            this.setState({ error: "Pessoa existentes!" });
            this.setState({ errorMessage: "Pessoa existentes!" });
        } else{
            if (!modalCadastrarNome ||
                !modalCadastrarTelefone ||
                !modalCadastrarCpf ||
                !modalCadastrarCep ||
                !modalCadastrarEstado ||
                !modalCadastrarCidade ||
                !modalCadastrarBairro ||
                !modalCadastrarLogradouro ||
                !modalCadastrarNumero ||
                !cpfValido ||
                modalCadastrarEstado === 'NULL' ||
                modalCadastrarTelefone === '(__) _____-____' ||
                modalCadastrarCpf === '___.___.___-__' ||
                modalCadastrarCep === '_____-___') {
                this.setState({ error: "preencha todos os dados obrigatórios corretamente para se cadastrar!" });
                this.setState({ errorMessage: "Preencha todos os dados obrigatórios corretamente para se cadastrar!" });
                this.log(this.state.error);
    
                this.verificarCampo()
    
            } else {
                this.setState({ errorMessage: '' });
    
                this.verificarCampo()
    
                try {
                    await api_topmotos.post('/enderecos/create', {
                        endereco_cep: modalCadastrarCep,
                        endereco_estado: modalCadastrarEstado,
                        endereco_cidade: modalCadastrarCidade,
                        endereco_bairro: modalCadastrarBairro,
                        endereco_logradouro: modalCadastrarLogradouro,
                        endereco_numero: modalCadastrarNumero,
                        endereco_complemento: modalCadastrarComplemento,
                        endereco_admissaodata: this.getTimestamp(),
    
                    }).then(res => {
                        this.setState({ modalCadastrarEnderecoId: res.data.returnId });
                    });
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                    this.log(this.state.error);
                }
    
                try {
                    await api_topmotos.post('/pessoas/create', {
                        pessoa_nome: modalCadastrarNome,
                        pessoa_telefone: modalCadastrarTelefone,
                        pessoa_celular: modalCadastrarCelular,
                        pessoa_email: modalCadastrarEmail,
                        pessoa_cpf: modalCadastrarCpf,
                        pessoa_endereco: this.state.modalCadastrarEnderecoId,
                        pessoa_admissaodata: this.getTimestamp(),
                        lead: this.state.lead,
                        pessoa_rg: rg
                    })
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/pessoas/create)" });
                    this.log(this.state.error);
                }
    
                this.props.history.push("/pessoas")
            }
        }
    }

    maskCnpjCpf = (e) => {
        if(e.length <= 14){
            e = e.replace(/\D/g,"")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        } else{
            e = e.replace(/\D/g,"")
            e = e.replace(/^(\d{2})(\d)/,"$1.$2")
            e = e.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            e = e.replace(/\.(\d{3})(\d)/,".$1/$2")
            e = e.replace(/(\d{4})(\d)/,"$1-$2")
        }

        this.setState({modalCadastrarCpf: e})
    }

    validarCPF = async (e) =>{
        var cpf = e.target.value;
        var cpfC = e.target.value;
        var aviso = document.getElementById('aviso')

        if(cpf.length == 14){
            if (typeof cpf !== "string"){
                await this.setState({aviso: 'CPF inválido', cpfValido: false})
                aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
            } else{
                cpf = cpf.replace(/[\s.-]*/igm, '')

                if (!cpf ||
                    cpf.length != 11 ||
                    cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999" 
                ) {
                    await this.setState({aviso: 'CPF inválido', cpfValido: false})
                    aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                } else{
                    var soma = 0
                    var resto

                    for (var i = 1; i <= 9; i++) 
                        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        
                    resto = (soma * 10) % 11

                    if ((resto == 10) || (resto == 11))  resto = 0

                    if (resto != parseInt(cpf.substring(9, 10)) ){
                        await this.setState({aviso: 'CPF inválido', cpfValido: false})
                        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                        
                    } else{
                        soma = 0

                        for (var i = 1; i <= 10; i++) 
                            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
                        resto = (soma * 10) % 11

                        if ((resto == 10) || (resto == 11))  resto = 0

                        if (resto != parseInt(cpf.substring(10, 11) ) ){
                            await this.setState({aviso: 'CPF inválido', cpfValido: false})
                            aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                        } else{
                            await this.setState({aviso: 'CPF válido', cpfValido: true});
                            aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
                            this.checkPessoa(cpfC);
                        }
                    }
                }
            }
        } else if(cpf.length == 18){
            let cnpj = cpf.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                await this.setState({aviso: 'CNPJ inválido', cpfValido: false})
                        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;"
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    await this.setState({aviso: 'CNPJ inválido', cpfValido: false})
                        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        await this.setState({aviso: 'CNPJ inválido', cpfValido: false})
                        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
                    } else{
                        await this.setState({aviso: 'CNPJ válido', cpfValido: true});
                        aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
                        this.checkPessoa(cpfC);
                    }
                }
            }
        } else{
            this.checkPessoa(e)
            this.setState({aviso: '', cpfValido: false})
        }
    }

    checkPessoa = (e) => {

        if (e && (e.length == 14 || e.length == 18)) {

            var pessoa = this.state.pessoas.filter((pes) => {
                return pes.pessoa_cpf_cnpj == e
            })

            if(pessoa.length > 0){
                
                this.setState({
                    aviso: 'Pessoa já existente!',
                    cpfValido: false,
                    is_pessoa: false,
                    rg: pessoa[0].pessoa_rg,
                    modalCadastrarNome: pessoa[0].pessoa_nome,
                    modalCadastrarEmail: pessoa[0].pessoa_email,
                    modalCadastrarTelefone: pessoa[0].pessoa_telefone,
                    modalCadastrarCelular: pessoa[0].pessoa_celular,
                    modalCadastrarCep: pessoa[0].endereco_cep,
                    modalCadastrarEstado: pessoa[0].endereco_estado,
                    modalCadastrarCidade: pessoa[0].endereco_cidade,
                    modalCadastrarBairro: pessoa[0].endereco_bairro,
                    modalCadastrarLogradouro: pessoa[0].endereco_logradouro,
                    modalCadastrarNumero: pessoa[0].endereco_numero,
                    modalCadastrarComplemento: pessoa[0].endereco_complemento,
                })

                var aviso = document.getElementById('aviso')
                aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
            } else{
                this.setState({
                    is_pessoa: true,
                    modalCadastrarNome: '',
                    modalCadastrarEmail: '',
                    modalCadastrarTelefone: '',
                    modalCadastrarCep: '',
                    modalCadastrarEstado: '',
                    modalCadastrarCidade: '',
                    modalCadastrarBairro: '',
                    modalCadastrarLogradouro: '',
                    modalCadastrarNumero: '',
                    modalCadastrarComplemento: '',
                    rg: ''
                })
            }
        } else{
            if(this.state.is_pessoa){
                this.setState({
                    aviso: '',
                    cpfValido: false,
                    pessoaId: null, 
                    is_pessoa: false,
                    modalCadastrarNome: '',
                    modalCadastrarEmail: '',
                    modalCadastrarTelefone: '',
                    modalCadastrarCelular: '',
                    modalCadastrarCep: '',
                    modalCadastrarEstado: '',
                    modalCadastrarCidade: '',
                    modalCadastrarBairro: '',
                    modalCadastrarLogradouro: '',
                    modalCadastrarNumero: '',
                    modalCadastrarComplemento: '',
                    rg: ''
                })
            }
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>
                                    {
                                        this.state.lead ? (
                                            "Converter lead"
                                        ) : ("Inserir Pessoa")
                                    }
                                </strong></p>

                            </div>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cpf"  id="lblCpf">CPF/CNPJ *</label>
                                            <div className="d-flex">
                                                <div className="grupo-campo-input input-cad" id="cpf">
                                                    <i className="fas fa-id-card" id="iconCpf"></i>
                                                    <InputMask
                                                        maxLength="18"
                                                        type="text"
                                                        placeholder="&nbsp;Informe o CPF ou CNPJ"
                                                        autoComplete="on"
                                                        value={this.state.modalCadastrarCpf}
                                                        onChange={a => {this.maskCnpjCpf(a.target.value); this.validarCPF(a)}}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <p className="valorFipe pt-2" id="aviso">{this.state.aviso}</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <p className="subtitle-form">Informações pessoais</p>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nome" id="lblNome">Nome *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="nome">
                                                <i className="fas fa-user" id="iconNome"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Nome"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarNome}
                                                    onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                    disabled={!this.state.is_pessoa}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="email" id="lblEmail">E-mail</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="email">
                                                <i className="far fa-envelope" id="iconEmail"></i>
                                                <input
                                                    type="email"
                                                    placeholder="&nbsp;Informe o E-mail"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarEmail}
                                                    onChange={event => this.setState({ modalCadastrarEmail: event.target.value })}
                                                    disabled={!this.state.is_pessoa}
                                                />
                                            </div>
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="celular" id="lblCelular">Celular *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="celular">
                                                <i className="fas fa-phone" id="iconCelular"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="&nbsp;Informe o Telefone"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarTelefone}
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarTelefone: event.target.value})}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="telefone" id="lblTelefone">Telefone </label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="telefone">
                                                <i className="fas fa-phone" id="iconTelefone"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="&nbsp;Informe o Telefone"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarCelular}
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarCelular: event.target.value})}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="rg" id="lblrg">RG</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="rg">
                                                <i class="fas fa-id-card" id="iconRg"></i>
                                                <InputMask
                                                    mask="99.999.999-9"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="text"
                                                    placeholder="&nbsp;Informe o RG"
                                                    autoComplete="on"
                                                    value={this.state.rg}
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={a => {this.setState({rg: a.target.value})}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                                <p className="subtitle-form">Informações de Endereço</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cep" id="lblCep">CEP *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cep">
                                                <i className="fas fa-envelope" id="iconCep"></i>
                                                <InputMask
                                                    mask="99999-999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarCep}
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.getCEP(event, "cadastrar")}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>       
                                        
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estado" id="lblEstado">Estado *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="estado">
                                                <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                <select
                                                    name="estado"
                                                    value={this.state.modalCadastrarEstado}
                                                    placeholder="&nbsp;Selecione o Estado de residência"
                                                    autoComplete="on"
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarEstado: event.target.value })}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o Estado de residência</option>
                                                    <option value='AC'>Acre</option>
                                                    <option value='AL'>Alagoas</option>
                                                    <option value='AP'>Amapá</option>
                                                    <option value='AM'>Amazonas</option>
                                                    <option value='BA'>Bahia</option>
                                                    <option value='CE'>Ceará</option>
                                                    <option value='DF'>Distrito Federal</option>
                                                    <option value='ES'>Espírito Santo</option>
                                                    <option value='GO'>Goiás</option>
                                                    <option value='MA'>Maranhão</option>
                                                    <option value='MT'>Mato Grosso</option>
                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                    <option value='MG'>Minas Gerais</option>
                                                    <option value='PA'>Pará</option>
                                                    <option value='PB'>Paraíba</option>
                                                    <option value='PR'>Paraná</option>
                                                    <option value='PE'>Pernambuco</option>
                                                    <option value='PI'>Piauí</option>
                                                    <option value='RJ'>Rio de Janeiro</option>
                                                    <option value='RN'>Rio Grande do Norte</option>
                                                    <option value='RS'>Rio Grande do Sul</option>
                                                    <option value='RO'>Rondônia</option>
                                                    <option value='RR'>Roraima</option>
                                                    <option value='SC'>Santa Catarina</option>
                                                    <option value='SP'>São Paulo</option>
                                                    <option value='SE'>Sergipe</option>
                                                    <option value='TO'>Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cidade" id="lblCidade">Cidade *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cidade">
                                                <i className="fas fa-city" id="iconCidade"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarCidade}
                                                    placeholder="&nbsp;Informe a Cidade de residência"
                                                    autoComplete="on"
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarCidade: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>    
                                </div>       

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="logradouro" id="lblLogradouro">Logradouro *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="logradouro">
                                                <i className="fas fa-road" id="iconLogradouro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarLogradouro}
                                                    placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                    autoComplete="on"
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarLogradouro: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="numero" id="lblNumero">Número *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="numero">
                                                <i className="fas fa-home" id="iconNumero"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Número de Residência"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarNumero}
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarNumero: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="bairro" id="lblBairro">Bairro *</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="bairro">
                                                <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarBairro}
                                                    placeholder="&nbsp;Informe o Bairro"
                                                    autoComplete="on"
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarBairro: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="complemento">Complemento</label>
                                            <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="complemento">
                                                <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                <input
                                                    type="text"
                                                    value={this.state.modalCadastrarComplemento}
                                                    placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                    autoComplete="on"
                                                    disabled={!this.state.is_pessoa}
                                                    onChange={event => this.setState({ modalCadastrarComplemento: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        
                                        
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarPessoa} className="cadbtn">Cadastrar</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default PessoasCadastrarPage