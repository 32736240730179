import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

import { api_viacep } from '../../services/api';


class TrocaPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            itens: [],
            empresas: [],
            empresaId: '',
            empresaIdOrigem: '',
            veiculos: []
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/trocaEstoque")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarItens();
            this.apiListarEmpresas();
        }
    }

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }
    
    apiListarItens = async event => {
        let res = await api_topmotos.get('/itens/');
        this.setState({ itens: res.data});
        
        let vei = {}

        for(let i of res.data){
            vei[`${i.item_id}`] = 0
        }

        this.setState({veiculos: vei})
    }

    apiListarEmpresas = async event => {
        let res = await api_topmotos.get('/empresas/');
        this.setState({ empresas: res.data });
    }

    selecionarVeiculo = (id, val) =>{
        let {veiculos} = this.state;

        veiculos[id] = val

        this.setState({veiculos: veiculos})
    }

    apiAlterarEmpresa = () =>{
        var inputs = [
            [this.state.empresaIdOrigem, 'empresa', 'iconEmpresa', 'lblEmpresa'],
            [this.state.empresaId, 'empresaD', 'iconEmpresaD', 'lblEmpresaD'],
        ]

        var conteudo = 0

        for(var i = 0; i < inputs.length; i++){
            if(inputs[i][0]){
                conteudo++
            }
        }

        if(conteudo == inputs.length){
            for(var i = 0; i < inputs.length; i++){
                var ssInputNome = document.getElementById(inputs[i][1]);
                var ssInputIconNome = document.getElementById(inputs[i][2]);
                var ssInputLabel = document.getElementById(inputs[i][3]);
                ssInputNome.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                ssInputIconNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                ssInputLabel.style.cssText = "color: #777; transition: 0.2s;";
			}	
            
            for(let i in this.state.veiculos){
                if(!this.state.veiculos[i]){
                  continue  
                }
    
                try{
                    api_topmotos.put(`/itens/update/empresa/${i}`, {
                        empresa: this.state.empresaId
                    })

                    this.props.history.push("/itens")

                } catch{
                    this.setState({ error: "ocorreu um erro ao conectar com a API! (/itens/update)" });
				    this.log(this.state.error);
                }
            }
        } else{
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var ssInputNome = document.getElementById(inputs[i][1]);
					var ssInputIconNome = document.getElementById(inputs[i][2]);
					var ssInputLabel = document.getElementById(inputs[i][3]);
					ssInputNome.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					ssInputIconNome.style.cssText = "color: #FF7115; transition: 0.2s;";
					ssInputLabel.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var ssInputNome = document.getElementById(inputs[i][1]);
					var ssInputIconNome = document.getElementById(inputs[i][2]);
					var ssInputLabel = document.getElementById(inputs[i][3]);
					ssInputNome.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					ssInputIconNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					ssInputLabel.style.cssText = "color: #777; transition: 0.2s;";
				}
			}	
        }

    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Troca de Estoque</strong></p>

                            </div>
                            <div className="content-formulario">
								<div className="row">
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="empresa" id="lblEmpresa">Selecione a origem</label>
											<div className="grupo-campo-input input-cad" id="empresa">
												<i className="fas fa-user-edit" id="iconEmpresa"></i>
												<select
													name="empresa"
													placeholder="&nbsp;Selecione a empresa"
													autoComplete="on"
													onChange={event => this.setState({ empresaIdOrigem: event.target.value })}
													required
												>
													<option value="">Selecione a empresa</option>
													{
														this.state.empresas.map((empresa, id) => (
															<option key={empresa.empresa_id} value={empresa.empresa_id}>{empresa.empresa_nomefantasia}</option>
														))
													}
												</select>
											</div>
										</div>
									</div>
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="empresa" id="lblEmpresaD">Selecione o destino</label>
											<div className="grupo-campo-input input-cad" id="empresaD">
												<i className="fas fa-user-edit" id="iconEmpresaD"></i>
												<select
													name="empresa"
													placeholder="&nbsp;Selecione a empresa"
													autoComplete="on"
													onChange={event => this.setState({ empresaId: event.target.value })}
													required
												>
													<option value="">Selecione a empresa</option>
													{
														this.state.empresas.map((empresa, id) => (
															<option key={empresa.empresa_id} value={empresa.empresa_id}>{empresa.empresa_nomefantasia}</option>
														))
													}
												</select>
											</div>
										</div>
									</div>
                                </div>
                                <div className="list-itens">
                                    {this.state.itens.filter((it) => {
                                        return it.empresa_id == this.state.empresaIdOrigem && it.status == 1}).map((itens) => {
                                            return (
                                                <>
                                                    <div className="check-itens">
                                                        <input type="checkbox" id={"vei" + itens.item_id} name={"vei" + itens.item_id} value={itens.item_id} onChange={a => this.selecionarVeiculo(itens.item_id, a.target.checked)}/>
                                                        <label htmlFor={"vei" + itens.item_id}>{itens.item_modelo} - {itens.cor} <p className="placa">{itens.placa.toUpperCase()}</p></label>
                                                    </div>
                                                </>
                                            )
                                    })}
                                </div>
                                <button onClick={this.apiAlterarEmpresa} className="cadbtn btn mt-1">Trocar</button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default TrocaPage