import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { ThemeProvider } from 'styled-components';

class ContasBancariasPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalCadastrar: false,
            modalAlterar: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',
            contas: [],
            descricao: '',
            contaId: ''
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/contasBancarias")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.listarContas()
        }
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    listarContas = async () =>{
        let res = await api_topmotos.get('/contasBancarias/');
        this.setState({ contas: res.data });
    }

    // Selecionar conta por ID
    listarContaId = async (id) =>{
        let {data} = await api_topmotos.get("/contasBancarias/id/" + id)

        this.setState({
            contaId: data[0].id,
            descricao: data[0].descricao,
        })


    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Off/On modal alterar
    toggleModalAlterar = () => {
        this.setState({ modalAlterar: !this.state.modalAlterar });
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }
    
    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarContaId(id);
        await this.setState({modalAlterar: true})
    }

    // Abrir modal deletar
    OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/contasBancarias/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
    }

    // Inserir conta
    salvar = async () => {
        try {
            await api_topmotos.post('/contasBancarias/create', {
                descricao: this.state.descricao
            })

            this.listarContas();
            this.toggleModalCadastrar();

        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/contas/create)" });
            this.log(this.state.error);
        }
    }

    // Alterar conta
    alterar = async (id) => {
        try {
            await api_topmotos.put('/contasBancarias/update/id/' + id, {
                id: id,
                descricao: this.state.descricao
            })

            this.listarContas();
            this.toggleModalAlterar();


        } catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/contas/update)" });
            this.log(this.state.error);
        }
    }

    // Deletar conta
    deletar = async e =>{
        e.preventDefault();

		try {
            await api_topmotos.delete('/contas/delete/id/' + this.state.modalDeletarId);
            this.listarContas();
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/contas/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Contas deletado com sucesso!' });
    }
 
    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Contas Bancárias</p>
                                    <button className="btn-add px-3 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Conta Bancária<i class="fas fa-plus"></i></button>
                                </div>
                            </div>
                            <div className="all-contas">
                                {
                                    this.state.contas.map((contas) => (
                                        <div className="contas"> 
                                            <div className="header">
                                                <div className="w-100 d-flex justify-between">
                                                    <div className="d-flex cont-head-calc">
                                                        <div className="bolinha">
                                                            <p>{contas.descricao.substr(0, 1)}</p>
                                                        </div>
                                                        <p>{contas.descricao}</p>
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="p-2" onClick={() => this.OpenModalAlteracao(contas.id)}><i className='fas fa-pen'></i></p>
                                                        <p onClick={() => {this.OpenModalDeletar(contas.id)}}><i className='fas fa-trash'></i></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                
                            </div>
                            <Versionamento/>
                        </div>
                    </div>

                    {/* Modal de Cadastro */}
                    <Modal
					isOpen={this.state.modalCadastrar}
					toggle={this.toggleModalCadastrar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="md"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Conta Bancária</ModalHeader>
						<ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="grupo-input input-style-cad">
                                        <label htmlFor="desc">Banco</label>
                                        <div className="grupo-campo-input input-cad" id="desc">
                                        <i class="far fa-file-alt"></i>
                                            <input
                                                type="text"
                                                autoComplete="on"
                                                placeholder="Nome da Agência (ex: Bradesco, Santander)"
                                                onChange={event => this.setState({ descricao: event.target.value })}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button>Fechar</Button>
                            <Button className="bg-laranja" onClick={this.salvar}>Salvar</Button>
						</ModalFooter>
					</Modal>

                    {/* Modal de Alteração */}
                    <Modal
					isOpen={this.state.modalAlterar}
					toggle={this.toggleModalAlterar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="md"
					fade={false}>
						<ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Conta Bancária</ModalHeader>
						<ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="grupo-input input-style-cad">
                                        <label htmlFor="desc">Banco</label>
                                        <div className="grupo-campo-input input-cad" id="desc">
                                        <i class="far fa-file-alt"></i>
                                            <input
                                                type="text"
                                                autoComplete="on"
                                                placeholder="Nome da Agência (ex: Bradesco, Santander)"
                                                value={this.state.descricao}
                                                onChange={event => this.setState({ descricao: event.target.value })}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button>Fechar</Button>
                            <Button className="bg-laranja" onClick={() => this.alterar(this.state.contaId)}>Salvar</Button>
						</ModalFooter>
					</Modal>
                
                    {/*Modal Deletar*/}
                    <Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                        <ModalBody>
                            Deseja realmente deletar a conta<br></br>(ID: {this.state.modalDeletarId})?<br></br> Ao deletar você não tera mais acesso as movientações da mesma
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.deletar} color="danger">Deletar</Button>
                            <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                
                    {/* MODAL AVISO */}
                    <Modal
                        isOpen={this.state.modalAviso}
                        toggle={this.toggleModalAviso.bind(this)}
                        backdrop={true}
                        keyboard={true}
                        size="sm"
                        fade={false} >
                        <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                        <ModalBody>
                            {this.state.modalAvisoText}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => { this.toggleModalAviso();}}>Fechar</Button>
                        </ModalFooter>
                    </Modal>

                </section>
            </>
        )
    }
} export default ContasBancariasPage