import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';
import moment from 'moment';

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { NavSlider } from '../../components/NavSlider';
import TipoPagPage from '../financeiro/TipoPagamento';

class Pessoas extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],
			dadosF: [],

			pageSize: 1,
			pageLimit: 10,
			pageAtual: 0,

			modalDetalhesCnpj: '',
			modalDetalhesAdmissaodata: '',
			modalDetalhesAtualizacaodata: '',
			modalDetalhesCep: '',
			modalDetalhesEstado: '',
			modalDetalhesCidade: '',
			modalDetalhesBairro: '',
			modalDetalhesLogradouro: '',
			modalDetalhesNumero: '',
			modalDetalhesComplemento: '',
			modalDetalhesOpenF: false,
			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesNome: '',
			modalDetalhesTelefone: '',
			modalDetalhesCelular: '',
			modalDetalhesEmail: '',
			modalDetalhesCpf: '',
			modalDetalhesRg: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',
      pagina: 1, 
			quantidade_paginas: 1,

			fid: '',
			fno: '',
			ftl: '',
			fcp: ''
		};

		this.verificarPermissao();
	}

	modalDetalhesF = async (idFornecedor) => {
		let resFornecedor = await api_topmotos.get('/fornecedores/id/' + idFornecedor);
		resFornecedor = resFornecedor.data[0];

		let idEndereco = resFornecedor.fornecedor_endereco;
		let resEndereco = await api_topmotos.get('/enderecos/id/' + idEndereco);
		resEndereco = resEndereco.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resFornecedor.fornecedor_id,
			modalDetalhesNome: resFornecedor.fornecedor_nome,
			modalDetalhesTelefone: resFornecedor.fornecedor_telefone,
			modalDetalhesEmail: resFornecedor.fornecedor_email,
			modalDetalhesCnpj: resFornecedor.fornecedor_cnpj,
			modalDetalhesAdmissaodata: resFornecedor.fornecedor_admissaodata,
			modalDetalhesAtualizacaodata: resFornecedor.fornecedor_atualizacaodata,
			modalDetalhesCep: resEndereco.endereco_cep,
			modalDetalhesEstado: resEndereco.endereco_estado,
			modalDetalhesCidade: resEndereco.endereco_cidade,
			modalDetalhesBairro: resEndereco.endereco_bairro,
			modalDetalhesLogradouro: resEndereco.endereco_logradouro,
			modalDetalhesNumero: resEndereco.endereco_numero,
			modalDetalhesComplemento: resEndereco.endereco_complemento,
			modalDetalhesDescricao: resFornecedor.fornecedor_descricao,
		});
	}

	componentDidMount() {
		this.apiListarPessoas();
		this.apiListarFornecedores();
	}

	apiListarFornecedores = async event => {
		let res = await api_topmotos.get('/fornecedores/');
		this.setState({ dadosF: res.data });
		this.setState({pageSize: Math.ceil(res.data.length / this.state.pageLimit)})
	}

	verificarPermissao = async event => {
		if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/pessoas")
            if(res.length == 0){
                this.props.history.push('/dashboardcomercial')
            }
        } else{
            this.props.history.push('/dashboardcomercial')
        }
    }

	log(msg) {
		console.log("[" + this.getTimestamp() + "] clientesUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	getCEP = async (event, modal) => {
		let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');
		if (modal === 'editar') {
			this.setState({ modalDetalhesCep: event.target.value });
			this.setState({ modalEditarCep: event.target.value });
		} else if ('cadastrar') {
			this.setState({ modalCadastrarCep: event.target.value });
		}

		if (cep.length === 8) {
			let res = await api_viacep.get(`${cep}/json/`);

			if (modal === 'editar') {
				this.setState({
					modalEditarEstado: res['data'].uf,
					modalEditarCidade: res['data'].localidade,
					modalEditarBairro: res['data'].bairro,
					modalEditarLogradouro: res['data'].logradouro,
				})
			} else if ('cadastrar') {
				this.setState({
					modalCadastrarEstado: res['data'].uf,
					modalCadastrarCidade: res['data'].localidade,
					modalCadastrarBairro: res['data'].bairro,
					modalCadastrarLogradouro: res['data'].logradouro,
				})
			}
		}
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	} 

	apiListarPessoas = async event => {
		let res = await api_topmotos.get('/pessoas/');
		this.setState({ dados: res.data,  quantidade_paginas: Math.ceil(res.data.length / 10)});
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	toggleModalDetalhesF = () => {
		this.setState({ modalDetalhesOpenF: !this.state.modalDetalhesOpenF });
	}

	modalDetalhes = async (idPessoas) => {
		let resPessoas = await api_topmotos.get('/pessoas/id/' + idPessoas);
		resPessoas = resPessoas.data[0];

		let idEndereco = resPessoas.pessoa_endereco;
		let resEndereco = await api_topmotos.get('/enderecos/id/' + idEndereco);
		resEndereco = resEndereco.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resPessoas.pessoa_id,
			modalDetalhesNome: resPessoas.pessoa_nome,
			modalDetalhesTelefone: resPessoas.pessoa_telefone,
			modalDetalhesCelular: resPessoas.pessoa_celular,
			modalDetalhesEmail: resPessoas.pessoa_email,
			modalDetalhesCpf: resPessoas.pessoa_cpf_cnpj,
			modalDetalhesAdmissaodata: moment(resPessoas.pessoa_admissaodata).format("DD/MM/YYYY HH:mm"),
			modalDetalhesAtualizacaodata: resPessoas.pessoa_atualizacaodata ? moment(resPessoas.pessoa_atualizacaodata).format("DD/MM/YYYY HH:mm") : '',
			modalDetalhesCep: resEndereco.endereco_cep,
			modalDetalhesEstado: resEndereco.endereco_estado,
			modalDetalhesCidade: resEndereco.endereco_cidade,
			modalDetalhesBairro: resEndereco.endereco_bairro,
			modalDetalhesLogradouro: resEndereco.endereco_logradouro,
			modalDetalhesNumero: resEndereco.endereco_numero,
			modalDetalhesComplemento: resEndereco.endereco_complemento,
		});
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletarF = async (id) => {
		let res = await api_topmotos.get('/fornecedores/id/' + id);
		res = res.data[0];

		this.setState({
			modalTipo: "Fornecedor",
			modalDeletarOpen: true,
			modalDeletarId: res.fornecedor_id,
			modalDeletarNome: res.fornecedor_nome,
		})
	}

	modalDeletar = async (id) => {
		let compra = await api_topmotos.get('/compras/pessoa/' + id);
		let venda = await api_topmotos.get('/venda/cliente/' + id);
		
		if(compra.data || venda.data){
			var txt = venda && compra ? 'compras e vendas!' : venda ? 'vendas!' : 'compras!';
			this.setState({ 
				modalAvisoOpen: true,
				modalAvisoText: 'Não é possível deletar pois essa pessoa tem ligação em ' + txt
			});
		} else{
			let res = await api_topmotos.get('/pessoas/id/' + id);
			res = res.data[0];
	
			this.setState({
				modalTipo: "Pessoa",
				modalDeletarOpen: true,
				modalDeletarId: res.pessoa_id,
				modalDeletarNome: res.pessoa_nome,
			});
		}
	}

	apiDeletarFornecedor = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/fornecedores/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/fornecedores/update)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Fornecedor deletado com sucesso!' });

		this.apiListarFornecedores();
	}

	apiDeletarPessoa = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/pessoas/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/pessoas/update)" });
			this.log(this.state.error);
		}

		this.setState({ 
			modalAvisoOpen: true,
			modalAvisoText: 'Pessoa deletado com sucesso!' });

		this.apiListarPessoas();
	}
	
	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	
	filtrar = (ara) => {
		let filtro = ara.filter(v => {
			return this.filtrarID(v) && this.filtrarNome(v) && this.filtrarTelefone(v) && this.filtrarCpf(v);
		})

		return filtro;
	}

	filtrarID = (v) => {
		return !this.state.fid || (v.pessoa_id &&
			v.pessoa_id.toString().toLowerCase().includes(this.state.fid.toLowerCase()));
	}

	filtrarNome = (v) => {
		return !this.state.fno || (v.pessoa_nome &&
			v.pessoa_nome.toString().toLowerCase().includes(this.state.fno.toLowerCase()));
	}

	filtrarTelefone = (v) => {
		return !this.state.ftl || (v.pessoa_telefone &&
			v.pessoa_telefone.toString().toLowerCase().includes(this.state.ftl.toLowerCase()));
	}

	filtrarCpf = (v) => {
		return !this.state.fcp || (v.pessoa_cpf_cnpj &&
			v.pessoa_cpf_cnpj.toString().toLowerCase().includes(this.state.fcp.toLowerCase()));
	}

	fecharModalAviso = () => {
		if(this.state.modalDeletarOpen){
			this.toggleModalDeletar();
		}
		this.toggleModalAviso();
		this.apiListarPessoas();
	}

	putItem = (pageAtual, index) => {

		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.state.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<NavSlider navs={["Pessoas", "Fornecedores"]} slideClass="nav-slide">
							<div className="nav-slide" style={{display: "block"}}>
								<div className="form-head">
									<p>Pessoas</p>
									<div className="form-add" onClick={a => this.props.history.push("/cadastrarpessoa")}>
										Adicionar <i className="fas fa-plus"></i>
									</div>
								</div>
								<div className="table">
									<table>
										<thead>
											<tr>
												<th>
													<div className="d-flex align-center justify-between">
														ID
														<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														Nome
														<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														Telefone
														<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														CPF
														<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														Ações
													</div>
												</th>
											</tr>


											<tr>
												<th>
													<div className="d-flex align-center justify-between">
														<i className="fas fa-search"></i>
														<input type="text" value={this.state.fid} onChange={a => {this.setState({ fid: a.target.value, pagina: 1});}} />
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														<i className="fas fa-search"></i>
														<input type="text" value={this.state.fno} onChange={a => this.setState({ fno: a.target.value, pagina: 1 })} />
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														<i className="fas fa-search"></i>

														<input type="text" value={this.state.ftl} onChange={a => this.setState({ ftl: a.target.value, pagina: 1 })} />

													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														<i className="fas fa-search"></i>

														<input type="text" value={this.state.fcp} onChange={a => this.setState({ fcp: a.target.value, pagina: 1 })} />

													</div>
												</th>
												<th>
												</th>
											</tr>
										</thead>
										<tbody>

											{
												this.filtrar(this.state.dados).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map((pessoa, id) => (
													<tr key={pessoa.pessoa_id}>
														<td>{pessoa.pessoa_id}</td>
														<td>{pessoa.pessoa_nome}</td>
														<td>{pessoa.pessoa_telefone}</td>
														<td>{pessoa.pessoa_cpf_cnpj}</td>
														<td className="td-acoes">
															<i className="fas fa-eye" onClick={() => this.modalDetalhes(pessoa.pessoa_id)}></i>&nbsp;
															<i className="fas fa-pen" onClick={() => this.props.history.push("/editarpessoa/" + pessoa.pessoa_id)}></i>&nbsp;
															<i className="fas fa-trash" onClick={() => this.modalDeletar(pessoa.pessoa_id)}></i>
														</td>
													</tr>
												))
											}

										</tbody>
									</table>
									<div className="form-footer">
										<span></span>
										{this.filtrar(this.state.dados).length > 0 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																				<div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
																						this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
																				}}>
																						{<i class="fas fa-angle-left arrows"></i>}
																				</div>
																				{
																						[...Array(Math.ceil((this.filtrar(this.state.dados)).length / 10))].map((pagina, index) => {
																								if( (this.state.pagina === 1 && index + 1 < 4) || 
																										(this.state.pagina === (Math.ceil((this.filtrar(this.state.dados)).length / 10)) && index + 1 > (Math.ceil((this.filtrar(this.state.dados)).length / 10)) - 3) ||
																										(index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
																								){
																										return (
																												<div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
																												onClick={() => {
																														this.setState({pagina: index + 1})
																												}}>
																														{index + 1}
																												</div>
																										)
																								}
																								else{
																										return null
																								}
																						})
																				}
																				<div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
																						this.setState({pagina: this.state.pagina < (Math.ceil((this.filtrar(this.state.dados)).length / 10)) ? this.state.pagina + 1 : (Math.ceil((this.filtrar(this.state.dados)).length / 10))})
																				}}>
																						{<i class="fas fa-angle-right arrows"></i>}
																				</div>
																		</div>}
									</div>
								</div>
							</div>
							<div className="nav-slide">
								<div className="form-head">
									<p>Fornecedores</p>
									<div className="form-add" onClick={a => this.props.history.push("/cadastrarfornecedor")}>
										Adicionar <i className="fas fa-plus"></i>
									</div>
								</div>
								<div className="table">
									<table>
										<thead>
											<tr>
												<th>
													<div className="d-flex align-center justify-between">
														ID
															<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														Nome
															<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														Telefone
															<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														CNPJ/CPF
															<i className="fas fa-sort"></i>
													</div>
												</th>
												<th>
													<div className="d-flex align-center justify-between">
														Ações
														</div>
												</th>
											</tr>
										</thead>
										<tbody>

											{
												this.state.dadosF.slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((fornecedor, id) => (
													<tr key={fornecedor.fornecedor_id}>
														<td>{fornecedor.fornecedor_id}</td>
														<td>{fornecedor.fornecedor_nome}</td>
														<td>{fornecedor.fornecedor_telefone}</td>
														<td>{fornecedor.fornecedor_cnpj}</td>
														<td className="td-acoes">
															<i className="fas fa-eye" onClick={() => this.modalDetalhesF(fornecedor.fornecedor_id)}></i>&nbsp;
															<i className="fas fa-pen" onClick={() => this.props.history.push("/editarfornecedor/" + fornecedor.fornecedor_id)}></i>&nbsp;
															<i className="fas fa-trash" onClick={() => this.modalDeletarF(fornecedor.fornecedor_id)}></i>
														</td>
													</tr>
												))
											}

										</tbody>
									</table>
									<div className="form-footer">
										{this.state.dadosF.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
												<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
											</div>

											{
												[...Array(this.state.pageSize)].map((e, index) => (
													this.putItem(this.state.pageAtual, index)
												))
											}

											<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
												<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.state.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
											</div>
										</div>}
									</div>
								</div>
							</div>
						</NavSlider>
						<Versionamento/>
					</div>
				</div>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesNome} - {this.state.modalDetalhesId}</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Nome</p>
								<p>{this.state.modalDetalhesNome}</p>
							</div>
							<div className="conteudo-info">
								<p>Email</p>
								<p>{this.state.modalDetalhesEmail}</p>
							</div>
							<div className="conteudo-info">
								<p>Telefone</p>
								<p>{this.state.modalDetalhesCelular}</p>
							</div>
							<div className="conteudo-info">
								<p>Celular</p>
								<p>{this.state.modalDetalhesTelefone}</p>
							</div>
							<div className="conteudo-info">
								<p>CPF/CNPJ</p>
								<p>{this.state.modalDetalhesCpf}</p>
							</div>
							<div className="conteudo-info">
								<p>Data de Cadastro</p>
								<p>{this.state.modalDetalhesAdmissaodata}</p>
							</div>
							<div className="conteudo-info">
								<p>Ultima vez Atualizado</p>
								<p>{this.state.modalDetalhesAtualizacaodata}</p>
							</div>
							<div className="conteudo-info">
								<p>CEP</p>
								<p>{this.state.modalDetalhesCep}</p>
							</div>
							<div className="conteudo-info">
								<p>Estado</p>
								<p>{this.state.modalDetalhesEstado}</p>
							</div>
							<div className="conteudo-info">
								<p>Cidade</p>
								<p>{this.state.modalDetalhesCidade}</p>
							</div>
							<div className="conteudo-info">
								<p>Bairro</p>
								<p>{this.state.modalDetalhesBairro}</p>
							</div>
							<div className="conteudo-info">
								<p>Logradouro</p>
								<p>{this.state.modalDetalhesLogradouro}</p>
							</div>
							<div className="conteudo-info">
								<p>Número</p>
								<p>{this.state.modalDetalhesNumero}</p>
							</div>
							<div className="conteudo-info">
								<p>Complemento</p>
								<p>{this.state.modalDetalhesComplemento}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL DELETAR */}
				<Modal
					isOpen={this.state.modalDeletarOpen}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente deletar a {this.state.modalTipo} {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
					</ModalBody>
					<ModalFooter>
						<Button onClick={(e) => {
							if (this.state.modalTipo == "Fornecedor") this.apiDeletarFornecedor(e) 
							else if (this.state.modalTipo == "Pessoa") this.apiDeletarPessoa(e)
							else this.setState({modalDeletarOpen: false})
						}} color="danger">Deletar</Button>
						<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL AVISO */}
				<Modal
					isOpen={this.state.modalAvisoOpen}
					toggle={this.toggleModalAviso.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
					<ModalBody>
						{this.state.modalAvisoText}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { this.fecharModalAviso();}}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* FORNECEDOR */}

				{/* MODAL DETALHES DO FORNECEDOR */}
				<Modal
					isOpen={this.state.modalDetalhesOpenF}
					toggle={this.toggleModalDetalhesF.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhesF.bind(this)}>{this.state.modalDetalhesNome} - {this.state.modalDetalhesId}</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Nome</p>
								<p>{this.state.modalDetalhesNome}</p>
							</div>
							<div className="conteudo-info">
								<p>Email</p>
								<p>{this.state.modalDetalhesEmail}</p>
							</div>
							<div className="conteudo-info">
								<p>Telefone</p>
								<p>{this.state.modalDetalhesTelefone}</p>
							</div>
							<div className="conteudo-info">
								<p>CNPJ/CPF</p>
								<p>{this.state.modalDetalhesCnpj}</p>
							</div>
							<div className="conteudo-info">
								<p>Data de Cadastro</p>
								<p>{moment(this.state.modalDetalhesAdmissaodata).format("DD/MM/YYYY")}</p>
							</div>
							<div className="conteudo-info">
								<p>Ultima vez Atualizado</p>
								<p>{moment(this.state.modalDetalhesAtualizacaodata).format("DD/MM/YYYY")}</p>
							</div>
							<div className="conteudo-info">
								<p>CEP</p>
								<p>{this.state.modalDetalhesCep}</p>
							</div>
							<div className="conteudo-info">
								<p>Estado</p>
								<p>{this.state.modalDetalhesEstado}</p>
							</div>
							<div className="conteudo-info">
								<p>Cidade</p>
								<p>{this.state.modalDetalhesCidade}</p>
							</div>
							<div className="conteudo-info">
								<p>Bairro</p>
								<p>{this.state.modalDetalhesBairro}</p>
							</div>
							<div className="conteudo-info">
								<p>Logradouro</p>
								<p>{this.state.modalDetalhesLogradouro}</p>
							</div>
							<div className="conteudo-info">
								<p>Número</p>
								<p>{this.state.modalDetalhesNumero}</p>
							</div>
							<div className="conteudo-info">
								<p>Complemento</p>
								<p>{this.state.modalDetalhesComplemento}</p>
							</div>
							<div className="conteudo-info">
								<p>Descrição</p>
								<p>{this.state.modalDetalhesDescricao}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>
			</section>

		)
	}
}

export default Pessoas;