import React from 'react';
import { api_topmotos } from '../../services/api';


class PDFVendaPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            modelo: '',
            iten: []
        }
    }

    async componentDidMount(){
        await this.apiListarModelo(this.props.match.params.modelo)
        await this.apiListarIten(this.props.match.params.iten)
        window.print();
    }

    apiListarModelo = async (id) => {
        let {data} = await api_topmotos.post("/modelosdoc/unique", {id: id})
        this.setState({modelo: data[0].conteudo})        
    }

    formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

    apiListarIten = async (id) => {
        let {data} = await api_topmotos.post("/vendas/unique/pdf", {id: id})

        let vei = data.itens;
        data = data.venda[0]

        let modelo = this.state.modelo;

        try {
            modelo = modelo.replace(/@rgCliente/g, data.cliente_rg)
            .replace(/@cpfCliente/g, data.cliente_cpf)
            .replace(/@idCliente/g, data.cliente_id)
            .replace(/@emailCliente/g, data.cliente_email)
            .replace(/@nomeCliente/g, data.cliente_nome)
            .replace(/@telCliente/g, data.cliente_telefone)
    
            .replace(/@idVenda/g, data.venda_id)
            .replace(/@dataVenda/g, this.formatTime(data.venda_admissaodata))
            .replace(/@valorVenda/g, data.venda_valor)
            .replace(/@pagVenda/g, data.venda_metodopagamento)
            .replace(/@parVenda/g, data.venda_parcelas)
            .replace(/@entVenda/g, data.venda_entrada)
            .replace(/@descVenda/g, data.venda_desconto)
    
            .replace(/@idEmpresa/g, data.empresa_id)
            .replace(/@rsEmpresa/g, data.empresa_razaosocial)
            .replace(/@fantaVenda/g, data.empresa_nomefantasia)
            .replace(/@cnpjEmpresa/g, data.empresa_cnpj)
            .replace(/@telEmpresa/g, data.empresa_telefone)
            .replace(/@emailEmpresa/g, data.empresa_email)

            .replace(/@idVeiculo/g, vei.id.toString().replace(/\,/g, ", "))
            .replace(/@corVeiculo/g, vei.cor.toString().replace(/\,/g, ", "))
            .replace(/@gasVeiculo/g, vei.gas.toString().replace(/\,/g, ", "))
            .replace(/@marcaVeiculo/g, vei.marca.toString().replace(/\,/g, ", "))
            .replace(/@modeloVeiculo/g, vei.modelo.toString().replace(/\,/g, ", "))
            .replace(/@precVeiculo/g, vei.price.toString().replace(/\,/g, ", "))

        } catch (e) {
            console.log("dasd")
        }

        this.setState({modelo: modelo})        
    }

    render() {
        return (
            <>
                <p></p>
                <div dangerouslySetInnerHTML={{__html: this.state.modelo}}>

                </div>
            </>
        )
    }
}

export default PDFVendaPage;