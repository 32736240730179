import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class CategoriasPrestadoresPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			checklists: {},

            pergunta: '',
            obrigatorio: '',
            id:'',
            checklist: [],
						dados: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            ativo:'',
            errorMessage: '',

						modalDeletarOpen: false,
						modalDeletarId: '',
						modalDeletarNome: '',
        };
    }

		modalDeletar = async (id) => {
			let res = await api_topmotos.get('/prestadores/categorias/' + id);
			res = res.data[0];
	
			this.setState({
				modalDeletarOpen: true,
				modalDeletarId: res.produto_id,
				modalDeletarNome: res.produto_nome,
			})
		}

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/prestadores/categorias")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.listarChecklist();
						this.listarDados();
        }
    }

    listarChecklist = async () =>{
        let res = await api_topmotos.get('/checklist/');
        this.setState({ checklist: res.data });
    }
		listarDados = async () =>{
			let res = await api_topmotos.get('/prestadores/categorias');
			this.setState({ dados: res.data });
			console.log(res.data)
		}

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/checklist/id/" + id)
        
        this.setState({
            id: data[0].id,
            pergunta: data[0].pergunta,
            obrigatorio: data[0].obrigatorio
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);
        await this.setState({modalAlterar: true})
    }

    apiCadastrarChecklist = async () => {
        var inputs = [
            [this.state.pergunta, 'pergunta','iconPergunta','lblPergunta'],
            [Object.values(this.state.checklists).length > 0 ? true : false, 'lblObrigatoria','lblObrigatoria','lblObrigatoria']
        ]
				
        if(this.state.pergunta) {
            try {
                await api_topmotos.post('/prestadores/categorias/create', {
                    nome: this.state.pergunta,
                    checklists: Object.values(this.state.checklists)
                });

                this.toggleModalCadastrar()
								this.setState({checklists: {}})
                this.listarChecklist()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarChecklist = async () => {
        var inputs = [
            [this.state.pergunta, 'pergunta','iconPergunta','lblPergunta'],
            // [this.state.obrigatorio, '','','lblObrigatoria']
        ]

        if(this.state.pergunta && this.state.obrigatorio) {
            try {
                await api_topmotos.put('/checklist/update/id/' + this.state.id, {
                    nome: this.state.pergunta,
                    checklist: this.state.checklist
                })

                this.toggleModalAlterar()
                this.listarChecklist()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleDeletar = async (id) =>{
        try {
            await api_topmotos.delete('/prestadores/categorias/delete/' + id)
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/prestadores/categorias/delete/)" });
            this.log(this.state.error);
        }
    }

		toggleModalDeletar = () => {
			this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
		}

		selectCheckBox(e, index, id) {
			let checklists = this.state.checklists
			if (e.target.checked) checklists[index] = id
			else delete checklists[index]
			this.setState({checklists})
		}

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Categorias de Prestadores de Serviço</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Categoria<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            {this.state.dados.map((check) => (
                                    <div className="item-opcao">
                                        <div className="head default">
                                            <div className="p-head">
                                                <p className="pr-2 pergunta-p">{check.id}.</p>
                                                <p className="pergunta-p">{check.nome}</p>
												<p style={{padding: "0 15px"}}>({check.checklist})</p>
                                            </div>
                                            <div className="td-acoes">
												<i className='fas fa-trash' onClick={() => {this.modalDeletar(check.id)}}></i>&nbsp;&nbsp;
                                                {/*<i className='fas fa-pen' onClick={() => {this.OpenModalAlteracao(check.id)}}></i>*/}
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }

                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="pergunta" id="lblPergunta">Nome</label>
                                                        <div className="grupo-campo-input input-cad" id="pergunta">
                                                            <i className="fas fa-pen" id="iconPergunta"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva o Nome"
                                                                onChange={event => this.setState({ pergunta: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="lblObrigatoria">Quais os Checklists de Veiculos Relacionados?</label>
                                                        {this.state.checklist.map((c,index) => (
                                                            <div className="obrigatorio">
                                                                <input type="checkbox" name="checklists" value="0" id={c.id}  defaultChecked={false} onChange={(e) => this.selectCheckBox(e, index, c.id)}/>
                                                                <label htmlFor={c.id}>{c.pergunta}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrar}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarChecklist}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario">
                                            <div className="row">
																								<div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="pergunta" id="lblPergunta">Nome</label>
                                                        <div className="grupo-campo-input input-cad" id="pergunta">
                                                            <i className="fas fa-pen" id="iconPergunta"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva o Nome"
                                                                onChange={event => this.setState({ pergunta: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
																									<div className="grupo-input input-style-cad">
																										<label htmlFor="" id="lblObrigatoria">Quais os Checklists de Veiculos Relacionados?</label>
																										{this.state.checklist.map((c,index) => (
																											<div className="obrigatorio">
																												<input type="checkbox" name="checklists" value="0" id={c.id}  defaultChecked={false} onChange={(e) => this.selectCheckBox(e, index, c.id)}/>
																												<label htmlFor={c.id}>{c.pergunta}</label>
																											</div>
																										))}
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarChecklist}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

																{/* MODAL DELETAR */}
																<Modal
																	isOpen={this.state.modalDeletarOpen}
																	toggle={this.toggleModalDeletar.bind(this)}
																	backdrop={true}
																	keyboard={true}
																	size="sm"
																	fade={false} >
																	<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
																	<ModalBody>
																		Deseja realmente deletar a Categoria {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
																	</ModalBody>
																	<ModalFooter>
																		<Button onClick={this.toggleDeletar} color="danger">Deletar</Button>
																		<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
																	</ModalFooter>
																</Modal>

																{/* MODAL AVISO */}
																<Modal
																	isOpen={this.state.modalAvisoOpen}
																	toggle={this.toggleModalAviso.bind(this)}
																	backdrop={true}
																	keyboard={true}
																	size="sm"
																	fade={false} >
																	<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
																	<ModalBody>
																		{this.state.modalAvisoText}
																	</ModalBody>
																	<ModalFooter>
																		<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
																	</ModalFooter>
																</Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default CategoriasPrestadoresPage