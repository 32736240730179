import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class ComissaoPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            dados: [],
            // head: [],
            // despesas: [],
            pagina: 1, 
            quantidade_paginas: 1,
			quantidade: '',
			data: ''
		};

		this.verificarPermissao();
	}

	componentDidMount() {
        // this.apiListarHead();
        this.apiComissao();
	}

	verificarPermissao = async event => {
		if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/comissao")
            if(res.length == 0){
                this.props.history.push('/dashboardcomercial')
            }
        } else{
            this.props.history.push('/dashboardcomercial')
        }
    }

	log(msg) {
		console.log("[" + this.getTimestamp() + "] clientesUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }
    
	apiListarHead = async event => {
        let head = ['Data','Modelo - Ano - Cor', 'Placa', 'KM', 'Propriedade'];

        let res = await api_topmotos.get('/despesas/');
        for(let i of res.data){
            head.push(i.descricao)
        }
        
        head.push('Aquisição', 'Gastos', 'Venda', 'Margem', 'Comissão')

        this.setState({head: head, despesas: res.data})
    }
    
    apiComissao = async event => {
        let res = await api_topmotos.get('/comissao');
        this.setState({
            dados: res.data,
            quantidade_paginas: Math.ceil(res.data.length/ 10),
            quantidade: res.data.length
        })

	}
	
	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}
	
	filtrar = (ara) => {
		
		let filtro = ara.filter(v => {
			return this.filtrarData(v);
		})

		return filtro;
	}

	filtrarData = (v) => {
		return !this.state.data || (moment(v.data_pagamento).format('YYYY-MM') &&
			moment(v.data_pagamento).format('YYYY-MM').toString().toLowerCase().includes(this.state.data.toLowerCase()));
	}

	

	formatValue = (valor) => {
		return valor ? parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
	}




	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Relatório de Comissão Em Consórcio</p>
							<div className="grupo-input input-style-cad" style={{width: '230px'}}>
								<div className="grupo-campo-input input-cad" id="dataI">
									<i className="far fa-calendar-alt" id="iconDataI"></i>
									<input type="month" value={this.state.data} onChange={a => this.setState({ data: a.target.value, pagina: 1})}/>
								</div>
							</div>

						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Data da venda
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Cliente
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Vendedor
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Valor do Crédito
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Porcentagem
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Data do Pagamento
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Valor da comissão
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex align-center justify-between">
                                                Parcela
                                            </div>
                                        </th>
									</tr>
                                </thead>
								<tbody>
                                    {this.filtrar(this.state.dados).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(a => (
                                        <tr>
											<td>{moment(a.data_venda).format('DD/MM/YYYY')}</td>
                                            <td>{`${a.cliente}`}</td>
                                            <td>{a.vendedor}</td>
											<td>{this.formatValue(a.valor_credito)}</td>
											<td>{a.porcentagem}</td>
											<td>{moment(a.data_pagamento).format('DD/MM/YYYY')}</td>
											<td>{this.formatValue(a.valor_comissao)}</td>
                                            <td>{a.index_parcela}</td>
										</tr>
                                    ))}
								</tbody>
							</table>
						</div>
						{this.filtrar(this.state.dados).length > 0 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fff'}}>
							<div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
								this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
							}}>
								{<i class="fas fa-angle-left arrows"></i>}
							</div>
							{
								[...Array(Math.ceil((this.filtrar(this.state.dados)).length / 10))].map((pagina, index) => {
									if( (this.state.pagina === 1 && index + 1 < 4) || 
										(this.state.pagina === (Math.ceil((this.filtrar(this.state.dados)).length / 10)) && index + 1 > (Math.ceil((this.filtrar(this.state.dados)).length / 10)) - 3) ||
										(index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
									){
										return (
											<div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
											onClick={() => {
												this.setState({pagina: index + 1})
											}}>
												{index + 1}
											</div>
										)
									}
									else{
										return null
									}
								})
							}
							<div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
								this.setState({pagina: this.state.pagina < (Math.ceil((this.filtrar(this.state.dados)).length / 10)) ? this.state.pagina + 1 : (Math.ceil((this.filtrar(this.state.dados)).length / 10))})
							}}>
								{<i class="fas fa-angle-right arrows"></i>}
							</div>
						</div>}
						<div className="form-footer">
							<span></span>
						</div>
						<Versionamento/>
					</div>
				</div>

			
            </section>

		)
	}
}

export default ComissaoPage;
