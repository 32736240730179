import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';
import { api_viacep } from '../../services/api';
import InputMask from 'react-input-mask';
import moment from 'moment';

import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            especies: [],
            questoesV: [],
            respostas: [],
            questoesD: [],
            opcao: [],
            respostasPreenchidasDoc: {},
            respostasPreenchidasVei: {},
            obsVei: '',
            obsDoc: '',

            pessoas: [],
            usuarios: [],
            cpf: '',

            modalCadastrarNome: '',
            modalCadastrarTelefone: '',
            modalCadastrarCelular: '',
            modalCadastrarEmail: '',
            modalCadastrarRg: '',
            modalCadastrarEnderecoId: '',
            modalCadastrarCep: '',
            modalCadastrarEstado: '',
            modalCadastrarCidade: '',
            modalCadastrarBairro: '',
            modalCadastrarLogradouro: '',
            modalCadastrarNumero: '',
            modalCadastrarComplemento: '',

            dados: [],
            modalCadastrarOpen: false,
            modalCadastrarMarca: '',
            modalCadastrarModelo: '',
            modalCadastrarAno: 0,
            modalCadastrarKm: 0.0,
            modalCadastrarCor: '',
            modalCadastrarCombustivel: '',
            modalCadastrarPreco: 0.0,
            modalCadastrarDescricao: '',
            modalCadastrarTipo: '',
            modalCadastrarEstilo: '',

            forname: '',
            clientFocus: false,
            idIten: 0,

            yesp: false,
            modelos: [],
            modelSE: 'NULL',
            tipo_i: '',

            marcaSE: 'NULL',
            clientFocus2: false,
            modelosvei: [],
            marcas: [],
            modeloSE: '',

            cores: [],
            coresSE: 'NULL',

            combustiveis: [],
            combustiveisSE: 'NULL',

            estilos: [],
            estilosSE: 'NULL',

            propriedades: [],
            propriedadesSE: 'NULL',
            renavam: '',
            chassi: '',
            valorMoto: '',
            valorCompra: '',

            anos: [],
            anosSE: '',
            modeloId: '',
            pessoaId: '',

            CLInomeT: '',
            CLIrgT: '',
            CLIcpfT: '',
            CLItelefoneT: '',

            idmoto: '',

            motoNome: '',
            especie: null,

            placa: '',
            is_pessoa: false,

            indexperg: 0,
            obrigatorio: {},
            obrigatorioD: {},
            titles: ['Verificar Cliente', 'Inserir Veículo', 'Questionário Veículo', 'Questionário Documento', 'Confirmar compra'],
       
            tipoPagamento: [],
            tipoParcelamento: '',
            contas: [],
            categorias: [],
            conta: '',
            categoria: '',
            tipo:'',

            errorMessageVeiculo: '',
            questoesId: [],

            compraId: '',
            aviso: '',
            cpfValido: false,
            rg: '',
            display: 'none',
            displayTParcela: 'none',
            displayTFinanciamento: "none",
            nConta: '',
            banco: '',
            agencia: '',
            tipoConta: '',
            tipoPag: '',
            movId: '',

            terceiro: '',
            terceiroD: 'none',
            nomeT: '',
            rgT: '',
            cpfT: '',
            telefoneT: '',
            modalCadastrarCepT: '',
            modalCadastrarEstadoT: '',
            modalCadastrarCidadeT: '',
            modalCadastrarBairroT: '',
            modalCadastrarLogradouroT: '',
            modalCadastrarNumeroT: '',
            modalCadastrarComplementoT: '',
            terceiroId: '',
            mensagemCpf: '',

            modalCadastrar: false,
            movimentacoes: [],
            movimentacoesTabela: [],
            statusMov: '',

            realCompra: '', 
            realMoto: '',

            modalCadastrarValor: '',
            valorPagamento: '',

            valor: '',

            contasBancarias: [],
            contaB: '',
            dataCompetencia: '',

            repetirLancamento: '',
            meses: '',
            dataRepeticao: [],
            parcela_id: '',
            valorParcela: '',

            empresas: [],
            empresa: '',

            anoF: '',
            tID: '',
            cID: '',
            anosAll: [],
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',
            valorDeletado: '',
            obsPag: '',
            itens: [],
            valorTParcela: '',
            tParcela: '',
            tFinanciado: '',
            displayLoading: false,

            modalCadastrarHoraCheg: '',
            modalCadastrarDataCheg: '',
            despesas: [],

            comprador: null,
            comprador_name: '',
        };
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            await this.apiListarQuestoesVeiculos();
            await this.apiListarQuestoesDocumentos();
            this.apiListarOpcao();
            await this.apiListarPessoas();
            this.apiListarUsuarios();
            this.apiListarModelosDoc();
            await this.apiListarModelos();
            await this.apiListarMarcas();
            this.apiListarEspecies();
            this.apiListarCores();
            this.apiListarCombustivel();
            this.apiListarEstilos();
            this.apiListarPropriedades();
            await this.apiListarAnos();
            await this.apiListarTodosAnos();
            await this.apiListarPagamento();
            await this.apiListarContas();
            await this.apiListarCategorias();
            this.listarContasBancarias();
            this.apiListarEmpresa();
            this.apiListarItens();
            await this.listarDespesas();
    
            if (this.props.match.params.id){
                this.apiConsultarItem()
            }
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/itens")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    apiConsultarItem = async () => {
        let {data} = await api_topmotos.post('/questitem', {id: this.props.match.params.id})

        if (data == 'Invalid'){
            return
        }

        let {respostasPreenchidasVei, respostasPreenchidasDoc} = this.state

        for (let i of data.ckvei){
            respostasPreenchidasVei[`1-${i.id_opcao_checklist}`] = i.resposta;
        }

        for (let i of data.ckdoc){
            respostasPreenchidasDoc[`2-${i.cod_check_list}`] = {'opt': i.checklist_item, 'txt': i.respostas_checklist_documento}
        }

        let resp = await api_topmotos.get('/anos/mes/id/'+ data.item[0].item_ano);
        let ano = resp.data.filter(a => a.ano == data.item[0].ano_mod && a.id_modelo == data.item[0].id_modelo)
        
        this.apiListarAnos(resp.data);
        this.apiListarModelos(resp.data[0].mes_referencia)

        this.setState({
            rg: data.item[0].pessoa_rg, 
            cpf: data.item[0].pessoa_cpf_cnpj, 
            modalCadastrarTipo: data.item[0].item_tipo,
            marcaSE: this.state.marcas.filter(y => y.nome == data.item[0].item_marca)[0].id,
            estilosSE: data.item[0].estilo_id,
            modalCadastrarEstilo: data.item[0].estilo_id,
            modalCadastrarModelo: data.item[0].item_modelo,
            modalCadastrarKm: data.item[0].item_km,
            modalCadastrarAno: ano[0].id,
            placa: data.item[0].placa,
            motoNome: data.item[0].motoNome,
            coresSE: data.item[0].cor_id,
            modalCadastrarCor: data.item[0].cor_id,
            combustiveisSE: data.item[0].combustivel_id,
            modalCadastrarCombustivel: data.item[0].combustivel_id,
            modalCadastrarDescricao: data.item[0].item_descricao,
            idmoto: data.item[0].item_id,
            modalCadastrarMarca: data.item[0].item_marca,
            respostasPreenchidasVei: respostasPreenchidasVei,
            empresa: data.item[0].empresa_id,
            modeloSE: data.item[0].item_modelo,
            modeloId: data.item[0].id_modelo,
            anoF: data.item[0].item_ano_fab,
            renavam: data.item[0].renavam,
            chassi: data.item[0].chassi,
            propriedadesSE: data.item[0].prop_id,
            modalCadastrarDataCheg: moment(data.item[0].data_chegada).utc().format("yyyy-MM-DD"),
            modalCadastrarHoraCheg: data.item[0].hora_chegada
        })

        this.configAnoZero()

        if(data.item[0].item_preco){
            let realMoto = data.item[0].item_preco.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})      
            this.mascaraVenda(realMoto)
        }

        if(data.item[0].terceira_pessoa){
            let re = await api_topmotos.get('/pessoasT/id/' + data.item[0].terceira_pessoa);

            this.setState({
                terceiroD: 'block',
                nomeT: re.data[0].nome,
                rgT: re.data[0].rg,  
                cpfT: re.data[0].cpf,
                telefoneT: re.data[0].telefone,
                terceiro: re.data[0].tipo,
                tID: re.data[0].id,
                modalCadastrarCepT: re.data[0].cep,
                modalCadastrarEstadoT: re.data[0].estado,
                modalCadastrarCidadeT: re.data[0].cidade,
                modalCadastrarBairroT: re.data[0].bairro,
                modalCadastrarLogradouroT: re.data[0].logradouro,
                modalCadastrarNumeroT: re.data[0].numero,
                modalCadastrarComplementoT: re.data[0].complemento,
            })
        } else{
            this.setState({
                terceiro: 'cliente'
            })
        }

        try {
            this.setState({
                obsDoc: data.obss[1].obs,
                obsVei: data.obss[0].obs,
            })
        } catch (error) {
            
        }

        let res = await api_topmotos.get('/compras/itens/' + this.props.match.params.id)
        if(res.data.length > 0){
            let real = res.data[0].valor_compra.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})
            this.setState({
                cID: res.data[0].id,
                obsPag: res.data[0].observacao_pag
            })        
            this.mascaraCompra(real)
        }

        this.abrirEstilo(data.item[0].item_tipo)
        this.checkPessoa({target: {value: data.item[0].pessoa_cpf_cnpj}})
    }

    // Questões veiculos
    apiListarQuestoesVeiculos = async () => {
        let res = await api_topmotos.get('/checklist/ativo/');

        let { respostasPreenchidasVei, obrigatorio, questoesId } = this.state;
        let cont = 0

        for (let i of res.data) {
            cont++
            questoesId[cont] = i['id']
            obrigatorio[`1-${i['id']}`] = i['obrigatorio']
            respostasPreenchidasVei[`1-${i['id']}`] = ''
        }

        this.setState({ questoesV: res.data, respostasPreenchidasVei: respostasPreenchidasVei, obrigatorio: obrigatorio});
    }

    // Questões Documentos
    apiListarQuestoesDocumentos = async () => {
        let res = await api_topmotos.get('/checklistD/ativo/');

        let { respostasPreenchidasDoc, obrigatorioD } = this.state

        for (let i of res.data) {
            respostasPreenchidasDoc[`2-${i['id']}`] = { "opt": '', 'txt': '' }
            obrigatorioD[`2-${i['id']}`] = i['obrigatorio']
        }

        this.setState({ questoesD: res.data, respostasPreenchidas: respostasPreenchidasDoc, obrigatorioD: obrigatorioD });
    }

    // Opções das Questões Documentos 
    apiListarOpcao = async () => {
        let res = await api_topmotos.get('/checklistA/');
        this.setState({ opcao: res.data });
    }

    // Pessoas
    apiListarPessoas = async () => {
        let res = await api_topmotos.get('/pessoas/');
        this.setState({ pessoas: res.data });
    }

    // Usuarios
    apiListarUsuarios = async () => {
        let res = await api_topmotos.get('/usuarios');
        this.setState({ usuarios: res.data });
        console.log(res.data)
    }

    // Itens
    apiListarItens = async () => {
        let res = await api_topmotos.get('/itens/');
        this.setState({ itens: res.data });
    }

    // Espécies
    apiListarEspecies = async () => {
        let res = await api_topmotos.get('/especie');
        this.setState({ especies: res.data });
    }

    // Empresas
    apiListarEmpresa = async () => {
        let res = await api_topmotos.get('/empresas/');
        this.setState({ empresas: res.data });
    }

    // Marcas
    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    // Modelos
    apiListarModelos = async (mes) => {
        if(mes){
            let res = await api_topmotos.post('/modelos/mes', {mes: mes});
            this.setState({ modelosvei: res.data });
        } else{
            let res = await api_topmotos.get('/modelos/');
            this.setState({ modelosvei: res.data });
        }
    }

    // Modelos de Documentos
    apiListarModelosDoc = async event => {
        let res = await api_topmotos.get('/modelosdoc/pro');
        this.setState({ modelos: res.data });
    }

    // Cores
    apiListarCores = async event => {
        let res = await api_topmotos.get('/cores/');
        this.setState({ cores: res.data });
    }

    // Combustível
    apiListarCombustivel = async event => {
        let res = await api_topmotos.get('/combustivel/');
        this.setState({ combustiveis: res.data });
    }

    // Estilos
    apiListarEstilos = async event => {
        let res = await api_topmotos.get('/estilo/');
        this.setState({ estilos: res.data });
    }

    // Propriedades
    apiListarPropriedades = async event => {
        let res = await api_topmotos.get('/propriedades/');
        this.setState({ propriedades: res.data });
    }

    // Anos
    apiListarAnos = async (ano) => {
        let res = await api_topmotos.get('/anos/');
        
        if(ano){
            for(let i of ano){
                i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
            }
            
            this.setState({ anos: ano });
        }else{
            for(let i of res.data){
                i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
            }

            this.setState({ anos: res.data });
        }

    }

    configAnoZero = () =>{
        for(let i of this.state.anos){
			i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
        }
    }

    // Anos
    apiListarTodosAnos = async event => {
        let res = await api_topmotos.get('/anosAll/');

        for(let i of res.data){
			i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
        }
        
        this.setState({ anosAll: res.data });

    }

    // Listar tipo pagamentos
    apiListarPagamento = async () =>{
        let res = await api_topmotos.get('/tipopag/');
        res = res.data.filter( t => t.compra == 1);
        this.setState({ tipoPagamento: res });
    }

    // Listar Contas 
    apiListarContas = async () =>{
        let res = await api_topmotos.get('/contas/');
        this.setState({ contas: res.data });
    }

    // Listar Categorias 
    apiListarCategorias = async () => {
        let res = await api_topmotos.get('/categoriasAll/');
        
        let groups = res.data.filter(r => {
            return !r.cat_id && r.tipo == "D";
        })
        groups.forEach(g => {
            g.options = res.data.filter(r => {
                return r.cat_id == g.id;
            })
        })

        this.setState({ categorias: groups });
    }

    // Listar despesas
    listarDespesas = async () =>{
        let res = await api_topmotos.get('/despesas/const');
        this.setState({ despesas: res.data });
    }

    // Listar Contas Bancárias
    listarContasBancarias = async () =>{
        let res = await api_topmotos.get('/contasBancarias/');
        this.setState({ contasBancarias: res.data });
    }

    listarMovimentacao = async () =>{
        let res;
        res = await api_topmotos.get('/financeiro/');
        this.setState({ movimentacoes: res.data });
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    slide = (cont) => {
        var questionario = document.getElementsByClassName("perguntas");
        var aberta;

        for(var i = 0; i < questionario.length; i++){
            if(questionario[i].style.display == 'flex'){
                aberta = i;
            }
            questionario[i].style.display = 'none';
        }

        questionario[aberta + cont].style.display = 'flex'
        this.setState({indexperg: aberta + cont})
    }

    makeRelation = (id, txt, is_opt = false) => {

        if (id.split('-')[0] == '2') {
            let r = this.state.respostasPreenchidasDoc

            if (is_opt) {
                r[id]['opt'] = txt
            }
            else {
                r[id]['txt'] = txt
            }

            this.setState({ respostasPreenchidasDoc: r })
        }
        else {
            let r = this.state.respostasPreenchidasVei
            r[id] = txt;
            this.setState({ respostasPreenchidasVei: r })
        }

    }

    saveChecklist = async (re, obs, typeobs, idmoto, obg) => {
        try{
            this.toggleLoading();
            api_topmotos.post('/checklistA/saveChecklist', { respostas: re, obs: obs, typeobs: typeobs, idmoto: idmoto }).then(res => {
                this.toggleLoading();
            })
        } catch{
        }

        if (typeobs == "1"){
            for(let i in re){
                if (re[i] == "" && obg[i] != 1){
                    alert("Preencha todos os campos obrigatórios para avançar.")
                    return 
                }
            }
        }
        else{
            for(let i in re){
                if (re[i]['opt'] == "" && obg[i] != 1){
                    alert("Preencha todos os campos obrigatórios para avançar.")
                    return 
                }
            }
        }

        this.slide(+1);
    }

    apiCadastrarPessoa = async e => {
        
        const {
            modalCadastrarNome,
            modalCadastrarTelefone,
            modalCadastrarCelular,
            modalCadastrarEmail,
            cpf,
            rg,
            modalCadastrarCep,
            modalCadastrarEstado,
            modalCadastrarCidade,
            modalCadastrarBairro,
            modalCadastrarLogradouro,
            modalCadastrarNumero,
            modalCadastrarComplemento,
            cpfValido
        } = this.state;

        var inputs = [
            [cpf,'cpf','iconCpf','lblCpf'],
            [cpfValido,'cpf','iconCpf','lblCpf'],
            [modalCadastrarNome,'nome','iconNome','lblNome'],
            [modalCadastrarTelefone,'celular','iconCelular','lblCelular'],
            [modalCadastrarCep,'cep','iconCep','lblCep'],
            [modalCadastrarEstado,'estado','iconEstado','lblEstado'],
            [modalCadastrarCidade,'cidade','iconCidade','lblCidade'],
            [modalCadastrarLogradouro,'logradouro','iconLogradouro','lblLogradouro'],
            [modalCadastrarNumero,'numero','iconNumero','lblNumero'],
            [modalCadastrarBairro,'bairro','iconBairro','lblBairro']
        ]

        if(this.state.pessoaId){
            this.slide(+1);
        } else{
            if (!modalCadastrarNome ||
                !modalCadastrarTelefone ||
                !cpf ||
                !modalCadastrarCep ||
                !modalCadastrarEstado ||
                !modalCadastrarCidade ||
                !modalCadastrarBairro ||
                !modalCadastrarLogradouro ||
                !modalCadastrarNumero ||
                !cpfValido ||
                modalCadastrarEstado === 'NULL' ||
                modalCadastrarTelefone === '(__) _____-____' ||
                modalCadastrarCep === '_____-___') {
                this.setState({ error: "Preencha todos os dados Obrigatórios!" });
                this.setState({ errorMessage: "Preencha todos os dados Obrigatórios!" });
    
                if(this.state.pessoaId === '' || this.state.pessoaId == null && !cpfValido){
                    var inputId = document.getElementById(inputs[0][1]);
                    var inputIcone = document.getElementById(inputs[0][2]);
                    var inputNome = document.getElementById(inputs[0][3]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                } else{
                    for(var i = 0; i < inputs.length; i++){
                        if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                            var inputId = document.getElementById(inputs[i][1]);
                            var inputIcone = document.getElementById(inputs[i][2]);
                            var inputNome = document.getElementById(inputs[i][3]);
                            inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                            inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                            inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                            
                        } else{
                            var inputId = document.getElementById(inputs[i][1]);
                            var inputIcone = document.getElementById(inputs[i][2]);
                            var inputNome = document.getElementById(inputs[i][3]);
                            inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                            inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                            inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        }
                    }
                }
            } else {
                this.toggleLoading();
        
                this.setState({ errorMessage: '' });
    
                for(var i = 0; i < inputs.length; i++){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
    
                try {
                    await api_topmotos.post('/enderecos/create', {
                        endereco_cep: modalCadastrarCep,
                        endereco_estado: modalCadastrarEstado,
                        endereco_cidade: modalCadastrarCidade,
                        endereco_bairro: modalCadastrarBairro,
                        endereco_logradouro: modalCadastrarLogradouro,
                        endereco_numero: modalCadastrarNumero,
                        endereco_complemento: modalCadastrarComplemento,
                        endereco_admissaodata: this.getTimestamp(),
    
                    }).then(res => {
                        this.setState({ modalCadastrarEnderecoId: res.data.returnId });
                        this.slide(+1);
                    });
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                    this.log(this.state.error);
                }
    
                try {
                    await api_topmotos.post('/pessoas/create', {
                        pessoa_nome: modalCadastrarNome,
                        pessoa_telefone: modalCadastrarTelefone,
                        pessoa_celular: modalCadastrarCelular,
                        pessoa_email: modalCadastrarEmail,
                        pessoa_rg: rg,
                        pessoa_cpf: cpf,
                        pessoa_endereco: this.state.modalCadastrarEnderecoId,
                        pessoa_admissaodata: this.getTimestamp(),
                        lead: null,
                        idpessoa: this.state.pessoaId
                    }).then(res => {
                        this.setState({ 
                            pessoaId: res.data.returnId,
                            CLInomeT: modalCadastrarNome,
                            CLIrgT: rg,
                            CLIcpfT: cpf,
                            CLItelefoneT: modalCadastrarTelefone || modalCadastrarCelular
                        });
                    });
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/pessoas/create)" });
                    this.log(this.state.error);
                }

                this.toggleLoading();
            }
        }
    }

    apiCadastrarItem = async e => {

        const {
            modalCadastrarModelo,
            modalCadastrarMarca,
            modalCadastrarAno,
            modalCadastrarKm,
            modalCadastrarCor,
            modalCadastrarCombustivel,
            modalCadastrarPreco,
            modalCadastrarDescricao,
            modalCadastrarTipo,
            modalCadastrarEstilo,
            nomeT,
            rgT,
            cpfT,
            telefoneT, 
            empresa,
            anoF,
            estilosSE,
            modalCadastrarDataCheg,
            modalCadastrarHoraCheg,
            comprador,
        } = this.state;

        let telefoneClen = telefoneT.replace(" ", "").replace("(", "").replace(")", "").replace("-", "");
        telefoneClen = telefoneClen.replaceAll("_", "");

        var inputs = [
            [modalCadastrarTipo,'tipo','iconTipo','lblTipo'],
            [estilosSE,'Iestilo','iconEstilo','lblEstilo'],
            [modalCadastrarMarca,'marca','iconMarca','lblMarca'],
            [modalCadastrarModelo,'modelo','iconModelo','lblModelo'],
            [modalCadastrarAno,'ano','iconAno','lblAno'],
            [anoF,'anoF','iconAnoF','lblAnoF'],
            [modalCadastrarKm,'km','iconKm','lblKm'],
            [modalCadastrarCor,'cor','iconCor','lblCor'],
            [modalCadastrarCombustivel,'combustivel','iconCombustivel','lblCombustivel'],
            [empresa,'empresa','iconEmpresa','lblEmpresa'],
            [modalCadastrarDataCheg,'dataCheg','iconDataCheg','lblDataCheg'],
            [modalCadastrarHoraCheg,'horaCheg','iconHoraCheg','lblHoraCheg'],

            [telefoneClen,'telefoneT','iconTelefoneT','lblTelefoneT'],
            [nomeT,'nomeT','iconNomeT','lblNomeT'],
            [cpfT,'cpfT','iconCpfT','lblCpfT'],
        ]

        if (!modalCadastrarModelo ||
            !modalCadastrarMarca ||
            !modalCadastrarAno ||
            !modalCadastrarKm ||
            !modalCadastrarCor ||
            !modalCadastrarCombustivel ||
            !modalCadastrarTipo ||
            !empresa ||
            !anoF ||
            (modalCadastrarTipo != 'Carro' && estilosSE === 'NULL') ||
            !modalCadastrarHoraCheg ||
            !modalCadastrarDataCheg ||
            (!telefoneClen || telefoneClen.length < 11) ||
            !nomeT ||
            (!cpfT || cpfT.length < 14) ||
            /*(!this.state.motoNome && this.state.terceiro != 'cliente') ||
            (this.state.terceiro != 'terceiro' 
            && (!this.state.modalCadastrarCepT || !this.state.modalCadastrarEstadoT ||
                !this.state.modalCadastrarCidadeT || !this.state.modalCadastrarBairroT ||
                !this.state.modalCadastrarLogradouroT || !this.state.modalCadastrarComplementoT)) ||*/
            modalCadastrarTipo === 'NULL' || 
            this.state.marcaSE === "NULL" ||
            this.state.coresSE === "NULL" ||
            this.state.combustiveisSE === "NULL" ||
            modalCadastrarAno === '____') {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessageVeiculo: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);

            for(var i = 0; i < inputs.length; i++){
                if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                    inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    
                } else{
                    var inputId = document.getElementById(inputs[i][1]);
                    var inputIcone = document.getElementById(inputs[i][2]);
                    var inputNome = document.getElementById(inputs[i][3]);
                    inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                    inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                }
            }
            alert("Verifique se todas as informações estão corretas");
        } else {
            this.toggleLoading();
            this.setState({ errorMessage: '' });

            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }  
            
            let terceiroId;

            if(this.state.terceiro != 'cliente' && nomeT && telefoneT){
                let re;
                
                try {
                    re = await api_topmotos.post('/pessoasT/create', {
                        nome: nomeT,
                        cpf: cpfT,
                        rg: rgT,
                        telefone: telefoneT,
                        terceiroId: this.state.terceiroId,
                        terceiro: this.state.terceiro,
                        cep: this.state.modalCadastrarCepT,
                        estado: this.state.modalCadastrarEstadoT,
                        cidade: this.state.modalCadastrarCidadeT,
                        bairro: this.state.modalCadastrarBairroT,
                        logradouro: this.state.modalCadastrarLogradouroT,
                        numero: this.state.modalCadastrarNumeroT ? this.state.modalCadastrarNumeroT : null,
                        complemento: this.state.modalCadastrarComplementoT,
                        id: this.state.tID
                    })

                    terceiroId = this.state.tID ? this.state.tID : re.data.returnId 
                    this.setState({terceiroId: terceiroId})
  
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/itens/create)" })
                    this.log(this.state.error);
                }
            }

            let re;
            let ano = this.state.anos.filter(a => a.id == modalCadastrarAno)
            ano[0].ano = ano[0].ano == "Zero Km" ? 3200 : ano[0].ano

            try {
                re = await api_topmotos.post('/itens/create/quest', {
                    item_modelo: modalCadastrarModelo,
                    item_marca: modalCadastrarMarca,
                    item_ano: modalCadastrarAno,
                    ano_mod: ano[0].ano,
                    item_km: modalCadastrarKm,
                    cor_id: modalCadastrarCor,
                    combustivel_id: modalCadastrarCombustivel,
                    item_preco: modalCadastrarPreco,
                    item_descricao: modalCadastrarDescricao,
                    item_fornecedor: this.state.pessoaId,
                    item_tipo: modalCadastrarTipo,
                    item_admissaodata: this.getTimestamp(),
                    estilo_id: modalCadastrarEstilo,
                    compra: 0,
                    placa: this.state.placa,
                    motoNome: this.state.motoNome,
                    itemEspecie: this.state.especie,
                    idmoto: this.state.idmoto,
                    empresa: this.state.empresa,
                    terceiro: terceiroId ? terceiroId : null,
                    id_modelo: this.state.modeloId,
                    anoF: anoF,
                    data_cheg: modalCadastrarDataCheg,
                    hora_cheg: modalCadastrarHoraCheg,
                    comprador: comprador,
                })

                this.setState({ idmoto: re.data.returnId });
                this.slide(+1);
                this.apiCadastrarDespesa();

            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/itens/create)" })
                this.log(this.state.error);
            }
            
            this.toggleLoading();
        }
    }

    checkPessoa = (e) => {

        if (e.target.value && (e.target.value.length == 14 || e.target.value.length == 18)) {

            var pessoaBox = document.getElementById('pessoa')
            var aviso = document.getElementById('aviso')

            this.setState({aviso: '', errorMessage: ''})

            var pessoa = this.state.pessoas.filter((pes) => {
                return pes.pessoa_cpf_cnpj == e.target.value
            })

            if (pessoa.length == 0) {
                aviso.style.display = "none"
                this.setState({
                    pessoaId: null, 
                    is_pessoa: true,
                    modalCadastrarNome: '',
                    modalCadastrarEmail: '',
                    modalCadastrarTelefone: '',
                    modalCadastrarCelular: '',
                    modalCadastrarCep: '',
                    modalCadastrarEstado: '',
                    modalCadastrarCidade: '',
                    modalCadastrarBairro: '',
                    modalCadastrarLogradouro: '',
                    modalCadastrarNumero: '',
                    modalCadastrarComplemento: '',
                    rg: ''
                })
            } else {
                this.setState({aviso: 'Cliente Encontrado!'})
                aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
        
                this.setState({
                    pessoaId: pessoa[0]['pessoa_id'], 
                    is_pessoa: false,
                    rg: pessoa[0].pessoa_rg,
                    modalCadastrarNome: pessoa[0].pessoa_nome,
                    modalCadastrarEmail: pessoa[0].pessoa_email,
                    modalCadastrarTelefone: pessoa[0].pessoa_telefone,
                    modalCadastrarCelular: pessoa[0].pessoa_celular,
                    modalCadastrarCep: pessoa[0].endereco_cep,
                    modalCadastrarEstado: pessoa[0].endereco_estado,
                    modalCadastrarCidade: pessoa[0].endereco_cidade,
                    modalCadastrarBairro: pessoa[0].endereco_bairro,
                    modalCadastrarLogradouro: pessoa[0].endereco_logradouro,
                    modalCadastrarNumero: pessoa[0].endereco_numero,
                    modalCadastrarComplemento: pessoa[0].endereco_complemento,
                    CLInomeT: pessoa[0].pessoa_nome,
                    CLIrgT: pessoa[0].pessoa_rg,
                    CLIcpfT: pessoa[0].pessoa_cpf_cnpj,
                    CLItelefoneT: pessoa[0].pessoa_telefone || pessoa[0].pessoa_celular,
                })
            }
        }
        else{
            this.setState({
                is_pessoa: false,
            })

            if(this.state.pessoaId){
                this.setState({
                    pessoaId: null, 
                    is_pessoa: false,
                    modalCadastrarNome: '',
                    modalCadastrarEmail: '',
                    modalCadastrarTelefone: '',
                    modalCadastrarCep: '',
                    modalCadastrarEstado: '',
                    modalCadastrarCidade: '',
                    modalCadastrarBairro: '',
                    modalCadastrarLogradouro: '',
                    modalCadastrarNumero: '',
                    modalCadastrarComplemento: '',
                    rg: ''
                })
            }
        }
    }

    getCEP = async (event, modal) => {
        let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');

        this.setState({ modalCadastrarCep: event.target.value });

        if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstado: res['data'].uf,
                modalCadastrarCidade: res['data'].localidade,
                modalCadastrarBairro: res['data'].bairro,
                modalCadastrarLogradouro: res['data'].logradouro,
            })
        }
    }

    getCEPT = async (event, modal) => {
        let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');

        this.setState({ modalCadastrarCepT: event.target.value });

        if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                modalCadastrarEstadoT: res['data'].uf,
                modalCadastrarCidadeT: res['data'].localidade,
                modalCadastrarBairroT: res['data'].bairro,
                modalCadastrarLogradouroT: res['data'].logradouro,
            })
        }
    }

    configModelo = async (e) => {
        this.setState({ modeloSE: e });

        if (e.length == 0) {
            this.setState({ modalCadastrarModelo: null })
            return
        }

        let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarModelo: c[0].nome })
        }
        else {
            this.setState({ modalCadastrarModelo: e })
        }
    }

    changeModalCadastrarTipo = (event) => {
        this.setState({ modalCadastrarTipo: event.target.value });
        this.abrirEstilo(event.target.value);
    }

    checkItem = async (e) => {
        this.setState({placa: e.target.value})

        if(e.target.value.length >= 8){

            var item = this.state.itens.filter((i) => {
                return i.placa.toUpperCase() == e.target.value.toUpperCase()
            })
    
            if (item.length > 0) {
                let resp = await api_topmotos.get('/anos/mes/id/'+ item[0].item_ano);
                let ano = resp.data.filter(a => a.ano == item[0].ano_mod && a.id_modelo == item[0].id_modelo)
                
                this.apiListarAnos(resp.data);
                this.apiListarModelos(resp.data[0].mes_referencia)
    
                this.abrirEstilo(item[0].item_tipo);

                this.setState({
                    modalCadastrarTipo: item[0].item_tipo,
                    marcaSE: this.state.marcas.filter(y => y.nome == item[0].item_marca)[0].id,
                    estilosSE: item[0].estilo_id,
                    modalCadastrarEstilo: item[0].estilo_id,
                    modalCadastrarModelo: item[0].item_modelo,
                    modalCadastrarAno: ano[0].id,
                    placa: item[0].placa,
                    coresSE: item[0].cor_id,
                    modalCadastrarCor: item[0].cor_id,
                    combustiveisSE: item[0].combustivel_id,
                    modalCadastrarCombustivel: item[0].combustivel_id,
                    modalCadastrarDescricao: item[0].item_descricao,
                    modalCadastrarMarca: item[0].item_marca,
                    modeloSE: item[0].item_modelo,
                    modeloId: item[0].id_modelo,
                    anoF: item[0].item_ano_fab,
                    renavam: item[0].renavam,
                    chassi: item[0].chassi,
                    propriedadesSE: item[0].prop_id
                })
            } else {
                
                this.apiListarAnos();
                this.apiListarModelos();

                this.setState({
                    modalCadastrarTipo: '',
                    marcaSE: '',
                    estilosSE: '',
                    modalCadastrarEstilo: '',
                    modalCadastrarModelo: '',
                    modalCadastrarAno: '',
                    coresSE: '',
                    modalCadastrarCor:'',
                    combustiveisSE:'',
                    modalCadastrarCombustivel:'',
                    modalCadastrarDescricao:'',
                    modalCadastrarMarca:'',
                    empresa:'',
                    modeloSE:'',
                    modeloId:'',
                    anoF:'',
                    renavam: '',
                    chassi: '',
                    propriedadesSE: ''
                })
            }
        }
    }

    abrirEstilo = (tipo) => {
        var estilo = document.getElementById('estilo');

        if (tipo != 'Moto' && tipo != 'Motoneta') {
            estilo.style.display = "none";
            this.setState({ modalCadastrarEstilo: null });
        } else {
            estilo.style.display = "block";
        }

    }

    enviar = async () => {
        // await this.apiCadastrarPessoa();
        // await this.apiCadastrarItem();
    }

    maskCnpjCpf = (e) => {
        let mask = []

        if(e.length <= 14){
            e = e.replace(/\D/g,"")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        } else{
            e = e.replace(/\D/g,"")
            e = e.replace(/^(\d{2})(\d)/,"$1.$2")
            e = e.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            e = e.replace(/\.(\d{3})(\d)/,".$1/$2")
            e = e.replace(/(\d{4})(\d)/,"$1-$2")
        }

        this.setState({ cpf: e })
    }

    maskCnpjCpfT = (e) => {
        let mask = []

        if(e.length <= 14){
            e = e.replace(/\D/g,"")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d)/,"$1.$2")
            e = e.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        } else{
            e = e.replace(/\D/g,"")
            e = e.replace(/^(\d{2})(\d)/,"$1.$2")
            e = e.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            e = e.replace(/\.(\d{3})(\d)/,".$1/$2")
            e = e.replace(/(\d{4})(\d)/,"$1-$2")
        }

        this.setState({cpfT: e })
    }

    // Mascára real R$00,00
    mascaraCompra = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorCompra: real})
    }

    // Mascára real R$00,00
    mascaraVenda = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorMoto: real})
    }

    // Mascára real R$00,00
    mascaraReal = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valor: real})
    }

    // Mascára parcelamento R$00,00
    mascaraParcelamento = (e) =>  {
        var real = e.replace('.','');
        real = real.replace(',', '')
        real = real + '';
        real = real.replace(/([0-9]{2})$/g, ",$1");
        if( real.length > 6 )
                real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        this.setState({valorTParcela: real})
    }

    saveCompra = async () => {
        if(this.state.valorMoto){
            var realMoto = this.state.valorMoto.replace('.', '');
            realMoto = realMoto.replace(',', '.');
            this.setState({realMoto: realMoto})
        }

        if(this.state.valorCompra){
            var realCompra = this.state.valorCompra.replace('.', '');
            realCompra = realCompra.replace(',', '.');
            this.setState({realCompra: realCompra})
        }

        const {
            valorMoto,
            valorCompra
        } = this.state

        var inputs = [
            [valorCompra, 'compra_valor', 'iconCompra_valor', 'lblCompra_valor']
        ]

        if(!valorCompra){

            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);

            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}
        } else{
            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}

            try {
                
                this.toggleLoading();
                await api_topmotos.post("/compras", {
                    prop_id: this.state.propriedadesSE ? this.state.propriedadesSE : null,
                    renavam: this.state.renavam,
                    chassi: this.state.chassi,
                    valorMoto: realMoto ? realMoto : null,
                    valorCompra: realCompra ? realCompra : null,
                    pessoa: this.state.pessoaId,
                    idmoto: this.state.idmoto,
                    empresa: this.state.empresa,
                    idCompra: this.state.cID
                }).then(res => {
                    let id = this.state.cID ? this.state.cID : res.data.id
                    this.setState({compraId: id, valorPagamento: this.state.realCompra, cID: id});
                    this.atualizarMovimentacao(1);
                    this.toggleLoading();
                    this.slide(+1);
                });
                
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                this.log(this.state.error);
            }
        }
    }

    configComprador = async (e) => {
        this.setState({ comprador_name: e.target.value });

        if (e.target.value.length == 0) {
            this.setState({ comprador: null })
            return
        }

        let c = this.state.pessoas.filter(y => y.pessoa_nome.toLowerCase().includes(e.target.value.toLowerCase()))
        if (c.length > 0) {
            this.setState({ comprador: c[0].pessoa_id })
        }
        else {
            this.setState({ comprador: null })
        }
    }

    saveMovimentacao = async () =>{
        var inputId = document.getElementById("parcelaM");
        var inputIcone = document.getElementById("lblParcelaM");
        if (this.state.meses == '' && this.state.repetirLancamento == 2) {
            inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
            inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
            return;
        } else {
            inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
            inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
        }
        await this.repetirLancamento();

        let parcela_id;

        if(this.state.repetirLancamento == 2){
            await api_topmotos.post('/parcelamento/create', {
                admissaodata: this.getTimestamp()
            }).then(res => {
                this.setState({parcela_id: res.data.returnId})
            })
        }

        const {
            valor,
            tipo,
            conta, 
            categoria,
            dataVencimento,
            banco,
            agencia, 
            nConta,
            tipoConta,
            tipoPag
        } = this.state

        var inputs = [
            [valor, 'valorM', 'iconValorM', 'lblValorM'],
            [tipo, 'tipoPagamento', 'iconTipoPagamento', 'lblTipoPagamento'],
            [conta, 'conta', 'iconConta', 'lblConta'],
            [categoria, 'categoria', 'iconCategoria', 'lblCategoria'],
            [dataVencimento, 'vencimento', 'iconVencimento', 'lblVencimento'],
        ]

        var inputsTransf = [
            [banco, 'banco', 'iconBanco', 'lblBanco'],
            [agencia, 'agencia', 'iconAgencia', 'lblAgencia'],
            [nConta, 'nconta', 'iconNConta', 'lblNConta'],
            [tipoConta, 'tipoConta', 'iconTipoConta', 'lblTipoConta']
        ]

        if(valor){
            var real = this.state.valor.replace('.', '');
            real = real.replace(',', '.');
        }

        let transferencia = false;

        if(tipoPag){
            /*if(!nConta || 
                !tipoConta ){

                for(var i = 0; i < inputsTransf.length; i++){
                    if(!inputsTransf[i][0] || inputsTransf[i][0] == 'NULL' ){
                        var inputId = document.getElementById(inputsTransf[i][1]);
                        var inputIcone = document.getElementById(inputsTransf[i][2]);
                        var inputNome = document.getElementById(inputsTransf[i][3]);
                        inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                        inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    } else{
                        var inputId = document.getElementById(inputsTransf[i][1]);
                        var inputIcone = document.getElementById(inputsTransf[i][2]);
                        var inputNome = document.getElementById(inputsTransf[i][3]);
                        inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                        inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                        inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    }
                }
                transferencia = true
            }*/
        } else{
            transferencia = false
        }
        console.log(transferencia)
        if(!tipo ||
            !conta || 
            !categoria ||
            !dataVencimento || 
            !valor || transferencia){
            
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
            
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}
        } else{
            for(var i = 0; i < inputs.length; i++){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }
            
            if(this.state.compraId){
                try{
                    this.toggleLoading();
                    if(this.state.repetirLancamento){
                        for(let i in this.state.dataRepeticao){
                            await api_topmotos.post('/financeiro/create', {
                                descricao: this.state.compraId + ' - Compra - ' +  this.state.modalCadastrarNome + ' - ' + this.state.modalCadastrarModelo,
                                dataVencimento: this.state.dataRepeticao[i],
                                valor: this.state.repetirLancamento == 2 ? this.state.valorParcela : real,
                                tipoPagamento: this.state.tipo,
                                status: 'AP',
                                admissaodata: this.getTimestamp(),
                                cat_id: this.state.categoria,
                                conta_id: this.state.conta,
                                compraId: this.state.compraId,
                                dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                                banco: this.state.contaB ? this.state.contaB : null,
                                parcela_id: this.state.parcela_id ? this.state.parcela_id : null,
                                bancoFinanciado: this.state.tFinanciado
                            }).then(res => {
                                this.setState({ movId: res.data.returnId, valorPagamento: this.state.valorPagamento - this.state.valorParcela})
                                if(this.state.tipoPag && this.state.dataRepeticao.length == (parseInt(i)+1)){
                                    this.apiCadastrarDadosBanc(res.data.returnId)
                                }
                                if(this.state.tipoParcelamento && this.state.dataRepeticao.length == (parseInt(i)+1)){
                                    this.apiCadastrarTipoParcelamento(res.data.returnId)
                                }
                            });
                        }
                    } else{
                        await api_topmotos.post('/financeiro/create', {
                            descricao: this.state.compraId + ' - Compra - ' +  this.state.modalCadastrarNome + ' - ' + this.state.modalCadastrarModelo,
                            dataVencimento: this.state.dataVencimento,
                            valor: real,
                            tipoPagamento: this.state.tipo,
                            status: 'AP',
                            admissaodata: this.getTimestamp(),
                            cat_id: this.state.categoria,
                            conta_id: this.state.conta,
                            compraId: this.state.compraId,
                            dataCompetencia: this.state.dataCompetencia ? this.state.dataCompetencia : null,
                            banco: this.state.contaB ? this.state.contaB : null,
                            parcela_id: null,
                            bancoFinanciado: this.state.tFinanciado
                        }).then(res => {
                            this.setState({ movId: res.data.returnId, valorPagamento: this.state.valorPagamento - real})
                            if(this.state.tipoPag){
                                this.apiCadastrarDadosBanc(res.data.returnId)
                            }

                            if(this.state.tipoParcelamento){
                                this.apiCadastrarTipoParcelamento(res.data.returnId)
                            }
                        });
                    }
        
                    this.atualizarMovimentacao();
                    this.toggleModalCadastrar();
                    this.limparState();
                    this.setState({valor: ''})
                    this.toggleLoading();
                } catch (err) {
                    this.setState({ error: "Ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                    this.log(this.state.error);
                }
            } else{
                console.log('Ocorreu um erro')
            }

        }
    }

    limparState = () =>{
        this.setState({
            meses: '',
            repetirLancamento: '',
            dataRepeticao: [],
            parcela_id: '',
            dataCompetencia: '',
            categoria: '',
            conta: '',
            valor: '',
            tipo: '',
            dataVencimento: '',
            valorTParcela: ''
        })
    }

    apiCadastrarDadosBanc = async (id) =>{
        try{

            await api_topmotos.post('/dadosBanc/create', {
                banco: this.state.banco,
                agencia: this.state.agencia,
                conta: this.state.nConta,
                tipo: this.state.tipoConta,
                mov: id
            })

            this.setState({display: 'none'})
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/dadosBanc/create)" });
            this.log(this.state.error);
        }
    }

    apiCadastrarTipoParcelamento = async (id) =>{
        try{
            var real = this.state.valorTParcela.replace('.', '');
            real = real.replace(',', '.');

            await api_topmotos.post('/tParcelamento/create', {
                valor: real,
                parcelas: this.state.tParcela,
                mov: id
            })

            this.setState({displayTParcela: 'none'})
        } catch (err) {
            this.setState({ error: "Ocorreu um erro ao conectar com a API! (/tParcelamento/create)" });
            this.log(this.state.error);
        }
    }

    recusarCompra = async () => {
        api_topmotos.post('/comprarecusa', {
            id: this.state.idmoto
        })

        this.props.history.push('/itens')
    }

    fipe = async () =>{
        let res = await api_topmotos.get('/anos/id/'+this.state.modalCadastrarAno);

        this.setState({ valorFipe: <p>Valor da moto em <strong>{res.data[0].mes_referencia}</strong> pela tabela fipe: <strong>{res.data[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></p>});
    }

    cpfInvalido = (tipo) =>{
        
        this.setState({aviso: tipo == 1 ? 'CNPJ inválido' : 'CPF inválido', cpfValido: false})
        var aviso = document.getElementById('aviso')
        aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";

        this.setState({
            pessoaId: null, 
            is_pessoa: false,
            modalCadastrarNome: '',
            modalCadastrarEmail: '',
            modalCadastrarTelefone: '',
            modalCadastrarCep: '',
            modalCadastrarEstado: '',
            modalCadastrarCidade: '',
            modalCadastrarBairro: '',
            modalCadastrarLogradouro: '',
            modalCadastrarNumero: '',
            modalCadastrarComplemento: '',
        })
    }
    
    validarCPF = async (e) =>{
        var cpf = e.target.value;

        if(cpf.length == 14){
            if (typeof cpf !== "string"){
                await this.cpfInvalido();
                
            } else{
                cpf = cpf.replace(/[\s.-]*/igm, '')

                if (!cpf ||
                    cpf.length != 11 ||
                    cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999" 
                ) {
                    await this.cpfInvalido();
                } else{
                    var soma = 0
                    var resto

                    for (var i = 1; i <= 9; i++) 
                        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        
                    resto = (soma * 10) % 11

                    if ((resto == 10) || (resto == 11))  resto = 0

                    if (resto != parseInt(cpf.substring(9, 10)) ){
                        await this.cpfInvalido();
                    } else{
                        soma = 0

                        for (var i = 1; i <= 10; i++) 
                            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
                        resto = (soma * 10) % 11

                        if ((resto == 10) || (resto == 11))  resto = 0

                        if (resto != parseInt(cpf.substring(10, 11) ) ){
                            await this.cpfInvalido();
                        } else{
                            this.setState({cpfValido: true})
                            this.checkPessoa(e)
                        }
                    }
                }
            }
        } else if(cpf.length == 18){
            let cnpj = cpf.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                await this.cpfInvalido(1);
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    await this.cpfInvalido(1);
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        await this.cpfInvalido(1);
                    } else{
                        this.setState({cpfValido: true})
                        this.checkPessoa(e)
                    }
                }
            }
        } else{
            this.checkPessoa(e)
            this.setState({aviso: '', cpfValido: false})
        }
    }

    validarCPFT = async (e) =>{
        var cpf = e.target.value;

        if(cpf.length == 14){
            if (typeof cpf !== "string"){
                this.setState({mensagemCpf: "CPF inválido"})
            } else{
                cpf = cpf.replace(/[\s.-]*/igm, '')

                if (!cpf ||
                    cpf.length != 11 ||
                    cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999" 
                ) {
                    this.setState({mensagemCpf: "CPF inválido"})
                } else{
                    var soma = 0
                    var resto

                    for (var i = 1; i <= 9; i++) 
                        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        
                    resto = (soma * 10) % 11

                    if ((resto == 10) || (resto == 11))  resto = 0

                    if (resto != parseInt(cpf.substring(9, 10)) ){
                        this.setState({mensagemCpf: "CPF inválido"})
                    } else{
                        soma = 0

                        for (var i = 1; i <= 10; i++) 
                            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
                        resto = (soma * 10) % 11

                        if ((resto == 10) || (resto == 11))  resto = 0

                        if (resto != parseInt(cpf.substring(10, 11) ) ){
                            this.setState({mensagemCpf: "CPF inválido"})
                        } else{
                            this.setState({mensagemCpf: "CPF válido"})
                        }
                    }
                }
            }
        } 
        else if(cpf.length == 18){
            let cnpj = cpf.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                this.setState({mensagemCpf: "CNPJ inválido"})
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    this.setState({mensagemCpf: "CNPJ inválido"})
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        this.setState({mensagemCpf: "CNPJ inválido"})
                    } else{
                        this.setState({mensagemCpf: "CNPJ válido"})
                    }
                }
            }
        } else{
            this.setState({mensagemCpf: ""})
        }
    }

    verificarTransferencia = (e) =>{
        let transferencia = this.state.tipoPagamento.filter(y => y.id == e)
        if (transferencia[0].id == 12) {
            this.setState({
                displayTFinanciamento: 'block',
            })
        } 
        if(transferencia[0].dados_bancarios == 1){
            this.setState({
                display: 'block',
                tipoPag: true,
                displayTParcela: 'none',
                tipoParcelamento: false
            })

        } else if(transferencia[0].parcelamento == 1){
            this.setState({
                displayTParcela: 'block',
                tipoParcelamento: true,
                tipoPag: false,
                display: 'none',
            })
        } else{
            this.setState({
                tipoPag: false,
                display: 'none',
                nConta: '',
                banco: '',
                agencia: '',
                tipoConta: '',
                displayTParcela: 'none',
                tipoParcelamento: false,
                tParcela: '',
                valorTParcela: ''
            }) 
        }
    }

    verificarTerceiro = (e) =>{
        this.setState({terceiro: e})

        if(e == 'garagem'){
            this.setState({
                modalCadastrarCepT: '',
                modalCadastrarLogradouroT: '',
                modalCadastrarCidadeT: '',
                modalCadastrarEstadoT: '',
                modalCadastrarNumeroT: '',
                modalCadastrarBairroT: '',
                modalCadastrarComplementoT: ''
            })
        }

        if(e != 'cliente'){
            this.setState({
                terceiroD: 'block',
                nomeT: '',
                cpfT: '',
                rgT: '',
                telefoneT: '',
            })
        } else{
            this.setState({
                terceiroD: 'none',
                nomeT: this.state.CLInomeT,
                cpfT: this.state.CLIcpfT,
                rgT: this.state.CLIrgT,
                telefoneT: this.state.CLItelefoneT,
            })
        }
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    openPdf = () =>{
        window.open('/pdfcompra/'+this.state.compraId+'/7', '_blank')

        let search = ''
        if (this.state.motoNome) {
            search = '?nomeVeiculo='+this.state.motoNome
        }
        window.open('/pdfcompra/'+this.state.compraId+'/9'+search, '_blank')

        this.props.history.push('/itens')
    }

    atualizarMovimentacao = async (f) =>{
        await this.listarMovimentacao();

        var mov = await this.state.movimentacoes.filter((mov) => {
            return mov.compra_id == this.state.compraId
        })

        let valor = 0;

        if(f){
            for(let i of mov){
                valor += i.valor;
            }
            this.setState({valorPagamento: this.state.valorPagamento - valor})
        }

        this.setState({movimentacoesTabela: mov})
    }

    atualizarMovimentacaoS = async (f) =>{
        await this.listarMovimentacao();

        var mov = await this.state.movimentacoes.filter((mov) => {
            return mov.compra_id == this.state.compraId
        })

        this.setState({movimentacoesTabela: mov})
    }

    corStatus = (status) =>{

        var status_repla = status.toLowerCase(); 

        return (
            <div className="d-flex w-100">
                <div class={"tag-status tag-" + status_repla}>
                </div>
            </div>
        )
        
    }

    repetirLancamento = async () =>{
        var date = new Date();

        var mes;
        var meses = [];
        var anos = 0;
        var dia = moment(this.state.dataVencimento).format('DD');
        var ano = parseInt(moment(this.state.dataVencimento).format('YYYY'));

        for (let i = 0; i < this.state.meses; i++){
            mes = parseInt(moment(this.state.dataVencimento).format('MM')) + i;

            if(mes%12 == 0){
                anos++;
                mes = `${ano + anos - 1}-12`
            } else{
                if(mes > 12){
                    mes = mes - (12 * anos)
                    mes = `${ano + anos}-${mes}`
                } else{
                    mes = `${ano + anos}-${mes}`
                }
            }

            meses.push(mes + '-' + dia)
    
        }

        this.setState({dataRepeticao: meses})

    }

    valorParcela = async (meses) =>{
        const {valor, repetirLancamento} = this.state

        if(repetirLancamento == 2){
            if(meses && valor){
                var real = valor.replace('.', '');
                real = real.replace(',', '.');
    
                this.setState({valorParcela: real / meses})
            }
        } else{
            this.setState({valorParcela: ''})
        }
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/financeiro/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
    }
    
    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.delete('/financeiro/delete/id/' + this.state.modalDeletarId);
            this.setState({valorPagamento: this.state.valorPagamento + this.state.valorDeletado})
            this.atualizarMovimentacaoS();
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/financeito/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Contas deletado com sucesso!' });
    }

    salvarObs = async () =>{
        if(this.state.obsPag){
            await api_topmotos.put('/compra/obs/' + this.state.compraId, {
                obs: this.state.obsPag
            });
        } 

        await this.openPdf();
    }

    toggleLoading = () =>{
        this.setState({ displayLoading: !this.state.displayLoading });
    }

    apiCadastrarDespesa = async () => {
        for(let i of this.state.despesas){
            try {
                await api_topmotos.post('/despesasItens/create', {
                    despesa: i.id,
                    item: this.state.idmoto,
                    valor: i.valor_padrao,
                    obs: ''
                });

            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }

        }
    }

    render() {
        return (
            <>
                <div className="overlay" style={{display: this.state.displayLoading ? 'flex' : 'none'}}>
                    <div className="modal__loading">
                        <img src={require("../../imgs/loading.gif")} alt=""/>
                        <p>Loading</p>
                    </div>
                </div>

                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />

                            <div className="questionario">
                                <p className="head">{this.state.titles[this.state.indexperg]}</p>
                                <div className="perguntas" id="primeira"  style={{display:"flex"}}>
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cpf" id="lblCpf">CPF Cliente *</label>
                                                    <div className="grupo-campo-input input-cad" id="cpf">
                                                        <i class="fas fa-id-card" id="iconCpf"></i>
                                                        <input
                                                            maxLength="18"
                                                            type="text"
                                                            placeholder="&nbsp;Informe o CPF ou CNPJ"
                                                            value={this.state.cpf}
                                                            onChange={a => {this.maskCnpjCpf(a.target.value); this.validarCPF(a);}}
                                                            // value={this.state.cpf}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p id="aviso">{this.state.aviso}</p>

                                        <div id="pessoa d-block">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="nome" id="lblNome">Nome *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="nome">
                                                            <i className="fas fa-user" id="iconNome"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Nome"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarNome}
                                                                onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="email" id="lblEmail">E-mail</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="email">
                                                            <i className="far fa-envelope" id="iconEmail"></i>
                                                            <input
                                                                type="email"
                                                                placeholder="&nbsp;Informe o E-mail"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarEmail}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.setState({ modalCadastrarEmail: event.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                            <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="celular" id="lblCelular">Celular *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="celular">
                                                            <i className="fas fa-phone" id="iconCelular"></i>
                                                            <InputMask
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Celular"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarTelefone}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.setState({ modalCadastrarTelefone: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="telefone" id="lblTelefone">Telefone</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="telefone">
                                                            <i className="fas fa-phone" id="iconTelefone"></i>
                                                            <InputMask
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Telefone"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarCelular}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.setState({ modalCadastrarCelular: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="rg" id="lblrg">RG</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="rg">
                                                            <i class="fas fa-id-card" id="iconRg"></i>
                                                            <InputMask
                                                                maxLength="15"
                                                                type="text"
                                                                placeholder="&nbsp;Informe o RG"
                                                                autoComplete="on"
                                                                value={this.state.rg}
                                                                onChange={a => {this.setState({rg: a.target.value})}}
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cep" id="lblCep">CEP *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cep">
                                                            <i className="fas fa-envelope" id="iconCep"></i>
                                                            <InputMask
                                                                mask="99999-999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                                autoComplete="on"
                                                                value={this.state.modalCadastrarCep}
                                                                disabled={!this.state.is_pessoa}
                                                                onChange={event => this.getCEP(event, "cadastrar")}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>       
                                                    
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="estado" id="lblEstado">Estado *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="estado">
                                                            <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                            <select
                                                                name="estado"
                                                                value={this.state.modalCadastrarEstado}
                                                                placeholder="&nbsp;Selecione o Estado de residência"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarEstado: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            >
                                                                <option value="NULL">Selecione o Estado de residência</option>
                                                                <option value='AC'>Acre</option>
                                                                <option value='AL'>Alagoas</option>
                                                                <option value='AP'>Amapá</option>
                                                                <option value='AM'>Amazonas</option>
                                                                <option value='BA'>Bahia</option>
                                                                <option value='CE'>Ceará</option>
                                                                <option value='DF'>Distrito Federal</option>
                                                                <option value='ES'>Espírito Santo</option>
                                                                <option value='GO'>Goiás</option>
                                                                <option value='MA'>Maranhão</option>
                                                                <option value='MT'>Mato Grosso</option>
                                                                <option value='MS'>Mato Grosso do Sul</option>
                                                                <option value='MG'>Minas Gerais</option>
                                                                <option value='PA'>Pará</option>
                                                                <option value='PB'>Paraíba</option>
                                                                <option value='PR'>Paraná</option>
                                                                <option value='PE'>Pernambuco</option>
                                                                <option value='PI'>Piauí</option>
                                                                <option value='RJ'>Rio de Janeiro</option>
                                                                <option value='RN'>Rio Grande do Norte</option>
                                                                <option value='RS'>Rio Grande do Sul</option>
                                                                <option value='RO'>Rondônia</option>
                                                                <option value='RR'>Roraima</option>
                                                                <option value='SC'>Santa Catarina</option>
                                                                <option value='SP'>São Paulo</option>
                                                                <option value='SE'>Sergipe</option>
                                                                <option value='TO'>Tocantins</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cidade" id="lblCidade">Cidade *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cidade">
                                                            <i className="fas fa-city" id="iconCidade"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarCidade}
                                                                placeholder="&nbsp;Informe a Cidade de residência"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarCidade: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>    
                                            </div>       

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="logradouro" id="lblLogradouro">Logradouro *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="logradouro">
                                                            <i className="fas fa-road" id="iconLogradouro"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarLogradouro}
                                                                placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarLogradouro: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="numero" id="lblNumero">Número *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="numero">
                                                            <i className="fas fa-home" id="iconNumero"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Número de Residência"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarNumero: event.target.value })}
                                                                required
                                                                value={this.state.modalCadastrarNumero}
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="bairro" id="lblBairro">Bairro *</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="bairro">
                                                            <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarBairro}
                                                                placeholder="&nbsp;Informe o Bairro"
                                                                autoComplete="on"
                                                                onChange={event => this.setState({ modalCadastrarBairro: event.target.value })}
                                                                required
                                                                disabled={!this.state.is_pessoa}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="complemento">Complemento</label>
                                                        <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="complemento">
                                                            <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                            <input
                                                                type="text"
                                                                value={this.state.modalCadastrarComplemento}
                                                                placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                                autoComplete="on"
                                                                disabled={!this.state.is_pessoa}
                                                                
                                                                onChange={event => this.setState({ modalCadastrarComplemento: event.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt">
                                        <button onClick={async () => {
                                            await this.apiCadastrarPessoa();
                                            this.verificarTerceiro("cliente");
                                        }}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="pla">Placa</label>
                                                    <div className="grupo-campo-input input-cad">
                                                        <i className="fa fa-car"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe a placa"
                                                            autoComplete="on"
                                                            value={this.state.placa}
                                                            onChange={event => {this.checkItem(event)}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="tipo" id="lblTipo">Tipo *</label>
                                                    <div className="grupo-campo-input input-cad" id="tipo">
                                                        <i className="fas fa-wrench" id="iconTipo"></i>
                                                        <select
                                                            name="nivel"
                                                            placeholder="&nbsp;Selecione o Tipo do item"
                                                            autoComplete="on"
                                                            onChange={this.changeModalCadastrarTipo}
                                                            required
                                                            value={this.state.modalCadastrarTipo}
                                                        >
                                                            <option value="NULL">Selecione o Tipo do item</option>
                                                            <option value="Moto">Motocicleta</option>
                                                            <option value="Motoneta">Motoneta</option>
                                                            <option value="Carro">Carro</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad" id="estilo">
                                                    <label htmlFor="estilo" id="lblEstilo">Estilo*</label>
                                                    <div className="grupo-campo-input input-cad" id="Iestilo">
                                                        <i class="fas fa-motorcycle" id="iconEstilo"></i>
                                                        <select name="" value={this.state.estilosSE} onChange={e => this.setState({ estilosSE: e.target.value, modalCadastrarEstilo: e.target.value})}>
                                                            <option value="NULL">Selecione um estilo</option>
                                                            {
                                                                this.state.estilos.map(e => (
                                                                    <option value={e.id}>{e.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad" id="">
                                                    <label htmlFor="marca" id="lblMarca">Marca *</label>
                                                    <div className="grupo-campo-input input-cad" id="marca">
                                                        <i className="fas fa-certificate" id="iconMarca"></i>
                                                        <select name="" value={this.state.marcaSE} id="imark" onChange={e => this.setState({ marcaSE: e.target.value, modalCadastrarMarca: e.target.selectedOptions[0].innerHTML, modalCadastrarModelo: null, modeloSE: '' })}>
                                                            <option value="NULL">Selecione uma marca</option>
                                                            {
                                                                this.state.marcas.map(e => (
                                                                    <option value={e.id}>{e.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="modelo" id="lblModelo">Modelo *</label>
                                                    <div className="grupo-campo-input input-cad" id="modelo">
                                                        <i className="fas fa-asterisk" id="iconModelo"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe o Modelo do item"
                                                            autoComplete="on"
                                                            onChange={event => this.configModelo(event.target.value)}
                                                            required
                                                            onFocus={a => this.setState({ clientFocus2: true })} 
                                                            onBlur={a => this.setState({ clientFocus2: false })}
                                                            value={this.state.modeloSE}
                                                            disabled={this.state.marcaSE == "NULL"} 

                                                        />
                                                    </div>
                                                    <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                        {
                                                            this.state.marcaSE != "NULL" ?
                                                                (this.state.modelosvei.filter(y => y.id_marca == this.state.marcaSE && y.nome.toLowerCase().includes(this.state.modeloSE.toLowerCase())).map(e => (
                                                                    <li onClick={a => this.setState({modeloSE: e.nome, modalCadastrarModelo: e.nome, modeloId: e.id})}>{e.nome}</li>
                                                                )))
                                                                : (null)
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>                       

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="ano" id="lblAno">Ano do modelo*</label>
                                                    <div className="grupo-campo-input input-cad" id="ano">
                                                        <i className="fas fa-calendar-alt" id="iconAno"></i>
                                                        <select name="" value={this.state.modalCadastrarAno} id="imark" onChange={e => this.setState({modalCadastrarAno: e.target.value})}>
                                                            <option value="NULL">Selecione o ano</option>
                                                            {
                                                                this.state.anos.filter((ano) => {
                                                                    return ano.id_modelo == this.state.modeloId }).map(e => (
                                                                    <option value={e.id}>{e.ano}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="anoF" id="lblAnoF">Ano de Fabricação*</label>
                                                    <div className="grupo-campo-input input-cad" id="anoF">
                                                        <i className="fas fa-calendar-alt" id="iconAnoF"></i>
                                                        <input
                                                            type="number"
                                                            min="1985"
                                                            value={this.state.anoF}
                                                            onChange={a => this.setState({anoF: a.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="km" id="lblKm">Quilometragem *</label>
                                                    <div className="grupo-campo-input input-cad" id="km">
                                                        <i className="fa fa-tachometer-alt" id="iconKm"></i>
                                                        <input
                                                            type="number"
                                                            placeholder="&nbsp;Informe a Quilometragem do item"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ modalCadastrarKm: event.target.value })}
                                                            required
                                                            value={this.state.modalCadastrarKm}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="dataCheg" id="lblDataCheg">Data da chegada a loja *</label>
                                                    <div className="grupo-campo-input input-cad" id="dataCheg">
                                                        <i className="fas fa-calendar-alt" id="iconDataCheg"></i>
                                                        <input
                                                            type="date"
                                                            autoComplete="on"
                                                            onChange={event => {this.setState({ modalCadastrarDataCheg: event.target.value })}}
                                                            required
                                                            value={this.state.modalCadastrarDataCheg}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="horaCheg" id="lblHoraCheg">Hora da chegada a loja *</label>
                                                    <div className="grupo-campo-input input-cad" id="horaCheg">
                                                        <i className="far fa-clock" id="iconHoraCheg"></i>
                                                        <input
                                                            type="time"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ modalCadastrarHoraCheg: event.target.value })}
                                                            required
                                                            value={this.state.modalCadastrarHoraCheg}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cor" id="lblCor">Cor *</label>
                                                    <div className="grupo-campo-input input-cad" id="cor">
                                                        <i className="fas fa-paint-roller" id="iconCor"></i>
                                                       
                                                        <select name="" value={this.state.coresSE} onChange={e => this.setState({ coresSE: e.target.value, modalCadastrarCor: e.target.value})}>
                                                            <option value="NULL">Selecione uma cor</option>
                                                            {
                                                                this.state.cores.map(e => (
                                                                    <option value={e.id}>{e.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="combustivel" id="lblCombustivel">Combustível *</label>
                                                    <div className="grupo-campo-input input-cad" id="combustivel">
                                                        <i className="fa fa-gas-pump" id="iconCombustivel"></i>
                                                        <select value={this.state.combustiveisSE} name="" onChange={e => this.setState({ combustiveisSE: e.target.value, modalCadastrarCombustivel: e.target.value})}>
                                                            <option value="NULL">Selecione um combustível</option>
                                                            {
                                                                this.state.combustiveis.map(e => (
                                                                    <option value={e.id}>{e.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="descricao">Descrição</label>
                                                    <div className="grupo-campo-input input-cad hightop" id="descricao">
                                                        <i className="fa fa-marker" id="iconDescricao"></i>
                                                        <textarea
                                                            type="text"
                                                            placeholder="&nbsp;Informe a Descricao do item"
                                                            autoComplete="on"
                                                            value={this.state.modalCadastrarDescricao}
                                                            onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad" id="">
                                                    <label htmlFor="empresa" id="lblEmpresa">Empresa *</label>
                                                    <div className="grupo-campo-input input-cad" id="empresa">
                                                        <i className="fas fa-building" id="iconEmpresa"></i>
                                                        <select name="" value={this.state.empresa} onChange={e => this.setState({ empresa: e.target.value})}>
                                                            <option value="NULL">Selecione uma empresa</option>
                                                            {
                                                                this.state.empresas.map(e => (
                                                                    <option value={e.empresa_id}>{e.empresa_nomefantasia}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad" id="">
                                                    <label htmlFor="comprador">Comprador *</label>
                                                    <div className="grupo-campo-input input-cad" id="comprador">
                                                        <i className="fa fa-hand-holding-usd" id="iconComprador"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe o Comprador do item"
                                                            autoComplete="on"
                                                            value={this.state.comprador_name} onFocus={a => this.setState({ clientFocus3: true })} onBlur={a => this.setState({ clientFocus3: false })} onChange={this.configComprador}
                                                        />
                                                    </div>
                                                    <ul className={this.state.clientFocus3 ? "listC activate" : "listC"}>
                                                        {
                                                            this.state.usuarios.filter(y => y.usuario_nome.toLowerCase().includes(this.state.comprador_name.toLowerCase())).map(e => (
                                                                <li onClick={a => this.setState({ comprador_name: e.usuario_nome, comprador: e.usuario_id })}>{e.usuario_nome}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="subtitle-form">Este veículo pertence a(o): </p>   
                                        <div className="opcoes">
                                            <div className="alternativa">
                                                <input type="radio" id="cliente" name="terceiro" value="cliente" onChange={e => this.verificarTerceiro(e.target.value)} checked={!this.state.terceiro || this.state.terceiro == 'cliente'}/>
                                                <label for={"cliente"}>
                                                    <div class="check"></div>
                                                    <p>Cliente</p>
                                                </label>
                                            </div>
                                            <div className="alternativa">
                                                <input type="radio" id="terceiro" name="terceiro" value="terceiro" onChange={e => this.verificarTerceiro(e.target.value)} checked={this.state.terceiro == 'terceiro'}/>
                                                <label for={"terceiro"}>
                                                    <div class="check"></div>
                                                    <p>Terceiro</p>
                                                </label>
                                            </div>
                                            <div className="alternativa">
                                                <input type="radio" id="garagem" name="terceiro" value="garagem" onChange={e => this.verificarTerceiro(e.target.value)} checked={this.state.terceiro == 'garagem'}/>
                                                <label for={"garagem"}>
                                                    <div class="check"></div>
                                                    <p>Garagem</p>
                                                </label>
                                            </div>
                                        </div> 

                                        <div>
        
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="motoNomeT" id="lblMotoNomeT">Nome da Moto (Procuração) *</label>
                                                        <div className="grupo-campo-input input-cad" id="motoNomeT">
                                                            <i className="fa fa-car" id="iconMotoNomeT"></i>
                                                            <input
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Nome da Moto"
                                                                autoComplete="on"
                                                                value={this.state.motoNome}
                                                                onChange={event => {this.setState({motoNome: event.target.value})}}
                                                        />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="especieT" id="lblEspecieT">Espécie/Tipo *</label>
                                                        <div className="grupo-campo-input input-cad" id="especieT">
                                                            <i className="fas fa-wrench" id="iconTipo"></i>
                                                            <select
                                                                name="nivel"
                                                                placeholder="&nbsp;Selecione a Espécie"
                                                                autoComplete="on"
                                                                onChange={(e) => this.setState({especie: e.target.value})}
                                                                required
                                                                value={this.state.especie}
                                                            >
                                                                <option value="">Selecione a Espécie da Moto</option>
                                                                {
                                                                    this.state.especies.map((e) => (
                                                                        <option value={e.id}>{e.nome}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>

                                        <div style={{display: this.state.terceiroD}}>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="nomeT" id="lblNomeT">Nome *</label>
                                                        <div className={this.state.terceiro == 'cliente' ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="nomeT">
                                                            <i className="fas fa-user" id="iconNomeT"></i>
                                                            <input
                                                                disabled={this.state.terceiro == 'cliente'}
                                                                type="text"
                                                                placeholder="&nbsp;Informe o Nome"
                                                                autoComplete="on"
                                                                value={this.state.nomeT}
                                                                onChange={event => this.setState({ nomeT: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="rg" id="lblrgT">RG</label>
                                                        <div className={this.state.terceiro == 'cliente' ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="rgT">
                                                            <i class="fas fa-id-card" id="iconRgT"></i>
                                                            <InputMask
                                                                disabled={this.state.terceiro == 'cliente'}
                                                                mask="99.999.999-9"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                maxLength="15"
                                                                type="text"
                                                                placeholder="&nbsp;Informe o RG"
                                                                autoComplete="on"
                                                                value={this.state.rgT}
                                                                onChange={a => {this.setState({rgT: a.target.value})}}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="cpfT" id="lblCpfT">CPF *</label>
                                                        <div className={this.state.terceiro == 'cliente' ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cpfT">
                                                            <i className="fas fa-id-card" id="iconCpfT"></i>
                                                            <input
                                                                disabled={this.state.terceiro == 'cliente'}
                                                                maxLength="18"
                                                                type="cpfT"
                                                                placeholder="&nbsp;Informe o CPF"
                                                                autoComplete="on"
                                                                value={this.state.cpfT}
                                                                onChange={event => {this.maskCnpjCpfT(event.target.value); this.validarCPFT(event)}}
                                                            />
                                                        </div>
                                                        <p>{this.state.mensagemCpf}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="telefoneT" id="lblTelefoneT">Telefone *</label>
                                                        <div className={this.state.terceiro == 'cliente' ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="telefoneT">
                                                            <i className="fas fa-phone" id="iconTelefoneT"></i>
                                                            <InputMask
                                                                disabled={this.state.terceiro == 'cliente'}
                                                                mask="(99) 99999-9999"
                                                                maskChar="_"
                                                                alwaysShowMask="true"
                                                                type="tel"
                                                                placeholder="&nbsp;Informe o Telefone"
                                                                autoComplete="on"
                                                                value={this.state.telefoneT}
                                                                onChange={event => this.setState({telefoneT: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div style={{display: this.state.terceiro == 'terceiro' ? "block" : "none"}}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="cepT" id="lblCepT">CEP *</label>
                                                            <div className="grupo-campo-input input-cad" id="cepT">
                                                                <i className="fas fa-envelope" id="iconCepT"></i>
                                                                <InputMask
                                                                    mask="99999-999"
                                                                    maskChar="_"
                                                                    alwaysShowMask="true"
                                                                    type="text"
                                                                    placeholder="&nbsp;Informe o Código Postal (CEP)"
                                                                    autoComplete="on"
                                                                    value={this.state.modalCadastrarCepT}
                                                                    onChange={event => this.getCEPT(event, "cadastrar")}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>       
                                                        
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="estadoT" id="lblEstadoT">Estado *</label>
                                                            <div className={"grupo-campo-input input-cad"} id="estadoT">
                                                                <i className="fas fa-map-marker-alt" id="iconEstadoT"></i>
                                                                <select
                                                                    name="estado"
                                                                    value={this.state.modalCadastrarEstadoT}
                                                                    placeholder="&nbsp;Selecione o Estado de residência"
                                                                    autoComplete="on"
                                                                    onChange={event => this.setState({ modalCadastrarEstadoT: event.target.value })}
                                                                    required
                                                                >
                                                                    <option value="NULL">Selecione o Estado de residência</option>
                                                                    <option value='AC'>Acre</option>
                                                                    <option value='AL'>Alagoas</option>
                                                                    <option value='AP'>Amapá</option>
                                                                    <option value='AM'>Amazonas</option>
                                                                    <option value='BA'>Bahia</option>
                                                                    <option value='CE'>Ceará</option>
                                                                    <option value='DF'>Distrito Federal</option>
                                                                    <option value='ES'>Espírito Santo</option>
                                                                    <option value='GO'>Goiás</option>
                                                                    <option value='MA'>Maranhão</option>
                                                                    <option value='MT'>Mato Grosso</option>
                                                                    <option value='MS'>Mato Grosso do Sul</option>
                                                                    <option value='MG'>Minas Gerais</option>
                                                                    <option value='PA'>Pará</option>
                                                                    <option value='PB'>Paraíba</option>
                                                                    <option value='PR'>Paraná</option>
                                                                    <option value='PE'>Pernambuco</option>
                                                                    <option value='PI'>Piauí</option>
                                                                    <option value='RJ'>Rio de Janeiro</option>
                                                                    <option value='RN'>Rio Grande do Norte</option>
                                                                    <option value='RS'>Rio Grande do Sul</option>
                                                                    <option value='RO'>Rondônia</option>
                                                                    <option value='RR'>Roraima</option>
                                                                    <option value='SC'>Santa Catarina</option>
                                                                    <option value='SP'>São Paulo</option>
                                                                    <option value='SE'>Sergipe</option>
                                                                    <option value='TO'>Tocantins</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="cidadeT" id="lblCidadeT">Cidade *</label>
                                                            <div className={"grupo-campo-input input-cad"} id="cidadeT">
                                                                <i className="fas fa-city" id="iconCidadeT"></i>
                                                                <input
                                                                    type="text"
                                                                    value={this.state.modalCadastrarCidadeT}
                                                                    placeholder="&nbsp;Informe a Cidade de residência"
                                                                    autoComplete="on"
                                                                    onChange={event => this.setState({ modalCadastrarCidadeT: event.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </div>       

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="logradouroT" id="lblLogradouroT">Logradouro *</label>
                                                            <div className={"grupo-campo-input input-cad"} id="logradouroT">
                                                                <i className="fas fa-road" id="iconLogradouroT"></i>
                                                                <input
                                                                    type="text"
                                                                    value={this.state.modalCadastrarLogradouroT}
                                                                    placeholder="&nbsp;Informe o Logradouro (Rua/Avenida)"
                                                                    autoComplete="on"
                                                                    onChange={event => this.setState({ modalCadastrarLogradouroT: event.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="numeroT" id="lblNumeroT">Número *</label>
                                                            <div className={"grupo-campo-input input-cad"} id="numeroT">
                                                                <i className="fas fa-home" id="iconNumeroT"></i>
                                                                <input
                                                                    type="text"
                                                                    placeholder="&nbsp;Informe o Número de Residência"
                                                                    autoComplete="on"
                                                                    onChange={event => this.setState({ modalCadastrarNumeroT: event.target.value })}
                                                                    required
                                                                    value={this.state.modalCadastrarNumeroT}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="bairroT" id="lblBairroT">Bairro *</label>
                                                            <div className={"grupo-campo-input input-cad"} id="bairroT">
                                                                <i className="fas fa-map-marked-alt" id="iconBairroT"></i>
                                                                <input
                                                                    type="text"
                                                                    value={this.state.modalCadastrarBairroT}
                                                                    placeholder="&nbsp;Informe o Bairro"
                                                                    autoComplete="on"
                                                                    onChange={event => this.setState({ modalCadastrarBairroT: event.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="grupo-input input-style-cad">
                                                            <label htmlFor="complementoT">Complemento</label>
                                                            <div className={"grupo-campo-input input-cad"} id="complementoT">
                                                                <i className="fas fa-asterisk" id="iconComplementoT"></i>
                                                                <input
                                                                    type="text"
                                                                    value={this.state.modalCadastrarComplementoT}
                                                                    placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.)"
                                                                    autoComplete="on"
                                                                    onChange={event => this.setState({ modalCadastrarComplementoT: event.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                    
                                        </div>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => { this.apiCadastrarItem(); }}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <p className="legenda">(*) Campos obrigatórios</p>
                                        {this.state.questoesV.map((questao, index) => (
                                            <div className="questao">
                                                <div className="enunciado" id={'quest'+ questao.id}>
                                                    <p>{index+1}.</p>
                                                    <p>{questao.pergunta}{questao.obrigatorio == 0 ? '*' : '' }</p>
                                                </div>
                                                <div className="resposta">
                                                    <input value={this.state.respostasPreenchidasVei[`1-${questao.id}`]} onChange={a => this.makeRelation(`1-${questao.id}`, a.target.value)} type="text" placeholder="Escreva aqui"/>
                                                </div>
                                            </div>
                                            ))
                                        }
                                        <textarea value={this.state.obsVei} onChange={a => this.setState({obsVei: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => { this.saveChecklist(this.state.respostasPreenchidasVei, this.state.obsVei, 1, this.state.idmoto, this.state.obrigatorio) }}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <p className="legenda">(*) Campos obrigatórios</p>
                                        {this.state.questoesD.map((questao, index) => (
                                            <div className="questao">
                                                <div className="enunciado">
                                                    <p>{index+1}.</p>
                                                    <p>{questao.pergunta}{questao.obrigatorio == 0 ? '*' : '' }</p>
                                                </div>
                                                <div className="opcao">
                                                    {this.state.opcao.filter((op) => {
                                                        return op.cod_check_list == questao.id }).map((alternativa) => {
                                                            return (
                                                                <>
                                                                    <div className="alternativa">
                                                                        <input type="radio" checked={this.state.respostasPreenchidasDoc[`2-${questao.id}`]['opt'] == alternativa.id} onChange={a => this.makeRelation(`2-${questao.id}`, alternativa.id, true)} name={"questao"+alternativa.cod_check_list} value={alternativa.id} id={"alternativa"+alternativa.id}/>
                                                                        <label for={"alternativa"+alternativa.id}>
                                                                            <div class="check"></div>
                                                                            {alternativa.alternativa}
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                        <div className="resposta resposta-obs">
                                                            <input value={this.state.respostasPreenchidasDoc[`2-${questao.id}`]['txt']} onChange={a => this.makeRelation(`2-${questao.id}`, a.target.value)} type="text" placeholder="Escreva aqui uma observação"/>
                                                        </div>
                                                </div>
                                            </div>
                                            ))
                                        }
                                        <textarea value={this.state.obsDoc} onChange={a => this.setState({obsDoc: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                    </div>
                                   <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => { this.saveChecklist(this.state.respostasPreenchidasDoc, this.state.obsDoc, 2, this.state.idmoto, this.state.obrigatorioD); this.fipe()}}>Próximo<i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="compra_valor" id="lblCompra_valor">Valor da compra *</label>
                                                    <div className="grupo-campo-input input-cad" id="compra_valor">
                                                        <i className="fas fa-dollar-sign" id="iconCompra_valor"></i>
                                                        <input onChange={event => this.mascaraCompra(event.target.value)} value={this.state.valorCompra} placeholder="&nbsp;Informe o valor total da compra" type="text"/>
                                                    </div>
                                                    <p className="valorFipe pt-2">{this.state.valorFipe}</p>
                                                </div>
                                            </div>
                                        
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="preco" id="lblPreco">Valor de venda</label>
                                                    <div className="grupo-campo-input input-cad" id="preco">
                                                        <i className="fa fa-dollar-sign" id="iconPreco"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe o Preço do item"
                                                            autoComplete="on"
                                                            onChange={event => this.mascaraVenda(event.target.value)} 
                                                            value={this.state.valorMoto}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="estoque">RENAVAM</label>
                                                    <div className="grupo-campo-input input-cad" id="havam">
                                                        <i className="fa fa-car" id="havam"></i>
                                                        <input
                                                            type="number"
                                                            placeholder="&nbsp;Informe o RENAVAM"
                                                            autoComplete="on"
                                                            value={this.state.renavam}
                                                            onChange={event => this.setState({ renavam: event.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="estoque">Chassi</label>
                                                    <div className="grupo-campo-input input-cad" id="cha">
                                                        <i className="fa fa-car" id="cha"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe o chassi"
                                                            autoComplete="on"
                                                            value={this.state.chassi}
                                                            onChange={event => this.setState({ chassi: event.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="prop">Propriedades</label>
                                                    <div className="grupo-campo-input input-cad" id="prop">
                                                        <i className="fas fa-paint-roller" id="iconprop"></i>
                                                        <select name="" value={this.state.propriedadesSE} onChange={e => this.setState({ propriedadesSE: e.target.value })}>
                                                            <option value={null}>Selecione uma propriedade</option>
                                                            {
                                                                this.state.propriedades.map(e => (
                                                                    <option value={e.id}>{e.descricao}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <div className="d-flex">
                                            <button onClick={this.recusarCompra} className="btn-descartar mr-2">Descartar</button>
                                            <button onClick={this.saveCompra}>Próximo<i class="fas fa-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="perguntas">
                                    <div>
                                        <div className="d-flex justify-content-between my-2">
                                            <div className="d-flex align-center line-separa">
                                                <div className="indicadores m-3 d-flex">
                                                    <i class="fas fa-dollar-sign" id="receber"></i>
                                                    <div>
                                                        <p>Total</p>
                                                        <p>{parseFloat(this.state.realCompra).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                                <div className="indicadores m-3 d-flex">
                                                    <i class="fas fa-dollar-sign" id="recebido"></i>
                                                    <div>
                                                        <p>Restante</p>
                                                        <p>{parseFloat(this.state.valorPagamento).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <button className="btn-add p-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar movimentação<i class="fas fa-plus"></i></button>
                                        </div>
                                        <div className="table mt-3">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Operação
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Descrição
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Data de Vencimento
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Valor
                                                                <i className="fas fa-sort"></i>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-center justify-between">
                                                                Ações
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.movimentacoesTabela.map((mov) => (
                                                            <tr key={mov.id}>
                                                                <td>{this.corStatus(mov.conta_status)}</td>
                                                                <td>{mov.descricao}</td>
                                                                <td>{moment(mov.dataVencimento).format('DD/MM/YYYY')}</td>
                                                                <td>{mov.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                <td className="td-acoes">
                                                                    <i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(mov.id); this.setState({valorDeletado: mov.valor})}}></i>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="grupo-input input-style-cad mt-4">
                                        <label htmlFor="obsPag" id="lblObsPag">Observação Forma de Pagamento</label>
                                        <textarea value={this.state.obsPag} onChange={a => this.setState({obsPag: a.target.value})} className="box-obs" placeholder="Escreva aqui observações"></textarea>
                                    </div>
                                    <div className="btn-prox-volt justify-content-between">
                                        <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                        <button onClick={() => {this.salvarObs()}}>Salvar</button>
                                    </div>
                                </div>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

                {/* Modal de Cadastro */}
                <Modal
					isOpen={this.state.modalCadastrar}
					toggle={this.toggleModalCadastrar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Conta</ModalHeader>
						<ModalBody>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblValorM">Valor *</label>
                                            <div className="grupo-campo-input input-cad" id="valorM">
                                            <i class="fas fa-dollar-sign" id="iconValorM"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o valor"
                                                    autoComplete="on"
                                                    value={this.state.valor}
                                                    onChange={event => this.mascaraReal(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad d-flex justify-content-start">
                                            <label htmlFor="" id="lblRepetirLancamento">Repetir Lançamento</label>
                                            <div className="obrigatorio">
                                                <input type="radio" name="repetirLancamento" value="2" id="parcela" onChange={event => {this.setState({repetirLancamento: event.target.value, meses: '', valorParcela: ''})}}/>
                                                <label htmlFor="parcela">Parcelamento</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{display: this.state.repetirLancamento ? 'block' : 'none'}}>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblParcelaM">Quantos meses? *</label>
                                            <div className="grupo-campo-input input-cad" id="parcelaM">
                                                <input
                                                    type="number"
                                                    autoComplete="on"
                                                    value={this.state.meses}
                                                    onChange={event => {this.setState({ meses: event.target.value }); this.valorParcela(event.target.value)}}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p id="valor-mensal" style={{display: this.state.valorParcela ? 'block' : 'none'}}>
                                            <i class="fas fa-info-circle"></i>
                                             {this.state.meses} vezes de {(this.state.valorParcela).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblTipoPagamento">Tipo de pagamento *</label>
                                            <div className="grupo-campo-input input-cad" id="tipoPagamento" >
                                                <i class="fas fa-dollar-sign" id="iconTipoPagamento"></i>
                                                <select onChange={event => {this.setState({ tipo: event.target.value}); this.verificarTransferencia(event.target.value)}}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.tipoPagamento.map((tipo) => (
                                                        <option value={tipo.id}>{tipo.tipo_pagamento}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblConta">Centro de Custos *</label>
                                            <div className="grupo-campo-input input-cad" id="conta">
                                                <i class="fas fa-dollar-sign" id="iconConta"></i>
                                                <select onChange={event => this.setState({ conta: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contas.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblVencimento">Data de vencimento *</label>
                                            <div className="grupo-campo-input input-cad" id="vencimento">
                                                <i class="far fa-calendar-alt" id="iconVencimento"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataVencimento: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label id="lblCategoria">Plano de Contas *</label>
                                            <div className="grupo-campo-input input-cad" id="categoria" >
                                                <i class="fas fa-dollar-sign" id="iconCategoria"></i>
                                                <select onChange={event => this.setState({ categoria: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    
                                                    {this.state.categorias.map((cat) => (
                                                        <>
                                                            <option className="select__group" value={cat.id}>{cat.categoria}</option>
                                                            {cat.options.map((op) => (
                                                                <option value={op.id}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {op.categoria}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ))}
                                                    
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Conta Bancária</label>
                                            <div className="grupo-campo-input input-cad" >
                                                <i class="fas fa-dollar-sign"></i>
                                                <select onChange={event => this.setState({ contaB: event.target.value })}>
                                                    <option value="">Selecione uma opção</option>
                                                    {this.state.contasBancarias.map((contas) => (
                                                        <option value={contas.id}>{contas.descricao}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label>Data de competência</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="far fa-calendar-alt"></i>
                                                <input
                                                    type="date"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ dataCompetencia: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: this.state.display}}>
                                    <p class="subtitle-form">Dados bancários</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblBanco">Banco *</label>
                                                <div className="grupo-campo-input input-cad" id="banco" >
                                                    <i class="fas fa-dollar-sign" id="iconBanco"></i>
                                                    <input 
                                                        onChange={event => this.setState({banco: event.target.value})} 
                                                        placeholder="&nbsp;Informe o Banco" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblAgencia">Agência *</label>
                                                <div className="grupo-campo-input input-cad" id="agencia" >
                                                    <i class="fas fa-dollar-sign" id="iconAgencia"></i>
                                                    <input 
                                                        onChange={event => this.setState({agencia: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblNConta">Conta *</label>
                                                <div className="grupo-campo-input input-cad" id="nconta" >
                                                    <i class="fas fa-dollar-sign" id="iconNConta"></i>
                                                    <input 
                                                        onChange={event => this.setState({nConta: event.target.value})} 
                                                        placeholder="&nbsp;Informe a Conta" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTipoConta">Tipo de conta *</label>
                                                <div className="grupo-campo-input input-cad" id="tipoConta" >
                                                    <i class="fas fa-dollar-sign" id="iconTipoConta"></i>
                                                    <select onChange={ e => this.setState({tipoConta: e.target.value})}>
                                                        <option value="">Selecione o tipo da conta</option>
                                                        <option value="Conta Corrente">Conta Corrente</option>
                                                        <option value="Conta Poupança">Conta Poupança</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div style={{display: this.state.displayTParcela}}>
                                    <p class="subtitle-form">Parcelamento</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTParcela">Quantas parcelas? *</label>
                                                <div className="grupo-campo-input input-cad" id="tParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconTParcela"></i>
                                                    <input 
                                                        onChange={event => this.setState({tParcela: event.target.value})} 
                                                        placeholder="&nbsp;Informe a quantidade de parcelas" 
                                                        min="0"
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblValorTParcela">Valor da Parcela*</label>
                                                <div className="grupo-campo-input input-cad" id="valorTParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconValorTParcela"></i>
                                                    <input 
                                                        onChange={event => this.mascaraParcelamento(event.target.value)} 
                                                        value={this.state.valorTParcela}
                                                        // placeholder="&nbsp;Informe a Agência" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: this.state.displayTFinanciamento}}>
                                    <p class="subtitle-form">Financiamento</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="grupo-input input-style-cad">
                                                <label id="lblTParcela">Banco Financiado *</label>
                                                <div className="grupo-campo-input input-cad" id="tParcela" >
                                                    <i class="fas fa-dollar-sign" id="iconTParcela"></i>
                                                    <input 
                                                        onChange={event => this.setState({tFinanciado: event.target.value})} 
                                                        placeholder="&nbsp;Informe a qual o financiador" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</ModalBody>
						<ModalFooter>
							<Button onClick={this.toggleModalCadastrar.bind(this)}>Fechar</Button>
                            <Button className="bg-laranja" onClick={this.saveMovimentacao}>Salvar</Button>
						</ModalFooter>
					</Modal>
            
                {/*Modal Deletar*/}
                <Modal
					isOpen={this.state.modalDeletar}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
                        <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                        <ModalBody>
                            Deseja realmente deletar a conta<br></br>(ID: {this.state.modalDeletarId})?
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.deletar} color="danger">Deletar</Button>
                            <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                
                {/* MODAL AVISO */}
                <Modal
                    isOpen={this.state.modalAviso}
                    toggle={this.toggleModalAviso.bind(this)}
                    backdrop={true}
                    keyboard={true}
                    size="sm"
                    fade={false} >
                    <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                    <ModalBody>
                        {this.state.modalAvisoText}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                
            </>
        )
    }
}

export default Page
