import React, { useRef, useEffect } from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import 'leaflet/dist/leaflet.css';
import { circle, Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { City } from 'country-state-city';

class MapaVendas extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			vendas: [],
			citys: [],
			marks: [],
		};
	}

	getVendasAPI = async () => {
		const res = await api_topmotos.get('/venda/mapa');

		//let data = res.data.filter(a => a.id == this.state.compra_id);

		this.setState({
			vendas: res.data,
		});
	}

	setMap = async () => {
		var vendas = this.state.vendas.map(element => {
			const city = this.state.citys.filter((city) => element.endereco_cidade.toLowerCase() === city.name.toLowerCase())[0];
			element.map = city;
			return element;
		}).filter((venda) => venda && venda.map !== undefined);

		this.setState({ marks: vendas })
	}

	async componentDidMount() {
		this.setState({ citys: City.getCitiesOfState("BR", "SP") })
		await this.getVendasAPI();
		this.setMap();
	}

	render() {
		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="relatorio-head">
							<MapContainer style={{ width: "100%", height: "500px" }}
								center={[-22.3154, -49.0615]}
								zoom={8}
								scrollWheelZoom={false}>
								<TileLayer
									attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{this.state.marks.map((mark) => (
									<Marker position={[mark.map.latitude, mark.map.longitude]} key={mark.map.name}
										icon={new Icon({
											iconUrl: markerIconPng,
											iconSize: [25, 41],
											iconAnchor: [12, 41]
										})}>
										<Popup keepInView={true}>
											<b>{mark.map.name}</b> <br /> {mark.quantidade} vendas.
										</Popup>
									</Marker>
								))}
							</MapContainer>
						</div>
						<div className="relatorio-footer">
						</div>
						<Versionamento />
					</div>
				</div>
			</section>
		)
	}
}

export default MapaVendas;