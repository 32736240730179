import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Empresas extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],

			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesRazaoSocial: '',
			modalDetalhesTelefone: '',
			modalDetalhesEmail: '',
			modalDetalhesFantasia: '',
			modalDetalhesCnpj: '',
			modalDetalhesAdmissaodata: '',
			modalDetalhesAtualizacaodata: '',
			modalDetalhesCep: '',
			modalDetalhesEstado: '',
			modalDetalhesCidade: '',
			modalDetalhesBairro: '',
			modalDetalhesLogradouro: '',
			modalDetalhesNumero: '',
			modalDetalhesComplemento: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarRazaoSocial: '',

			modalAvisoOpen: false,
			modalAvisoText: '',
		};
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/empresas")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
			this.apiListarEmpresas();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}


	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	apiListarEmpresas = async event => {
		let res = await api_topmotos.get('/empresas/');
		this.setState({ dados: res.data });
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idEmpresa) => {
		let resEmpresa = await api_topmotos.get('/empresas/id/' + idEmpresa);
		resEmpresa = resEmpresa.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resEmpresa.empresa_id,
			modalDetalhesRazaoSocial: resEmpresa.empresa_razaosocial,
			modalDetalhesTelefone: resEmpresa.empresa_telefone,
			modalDetalhesEmail: resEmpresa.empresa_email,
			modalDetalhesFantasia: resEmpresa.empresa_nomefantasia,
			modalDetalhesEndereco: resEmpresa.endereco_logradouro,
			modalDetalhesCnpj: resEmpresa.empresa_cnpj,
			modalDetalhesAdmissaodata: moment(resEmpresa.empresa_admissaodata).format('DD/MM/YY HH:mm'),
			modalDetalhesAtualizacaodata: resEmpresa.empresa_atualizacaodata ? moment(resEmpresa.empresa_atualizacaodata).format('DD/MM/YY HH:mm') : '',
			modalDetalhesCep: resEmpresa.endereco_logradouro,
			modalDetalhesEstado: resEmpresa.endereco_estado,
			modalDetalhesCidade: resEmpresa.endereco_cidade,
			modalDetalhesBairro: resEmpresa.endereco_bairro,
			modalDetalhesLogradouro: resEmpresa.endereco_logradouro,
			modalDetalhesNumero: resEmpresa.endereco_numero,
			modalDetalhesComplemento: resEmpresa.endereco_complemento,
		});
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (idEmpresa) => {
		let resEmpresa = await api_topmotos.get('/empresas/id/' + idEmpresa);
		resEmpresa = resEmpresa.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: resEmpresa.empresa_id,
			modalDeletarRazaoSocial: resEmpresa.empresa_razaosocial,
		});
	}

	apiDeletarUsuario = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/empresas/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/empresas/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Empresa deletado com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	render() {
		const { senhaVisivel } = this.state;

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Empresas</p>
							<div className="form-add" onClick={a => this.props.history.push("/cadastrarempresa")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table m-0">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Razão Social
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												CNPJ
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Fantasia
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Endereço
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Telefone
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												E-mail
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.state.dados.map((empresa, id) => (
											<tr key={empresa.empresa_id}>
												<td>{empresa.empresa_id}</td>
												<td>{empresa.empresa_razaosocial}</td>
												<td>{empresa.empresa_cnpj}</td>
												<td>{empresa.empresa_nomefantasia}</td>
												<td>{empresa.endereco_logradouro}</td>
												<td>{empresa.empresa_telefone}</td>
												<td>{empresa.empresa_email}</td>
												<td className="td-acoes">
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(empresa.empresa_id)}></i>&nbsp;
													<i className="fas fa-pen" onClick={() => this.props.history.push("/editarempresa/" + empresa.empresa_id)}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(empresa.empresa_id)}></i>
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
						</div>
						<div className="form-footer">
							<span></span>
						</div>
						<Versionamento/>
					</div>
				</div>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesRazaoSocial} - {this.state.modalDetalhesId}</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Razão Social</p>
								<p>{this.state.modalDetalhesRazaoSocial}</p>
							</div>
							<div className="conteudo-info">
								<p>CNPJ</p>
								<p>{this.state.modalDetalhesCnpj}</p>
							</div>
							<div className="conteudo-info">
								<p>Fantasia</p>
								<p>{this.state.modalDetalhesFantasia}</p>
							</div>
							<div className="conteudo-info">
								<p>E-mail</p>
								<p>{this.state.modalDetalhesEmail}</p>
							</div>
							<div className="conteudo-info">
								<p>Telefone</p>
								<p>{this.state.modalDetalhesTelefone}</p>
							</div>
							<div className="conteudo-info">
								<p>Data de Cadastro</p>
								<p>{this.state.modalDetalhesAdmissaodata}</p>
							</div>
							<div className="conteudo-info">
								<p>Última vez Atualizado</p>
								<p>{this.state.modalDetalhesAtualizacaodata}</p>
							</div>
							<div className="conteudo-info">
							</div>
							<div className="conteudo-info">
							</div>
							<div className="conteudo-info">
								<p>CEP</p>
								<p>{this.state.modalDetalhesCep}</p>
							</div>
							<div className="conteudo-info">
								<p>Estado</p>
								<p>{this.state.modalDetalhesEstado}</p>
							</div>
							<div className="conteudo-info">
								<p>Cidade</p>
								<p>{this.state.modalDetalhesCidade}</p>
							</div>
							<div className="conteudo-info">
								<p>Bairro</p>
								<p>{this.state.modalDetalhesBairro}</p>
							</div>
							<div className="conteudo-info">
								<p>Logradouro</p>
								<p>{this.state.modalDetalhesLogradouro}</p>
							</div>
							<div className="conteudo-info">
								<p>Número</p>
								<p>{this.state.modalDetalhesNumero}</p>
							</div>
							<div className="conteudo-info">
								<p>Complemento</p>
								<p>{this.state.modalDetalhesComplemento}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL DELETAR */}
				<Modal
					isOpen={this.state.modalDeletarOpen}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente deletar o usuário {this.state.modalDeletarRazaoSocial} (ID: {this.state.modalDeletarId})?
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.apiDeletarUsuario} color="danger">Deletar</Button>
						<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL AVISO */}
				<Modal
					isOpen={this.state.modalAvisoOpen}
					toggle={this.toggleModalAviso.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
					<ModalBody>
						{this.state.modalAvisoText}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
					</ModalFooter>
				</Modal>
			</section>

		)
	}
}

export default Empresas;