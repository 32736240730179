import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';


import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class UsuarioEditarPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
			modalCadastrarOpen: false,
			modalCadastrarNome: '',
			modalCadastrarTelefone: '',
            modalCadastrarEmail: '',
			modalCadastrarSenha: '',
			modalCadastrarNivel: '',
            senhaVisivel: false,
            empresas: [],
            empresaId: '',
            usuarios: [],
            email: '',
            error: '',
            errorMessage: '',
            emailExistente: false,

            porcentagemVenda: '',
            porcentagemConsorcio: '',

            modalCadastrar: false,
            errorAlterar: ''
        }

        this.apiListarUsuario(this.props.match.params.id)
    }


    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/usuarios")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.apiListarEmpresas();
            this.apiListarUsuarios();
        }
    }

    apiListarEmpresas = async event => {
        let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
    }

    apiListarUsuarios = async event => {
		let res = await api_topmotos.get('/usuarios/');
		this.setState({ usuarios: res.data });
	}

    apiListarUsuario = async e => {
        let {data} = await api_topmotos.get('/usuarios/id/' + e)

        this.setState({
            modalCadastrarEmail: data[0].usuario_email,
            email: data[0].usuario_email,
            modalCadastrarNome: data[0].usuario_nome,
            modalCadastrarTelefone: data[0].usuario_telefone,
            modalCadastrarNivel: data[0].usuario_nivel,
            empresaId: data[0].usuario_empresa,
            porcentagemConsorcio: data[0].usuario_comissao_consorcio,
            porcentagemVenda: data[0].usuario_comissao_venda
        })
    }

    log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
    }
    
    togglePassword = () => {
		var s = this.state.senhaVisivel;
		this.setState({ senhaVisivel: !s });
    }
    
    verificarEmail = (email) =>{
		this.setState({ modalCadastrarEmail: email })

		var usuario = this.state.usuarios.filter( y => y.usuario_email == email && email != this.state.email)

		if(usuario[0]){
			this.setState({ errorMessage: "Email já existente!", emailExistente: true});
			var inputId = document.getElementById('email');
			var inputIcone = document.getElementById('iconEmail');
			inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
			inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
		
		} else{
			this.setState({ errorMessage: "", emailExistente: false});
			var inputId = document.getElementById('email');
			var inputIcone = document.getElementById('iconEmail');
			inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
			inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
		}
    }
    
    apiCadastrarUsuario = async e => {
		e.preventDefault();

		const {
			modalCadastrarNome,
			modalCadastrarEmail,
			modalCadastrarTelefone,
            modalCadastrarNivel,
            empresaId
		} = this.state;

        var inputs = [
			[modalCadastrarNome, 'nome', 'iconNome', 'lblNome'],
			[modalCadastrarEmail, 'email', 'iconEmail', 'lblEmail'],
			[modalCadastrarTelefone, 'telefone', 'iconTelefone', 'lblTelefone'],
			[modalCadastrarNivel, 'nivel', 'iconNivel', 'lblNivel']
        ]
        
		if (!modalCadastrarNome ||
			!modalCadastrarEmail ||
			!modalCadastrarTelefone ||
			!modalCadastrarNivel ||
			modalCadastrarNivel === 'NULL') {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
            
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var ssInputNome = document.getElementById(inputs[i][1]);
                    var ssInputIconNome = document.getElementById(inputs[i][2]);
                    var ssInputLabel = document.getElementById(inputs[i][3]);
					ssInputNome.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                    ssInputIconNome.style.cssText = "color: #FF7115; transition: 0.2s;";
                    ssInputLabel.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var ssInputNome = document.getElementById(inputs[i][1]);
                    var ssInputIconNome = document.getElementById(inputs[i][2]);
                    var ssInputLabel = document.getElementById(inputs[i][3]);
					ssInputNome.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					ssInputIconNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                    ssInputLabel.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
			}	
            
		} else if (this.state.emailExistente){
			this.setState({ errorMessage: "Email já existente!"})
		} else {
            this.setState({ errorMessage: '' });

            for(var i = 0; i < inputs.length; i++){
				ssInputNome = document.getElementById(inputs[i][1]);
				ssInputIconNome = document.getElementById(inputs[i][2]);
				ssInputNome.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
				ssInputIconNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
			}
            
			try {
				await api_topmotos.put('/usuarios/update/id/' + this.props.match.params.id, {
					usuario_nome: modalCadastrarNome,
					usuario_email: modalCadastrarEmail,
					usuario_telefone: modalCadastrarTelefone,
                    usuario_nivel: modalCadastrarNivel,
                    usuario_empresa: empresaId,
                    comissao_consorcio: this.state.porcentagemConsorcio,
                    comissao_venda: this.state.porcentagemVenda,
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/usuarios/create)" });
				this.log(this.state.error);
			}

            this.props.history.push("/usuarios")
        }
    }

    apiAlterarSenha = async e =>{
        e.preventDefault();

        const {
            modalCadastrarSenha
        } = this.state

        if (!modalCadastrarSenha) {
			this.setState({ errorAlterar: "Preencha o campo!" });
        } else{
            try {
                await api_topmotos.put('/usuarios/update/senha/id/' + this.props.match.params.id, {
                    usuario_senha: modalCadastrarSenha
                })

                this.toggleModalCadastrar()
                this.props.history.push("/usuarios")
            } catch (err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/usuarios/update)" });
                this.log(this.state.error);
            }
        }

    }
    
    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar });
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar usuário</strong></p>

                            </div>
                            <div className="content-formulario">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nome" id="lblNome">Nome*</label>
                                            <div className="grupo-campo-input input-cad" id="nome">
                                                <i className="fas fa-user" id="iconNome"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Nome do usuário"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarNome}
                                                    onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="telefone" id="lblTelefone">Telefone*</label>
                                            <div className="grupo-campo-input input-cad" id="telefone">
                                                <i className="fas fa-phone" id="iconTelefone"></i>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="tel"
                                                    placeholder="&nbsp;Informe o Telefone do usuário"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarTelefone}
                                                    onChange={event => this.setState({ modalCadastrarTelefone: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="email" id="lblEmail">E-mail*</label>
                                            <div className="grupo-campo-input input-cad" id="email">
                                                <i className="far fa-envelope" id="iconEmail"></i>
                                                <input
                                                    type="email"
                                                    placeholder="&nbsp;Informe o E-mail do usuário"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarEmail}
                                                    onChange={event => this.verificarEmail(event.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-start">
                                        <div className="redefinir-senha">
										    <p onClick={this.toggleModalCadastrar}><i class="fas fa-user-lock pr-2"></i>Redefinir Senha</p>
                                        </div>
									</div>
                               </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nivel" id="lblNivel">Nível*</label>
                                            <div className="grupo-campo-input input-cad" id="nivel">
                                                <i className="fas fa-user-shield" id="iconNivel"></i>
                                                <select
                                                    name="nivel"
                                                    placeholder="&nbsp;Selecione o Nível do usuário"
                                                    autoComplete="on"
                                                    value={this.state.modalCadastrarNivel}
                                                    onChange={event => this.setState({ modalCadastrarNivel: event.target.value })}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o Nível do usuário</option>
                                                    <option value="Funcionário">Funcionário</option>
                                                    <option value="Gerente">Gerente</option>
                                                    <option value="Administrador">Administrador</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
										<div className="grupo-input input-style-cad">
											<label htmlFor="empresa">Empresa</label>
											<div className="grupo-campo-input input-cad" id="empresa">
												<i className="fas fa-user-edit" id="iconempresa"></i>
												<select
													name="empresa"
													placeholder="&nbsp;Selecione a empresa"
                                                    autoComplete="on"
                                                    value={this.state.empresaId}
													onChange={event => this.setState({ empresaId: event.target.value })}
													required
												>
													<option value="">Selecione a empresa</option>
													{
														this.state.empresas.map((empresa, id) => (
															<option key={empresa.empresa_id} value={empresa.empresa_id}>{empresa.empresa_nomefantasia}</option>
														))
													}
												</select>
											</div>
										</div>
									</div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="venda" id="lblVenda">Comissão por venda</label>
                                            <div className="grupo-campo-input input-cad" id="venda">
                                                <i className="fas fa-percent"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe a comissão por venda"
                                                    value={this.state.porcentagemVenda}
                                                    onChange={event => this.setState({ porcentagemVenda: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="consorcio" id="lblConsorcio">Comissão por Consórcio</label>
                                            <div className="grupo-campo-input input-cad" id="consorcio">
                                                <i className="fas fa-percent"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe a comissão por consórcio"
                                                    value={this.state.porcentagemConsorcio}
                                                    onChange={event => this.setState({ porcentagemConsorcio: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
   
                                
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarUsuario} className="cadbtn">Editar</Button>

                            </div>

                            {/* Cadastro */}
                            <Modal
                            isOpen={this.state.modalCadastrar}
                            toggle={this.toggleModalCadastrar.bind(this)}
                            backdrop={true}
                            keyboard={true}
                            size="md"
                            fade={false}>
                                <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Redefinir Senha</ModalHeader>
                                <ModalBody>
                                    <div className="content-formulario">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="senha">Senha</label>
                                                    <div className="grupo-campo-input input-cad" id="senha">
                                                        <i className="fas fa-lock" id="iconSenha"></i>
                                                        <input
                                                            type={(this.state.senhaVisivel) ? "text" : "password"}
                                                            placeholder="&nbsp;Informe a Senha do usuário"
                                                            autoComplete="on"
                                                            onChange={event => {this.setState({ modalCadastrarSenha: event.target.value }); this.setState({ errorAlterar: ''})}}
                                                            required
                                                        />
                                                        <i
                                                            className={`fa ${this.state.senhaVisivel ? "fa-eye-slash" : "fa-eye"}`}
                                                            id="iconOlho"
                                                            onClick={this.togglePassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" w-100 aviso-formulario aviso-formulario-err d-flex">
                                                <p className="aviso">{this.state.errorAlterar}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={this.toggleModalCadastrar}>Fechar</Button>
                                    <Button className="bg-laranja" onClick={this.apiAlterarSenha}>Salvar</Button>
                                </ModalFooter>
                            </Modal>
                            <Versionamento/>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default UsuarioEditarPage