import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { api_viacep } from '../../services/api';

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Fornecedores extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],

			pageSize: 1,

			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesNome: '',
			modalDetalhesTelefone: '',
			modalDetalhesEmail: '',
			modalDetalhesCnpj: '',
			modalDetalhesAdmissaodata: '',
			modalDetalhesAtualizacaodata: '',
			modalDetalhesCep: '',
			modalDetalhesEstado: '',
			modalDetalhesCidade: '',
			modalDetalhesBairro: '',
			modalDetalhesLogradouro: '',
			modalDetalhesNumero: '',
			modalDetalhesComplemento: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',

			pageLimit: 10,
			pageAtual: 0,
		};
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/fornecedores")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarFornecedores();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	getCEP = async (event, modal) => {
		let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');
		if (modal === 'editar') {
			this.setState({ modalDetalhesCep: event.target.value });
			this.setState({ modalEditarCep: event.target.value });
		} else if ('cadastrar') {
			this.setState({ modalCadastrarCep: event.target.value });
		}

		if (cep.length === 8) {
			let res = await api_viacep.get(`${cep}/json/`);

			if (modal === 'editar') {
				this.setState({
					modalEditarEstado: res['data'].uf,
					modalEditarCidade: res['data'].localidade,
					modalEditarBairro: res['data'].bairro,
					modalEditarLogradouro: res['data'].logradouro,
				})
			} else if ('cadastrar') {
				this.setState({
					modalCadastrarEstado: res['data'].uf,
					modalCadastrarCidade: res['data'].localidade,
					modalCadastrarBairro: res['data'].bairro,
					modalCadastrarLogradouro: res['data'].logradouro,
				})
			}
		}
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	apiListarFornecedores = async event => {
		let res = await api_topmotos.get('/fornecedores/');
		this.setState({ dados: res.data });
		this.setState({pageSize: Math.ceil(res.data.length / this.state.pageLimit)})
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idFornecedor) => {
		let resFornecedor = await api_topmotos.get('/fornecedores/id/' + idFornecedor);
		resFornecedor = resFornecedor.data[0];

		let idEndereco = resFornecedor.fornecedor_endereco;
		let resEndereco = await api_topmotos.get('/enderecos/id/' + idEndereco);
		resEndereco = resEndereco.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resFornecedor.fornecedor_id,
			modalDetalhesNome: resFornecedor.fornecedor_nome,
			modalDetalhesTelefone: resFornecedor.fornecedor_telefone,
			modalDetalhesEmail: resFornecedor.fornecedor_email,
			modalDetalhesCnpj: resFornecedor.fornecedor_cnpj,
			modalDetalhesAdmissaodata: resFornecedor.fornecedor_admissaodata,
			modalDetalhesAtualizacaodata: resFornecedor.fornecedor_atualizacaodata,
			modalDetalhesCep: resEndereco.endereco_cep,
			modalDetalhesEstado: resEndereco.endereco_estado,
			modalDetalhesCidade: resEndereco.endereco_cidade,
			modalDetalhesBairro: resEndereco.endereco_bairro,
			modalDetalhesLogradouro: resEndereco.endereco_logradouro,
			modalDetalhesNumero: resEndereco.endereco_numero,
			modalDetalhesComplemento: resEndereco.endereco_complemento,
			modalDetalhesDescricao: resFornecedor.fornecedor_descricao,
		});
	}

	toggleModalCadastrar = () => {
		this.setState({ modalCadastrarOpen: !this.state.modalCadastrarOpen });
	}

	modalCadastrar = () => {
		this.toggleModalCadastrar();
	}

	toggleModalEditar = () => {
		this.setState({ modalEditarOpen: !this.state.modalEditarOpen });
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id) => {
		let res = await api_topmotos.get('/fornecedores/id/' + id);
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: res.fornecedor_id,
			modalDeletarNome: res.fornecedor_nome,
		})
	}

	apiDeletarFornecedor = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/fornecedores/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/fornecedores/update)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Fornecedor deletado com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	/**
	 * @description Organiza a paginação e ativa um indice
	 */
	 putItem = (pageAtual, index) => {

		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item

		if (pageAtual + 1 == this.state.pageSize && index >= pageAtual - 4) return item

		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item

		if ((pageAtual + 2 == this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item

		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.state.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item

	}

	nextorprevious = (e, index) => {
		e.preventDefault();

		this.setState({ pageAtual: index })
	}

	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Fornecedores</p>
							<div className="form-add" onClick={a => this.props.history.push("/cadastrarfornecedor")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Telefone
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												CNPJ/CPF
													<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
												</div>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.state.dados.slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit).map((fornecedor, id) => (
											<tr key={fornecedor.fornecedor_id}>
												<td>{fornecedor.fornecedor_id}</td>
												<td>{fornecedor.fornecedor_nome}</td>
												<td>{fornecedor.fornecedor_telefone}</td>
												<td>{fornecedor.fornecedor_cnpj}</td>
												<td className="td-acoes">
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(fornecedor.fornecedor_id)}></i>&nbsp;
													<i className="fas fa-pen" onClick={() => this.props.history.push("/editarfornecedor/" + fornecedor.fornecedor_id)}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(fornecedor.fornecedor_id)}></i>
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
							<div className="form-footer">
								{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
									</div>

									{
										[...Array(this.state.pageSize)].map((e, index) => (
											this.putItem(this.state.pageAtual, index)
										))
									}

									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.state.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
									</div>
								</div>}
							</div>
						</div>

						{/* MODAL DETALHES */}
						<Modal
							isOpen={this.state.modalDetalhesOpen}
							toggle={this.toggleModalDetalhes.bind(this)}
							backdrop={true}
							keyboard={true}
							size="lg"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesNome} - {this.state.modalDetalhesId}</ModalHeader>
							<ModalBody>
								<div className="box-conteudo">
									<div className="conteudo-info">
										<p>Nome</p>
										<p>{this.state.modalDetalhesNome}</p>
									</div>
									<div className="conteudo-info">
										<p>Email</p>
										<p>{this.state.modalDetalhesEmail}</p>
									</div>
									<div className="conteudo-info">
										<p>Telefone</p>
										<p>{this.state.modalDetalhesTelefone}</p>
									</div>
									<div className="conteudo-info">
										<p>CNPJ/CPF</p>
										<p>{this.state.modalDetalhesCnpj}</p>
									</div>
									<div className="conteudo-info">
										<p>Data de Cadastro</p>
										<p>{this.formatTime(this.state.modalDetalhesAdmissaodata)}</p>
									</div>
									<div className="conteudo-info">
										<p>Ultima vez Atualizado</p>
										<p>{this.formatTime(this.state.modalDetalhesAtualizacaodata)}</p>
									</div>
									<div className="conteudo-info">
										<p>CEP</p>
										<p>{this.state.modalDetalhesCep}</p>
									</div>
									<div className="conteudo-info">
										<p>Estado</p>
										<p>{this.state.modalDetalhesEstado}</p>
									</div>
									<div className="conteudo-info">
										<p>Cidade</p>
										<p>{this.state.modalDetalhesCidade}</p>
									</div>
									<div className="conteudo-info">
										<p>Bairro</p>
										<p>{this.state.modalDetalhesBairro}</p>
									</div>
									<div className="conteudo-info">
										<p>Logradouro</p>
										<p>{this.state.modalDetalhesLogradouro}</p>
									</div>
									<div className="conteudo-info">
										<p>Número</p>
										<p>{this.state.modalDetalhesNumero}</p>
									</div>
									<div className="conteudo-info">
										<p>Complemento</p>
										<p>{this.state.modalDetalhesComplemento}</p>
									</div>
									<div className="conteudo-info">
										<p>Descrição</p>
										<p>{this.state.modalDetalhesDescricao}</p>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL DELETAR */}
						<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar o fornecedor {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarFornecedor} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

						<Versionamento />
					</div>
				</div>
			</section>

		)
	}
}

export default Fornecedores;
