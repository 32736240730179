import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class ChecklistDocPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pergunta: '',
            obrigatorio: '',
            alternativaTxt: '',
            id:'',
            idDoc: '',
            idAlt: '',
            checklist: [],
            alternativa: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',

            modalCadastrarAlt: false,
            modalAlterarAlt: false,
            ativo:''
        };
    }
    
    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/checklistDoc")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.listarChecklist();
            this.listarAlternativas();
        }
    }

    listarChecklist = async () =>{
        let res = await api_topmotos.get('/checklistD/');
        this.setState({ checklist: res.data });
    }

    listarAlternativas = async () =>{
        let res = await api_topmotos.get('/checklistA/');
        this.setState({ alternativa: res.data });
    }

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/checklistD/id/" + id)
        
        this.setState({
            id: data[0].id,
            pergunta: data[0].pergunta,
            obrigatorio: data[0].obrigatorio
        })

    }

    listarIdAlt = async (id) =>{
        let {data} = await api_topmotos.get("/checklistA/id/" + id)
        
        this.setState({
            idAlt: data[0].id,
            alternativaTxt: data[0].alternativa
        })
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] pergunta/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar, errorMessage: ''});
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar, errorMessage: ''});
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);

        await this.setState({modalAlterar: true})

    }

    apiCadastrarChecklist = async () => {
        var inputs = [
            [this.state.pergunta, 'pergunta','iconPergunta','lblPergunta'],
            [this.state.obrigatorio, 'lblObrigatoria','lblObrigatoria','lblObrigatoria']
        ]

        if(this.state.pergunta && this.state.obrigatorio) {
            try {
                await api_topmotos.post('/checklistD/create', {
                    pergunta: this.state.pergunta,
                    obrigatorio: this.state.obrigatorio
                });

                this.toggleModalCadastrar()
                this.listarChecklist()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarChecklist = async () => {
        var inputs = [
            [this.state.pergunta, 'pergunta','iconPergunta','lblPergunta']
        ]

        if(this.state.pergunta) {
            try {
                await api_topmotos.put('/checklistD/update/id/' + this.state.id, {
                    id: this.state.id,
                    pergunta: this.state.pergunta,
                    obrigatorio: this.state.obrigatorio
                })

                this.toggleModalAlterar()
                this.listarChecklist()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
                this.log(this.state.error);
            }
            
        } else {

            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    toggleAtivar = async (id) =>{
        var check = document.getElementById("check" + id);

        try {
            await api_topmotos.post('/checklistD/ativar/id/' + id, {
                id: this.state.id,
                check: check.checked == true ? 0 : 1
            })
    
        }catch(err) {
            this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/create)" });
            this.log(this.state.error);
        }
    }

    // Abrir Opções
    openToggle = (id) =>{
        var toggles = document.getElementsByClassName('toggleOptions');
        var toggleA = document.getElementById(id);
        
        if(toggleA.style.display == 'block'){
            toggleA.style.display = 'none'
        } else{
            for(var i = 0; i < toggles.length; i++){
                toggles[i].style.display = 'none';
            }

            toggleA.style.display = 'block'
        }

    }

    // VIEW SUB 
    viewSubCat =  (id) =>{
        var body = document.getElementsByName('categoria-body');
        var sub = document.getElementById(id)
        
        if(sub.style.display == 'block'){
            sub.style.display = 'none'
        } else{
            for(var i = 0; i < body.length; i++){
                body[i].style.display = 'none';
            }

            sub.style.display = 'block'
        }
        
    }

    // Off/On modal alternativa 
    toggleModalCadastrarAlt = () => {
		this.setState({ modalCadastrarAlt: !this.state.modalCadastrarAlt, errorMessage: ''});
    }

    // Off/On modal alternativa 
    toggleModalAlterarAlt = () => {
		this.setState({ modalAlterarAlt: !this.state.modalAlterarAlt, errorMessage: ''});
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    // Abrir modal deletar
    OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/checklistA/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
    }

    // Abrir modal alteração
    OpenModalAlteracaoAlt = async (id) => {
        await this.listarIdAlt(id);
        await this.setState({modalAlterarAlt: true})
    }

    OpenModalCadastrarAlt = (id) =>{
        this.setState({idDoc: id})
        this.toggleModalCadastrarAlt();
    }

    // Cadastrar Alternativa
    apiCadastrarAlternativa = async () => {

        var inputs = [
            [this.state.alternativaTxt, 'alternativa','iconAlternativa','lblAlternativa']
        ]

        if(this.state.alternativaTxt) {
            try {
                await api_topmotos.post('/checklistA/create', {
                    alternativa: this.state.alternativaTxt,
                    idDoc: this.state.idDoc,
                });

                this.toggleModalCadastrarAlt()
                this.listarAlternativas()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/alternativas/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    // Alterar Alternativa
    apiAlterarAlternativa = async () => {
        var inputs = [
            [this.state.alternativaTxt, 'alternativa','iconAlternativa','lblAlternativa']
        ]

        if(this.state.alternativaTxt) {
            try {
                await api_topmotos.put('/checklistA/update/id/' + this.state.idAlt, {
                    id: this.state.idAlt,
                    alternativa: this.state.alternativaTxt
                })

                this.toggleModalAlterarAlt()
                this.listarAlternativas()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/alternativas/create)" });
                this.log(this.state.error);
            }
            
        } else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.post('/checklistA/delete/id/' + this.state.modalDeletarId);
            this.listarAlternativas();
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/checklist/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Alternativa deletada com sucesso!' });
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Checklist Documentos</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Checklist<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.checklist.map((check) => (
                                    <div className="item-opcao">
                                        <div className="head">
                                            <div className="p-head" onClick={() => this.viewSubCat('sub'+check.id)}>
                                                <p className="pr-2 pergunta-p">{check.id}.</p>
                                                <p className="pergunta-p">{check.pergunta}</p>
                                                <p className="pergunta-p">{check.obrigatorio  == 0 ? '*' : ''}</p>
                                            </div>
                                            <div>
                                                <label className="switch mr-2">
                                                    <input type="checkbox" id={"check" + check.id} onClick={() => this.toggleAtivar(check.id)} defaultChecked={check.ativo == 0 ? true : false}/>
                                                    <span className="slider round"></span>
                                                </label>
                                                <i className="fas fa-ellipsis-h gray" onClick={() => this.openToggle("toggle" + check.id)}></i>
                                                <div className="toggleOptions" id={"toggle" + check.id}>
                                                    <p onClick={() => {this.OpenModalAlteracao(check.id); this.openToggle("toggle" + check.id)}}><i className='fas fa-pen'></i>Alterar</p>
                                                    <p onClick={() => {this.OpenModalCadastrarAlt(check.id); this.openToggle("toggle" + check.id)}}><i className='fas fa-plus'></i>Cadastrar Alternativa</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="item-body" id={'sub'+check.id}>
                                            {this.state.alternativa.filter((alt) => {
                                                return alt.cod_check_list == check.id }).map((altCheck) => {
                                                    return (
                                                        <>
                                                            <div className="alt-item">
                                                                <p>{altCheck.alternativa}</p>
                                                                <div className="d-flex pointer">
                                                                    <p className="p-2" onClick={() => this.OpenModalAlteracaoAlt(altCheck.id)}><i className='fas fa-pen'></i></p>
                                                                    <p onClick={() => {this.OpenModalDeletar(altCheck.id)}}><i className='fas fa-trash'></i></p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                            })} 
                                            {this.state.alternativa.filter((alt) => {
                                            return alt.cod_check_list == check.id }).length == 0 && "Não há alternativas"}
                                        </div>
                                    </div>
                                ))
                            }

                            {/* PERGUNTAS */}
                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="pergunta" id="lblPergunta">Pergunta</label>
                                                        <div className="grupo-campo-input input-cad" id="pergunta">
                                                            <i className="fas fa-pen" id="iconPergunta"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a pergunta"
                                                                onChange={event => this.setState({ pergunta: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="lblObrigatoria">Está pergunta é obrigatória?</label>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="0" id="s" onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="s">Sim</label>
                                                        </div>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="1" id="n" onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="n">Não</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarChecklist}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="pergunta" id="lblPergunta">Pergunta</label>
                                                        <div className="grupo-campo-input input-cad" id="pergunta">
                                                            <i className="fas fa-pen" id="iconPergunta"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a pergunta"
                                                                value={this.state.pergunta}
                                                                onChange={event => this.setState({ pergunta: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="" id="lblObrigatoria">Está pergunta é obrigatória?</label>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="0" id="s"  defaultChecked={this.state.obrigatorio == 0 ? true : false} onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="s">Sim</label>
                                                        </div>
                                                        <div className="obrigatorio">
                                                            <input type="radio" name="obrigatorio" value="1" id="n" defaultChecked={this.state.obrigatorio == 1 ? true : false} onChange={event => this.setState({obrigatorio: event.target.value})}/>
                                                            <label htmlFor="n">Não</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarChecklist}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* ALTERNATIVAS */}
                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrarAlt}
                                toggle={this.toggleModalCadastrarAlt.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrarAlt.bind(this)}>Adicionar Alternativa</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="alternativa" id="lblAlternativa">Alternativa</label>
                                                        <div className="grupo-campo-input input-cad" id="alternativa">
                                                            <i className="fas fa-pen" id="iconAlternativa"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a alternativa"
                                                                onChange={event => this.setState({ alternativaTxt: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalCadastrarAlt}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarAlternativa}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterarAlt}
                                toggle={this.toggleModalAlterarAlt.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterarAlt.bind(this)}>Alterar Checklist</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="alternativa" id="lblAlternativa">Alternativa</label>
                                                        <div className="grupo-campo-input input-cad" id="alternativa">
                                                            <i className="fas fa-pen" id="iconAlternativa"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Descreva a alternativa"
                                                                value={this.state.alternativaTxt}
                                                                onChange={event => this.setState({ alternativaTxt: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterarAlt.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarAlternativa}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/*Modal Deletar*/}
                            <Modal
                            isOpen={this.state.modalDeletar}
                            toggle={this.toggleModalDeletar.bind(this)}
                            backdrop={true}
                            keyboard={true}
                            size="sm"
                            fade={false} >
                                <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                                <ModalBody>
                                    Deseja realmente deletar a Alternativa<br></br>(ID: {this.state.modalDeletarId})?
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={this.deletar} color="danger">Deletar</Button>
                                    <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                                </ModalFooter>
                            </Modal>
                            
                            {/* MODAL AVISO */}
                            <Modal
                                isOpen={this.state.modalAviso}
                                toggle={this.toggleModalAviso.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="sm"
                                fade={false} >
                                <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                                <ModalBody>
                                    {this.state.modalAvisoText}
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                                </ModalFooter>
                            </Modal>
                
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ChecklistDocPage