import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Itens extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],
			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesMarca: '',
			modalDetalhesModelo: '',
			modalDetalhesAno: 0,
			modalDetalhesKm: 0.0,
			modalDetalhesCor: '',
			modalDetalhesCombustivel: '',
			modalDetalhesPreco: 0.0,
			modalDetalhesDescricao: '',
			modalDetalhesEstoque: 0,
			modalDetalhesFornecedor: 0,
			modalDetalhesTipo: '',
			modalDetalhesAdmissaodata: '',
			modalDetalhesAtualizacaodata: '',
			modalDetalhesPropriedade: '',
			modalDetalhesObservacao: '',
			modalDetalhesPlaca: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',
			status: [],
			sid: 1,
			permitir: ''
		};

		this.verificarPermissao();
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			
            res = res.data.filter(a => a.rota == "/itens")
            if(res.length == 0){
                this.props.history.push('/dashboardcomercial')
            }
        } else{
            this.props.history.push('/dashboardcomercial')
        }
    }

	async componentDidMount() {
		this.apiListarItens();
		this.apiListarStatus();
    this.apiListarPermissao();
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	apiListarItens = async event => {
		let res = await api_topmotos.get('/itens/');
		
		for(let i of res.data){
			i.ano == 3200 ? i.ano = 'Zero Km' : i.ano = i.ano
		}

		for(let i of res.data){
			if(i.itemfotos_foto){
				i.itemfotos_foto = await this.apiListarCapa(i.item_id, i.itemfotos_foto)
			}
		}

		this.setState({ dados: res.data });

	}

	apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
		res = res.data.filter(a => a.restricao_id == 2);

		this.setState({permitir: res.length > 0 ? true : false})
    }

	apiListarStatus = async event => {
		let res = await api_topmotos.get('/status/');
		this.setState({ status: res.data });
	}

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idItem) => {
		let resItem = await api_topmotos.get('/itens/id/' + idItem);
		resItem = resItem.data.item[0];

		let resFornecedor = await api_topmotos.get('/pessoas/id/' + resItem.item_fornecedor);
		resFornecedor = resFornecedor.data[0];

		let resCor = await api_topmotos.get('/cores/id/' + resItem.cor_id);
		resCor = resCor.data[0];

		let resCombustivel = await api_topmotos.get('/combustivel/id/' + resItem.combustivel_id);
		resCombustivel = resCombustivel.data[0];

		let resPropriedade = await api_topmotos.get('/propriedades/id/' + resItem.prop_id);
		resPropriedade = resPropriedade.data[0];

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resItem.item_id,
			modalDetalhesMarca: resItem.item_marca,
			modalDetalhesModelo: resItem.item_modelo,
			modalDetalhesAno: resItem.ano_mod,
			modalDetalhesAnoF: resItem.item_ano_fab,
			modalDetalhesKm: resItem.item_km,
			modalDetalhesPreco: resItem.item_preco,
			modalDetalhesDescricao: resItem.item_descricao,
			modalDetalhesEstoque: resItem.item_estoque,
			modalDetalhesFornecedor: resFornecedor.pessoa_nome,
			modalDetalhesTipo: resItem.item_tipo,
			modalDetalhesAdmissaodata: moment(resItem.item_admissaodata).format("DD/MM/YYYY HH:mm"),
			modalDetalhesAtualizacaodata: resItem.item_atualizacaodata ? moment(resItem.item_atualizacaodata).format("DD/MM/YYYY HH:mm") : '',
			modalDetalhesCor: resCor.nome,
			modalDetalhesCombustivel: resCombustivel.nome,
			modalDetalhesPropriedade: resPropriedade? resPropriedade.descricao : '',
			modalDetalhesObservacao: resItem.observacao,
			modalDetalhesPlaca: resItem.placa,
			modalDetalhesDataCheg: resItem.data_chegada ? moment(resItem.data_chegada).format("DD/MM/YYYY") : '',
			modalDetalhesHoraCheg: resItem.hora_chegada ? resItem.hora_chegada : ''
		});
	}


	apiEditarItem = async e => {
		e.preventDefault();

		const {
			modalEditarId,
			modalEditarMarca,
			modalEditarModelo,
			modalEditarAno,
			modalEditarKm,
			modalEditarCor,
			modalEditarCombustivel,
			modalEditarPreco,
			modalEditarDescricao,
			modalEditarEstoque,
			modalEditarFornecedor,
			modalEditarTipo,
			modalEditarAdmissaodata,
		} = this.state;

		if (!modalEditarMarca ||
			!modalEditarModelo ||
			!modalEditarAno ||
			!modalEditarKm ||
			!modalEditarCor ||
			!modalEditarCombustivel ||
			!modalEditarFornecedor ||
			!modalEditarTipo ||
			!modalEditarTipo === 'NULL' ||
			!modalEditarAno === '9999') {
			this.setState({ error: "preencha todos os dados para cadastrar!" });
			this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
			this.log(this.state.error);

			var aviso = document.getElementById('aviso');
			aviso.style.cssText = "color: #FF0000; transition: 0.2s;";

		} else {
			this.setState({ errorMessage: '' });

			try {
				await api_topmotos.put('/itens/update/id/' + modalEditarId, {
					item_marca: modalEditarMarca,
					item_modelo: modalEditarModelo,
					item_ano: modalEditarAno,
					item_km: modalEditarKm,
					item_cor: modalEditarCor,
					item_combustivel: modalEditarCombustivel,
					item_preco: modalEditarPreco,
					item_descricao: modalEditarDescricao,
					item_estoque: modalEditarEstoque,
					item_fornecedor: modalEditarFornecedor,
					item_tipo: modalEditarTipo,
					item_admissaodata: modalEditarAdmissaodata,
					item_atualizacaodata: this.getTimestamp(),
				})
			} catch (err) {
				this.setState({ error: "ocorreu um erro ao conectar com a API! (/itens/update)" });
				this.log(this.state.error);
			}

			this.setState({ modalAvisoOpen: true });
			this.setState({ modalAvisoText: 'Item atualizado com sucesso!' });
		}
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id) => {
		let res = await api_topmotos.get('/itens/id/' + id);
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: res.item_id,
			modalDeletarNome: res.item_nome,
		});
	}

	apiDeletarItem = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/itens/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/itens/update)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Item deletado com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	apiListarCapa = async (item, foto) => {
		let res = await api_topmotos.post('/itens/capa/unique/' + item);

        if(res.data.length > 0){
            return(res.data[0].foto)
        } else{
			return(foto ? foto  : '')
		}
    }

	render() {

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="mininav">
							<div className="form-add ntl" onClick={a => this.props.history.push("/questionario")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
							<select className="pure" onChange={a => this.setState({ sid: a.target.value })}>
								{
									this.state.status.map(x => (
										<option value={x.id}>{x.status}</option>
									))
								}
							</select>
						</div>
						<br />
						<div className="gridviewer">
							{
								this.state.dados.filter(y => y.status == this.state.sid && y.compra == 1 &&  y.empresa_id == localStorage.getItem('usuario_empresa')).map((item, id) => (
									<div className="viewiten">
										<div className="imgwrapper" style={{backgroundImage:`url("${item.itemfotos_foto ? item.itemfotos_foto : require('../../imgs/sFoto.png')}")`}}>
										</div>					
										<div className="p-3 gridinfo">
											<span className="badgeman badge">{item.statusName}</span>
											<span></span>
											<span className="orange">
												{item.item_modelo}
											</span>
											<span className=" gr">
												{item.placa}
											</span>
											<span className="tl gr">
												{item.ano_mod}
											</span>
											<span>
												{item.item_preco? item.item_preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}
											</span>
											<span className="link-ver tl" onClick={() => this.modalDetalhes(item.item_id)}>ver mais</span>
										</div>
									</div>

								))

							}
						</div>

						{/* MODAL DETALHES */}
						<Modal
							isOpen={this.state.modalDetalhesOpen}
							toggle={this.toggleModalDetalhes.bind(this)}
							backdrop={true}
							keyboard={true}
							size="lg"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesModelo}</ModalHeader>
							<ModalBody>
								<div className="box-conteudo">
									<div className="conteudo-info">
										<p>Tipo</p>
										<p>{this.state.modalDetalhesTipo}</p>
									</div>
									<div className="conteudo-info">
										<p>Marca</p>
										<p>{this.state.modalDetalhesMarca}</p>
									</div>
									<div className="conteudo-info">
										<p>Modelo</p>
										<p>{this.state.modalDetalhesModelo}</p>
									</div>
									<div className="conteudo-info">
										<p>Ano Modelo</p>
										<p>{this.state.modalDetalhesAno}</p>
									</div>
									<div className="conteudo-info">
										<p>Ano Fabricação</p>
										<p>{this.state.modalDetalhesAnoF}</p>
									</div>
									<div className="conteudo-info">
										<p>Quilometragem</p>
										<p>{this.state.modalDetalhesKm}</p>
									</div>
									<div className="conteudo-info">
										<p>Cor</p>
										<p>{this.state.modalDetalhesCor}</p>
									</div>
									<div className="conteudo-info">
										<p>Combustível</p>
										<p>{this.state.modalDetalhesCombustivel}</p>
									</div>
									<div className="conteudo-info">
										<p>Preço</p>
										<p>{this.state.modalDetalhesPreco ? this.state.modalDetalhesPreco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</p>
									</div>
									<div className="conteudo-info">
										<p>Descrição</p>
										<p>{this.state.modalDetalhesDescricao}</p>
									</div>
									<div className="conteudo-info">
										<p>Observação</p>
										<p>{this.state.modalDetalhesObservacao}</p>
									</div>
									<div className="conteudo-info">
										<p>Itens em Estoque</p>
										<p>{this.state.modalDetalhesEstoque}</p>
									</div>
									<div className="conteudo-info">
										<p>Propriedade</p>
										<p>{this.state.modalDetalhesPropriedade}</p>
									</div>
									<div className="conteudo-info">
										<p>Fornecedor</p>
										<p>{this.state.modalDetalhesFornecedor}</p>
									</div>
									<div className="conteudo-info">
										<p>Placa</p>
										<p>{this.state.modalDetalhesPlaca}</p>
									</div>
									<div className="conteudo-info">
										<p>Data de Cadastro</p>
										<p>{this.state.modalDetalhesAdmissaodata}</p>
									</div>
									<div className="conteudo-info">
										<p>Ultima Vez Atualizado</p>
										<p>{this.state.modalDetalhesAtualizacaodata}</p>
									</div>
									{this.state.modalDetalhesDataCheg ? 
									<div className="conteudo-info">
										<p>Data de Chegada</p>
										<p>{this.state.modalDetalhesDataCheg}</p>
									</div> : ''}
									{this.state.modalDetalhesHoraCheg ?
									<div className="conteudo-info">
										<p>Hora de Chegada</p>
										<p>{this.state.modalDetalhesHoraCheg}</p>
									</div> : '' }
								</div>
							</ModalBody>
							<ModalFooter>
								{/* <Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>	 */}
								{this.state.permitir ? <Button onClick={a => this.props.history.push("/editaritens/" + this.state.modalDetalhesId)} className="btnora">Editar</Button> : ''}
							</ModalFooter>
						</Modal>

						{/* MODAL DELETAR */}
						<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar o item {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarItem} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>

						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>
					
						<Versionamento/>
					</div>
				</div>
			</section>

		)
	}
}

export default Itens;
