import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import InputMask from 'react-input-mask';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

class ItemCadastrarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dados: [],
            modalCadastrarOpen: false,
            modalCadastrarMarca: '',
            modalCadastrarModelo: '',
            modalCadastrarAno: 0,
            modalCadastrarKm: 0.0,
            modalCadastrarCor: '',
            modalCadastrarCombustivel: '',
            modalCadastrarPreco: 0.0,
            modalCadastrarDescricao: '',
            modalCadastrarObservacao: '',
            modalCadastrarEstoque: 0,
            modalCadastrarFornecedor: 0,
            modalCadastrarTipo: '',
            modalCadastrarEstilo: '',

            forname: '',
            clientFocus: false,
            imgsFile: [],
            imgsView: [],
            idIten: 0,
            consig: false,
            chassi: '',
            renavam: '',
            openmodal: false,
            
            yesp: false,
            modelos: [],
            modelSE: 'NULL',
            itpdf: null,
            tipo_i: '',
            placa: '',

            marcaSE: 'NULL',
            clientFocus2: false,
            modelosvei: [],
            marcas: [],
            modeloSE: '',

            cores: [],
            coresSE: 'NULL',

            combustiveis: [],
            combustiveisSE: 'NULL',

            estilos: [],
            estilosSE: 'NULL',

            propriedades: [],
            propriedadesSE: 'NULL',
        }
    }

    componentDidMount() {
        this.apiListarFornecedores();
        this.apiListarModelosDoc();
        this.apiListarModelos();
        this.apiListarMarcas();
        this.apiListarCores();
        this.apiListarCombustivel();
        this.apiListarEstilos();
        this.apiListarPropriedades();
    }

    apiListarMarcas = async event => {
        let res = await api_topmotos.get('/marcas/');
        this.setState({ marcas: res.data });
    }

    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    apiListarFornecedores = async event => {
        let res = await api_topmotos.get('/fornecedores/');
        this.setState({ dados: res.data });
    }

    apiListarModelosDoc = async event => {
        let res = await api_topmotos.get('/modelosdoc/pro');
        this.setState({ modelos: res.data });
    }

    apiListarCores = async event =>{
        let res = await api_topmotos.get('/cores/');
        this.setState({ cores: res.data });
    }
    
    apiListarCombustivel = async event =>{
        let res = await api_topmotos.get('/combustivel/');
        this.setState({ combustiveis: res.data });
    }
    
    apiListarEstilos = async event =>{
        let res = await api_topmotos.get('/estilo/');
        this.setState({ estilos: res.data });
    }

    apiListarPropriedades = async event =>{
        let res = await api_topmotos.get('/propriedades/');
        this.setState({ propriedades: res.data });
    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    apiCadastrarItem = async e => {
        e.preventDefault();

        const {
            modalCadastrarModelo,
            modalCadastrarMarca,
            modalCadastrarAno,
            modalCadastrarKm,
            modalCadastrarCor,
            modalCadastrarCombustivel,
            modalCadastrarPreco,
            modalCadastrarDescricao,
            modalCadastrarEstoque,
            modalCadastrarFornecedor,
            modalCadastrarTipo,
            modalCadastrarEstilo,
        } = this.state;

        if (!modalCadastrarModelo ||
            !modalCadastrarMarca ||
            !modalCadastrarAno ||
            !modalCadastrarKm ||
            !modalCadastrarCor ||
            !modalCadastrarCombustivel ||
            !modalCadastrarFornecedor ||
            !modalCadastrarTipo ||
            modalCadastrarTipo === 'NULL' ||
            this.state.marcaSE === "NULL" ||
            this.state.coresSE === "NULL" ||
            this.state.combustiveisSE === "NULL" ||
            modalCadastrarAno === '____') {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);


        } else {
            this.setState({ errorMessage: '' });

            let re;
            try {
                re = await api_topmotos.post('/itens/create', {
                    item_modelo: modalCadastrarModelo,
                    item_marca: modalCadastrarMarca,
                    item_ano: modalCadastrarAno,
                    item_km: modalCadastrarKm,
                    cor_id: modalCadastrarCor,
                    combustivel_id: modalCadastrarCombustivel,
                    item_preco: modalCadastrarPreco,
                    item_descricao: modalCadastrarDescricao,
                    item_fornecedor: modalCadastrarFornecedor,
                    item_tipo: modalCadastrarTipo,
                    item_admissaodata: this.getTimestamp(),
                    item_atualizacaodata: null,
                    chassi: this.state.chassi,
                    renavam: this.state.renavam,
                    placa: this.state.placa,
                    estilo_id: modalCadastrarEstilo,
                    prop_id: this.state.propriedadesSE ? this.state.propriedadesSE : null,
                    observacao: this.state.modalCadastrarObservacao ? this.state.modalCadastrarObservacao : ' ',

                })
            } catch (err) {
                this.setState({ error: "Ocorreu um erro ao conectar com a API! (/itens/create)" })
                this.log(this.state.error);
            }

            if (re && this.state.imgsFile.length > 0) {
                for (let i of this.state.imgsFile) {
                    let form = new FormData()
                    form.append("id", re.data.returnId)
                    form.append("img", i)

                    await api_topmotos.post("/itens/foto", form);
                }
            }

            if (this.state.consig) {
                this.setState({ itpdf: re.data.returnId, openmodal: true })
            }
            else {
                this.props.history.push("/itens")
            }
        }
    }

    changeModalCadastrarTipo = (event) => {
        this.setState({ modalCadastrarTipo: event.target.value });
        this.abrirEstilo(event.target.value);
    }

    changeModelo = (event) =>{

    }

    abrirEstilo = (tipo) =>{
        var estilo = document.getElementById('estilo');

        if(tipo != 'Moto'){
            estilo.style.display = "none";
            this.setState({modalCadastrarEstilo: null});
        } else{
            estilo.style.display = "block";
        }
        
    }

    configAutoComplete = async (e) => {
        this.setState({ forname: e.target.value });

        if (e.target.value.length == 0) {
            this.setState({ modalCadastrarFornecedor: null })
            return
        }

        let c = this.state.dados.filter(y => y.fornecedor_nome.toLowerCase().includes(e.target.value.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarFornecedor: c[0].fornecedor_id })
        }
        else {
            this.setState({ modalCadastrarFornecedor: null })
        }
    }

    renderImgs = (e) => {
        this.setState({ imgsFile: Array.from(e.target.files) })
        let self = this;

        for (let i of e.target.files) {

            let render = new FileReader();

            render.onload = (s) => {
                self.setState({ imgsView: self.state.imgsView.concat(s.target.result) })
            }

            render.readAsDataURL(i);
        }
    }

    removeimg = async (index) => {
        let ft = this.state.imgsView;
        let ftfiles = this.state.imgsFile;
        ft.splice(index, 1);
        ftfiles.splice(index, 1);

        this.setState({ imgsView: ft, imgsFile: ftfiles })
    }

    configModelo = async (e) => {
        this.setState({ modeloSE: e });

        if (e.length == 0) {
            this.setState({ modalCadastrarModelo: null })
            return
        }

        let c = this.state.modelosvei.filter(y => y.modelo.toLowerCase().includes(e.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarModelo: c[0].modelo })
        }
        else {
            this.setState({ modalCadastrarModelo: e})
        }
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.openmodal}>
                    <ModalHeader>
                        Imprimir procuração
                    </ModalHeader>
                    {
                        !this.state.yesp ? (
                            <>
                                <ModalBody>
                                    Deseja imprimir a procuração?
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={a => this.props.history.push("/itens")}>Não</Button>
                                    <Button className="btnora" onClick={a => this.setState({ yesp: true })}>Sim</Button>
                                </ModalFooter>
                            </>
                        ) : (
                                <>
                                    <ModalBody>
                                        Qual o modelo? <br />

                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="tipo">Modelo</label>
                                            <div className="grupo-campo-input input-cad" id="tipo">
                                                <i className="fas fa-wrench" id="iconTipo"></i>
                                                <select onChange={a => this.setState({ modelSE: a.target.value })}>
                                                    <option value="NULL">Selecione o modelo</option>
                                                    {
                                                        this.state.modelos.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        {
                                            this.state.modelSE != "NULL" ? (
                                                <Link to={`/pdf/${this.state.itpdf}/${this.state.modelSE}`} target="_blank">
                                                    <Button className="btnora">Imprimir</Button>
                                                </Link>
                                            ) : (null)
                                        }
                                    </ModalFooter>
                                </>
                            )
                    }
                </Modal>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />

                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir veículo</strong></p>
                            </div>

                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="tipo">Tipo *</label>
                                            <div className="grupo-campo-input input-cad" id="tipo">
                                                <i className="fas fa-wrench" id="iconTipo"></i>
                                                <select
                                                    name="nivel"
                                                    placeholder="&nbsp;Selecione o Tipo do item"
                                                    autoComplete="on"
                                                    onChange={this.changeModalCadastrarTipo}
                                                    required
                                                >
                                                    <option value="NULL">Selecione o Tipo do item</option>
                                                    <option value="Moto">Motocicleta</option>
                                                    <option value="Motoneta">Motoneta</option>
                                                    <option value="Carro">Carro</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad" id="estilo">
                                            <label htmlFor="estoque">Estilo</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i class="fas fa-motorcycle"></i>
                                                <select name="" onChange={e => this.setState({ estilosSE: e.target.value, modalCadastrarEstilo: e.target.value})}>
                                                    <option value="NULL">Selecione um estilo</option>
                                                    {
                                                        this.state.estilos.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="marca">Marca *</label>
                                            <div className="grupo-campo-input input-cad" id="marca">
                                                <i className="fas fa-certificate" id="iconMarca"></i>
                                                <select name="" id="imark" onChange={e => this.setState({ marcaSE: e.target.value, modalCadastrarMarca: e.target.selectedOptions[0].innerHTML, modalCadastrarModelo: null, modeloSE: '' })}>
                                                    <option value="NULL">Selecione uma marca</option>
                                                    {
                                                        this.state.marcas.map(e => (
                                                            <option value={e.id}>{e.marca}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="modelo">Modelo *</label>
                                            <div className="grupo-campo-input input-cad" id="modelo">
                                                <i className="fas fa-asterisk" id="iconModelo"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Modelo do item"
                                                    autoComplete="on"
                                                    onChange={event => this.configModelo(event.target.value)}
                                                    required
                                                    onFocus={a => this.setState({ clientFocus2: true })} 
                                                    onBlur={a => this.setState({ clientFocus2: false })}
                                                    value={this.state.modeloSE}
                                                    disabled={this.state.marcaSE == "NULL"} 

                                                />
                                            </div>
                                            <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                {
                                                    this.state.marcaSE != "NULL" ?
                                                        (this.state.modelosvei.filter(y => y.marca == this.state.marcaSE && y.modelo.toLowerCase().includes(this.state.modeloSE.toLowerCase())).slice(0, 10).map(e => (
                                                            <li onClick={a => this.setState({modeloSE: e.modelo, modalCadastrarModelo: e.modelo})}>{e.modelo}</li>
                                                        )))
                                                        : (null)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>                       

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="ano">Ano *</label>
                                            <div className="grupo-campo-input input-cad" id="ano">
                                                <i className="fas fa-calendar-alt" id="iconAno"></i>
                                                <InputMask
                                                    mask="9999"
                                                    maskChar="_"
                                                    alwaysShowMask="true"
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Ano do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarAno: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="km">Quilometragem *</label>
                                            <div className="grupo-campo-input input-cad" id="km">
                                                <i className="fa fa-tachometer-alt" id="iconKm"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe a Quilometragem do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarKm: event.target.value })}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="cor">Cor *</label>
                                            <div className="grupo-campo-input input-cad" id="cor">
                                                <i className="fas fa-paint-roller" id="iconCor"></i>
                                                {/* <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe a Cor do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarCor: event.target.value })}
                                                    required
                                                /> */}
                                                <select name="" onChange={e => this.setState({ coresSE: e.target.value, modalCadastrarCor: e.target.value})}>
                                                    <option value="NULL">Selecione uma cor</option>
                                                    {
                                                        this.state.cores.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="combustivel">Combustível *</label>
                                            <div className="grupo-campo-input input-cad" id="combustivel">
                                                <i className="fa fa-gas-pump" id="iconCombustivel"></i>
                                                <select name="" onChange={e => this.setState({ combustiveisSE: e.target.value, modalCadastrarCombustivel: e.target.value})}>
                                                    <option value="NULL">Selecione um combustível</option>
                                                    {
                                                        this.state.combustiveis.map(e => (
                                                            <option value={e.id}>{e.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="descricao">Descrição</label>
                                            <div className="grupo-campo-input input-cad hightop" id="descricao">
                                                <i className="fa fa-marker" id="iconDescricao"></i>
                                                <textarea
                                                    type="text"
                                                    placeholder="&nbsp;Informe a Descricao do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="preco">Preço</label>
                                            <div className="grupo-campo-input input-cad" id="preco">
                                                <i className="fa fa-dollar-sign" id="iconPreco"></i>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    placeholder="&nbsp;Informe o Preço do item"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarPreco: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="fornecedor">Fornecedor *</label>
                                            <div className="grupo-campo-input input-cad" id="fornecedor">
                                                <i className="fa fa-hand-holding-usd" id="iconFornecedor"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Fornecedor do item"
                                                    autoComplete="on"
                                                    value={this.state.forname} onFocus={a => this.setState({ clientFocus: true })} onBlur={a => this.setState({ clientFocus: false })} onChange={this.configAutoComplete}
                                                />
                                            </div>
                                            <ul className={this.state.clientFocus ? "listC activate" : "listC"}>
                                                {
                                                    this.state.dados.filter(y => y.fornecedor_nome.toLowerCase().includes(this.state.forname.toLowerCase())).map(e => (
                                                        <li onClick={a => this.setState({ forname: e.fornecedor_nome, modalCadastrarFornecedor: e.fornecedor_id })}>{e.fornecedor_nome}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estoque">RENAVAM</label>
                                            <div className="grupo-campo-input input-cad" id="havam">
                                                <i className="fa fa-car" id="havam"></i>
                                                <input
                                                    type="number"
                                                    placeholder="&nbsp;Informe o RENAVAM"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ renavam: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="estoque">Chassi</label>
                                            <div className="grupo-campo-input input-cad" id="cha">
                                                <i className="fa fa-car" id="cha"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o chassi"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ chassi: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="pla">Placa</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i className="fa fa-car"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe a placa"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ placa: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="prop">Propriedades</label>
                                            <div className="grupo-campo-input input-cad" id="prop">
                                                <i className="fas fa-paint-roller" id="iconprop"></i>
                                                <select name="" onChange={e => this.setState({ propriedadesSE: e.target.value})}>
                                                    <option value="NULL">Selecione uma propriedade</option>
                                                    {
                                                        this.state.propriedades.map(e => (
                                                            <option value={e.id}>{e.descricao}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="observacao">Observação</label>
                                            <div className="grupo-campo-input input-cad hightop" id="observacao">
                                                <i className="fa fa-marker" id="iconobservacao"></i>
                                                <textarea
                                                    type="text"
                                                    placeholder="&nbsp;Observaçoes"
                                                    autoComplete="on"
                                                    onChange={event => this.setState({ modalCadastrarObservacao: event.target.value })}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="imgs">Imagens</label>
                                            <label>
                                                <div className="grupo-campo-input input-cad" id="imgs">
                                                    <i className="fa fa-camera" id="iconImg"></i>
                                                    {this.state.imgsFile.length} Imagens selecionadas
                                                </div>
                                                <input type="file" hidden accept="image/*" multiple onChange={this.renderImgs} />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="ambar">
                                            {
                                                this.state.imgsView.map((e, index) => (
                                                    <div className="cropper">
                                                        <img src={e} alt="" />
                                                        <div className="overpass">
                                                            <i className="fa fa-times" onClick={a => this.removeimg(index)}></i>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="aviso-formulario">
                                    <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                </div>
                                <Button onClick={this.apiCadastrarItem} className="cadbtn">Cadastrar</Button>
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ItemCadastrarPage
