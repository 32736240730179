import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Especies extends React.Component {
	constructor(props) {
		super(props);

		this.pageSize = 1;

		this.state = {
			dados: [],

			id: '',
			nome: '',

			modalAvisoOpen: false,
			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			pageLimit: 10,
			pageAtual: 0,
		};
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] especieREAD > " + msg);
	}

	verificarPermissao = async event => {
		if (localStorage.getItem('usuario_id')) {
			let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
			res = res.data.filter(a => a.rota == "/especie")
			if (res.length == 0) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	async componentDidMount() {
		if (await this.verificarPermissao()) {
			this.props.history.push('/dashboardcomercial')
		} else {
			this.apiListarEspecies();
		}
	}

	apiListarEspecies = async event => {
		let res = await api_topmotos.get('/especie');
		this.setState({ dados: res.data })
		this.pageSize = Math.ceil(res.data.length / this.state.pageLimit);
	}

	nextorprevious = (e, index) => {
		e.preventDefault();
		this.setState({ pageAtual: index })
	}

	putItem = (pageAtual, index) => {
		let item = (
			<div style={{ width: '30px', border: 'none', borderRadius: '3px', backgroundColor: index == pageAtual ? '#FF7115' : '', float: 'left' }} key={index}>
				<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left', color: index == pageAtual ? '#FFF' : '#222' }} onClick={l => this.nextorprevious(l, index)}>
					{index + 1}
				</button>
			</div>
		)

		if (pageAtual == 0 && index <= 4) return item
		if (pageAtual + 1 == this.pageSize && index >= pageAtual - 4) return item
		if ((pageAtual - 2 == -1) && (index == pageAtual || (index >= pageAtual - 1 && index <= pageAtual + 3))) return item
		if ((pageAtual + 2 == this.pageSize) && (index == pageAtual || (index >= pageAtual - 3 && index <= pageAtual + 1))) return item
		if ((pageAtual - 2 >= 0 || pageAtual + 2 < this.pageSize) && (index == pageAtual || (index >= pageAtual - 2 && index <= pageAtual + 2))) return item
	}

	filtrar = (dados) => {
		let dadosFilter = dados.filter((d) => {
			return this.filtrarID(d) && this.filtrarNome(d)
		})

		this.pageSize = Math.ceil(dadosFilter.length / this.state.pageLimit)

		return dadosFilter
	}

	filtrarID = (d) => {
		return !this.state.id || (d.id && d.id.toString().toLowerCase().includes(this.state.id.toLowerCase()));
	}
	filtrarNome = (d) => {
		return !this.state.nome || (d.nome && d.nome.toString().toLowerCase().includes(this.state.nome.toLowerCase()));
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}
	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (id) => {
		let res = await api_topmotos.get('/especie/id/' + id);
		res = res.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: res.id,
			modalDeletarNome: res.nome,
		})
	}

	apiDeletarEspecie = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/especie/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/especie/update)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Espécie deletada com sucesso!' });
	}

	render() {
		return (
			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Espécies</p>
							<div className="form-add" onClick={a => this.props.history.push("/criarespecie")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th></th>
									</tr>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.id} onChange={a => this.setState({ id: a.target.value })} />
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												<i className="fas fa-search"></i>
												<input type="text" value={this.state.nome} onChange={a => this.setState({ nome: a.target.value })} />
											</div>
										</th>
										<th>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.filtrar(this.state.dados).slice(this.state.pageAtual * this.state.pageLimit, (this.state.pageAtual + 1) * this.state.pageLimit)
											.map((especie, id) => (
												<tr key={id}>
													<td>{especie.id}</td>
													<td>{especie.nome}</td>
													<td className="td-acoes">
														<i className="fas fa-pen" onClick={() => this.props.history.push("/alterespecies/" + especie.id)}></i>&nbsp;
														<i className="fas fa-trash" onClick={() => this.modalDeletar(especie.id)}></i>
													</td>
												</tr>
											))
									}
								</tbody>
								<tr style={{ width: '100%' }}>
								</tr>
							</table>
							<div className="form-footer">
								{this.state.dados.length > 0 && <div style={{ padding: '10px', height: '48px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }}>
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual <= 0} onClick={l => { this.nextorprevious(l, this.state.pageAtual - 1) }}><i className='fas fa-angle-left' /></button>
									</div>
									{
										[...Array(this.pageSize)].map((e, index) => (
											this.putItem(this.state.pageAtual, index)
										))
									}
									<div style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} >
										<button style={{ width: '30px', border: 'none', background: 'transparent', float: 'left' }} disabled={this.state.pageAtual >= this.pageSize - 1} onClick={l => { this.nextorprevious(l, this.state.pageAtual + 1) }}><i className='fas fa-angle-right' /></button>
									</div>
								</div>}
							</div>
						</div>

						{/* MODAL DELETAR */}
						<Modal
							isOpen={this.state.modalDeletarOpen}
							toggle={this.toggleModalDeletar.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
							<ModalBody>
								Deseja realmente deletar a espécie {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
							</ModalBody>
							<ModalFooter>
								<Button onClick={this.apiDeletarEspecie} color="danger">Deletar</Button>
								<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
							</ModalFooter>
						</Modal>


						{/* MODAL AVISO */}
						<Modal
							isOpen={this.state.modalAvisoOpen}
							toggle={this.toggleModalAviso.bind(this)}
							backdrop={true}
							keyboard={true}
							size="sm"
							fade={false} >
							<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
							<ModalBody>
								{this.state.modalAvisoText}
							</ModalBody>
							<ModalFooter>
								<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
							</ModalFooter>
						</Modal>

						<Versionamento />
					</div>
				</div>
			</section>
		)
	}
}

export default Especies;