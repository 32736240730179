import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import jwt from 'jwt-simple';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class PropriedadesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            id:'',
            propriedades: [],
            modalCadastrar: false,
            modalAlterar: false,
            modalDetalhes: false,
            modalDeletar: false,
            modalDeletarId: '',
            modalAviso: false,
            modalAvisoText: '',
            errorMessage: ''
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/propriedades")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.listarPropriedades();
        }
    }

    listarPropriedades = async () =>{
        let res = await api_topmotos.get('/propriedades/');
        this.setState({ propriedades: res.data });
    }

    listarId = async (id) =>{
        let {data} = await api_topmotos.get("/propriedades/id/" + id)
        
        this.setState({
            id: data[0].id,
            nome: data[0].descricao
        })

    }

    log(msg) {
        console.log("[" + this.getTimestamp() + "] propriedade/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    // Off/On modal cadastro
    toggleModalCadastrar = () => {
		this.setState({ modalCadastrar: !this.state.modalCadastrar, errorMessage:'' });
    }

    // Off/On modal cadastro
    toggleModalAlterar = () => {
		this.setState({ modalAlterar: !this.state.modalAlterar, errorMessage:''});
    }

    // Off/On modal aviso
    toggleModalAviso = () => {
        this.setState({ modalAviso: !this.state.modalAviso });
    }

    
    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    // Abrir modal alteração
    OpenModalAlteracao = async (id) => {
        await this.listarId(id);

        await this.setState({modalAlterar: true})

    }

    // Abrir modal deletar
    OpenModalDeletar = async (id) => {
		let res = await api_topmotos.get('/propriedades/id/' + id);
		res = res.data[0];

		this.setState({
            modalDeletar: true,
			modalDeletarId: res.id
		});
    }

    verificarCampo = () =>{
        var inputs = [
            [this.state.nome, 'propriedade','iconPropriedade','lblPropriedade']
        ]

        for(var i = 0; i < inputs.length; i++){
            if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
                inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
                inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
            } else{
                var inputId = document.getElementById(inputs[i][1]);
                var inputIcone = document.getElementById(inputs[i][2]);
                var inputNome = document.getElementById(inputs[i][3]);
                inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
                inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
                inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
            }
        }
    }

    apiCadastrarPropriedade = async () => {
        
        if(this.state.nome) {
            try {
                await api_topmotos.post('/propriedades/create', {
                    nome: this.state.nome,
                    data: this.getTimestamp()
                });

                this.toggleModalCadastrar()
                this.listarPropriedades()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            this.verificarCampo();
            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    apiAlterarPropriedade = async () => {
        if(this.state.nome) {
            try {
                await api_topmotos.put('/propriedades/update/id/' + this.state.id, {
                    id: this.state.id,
                    nome: this.state.nome,
                    data: this.getTimestamp()
                })

                this.toggleModalAlterar()
                this.listarPropriedades()
            }catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/create)" });
                this.log(this.state.error);
            }
            
        } else {
            this.verificarCampo();
            this.setState({ errorMessage: "Preencha todos os campos" })
        }
    }

    // Deletar conta
    deletar = async e =>{

        e.preventDefault();

		try {
            await api_topmotos.delete('/propriedades/delete/id/' + this.state.modalDeletarId);
            this.listarPropriedades();
            this.toggleModalDeletar();
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/propriedades/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAviso: true });
		this.setState({ modalAvisoText: 'Propriedade deletada com sucesso!' });
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="relatorio-info">
                                <div className="head">
                                    <p>Propriedades</p>
                                    <div>
                                       <button className="btn-add px-4 py-2" onClick={() => this.setState({modalCadastrar: true})}>Adicionar Propriedade<i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Id
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Descrição
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-center justify-between">
                                                    Opções
                                                    <i className="fas fa-sort"></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.propriedades.map((prop) => (
                                                <tr key={prop.id}>
                                                    <td>{prop.id}</td>
                                                    <td>{prop.descricao}</td>
                                                    <td className="td-acoes">
                                                        <i className="fas fa-pen" onClick={() => this.OpenModalAlteracao(prop.id)}></i>&nbsp;
                                                        <i className="fas fa-trash" onClick={() => {this.OpenModalDeletar(prop.id)}}></i>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className="form-footer">
                                    <span></span>
                                </div>
                            </div>

                            {/* Cadastro */}
                            <Modal
                                isOpen={this.state.modalCadastrar}
                                toggle={this.toggleModalCadastrar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalCadastrar.bind(this)}>Adicionar Conta</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="propriedade" id="lblPropriedade">Propriedade</label>
                                                        <div className="grupo-campo-input input-cad" id="propriedade">
                                                            <i className="fas fa-briefcase" id="iconPropriedade"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Ex: Consignação"
                                                                onChange={event => this.setState({ nome: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiCadastrarPropriedade}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/* Alteração */}
                            <Modal
                                isOpen={this.state.modalAlterar}
                                toggle={this.toggleModalAlterar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="md"
                                fade={false}>
                                    <ModalHeader toggle={this.toggleModalAlterar.bind(this)}>Alterar Propriedade</ModalHeader>
                                    <ModalBody>
                                        <div className="content-formulario pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="grupo-input input-style-cad">
                                                        <label htmlFor="propriedade" id="lblPropriedade">Propriedade</label>
                                                        <div className="grupo-campo-input input-cad" id="propriedade">
                                                            <i className="fas fa-briefcase" id="iconPropriedade"></i>
                                                            <input
                                                                type="text"
                                                                autoComplete="on"
                                                                placeholder="Ex: Consignação"
                                                                value={this.state.nome}
                                                                onChange={event => this.setState({ nome: event.target.value })}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aviso-formulario aviso-formulario-err">
                                                <p className="aviso">{this.state.errorMessage}</p>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.toggleModalAlterar.bind(this)}>Fechar</Button>
                                        <Button className="bg-laranja" onClick={this.apiAlterarPropriedade}>Alterar</Button>
                                    </ModalFooter>
                                </Modal>

                            {/*Modal Deletar*/}
                            <Modal
                                isOpen={this.state.modalDeletar}
                                toggle={this.toggleModalDeletar.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="sm"
                                fade={false} >
                                    <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                                    <ModalBody>
                                        Deseja realmente deletar a propriedade<br></br>(ID: {this.state.modalDeletarId})?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.deletar} color="danger">Deletar</Button>
                                        <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                                    </ModalFooter>
                                </Modal>
                                
                            {/* MODAL AVISO */}
                            <Modal
                                isOpen={this.state.modalAviso}
                                toggle={this.toggleModalAviso.bind(this)}
                                backdrop={true}
                                keyboard={true}
                                size="sm"
                                fade={false} >
                                <ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
                                <ModalBody>
                                    {this.state.modalAvisoText}
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => { this.toggleModalAviso()}}>Fechar</Button>
                                </ModalFooter>
                            </Modal>

                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default PropriedadesPage