import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment'

import InputMask from 'react-input-mask';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class Usuarios extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dados: [],

			modalDetalhesOpen: false,
			modalDetalhesId: 0,
			modalDetalhesNome: '',
			modalDetalhesTelefone: '',
			modalDetalhesEmail: '',
			modalDetalhesNivel: '',
			modalDetalhesAdmissaodata: '',
			modalDetalhesAtualizacaodata: '',
			modalDetalhesEmpresa: '',

			modalEditarOpen: false,
			modalEditarId: 0,
			modalEditarNome: '',
			modalEditarTelefone: '',
			modalEditarEmail: '',
			modalEditarSenha: '',
			modalEditarNivel: '',
			modalEditarAdmissaodata: '',

			modalDeletarOpen: false,
			modalDeletarId: '',
			modalDeletarNome: '',

			modalAvisoOpen: false,
			modalAvisoText: '',

			empresas: []
		};
	}

	verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/usuarios")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
			this.apiListarUsuarios();
			this.apiListarEmpresas();
		}
	}

	log(msg) {
		console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
	}

	getTimestamp() {
		var today = new Date();
		var date = today.getFullYear() + '-'
			+ ('0' + (today.getMonth() + 1)) + '-'
			+ ('0' + today.getDate()).slice(-2);

		var time = ('0' + today.getHours()).slice(-2) + ':'
			+ ('0' + today.getMinutes()).slice(-2) + ':'
			+ ('0' + today.getSeconds()).slice(-2);

		var dateTime = date + ' ' + time;
		return dateTime;
	}

	togglePassword = () => {
		var s = this.state.senhaVisivel;
		this.setState({ senhaVisivel: !s });
	}

	toggleModalAviso = () => {
		this.setState({ modalAvisoOpen: !this.state.modalAvisoOpen });
	}

	apiListarUsuarios = async event => {
		let res = await api_topmotos.get('/usuarios/');
		this.setState({ dados: res.data });
	}

	apiListarEmpresas = async event => {
        let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
    }

	toggleModalDetalhes = () => {
		this.setState({ modalDetalhesOpen: !this.state.modalDetalhesOpen });
	}

	modalDetalhes = async (idUsuario) => {
		let resUsuario = await api_topmotos.get('/usuarios/id/' + idUsuario);
		resUsuario = resUsuario.data[0];

		let empresa = this.state.empresas.filter(y => y.empresa_id == resUsuario.usuario_empresa);

		this.setState({
			modalDetalhesOpen: true,
			modalDetalhesId: resUsuario.usuario_id,
			modalDetalhesNome: resUsuario.usuario_nome,
			modalDetalhesTelefone: resUsuario.usuario_telefone,
			modalDetalhesEmail: resUsuario.usuario_email,
			modalDetalhesNivel: resUsuario.usuario_nivel,
			modalDetalhesAdmissaodata: moment(resUsuario.usuario_admissaodata).format('DD/MM/YYYY HH:mm'),
			modalDetalhesAtualizacaodata: resUsuario.usuario_atualizacaodata ? moment(resUsuario.usuario_atualizacaodata).format('DD/MM/YYYY HH:mm') : '',
			modalDetalhesEmpresa: empresa[0].empresa_razaosocial
		});
	}

	toggleModalDeletar = () => {
		this.setState({ modalDeletarOpen: !this.state.modalDeletarOpen });
	}

	modalDeletar = async (idUsuario) => {
		let resUsuario = await api_topmotos.get('/usuarios/id/' + idUsuario);
		resUsuario = resUsuario.data[0];

		this.setState({
			modalDeletarOpen: true,
			modalDeletarId: resUsuario.usuario_id,
			modalDeletarNome: resUsuario.usuario_nome,
		});
	}

	apiDeletarUsuario = async e => {
		e.preventDefault();

		try {
			await api_topmotos.delete('/usuarios/delete/id/' + this.state.modalDeletarId);
		} catch (err) {
			this.setState({ error: "ocorreu um erro ao conectar com a API! (/usuarios/delete)" });
			this.log(this.state.error);
		}

		this.setState({ modalAvisoOpen: true });
		this.setState({ modalAvisoText: 'Usuário deletado com sucesso!' });
	}

	formatTime(e) {
		if (!e) return e

		let [dias, tempo] = e.split("T");
		let [ano, mes, dia] = dias.split('-');
		let [hora, minutos, segundos] = tempo.split(':');

		return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
	}

	render() {
		const { senhaVisivel } = this.state;

		return (

			<section>
				<div className="content-all d-flex">
					<NavMenu props={this.props} />
					<div className="content-session">
						<NavUsuario />
						<div className="form-head">
							<p>Usuários</p>
							<div className="form-add" onClick={a => this.props.history.push("/cadastrarusuario")}>
								Adicionar <i className="fas fa-plus"></i>
							</div>
						</div>
						<div className="table">
							<table>
								<thead>
									<tr>
										<th>
											<div className="d-flex align-center justify-between">
												ID
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nome
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Telefone
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												E-mail
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Nível
												<i className="fas fa-sort"></i>
											</div>
										</th>
										<th>
											<div className="d-flex align-center justify-between">
												Ações
											</div>
										</th>
									</tr>
								</thead>
								<tbody>

									{
										this.state.dados.map((usuario, id) => (
											<tr key={usuario.usuario_id}>
												<td>{usuario.usuario_id}</td>
												<td>{usuario.usuario_nome}</td>
												<td>{usuario.usuario_telefone}</td>
												<td>{usuario.usuario_email}</td>
												<td>{usuario.usuario_nivel}</td>
												<td className="td-acoes">
													<i className="fas fa-bars" onClick={() => this.props.history.push("/restricao/" + usuario.usuario_id)}></i>
													<i className="fas fa-eye" onClick={() => this.modalDetalhes(usuario.usuario_id)}></i>&nbsp;
													<i className="fas fa-pen" onClick={() => this.props.history.push("/editarusuario/" + usuario.usuario_id)}></i>&nbsp;
													<i className="fas fa-trash" onClick={() => this.modalDeletar(usuario.usuario_id)}></i>
												</td>
											</tr>
										))
									}

								</tbody>
							</table>
							<div className="form-footer">
								<span></span>
							</div>
						</div>
						<Versionamento/>
					</div>
				</div>

				{/* MODAL DETALHES */}
				<Modal
					isOpen={this.state.modalDetalhesOpen}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDetalhes.bind(this)}>{this.state.modalDetalhesNome} - {this.state.modalDetalhesId}</ModalHeader>
					<ModalBody>
						<div className="box-conteudo">
							<div className="conteudo-info">
								<p>Nome</p>
								<p>{this.state.modalDetalhesNome}</p>
							</div>
							<div className="conteudo-info">
								<p>E-mail</p>
								<p>{this.state.modalDetalhesEmail}</p>
							</div>
							<div className="conteudo-info">
								<p>Telefone</p>
								<p>{this.state.modalDetalhesTelefone}</p>
							</div>
							<div className="conteudo-info">
								<p>Nível</p>
								<p>{this.state.modalDetalhesNivel}</p>
							</div>
							<div className="conteudo-info">
								<p>Empresa</p>
								<p>{this.state.modalDetalhesEmpresa}</p>
							</div>
							<div className="conteudo-info">
								<p>Data de Cadastro</p>
								<p>{this.state.modalDetalhesAdmissaodata}</p>
							</div>
							<div className="conteudo-info">
								<p>Última vez Atualizado</p>
								<p>{this.state.modalDetalhesAtualizacaodata}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.toggleModalDetalhes.bind(this)}>Fechar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL DELETAR */}
				<Modal
					isOpen={this.state.modalDeletarOpen}
					toggle={this.toggleModalDeletar.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
					<ModalBody>
						Deseja realmente deletar o usuário {this.state.modalDeletarNome} (ID: {this.state.modalDeletarId})?
					</ModalBody>
					<ModalFooter>
						<Button onClick={this.apiDeletarUsuario} color="danger">Deletar</Button>
						<Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
					</ModalFooter>
				</Modal>

				{/* MODAL AVISO */}
				<Modal
					isOpen={this.state.modalAvisoOpen}
					toggle={this.toggleModalAviso.bind(this)}
					backdrop={true}
					keyboard={true}
					size="sm"
					fade={false} >
					<ModalHeader toggle={this.toggleModalAviso.bind(this)}>Aviso!</ModalHeader>
					<ModalBody>
						{this.state.modalAvisoText}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => { this.toggleModalAviso.bind(this); window.location.reload(); }}>Fechar</Button>
					</ModalFooter>
				</Modal>
			</section>

		)
	}
}

export default Usuarios;