import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos, api_viacep } from '../../services/api';
import { Button } from 'reactstrap';
import InputMask from 'react-input-mask';

class ProdutoCadastroPage extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			dados: [],

            titles: ['Cadastrar Produto', 'Cadastrar Fornecedor'],

			modalCadastrarOpen: false,
			modalCadastrarNome: '',
            modalCadastrarTipo: '',
            modalCadastrarDescricao: '',
            modalCadastrarFornecedor: '',
            modalCadastrarRefInterna: '',
            modalCadastrarRefExterna: '',
            modalCadastrarValorVenda: '',
            modalCadastrarValorCompra: '',

            FmodalCadastrarNome: '',
			FmodalCadastrarTelefone: '',
			FmodalCadastrarEmail: '',
			FmodalCadastrarCnpj: '',
			FmodalCadastrarCep: '',
			FmodalCadastrarEstado: '',
			FmodalCadastrarCidade: '',
			FmodalCadastrarBairro: '',
			FmodalCadastrarLogradouro: '',
			FmodalCadastrarNumero: '',
			FmodalCadastrarComplemento: '',
            FmodalCadastrarEnderecoId: '',

            cpf: '',
            aviso: '',
            cpfValido: '',
            fornecedores: [],
            fornecedorId: null,
            is_pessoa: false,

            tipos: [],
		};
    }

    verificarPermissao = async event => {
		if(localStorage.getItem('usuario_id')){
				let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
				res = res.data.filter(a => a.rota == "/produtos")
				if(res.length == 0){
						return true
				} else{
						return false
				}
		} else{
				return true
		}
}

    log(msg) {
        console.log("[" + this.getTimestamp() + "] ProdutoUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.apiListarFornecedores();
            this.apiListarTipos();
        }
    }

    apiListarFornecedores = async () => {
        let res = await api_topmotos.get('/fornecedores/');
		this.setState({ fornecedores: res.data });
    }

    apiListarTipos = async () => {
        let res = await api_topmotos.get('/produtos/tipos/');
		this.setState({ tipos: res.data });
    }

    apiCadastrarProduto = async e => {
        const {
            modalCadastrarNome,
            modalCadastrarTipo,
            modalCadastrarDescricao,
            fornecedorId,
            modalCadastrarRefInterna,
            modalCadastrarRefExterna,
            modalCadastrarFornecedor,
            modalCadastrarValorVenda,
            modalCadastrarValorCompra,
        } = this.state;

        if(!modalCadastrarNome        ||
            !modalCadastrarTipo       ||
            !modalCadastrarDescricao  ||
            !modalCadastrarRefExterna ||
            !modalCadastrarRefInterna ||
            !modalCadastrarFornecedor ||
            !modalCadastrarValorVenda ||
            !modalCadastrarValorCompra) {
            this.setState({ error: "preencha todos os dados para cadastrar!" });
            this.setState({ errorMessage: "Preencha todos os dados para cadastrar!" });
            this.log(this.state.error);
            alert("Preencha todos os dados para cadastrar!")
        } else {
            this.setState({ errorMessage: '' });

            if (!fornecedorId) {
                const {
                    FmodalCadastrarNome,
                    FmodalCadastrarTelefone,
                    FmodalCadastrarEmail,
                    FmodalCadastrarCnpj,
                    FmodalCadastrarCep,
                    FmodalCadastrarEstado,
                    FmodalCadastrarCidade,
                    FmodalCadastrarBairro,
                    FmodalCadastrarLogradouro,
                    FmodalCadastrarNumero,
                    FmodalCadastrarComplemento,
                } = this.state;
                
                try {
                    await api_topmotos.post('/enderecos/create', {
                        endereco_cep:           FmodalCadastrarCep,
                        endereco_estado:        FmodalCadastrarEstado,
                        endereco_cidade:        FmodalCadastrarCidade,
                        endereco_bairro:        FmodalCadastrarBairro,
                        endereco_logradouro:    FmodalCadastrarLogradouro,
                        endereco_numero:        FmodalCadastrarNumero,
                        endereco_complemento:   FmodalCadastrarComplemento,
                        endereco_admissaodata:  this.getTimestamp(),
                    }).then(res => {
                        this.setState({ FmodalCadastrarEnderecoId: res.data.returnId });
                    });
                } catch(err) {
                    this.setState({ error: "ocorreu um erro ao conectar com a API! (/enderecos/create)" });
                    this.log(this.state.error);
                }
    
                try {
                    await api_topmotos.post('/fornecedores/create', {
                        fornecedor_nome:          FmodalCadastrarNome,
                        fornecedor_email:         FmodalCadastrarEmail,
                        fornecedor_telefone:      FmodalCadastrarTelefone,
                        fornecedor_cnpj:          FmodalCadastrarCnpj,
                        fornecedor_endereco:      this.state.FmodalCadastrarEnderecoId,
                        fornecedor_admissaodata:  this.getTimestamp(),
                    }).then(res => {
                        this.setState({ fornecedorId: res.data.returnId });
                    });
                } catch(err) {
                    this.setState({ error: "ocorreu um erro ao conectar com a API! (/fornecedores/create)" });
                    this.log(this.state.error);
                }
            }

            try {
                const newFornecedorId = this.state.fornecedorId;

                let realVenda = modalCadastrarValorVenda.replace('.', '');
                realVenda = realVenda.replace(',', '.');
                realVenda = parseFloat(realVenda);
                let realCompra = modalCadastrarValorCompra.replace('.', '');
                realCompra = realCompra.replace(',', '.');
                realCompra = parseFloat(realCompra);

                await api_topmotos.post('/produtos/create', {
                    nome:          modalCadastrarNome,
                    descricao:     modalCadastrarDescricao,
                    tipo:          modalCadastrarTipo,
                    fornecedor:    newFornecedorId,
                    refInterna:    modalCadastrarRefInterna,
                    refExterna:    modalCadastrarRefExterna,
                    valor_venda:   realVenda,
                    valor_compra:  realCompra,
                })

            } catch(err) {
                this.setState({ error: "ocorreu um erro ao conectar com a API! (/produtos/create)" });
                this.log(this.state.error);
            }

            this.props.history.push('/produtos');
        }
    }

    slide = (cont) => {
        var questionario = document.getElementsByClassName("perguntas");
        var aberta;

        for(var i = 0; i < questionario.length; i++){
            if(questionario[i].style.display == 'flex'){
                aberta = i;
            }
            questionario[i].style.display = 'none';
        }

        questionario[aberta + cont].style.display = 'flex'
        this.setState({indexperg: aberta + cont})
    }

    cpfInvalido = (tipo) =>{
        if(tipo == 1){
            this.setState({avisoLead: 'CPF inválido', cpfValido: false})
            var aviso = document.getElementById('avisoLead')
            aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
        } else{
            this.setState({aviso: tipo == 2 ? 'CNPJ inválido' : 'CPF inválido', cpfValido: false})
            var aviso = document.getElementById('aviso')
            aviso.style.cssText = "color: #dc3545; transition: 0.2s; display: block;";
    
            this.setState({
                is_pessoa: true,
                pessoaId: null, 
                FmodalCadastrarCnpj: '',
                FmodalCadastrarNome: '',
                FmodalCadastrarEmail: '',
                FmodalCadastrarTelefone: '',
                FmodalCadastrarCep: '',
                FmodalCadastrarEstado: '',
                FmodalCadastrarCidade: '',
                FmodalCadastrarBairro: '',
                FmodalCadastrarLogradouro: '',
                FmodalCadastrarNumero: '',
                fmodalCadastrarComplemento: '',
            })
        }
    }

    checkPessoa = async (e) => {

        if (e && (e.length == 14 || e.length == 18)) {

            var aviso = document.getElementById('aviso')

            this.setState({aviso: '', errorMessage: ''})

            var pessoa = this.state.fornecedores.filter((f) => {
                return f.fornecedor_cnpj == e
            })

            if (pessoa.length == 0) {
                aviso.style.display = "none"
                this.setState({
                    is_pessoa: true,
                    fornecedorId: null, 
                    FmodalCadastrarNome: '',
                    FmodalCadastrarTelefone: '',
                    FmodalCadastrarEmail: '',
                    FmodalCadastrarCep: '',
                    FmodalCadastrarEstado: '',
                    FmodalCadastrarCidade: '',
                    FmodalCadastrarBairro: '',
                    FmodalCadastrarLogradouro: '',
                    FmodalCadastrarNumero: '',
                    FmodalCadastrarComplemento: '',
                })
            } else {
                this.setState({aviso: 'Cliente Encontrado!'})
                aviso.style.cssText = "color: #17AD1D; transition: 0.2s; display: block;";
                
                let resEndereco = await api_topmotos.get('/enderecos/id/' + pessoa[0].fornecedor_endereco);
		        resEndereco = resEndereco.data[0];
        
                this.setState({
                    is_pessoa: false,
                    fornecedorId: pessoa[0].fornecedor_id,
                    FmodalCadastrarNome: pessoa[0].fornecedor_nome,
                    FmodalCadastrarEmail: pessoa[0].fornecedor_email,
                    FmodalCadastrarTelefone: pessoa[0].fornecedor_telefone,
                    FmodalCadastrarCnpj: pessoa[0].fornecedor_cnpj,
                    FmodalCadastrarCep: resEndereco.endereco_cep,
                    FmodalCadastrarEstado: resEndereco.endereco_estado,
                    FmodalCadastrarCidade: resEndereco.endereco_cidade,
                    FmodalCadastrarBairro: resEndereco.endereco_bairro,
                    FmodalCadastrarLogradouro: resEndereco.endereco_logradouro,
                    FmodalCadastrarNumero: resEndereco.endereco_numero,
                    FmodalCadastrarComplemento: resEndereco.endereco_complemento,
                })
            }
        }
    }

    validarCPF = async (e) =>{
        var cpf = e;

        if(cpf.length == 14){
            if (typeof cpf !== "string"){
                await this.cpfInvalido();
                
            } else{
                cpf = cpf.replace(/[\s.-]*/igm, '')

                if (!cpf ||
                    cpf.length != 11 ||
                    cpf == "00000000000" ||
                    cpf == "11111111111" ||
                    cpf == "22222222222" ||
                    cpf == "33333333333" ||
                    cpf == "44444444444" ||
                    cpf == "55555555555" ||
                    cpf == "66666666666" ||
                    cpf == "77777777777" ||
                    cpf == "88888888888" ||
                    cpf == "99999999999" 
                ) {
                    await this.cpfInvalido();
                } else{
                    var soma = 0
                    var resto

                    for (var i = 1; i <= 9; i++) 
                        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        
                    resto = (soma * 10) % 11

                    if ((resto == 10) || (resto == 11))  resto = 0

                    if (resto != parseInt(cpf.substring(9, 10)) ){
                        await this.setState({aviso: 'CPF inválido'})
                        await this.cpfInvalido();
                    } else{
                        soma = 0

                        for (var i = 1; i <= 10; i++) 
                            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
                        resto = (soma * 10) % 11

                        if ((resto == 10) || (resto == 11))  resto = 0

                        if (resto != parseInt(cpf.substring(10, 11) ) ){
                            await this.cpfInvalido();
                        } else{
                            this.setState({cpfValido: true})
                            this.checkPessoa(e)
                        }
                    }
                }
            }
        } else if(cpf.length == 18){
            let cnpj = cpf.replace(/[^\d]+/g, '')

            if ( !cnpj || cnpj.length != 14
                || cnpj == "00000000000000" 
                || cnpj == "11111111111111" 
                || cnpj == "22222222222222" 
                || cnpj == "33333333333333" 
                || cnpj == "44444444444444" 
                || cnpj == "55555555555555" 
                || cnpj == "66666666666666" 
                || cnpj == "77777777777777" 
                || cnpj == "88888888888888" 
                || cnpj == "99999999999999"){
                await this.cpfInvalido(2);
            } else{
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0,tamanho)
                var digitos = cnpj.substring(tamanho)
                var soma = 0
                var pos = tamanho - 7

                for (var i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--
                  if (pos < 2) pos = 9
                }

                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

                if (resultado != digitos.charAt(0)){
                    await this.cpfInvalido(2);
                } else{
                    tamanho = tamanho + 1
                    numeros = cnpj.substring(0,tamanho)
                    soma = 0
                    pos = tamanho - 7

                    for (var i = tamanho; i >= 1; i--) {
                      soma += numeros.charAt(tamanho - i) * pos--
                      if (pos < 2) pos = 9;
                    }

                    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

                    if (resultado != digitos.charAt(1)){
                        await this.cpfInvalido(2);
                    } else{
                        this.setState({cpfValido: true})
                        this.checkPessoa(e)
                    }
                }
            }
        } else{
            this.checkPessoa(e)
            this.setState({aviso: '', cpfValido: false})
        }
    }

    fornecedor = () => {
        if(this.state.modalCadastrarFornecedor){
            this.checkPessoa(this.state.modalCadastrarFornecedor)
        } else {
            this.setState({
                is_pessoa: true,
                errorMessage: '',
                fornecedorId: null, 
                FmodalCadastrarNome: '',
                FmodalCadastrarTelefone: '',
                FmodalCadastrarEmail: '',
                FmodalCadastrarCep: '',
                FmodalCadastrarEstado: '',
                FmodalCadastrarCidade: '',
                FmodalCadastrarBairro: '',
                FmodalCadastrarLogradouro: '',
                FmodalCadastrarNumero: '',
                FmodalCadastrarComplemento: '',
            })
            
            this.checkPessoa()
        }
    
        this.setState({
            FmodalCadastrarCnpj: this.state.modalCadastrarFornecedor
        })
        this.slide(+1);
    }

    getCEP = async (event) => {
		let cep = event.target.value.replace(/\./g, '').replace(/-/g, '').replace(/ /g, '');
        this.setState({ FmodalCadastrarCep: event.target.value });

		if (cep.length === 8) {
            let res = await api_viacep.get(`${cep}/json/`);
            this.setState({
                FmodalCadastrarEstado: res['data'].uf,
                FmodalCadastrarCidade: res['data'].localidade,
                FmodalCadastrarBairro: res['data'].bairro,
                FmodalCadastrarLogradouro: res['data'].logradouro,
            })
		}
    }

    maskCnpjCpf = (e, slide) => {
        let mask = []

        let regexs = {
            "0": [/^(\d{3})(\d{3})?/, "$1.$2"],
            "1": [/^(\d{3})(\d{3})?(\d{3})?/, "$1.$2.$3"],
            "2": [/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4"],
            "3": [/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5"],
        }

        if (e.length < 5){
            mask = regexs[0]
        }
        else if (e.length < 10){
            mask = regexs[1]
        }
        else if (e.length < 15){
            mask = regexs[2]
        }
        else{
            mask = regexs[3]
        }

        e = e.replace(/\D/g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(mask[0], mask[1]) 
        
        if (slide === 1) this.setState({modalCadastrarFornecedor: e})
        else if (slide === 2) this.setState({FmodalCadastrarCnpj: e})
    }

    mascaraReal = (e) =>  {
		var real = e.replace('.','');
		real = real.replace(',', '')
		real = real + '';
		real = real.replace(/([0-9]{2})$/g, ",$1");
		if( real.length > 6 )
		    real = real.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

		return real;
	}
    
    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Inserir Produto</strong></p>
                            </div>
                            <div className="questionario">
                                <p className="head">{this.state.titles[this.state.indexperg]}</p>
                                <div className="perguntas" id="primeira" style={{display:"flex"}}>
                                    <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="nome">Nome *</label>
                                                    <div className="grupo-campo-input input-cad" id="nome">
                                                        <i className="fas fa-user" id="iconNome"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe o Nome do Produto"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ modalCadastrarNome: event.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="descricao">Descrição</label>
                                                    <div className="grupo-campo-input input-cad" id="descricao">
                                                        <i className="fa fa-pen" id="iconDescricao"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe a Descrição do Produto"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ modalCadastrarDescricao: event.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="refExterna">Referencia Externa *</label>
                                                    <div className="grupo-campo-input input-cad" id="refExterna">
                                                        <i className="fas fa-asterisk" id="iconRefExterna"></i>
                                                        <input type="text" value={this.state.modalCadastrarRefExterna} onChange={event => this.setState({ modalCadastrarRefExterna: event.target.value })}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="refInterna">Referencia Interna *</label>
                                                    <div className="grupo-campo-input input-cad" id="refInterna">
                                                        <i className="fas fa-asterisk" id="iconRefInterna"></i>
                                                        <input type="text" value={this.state.modalCadastrarRefInterna} onChange={event => this.setState({ modalCadastrarRefInterna: event.target.value })}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>      
                                
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="fornecedor">CPF/CNPJ do Fornecedor *</label>
                                                    <div className="grupo-campo-input input-cad" id="fornecedor">
                                                        <i className="fas fa-warehouse" id="iconFornecedor"></i>
                                                        <input 
                                                            maxLength="18" 
                                                            type="text" 
                                                            value={this.state.modalCadastrarFornecedor} 
                                                            onChange={a => {
                                                                this.maskCnpjCpf(a.target.value, 1); 
                                                                this.validarCPF(a.target.value)
                                                            }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="tipo">Unidade *</label>
                                                    <div className="grupo-campo-input input-cad" id="tipo">
                                                        <i className="fas fa-balance-scale" id="iconTipo"></i>
                                                        <select
                                                            name="tipo"
                                                            placeholder="&nbsp;Selecione a Unidade de Medida"
                                                            autoComplete="on"
                                                            required
                                                            onChange={a => this.setState({ modalCadastrarTipo: a.target.value })}
                                                        >
                                                            <option value="NULL">Selecione a Unidade de Medida</option>
                                                            {
                                                                this.state.tipos.map((e, id) => (
                                                                    <option key={e.tipo_id} value={e.tipo_id}>{e.tipo_nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="row">
                                             <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="valor">Valor de Venda</label>
                                                    <div className="grupo-campo-input input-cad" id="valor">
                                                        <i className="fas fa-dollar-sign" id="iconTipo"></i>
                                                        <input type="text" value={this.state.modalCadastrarValorVenda} onChange={a => {this.setState({modalCadastrarValorVenda: this.mascaraReal(a.target.value, 1)})}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="valor">Valor de Compra</label>
                                                    <div className="grupo-campo-input input-cad" id="valor">
                                                        <i className="fas fa-dollar-sign" id="iconTipo"></i>
                                                        <input type="text" value={this.state.modalCadastrarValorCompra} onChange={a => {this.setState({modalCadastrarValorCompra: this.mascaraReal(a.target.value, 1)})}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="aviso-formulario">
                                            <p className="aviso" id="aviso">{this.state.errorMessage}</p>
                                        </div>
                                        <div className="btn-prox-volt">
                                            <button onClick={() => this.fornecedor()}>Próximo<i class="fas fa-arrow-right"></i></button>
                                        </div>
                                    </div>   
                                </div> 
                                <div className="perguntas">
                                    <div> 
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="nome">Nome *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="nome">
                                                        <i className="fas fa-user" id="iconNome"></i>
                                                        <input
                                                        type="text"
                                                        placeholder="&nbsp;Informe o Nome do fornecedor"
                                                        autoComplete="on"
                                                        value={this.state.FmodalCadastrarNome}
                                                        onChange={event => this.setState({ FmodalCadastrarNome: event.target.value })}
                                                        required
                                                        disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="email">E-mail</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="email">
                                                        <i className="far fa-envelope" id="iconEmail"></i>
                                                        <input
                                                            type="email"
                                                            placeholder="&nbsp;Informe o E-mail do fornecedor"
                                                            autoComplete="on"
                                                            value={this.state.FmodalCadastrarEmail}
                                                            onChange={event => this.setState({ FmodalCadastrarEmail: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="rg">CNPJ/CPF</label>
                                                    <div className="grupo-campo-input input-cad disabled" id="rg">
                                                        <i className="fas fa-id-badge" id="iconRg"></i>
                                                        <input disabled={true} maxLength="18" type="text" value={this.state.FmodalCadastrarCnpj} onChange={a => {this.maskCnpjCpf(a.target.value, 2); this.validarCPF(a.target.value)}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="telefone">Telefone *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="telefone">
                                                        <i className="fas fa-phone" id="iconTelefone"></i>
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            maskChar="_"
                                                            alwaysShowMask="true"
                                                            type="tel"
                                                            placeholder="&nbsp;Informe o Telefone do fornecedor"
                                                            autoComplete="on"
                                                            value={this.state.FmodalCadastrarTelefone}
                                                            onChange={event => this.setState({ FmodalCadastrarTelefone: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                        <p className="subtitle-form">Informações sobre endereço</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cep">CEP *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cep">
                                                        <i className="fas fa-envelope" id="iconCep"></i>
                                                        <InputMask
                                                            mask="99999-999"
                                                            maskChar="_"
                                                            alwaysShowMask="true"
                                                            type="text"
                                                            placeholder="&nbsp;Informe o Código Postal (CEP) do fornecedor"
                                                            autoComplete="on"
                                                            value={this.state.FmodalCadastrarCep}
                                                            onChange={event => this.getCEP(event, "cadastrar")}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="estado">Estado *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="estado">
                                                        <i className="fas fa-map-marker-alt" id="iconEstado"></i>
                                                        <select
                                                            name="estado"
                                                            value={this.state.FmodalCadastrarEstado}
                                                            placeholder="&nbsp;Selecione o Estado de residência do fornecedor"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ FmodalCadastrarEstado: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        >
                                                            <option value="NULL">Selecione o Estado de residência do fornecedor</option>
                                                            <option value='AC'>Acre</option>
                                                            <option value='AL'>Alagoas</option>
                                                            <option value='AP'>Amapá</option>
                                                            <option value='AM'>Amazonas</option>
                                                            <option value='BA'>Bahia</option>
                                                            <option value='CE'>Ceará</option>
                                                            <option value='DF'>Distrito Federal</option>
                                                            <option value='ES'>Espírito Santo</option>
                                                            <option value='GO'>Goiás</option>
                                                            <option value='MA'>Maranhão</option>
                                                            <option value='MT'>Mato Grosso</option>
                                                            <option value='MS'>Mato Grosso do Sul</option>
                                                            <option value='MG'>Minas Gerais</option>
                                                            <option value='PA'>Pará</option>
                                                            <option value='PB'>Paraíba</option>
                                                            <option value='PR'>Paraná</option>
                                                            <option value='PE'>Pernambuco</option>
                                                            <option value='PI'>Piauí</option>
                                                            <option value='RJ'>Rio de Janeiro</option>
                                                            <option value='RN'>Rio Grande do Norte</option>
                                                            <option value='RS'>Rio Grande do Sul</option>
                                                            <option value='RO'>Rondônia</option>
                                                            <option value='RR'>Roraima</option>
                                                            <option value='SC'>Santa Catarina</option>
                                                            <option value='SP'>São Paulo</option>
                                                            <option value='SE'>Sergipe</option>
                                                            <option value='TO'>Tocantins</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="cidade">Cidade *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="cidade">
                                                        <i className="fas fa-city" id="iconCidade"></i>
                                                        <input
                                                            type="text"
                                                            value={this.state.FmodalCadastrarCidade}
                                                            placeholder="&nbsp;Informe a Cidade de residência do fornecedor"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ FmodalCadastrarCidade: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="logradouro">Logradouro *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="logradouro">
                                                        <i className="fas fa-road" id="iconLogradouro"></i>
                                                        <input
                                                            type="text"
                                                            value={this.state.FmodalCadastrarLogradouro}
                                                            placeholder="&nbsp;Informe o Logradouro (Rua/Avenida) do fornecedor"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ FmodalCadastrarLogradouro: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="numero">Número *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="numero">
                                                        <i className="fas fa-home" id="iconNumero"></i>
                                                        <input
                                                            type="text"
                                                            placeholder="&nbsp;Informe o Número de Residência do fornecedor"
                                                            autoComplete="on"
                                                            value={this.state.FmodalCadastrarNumero}
                                                            onChange={event => this.setState({ FmodalCadastrarNumero: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="bairro">Bairro *</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="bairro">
                                                        <i className="fas fa-map-marked-alt" id="iconBairro"></i>
                                                        <input
                                                            type="text"
                                                            value={this.state.FmodalCadastrarBairro}
                                                            placeholder="&nbsp;Informe o Bairro do fornecedor"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ FmodalCadastrarBairro: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="grupo-input input-style-cad">
                                                    <label htmlFor="complemento">Complemento</label>
                                                    <div className={!this.state.is_pessoa ? "grupo-campo-input input-cad disabled" : "grupo-campo-input input-cad"} id="complemento">
                                                        <i className="fas fa-asterisk" id="iconComplemento"></i>
                                                        <input
                                                            type="text"
                                                            value={this.state.FmodalCadastrarComplemento}
                                                            placeholder="&nbsp;Informe o Complemento (Bloco/Andar/Apart.) do fornecedor"
                                                            autoComplete="on"
                                                            onChange={event => this.setState({ FmodalCadastrarComplemento: event.target.value })}
                                                            required
                                                            disabled={!this.state.is_pessoa}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            
                                        <div className="aviso-formulario aviso-formulario-err ">
                                            <p className="aviso text-right">{this.state.errorMessage}</p>
                                        </div>
                                        <div className="btn-prox-volt justify-content-between">
                                            <button className="btn-voltar" onClick={() => this.slide(-1)}>Voltar</button>
                                            <button onClick={() => {
                                                this.apiCadastrarProduto()
                                            }}>Cadastrar<i class="fas fa-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </div>      															
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
					
            </>
        )
    }
}

export default ProdutoCadastroPage
