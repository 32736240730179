import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

class RelatorioComprasPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anos: [],
            compras: [],
            comprasF: [],
            inicio: '',
            fim: '',
            empresas: [],
			clientes: [],
            itens: [],
            dadosF: [],
            pagina: 1, 
            quantidade_paginas: 1,
            vendedor: '',
            empresa: '',
            placa: '',
            modelosvei: [],
            modelo: '',
            clientFocus2: false,
            veiculo: '',
		};
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/compras/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarCompras();
            this.apiListarAnos();
            this.apiListarEmpresas();
            this.apiListarModelos();
        }
    }

    apiListarCompras = async event => {
        let res = await api_topmotos.get('/compras/all');

        this.setState({ 
            compras: res.data, 
            comprasF: res.data,
            quantidade_paginas: Math.ceil(res.data.length / 10) });
    }

    // Modelos
    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    filtrar = async (veiculo, ano, inicio, fim, empresa, placa, modelo) => {
        let res = this.state.compras;

        if (veiculo) {
            res = await res.filter((compras) => {
                var data = compras.item_modelo + ' - ' + compras.placa
                return data.toLowerCase().includes(veiculo)
            })
        }

        if (ano) {
            res = await res.filter((compras) => {
                var data = compras.item_ano_fab;
                return data == ano
            })
        }

        if(inicio && fim){
            res = await res.filter((compras) => {
                var data = moment(compras.data).format('YYYY-MM-DD');
                
                return data >= inicio && data <= fim
            })
        }

        if(empresa){
            res = await res.filter((compras) => {
                var data = compras.empresa_id;
                return data == empresa
            })
        }

        if(placa){
            res = await res.filter((compras) => {
                var data = compras.placa;
                
                return data == placa
            })
        }

        if(modelo){
            res = await res.filter((compras) => {
                var data = compras.item_modelo;
                
                return data == modelo
            })
        }

        // if(inicio && fim){
        //     let res = await this.state.compras.filter((compras) => {
        //         var data = compras.data;
                
        //         return data >= inicio &&
        //             data <= fim
        //     })

        //     if(empresa){
        //         res = await res.filter((compras) => {
        //             var data = compras.empresa_id;
        //             return data == empresa
        //         })
        //     }

        //     this.setState({comprasF: res, quantidade_paginas: Math.ceil(res.length / 10)})

        // } else if(empresa){
        //     let res = await this.state.compras.filter((compras) => {
        //         var data = compras.empresa_id;

        //         return data == empresa
        //     })

        //     this.setState({comprasF: res, quantidade_paginas: Math.ceil(res.length / 10)})
        // }

        this.setState({comprasF: res, quantidade_paginas: Math.ceil(res.length / 10)})
        
    }

    apiListarAnos = async event => {
		let res = await api_topmotos.get('/anosAll/group');
		this.setState({ anos: res.data });
    }
    
    apiListarEmpresas = async event => {
		let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
    }
    
    apiListarClientes = async event => {
		let res = await api_topmotos.get('/clientes/');
		this.setState({ clientes: res.data });
	}

	apiListarItens = async event => {
		let res = await api_topmotos.get('/itens/');
		this.setState({ itens: res.data });

		let selectOptions = this.state.itens.map((item, id) => (
			{ value: item.item_id, label: item.item_modelo + " - Cor: " + item.cor }
		))

		this.setState({ options: selectOptions });
    }
    
    log(msg) {
        console.log("[" + this.getTimestamp() + "] fornecedorUPDATE/READ > " + msg);
    }

    getTimestamp() {
        var today = new Date();
        var date = today.getFullYear() + '-'
            + ('0' + (today.getMonth() + 1)) + '-'
            + ('0' + today.getDate()).slice(-2);

        var time = ('0' + today.getHours()).slice(-2) + ':'
            + ('0' + today.getMinutes()).slice(-2) + ':'
            + ('0' + today.getSeconds()).slice(-2);

        var dateTime = date + ' ' + time;
        return dateTime;
    }

    pesquisar = () => {
        this.filtrar(this.state.veiculo, this.state.ano, this.state.inicio, this.state.fim, this.state.empresa, this.state.placa, this.state.modelo)
    }

    configModelo = async (e) => {
        this.setState({ modelo: e });

        // let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))
        // if (c.length > 0) {
        //     this.setState({ modalCadastrarModelo: c[0].nome })
        // }
        // else {
        //     this.setState({ modalCadastrarModelo: e })
        // }
    }

    render(){
        return(
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="relatorio-head">
                                <strong>
                                    <p>Relatório Compras</p>
                                </strong>
                            </div>
                            <div className="relatorio-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="dataI">Data Início</label>
                                            <div className="grupo-campo-input input-cad" id="dataI">
                                                <i className="far fa-calendar-alt" id="iconDataI"></i>
                                                <input type="date" onChange={a => this.setState({inicio: a.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="dataF">Data Fim</label>
                                            <div className="grupo-campo-input input-cad">
                                                <i className="far fa-calendar-alt" id="iconDataF"></i>
                                                <input type="date" id="dataF" onChange={a => {this.setState({fim: a.target.value})}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="empresa">Empresa</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i className="fas fa-building" id="iconEmpresa"></i>
                                                <select onChange={a => this.setState({empresa: a.target.value})}>
                                                    <option value="">Selecione a empresa</option>
                                                    { this.state.empresas.map((emp) => (
                                                        <option value={emp.empresa_id}>{emp.empresa_razaosocial}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="placa">Placa</label>
                                            <div className="grupo-campo-input input-cad" id="placa">
                                                <i className="fa fa-car" id="iconPlaca"></i>
                                                <input type="text" placeholder="Informe a placa" onChange={e => this.setState({placa: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="modelo" id="lblModelo">Modelo </label>
                                            <div className="grupo-campo-input input-cad" id="modelo">
                                                <i className="fas fa-asterisk" id="iconModelo"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Modelo do item"
                                                    autoComplete="on"
                                                    onChange={event => this.configModelo(event.target.value)}
                                                    required
                                                    onFocus={a => this.setState({ clientFocus2: true })} 
                                                    onBlur={a => this.setState({ clientFocus2: false })}
                                                    value={this.state.modelo}

                                                />
                                            </div>
                                            <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                {
                                                    this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(this.state.modelo.toLowerCase())).map(e => (
                                                        <li onClick={a => this.setState({modelo: e.nome})}>{e.nome}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="empresa">Ano de Fabricação</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i className="fa fa-calendar" id="iconEmpresa"></i>
                                                <select onChange={a => this.setState({ano: a.target.value})}>
                                                    <option value="">Selecione o Ano</option>
                                                    { this.state.anos.map((ano) => (
                                                        <option value={ano.ano}>{ano.ano}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="veiculo">Palavra Chave</label>
                                            <div className="grupo-campo-input input-cad" id="veiculo">
                                                <i className="fas fa-search" id="iconVeiculo"></i>
                                                <input type="text" placeholder="Buscar por palavra chave" onChange={e => this.setState({veiculo: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-campo-input input-cad">
                                            <label style={{height: '19px'}}></label>
                                            <div className="grupo-campo-input input-cad">
                                                <button className="btn-env btn-add" onClick={() => this.pesquisar()}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="txt-info">
                                            <p>Total de compras</p>
                                            <p>{this.state.comprasF.length}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        ID
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Vendedor Veículo
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Veículo
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Data
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Valor
                                                        <i className="fas fa-sort"></i>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="d-flex align-center justify-between">
                                                        Ações
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.state.comprasF.slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map((compra, id) => (
                                                    <tr key={compra.id}>
                                                        <td>{compra.id}</td>
                                                        <td>{compra.pessoa_nome}</td>
                                                        <td>{compra.item_modelo + ' - ' + compra.placa}</td>
                                                        <td>{moment(compra.data).format("DD/MM/YYYY HH:mm")}</td>
                                                        <td>{compra.valor_compra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td className="td-acoes">
                                                            {<i className="fas fa-print" onClick={() => this.props.history.push("../compras/detalhe/" + compra.id)}></i>}
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                    <div className="form-footer">
                                        <span></span>
                                    </div>
                                </div>
                                {this.state.comprasF.length > 0 && <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                    }}>
                                        {<i class="fas fa-angle-left arrows"></i>}
                                    </div>
                                    {
                                        [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                                (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                            ){
                                                return (
                                                    <div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
                                                    onClick={() => {
                                                        this.setState({pagina: index + 1})
                                                    }}>
                                                        {index + 1}
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null
                                            }
                                        })
                                    }
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                                    }}>
                                        {<i class="fas fa-angle-right arrows"></i>}
                                    </div>
                                </div>}
                            </div>
                            <div className="relatorio-footer">
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export default RelatorioComprasPage