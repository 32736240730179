import React from 'react';
import { api_topmotos } from '../services/api';
import "../styles/style.css";

class Versionamento extends React.Component {

    constructor(props) {
        super(props);
    }
    
	render() {
		return (
            <>
                <p className="versao">
                    1.12.0v - 20210514/1
                </p>
            </>
		)
	}
}

export default Versionamento;
