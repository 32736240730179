import React from 'react';
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import moment from 'moment';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            despesas: [],
            itens: [],
            itensD: [],
            status: [],
            statusSE: 0,
            istatus: 0,

            dateS: new Date(2000, 1, 1),
            dateF: new Date(),

            ssDateS: '',
            ssDateF: '',
            modalopen: false,
            modalDetalhes: false,
            selecSE: -1,
            status: [],
            selectStatus: 0,
            checklistD: [],
            checklist: [],
            img: '',
            imgs: [],
            vei: '',
            pessoas: [],
            capa: [],
            empresas: [],
            empresa: localStorage.getItem('usuario_empresa'),
            clientes: [],
            permitir: '',
            permitirD: '',
            permitirS: '',
            
            clientFocus2: false,
            clientFocus1: false,
            modelosvei: [],
            modeloSE: '',
            modalCadastrarModelo: '',
            cilindrada: '',
            statusS: [],
            checklists: [],
            check: {}
        }
    }

    async componentDidMount() {
        this.apiListarStatus();
        this.apiListarPessoas();
        this.apiListarItens();
        this.apiListarDespesas();
        this.apiListarEmpresas();
        this.apiListarClientes();
        this.respostaChecklist();
        this.apiListarPermissao();
        this.apiListarModelos();
        this.apiListarItensGoogleImages();
    }

    apiListarDespesas = async event => {
        let res = await api_topmotos.get('/despesas/');

        this.setState({despesas: res.data})
    }

    apiListarItens = async event => {
        let res = await api_topmotos.get('/itens/');
        console.log(res.data)
        // for(let i of res.data){
        //     if(i.itemfotos_foto){
        //         i.itemfotos_foto = await this.apiListarCapa(i.item_id, i.itemfotos_foto)
		// 	}
        // }

        let gest = await api_topmotos.get('/gestao');
        let itens = res.data.map(i => {
            i.gestao = Object.values(gest.data).filter(g => { return g.item_id === i.item_id })[0]
            return i
        })
        
        this.setState({ itensD: itens.filter(y => y.compra == 1), itens: itens.filter(y => y.compra == 1) });
    }

    apiListarItensGoogleImages = async e => {
        let res = await api_topmotos.get('/itens/googleImages');
        if (res.length == 0) return;
        
        let itens = this.state.itens.map((r) => {
            let item = res.data.filter(y => y.item_id == r.item_id);
            if (item.length > 0) { 
                r.byGoogle = true
                r.capa_item = item[0].capa_item 
            };
            return r
        })
        let itensD = this.state.itensD.map((r) => {
            let item = res.data.filter(y => y.item_id == r.item_id);
            if (item.length > 0) { 
                console.log(item[0])
                r.capa_item = item[0].capa_item 
            };
            return r
        })

        this.setState({itens, itensD})
    }

    apiListarEmpresas = async event => {
		let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
    }

    apiListarClientes = async event => {
		let res = await api_topmotos.get('/venda/cliente');
		this.setState({ clientes: res.data });
    }

    apiListarStatus = async event => {
        let res = await api_topmotos.get('/status/ordem');
        this.setState({ status: res.data, statusSE: res.data[0], statusS: res.data });
    }

    apiListarPessoas = async event => {
        let res = await api_topmotos.get('/pessoas');
        this.setState({ pessoas: res.data});
    }

    apiListarCapa = async (item, foto) => {
        let res = await api_topmotos.post('/itens/capa/unique/' + item);
        if(res.data.length > 0){
            return(res.data[0].foto)
        } else{
            return(foto)
        }
    }

    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    apiListarPermissao = async event => {
		let res = await api_topmotos.get('/permissaoSistema/user/' + localStorage.getItem('usuario_id'));
        let editar = res.data.filter(a => a.restricao_id == 2);
        let despesa = res.data.filter(a => a.restricao_id == 3);
        let status = res.data.filter(a => a.restricao_id == 6);

        this.setState({permitir: editar.length > 0 ? true : false, 
            permitirD: despesa.length > 0 ? true : false, 
            permitirS: status.length > 0 ? true : false})
    }

    passge = async () => {
        let s = this.state.istatus + 1;

        if (this.state.status[s] == undefined) {
            s = 0;
            this.setState({ statusSE: this.state.status[s], istatus: s })
            return
        }

        this.setState({ statusSE: this.state.status[s], istatus: s })
    }

    togglemodal = async (id, s) => this.setState({ modalopen: !this.state.modalopen, selecSE: id, selectStatus: s })

    saveChange = async () => {

        let { data } = await api_topmotos.post('/itens/dash', {
            status: this.state.selectStatus,
            id: this.state.selecSE
        });

        this.setState({
            itens: data,
            modalopen: false
        })

        this.apiListarItens();
        this.apiListarItensGoogleImages();
    }

    ano = (ano, fab) => {

        ano = ano == 3200 ? '0km' : ano.toString().slice(2, 4) ;
        fab = fab ? fab.toString().slice(2, 4) : '';
        
        let anos = fab + "/" + ano;
        return(anos)
    }

    toggleModals = () => {
		this.setState({ modalopen: !this.state.modalopen });
    }

    toggleModalDetalhes = () => {
		this.setState({ modalDetalhes: !this.state.modalDetalhes });
    }
    
    abrirDetalhes = async (id, img, nome) => {
        let res = await api_topmotos.post('/checklistD/resposta/' + id);
        this.setState({checklistD: res.data, img: img, nome: nome})

        let ret = await api_topmotos.post('/checklist/resposta/' + id);
        this.setState({checklist: ret.data})

        let req = await api_topmotos.post('/itens/fotos/' + id);
        if(req.data.length > 0){
            this.setState({imgs: req.data, img: req.data[0].itemfotos_id})
        }
        
        this.toggleModalDetalhes()
    }

    slide = (arrow) =>{
        let img = document.getElementsByClassName('img');
        let aberta;

        for(let i = 0; i < img.length; i++){
            if(img[i].style.display == 'block'){
                aberta = i;
            }

            img[i].style.display = 'none'
        }

        let abertaAgr = aberta + arrow;

        if(abertaAgr == img.length){
            img[0].style.display = 'block';
        } else if (abertaAgr == -1){
            img[img.length-1].style.display = 'block';
        } else{
            img[abertaAgr].style.display = 'block';
        }
    }

    pesquisar = (final) =>{
        let  itens = this.state.itens.filter(a => moment(a.item_admissaodata).format('YYYY-MM-DD') >= this.state.ssDateS && moment(a.item_admissaodata).format("YYYY-MM-DD") <= final)
        this.setState({itensD: itens})
    }

    comprador = (item_id) =>{
        var item = this.state.clientes.filter(a => a.vendaitens_item == item_id)
        return (item[0] ? item[0].pessoa_nome : '')
    }

    configModelo = async (e) => {
        this.setState({ modeloSE: e });

        if (e.length == 0) {
            this.setState({ modalCadastrarModelo: null })
            return
        }

        let c = this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(e.toLowerCase()))
        if (c.length > 0) {
            this.setState({ modalCadastrarModelo: c[0].nome })
        }
        else {
            this.setState({ modalCadastrarModelo: e })
        }
    }

    pesquisarStatus = (id) =>{
        var filter = this.state.status.filter(a => a.id == id)
        this.setState({statusS: filter.length == 0 ? this.state.status : filter})
    }

    pesquisarCilindrada = (nome) =>{
        var filter = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(nome.toLowerCase()))
        this.setState({itensD: filter})
    }

    pesquisarModelo = (nome) =>{
        var filter = this.state.itens;

        if(nome){
            filter.filter(y => y.item_modelo.toLowerCase().includes(nome.toLowerCase()))
    
            if(this.state.cilindrada){
                filter = filter.filter(y => y.item_modelo.toLowerCase().includes(this.state.cilindrada.toLowerCase()))
            }
        } else{
            if(this.state.cilindrada){
                filter = filter.filter(y => y.item_modelo.toLowerCase().includes(this.state.cilindrada.toLowerCase()))
            }
        }

        this.setState({itensD: filter})
    }

    pesquisarModelo = (nome) =>{
        var filter = this.state.itens.filter(y => y.item_modelo.toLowerCase().includes(nome.toLowerCase()))

        if(this.state.cilindrada){
            filter = filter.filter(y => y.item_modelo.toLowerCase().includes(this.state.cilindrada.toLowerCase()))
        }
        
        this.setState({itensD: filter})
    }

    openPesquisa = () =>{
        var barra = document.getElementById('barra');

        if(barra.style.display == 'flex'){
            barra.style.display = 'none';
        } else{
            barra.style.display = 'flex';
        }
    }

    respostaChecklist = async () =>{
        let res = await api_topmotos.get('/checklistD/resposta');
        let c = {};

        for (let i of res.data){
            c[`${i.item_id}`] = 1
        }

        this.setState({check: c});
    }

    recibo = (id) =>{
        let res = api_topmotos.post('/checklistD/resposta/' + id);
        // console.log(res.data)

        // return '<p>oii<p>'
    }

    gastos = (despesas, compra) =>{
        console.log(compra)
		var valor = compra, despesa = [];
		this.state.despesas.map(a => (
			despesa.push(despesas.find(i => i.despesa == a.id))
		))

		for(let i of despesa){
			if(i){
				valor += i.valor
			}
		}

		return valor ? valor : ''
	}

    render() {
        return (
            <>
                <Modal isOpen={this.state.modalopen} toggle={this.toggleModals.bind(this)}>
                    <ModalHeader>
                        Trocar status
                    </ModalHeader>
                    <ModalBody>
                        <select value={this.state.selectStatus} onChange={s => this.setState({ selectStatus: s.target.value })}>
                            {
                                this.state.status.map(e => (
                                    <option value={e.id}>{e.status}</option>
                                ))
                            }
                        </select>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btnora" onClick={a => this.saveChange()}>Salvar</Button>
                    </ModalFooter>
                </Modal>
                
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />

                        <div className="content-session">
                            <NavUsuario />

                            <div className="barra-mob" onClick={() => this.openPesquisa()}>
                                <p>Barra de pesquisa</p>
                                <i className="fas fa-angle-down"></i>
                            </div>
                            {/* <div className="barra-pesquisa" id="barra">
                                <div className="barra-item">
                                    <p>Data Inicial</p>
                                    <input type="date" onChange={a => this.setState({ ssDateS: a.target.value })} />
                                </div>
                                <div className="barra-item">
                                    <p>Data Final</p>
                                    <input type="date" onChange={a => {this.setState({ ssDateF: a.target.value }); this.pesquisar(a.target.value)}} />
                                </div>
                                <div className="barra-item">
                                    <p>Empresa</p>
                                    <select defaultValue={this.state.empresa} onChange={e => this.setState({empresa: e.target.value})}>
                                        <option value="">Selecione</option>
                                        {this.state.empresas.map((emp) => (
                                            <>
                                                <option value={emp.empresa_id}>{emp.empresa_razaosocial}</option>
                                            </>
                                        ))}
                                    </select>
                                </div>
                                <div className="btn-limpar">
                                    <i className="fas fa-backspace pr-2"></i>
                                    Limpar filtros
                                </div>
                            </div> */}

                            <div className="barra-pesquisa" id="barra">
                            <div className="barra-item">
                                    <p>Cilindrada</p>
                                    <div className="grupo-input input-style-cad">
                                        <input
                                        type="text"
                                        placeholder="&nbsp;Informe a Cilindrada"
                                        onChange={event => {this.setState({cilindrada: event.target.value}); this.pesquisarCilindrada(event.target.value)}}
                                        />
                                    </div>
                                </div>
                                <div className="barra-item">
                                    <p>Modelo</p>
                                    <div className="grupo-input input-style-cad">
                                        <input
                                        type="text"
                                        placeholder="&nbsp;Informe o Modelo do item"
                                        autoComplete="on"
                                        onChange={event => {this.configModelo(event.target.value)}}
                                        required
                                        onFocus={a => this.setState({ clientFocus2: true })} 
                                        onBlur={a => this.setState({ clientFocus2: false })}
                                        value={this.state.modeloSE}
                                        />
                                        <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                            <li onClick={a => {this.setState({modeloSE: '', modalCadastrarModelo: ''}); this.pesquisarModelo('')}}>Nenhum</li>
                                            {
                                                (this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(this.state.modeloSE.toLowerCase())).map(e => (
                                                    <li onClick={a => {this.setState({modeloSE: e.nome, modalCadastrarModelo: e.nome}); this.pesquisarModelo(e.nome)}}>{e.nome}</li>
                                                )))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="barra-item">
                                    <p>Status</p>
                                    <div className="grupo-input input-style-cad">
                                        <select defaultValue={this.state.statusSE} onChange={e => {this.setState({statusSE: e.target.value}); this.pesquisarStatus(e.target.value)}}>
                                            <option value="">Selecione</option>
                                            {this.state.status.map((s) => (
                                                <>
                                                    <option value={s.id}>{s.status}</option>
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="barra-item">
                                    <p>Empresa</p>
                                    <div className="grupo-input input-style-cad">
                                        <select defaultValue={this.state.empresa} onChange={e => this.setState({empresa: e.target.value})}>
                                            <option value="">Selecione</option>
                                            {this.state.empresas.map((emp) => (
                                                <>
                                                    <option value={emp.empresa_id}>{emp.empresa_razaosocial}</option>
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                                {
                                    this.state.statusS.map(r => (
                                        <div className="gridviewer">
                                            <div class="barra-slide">
                                                <div>
                                                    <p>{r.status}</p>
                                                </div>
                                            </div>
                                                {
                                                    this.state.itensD.filter(y => y.status == r.id && y.empresa_id == this.state.empresa).map(e => (
                                                        <div className="card-item">
                                                            <div className="viewer" style={{backgroundImage:`url("${e.capa_item ? e.capa_item : e.itemfotos_foto ? e.itemfotos_foto : require('../../imgs/sFoto.png')}")`}}>
                                                                {r.byGoogle ? <p>Images By Google</p> : null}
                                                            </div>
                                                            {
                                                                r.vincular_cliente == 1 ? (
                                                                    <div className="card-headerX">
                                                                        <div className="icone-cliente">
                                                                            <p>{this.comprador(e.item_id).substr(0, 1)}</p>
                                                                        </div>
                                                                    <p className="nome-cliente">{this.comprador(e.item_id)}</p>
                                                                    </div>
                                                                ) : (null)
                                                            }
                                                            <div className="card-body">
                                                                <div className="auto-modelo">
                                                                    <div className="d-flex justify-content-between align-items-start">
                                                                        <p>{e.item_marca} {e.item_modelo}<br></br>
                                                                            <span className="span-orange">{e.item_preco ? e.item_preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</span>                        
                                                                            <br />
                                                                            {<span className="span-orange">{e.gestao && e.gestao.comissao
																			? `Comissão: ${Number((e.gestao.venda - this.gastos(e.gestao.despesa, e.gestao.valor)) * (e.gestao.comissao / 100)) > 120
																			    ? ((e.gestao.venda - this.gastos(e.gestao.despesa, e.gestao.valor)) * (e.gestao.comissao / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})
																			    : (120).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})
                                                                            }` : ''
																			}</span>}
                                                                        </p>
                                                                        <div className="icone">
                                                                        {
                                                                            r.id != 3 && this.state.permitirS ? (
                                                                                <i className="fas fa-exchange-alt" onClick={() => this.togglemodal(e.item_id, e.status)}></i>
                                                                            ) : (null)
                                                                        }
                                                                            {this.state.permitir ? <i className="far fa-edit" onClick={() => this.props.history.push('/editaritens/' + e.item_id)}></i> : ''}
                                                                            {this.state.permitirD ? <i className="fas fa-dollar-sign" onClick={() => this.props.history.push('/despesasItem/' + e.item_id)}></i> : ''}
                                                                            <i className="fas fa-ellipsis-h" onClick={() => this.abrirDetalhes(e.item_id, e.itemfotos_foto, e.item_modelo)}></i>
                                                                        </div>
                                                                    </div>
                                                                    <p>{e.tipo}</p>
                                                                </div>
                                                                {this.state.check[e.item_id] ? <div className="label">
                                                                    <p>Sem recibo</p>
                                                                </div> : ''}
                                                                <div className="card-caracteristicas">
                                                                    {e.placa ? <div className="carac-item">
                                                                        <p>{e.placa}</p>
                                                                    </div> : ''}
                                                                    <div className="carac-item">
                                                                        <p>{e.cor}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="card-caracteristicas">
                                                                    <div className="carac-item">
                                                                        <p>{e.item_km}Km</p>
                                                                    </div>
                                                                    <div className="carac-item">
                                                                        <p>{this.ano(e.ano_mod, e.item_ano_fab)}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="card-timer">
                                                                    {e.venda_data ?
                                                                        90 - moment.duration(moment().diff(moment(e.venda_data))).asDays() <= 0
                                                                        ? "Fora da Garantia" 
                                                                        : 90 - Math.floor(moment.duration(moment().diff(moment(e.venda_data))).asDays()) + ' Dias'
                                                                    : "Fora da Garantia" 
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                       </div>
                                    ))
                                }
                            <Versionamento/>
                        </div>    
                    </div>
                </section>
            
                <Modal
					isOpen={this.state.modalDetalhes}
					toggle={this.toggleModalDetalhes.bind(this)}
					backdrop={true}
					keyboard={true}
					size="lg"
					fade={false}>
						<ModalHeader>Detalhes - {this.state.nome}</ModalHeader>
						<ModalBody>
                            {
                                this.state.imgs.length > 0 ? 
                                <div className="modal__img">
                                    <i className="fas fa-angle-left" onClick={() => this.slide(-1)}></i>
                                    {
                                        this.state.imgs.map((img) => (
                                            <img src={img.foto} className="img" style={{display: img.itemfotos_id == this.state.img ? 'block' : 'none'}} alt="" />
                                        ))
                                    }
                                    <i className="fas fa-angle-right" onClick={() => this.slide(1)}></i>
                                </div> : ''
                            }
                            {this.state.checklistD.length > 0 ? <h1 className="header-relatorio" style={{fontSize: '16px'}}>Checklist Documentos</h1> : '' }
                            <div className="row py-2">
                                { this.state.checklistD.map((check, i) => (
                                    <div className="resposta col-md-6">
                                        <p className="title">{i+1}. {check.pergunta}</p>
                                        <p className="text">{check.alternativa}<br/>{check.respostas_checklist_documento ? check.respostas_checklist_documento : ""}</p>
                                    </div>
                                    ))
                                }
                            </div>
                            {this.state.checklist.filter(a => a.resposta != '').length > 0 ? <h1 className="header-relatorio" style={{fontSize: '16px'}}>Checklist Veículo</h1> : ''}
                            <div className="row py-2">
                                { this.state.checklist.filter(a => a.resposta != '').map((check, i) => (
                                    <div className="resposta col-md-6">
                                        <p className="title">{i+1}. {check.pergunta}</p>
                                        <p className="text">{check.resposta}</p>
                                    </div>
                                    ))
                                }
                            </div>
                        
                        </ModalBody>
						<ModalFooter>
							<Button onClick={() => this.toggleModalDetalhes()}>Fechar</Button>
						</ModalFooter>
					</Modal>

            </>
        )
    }
}

export default DashboardPage
