import React from 'react';
import { api_topmotos } from '../../services/api';
import moment from 'moment'


class PDFPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            modelo: '',
            iten: [],
            cliente: '',
            clientes: [],
            movimentacoes: [],
            tabela: '',
            obs: ''
        }
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/vendas" || a.rota == "/vendas/relatorio")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

    async componentDidMount(){

        // if(await this.verificarPermissao()){
        //     this.props.history.push('/dashboardcomercial')
        // } else{
            await this.apiListarModelo(this.props.match.params.modelo)
            await this.apiListarIten(this.props.match.params.iten)
        // }
    }

    apiListarModelo = async (id) => {
        let {data} = await api_topmotos.post("/modelosdoc/unique", {id: id})
        this.setState({modelo: data[0].conteudo})   
    }

    apiListarIten = async (id) => {
        let {data} = await api_topmotos.post("/vendas/unique", {id: id})

        this.setState({obs: data.venda[0].observacao_pag})

        await this.apiClienteId(data.venda[0].venda_cliente);
        await this.apiVeiculoId(data.itens[0].vendaitens_item, data.itens[0].vendaitens_km);
        await this.apiEmpresaId(data.venda[0].venda_empresa);
        await this.apiVendedorId(data.venda[0].venda_usuario);
        await this.apiMovimentacoesId(id);

        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@horarioVenda/g, moment(data.venda[0].venda_admissaodata).format('HH:mm'))
        .replace(/@dataVenda/g, new Date(data.venda[0].venda_admissaodata).toLocaleString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric'}))
        this.setState({modelo: modelo}) 

        var tabelas = document.getElementsByTagName('table');

        for(var i = 0; i < tabelas.length; i++){
            tabelas[i].classList.add('table-black')
        }

        await this.openPdf()
    }

    apiClienteId = async (id) =>{
        let {data} = await api_topmotos.get("/pessoas/id/" + id)

        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@nomePessoa/g, data[0].pessoa_nome)
        .replace(/@cpfPessoa/g, data[0].pessoa_cpf_cnpj)
        .replace(/@ruaPessoa/g, data[0].endereco_logradouro)
        .replace(/@nPessoa/g, data[0].endereco_numero)
        .replace(/@cidadePessoa/g, data[0].endereco_cidade)
        .replace(/@estadoPessoa/g, data[0].endereco_estado)
        .replace(/@telPessoa/g, data[0].pessoa_celular ? data[0].pessoa_celular + " </br> " : '')
        .replace(/@celPessoa/g, data[0].pessoa_telefone)
        .replace(/@cepPessoa/g, data[0].endereco_cep)
        .replace(/@BairroPessoa/g, data[0].endereco_bairro)
        .replace(/@emailPessoa/g, data[0].pessoa_email)
        this.setState({modelo: modelo})   
        
    }

    apiVeiculoId = async (id, vendaKm) =>{
        let {data} = await api_topmotos.post("/itens/unique", {id: id})

        document.title = `Termo de responsabilidade - ${data[0].item_modelo} - ${data[0].placa}`

        let modelo = this.state.modelo;  
    
        modelo = modelo.replace(/@marcaVeiculo/g, data[0].item_marca)
        .replace(/@modeloVeiculo/g, data[0].item_modelo)
        .replace(/@ruaCliente/g, data[0].endereco_logradouro)
        .replace(/@anoMVeiculo/g, data[0].ano_mod == 3200 ? '0Km' : data[0].ano_mod)
        .replace(/@anoFVeiculo/g, data[0].item_ano_fab)
        .replace(/@placaVeiculo/g, data[0].placa)
        .replace(/@chassiVeiculo/g, data[0].chassi)
        .replace(/@renavanVeiculo/g, data[0].renavam)
        .replace(/@estiloVeiculo/g, data[0].estilo)
        .replace(/@corVeiculo/g, data[0].cor)
        .replace(/@kmVeiculo/g, vendaKm ? vendaKm : data[0].item_km)


        this.setState({modelo: modelo}) ;
        
    }

    apiEmpresaId = async (id) =>{
        let {data} = await api_topmotos.get("/empresas/id/" + id)
            
        let modelo = this.state.modelo;  
    
        modelo = modelo.replace(/@razao_socialEmpresa/g, data[0].empresa_razaosocial)
        .replace(/@cnpjEmpresa/g, data[0].empresa_cnpj)
        .replace(/@telEmpresa/g, data[0].empresa_telefone)
        .replace(/@ruaEmpresa/g, data[0].endereco_logradouro)
        .replace(/@nEmpresa/g, data[0].endereco_numero)
        .replace(/@bairroEmpresa/g, data[0].endereco_bairro)
        .replace(/@cidadeEmpresa/g, data[0].endereco_cidade)
        .replace(/@estadoEmpresa/g, data[0].endereco_estado)

        this.setState({modelo: modelo}) ;
    }

    apiVendedorId = async (id) =>{
        let {data} = await api_topmotos.get("/usuarios/id/" + id)

        let modelo = this.state.modelo;  

        modelo = modelo.replace(/@nomeVendedor/g, data[0].usuario_nome)
        this.setState({modelo: modelo})   
        
    }

    apiMovimentacoesId = async (id) =>{
        let {data} = await api_topmotos.get("/financeiro/venda/" + id + "/termo");
        let resp = await api_topmotos.get("/tParcelamento");
        let p = {}
        for (let i of resp.data){
            p[`${i.mov}`] = i
        } 

        let modelo = this.state.modelo; 

        let res = await api_topmotos.post("/troca/" + data[0].venda_id);
        res = (res.data[0])

        let troca;

        if(res){
            troca = `<p style="padding: 10px 0px;"><strong>TROCA POR VEÍCULO</strong></p>
                <table>
                    <tbody>
                        <tr>
                            <td><p>Marca</p></td>
                            <td><p>${res.item_marca}</p></td>
                            <td><p>Modelo</p></td>
                            <td><p>${res.item_modelo}</p></td>
                            <td><p>Ano Fab.</p></td>
                            <td><p>${res.item_ano_fab}</p></td>
                        </tr>
                        <tr>
                            <td><p>Placa</p></td>
                            <td><p>${res.placa}</p></td>
                            <td><p>Cor</p></td>
                            <td><p>${res.cor}</p></td>
                            <td><p>Ano Mod.</p></td>
                            <td><p>${res.ano_mod == 3200 ? '0Km' : res.ano_mod}</p></td>
                        </tr>
                        <tr>
                            <td><p>KM</p></td>
                            <td><p>${res.item_km}</p></td>
                            <td><p>Chassi</p></td>
                            <td><p>${res.chassi}</p></td>
                            <td><p>Renavan</p></td>
                            <td><p>${res.renavam}</p></td>
                        </tr>
                    </tbody>
                </table>`;
        }

        if(data){
            let tabela = [];
            let total = 0;
            tabela.push('<table>')
            tabela.push(
                `<tr>
                    <th>Valor</th>
                    <th>Data de Vencimento</th>
                    <th>Tipo de Pagamento</th>
                </tr>`
            )
    
            for(var i of data){
                total += i.valor;
                tabela.push(
                    `<tr>
                        <td>${parseFloat(i.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        ${p[i.id] ? ` <br/>Parcelado em ${p[i.id].parcelamento} de ${parseFloat(p[i.id].valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : '' }</td>
                        <td>${moment(i.dataVencimento).format("DD-MM-YYYY")}</td>
                        <td>
                            ${i.pagamento} <br />
                            ${i.pagamento === "Financiamento" ? i.banco_financiado : ''}
                        </td>
                    </tr>`
                )
            }

            tabela.push(
                `<tr>
                    <td>${parseFloat(total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                    <th colspan="2">Total</th>
                </tr>`
            )
    
            tabela.push('</table>')
            
            if(this.state.obs){
                let obs = "<strong style='padding: 10px 0px;'>Obs</strong>: " + this.state.obs + "<br/>";
                tabela.push(obs)
            }

            tabela.push(troca)

            tabela = tabela.join('')
    
            modelo = modelo.replace(/@movimentacao/g, tabela)
        } else{
            modelo = modelo.replace(/@movimentacao/g, '')
        }

        this.setState({modelo: modelo})   
    }

    openPdf = async () =>{
        window.print();
    }

    render() {
        return (
            <>
            <section className="container d-flex align-item-center ">
                <div className="w-100">

                    <div dangerouslySetInnerHTML={{__html: this.state.modelo}}>

                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default PDFPage;
