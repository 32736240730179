import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import { Editor } from '@tinymce/tinymce-react';

import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class ModeloEditarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cont: '',
            nome: '',
            errormsg: '',
            typeC: "NULL"
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/modelos")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else {
            this.apiListarModelo(this.props.match.params.id);
        }
   }

    apiListarModelo = async (e) => {
        let { data } = await api_topmotos.post("/modelosdoc/unique", { id: e });

        this.setState({
            cont: data[0].conteudo,
            typeC: data[0].tipo,
            nome: data[0].nome
        })

    }

    apiCadastrarModelo = async () => {

        var inputs = [
            [this.state.nome, 'nome','iconNome','lblNome'],
            [this.state.typeC, 'tipo','iconTipo','lblTipo']
        ]

        if (this.state.nome && this.state.cont && this.state.typeC != "NULL") {
            await api_topmotos.post("/modelosdoc/update/id/" + this.props.match.params.id, {
                nome: this.state.nome,
                cont: this.state.cont,
                tipo: this.state.typeC
            })

            this.props.history.push("/modelos")
        }
        else {
            for(var i = 0; i < inputs.length; i++){
				if(!inputs[i][0] || inputs[i][0] == 'NULL' ){
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "border: 1px solid #FF7115; transition: 0.2s;";
					inputIcone.style.cssText = "color: #FF7115; transition: 0.2s;";
					inputNome.style.cssText = "color: #FF7115; transition: 0.2s;";
				} else{
					var inputId = document.getElementById(inputs[i][1]);
					var inputIcone = document.getElementById(inputs[i][2]);
					var inputNome = document.getElementById(inputs[i][3]);
					inputId.style.cssText = "1px solid #BFBFC3; transition: 0.2s;";
					inputIcone.style.cssText = "color: #BFBFC3; transition: 0.2s;";
					inputNome.style.cssText = "color: #BFBFC3; transition: 0.2s;";
				}
            }

            this.setState({ errormsg: "Preencha todos os campos" })
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            <div className="form-head">
                                <p><strong>Editar modelo</strong></p>

                            </div>
                            <div className="content-formulario">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="nome" id="lblNome">Nome*</label>
                                            <div className="grupo-campo-input input-cad" id="nome">
                                                <i className="fas fa-sign" id="iconNome"></i>
                                                <input value={this.state.nome} type="text" placeholder="Insira o nome" onChange={a => this.setState({ nome: a.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="tipo" id="lblTipo">Tipo*</label>
                                            <div className="grupo-campo-input input-cad" id="tipo">
                                                <i className="fas fa-print" id="iconTipo"></i>
                                                <select value={this.state.typeC} onChange={a => this.setState({ typeC: a.target.value })}>
                                                    <option value="NULL">Selecione um tipo</option>
                                                    <option value="1">Contrato</option>
                                                    <option value="2">Procuração</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Editor
                                    value={this.state.cont}
                                    apiKey="occ4ghutecdaq87x2848mvdhjlxbusf5l8615kzqopriexe1"
                                    onEditorChange={(e, ed) => this.setState({ cont: e })}
                                    // content={}
                                    init={{
                                        plugins: [
                                            "advlist autolink lists link image charmap print preview anchor",
                                            "searchreplace visualblocks code fullscreen",
                                            "insertdatetime media table paste imagetools wordcount"
                                        ],
                                        toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect image | lista",
                                        height: 500,
                                        setup: (editor) => {
                                            editor.ui.registry.addMenuButton('lista', {
                                                // text: "vars",
                                                icon: 'image-options',
                                                fetch: function (callback) {
                                                    let items = [
                                                        {
                                                            type: 'menuitem',
                                                            text: 'ID do cliente',
                                                            onAction: function (_) {
                                                                editor.insertContent("@idCliente");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Nome do cliente',
                                                            onAction: function (_) {
                                                                editor.insertContent("@nomeCliente");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Email do cliente',
                                                            onAction: function (_) {
                                                                editor.insertContent("@emailCliente");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Telefone do cliente',
                                                            onAction: function (_) {
                                                                editor.insertContent("@telCliente");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'CPF do cliente',
                                                            onAction: function (_) {
                                                                editor.insertContent("@cpfCliente");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'RG do cliente',
                                                            onAction: function (_) {
                                                                editor.insertContent("@rgCliente");
                                                            }
                                                        },
                                                        // {
                                                        //     type: 'menuitem',
                                                        //     text: 'Endereço do cliente',
                                                        //     onAction: function (_) {
                                                        //         editor.insertContent("@endCliente");
                                                        //     }
                                                        // },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'ID do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@idVeiculo");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'Marca do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@marcaVeiculo");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Modelo do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@modeloVeiculo");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Ano do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@anoVeiculo");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'Cor do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@corVeiculo");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'Combustível do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@gasVeiculo");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'Preço do veículo',
                                                            onAction: function (_) {
                                                                editor.insertContent("@precVeiculo");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'ID do fornecedor',
                                                            onAction: function (_) {
                                                                editor.insertContent("@idFornecedor");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Nome do fornecedor',
                                                            onAction: function (_) {
                                                                editor.insertContent("@nomeFornecedor");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'Telefone do fornecedor',
                                                            onAction: function (_) {
                                                                editor.insertContent("@telFornecedor");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'CNPJ/CNPJ do fornecedor',
                                                            onAction: function (_) {
                                                                editor.insertContent("@cnpjFornecedor");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Endereço do fornecedor',
                                                            onAction: function (_) {
                                                                editor.insertContent("@endFornecedor");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Email do fornecedor',
                                                            onAction: function (_) {
                                                                editor.insertContent("@emailFornecedor");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'ID da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@idVenda");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Data da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@dataVenda");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Valor da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@valorVenda");
                                                            }
                                                        },

                                                        {
                                                            type: 'menuitem',
                                                            text: 'Metodo de pagamento da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@pagVenda");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Valor de Entrada da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@entVenda");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Desconto da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@descVenda");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Parcelas da venda',
                                                            onAction: function (_) {
                                                                editor.insertContent("@parVenda");
                                                            }
                                                        },


                                                        {
                                                            type: 'menuitem',
                                                            text: 'ID da empresa',
                                                            onAction: function (_) {
                                                                editor.insertContent("@idEmpresa");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Razão social da Empresa',
                                                            onAction: function (_) {
                                                                editor.insertContent("@rsEmpresa");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Nome fantasia da Empresa',
                                                            onAction: function (_) {
                                                                editor.insertContent("@fantaEmpresa");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'CNPJ da Empresa',
                                                            onAction: function (_) {
                                                                editor.insertContent("@cnpjEmpresa");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Telefone da empresa',
                                                            onAction: function (_) {
                                                                editor.insertContent("@telEmpresa");
                                                            }
                                                        },
                                                        {
                                                            type: 'menuitem',
                                                            text: 'Email da empresa',
                                                            onAction: function (_) {
                                                                editor.insertContent("@emailEmpresa");
                                                            }
                                                        },

                                                    ];
                                                    callback(items);
                                                }
                                            })
                                        }
                                    }}
                                />
                                
                                <div className="aviso-formulario aviso-formulario-err">
                                    <p className="aviso">{this.state.errormsg}</p>
                                </div>
                                <Button onClick={this.apiCadastrarModelo} className="cadbtn">Editar</Button>
                            
                            </div>
                            <Versionamento/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ModeloEditarPage


