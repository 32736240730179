import React from 'react';
import "../../styles/style.css";
import NavMenu from '../../components/NavMenu';
import NavUsuario from '../../components/NavUsuario';
import Versionamento from '../../components/Versionamento';
import { api_topmotos } from '../../services/api';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            anos: {},
            itensF: [],
            modelosvei: [],
            modelo: '',
            placa: '',
            empresas: [],
            empresa: '',
            etapa: '',
            clientFocus2: false,
            pagina: 1, 
            quantidade_paginas: 1,

            modalDeletar: false,
            modalDeletarId: '',
            modalDeletarItem: '',
        };
    }

    verificarPermissao = async event => {
        if(localStorage.getItem('usuario_id')){
            let res = await api_topmotos.get('/permissoes/user/' + localStorage.getItem('usuario_id'));
            res = res.data.filter(a => a.rota == "/andamento")
            if(res.length == 0){
                return true
            } else{
                return false
            }
        } else{
            return true
        }
    }

	async componentDidMount() {
        if(await this.verificarPermissao()){
            this.props.history.push('/dashboardcomercial')
        } else{
            this.apiListarItens()
            this.apiListarAnos();
            this.apiListarModelos();
            this.apiListarEmpresas();
        }
    }

    apiListarItens = async event => {
		let res = await api_topmotos.get('/itens/anda');
		this.setState({ itens: res.data, itensF: res.data, quantidade_paginas: Math.ceil(res.data.length / 10)});
    }  
    
    apiListarAnos = async event => {
        let res = await api_topmotos.get('/anos/');
        let j = {};

        for (let i of res.data){
            j[`${i.id}`] = i.ano
        }

        this.setState({ anos: j});
    }

    // Off/On modal deletar
    toggleModalDeletar = () => {
		this.setState({ modalDeletar: !this.state.modalDeletar });
    }

    compraDeletar = async (id) => {
        await api_topmotos.delete('/compras/deletar/'+id);
        await this.apiListarItens();
        this.toggleModalDeletar();
    }

    // Abrir modal deletar
    OpenModalDeletar = async (id) => {
        const res = this.state.itens.filter((i) => i.item_id == id)[0]
		this.setState({
            modalDeletar: true,
			modalDeletarId: res.item_id,
            modalDeletarItem: `${res.item_marca} ${res.item_modelo} - ${res.placa}`,
		});
    }

    // Modelos
    apiListarModelos = async event => {
        let res = await api_topmotos.get('/modelos/');
        this.setState({ modelosvei: res.data });
    }

    apiListarEmpresas = async event => {
		let res = await api_topmotos.get('/empresas/');
		this.setState({ empresas: res.data });
    }
    
    pesquisar = () => {
        this.filtrar(this.state.etapa, this.state.empresa, this.state.placa, this.state.modelo)
    }

    filtrar = async (etapa, empresa, placa, modelo) => {
        let res = this.state.itens;

        if(etapa){
            res = await res.filter((item) => {
                var data = item.etapa;
                return data == etapa
            })
        }

        if(empresa){
            res = await res.filter((item) => {
                var data = item.empresa_id;
                return data == empresa
            })
        }

        if(placa){
            res = await res.filter((item) => {
                var data = item.placa;
                
                return data == placa.toUpperCase()
            })
        }

        if(modelo){
            res = await res.filter((compras) => {
                var data = compras.item_modelo;
                
                return data == modelo
            })
        }

        this.setState({itensF: res, quantidade_paginas: Math.ceil(res.length / 10)})
        
    }

    configModelo = async (e) => {
        this.setState({ modelo: e });
    }

    render() {
        return (
            <>
                <section>
                    <div className="content-all d-flex">
                        <NavMenu props={this.props} />
                        <div className="content-session">
                            <NavUsuario />
                            
                            <div className="cabecalho-head">
                                <p className="head">Compras em andamento</p>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="etapa">Etapa</label>
                                            <div className="grupo-campo-input input-cad" id="etapa">
                                                <select value={this.state.etapa} onChange={a => {this.setState({etapa: a.target.value}); this.pesquisar()}}>
                                                    <option value=''>Selecione uma etapa</option>
                                                    <option value='1'>Inserir Veículo</option>
                                                    <option value='2'>Questionário Veículo</option>
                                                    <option value='3'>Questionário Documento</option>
                                                    <option value='4'>Compra</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="empresa">Empresa</label>
                                            <div className="grupo-campo-input input-cad" id="empresa">
                                                <i className="fas fa-user" id="iconEmpresa"></i>
                                                <select onChange={a => this.setState({empresa: a.target.value})}>
                                                    <option value="">Selecione a empresa</option>
                                                    { this.state.empresas.map((emp) => (
                                                        <option value={emp.empresa_id}>{emp.empresa_razaosocial}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="modelo" id="lblModelo">Modelo </label>
                                            <div className="grupo-campo-input input-cad" id="modelo">
                                                <i className="fas fa-asterisk" id="iconModelo"></i>
                                                <input
                                                    type="text"
                                                    placeholder="&nbsp;Informe o Modelo do item"
                                                    autoComplete="on"
                                                    onChange={event => this.configModelo(event.target.value)}
                                                    required
                                                    onFocus={a => this.setState({ clientFocus2: true })} 
                                                    onBlur={a => this.setState({ clientFocus2: false })}
                                                    value={this.state.modelo}

                                                />
                                            </div>
                                            <ul className={this.state.clientFocus2 ? "listC activate" : "listC"}>
                                                {
                                                    this.state.modelosvei.filter(y => y.nome.toLowerCase().includes(this.state.modelo.toLowerCase())).map(e => (
                                                        <li onClick={a => this.setState({modelo: e.nome})}>{e.nome}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grupo-input input-style-cad">
                                            <label htmlFor="placa">Placa</label>
                                            <div className="grupo-campo-input input-cad" id="placa">
                                                <i className="fas fa-user" id="iconPlaca"></i>
                                                <input type="text" placeholder="Informe a placa" onChange={e => this.setState({placa: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="row justify-content-end">
                                    <div className="col-md-3">
                                        <div className="grupo-campo-input input-cad">
                                            <div className="grupo-campo-input input-cad">
                                                <button className="btn-env btn-add" onClick={() => this.pesquisar()}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.itensF.filter(a => a.compra == 1 || a.compra == 0).slice((this.state.pagina - 1) * 10, this.state.pagina * 10).map(e => (
                                <>
                                    <Link to={"/questionario/" + e.item_id}>
                                        <div className="andamento_compras">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="head">
                                                    <p>{e.item_marca} {e.item_modelo} - {e.placa}</p>
                                                    <div className="d-flex px-2">
                                                        <div className="space-p"></div>
                                                    </div>
                                                    <p>{e.pessoa_nome}</p>
                                                </div>
                                                <p className="text">{moment(e.item_admissaodata).format('DD/MM/YYYY HH:mm')}</p>
                                            </div>
                                            <div className="status">
                                                <div className="container">     
                                                    <div className="row bs-wizard" style={{borderBottom: 0}}>         
                                                        <div className={e.etapa > 0 ? 'bs-wizard-step  complete' : e.etapa == 0 ? 'bs-wizard-step active' : 'bs-wizard-step disabled'}>
                                                            
                                                            <div className="text-center bs-wizard-stepnum">
                                                                Etapa 1
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar"></div>
                                                            </div>
                                                            <span className="bs-wizard-dot"></span>
                                                            <div className="bs-wizard-info text-center">
                                                                Verificar Cliente
                                                            </div>
                                                        </div>    
                                                        <div className={e.etapa > 1 ? 'bs-wizard-step complete' : e.etapa == 1 ? 'bs-wizard-step active' : 'bs-wizard-step disabled'}>
                                                            
                                                            <div className="text-center bs-wizard-stepnum">
                                                                Etapa 2
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar"></div>
                                                            </div>
                                                            <span className="bs-wizard-dot"></span>
                                                            <div className="bs-wizard-info text-center">
                                                                Inserir Veículo
                                                            </div>
                                                        </div>
                                                        <div className={e.etapa > 2 ? 'bs-wizard-step  complete' : e.etapa == 2 ? 'bs-wizard-step active' : 'bs-wizard-step disabled'}>
                                                            <div className="text-center bs-wizard-stepnum">
                                                                Etapa 3
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar"></div>
                                                            </div>
                                                            <span className="bs-wizard-dot"></span>
                                                            <div className="bs-wizard-info text-center">
                                                                Questionário Veículo
                                                            </div>
                                                        </div>
                                                        <div className={e.etapa > 3 ? 'bs-wizard-step complete' : e.etapa == 3 ? 'bs-wizard-step active' : 'bs-wizard-step disabled'}>
                                                            
                                                            <div className="text-center bs-wizard-stepnum">
                                                                Etapa 4
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar"></div>
                                                            </div>
                                                            <span className="bs-wizard-dot"></span>
                                                            <div className="bs-wizard-info text-center">
                                                                Questionário Documento
                                                            </div>
                                                        </div> 
                                                        <div className={e.etapa > 3 ? 'bs-wizard-step complete' : e.etapa == 3 ? 'bs-wizard-step active' : 'bs-wizard-step disabled'}>
                                                            <div className="text-center bs-wizard-stepnum">
                                                                Etapa 5
                                                            </div>
                                                            <div className="progress">
                                                                <div className="progress-bar"></div>
                                                            </div>
                                                            <span className="bs-wizard-dot"></span>
                                                            <div className="bs-wizard-info text-center">
                                                                Compra
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </Link>       
                                    {e.status != 3 ? <div className="td-acoes andamento_compras" style={{marginTop: "-30px", cursor: "unset"}}>
                                        <i className="fa fa-trash" style={{cursor: "pointer"}} onClick={() => this.OpenModalDeletar(e.item_id)}></i>
                                    </div> : null}
                                </>
                                ))
                            }
                            {this.state.itensF.length > 0 && <div style={{marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina > 1 ? this.state.pagina - 1 : 1})
                                    }}>
                                        {<i class="fas fa-angle-left arrows"></i>}
                                    </div>
                                    {
                                        [...Array(this.state.quantidade_paginas)].map((pagina, index) => {
                                            if( (this.state.pagina === 1 && index + 1 < 4) || 
                                                (this.state.pagina === this.state.quantidade_paginas && index + 1 > this.state.quantidade_paginas - 3) ||
                                                (index + 1 === this.state.pagina -1 || index + 1 === this.state.pagina || index + 1 === this.state.pagina + 1)
                                            ){
                                                return (
                                                    <div key={index+1} className='paginacao text-center py-1  px-2' style={{width: '30px', backgroundColor: this.state.pagina === index + 1 ? '#ff7115' : null, fontWeight: this.state.pagina === index + 1 ? '500' : null, cursor: 'pointer', color: this.state.pagina === index + 1 ? '#fff' : null,}}
                                                    onClick={() => {
                                                        this.setState({pagina: index + 1})
                                                    }}>
                                                        {index + 1}
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null
                                            }
                                        })
                                    }
                                    <div className='paginacao text-center p-2' style={{width: '30px', cursor: 'pointer'}} onClick={() => {
                                        this.setState({pagina: this.state.pagina < this.state.quantidade_paginas ? this.state.pagina + 1 : this.state.quantidade_paginas})
                                    }}>
                                        {<i class="fas fa-angle-right arrows"></i>}
                                    </div>
                                </div>}
                        
                            <Versionamento/>
                        </div>
                    </div>
                </section>

                {/*Modal Deletar*/}
                <Modal
                isOpen={this.state.modalDeletar}
                toggle={this.toggleModalDeletar.bind(this)}
                backdrop={true}
                keyboard={true}
                size="sm"
                fade={false} >
                    <ModalHeader toggle={this.toggleModalDeletar.bind(this)}>Atenção!</ModalHeader>
                    <ModalBody>
                        Deseja realmente deletar a compra do item 
                        <br/><b>{this.state.modalDeletarItem} (ID: {this.state.modalDeletarId})</b>?
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.compraDeletar(this.state.modalDeletarId)} color="danger">Deletar</Button>
                        <Button onClick={this.toggleModalDeletar.bind(this)}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default Page